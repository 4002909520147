const columnTypes = {
  LINK: "link",
  INTEGER: "integer",
  STRING: "string",
  DATE_TIME: "datetime",
  SELECT: "select",
  SELECT_MULTI: "select_multi",
  BOOLEAN: "boolean",
};

const convertDataIndex = (dataIndex) => {
  return Array.isArray(dataIndex) ? dataIndex.join(".") : dataIndex;
};

const revertDataIndex = (dataIndex) => {
  return dataIndex.includes(".") ? dataIndex.split(".") : dataIndex;
};

export { columnTypes, convertDataIndex, revertDataIndex };
