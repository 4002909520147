import React, { Component } from "react";
import { formatDate } from "../utils/dates";
import { columnTypes } from "../utils/search";
import { apiBase } from "../utils/apiBase";
import { get, capitalize } from "lodash";

import BaseSearch from "../views/search/BaseSearch";
import Took from "../views/search/Took";
import TotalRecords from "../views/search/TotalRecords";
import { syncStatusOptions, syncTypeOptions } from "../common/pipedriveSyncs";

import ReactJson from "react-json-view";
import { DatabaseOutlined } from "@ant-design/icons";
import CreatePipedriveSync from "./forms/CreatePipedriveSync";
import FilterTags from "../views/search/FilterTags";

import {
  Row,
  Col,
  Table,
  Select,
  Button,
  Tooltip,
  Pagination,
  message,
} from "antd";

import styles from "./PipedriveSyncs.module.scss";

const renderResults = (syncData, key) => {
  const result = get(syncData, `results.${key}`, {});
  return (
    <div>
      <div>Total: {get(result, "total")}</div>
      <div>Created: {get(result, "created")}</div>
      <div>Updated: {get(result, "updated")}</div>
      <div>Deleted: {get(result, "deleted")}</div>
    </div>
  );
};

const baseColumnState = [
  {
    dataIndex: "id",
    title: "Id",
    className: `App__w8`,
    children: [
      {
        dataIndex: "id",
        className: styles.rowLinkNotLinked,
        title: () => <div />,
        render: (value) => <div className="App__rowIcon">{value}</div>,
      },
    ],
  },
  {
    dataIndex: "status",
    title: "Status",
    type: columnTypes.SELECT,
    options: syncStatusOptions,
    format: ({ value }) => capitalize(value),
  },
  {
    dataIndex: "type",
    title: "Type",
    type: columnTypes.SELECT,
    options: syncTypeOptions,
    format: ({ value }) => capitalize(value),
  },
  {
    key: "activities",
    dataIndex: "data",
    title: "Activites",
    className: `App__w12`,
    children: [
      {
        key: "activities",
        dataIndex: "data",
        title: () => <div />,
        render: (value) => renderResults(value, "activities"),
      },
    ],
  },
  {
    key: "persons",
    dataIndex: "data",
    title: "Persons",
    className: `App__w12`,
    children: [
      {
        key: "persons",
        dataIndex: "data",
        title: () => <div />,
        render: (value) => renderResults(value, "persons"),
      },
    ],
  },
  {
    key: "deals",
    dataIndex: "data",
    title: "Deals",
    className: `App__w12`,
    children: [
      {
        key: "deals",
        dataIndex: "data",
        title: () => <div />,
        render: (value) => renderResults(value, "deals"),
      },
    ],
  },
  {
    key: "users",
    dataIndex: "data",
    title: "Users",
    className: `App__w12`,
    children: [
      {
        key: "users",
        dataIndex: "data",
        title: () => <div />,
        render: (value) => renderResults(value, "users"),
      },
    ],
  },
  {
    key: "person_labels",
    dataIndex: "data",
    title: "Person labels",
    className: `App__w12`,
    children: [
      {
        key: "person_labels",
        dataIndex: "data",
        title: () => <div />,
        render: (value) => renderResults(value, "person_labels"),
      },
    ],
  },
  {
    key: "deal_stages",
    dataIndex: "data",
    title: "Deal stages",
    className: `App__w12`,
    children: [
      {
        key: "deal_stages",
        dataIndex: "data",
        title: () => <div />,
        render: (value) => renderResults(value, "deal_stages"),
      },
    ],
  },
  {
    key: "data",
    dataIndex: "data",
    title: "Sync data",
    className: `App__w10`,
    children: [
      {
        dataIndex: "data",
        title: () => <div />,
        render: (value) => (
          <Tooltip
            overlayClassName={styles.toolTipCard}
            placement="bottom"
            title={
              <ReactJson
                src={get(value, "results")}
                collapsed={false}
                style={{
                  backgroundColor: "white",
                  width: "484px",
                  fontSize: "12px",
                }}
                displayDataTypes={false}
              />
            }
          >
            <DatabaseOutlined />
          </Tooltip>
        ),
      },
    ],
  },
  {
    title: "Runtime (s)",
    dataIndex: "runtime_seconds",
    type: columnTypes.DATE_TIME,
  },
  {
    title: "Started at",
    dataIndex: "started_at",
    type: columnTypes.DATE_TIME,
    format: (value) => formatDate(value, "YYYY-MM-DD, h:mm:ss A"),
  },
  {
    title: "Stoppped Date",
    dataIndex: "stopped_at",
    type: columnTypes.DATE_TIME,
    format: (value) => formatDate(value, "YYYY-MM-DD, h:mm:ss A"),
  },
];

class PipedriveSyncs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      isLoading: false,
    };
  }

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      } else {
        this.setState({ isLoading: true });
        apiBase
          .post("/pipedrive-syncs", values)
          .then((res) => {
            let msg = `${get(res, "status")}: Successfully created new sync.`;
            message.success(msg);
          })
          .catch((err) => {
            let msg = `${get(err, "response.status")}: ${get(
              err,
              "response.data.message"
            )}`;
            message.error(msg);
          })
          .finally(() => {
            form.resetFields();
            this.setState({
              visible: false,
              isLoading: false,
            });
            this.props.executeSearch();
          });
      }
    });
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  render() {
    const {
      isLoadingRecords,
      isLoadingCount,
      records,
      columnState,
      limit,
      page,
      totalRecords,
      tookRecords,
      tookCount,
      searchFields,
    } = this.props;

    const {
      handleSearch,
      handleRecordsChange,
      handlePaginationChange,
      handleFilterClose,
      handleTableChange,
      getColumnProps,
    } = this.props;

    let columns = columnState
      .map((column, idx) => ({
        key: idx,
        ...column,
        ...getColumnProps(column),
      }))
      .filter((col) => col.visible === true);

    return (
      <div>
        <Row className="App__rowContent">
          <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16}>
            <Button onClick={this.showModal} className={styles.marginRight}>
              Create sync
            </Button>
            <Button className="App__mr-5" onClick={() => handleSearch()}>
              Search
            </Button>
            <FilterTags
              searchFields={searchFields}
              columnState={columnState}
              handleFilterClose={handleFilterClose}
            />
            <CreatePipedriveSync
              wrappedComponentRef={this.saveFormRef}
              visible={this.state.visible}
              isLoading={this.state.isLoading}
              onCancel={this.handleCancel}
              onCreate={this.handleCreate}
            />
          </Col>
          <Col
            span={8}
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            className="App__rightActions"
          >
            <Select
              value={limit}
              onChange={handleRecordsChange}
              className={"App__mr-5"}
            >
              <Select.Option value={25}>25</Select.Option>
              <Select.Option value={50}>50</Select.Option>
              <Select.Option value={100}>100</Select.Option>
            </Select>
            <TotalRecords
              title={"Pipedrive syncs"}
              isLoadingCount={isLoadingCount}
              totalRecords={totalRecords}
            />
          </Col>
        </Row>
        <Row className="App__rowContent">
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={records}
              onChange={handleTableChange}
              pagination={false}
              showSorterTooltip={false}
              className="App__smallTables"
              loading={isLoadingRecords}
              scroll={{ x: "100%" }}
              size="small"
              bordered
            />
            <div className="App__searchFooterContainer">
              <Took
                isLoadingRecords={isLoadingRecords}
                isLoadingCount={isLoadingCount}
                tookRecords={tookRecords}
                tookCount={tookCount}
              />
              <Pagination
                current={page}
                pageSize={limit}
                total={totalRecords}
                onChange={handlePaginationChange}
                className="App__pagination"
                showSizeChanger={false}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const PipedriveSyncsWithSearch = (props) => (
  <BaseSearch
    baseColumnState={baseColumnState}
    localSortColumns={[]}
    searchUri="pipedrive-syncs"
    baseUri="/settings"
  >
    <PipedriveSyncs {...props} />
  </BaseSearch>
);

export default PipedriveSyncsWithSearch;
