import React, { useState, useEffect } from "react";
import { Modal, Row, Col, message } from "antd";
import { apiBase } from "../../utils/apiBase";
import { get, isEmpty } from "lodash";
import ReactJson from "react-json-view";

import styles from "./OwnerModelPrediction.module.scss";

const OwnerModelPrediction = ({ ownerId, isModalOpen, setIsModalOpen }) => {
  const [loading, setLoading] = useState(false);
  const [prediction, setPrediction] = useState({});

  useEffect(() => {
    const fetchPrediction = async () => {
      if (!ownerId || !isEmpty(prediction) || !isModalOpen) return;

      setLoading(true);
      try {
        const res = await apiBase.post("/model-predictions", {
          owner_id: ownerId,
        });
        setPrediction(get(res, "data"));
      } catch (error) {
        message.error("Failed to fetch model prediction.");
      } finally {
        setLoading(false);
      }
    };

    fetchPrediction();
  }, [ownerId, isModalOpen, prediction]);

  const handleCancel = () => {
    setIsModalOpen(false);
    setPrediction({});
  };

  const { waterfall = "", input, output, offer_amount, error } = prediction;

  return (
    <>
      <Modal
        title="Owner Model Prediction"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={1200}
      >
        {loading ? (
          <div>Loading...</div>
        ) : (
          <Row>
            <Col span={8}>
              <ReactJson
                src={{
                  input,
                  output,
                  offer_amount,
                }}
                style={{
                  fontSize: 12,
                  padding: "5px 10px",
                  border: "1px solid gray",
                  overflow: "auto",
                }}
              />
            </Col>
            <Col span={16}>
              <>
                {waterfall && (
                  <div className={styles.container}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: waterfall,
                      }}
                      className={styles.waterfallChart}
                    />
                    {error && (
                      <ReactJson
                        src={error}
                        style={{
                          fontSize: 12,
                          padding: "5px 10px",
                          border: "1px solid gray",
                          maxHeight: 400,
                          overflow: "auto",
                        }}
                      />
                    )}
                  </div>
                )}
              </>
            </Col>
          </Row>
        )}
      </Modal>
    </>
  );
};

export default OwnerModelPrediction;
