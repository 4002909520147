import React from "react";
import { withParams } from "../../utils/withParams";
import { withRouter } from "../../utils/withRouter";
import { formatNumber, alphaSort } from "../../utils/numbers";
import { formatDate } from "../../utils/dates";

import { Table } from "antd";

const AssetSales = (props) => {
  const { assetSales, parentLoading } = props;

  function handleRowClick(e, _to) {
    e.stopPropagation();
    if (e.ctrlKey || e.metaKey) {
      window.open(_to, "_blank");
      return;
    }
    props.history(_to);
  }

  const columns = [
    {
      title: "Sale",
      dataIndex: "sale_id",
      className: `App__rowLink App__w8`,
      render: (value) => <div className="App__rowIcon">{value}</div>,
      onCell: (record) => ({
        onClick: (e) =>
          handleRowClick(e, `/portfolio/sales/${record["sale_id"]}`),
      }),
    },
    {
      title: "Effective date",
      dataIndex: "effective_date",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => alphaSort(a.effective_date, b.effective_date),
      render: (value) => formatDate(value, "YYYY-MM-DD"),
    },
    {
      title: "Sale date",
      dataIndex: "sale_date",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => alphaSort(a.sale_date, b.sale_date),
      render: (value) => formatDate(value, "YYYY-MM-DD"),
    },
    {
      title: "Grantee",
      dataIndex: "grantee",
      sorter: (a, b) => alphaSort(a.grantee, b.grantee),
    },
    {
      title: "Sale Code",
      dataIndex: "sale_code",
      sorter: (a, b) => alphaSort(a.sale_code, b.sale_code),
    },
    {
      title: "Fraction Conveyed",
      dataIndex: "fraction_conveyed",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.fraction_conveyed - b.fraction_conveyed,
      render: (value) =>
        value !== null ? `${formatNumber(value * 100, "0.0000")}%` : null,
    },
    {
      title: "Decimal Interest",
      dataIndex: "decimal_interest",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.decimal_interest - b.decimal_interest,
      render: (value) =>
        value !== null ? `${formatNumber(value, "0.00000000")}` : null,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.amount - b.amount,
      render: (value) =>
        value !== null ? `$${formatNumber(value, "0,0.00")}` : null,
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    };
  });

  return (
    <Table
      pagination={{ size: "small" }}
      columns={mergedColumns}
      dataSource={assetSales.map((saleLine, idx) => ({
        ...saleLine,
        key: idx,
      }))}
      showSorterTooltip={false}
      className="App__smallTables"
      loading={parentLoading}
      scroll={{ x: "100%" }}
      bordered
    />
  );
};

export default withRouter(withParams(AssetSales));
