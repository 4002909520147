import React from "react";
import { LoadingOutlined } from "@ant-design/icons";

import { formatNumber } from "../../utils/numbers";

import styles from "./Took.module.scss";

const Took = (props) => {
  const { isLoadingRecords, isLoadingCount, tookRecords, tookCount } = props;

  return (
    <div className={styles.multiTook}>
      <div className={styles.tookStat}>
        <div>Search (ms)</div>
        <div>
          {isLoadingRecords ? (
            <LoadingOutlined />
          ) : (
            <>{formatNumber(tookRecords, "0,0")}</>
          )}
        </div>
      </div>
      <div className={styles.tookStat}>
        <div>Count (ms)</div>
        <div>
          {isLoadingCount ? (
            <LoadingOutlined />
          ) : (
            <>{formatNumber(tookCount, "0,0")}</>
          )}
        </div>
      </div>
    </div>
  );
};

export default Took;
