import React, { useState } from "react";
import { connect } from "react-redux";
import { apiBase } from "../../utils/apiBase";

import { Modal, Form, Input, Button, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const OperatorCreateForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    // console.log(values);
    apiBase
      .post(`operators`, {
        ...values,
      })
      .then(async (res) => {
        message.success("Successfully created operator.");
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to create operator.`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
        resetComponent();
        setIsModalOpen(false);
        props.executeSearch();
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    resetComponent();
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    resetComponent();
  };

  const submitForm = () => {
    form
      .validateFields()
      .then(() => {
        form.submit();
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };

  const resetComponent = () => {
    form.resetFields();
  };

  const [form] = Form.useForm();

  return (
    <div>
      <Button type="secondary" icon={<PlusOutlined />} onClick={showModal} />
      <Modal
        title="Create operator"
        visible={isModalOpen}
        onOk={() => submitForm()}
        onCancel={handleCancel}
        cancelButtonProps={{ disabled: loading }}
        okButtonProps={{ disabled: loading, loading: loading }}
        maskClosable={false}
      >
        <Form
          name="operatorCreate"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
          disabled={loading}
          initialValues={{}}
        >
          {[{ name: "name", label: "Name", required: true }].map(
            (item, index) => (
              <Form.Item
                key={index}
                label={item.label}
                name={item.name}
                rules={[
                  {
                    required: item.required,
                    message: `${item.label} is required.`,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OperatorCreateForm);
