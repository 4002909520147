import React, { useState, useEffect, useCallback } from "react";
import { Form, Table, Button, Tooltip, message } from "antd";
import { get, orderBy, uniqBy, isEmpty } from "lodash";
import { apiBase } from "../../utils/apiBase";
import { alphaSort } from "../../utils/numbers";

import OwnerNameAddressSimilarSplink from "./OwnerNameAddressSimilarSplink";

import { CheckCircleTwoTone, WarningTwoTone } from "@ant-design/icons";

import styles from "./OwnerOwnerNameAddresses.module.scss";

const OwnerOwnerNameAddresses = (props) => {
  const {
    columnExtras,
    loadingOwnerNameAddresses,
    recordsPerPage,
    ownerNameAddresses,
    getOwnerNameAddresses,
  } = props;

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(ownerNameAddresses);
  const [uspsAddresses, setUspsAddresses] = useState([]);

  const [ownerNameAddress, setOwnerNameAddress] = useState(null);
  const [similarModalOpen, setSimilarModalOpen] = useState(false);

  useEffect(() => {
    const ownerNameAddressesSorted = orderBy(
      ownerNameAddresses,
      (o) => Math.max(...get(o, "tax_years", [])),
      ["desc"]
    );

    setData(ownerNameAddressesSorted);
  }, [ownerNameAddresses]);

  const handleSearchUSPSAddresses = useCallback(
    async ({ refresh = false }) => {
      const geocodedAddresses = uniqBy(
        ownerNameAddresses,
        "geocoded_address_id"
      ).map((address) => ({
        id: get(address, "geocoded_address_id"),
        street_address: get(address, "street_formatted"),
        city: get(address, "city"),
        state: get(address, "state"),
        zip: get(address, "zip_code"),
      }));

      const url = refresh
        ? "/usps-searches/bulk-validate"
        : "/usps-addresses/match";

      setLoading(true);
      apiBase
        .post(url, { addresses: geocodedAddresses, type: "geocoded_addresses" })
        .then((res) => {
          const _uspsAddresses = get(res, "data").map((address) => {
            const input = get(address, "input");
            const output = get(address, "output");
            return {
              input,
              output,
            };
          });
          setUspsAddresses(_uspsAddresses);
        })
        .catch((err) => {
          let msg = `${get(
            err,
            "response.status"
          )}: Failed to validate addresses.`;
          message.error(msg);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [ownerNameAddresses]
  );

  useEffect(() => {
    const addresses = uniqBy(ownerNameAddresses, "geocoded_address_id");
    if (uspsAddresses.length === 0 && addresses.length > 0) {
      handleSearchUSPSAddresses({
        refresh: false,
      });
    }
  }, [handleSearchUSPSAddresses, ownerNameAddresses, uspsAddresses]);

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      width: 75,
    },
    ...[
      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => alphaSort(a.name, b.name),
        width: 200,
        ellipsis: {
          showTitle: false,
        },
        render: (value) => (
          <Tooltip placement="topLeft" title={value}>
            {value}
          </Tooltip>
        ),
      },
      {
        title: "Name ICO",
        dataIndex: "name_ico",
        sorter: (a, b) => alphaSort(a.name_ico, b.name_ico),
        width: 200,
        ellipsis: {
          showTitle: false,
        },
        render: (value) => (
          <Tooltip placement="topLeft" title={value}>
            {value}
          </Tooltip>
        ),
      },
      {
        title: "Street raw",
        dataIndex: "street",
        sorter: (a, b) => alphaSort(a.street, b.street),
        width: 200,
        ellipsis: {
          showTitle: false,
        },
        render: (value) => (
          <Tooltip placement="topLeft" title={value}>
            {value}
          </Tooltip>
        ),
      },
      {
        title: "Geo Id",
        dataIndex: "geocoded_address_id",
        sorter: (a, b) => a.geocoded_address_id - b.geocoded_address_id,
        width: 100,
      },
      {
        title: "USPS",
        dataIndex: "usps_address",
        width: 75,
        sorter: (a, b) =>
          alphaSort(
            get(a, "usps_address.is_valid"),
            get(b, "usps_address.is_valid")
          ),
        render: (_, record) => {
          const uspsAddress = get(record, "usps_address");
          return (
            <Tooltip
              overlayStyle={{ width: "600px" }}
              overlayInnerStyle={{ width: "600px" }}
              title={<pre>{JSON.stringify(uspsAddress, null, 2)}</pre>}
            >
              {isEmpty(uspsAddress) ? (
                "-"
              ) : get(uspsAddress, "is_valid") ? (
                <CheckCircleTwoTone twoToneColor="#52c41a" />
              ) : (
                <WarningTwoTone twoToneColor="#faad14" />
              )}
            </Tooltip>
          );
        },
      },
      {
        title: "Street",
        dataIndex: "street_formatted",
        sorter: (a, b) => alphaSort(a.street_formatted, b.street_formatted),
        width: 250,
        ellipsis: {
          showTitle: false,
        },
        render: (value) => (
          <Tooltip placement="topLeft" title={value}>
            {value}
          </Tooltip>
        ),
      },
      {
        title: "City",
        dataIndex: "city",
        sorter: (a, b) => alphaSort(a.city, b.city),
        width: 150,
        ellipsis: {
          showTitle: false,
        },
        render: (value) => (
          <Tooltip placement="topLeft" title={value}>
            {value}
          </Tooltip>
        ),
      },
      {
        title: "State",
        dataIndex: "state",
        sorter: (a, b) => alphaSort(a.state, b.state),
        width: 75,
      },
      {
        title: "Zip Code",
        dataIndex: "zip_code",
        sorter: (a, b) => alphaSort(a.zip_code, b.zip_code),
        width: 90,
      },
      {
        title: "Source",
        dataIndex: "source",
        width: 120,
        sorter: (a, b) => alphaSort(a.source, b.source),
      },
      {
        title: "OUI Count",
        dataIndex: "oui_count",
        width: 90,
        sorter: (a, b) => a.oui_count - b.oui_count,
      },
      {
        title: "GFR Count",
        dataIndex: "gfr_count",
        width: 90,
        sorter: (a, b) => a.gfr_count - b.gfr_count,
      },
      {
        title: "Tax years",
        dataIndex: "tax_years",
        width: 200,
        ellipsis: {
          showTitle: false,
        },
        render: (value) => (
          <Tooltip
            title={value.sort((a, b) => b - a).join(", ")}
            placement="topLeft"
          >
            <span>{value.sort((a, b) => b - a).join(", ")}</span>
          </Tooltip>
        ),
      },
      {
        title: "Unit Ownerships",
        dataIndex: "unit_ownerships",
        width: 300,
        ellipsis: {
          showTitle: false,
        },
        render: (value) => (
          <Tooltip title={value.join(", ")} placement="topLeft">
            <span>{value.join(", ")}</span>
          </Tooltip>
        ),
      },
      {
        title: "Matched",
        dataIndex: "matched",
        width: 75,
        render: (_, record) => {
          return record.matched ? "Yes" : "No";
        },
      },
    ].filter((item) => (columnExtras ? true : !item.extras)),
    {
      title: "Actions",
      dataIndex: "id",
      key: "actions",
      width: 75,
      render: (_, record) => {
        return (
          <Button
            size="small"
            onClick={() => {
              setOwnerNameAddress(record);
              setSimilarModalOpen(true);
            }}
          >
            Similar
          </Button>
        );
      },
    },
  ];

  const _data = data.map((record) => {
    const uspsAddress = get(
      uspsAddresses.find(
        (uspsAddress) =>
          get(uspsAddress, "input.id") === record.geocoded_address_id
      ),
      "output"
    );
    return {
      ...record,
      usps_address: uspsAddress,
    };
  });

  return (
    <>
      <Form form={form} component={false}>
        <div className={styles.headerActions}>
          <Button
            type="secondary"
            onClick={() => handleSearchUSPSAddresses({ refresh: true })}
            loading={loading}
          >
            Validate addresses
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={_data}
          pagination={{
            pageSize: recordsPerPage,
            showSizeChanger: false,
          }}
          showSorterTooltip={false}
          className="App__smallTables"
          loading={loadingOwnerNameAddresses || loading}
          scroll={{ x: "100%" }}
          bordered
        />
      </Form>
      <OwnerNameAddressSimilarSplink
        open={similarModalOpen}
        ownerNameAddressId={ownerNameAddress?.id}
        onCancel={() => setSimilarModalOpen(false)}
        getOwnerNameAddresses={getOwnerNameAddresses}
      />
    </>
  );
};

export default OwnerOwnerNameAddresses;
