import { capitalize } from "lodash";

const syncStatus = {
  initialization: "initialization",
  running: "running",
  completed: "completed",
  warning: "warning",
  failed: "failed",
};

const syncType = {
  partial: "partial",
  full: "full",
};

const syncStatusOptions = Object.keys(syncStatus).map((key) => ({
  value: key,
  label: capitalize(key),
}));

const syncTypeOptions = Object.keys(syncType).map((key) => ({
  value: key,
  label: capitalize(key),
}));

export { syncStatus, syncType, syncStatusOptions, syncTypeOptions };
