function nonEmptyString(value) {
  if (value === null || value === undefined) {
    return null;
  } else if (value === "") {
    return null;
  } else if (typeof value !== "string") {
    return String(value);
  } else {
    return value;
  }
}

export { nonEmptyString };
