import React from "react";

import { leadViews } from "../../common/views";

import { NavLink } from "react-router-dom";
import {
  ClusterOutlined,
  EyeOutlined,
  LayoutOutlined,
  SolutionOutlined,
  MailOutlined,
  CloudUploadOutlined,
  CloudServerOutlined,
  LineChartOutlined,
  GlobalOutlined,
  CodeOutlined,
  DollarOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";

import { DEFAULT_PAGE_SIZE } from "../../common/navs";

import { Menu } from "antd";

import styles from "./Nav.module.scss";

const CRM = (props) => {
  const { collapsed, navSelectedKeys } = props;

  return (
    <Menu
      theme="light"
      mode="inline"
      className={collapsed ? null : styles.siderMenu}
      selectedKeys={navSelectedKeys}
    >
      <Menu.ItemGroup key="g1" title={collapsed ? "" : "Interests"}>
        <Menu.Item key="permit_plats" className={styles.siderMenuItem}>
          <NavLink to={`/permit-plats?limit=${DEFAULT_PAGE_SIZE}&page=1`}>
            <FileDoneOutlined />
            <span>Permit Plats</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="permits" className={styles.siderMenuItem}>
          <NavLink to={`/permits?limit=${DEFAULT_PAGE_SIZE}&page=1`}>
            <FileDoneOutlined />
            <span>Permits</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="units" className={styles.siderMenuItem}>
          <NavLink to={`/units?limit=${DEFAULT_PAGE_SIZE}&page=1`}>
            <LayoutOutlined />
            <span>Units</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="owners" className={styles.siderMenuItem}>
          <NavLink to="/owners?limit=50&page=1">
            <ClusterOutlined />
            <span>Owners</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="generic_file_records" className={styles.siderMenuItem}>
          <NavLink
            to={`/generic-file-records?limit=${DEFAULT_PAGE_SIZE}&page=1`}
          >
            <CloudUploadOutlined />
            <span>Generics</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="owner_name_addresses" className={styles.siderMenuItem}>
          <NavLink
            to={`/owner-name-addresses?limit=${DEFAULT_PAGE_SIZE}&page=1`}
          >
            <SolutionOutlined />
            <span>Name addresses</span>
          </NavLink>
        </Menu.Item>
        {/* <Menu.Item key="interests" className={styles.siderMenuItem}>
        <NavLink
          to="/interests?limit=50&page=1"
          
        >
          <Icon type="file-text" />
          <span>Interests</span>
        </NavLink>
      </Menu.Item> */}
      </Menu.ItemGroup>
      <Menu.ItemGroup key="g2" title={collapsed ? "" : "CRM"}>
        <Menu.Item key="offers" className={styles.siderMenuItem}>
          <NavLink to={`/offers?limit=${DEFAULT_PAGE_SIZE}&page=1`}>
            <DollarOutlined />
            <span>Offers</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="leads" className={styles.siderMenuItem}>
          <NavLink
            to={`/leads?view=${leadViews.tax_roll}&limit=${DEFAULT_PAGE_SIZE}&page=1`}
          >
            <EyeOutlined />
            <span>Leads</span>
          </NavLink>
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.ItemGroup key="g3" title={collapsed ? "" : "Ops"}>
        <Menu.Item key="generic_files" className={styles.siderMenuItem}>
          <NavLink to={`/generic-files?limit=${DEFAULT_PAGE_SIZE}&page=1`}>
            <CloudServerOutlined />
            <span>Generic Files</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="idi_bulk_searches" className={styles.siderMenuItem}>
          <NavLink to={`/idi-bulk-searches?limit=${DEFAULT_PAGE_SIZE}&page=1`}>
            <CodeOutlined />
            <span>IDI bulks</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="usps_bulk_searches" className={styles.siderMenuItem}>
          <NavLink to={`/usps-bulk-searches?limit=${DEFAULT_PAGE_SIZE}&page=1`}>
            <CodeOutlined />
            <span>USPS bulks</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="mail_exports" className={styles.siderMenuItem}>
          <NavLink to={`/mail-exports?limit=${DEFAULT_PAGE_SIZE}&page=1`}>
            <MailOutlined />
            <span>Mail exports</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="mail_queue" className={styles.siderMenuItem}>
          <NavLink to={`/mail-queue`}>
            <CloudUploadOutlined />
            <span>Mail queue</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="pipedrive_uploads" className={styles.siderMenuItem}>
          <NavLink to={`/pipedrive-uploads?limit=${DEFAULT_PAGE_SIZE}&page=1`}>
            <CloudUploadOutlined />
            <span>Pipedrive uploads</span>
          </NavLink>
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.ItemGroup key="s4" title={collapsed ? "" : "Contact data"}>
        <Menu.Item key="idi_searchs" className={styles.siderMenuItem}>
          <NavLink to={`/idi-searches?limit=${DEFAULT_PAGE_SIZE}&page=1`}>
            <CodeOutlined />
            <span>IDI searchs</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="idi_persons" className={styles.siderMenuItem}>
          <NavLink to={`/idi-persons?limit=${DEFAULT_PAGE_SIZE}&page=1`}>
            <CodeOutlined />
            <span>IDI people</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="endato_searches" className={styles.siderMenuItem}>
          <NavLink to={`/endato-searches?limit=${DEFAULT_PAGE_SIZE}&page=1`}>
            <CodeOutlined />
            <span>Endato searches</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="endato_persons" className={styles.siderMenuItem}>
          <NavLink to={`/endato-persons?limit=${DEFAULT_PAGE_SIZE}&page=1`}>
            <CodeOutlined />
            <span>Endato people</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="usps_searches" className={styles.siderMenuItem}>
          <NavLink to={`/usps-searches?limit=${DEFAULT_PAGE_SIZE}&page=1`}>
            <CodeOutlined />
            <span>USPS searches</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="usps_addresses" className={styles.siderMenuItem}>
          <NavLink to={`/usps-addresses?limit=${DEFAULT_PAGE_SIZE}&page=1`}>
            <CodeOutlined />
            <span>USPS addresses</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="phones" className={styles.siderMenuItem}>
          <NavLink to="/phones">
            <CodeOutlined />
            <span>Phones</span>
          </NavLink>
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.ItemGroup key="g4" title={collapsed ? "" : "Reports"}>
        <Menu.Item key="crmReportsMain" className={styles.siderMenuItem}>
          <NavLink to="/crm/reports/main">
            <LineChartOutlined />
            <span>Main</span>
          </NavLink>
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.ItemGroup key="g5" title={collapsed ? "" : "Maps"}>
        <Menu.Item key="map" className={styles.siderMenuItem}>
          <NavLink to="/map">
            <GlobalOutlined />
            <span>Main</span>
          </NavLink>
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );
};
export default CRM;
