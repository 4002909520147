import React, { Component } from "react";
import { formatNumber } from "../../utils/numbers";
import { columnTypes } from "../../utils/search";
import BaseSearch from "../search/BaseSearch";
import Took from "../search/Took";
import TotalRecords from "../search/TotalRecords";
import { convertDataIndex } from "../../utils/search";

import ExportForm from "../forms/ExportForm";
import ChangeColumnsForm from "../forms/ChangeColumnsForm";

import { DownloadOutlined, TableOutlined } from "@ant-design/icons";

import {
  Row,
  Col,
  Table,
  Select,
  Tag,
  Button,
  Tooltip,
  Pagination,
} from "antd";

const baseColumnState = [
  {
    dataIndex: "unit_id",
    title: "Unit",
    editable: false,
    type: columnTypes.LINK,
    baseUrl: "/units",
  },
  {
    dataIndex: "owner_id",
    title: "Owner",
    editable: false,
    type: columnTypes.LINK,
    baseUrl: "/owners",
  },
  {
    dataIndex: "county",
    title: "County",
    type: columnTypes.STRING,
    className: `App__w14`,
    visible: true,
  },
  {
    dataIndex: "legal_description",
    title: "Legal description",
    type: columnTypes.STRING,
    visible: true,
  },
  {
    dataIndex: "owner_name",
    title: "Owner name",
    type: columnTypes.STRING,
    visible: true,
  },
  {
    dataIndex: "owner_address",
    title: "Owner address",
    type: columnTypes.STRING,
    visible: true,
  },
  {
    dataIndex: "interest_type",
    title: "Type",
    type: columnTypes.STRING,
    className: `App__w10`,
    visible: true,
  },
  {
    dataIndex: "decimal_interest",
    title: "DOI",
    type: columnTypes.INTEGER,
    format: (value) => formatNumber(value, "0.000000"),
    className: `App__w10`,
    visible: true,
  },
  {
    dataIndex: "value",
    title: "Value",
    type: columnTypes.INTEGER,
    className: `App__w10`,
    visible: false,
  },
  {
    title: "Tax year",
    dataIndex: "tax_year",
    className: `App__w10`,
    type: columnTypes.SELECT,
  },
];

class Interests extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      isLoadingRecords,
      isLoadingCount,
      records,
      columnState,
      limit,
      page,
      totalRecords,
      tookRecords,
      tookCount,
      searchFields,
      isExportModalVisible,
      isChangeColumnsVisible,
      isLoadingExport,
    } = this.props;

    const {
      handleSearch,
      handleRecordsChange,
      handlePaginationChange,
      handleFilterClose,
      handleTableChange,
      handleSubmitExport,
      showExportModal,
      handleCancelExport,
      handleSubmitColumnChange,
      showColumnChangeModal,
      handleCancelColumnsChange,
      exportFormRef,
      changeColumnFormRef,
      getColumnProps,
    } = this.props;

    let columns = columnState
      .map((column, idx) => ({
        key: idx,
        ...column,
        ...getColumnProps(column),
      }))
      .filter((col) => col.visible === true);

    return (
      <div>
        <Row className="App__rowContent">
          <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16}>
            <Button className="App__mr-5" onClick={() => handleSearch()}>
              Search
            </Button>
            {Object.keys(searchFields).map((dataIndex, idx) => {
              return (
                <Tag
                  key={idx}
                  closable={true}
                  visible={searchFields[dataIndex] !== undefined ? true : false}
                  onClose={() => handleFilterClose(dataIndex)}
                  onClick={() => handleFilterClose(dataIndex)}
                  className="App__searchTags"
                >
                  {`${
                    columns[
                      columns.findIndex(
                        (col) => convertDataIndex(col.dataIndex) === dataIndex
                      )
                    ].title
                  }: ${JSON.stringify(searchFields[dataIndex])}`}
                </Tag>
              );
            })}
          </Col>
          <Col
            span={8}
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            className="App__rightActions"
          >
            <Tooltip placement="bottomRight" title={"Export search results"}>
              <Button className="App__iconButton" onClick={showExportModal}>
                <DownloadOutlined />
              </Button>
            </Tooltip>
            <ExportForm
              wrappedComponentRef={exportFormRef}
              visible={isExportModalVisible}
              isLoading={isLoadingExport}
              onCancel={handleCancelExport}
              onCreate={handleSubmitExport}
              totalRecords={totalRecords}
            />
            <Tooltip placement="bottomRight" title={"Change visible columns"}>
              <Button
                onClick={showColumnChangeModal}
                className="App__iconButton"
              >
                <TableOutlined />
              </Button>
            </Tooltip>
            <ChangeColumnsForm
              wrappedComponentRef={changeColumnFormRef}
              visible={isChangeColumnsVisible}
              isLoading={isLoadingRecords}
              onCancel={handleCancelColumnsChange}
              onOk={handleSubmitColumnChange}
              columnState={columnState}
            />
            <Select
              value={limit}
              onChange={handleRecordsChange}
              className={"App__mr-5"}
            >
              <Select.Option value={25}>25</Select.Option>
              <Select.Option value={50}>50</Select.Option>
              <Select.Option value={100}>100</Select.Option>
            </Select>
            <TotalRecords
              title={"Interests"}
              isLoadingCount={isLoadingCount}
              totalRecords={totalRecords}
            />
          </Col>
        </Row>
        <Row className="App__rowContent">
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={records}
              onChange={handleTableChange}
              pagination={false}
              showSorterTooltip={false}
              className="App__smallTables"
              loading={isLoadingRecords}
              scroll={{ x: "100%" }}
              size="small"
              bordered
            />
            <div className="App__searchFooterContainer">
              <Took
                isLoadingRecords={isLoadingRecords}
                isLoadingCount={isLoadingCount}
                tookRecords={tookRecords}
                tookCount={tookCount}
              />
              <Pagination
                current={page}
                pageSize={limit}
                total={totalRecords}
                onChange={handlePaginationChange}
                className="App__pagination"
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const InterestsWithSearch = (props) => (
  <BaseSearch
    baseColumnState={baseColumnState}
    localSortColumns={[]}
    searchUri="interests"
  >
    <Interests {...props} />
  </BaseSearch>
);

export default InterestsWithSearch;
