import { useParams, useLocation } from "react-router-dom";

function withParams(Component) {
  function ComponentWithParams(props) {
    const params = useParams();
    const location = useLocation();
    return <Component {...props} params={params} location={location} />;
  }

  return ComponentWithParams;
}

export { withParams };
