import React, { useState, useEffect } from "react";
import { get } from "lodash";
import clsx from "clsx";

import { apiBase } from "../../../utils/apiBase";

import MapFilter from "../common/MapFilter";
import { useMapFilter } from "../common/MapFilterContext";
import { fitMapToBounds } from "../common/utils";

import { Form, Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

import { CONTROL_IDS } from "../constants";

import styles from "./GridSearch.module.scss";

const LAYER_SOURCE_ID = "grid-search-results";
const LAYER_FILL_ID = `${LAYER_SOURCE_ID}-fill`;
const LAYER_OUTLINE_ID = `${LAYER_SOURCE_ID}-outline`;

const INITIAL_STATE = {
  value: "Texas",
};

const GridSearch = ({ mapRef, controlVisible, setControlVisible }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const { setOpenField } = useMapFilter();

  const [selectedStates, setSelectedStates] = useState([INITIAL_STATE]);
  const [selectedCounties, setSelectedCounties] = useState([]);
  const [selectedTownships, setSelectedTownships] = useState([]);
  const [selectedRanges, setSelectedRanges] = useState([]);
  const [selectedBlocks, setSelectedBlocks] = useState([]);
  const [selectedSections, setSelectedSections] = useState([]);
  const [selectedAbstracts, setSelectedAbstracts] = useState([]);

  useEffect(() => {
    resetFields([
      "counties",
      "townships",
      "ranges",
      "blocks",
      "sections",
      "abstracts",
    ]);
    setOpenField("State");
  }, [selectedStates, setOpenField]);

  useEffect(() => {
    resetFields(["townships", "ranges", "blocks", "sections", "abstracts"]);
    setOpenField("County");
  }, [selectedCounties, setOpenField]);

  useEffect(() => {
    resetFields(["sections", "abstracts"]);
    setOpenField(null);
  }, [selectedTownships, selectedRanges, selectedBlocks, setOpenField]);

  useEffect(() => {
    resetFields(["abstracts"]);
    setOpenField(null);
  }, [selectedSections, setOpenField]);

  const onFinish = (values) => {
    setLoading(true);
    // console.log(values);
    apiBase
      .post(`enverus-grid-search`, {
        // ...values,
        states: selectedStates.map((state) => state.value),
        counties: selectedCounties.map((county) => county.value),
        townships: selectedTownships.map((township) => township.value),
        ranges: selectedRanges.map((range) => range.value),
        blocks: selectedBlocks.map((block) => block.value),
        sections: selectedSections.map((section) => section.value),
        abstracts: selectedAbstracts.map((abstract) => abstract.value),
      })
      .then(async (res) => {
        const results = get(res, "data.results", []);
        const features = {
          type: "FeatureCollection",
          features: results.map((result) => {
            const geomPolygon = get(result, "geom_polygon", {});
            return {
              type: "Feature",
              geometry: geomPolygon,
              properties: {
                id: result.id,
              },
            };
          }),
        };

        // Draw polygon on map
        if (mapRef.current.getSource(LAYER_SOURCE_ID)) {
          mapRef.current.removeLayer(LAYER_FILL_ID);
          mapRef.current.removeLayer(LAYER_OUTLINE_ID);
          mapRef.current.removeSource(LAYER_SOURCE_ID);
        }

        mapRef.current.addSource(LAYER_SOURCE_ID, {
          type: "geojson",
          data: features,
          tolerance: 3,
          buffer: 512,
        });

        mapRef.current.addLayer({
          id: LAYER_FILL_ID,
          type: "fill",
          source: LAYER_SOURCE_ID,
          layout: {},
          paint: {
            "fill-color": "#005AF6",
            "fill-opacity": 0.1,
          },
        });

        mapRef.current.addLayer({
          id: LAYER_OUTLINE_ID,
          type: "line",
          source: LAYER_SOURCE_ID,
          layout: {},
          paint: {
            "line-color": "#005AF6",
            "line-width": 2,
          },
        });

        // Fit bounds to polygon
        fitMapToBounds(mapRef, features);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const resetFields = (fieldList) => {
    if (fieldList.includes("states")) {
      setSelectedStates([INITIAL_STATE]);
    }
    if (fieldList.includes("counties")) {
      setSelectedCounties([]);
    }
    if (fieldList.includes("townships")) {
      setSelectedTownships([]);
    }
    if (fieldList.includes("ranges")) {
      setSelectedRanges([]);
    }
    if (fieldList.includes("blocks")) {
      setSelectedBlocks([]);
    }
    if (fieldList.includes("sections")) {
      setSelectedSections([]);
    }
    if (fieldList.includes("abstracts")) {
      setSelectedAbstracts([]);
    }
  };

  const resetForm = () => {
    form.resetFields();
    if (mapRef.current.getSource(LAYER_SOURCE_ID)) {
      mapRef.current.removeLayer(LAYER_FILL_ID);
      mapRef.current.removeLayer(LAYER_OUTLINE_ID);
      mapRef.current.removeSource(LAYER_SOURCE_ID);
    }

    setOpenField(null);
    resetFields([
      "states",
      "counties",
      "townships",
      "ranges",
      "blocks",
      "sections",
      "abstracts",
    ]);
  };

  return (
    <div
      className={clsx(styles.control, {
        [styles.invisible]: controlVisible !== CONTROL_IDS.GRID_SEARCH,
      })}
    >
      <div className={styles.controlHeader}>
        <div></div>
        <div>Grid Search</div>
        <div className={styles.controlCollapseButton}>
          <ArrowLeftOutlined onClick={() => setControlVisible(null)} />
        </div>
      </div>
      <div className={styles.controlBody}>
        <Form
          name="gridSearch"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          disabled={loading}
          className={styles.compactForm}
        >
          <div className={styles.ctaContainer}>
            <Button
              size="small"
              type="primary"
              className={styles.resetButton}
              onClick={resetForm}
            >
              Reset
            </Button>
            <Button
              size="small"
              type="primary"
              className={styles.applyButton}
              onClick={() => form.submit()}
            >
              Apply
            </Button>
          </div>
          <MapFilter
            title="State"
            searchUri="enverus-grid-states/search"
            searchField="name"
            selectedItems={selectedStates}
            onSelectionChange={setSelectedStates}
            counts={false}
          />
          {get(selectedStates, "[0].value", "") === "Texas" ? (
            <>
              <MapFilter
                title="County"
                searchUri="enverus-grid-counties/search"
                searchField="name"
                selectedItems={selectedCounties}
                onSelectionChange={setSelectedCounties}
                otherFilters={{
                  states: selectedStates.map((state) => state.value),
                }}
                disabled={selectedStates.length === 0}
                counts={false}
              />
              <MapFilter
                title="Block"
                searchUri="enverus-grid-townships/search"
                searchField="block"
                selectedItems={selectedBlocks}
                onSelectionChange={setSelectedBlocks}
                otherFilters={{
                  states: selectedStates.map((state) => state.value),
                  counties: selectedCounties.map((county) => county.value),
                }}
                disabled={selectedCounties.length === 0}
              />
              <MapFilter
                title="Section"
                searchUri="enverus-grid-sections/search"
                searchField="section"
                selectedItems={selectedSections}
                onSelectionChange={setSelectedSections}
                otherFilters={{
                  states: selectedStates.map((state) => state.value),
                  counties: selectedCounties.map((county) => county.value),
                  blocks: selectedBlocks.map((block) => block.value),
                }}
                disabled={selectedCounties.length === 0}
              />
              <MapFilter
                title="Abstract"
                searchUri="enverus-grid-sections/search"
                searchField="abstract"
                selectedItems={selectedAbstracts}
                onSelectionChange={setSelectedAbstracts}
                otherFilters={{
                  states: selectedStates.map((state) => state.value),
                  counties: selectedCounties.map((county) => county.value),
                  blocks: selectedBlocks.map((block) => block.value),
                  sections: selectedSections.map((section) => section.value),
                }}
                disabled={selectedCounties.length === 0}
              />
            </>
          ) : (
            <>
              <MapFilter
                title="Township"
                searchUri="enverus-grid-townships/search"
                searchField="township"
                selectedItems={selectedTownships}
                onSelectionChange={setSelectedTownships}
                otherFilters={{
                  states: selectedStates.map((state) => state.value),
                  ranges: selectedRanges.map((range) => range.value),
                }}
                disabled={selectedStates.length === 0}
              />
              <MapFilter
                title="Range"
                searchUri="enverus-grid-townships/search"
                searchField="range"
                selectedItems={selectedRanges}
                onSelectionChange={setSelectedRanges}
                otherFilters={{
                  states: selectedStates.map((state) => state.value),
                  townships: selectedTownships.map(
                    (township) => township.value
                  ),
                }}
                disabled={selectedStates.length === 0}
              />
              <MapFilter
                title="Section"
                searchUri="enverus-grid-sections/search"
                searchField="section"
                selectedItems={selectedSections}
                onSelectionChange={setSelectedSections}
                otherFilters={{
                  states: selectedStates.map((state) => state.value),
                  ranges: selectedRanges.map((range) => range.value),
                  townships: selectedTownships.map(
                    (township) => township.value
                  ),
                }}
                disabled={
                  selectedTownships.length === 0 && selectedRanges.length === 0
                }
              />
            </>
          )}
        </Form>
      </div>
    </div>
  );
};

export default GridSearch;
