import React, { useState, useEffect, useCallback } from "react";

import { apiBase } from "../../utils/apiBase";
import { withParams } from "../../utils/withParams";
import { Helmet } from "react-helmet";
import { get } from "lodash";
import { clsx } from "clsx";

import { Row, Col, Table } from "antd";
import { Link } from "react-router-dom";

import { NTFIcon } from "../common/LabelIcon";
import TotalRecords from "../search/TotalRecords";
import IDIPersonCard from "./IDIPersonCard";

import { message } from "antd";

import styles from "./IDIPerson.module.scss";

const IDIPerson = (props) => {
  const [loading, setLoading] = useState(false);
  const [idiPersonOwners, setIDIPersonOwners] = useState([]);

  const getIDIPersonOwners = useCallback(async () => {
    try {
      setLoading(true);
      const res = await apiBase.get(`/idi-persons/${props.params.id}/owners`);
      setIDIPersonOwners(get(res, "data.owners", []));
    } catch (err) {
      let msg = `${err.response.status}: Failed to load IDI person ${props.params.id}`;
      message.error(msg);
    } finally {
      setLoading(false);
    }
  }, [props.params.id]);

  useEffect(() => {
    getIDIPersonOwners();
  }, [getIDIPersonOwners]);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (value) => <Link to={`/owners/${value}`}>{value}</Link>,
    },
    {
      title: "Name formatted",
      dataIndex: "name_formatted",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Address",
      dataIndex: "address",
    },
    {
      title: "City",
      dataIndex: "city",
    },
    {
      title: "State",
      dataIndex: "state",
    },
    {
      title: "Zip code",
      dataIndex: "zip_code",
    },
    {
      title: "NTF",
      dataIndex: "ntf",
      render: (value) => <NTFIcon value={value} />,
    },
  ];

  return (
    <div className="App__rowContent">
      <Helmet>
        <title>{`IDIPerson: ${props.params.id}`}</title>
      </Helmet>
      <Row>
        <Col span={24}>
          <div
            className={clsx({
              [styles.loading]: loading,
            })}
          >
            <IDIPersonCard idiPersonId={props.params.id} isRecord={true} />
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: "1em" }}>
        <Col span={24}>
          <div className={`${styles.card} ${styles.owners}`}>
            <div className={styles.cardTitle}>
              <div className={styles.leftTitle}>
                <h2>Owners</h2>
              </div>
              <div className={styles.rightTitle}>
                <TotalRecords
                  title={"Owners"}
                  totalRecords={idiPersonOwners.length}
                />
              </div>
            </div>
            <div className={styles.cardBody}>
              <Table
                columns={columns}
                dataSource={idiPersonOwners}
                pagination={false}
                className="App__smallTables"
                showSorterTooltip={false}
                loading={loading}
                scroll={{ x: "100%" }}
                bordered
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withParams(IDIPerson);
