import React from "react";

import { useMediaQuery } from "react-responsive";

function withMediaQuery(WrappedComponent) {
  return function (props) {
    /*
      should be in sync with $media-* variables in client/src/styles/variables.scss
    */
    const isSmallScreen = useMediaQuery({ query: "(max-width: 1920px)" });

    return <WrappedComponent isSmallScreen={isSmallScreen} {...props} />;
  };
}

export { withMediaQuery };
