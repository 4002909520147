import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { get, pick, isEmpty } from "lodash";
import clsx from "clsx";

import { apiBase } from "../../utils/apiBase";
import { withParams } from "../../utils/withParams";

import EntitySelect from "../common/EntitySelect";
import MappedIcon from "../common/MappedIcon";
import AppConfigSelect from "../common/AppConfigSelect";
import Files from "../common/Files";
import TractMap from "./TractMap";
import TractAssets from "./TractAssets";
import TractAssetLinkForm from "../common/TractAssetLinkForm";

import {
  Row,
  Col,
  Button,
  Input,
  Form,
  Modal,
  InputNumber,
  message,
} from "antd";

import { ExclamationCircleFilled } from "@ant-design/icons";

import styles from "./Tract.module.scss";

const { confirm } = Modal;

const labelProps = {
  labelAlign: "left",
  labelCol: {
    xxl: { span: 7, offset: 0 },
    xl: { span: 9 },
    lg: { span: 10 },
  },
};

const formFields = [
  "entity_id",
  "name",
  "county",
  "state",
  "acres",
  "decimal_interest",
  "nra",
];

const Tract = (props) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [tract, setTract] = useState({});
  const [tractAssets, setTractAssets] = useState([]);

  const getTract = useCallback(async () => {
    try {
      setLoading(true);
      const res = await apiBase.get(`/tracts/${props.params.id}`);
      setTract(get(res, "data", {}));
    } catch (err) {
      let msg = `${err.response.status}: Failed to load tract ${props.params.id}`;
      message.error(msg);
    } finally {
      setLoading(false);
    }
  }, [props.params.id]);

  const getTractAssets = useCallback(async () => {
    try {
      setLoading(true);
      const res = await apiBase.get(`/tracts/${props.params.id}/assets`);
      setTractAssets(get(res, "data", {}));
    } catch (err) {
      let msg = `${err.response.status}: Failed to load tract assets ${props.params.id}`;
      message.error(msg);
    } finally {
      setLoading(false);
    }
  }, [props.params.id]);

  useEffect(() => {
    getTract();
    getTractAssets();
  }, [getTract, getTractAssets]);

  useEffect(() => {
    if (!isEmpty(tract)) {
      form.setFieldsValue({
        ...pick(tract, formFields),
      });
    }
  }, [form, tract]);

  const updateSubmit = (values) => {
    setLoading(true);
    apiBase
      .patch(
        `tracts/${tract.id}`,
        values
        // omit(values, ["decimal_interest", "acres", "nra"])
      )
      .then((res) => {
        setTract(res.data);
        let msg = `${res.status}: Successfully updated tract.`;
        message.success(msg);
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
        form.resetFields();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteTract = async () => {
    setLoading(true);
    apiBase
      .delete(`/tracts/${tract.id}`)
      .then((res) => {
        setLoading(false);
        props.history("/portfolio/tracts?limit=50&page=1");
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to delete tract.`;
        message.error(msg);
        setLoading(false);
      });
  };

  const handleDelete = () => {
    confirm({
      title: "Are you sure you want to delete this tract?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      okButtonProps: { disabled: loading, loading: loading },
      cancelButtonProps: { disabled: loading, loading: loading },
      onOk: () => deleteTract(),
    });
  };

  const onFinishFailed = () => {
    message.error("Form fields reqired");
  };

  const onFormChange = (changedValues, allValues) => {
    const { acres, decimal_interest } = allValues;

    if (acres && decimal_interest) {
      form.setFieldsValue({
        nra: acres * decimal_interest * 8,
      });
    }
  };

  return (
    <div className="App__rowContentDetail">
      <Helmet>
        <title>{`Tract: ${tract.name}`}</title>
      </Helmet>
      <Row>
        <Col span={12} xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
          <div className={styles.card}>
            <div className={styles.cardTitle}>
              <div className={styles.leftTitle}>
                <h2>Tract</h2>
                {tract.geom_polygon && <MappedIcon />}
              </div>
              <div className={styles.rightTitle}>
                <div>
                  <Button
                    onClick={() => handleDelete()}
                    className={styles.cardHeaderAction}
                    disabled={loading}
                    loading={loading}
                  >
                    Delete
                  </Button>
                  <Button
                    onClick={() => form.resetFields()}
                    className={styles.cardHeaderAction}
                    disabled={loading}
                  >
                    Reset
                  </Button>
                  <Button
                    className={styles.cardHeaderAction}
                    type="primary"
                    loading={loading}
                    htmlType="submit"
                    onClick={() => {
                      form.submit();
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
            <div className={styles.cardBody}>
              <Row>
                <Col span={24}>
                  {!isEmpty(tract) && (
                    <div className={styles.compactForm}>
                      <Form
                        name="basic"
                        layout="horizontal"
                        form={form}
                        initialValues={{
                          ...pick(tract, formFields),
                        }}
                        onFinish={updateSubmit}
                        onFinishFailed={onFinishFailed}
                        onValuesChange={onFormChange}
                        autoComplete="off"
                        disabled={loading}
                      >
                        <Row gutter={8}>
                          <Col
                            span={12}
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                          >
                            <Form.Item
                              label="Entity"
                              name="entity_id"
                              rules={[{ required: true, message: "" }]}
                              {...labelProps}
                            >
                              <EntitySelect size="small" />
                            </Form.Item>
                            <Form.Item
                              label="Name"
                              name="name"
                              rules={[{ required: true, message: "" }]}
                              {...labelProps}
                            >
                              <Input
                                onPressEnter={() => form.submit()}
                                size="small"
                              />
                            </Form.Item>
                            <Form.Item
                              label="State"
                              name="state"
                              {...labelProps}
                            >
                              <AppConfigSelect
                                appConfigKey="states"
                                showSearch
                                allowClear
                                size="small"
                              />
                            </Form.Item>
                            <Form.Item
                              label="County"
                              name="county"
                              {...labelProps}
                            >
                              <AppConfigSelect
                                appConfigKey="counties"
                                showSearch
                                allowClear
                                size="small"
                              />
                            </Form.Item>
                            <Form.Item
                              label="Acres"
                              name="acres"
                              {...labelProps}
                            >
                              <InputNumber
                                className="App__fullWidth"
                                size="small"
                              />
                            </Form.Item>
                            <Form.Item
                              label="Decimal Interest"
                              name="decimal_interest"
                              {...labelProps}
                            >
                              <InputNumber
                                className="App__fullWidth"
                                precision={8}
                                placeholder="0.00"
                                size="small"
                              />
                            </Form.Item>
                            <Form.Item label="NRA" name="nra" {...labelProps}>
                              <InputNumber
                                disabled
                                className={clsx(
                                  "App__fullWidth",
                                  styles.disabledItem
                                )}
                                size="small"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.cardTitle}>
              <div className={styles.leftTitle}>
                <h2>Assets</h2>
              </div>
              <div className={styles.rightTitle}>
                <div>
                  <TractAssetLinkForm
                    tract={tract}
                    getTractAssets={getTractAssets}
                  />
                </div>
              </div>
            </div>
            <div className={styles.cardBody}>
              <Row>
                <Col span={24}>
                  <TractAssets
                    tractAssets={tractAssets}
                    getTractAssets={getTractAssets}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col span={12} xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
          <Row>
            <Col span={24}>
              <TractMap
                tract={tract}
                assets={tractAssets.map((tractAsset) => tractAsset.asset)}
                getTract={getTract}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Files recordId={tract.id} recordType="tract" />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default withParams(Tract);
