import React, { useState } from "react";
import { withParams } from "../utils/withParams";
import { apiBase } from "../utils/apiBase";
import { get } from "lodash";
import clsx from "clsx";

import { Row, Col, Button, Input, Form, Table, Checkbox, message } from "antd";

import RawDataModal from "../views/common/RawDataModal";

import styles from "./Phones.module.scss";

const Phones = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [displayAll, setDisplayAll] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    apiBase
      .post(`phones/bulk-validate`, {
        ...values,
      })
      .then(async (res) => {
        setResults(get(res, "data", []));
        message.success("Successfully bulk validated phones.");
      })
      .catch((err) => {
        console.log(err);
        let msg = `${err.response.status}: Failed to bulk validate phones.`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
        form.resetFields();
      });
  };

  const onFinishFailed = () => {
    message.error("Form fields reqired");
  };

  const columns = [
    {
      title: "Input",
      dataIndex: "raw",
      key: "raw",
      extra: true,
    },
    {
      title: "International",
      dataIndex: "international",
      key: "international",
    },
    {
      title: "National",
      dataIndex: "national",
      key: "national",
    },
    {
      title: "Valid",
      dataIndex: ["vendor_data", "response_body", "valid"],
      key: "valid",
      render: (value) => (value ? "Yes" : "No"),
    },
    {
      title: "Caller name",
      dataIndex: ["vendor_data", "response_body", "caller_name", "caller_name"],
      key: "caller_name",
    },
    {
      title: "Caller type",
      dataIndex: ["vendor_data", "response_body", "caller_name", "caller_type"],
      key: "caller_type",
    },
    {
      title: "Line type",
      dataIndex: [
        "vendor_data",
        "response_body",
        "line_type_intelligence",
        "type",
      ],
      key: "type",
    },
    {
      title: "Carrier",
      dataIndex: [
        "vendor_data",
        "response_body",
        "line_type_intelligence",
        "carrier_name",
      ],
      key: "carrier_name",
    },
    {
      title: "Line status",
      dataIndex: ["vendor_data", "response_body", "line_status", "status"],
      key: "status",
      extra: true,
    },
    {
      title: "Quality score",
      dataIndex: [
        "vendor_data",
        "response_body",
        "phone_number_quality_score",
        "quality_score",
      ],
      key: "quality_score",
      extra: true,
    },
    {
      title: "Raw data",
      dataIndex: "raw_data",
      key: "raw_data",
      render: (value, record) => (
        <RawDataModal modalProps={{ title: "Twilio data" }} data={record} />
      ),
      extra: true,
    },
  ];

  const displayColumns = columns.filter(
    (column) => (column.extra && displayAll) || !column.extra
  );

  return (
    <div className="App__rowContentDetail">
      <Row gutter={16}>
        <Col span={24}>
          <div className={styles.card}>
            <div className={styles.cardTitle}>
              <div className={styles.leftTitle}>
                <h2>Phones</h2>
              </div>
              <div className={styles.rightTitle}>
                <div>
                  <Button
                    className={styles.cardHeaderAction}
                    type="primary"
                    loading={loading}
                    htmlType="submit"
                    onClick={() => {
                      form.submit();
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
            <div className={styles.cardBody}>
              <Row gutter={24}>
                <Col span={24}>
                  <Form
                    name="basic"
                    layout="vertical"
                    form={form}
                    initialValues={{}}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    disabled={loading}
                  >
                    <Form.Item
                      label="Data"
                      name="data"
                      rules={[
                        {
                          required: true,
                          message: "Data is required.",
                        },
                      ]}
                    >
                      <Input.TextArea className="App__fullWidth" rows={10} />
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <div className={styles.card}>
            <div className={styles.cardTitle}>
              <div className={styles.leftTitle}>
                <h2>Results</h2>
              </div>
              <div className={styles.rightTitle}>
                <Checkbox
                  onChange={(checkedValue) =>
                    setDisplayAll(checkedValue.target.checked)
                  }
                >
                  Column extras
                </Checkbox>
              </div>
            </div>
            <div className={styles.cardBody}>
              <div className={styles.tableWidth}>
                <Table
                  className={clsx({
                    [styles.table]: !displayAll,
                  })}
                  columns={displayColumns}
                  dataSource={results}
                  pagination={false}
                  size="small"
                  bordered
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withParams(Phones);
