import React, { useState, forwardRef } from "react";
import clsx from "clsx";

import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

import styles from "./BaseControls.module.scss";

import ZoomPosition from "./common/ZoomPosition";
import GridPosition from "./common/GridPosition";

import Layers from "./controls/layers/Layers";

import GridSearch from "./controls/GridSearch";
import MapStyles from "./controls/MapStyles";

import { MapFilterProvider } from "./common/MapFilterContext";

import { INITIAL_CENTER, INITIAL_ZOOM, CONTROL_IDS } from "./constants";

import {
  FilterOutlined,
  FormatPainterOutlined,
  TableOutlined,
} from "@ant-design/icons";

const BaseControls = forwardRef(
  (
    {
      initialZoom = INITIAL_ZOOM,
      initialCenter = INITIAL_CENTER,
      initialShowFilters = false,
      initialShowMapStyles = false,
      initialShowSatellite = false,
      children,
    },
    mapRef
  ) => {
    const [controlVisible, setControlVisible] = useState(null);

    // if (mapRef.current) {
    //   try {
    //     const layers = mapRef.current.getStyle().layers;
    //     const sources = mapRef.current.getStyle().sources;

    //     console.log("LAYERS", layers);
    //     console.log("SOURCES", sources);
    //   } catch (e) {
    //     console.log(e);
    //   }
    // }

    return (
      <div className={styles.mapTools}>
        <ZoomPosition
          mapRef={mapRef}
          initialCenter={initialCenter}
          initialZoom={initialZoom}
        />
        <GridPosition mapRef={mapRef} />
        <div className={styles.controlBar}>
          <div
            className={styles.controlBarButton}
            onClick={() => {
              if (controlVisible === CONTROL_IDS.FILTERS) {
                setControlVisible(null);
              } else {
                setControlVisible(CONTROL_IDS.FILTERS);
              }
            }}
          >
            <FilterOutlined
              className={clsx({
                [styles.controlBarButtonActive]:
                  controlVisible === CONTROL_IDS.FILTERS,
              })}
            />
          </div>
          <div
            className={styles.controlBarButton}
            onClick={() => {
              if (controlVisible === CONTROL_IDS.GRID_SEARCH) {
                setControlVisible(null);
              } else {
                setControlVisible(CONTROL_IDS.GRID_SEARCH);
              }
            }}
          >
            <TableOutlined
              className={clsx({
                [styles.controlBarButtonActive]:
                  controlVisible === CONTROL_IDS.GRID_SEARCH,
              })}
            />
          </div>
          <div
            className={styles.controlBarButton}
            onClick={() => {
              if (controlVisible === CONTROL_IDS.MAP_STYLES) {
                setControlVisible(null);
              } else {
                setControlVisible(CONTROL_IDS.MAP_STYLES);
              }
            }}
          >
            <FormatPainterOutlined
              className={clsx({
                [styles.controlBarButtonActive]:
                  controlVisible === CONTROL_IDS.MAP_STYLES,
              })}
            />
          </div>
        </div>

        <div className={styles.controlBody}>
          <Layers
            mapRef={mapRef}
            controlVisible={controlVisible}
            setControlVisible={setControlVisible}
          />
          <MapFilterProvider>
            <GridSearch
              mapRef={mapRef}
              controlVisible={controlVisible}
              setControlVisible={setControlVisible}
            />
          </MapFilterProvider>
          <MapStyles
            mapRef={mapRef}
            controlVisible={controlVisible}
            setControlVisible={setControlVisible}
          />
        </div>
        {children}
      </div>
    );
  }
);

export default BaseControls;
