import { message } from "antd";
import { apiBase } from "../utils/apiBase";
import jwt_decode from "jwt-decode";
import { get } from "lodash";
import * as Sentry from "@sentry/react";

export const types = {
  USER_SET: "USER_SET",
};

export const setUser = (value) => ({
  type: types.USER_SET,
  value,
});

export const authenticateUser = (values) => (dispatch) => {
  return apiBase
    .post(`users/authenticate`, values)
    .then((res) => {
      const { auth_token } = res.data;
      window.localStorage.setItem("authToken", auth_token);
      apiBase.defaults.headers.Authorization = `Bearer ${auth_token}`;
      return true;
    })
    .catch((err) => {
      message.error(
        get(err.response, "data.detail", "Failed to authenticate user.")
      );
      return false;
    });
};

export const getUser = () => (dispatch) => {
  const authToken = window.localStorage.getItem("authToken");
  if (!authToken) {
    return dispatch(setUser({}));
  }
  const tokenDecoded = jwt_decode(authToken);
  const userId = get(tokenDecoded, "user_id");
  return apiBase
    .get(`users/${userId}`)
    .then((res) => {
      dispatch(setUser(res.data));
      Sentry.setUser({
        id: get(res, "data.id", null),
        email: get(res, "data.email", null),
        username: get(res, "data.alias", null),
      });
      message.success("Logged In.");
      return res.data;
    })
    .catch((err) => {
      message.error(get(err.response, "data.detail", "Failed to fetch user."));
      window.localStorage.clear();
      Sentry.setUser(null);
      return null;
    });
};

export const logoutUser = () => (dispatch) => {
  window.localStorage.clear();
  apiBase.defaults.headers.Authorization = null;
  dispatch(setUser({}));
  Sentry.setUser(null);
  message.success("Logged Out.");
};

export const initialState = {
  data: {},
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case types.USER_SET:
      return {
        data: action.value,
      };
    default:
      return state;
  }
}

const actions = {
  authenticateUser,
  getUser,
  logoutUser,
};

export default actions;
