import React, { forwardRef, useEffect, useRef } from "react";
import clsx from "clsx";

import Quill from "quill";
// import Toolbar from "quill/modules/toolbar";
// import Snow from "quill/themes/snow";

// import Bold from "quill/formats/bold";
// import Italic from "quill/formats/italic";
// import Underline from "quill/formats/underline";
// import Header from "quill/formats/header";

// Quill.register({
//   "modules/toolbar": Toolbar,
//   "themes/snow": Snow,
//   "formats/bold": Bold,
//   "formats/italic": Italic,
//   "formats/underline": Underline,
//   "formats/header": Header,
// });

import styles from "./QuillEditor.module.scss";

const Delta = Quill.import("delta");

// Editor is an uncontrolled React component
const QuillEditor = forwardRef(
  ({ defaultValue, isEdit, newNote, isExpanded }, ref) => {
    const containerRef = useRef(null);
    const defaultValueRef = useRef(new Delta(defaultValue));

    useEffect(() => {
      const container = containerRef.current;
      const editorContainer = container.appendChild(
        container.ownerDocument.createElement("div")
      );

      const toolBar =
        isEdit || newNote
          ? [
              [{ size: [] }],
              ["bold", "italic", "underline", "strike", "blockquote"],
              [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
                { align: [] },
              ],
              [{ color: [] }, { background: [] }],
              // [{ script: "super" }, { script: "sub" }],
              // [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
              // ["direction", { align: [] }],
              // ["link", "image", "video", "formula"],
              ["link", "image"],
              ["clean"],
            ]
          : null;

      const quill = new Quill(editorContainer, {
        theme: "snow",
        modules: {
          toolbar: toolBar,
        },
      });

      ref.current = quill;

      if (defaultValueRef.current) {
        quill.setContents(defaultValueRef.current);
      }

      quill.enable(isEdit || !!newNote);

      return () => {
        ref.current = null;
        container.innerHTML = "";
      };
    }, [ref, isEdit, newNote]);

    return (
      <div
        ref={containerRef}
        className={clsx(styles.editor, {
          [styles.notEditorExpanded]: !isExpanded && !newNote && !isEdit,
          [styles.editorEdit]: isEdit || newNote,
        })}
      ></div>
    );
  }
);

QuillEditor.displayName = "Editor";

export default QuillEditor;
