import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { get, isEmpty, find } from "lodash";
import { guid } from "../../utils/numbers";
import { LabelIcon } from "./LabelIcon";
import { Dropdown, Menu, Checkbox, message } from "antd";
import { apiBase } from "../../utils/apiBase";

import { TagOutlined } from "@ant-design/icons";

import styles from "./RecordLabelsEdit.module.scss";

function RecordLabelsEdit({ record_type, record_id, label_ids = [] }) {
  const labels = useSelector((state) => get(state.labels, "data", []));
  const [selectedLabelIds, setSelectedLabelIds] = useState(label_ids || []);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setSelectedLabelIds(label_ids || []);
  }, [label_ids]);

  const handleLabelToggle = async (labelId, checked) => {
    setLoading(true);
    try {
      if (checked) {
        // Add label
        await apiBase.post(`/labels/add`, {
          label_id: labelId,
          record_type: record_type,
          record_id: record_id,
        });
        setSelectedLabelIds([...selectedLabelIds, labelId]);
      } else {
        // Remove label
        await apiBase.post(`/labels/delete`, {
          label_id: labelId,
          record_type: record_type,
          record_id: record_id,
        });
        setSelectedLabelIds(selectedLabelIds.filter((id) => id !== labelId));
      }
    } catch (error) {
      message.error(`Failed to update label: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Filter labels by record_type and sort by order
  const relevantLabels = labels
    .filter((label) => label.record_type === record_type)
    .sort((a, b) => a.order - b.order);

  // Find the labels that match the provided label_ids
  const matchedLabels = selectedLabelIds
    .map((id) => find(relevantLabels, (label) => label.id === id))
    .sort((a, b) => a.order - b.order);

  const menu = (
    <Menu>
      {relevantLabels.map((label) => (
        <Menu.Item
          key={label.id}
          onClick={() => {
            const isCurrentlyChecked = selectedLabelIds.includes(label.id);
            handleLabelToggle(label.id, !isCurrentlyChecked);
          }}
        >
          <Checkbox
            checked={selectedLabelIds.includes(label.id)}
            disabled={loading}
          >
            <LabelIcon
              content={label.value || "Empty"}
              color={label.color || "white"}
              backgroundColor={label.background_color || "gray"}
            />
          </Checkbox>
        </Menu.Item>
      ))}
      {isEmpty(relevantLabels) && (
        <Menu.Item disabled>No labels available</Menu.Item>
      )}
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      className={styles.main}
      open={open}
      onOpenChange={setOpen}
    >
      <div style={{ cursor: "pointer" }} onClick={(e) => e.preventDefault()}>
        <TagOutlined />
        {!isEmpty(matchedLabels) ? (
          matchedLabels.map((labelObj) => {
            const content = get(labelObj, "value", "Empty");
            const color = get(labelObj, "color", "white");
            const backgroundColor = get(labelObj, "background_color", "gray");

            return (
              <div key={guid()} className={styles.label}>
                <LabelIcon
                  content={content}
                  color={color}
                  backgroundColor={backgroundColor}
                />
              </div>
            );
          })
        ) : (
          <div className={styles.emptyLabels}>
            <span>Add labels</span>
          </div>
        )}
      </div>
    </Dropdown>
  );
}

export default RecordLabelsEdit;
