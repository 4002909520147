import React, { useState, useEffect, useCallback } from "react";
import { apiBase } from "../../utils/apiBase";
import { withParams } from "../../utils/withParams";
import { get, pick, isEmpty } from "lodash";
import { clsx } from "clsx";
import ReactJson from "react-json-view";

import EntityQuickbooksAccountsForm from "./EntityQuickbooksAccountsForm";

import {
  Row,
  Col,
  Button,
  Typography,
  Input,
  Form,
  Modal,
  Tooltip,
  message,
} from "antd";

import { ExclamationCircleFilled, DatabaseOutlined } from "@ant-design/icons";

import styles from "./Entity.module.scss";

const { Text } = Typography;

const labelProps = {
  labelAlign: "left",
  labelCol: {
    xxl: { span: 7, offset: 0 },
    xl: { span: 9 },
    lg: { span: 10 },
  },
};

const { confirm } = Modal;

const formFields = [
  "name",
  "name_legal",
  "address",
  "city",
  "state",
  "zip_code",
];

const EntityUpdateForm = (props) => {
  const onFinish = (values) => {
    props.updateSubmit(values);
  };

  const onFinishFailed = () => {
    message.error("Form fields reqired");
  };

  const entity = props.entity || {};
  const { form, loading } = props;

  return (
    <div className={styles.compactForm}>
      <Form
        name="basic"
        layout="horizontal"
        form={form}
        initialValues={{
          ...pick(entity, formFields),
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        disabled={loading}
      >
        <Row gutter={16}>
          <Col span={12} xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
            {[
              {
                name: "name",
                label: "Name",
                required: true,
              },
              {
                name: "name_legal",
                label: "Name legal",
                required: false,
              },
            ].map((item, index) => (
              <Form.Item
                key={index}
                label={item.label}
                name={item.name}
                rules={[{ required: item.required, message: "" }]}
                {...labelProps}
              >
                <Input
                  disabled={item.disabled}
                  onPressEnter={() => form.submit()}
                  className={clsx({
                    [styles.disabledItem]: item.disabled,
                  })}
                  size="small"
                />
              </Form.Item>
            ))}
          </Col>
          <Col span={12} xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
            {[
              {
                name: "address",
                label: "Address",
                required: false,
              },
              {
                name: "city",
                label: "City",
                required: false,
              },
              {
                name: "state",
                label: "State",
                required: false,
              },
              {
                name: "zip_code",
                label: "Zip code",
                required: false,
              },
            ].map((item, index) => (
              <Form.Item
                key={index}
                label={item.label}
                name={item.name}
                rules={[{ required: item.required, message: "" }]}
                {...labelProps}
              >
                <Input
                  disabled={item.disabled}
                  onPressEnter={() => form.submit()}
                  className={clsx({
                    [styles.disabledItem]: item.disabled,
                  })}
                  size="small"
                />
              </Form.Item>
            ))}
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const Entity = (props) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [entity, setEntity] = useState({});
  const [connectionUrl, setConnectionUrl] = useState(null);

  const getEntity = useCallback(async () => {
    try {
      setLoading(true);
      const res = await apiBase.get(`/entities/${props.params.id}`);
      setEntity(get(res, "data", {}));
    } catch (err) {
      let msg = `${err.response.status}: Failed to load deal ${props.params.id}`;
      message.error(msg);
    } finally {
      setLoading(false);
    }
  }, [props.params.id]);

  useEffect(() => {
    getEntity();
  }, [getEntity]);

  useEffect(() => {
    const getQBOConnectionUrl = async () => {
      try {
        setLoading(true);
        const res = await apiBase.get(
          `/oauth/quickbooks/token?entity_id=${props.params.id}`
        );
        setConnectionUrl(get(res, "data.url", {}));
      } catch (err) {
        let msg = `${err.response.status}: Failed to get qbo connection url ${props.params.id}`;
        message.error(msg);
      } finally {
        setLoading(false);
      }
    };
    getQBOConnectionUrl();
  }, [props.params.id]);

  function updateSubmit(values) {
    setLoading(true);
    apiBase
      .patch(`entities/${entity.id}`, values)
      .then((res) => {
        setEntity(res.data);
        let msg = `${res.status}: Successfully updated entity.`;
        message.success(msg);
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
        form.resetFields();
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const disconnectQBO = async () => {
    try {
      setLoading(true);
      const res = await apiBase.get(
        `/oauth/quickbooks/disconnect?entity_id=${props.params.id}`
      );
      let msg = `${res.status}: Successfully disconnected quickbooks.`;
      message.success(msg);
    } catch (err) {
      let msg = `${err.response.status}: Failed to disconnect quickbooks.`;
      message.error(msg);
    } finally {
      getEntity();
    }
  };

  const handleDisconnectQBO = async () => {
    confirm({
      title: "Are you sure you want to disconnect quickbooks?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      okButtonProps: { disabled: loading },
      cancelButtonProps: { disabled: loading },
      onOk: () => disconnectQBO(),
    });
  };

  const renderQBOAccount = ({ title, account }) => {
    return (
      <div className={styles.objectData}>
        <span>{title}:</span>
        {account ? (
          <div>
            {get(account, "Name")}{" "}
            <span className={styles.idContainer}>
              {`[ID: ${get(account, "Id")}]`}
            </span>
            <span className="App__iconButton">
              <Tooltip
                overlayClassName="App__toolTipCard"
                placement="bottom"
                title={
                  <ReactJson
                    src={account || {}}
                    collapsed={true}
                    style={{
                      backgroundColor: "white",
                      width: "484px",
                      fontSize: "12px",
                    }}
                    displayDataTypes={false}
                  />
                }
              >
                <DatabaseOutlined />
              </Tooltip>
            </span>
          </div>
        ) : (
          <span>-</span>
        )}
      </div>
    );
  };

  return (
    <div className="App__rowContentDetail">
      <Row gutter={16}>
        <Col span={12}>
          <div className={styles.card}>
            <div className={styles.cardTitle}>
              <div className={styles.leftTitle}>
                <h2>Entity</h2>
              </div>
              <div className={styles.rightTitle}>
                <div>
                  <Button
                    onClick={() => form.resetFields()}
                    className={styles.cardHeaderAction}
                    disabled={loading}
                  >
                    Reset
                  </Button>
                  <Button
                    className={styles.cardHeaderAction}
                    type="primary"
                    loading={loading}
                    htmlType="submit"
                    onClick={() => {
                      form.submit();
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
            <div className={styles.cardBody}>
              <Row gutter={24}>
                <Col span={24}>
                  {!isEmpty(entity) && (
                    <EntityUpdateForm
                      form={form}
                      loading={loading}
                      entity={entity}
                      updateSubmit={updateSubmit}
                      history={props.history}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className={styles.card}>
            <div className={styles.cardTitle}>
              <div className={styles.leftTitle}>
                <h2>Quickbooks</h2>
              </div>
              <div className={styles.rightTitle}>
                {entity.quickbooks_realm_id ? (
                  <>
                    <div className={styles.cardHeaderAction}>
                      <EntityQuickbooksAccountsForm
                        entity={entity}
                        getEntity={getEntity}
                      />
                    </div>
                    <Button
                      onClick={handleDisconnectQBO}
                      className={styles.cardHeaderAction}
                      disabled={loading}
                      type="primary"
                      danger
                    >
                      Disconnect
                    </Button>
                  </>
                ) : (
                  <Button
                    onClick={() => window.open(connectionUrl, "_blank")}
                    className={styles.cardHeaderAction}
                    disabled={loading || !connectionUrl}
                    type="primary"
                  >
                    Connect
                  </Button>
                )}
              </div>
            </div>
            <div className={styles.cardBody}>
              <Row gutter={24}>
                <Col span={24}>
                  {entity.quickbooks_realm_id ? (
                    <>
                      <div className={styles.objectData}>
                        <span>Realm ID:</span>
                        <Text
                          code
                          ellipsis={{
                            tooltip: entity.quickbooks_realm_id,
                          }}
                        >
                          {entity.quickbooks_realm_id}
                        </Text>
                      </div>
                      {renderQBOAccount({
                        title: "Cost basis account",
                        account: get(entity, "quickbooks_cost_basis_account"),
                      })}
                      {renderQBOAccount({
                        title: "Distributions account",
                        account: get(
                          entity,
                          "quickbooks_distributions_account"
                        ),
                      })}
                      {renderQBOAccount({
                        title: "Distributions account (alternate)",
                        account: get(
                          entity,
                          "quickbooks_distributions_account_alternate"
                        ),
                      })}
                      {renderQBOAccount({
                        title: "Oil royalty revenue account",
                        account: get(
                          entity,
                          "quickbooks_oil_royalty_revenue_account"
                        ),
                      })}
                      {renderQBOAccount({
                        title: "Gas royalty revenue account",
                        account: get(
                          entity,
                          "quickbooks_gas_royalty_revenue_account"
                        ),
                      })}
                      {renderQBOAccount({
                        title: "Statutory interest account",
                        account: get(
                          entity,
                          "quickbooks_statutory_interest_account"
                        ),
                      })}
                    </>
                  ) : (
                    <div>
                      <p>No Quickbooks data available. Please connect</p>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withParams(Entity);
