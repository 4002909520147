import appConfig, {
  reducer as appConfigReducer,
  initialState as appConfigInitialState,
} from "./appConfig";

import user, {
  reducer as userReducer,
  initialState as userInitialState,
} from "./user";

import users, {
  reducer as usersReducer,
  initialState as usersInitialState,
} from "./users";

import entities, {
  reducer as entitiesReducer,
  initialState as entitiesInitialState,
} from "./entities";

import entity, {
  reducer as entityReducer,
  initialState as entityInitialState,
} from "./entity";

import boxAuth, {
  reducer as boxAuthReducer,
  initialState as boxAuthInitialState,
} from "./boxAuth";

import aggregates, {
  reducer as aggregatesReducer,
  initialState as aggregatesInitialState,
} from "./aggregates";

import map, {
  reducer as mapReducer,
  initialState as mapInitialState,
} from "./map";

import labels, {
  reducer as labelsReducer,
  initialState as labelsInitialState,
} from "./labels";

export const actions = {
  appConfig,
  user,
  users,
  entities,
  entity,
  boxAuth,
  aggregates,
  map,
  labels,
};

export const reducers = {
  user: userReducer,
  users: usersReducer,
  appConfig: appConfigReducer,
  entities: entitiesReducer,
  entity: entityReducer,
  boxAuth: boxAuthReducer,
  aggregates: aggregatesReducer,
  map: mapReducer,
  labels: labelsReducer,
};

export const initialState = {
  appConfig: appConfigInitialState,
  user: userInitialState,
  users: usersInitialState,
  entities: entitiesInitialState,
  entity: entityInitialState,
  boxAuth: boxAuthInitialState,
  aggregates: aggregatesInitialState,
  map: mapInitialState,
  labels: labelsInitialState,
};

export default actions;
