import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { apiBase } from "../../utils/apiBase";
import { clsx } from "clsx";

import AppConfigSelect from "../common/AppConfigSelect";

import { Drawer, Form, Input, Button, Space, message } from "antd";

import styles from "./WellEditForm.module.scss";

const WellEditForm = (props) => {
  const { well, isDrawerOpen, setIsDrawerOpen } = props;

  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    apiBase
      .patch(`wells/${well.id}`, {
        ...values,
      })
      .then(async (res) => {
        message.success("Successfully updated well.");
      })
      .catch((err) => {
        console.log(err);
        let msg = `${err.response.status}: Failed to create deal asset.`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
        resetComponent();
        setIsDrawerOpen(false);
        props.executeSearch();
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    resetComponent();
    setIsDrawerOpen(false);
  };

  const handleCancel = () => {
    setIsDrawerOpen(false);
    resetComponent();
  };

  const submitForm = () => {
    form
      .validateFields()
      .then(() => {
        form.submit();
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };

  const resetComponent = () => {
    form.resetFields();
  };

  const [form] = Form.useForm();

  useEffect(() => {
    if (!isEmpty(well)) {
      form.setFieldsValue({
        name: well.name,
        api_number: well.api_number,
        status: well.status,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [well]);

  return (
    <div>
      <Drawer
        title="Edit Well"
        width={720}
        onClose={handleCancel}
        visible={isDrawerOpen}
        extra={
          <Space>
            <Button onClick={handleCancel} loading={loading}>
              Cancel
            </Button>
            <Button type="primary" onClick={submitForm} loading={loading}>
              OK
            </Button>
          </Space>
        }
      >
        <Form
          name="dealAssetCreate"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
          disabled={loading}
        >
          {[
            {
              name: "name",
              label: "Name",
              required: true,
            },
            {
              name: "api_number",
              label: "API number",
              required: false,
            },
          ].map((item, index) => (
            <Form.Item
              key={index}
              label={item.label}
              name={item.name}
              rules={[{ required: item.required, message: "" }]}
            >
              <Input
                disabled={item.disabled}
                onPressEnter={() => form.submit()}
                className={clsx({
                  [styles.disabledItem]: item.disabled,
                })}
              />
            </Form.Item>
          ))}
          <Form.Item
            label="Status"
            name="status"
            rules={[{ required: true, message: "" }]}
          >
            <AppConfigSelect appConfigKey="well_status" />
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
};

export default WellEditForm;
