import React, { Component } from "react";
import { withParams } from "../../utils/withParams";
import { apiBase } from "../../utils/apiBase";
import { formatDate } from "../../utils/dates";
import { get, capitalize, cloneDeep } from "lodash";
import { uploadStatus } from "../../common/pipedriveUploads";
import ReactJson from "react-json-view";

import { Row, Col, message, Input, Button } from "antd";

import styles from "./PipedriveUploadDetail.module.scss";

class PipedriveUploadDetail extends Component {
  state = {
    isLoading: false,
    pipedriveUpload: {},
    isPipedriveUploadEdit: false,
    isLoadingPipedriveUploadEdit: false,
    pipedriveUploadCopy: {},
    isLoadingRetry: false,
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    await this.getPipedriveUpload();
    this.setState({ isLoading: false });
  }

  async getPipedriveUpload() {
    return apiBase
      .get(`/pipedrive-uploads/${this.props.params.id}`)
      .then((res) => {
        this.setState({
          pipedriveUpload: res.data,
        });
      })
      .catch((err) => {
        let msg = `${get(
          err,
          "response.status"
        )}: Error loading Pipedrive upload: ${this.props.params.id}`;
        message.error(msg);
      });
  }

  setPipedriveUploadEdit = () => {
    const { pipedriveUpload } = this.state;
    this.setState({
      isPipedriveUploadEdit: true,
      pipedriveUploadCopy: cloneDeep(pipedriveUpload),
    });
  };

  handlePipedriveUploadEditChange = (e) => {
    const { pipedriveUpload } = this.state;
    pipedriveUpload[e.target.name] = e.target.value;
    this.setState({ pipedriveUpload });
  };

  handlePipedriveUploadEditSubmit = () => {
    const { pipedriveUpload } = this.state;
    let pipedriveUploadEdit = {
      pipedrive_upload_id: parseInt(pipedriveUpload.id, 10),
      name: pipedriveUpload.name,
    };
    this.setState({ isLoadingPipedriveUploadEdit: true });
    apiBase
      .patch(`/pipedrive-uploads`, pipedriveUploadEdit)
      .then((res) => {
        this.setState({
          pipedriveUpload: res.data,
          isPipedriveUploadEdit: false,
          isLoadingPipedriveUploadEdit: false,
        });
        message.success("Successfully updated Pipedrive upload");
      })
      .catch((err) => {
        this.setState({
          isLoadingPipedriveUploadEdit: false,
        });
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
      });
  };

  cancelPipedriveUploadEdit = () => {
    const { pipedriveUploadCopy } = this.state;
    this.setState({
      isPipedriveUploadEdit: false,
      pipedriveUpload: pipedriveUploadCopy,
    });
  };

  handleRetry = () => {
    const { pipedriveUpload } = this.state;
    this.setState({ isLoadingRetry: true });
    apiBase
      .get(`/pipedrive-uploads/${pipedriveUpload.id}/retry`)
      .then((res) => {
        this.setState({
          pipedriveUpload: res.data,
          isLoadingRetry: false,
        });
        message.success("Successfully queued Pipedrive upload for retry");
      })
      .catch((err) => {
        this.setState({
          isLoadingRetry: false,
        });
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
      });
  };

  handleRowClick(e, _to) {
    e.stopPropagation();
    if (e.ctrlKey || e.metaKey) {
      window.open(_to, "_blank");
      return;
    }
    this.props.history(_to);
  }

  render() {
    const {
      pipedriveUpload,
      isLoading,
      isPipedriveUploadEdit,
      isLoadingPipedriveUploadEdit,
      isLoadingRetry,
    } = this.state;

    return (
      <div className="App__rowContentDetail">
        <Row>
          <Col span={24}>
            <div className={styles.card}>
              <div className={styles.cardTitle}>
                <div className={styles.leftTitle}>
                  <h2>Pipedrive Upload</h2>
                </div>
                <div className={styles.rightTitle}>
                  {!isPipedriveUploadEdit ? (
                    <div>
                      <Button
                        onClick={this.handleRetry}
                        className={styles.cardHeaderAction}
                        loading={isLoadingRetry}
                        disabled={
                          isLoadingRetry ||
                          isLoading ||
                          [uploadStatus.running, uploadStatus.queued].includes(
                            pipedriveUpload.status
                          )
                        }
                      >
                        Retry
                      </Button>
                      <Button
                        onClick={() => this.setPipedriveUploadEdit()}
                        className={styles.cardHeaderAction}
                        disabled={isLoadingRetry || isLoading}
                      >
                        Edit
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <Button
                        onClick={() => this.cancelPipedriveUploadEdit()}
                        className={styles.cardHeaderAction}
                        disabled={isLoadingPipedriveUploadEdit}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => this.handlePipedriveUploadEditSubmit()}
                        className={styles.cardHeaderAction}
                        type="primary"
                        loading={isLoadingPipedriveUploadEdit}
                      >
                        Save
                      </Button>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.cardBody}>
                <Row gutter={24}>
                  <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className={styles.objectData}>
                      <span>Id:</span>
                      {pipedriveUpload.id}
                    </div>
                    <div className={styles.objectData}>
                      <span>Name:</span>
                      <div onClick={() => this.setPipedriveUploadEdit()}>
                        <Input
                          value={pipedriveUpload.name}
                          onChange={(e) =>
                            this.handlePipedriveUploadEditChange(e)
                          }
                          onPressEnter={(e) => {
                            this.handlePipedriveUploadEditSubmit();
                            e.target.blur();
                          }}
                          className={
                            isPipedriveUploadEdit
                              ? styles.inputActive
                              : styles.inputDisabled
                          }
                          disabled={isLoadingPipedriveUploadEdit}
                          name="name"
                          type="string"
                          size="small"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className={styles.objectData}>
                      <span>Status:</span>
                      {capitalize(pipedriveUpload.status)}
                    </div>
                    <div className={styles.objectData}>
                      <span>Mail export:</span>
                      <div
                        className={styles.objectLink}
                        onClick={(e) => {
                          this.handleRowClick(
                            e,
                            `/mail-exports/${pipedriveUpload.mail_export_id}`
                          );
                        }}
                      >
                        {pipedriveUpload.mail_export_id}
                      </div>
                    </div>
                    <div className={styles.objectData}>
                      <span>Created At:</span>
                      {formatDate(
                        pipedriveUpload.created_at,
                        "YYYY-MM-DD, h:mm:ss A"
                      )}
                    </div>
                    <div className={styles.objectData}>
                      <span>Updated At:</span>
                      {formatDate(
                        pipedriveUpload.updated_at,
                        "YYYY-MM-DD, h:mm:ss A"
                      )}
                    </div>
                    <div className={styles.objectData}>
                      <span>Started At:</span>
                      {formatDate(
                        pipedriveUpload.started_at,
                        "YYYY-MM-DD, h:mm:ss A"
                      )}
                    </div>
                    <div className={styles.objectData}>
                      <span>Stopped At:</span>
                      {formatDate(
                        pipedriveUpload.stopped_at,
                        "YYYY-MM-DD, h:mm:ss A"
                      )}
                    </div>
                    <div className={styles.objectData}>
                      <span>Runtime (s):</span>
                      {pipedriveUpload.runtime_seconds}
                    </div>
                  </Col>
                  <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                    {pipedriveUpload.data && (
                      <div className={styles.objectData}>
                        <ReactJson
                          src={pipedriveUpload.data}
                          style={{ fontSize: "12px" }}
                          collapsed={2}
                          displayDataTypes={false}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withParams(PipedriveUploadDetail);
