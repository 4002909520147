import React, { Component } from "react";
import { formatNumber } from "../../utils/numbers";
import { columnTypes } from "../../utils/search";
import { capitalize } from "lodash";
import { formatDate } from "../../utils/dates";

import BaseSearch from "../search/BaseSearch";
import FilterTags from "../search/FilterTags";
import Took from "../search/Took";
import TotalRecords from "../search/TotalRecords";

import {
  DownloadOutlined,
  TableOutlined,
  EnvironmentTwoTone,
} from "@ant-design/icons";

import ExportForm from "../forms/ExportForm";
import ChangeColumnsForm from "../forms/ChangeColumnsForm";

import { Row, Col, Table, Select, Button, Tooltip, Pagination } from "antd";

const baseColumnState = [
  {
    dataIndex: "id",
    title: "Asset",
    editable: false,
    type: columnTypes.LINK,
    baseUrl: "/portfolio/assets",
    sortable: true,
  },
  {
    dataIndex: "geom_polygon",
    title: "Mapped",
    type: columnTypes.SELECT,
    options: [
      { value: "false", label: "False" },
      { value: "true", label: "True" },
    ],
    className: `App__w8`,
    selectClassName: `App__w8`,
    format: ({ record }) => {
      return record.geom_polygon ? (
        <div className="App__tableIcon">
          <EnvironmentTwoTone twoToneColor="#52c41a" />
        </div>
      ) : null;
    },
  },
  {
    dataIndex: "entity_id",
    title: "Entity",
    className: `App__w14`,
    type: columnTypes.SELECT,
    globalStateKey: "entities.data",
    globalStateOptions: (data) =>
      data.map((d) => ({ value: d.id, label: d.name })),
  },
  {
    dataIndex: "name",
    title: "Name",
    type: columnTypes.STRING,
  },
  {
    dataIndex: "county",
    title: "County",
    type: columnTypes.STRING,
    className: `App__w14`,
  },
  {
    dataIndex: "state",
    title: "State",
    type: columnTypes.STRING,
    className: `App__w14`,
  },
  {
    title: "Cost basis",
    dataIndex: "cost_basis",
    type: columnTypes.INTEGER,
    format: (value) => `$${formatNumber(value, "0,0.00")}`,
    className: `App__w14`,
  },
  {
    title: "DOI",
    dataIndex: "decimal_interest",
    type: columnTypes.INTEGER,
    format: (value) => formatNumber(value, "0.00000000"),
    className: `App__w14`,
  },
  {
    title: "Acres",
    dataIndex: "acres",
    type: columnTypes.INTEGER,
    className: `App__w14`,
  },
  {
    title: "NRA",
    dataIndex: "nra",
    type: columnTypes.INTEGER,
    format: (value) => formatNumber(value, "0.0000"),
    className: `App__w14`,
  },
  {
    title: "Sold",
    dataIndex: "sold",
    type: columnTypes.SELECT,
    options: [
      { value: "false", label: "False" },
      { value: "true", label: "True" },
    ],
    format: ({ value }) => capitalize(value),
    className: `App__w14`,
  },
  {
    title: "Created at",
    dataIndex: "created_at",
    type: columnTypes.DATE_TIME,
    format: (value) => formatDate(value, "YYYY-MM-DD"),
    className: `App__w14`,
  },
];

class Assets extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      isLoadingRecords,
      isLoadingCount,
      records,
      columnState,
      limit,
      page,
      totalRecords,
      tookRecords,
      tookCount,
      searchFields,
      isExportModalVisible,
      isChangeColumnsVisible,
      isLoadingExport,
    } = this.props;

    const {
      handleSearch,
      handleRecordsChange,
      handlePaginationChange,
      handleFilterClose,
      handleTableChange,
      handleSubmitExport,
      showExportModal,
      handleCancelExport,
      handleSubmitColumnChange,
      showColumnChangeModal,
      handleCancelColumnsChange,
      exportFormRef,
      changeColumnFormRef,
      getColumnProps,
    } = this.props;

    let columns = columnState
      .map((column, idx) => ({
        key: idx,
        ...column,
        ...getColumnProps(column),
      }))
      .filter((col) => col.visible === true);

    return (
      <div>
        <Row className="App__rowContent">
          <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16}>
            <Button className="App__mr-5" onClick={() => handleSearch()}>
              Search
            </Button>
            <FilterTags
              searchFields={searchFields}
              columnState={columnState}
              handleFilterClose={handleFilterClose}
            />
          </Col>
          <Col
            span={8}
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            className="App__rightActions"
          >
            <Tooltip placement="bottomRight" title={"Export search results"}>
              <Button className="App__iconButton" onClick={showExportModal}>
                <DownloadOutlined />
              </Button>
            </Tooltip>
            <ExportForm
              wrappedComponentRef={exportFormRef}
              visible={isExportModalVisible}
              isLoading={isLoadingExport}
              onCancel={handleCancelExport}
              onCreate={handleSubmitExport}
              totalRecords={totalRecords}
            />
            <Tooltip placement="bottomRight" title={"Change visible columns"}>
              <Button
                onClick={showColumnChangeModal}
                className="App__iconButton"
              >
                <TableOutlined />
              </Button>
            </Tooltip>
            <ChangeColumnsForm
              wrappedComponentRef={changeColumnFormRef}
              visible={isChangeColumnsVisible}
              isLoading={isLoadingRecords}
              onCancel={handleCancelColumnsChange}
              onOk={handleSubmitColumnChange}
              columnState={columnState}
            />
            <Select
              value={limit}
              onChange={handleRecordsChange}
              className={"App__mr-5"}
            >
              <Select.Option value={25}>25</Select.Option>
              <Select.Option value={50}>50</Select.Option>
              <Select.Option value={100}>100</Select.Option>
            </Select>
            <TotalRecords
              title={"Assets"}
              isLoadingCount={isLoadingCount}
              totalRecords={totalRecords}
            />
          </Col>
        </Row>
        <Row className="App__rowContent">
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={records}
              onChange={handleTableChange}
              showSorterTooltip={false}
              pagination={false}
              className="App__smallTables"
              loading={isLoadingRecords}
              scroll={{ x: "100%" }}
              size="small"
              bordered
            />
            <div className="App__searchFooterContainer">
              <Took
                isLoadingRecords={isLoadingRecords}
                isLoadingCount={isLoadingCount}
                tookRecords={tookRecords}
                tookCount={tookCount}
              />
              <Pagination
                current={page}
                pageSize={limit}
                total={totalRecords}
                onChange={handlePaginationChange}
                className="App__pagination"
                showSizeChanger={false}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const AssetsWithSearch = (props) => (
  <BaseSearch
    baseColumnState={baseColumnState}
    localSortColumns={[]}
    searchUri="assets"
    baseUri="/portfolio"
  >
    <Assets {...props} />
  </BaseSearch>
);

export default AssetsWithSearch;
