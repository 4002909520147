const SOURCE_ID = "tracts";
const LAYER_ID = "tracts";
const BORDER_HIGHLIGHT_LAYER_ID = "tracts_border_highlight";
const FILTER_LAYER_IDS = [LAYER_ID];
const CLICKABLE_LAYERS = [LAYER_ID];
const UNIQUE_COLUMN = "id";

const API_BASE_ENDPOINT = "tracts";
const TILE_ENDPOINT = `${
  process.env.REACT_APP_SERVER_URL
}/api/${API_BASE_ENDPOINT}/tiles/{z}/{x}/{y}.pbf?access_token=${localStorage.getItem(
  "authToken"
)}`;

const INITIAL_FILTER_STATE = {
  id: {
    column: "id",
    label: "ID",
    value: [],
  },
  county: {
    column: "county",
    label: "County",
    value: [],
  },
  name: {
    column: "name",
    label: "Name",
    value: [],
  },
};

const STYLES = {
  "fill-outline-color": "#000000",
  "fill-color": "#4bbf90",
  "fill-opacity": 0.3,
};

const BORDER_HIGHLIGHT_STYLES = {
  "line-color": "#000",
  "line-width": 2,
};

const ADD_BEFORE = "Laterals";

const addSource = (mapRef) => {
  if (!mapRef.current.getSource(SOURCE_ID)) {
    mapRef.current.addSource(SOURCE_ID, {
      type: "vector",
      tiles: [TILE_ENDPOINT],
      minzoom: 0,
      maxzoom: 14,
    });
  }
};

const addLayers = (mapRef) => {
  if (!mapRef.current.getLayer(LAYER_ID)) {
    mapRef.current.addLayer(
      {
        id: LAYER_ID,
        type: "fill",
        source: SOURCE_ID,
        "source-layer": SOURCE_ID,
        layout: {
          visibility: "none",
        },
        paint: STYLES,
      },
      ADD_BEFORE
    );
  }

  if (!mapRef.current.getLayer(BORDER_HIGHLIGHT_LAYER_ID)) {
    mapRef.current.addLayer(
      {
        id: BORDER_HIGHLIGHT_LAYER_ID,
        type: "line",
        source: SOURCE_ID,
        "source-layer": SOURCE_ID,
        layout: {},
        paint: BORDER_HIGHLIGHT_STYLES,
        filter: ["in", UNIQUE_COLUMN, ""],
      },
      ADD_BEFORE
    );
  }
};

const addAll = (mapRef) => {
  addSource(mapRef);
  addLayers(mapRef);
};

export {
  SOURCE_ID,
  LAYER_ID,
  BORDER_HIGHLIGHT_LAYER_ID,
  FILTER_LAYER_IDS,
  CLICKABLE_LAYERS,
  UNIQUE_COLUMN,
  STYLES,
  BORDER_HIGHLIGHT_STYLES,
  API_BASE_ENDPOINT,
  TILE_ENDPOINT,
  INITIAL_FILTER_STATE,
  addAll,
};
