import React from "react";

import { ReactComponent as Logo } from "../assets/logo/logo_full_black.svg";
import styles from "./Loading.module.scss";

function Loading(props) {
  return (
    <div className={styles.main}>
      <div className={styles.content}>
        <Logo className={styles.logo} />
        <div className={styles.loadingText}>{props.message}</div>
        {props.subMessage && (
          <div className={styles.loadingSubText}>{props.subMessage}</div>
        )}
      </div>
    </div>
  );
}

export default Loading;
