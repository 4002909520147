import React, { Component } from "react";
import { convertDataIndex } from "../../utils/search";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";

import { Modal, Checkbox } from "antd";

import styles from "./ChangeColumnsForm.module.scss";

const ChangeColumnsForm = Form.create({ name: "change_columns" })(
  // eslint-disable-next-line
  class extends Component {
    render() {
      const { visible, isLoading, onCancel, onOk, columnState, form } =
        this.props;
      const { getFieldDecorator } = form;

      return (
        <Modal
          open={visible}
          title="Change Columns"
          okText="Submit"
          cancelText="Cancel"
          onCancel={onCancel}
          onOk={onOk}
          confirmLoading={isLoading}
          maskClosable={false}
        >
          <Form
            {...{
              labelCol: {
                xs: { span: 24 },
                sm: { span: 20 },
              },
              wrapperCol: {
                xs: { span: 24 },
                sm: { span: 4 },
              },
            }}
          >
            <div className={styles.list}>
              {columnState.map((col) => {
                return (
                  <Form.Item label={col.title} key={col.dataIndex}>
                    {getFieldDecorator(
                      String(convertDataIndex(col.dataIndex)),
                      {
                        valuePropName: "checked",
                        initialValue: col.visible,
                      }
                    )(<Checkbox disabled={!col.editable} />)}
                  </Form.Item>
                );
              })}
            </div>
          </Form>
        </Modal>
      );
    }
  }
);

export default ChangeColumnsForm;
