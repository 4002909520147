import React from "react";
import { Tag } from "antd";
import { get } from "lodash";
import { connect } from "react-redux";
import { convertDataIndex } from "../../utils/search";

const mapStateToProps = (state) => ({
  appConfig: get(state.appConfig, "data", {}),
  entities: get(state.entities, "data", []),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const FilterTags = (props) => {
  const { searchFields, columnState, handleFilterClose, appConfig, entities } =
    props;

  const formatTagValue = (dataIndex, value) => {
    if (dataIndex === "entity_id") {
      const entity = entities.find((e) => e.id === parseInt(value));
      return entity ? entity.name : value;
    }

    if (dataIndex.indexOf("pipedrive_person_label_ids") > -1) {
      const pipedrivePersonLabels = get(
        appConfig,
        "pipedrive_person_labels",
        []
      );
      const valueArray = Array.isArray(value) ? value : Object.values(value)[0];
      if (valueArray.length) {
        const labels = valueArray.map((id) => {
          const label = pipedrivePersonLabels.find(
            (l) => l.id === parseInt(id)
          );
          return label ? label.label : id;
        });
        return JSON.stringify(labels);
      }
      return JSON.stringify(value);
    }

    return JSON.stringify(value);
  };

  return (
    <>
      {Object.keys(searchFields).map((dataIndex, idx) => {
        const column = columnState.find(
          (col) => convertDataIndex(col.dataIndex) === dataIndex
        );
        const title = get(column, "title", dataIndex);
        const value = searchFields[dataIndex];

        return (
          value !== undefined && (
            <Tag
              key={idx}
              closable={true}
              onClose={() => handleFilterClose(dataIndex)}
              onClick={() => handleFilterClose(dataIndex)}
              className="App__searchTags"
            >
              {`${title}: ${formatTagValue(dataIndex, value)}`}
            </Tag>
          )
        );
      })}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterTags);
