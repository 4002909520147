import React, { useState, useEffect } from "react";
import { apiBase } from "../utils/apiBase";
import { formatDate } from "../utils/dates";
import TotalRecords from "../views/search/TotalRecords";
import { get } from "lodash";

import {
  Row,
  Col,
  Table,
  Button,
  message,
  Typography,
  notification,
} from "antd";

import { PlayCircleOutlined, StopOutlined } from "@ant-design/icons";

import styles from "./Jobs.module.scss";

const notificationWidth = 450;

const Jobs = (props) => {
  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    getJobs();
  }, []);

  const getJobs = () => {
    setLoading(true);
    apiBase
      .get(`/jobs`)
      .then((res) => {
        setJobs(res.data.map((el, idx) => ({ ...el, ...{ key: idx } })));
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to load jobs`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const runJob = (id) => {
    setLoading(true);
    apiBase
      .get(`/jobs/${id}/run`)
      .then((res) => {
        const args = {
          message: (
            <div className={styles.notificationTitle}>
              Job {get(res, "data.status")}
            </div>
          ),
          description: (
            <Typography.Text
              keyboard
              copyable
              className={styles.notificationBody}
            >
              {get(res, "data.id")}
            </Typography.Text>
          ),
          style: {
            width: notificationWidth,
          },
          duration: 0,
        };
        notification.success(args);
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to run job`;
        message.error(msg);
      })
      .finally(() => {
        getJobs();
      });
  };

  const stopJob = (id) => {
    setLoading(true);
    apiBase
      .get(`/jobs/${id}/stop`)
      .then((res) => {
        const args = {
          message: (
            <div className={styles.notificationTitle}>
              Job {get(res, "data.status")}
            </div>
          ),
          description: (
            <Typography.Text
              keyboard
              copyable
              className={styles.notificationBody}
            >
              {get(res, "data.id")}
            </Typography.Text>
          ),
          style: {
            width: notificationWidth,
          },
          duration: 0,
        };
        notification.error(args);
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to stop job`;
        message.error(msg);
      })
      .finally(() => {
        getJobs();
      });
  };

  const columns = [
    { title: "id", dataIndex: "id" },
    { title: "Name", dataIndex: "name" },
    { title: "Function", dataIndex: "func" },
    { title: "Args", dataIndex: "args" },
    { title: "kwargs", dataIndex: "kwargs" },
    // { title: "coalesce", dataIndex: "coalesce" },
    { title: "Trigger", dataIndex: "trigger" },
    {
      title: "Crontab",
      dataIndex: "crontab",
      render: (value) => (
        <a
          href={`https://crontab.guru/#${value.replaceAll(" ", "_")}`}
          target="_blank"
          rel="noreferrer"
        >
          {value}
        </a>
      ),
    },
    // { title: "pending", dataIndex: "pending" },
    // { title: "executor", dataIndex: "executor" },
    { title: "Misfire grace time", dataIndex: "misfire_grace_time" },
    { title: "Max Instances", dataIndex: "max_instances" },
    {
      title: "Running",
      dataIndex: "running",
      render: (value) => String(value),
    },
    {
      title: "next_run_time",
      dataIndex: "next_run_time",
      render: (value) => formatDate(value, "YYYY-MM-DD, h:mm:ss A"),
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "actions",
      render: (value, record) => {
        if (!record.running) {
          return (
            <div className="App__tableOperations">
              <Button
                shape="circle"
                icon={<PlayCircleOutlined />}
                size="small"
                onClick={() => runJob(record.id)}
                disabled={loading}
              />
            </div>
          );
        } else {
          return (
            <div className="App__tableOperations">
              <Button
                shape="circle"
                icon={<StopOutlined />}
                size="small"
                onClick={() => stopJob(record.id)}
                disabled={loading}
              />
            </div>
          );
        }
      },
    },
  ];

  return (
    <div className={styles.main}>
      <Row className="App__rowContent">
        <Col span={8} />
        <Col span={8} offset={8} className="App__rightActions">
          <TotalRecords
            title={"Schedules"}
            isLoadingCount={false}
            totalRecords={jobs.length}
          />
        </Col>
      </Row>
      <Row className="App__rowContent">
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={jobs}
            pagination={false}
            className="App__smallTables"
            loading={loading}
            showSorterTooltip={false}
            scroll={{ x: "100%" }}
            size="small"
            bordered
          />
        </Col>
      </Row>
      <Row className={`App__rowContent ${styles.rqDash}`}>
        <Col span={24}>
          <iframe
            title="Dashboard"
            width="100%"
            height="100%"
            src={`${process.env.REACT_APP_SERVER_URL}/rq`}
            frameBorder={0}
            allowTransparency
          />
        </Col>
      </Row>
    </div>
  );
};

export default Jobs;
