import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiBase } from "../../utils/apiBase";
import { get, omit, isEmpty } from "lodash";
import * as turf from "@turf/turf";

import BaseControls from "../maps/BaseControls";
import mapActions from "../../actions/map";
import * as unitsLayer from "../maps/layers/units";
import * as landtracUnitsLayer from "../maps/layers/landtracUnits";

import DrawCardControls from "../maps/controls/DrawCardControls";
import { useInitialization } from "../maps/hooks/useInitialization";
import { fitMapToBounds } from "../maps/common/utils";

import { Spin, message } from "antd";

import styles from "./UnitMap.module.scss";

import { useLayerFilter } from "../maps/hooks/useLayerFilter";

const UnitMap = ({ unit, getUnit, enverusLandtracUnits }) => {
  const mapRef = useRef(null);
  const mapContainerRef = useRef(null);
  const drawRef = useRef(null);
  const [mapLoaded, setMapLoaded] = useState(false);

  useInitialization({
    mapRef,
    mapContainerRef,
    drawRef,
    setMapLoaded,
  });

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const edit = useSelector((state) => state.map.controls.draw.edit);

  const setUnitFilter = useLayerFilter(mapRef, mapLoaded, unitsLayer.LAYER_ID, [
    unit,
  ]);

  const setLandtracUnitsFilter = useLayerFilter(
    mapRef,
    mapLoaded,
    landtracUnitsLayer.LAYER_ID,
    enverusLandtracUnits
  );

  // Load to unit or landtrac units
  useEffect(() => {
    if (!mapLoaded) return;
    dispatch(mapActions.resetMap(mapRef));

    if (unit.geom_polygon) {
      const features = turf.feature(unit.geom_polygon);
      fitMapToBounds(mapRef, features);
      setUnitFilter(true);
    } else if (!isEmpty(enverusLandtracUnits)) {
      const enverusLandtracUnitsFeatures = enverusLandtracUnits
        .filter((r) => !isEmpty(r.geom_polygon))
        .map((r) => ({
          type: "Feature",
          geometry: get(r, "geom_polygon"),
          properties: omit(r, ["geom_polygon"]),
        }));

      const features = turf.featureCollection(enverusLandtracUnitsFeatures);
      fitMapToBounds(mapRef, features);
      setLandtracUnitsFilter(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef, mapLoaded, unit, enverusLandtracUnits]);

  // Toggle edit
  useEffect(() => {
    const draw = drawRef.current;
    if (!mapLoaded || !draw) return;

    if (edit) {
      setUnitFilter(false);
      setLandtracUnitsFilter(false);

      if (unit.geom_polygon) {
        const features = turf.feature(unit.geom_polygon);
        const existingFeatures = draw.getAll().features;
        if (existingFeatures.length === 0) {
          draw.add(features);
        }
      }
    } else {
      draw.deleteAll();
      dispatch(mapActions.resetMap(mapRef));
      if (unit.geom_polygon) {
        setUnitFilter(true);
      } else {
        setLandtracUnitsFilter(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapLoaded, edit, unit.geom_polygon]);

  const handleSave = () => {
    const draw = drawRef.current;
    if (!draw) return;

    const features = draw.getAll();
    setLoading(true);
    apiBase
      .patch(`/units/${unit.id}/map`, {
        geom_polygon: features,
      })
      .then((res) => {
        message.success("Unit map updated successfully!");
      })
      .catch((err) => {
        message.error("Error updating unit map");
      })
      .finally(() => {
        setLoading(false);
        mapRef.current
          .getSource(unitsLayer.SOURCE_ID)
          .setTiles([unitsLayer.TILE_ENDPOINT]);
        getUnit();
      });
  };

  return (
    <div className={styles.main}>
      <div className={styles.card}>
        <div className={styles.cardTitle}>
          <div className={styles.leftTitle}>
            <h2>Map</h2>
          </div>
          <div className={styles.rightTitle}>
            <DrawCardControls handleSave={handleSave} loading={loading} />
          </div>
        </div>
        <div className={styles.cardBody}>
          <div className={styles.mapContainer}>
            <div
              id="map-container"
              className={styles.map}
              ref={mapContainerRef}
            />
            {mapLoaded ? (
              <BaseControls ref={mapRef} />
            ) : (
              <div className={styles.mapLoading}>
                <Spin />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnitMap;
