import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { apiBase } from "../../utils/apiBase";
import { formatNumber } from "../../utils/numbers";
import { get } from "lodash";

import { Modal, Form, Tooltip, Button, InputNumber, List, message } from "antd";

import { PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";

import AppConfigSelect from "../common/AppConfigSelect";
import UserSelect from "../common/UserSelect";

import styles from "./UnitOfferCreateForm.module.scss";

const mapStateToProps = (state) => ({
  user: get(state.user, "data", {}),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const UnitOfferCreateForm = (props) => {
  const { unit, selectedInterests = [] } = props;

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    apiBase
      .post(`offers`, {
        ...values,
        unit_id: unit.id,
      })
      .then(async (res) => {
        message.success("Successfully created unit offer.");
      })
      .catch((err) => {
        console.log(err);
        let msg = `${err.response.status}: Failed to create unit offer.`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
        resetComponent();
        setIsModalOpen(false);
        props.resetInterestSelected();
        props.reloadUnit();
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    resetComponent();
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    resetComponent();
  };

  const submitForm = () => {
    form
      .validateFields()
      .then(() => {
        form.submit();
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };

  const resetComponent = () => {
    form.resetFields();
  };

  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedInterests.length > 0) {
      form.setFieldsValue({
        owner_unit_interest_ids: selectedInterests.map((row) => row.id),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInterests]);

  return (
    <div>
      <Button type="secondary" icon={<PlusOutlined />} onClick={showModal} />
      <Modal
        title="Create unit offer"
        visible={isModalOpen}
        onOk={() => submitForm()}
        onCancel={handleCancel}
        cancelButtonProps={{ disabled: loading }}
        okButtonProps={{ disabled: loading, loading: loading }}
        maskClosable={false}
      >
        <Form
          name="unitOfferCreate"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
          disabled={loading}
          initialValues={{
            acres: get(unit, "acres"),
            status: "New",
            user_id: get(props.user, "id"),
            owner_unit_interest_ids: selectedInterests.map((row) => row.id),
          }}
        >
          <Form.Item
            label="Offer per NRA"
            name="dollar_per_nra"
            rules={[
              {
                required: true,
                message: "Please input the offer per nra for the offer.",
              },
            ]}
          >
            <InputNumber
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              className="App__fullWidth"
            />
          </Form.Item>
          <Form.Item
            label="Unit Size (Acres)"
            name="acres"
            rules={[
              {
                required: true,
                message: "Please input the acreage for the offer.",
              },
            ]}
          >
            <InputNumber className="App__fullWidth" />
          </Form.Item>
          <Form.Item
            label={
              <span>
                Lead Status&nbsp;
                <Tooltip
                  title="When the offer is made we create a lead for each interest, this is the lead status assigned to the new leads."
                  placement="rightTop"
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
            name="status"
            rules={[{ required: true, message: "County is required" }]}
          >
            <AppConfigSelect showSearch allowClear appConfigKey="lead_status" />
          </Form.Item>
          <Form.Item
            label={
              <span>
                Lead Owner&nbsp;
                <Tooltip
                  title="When the offer is made we create a lead for each interest, this is the lead owner assigned to the new leads"
                  placement="rightTop"
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
            name="user_id"
            rules={[{ required: true, message: "County is required" }]}
          >
            <UserSelect showSearch allowClear />
          </Form.Item>
          <Form.Item
            label={
              <span>
                Unit Owners&nbsp;
                <Tooltip
                  title="These are the interests that leads will be created for."
                  placement="rightTop"
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
            name="owner_unit_interest_ids"
            rules={[
              {
                validator: async (_, value) => {
                  if (!value || value.length <= 0) {
                    return Promise.reject(
                      new Error("Need at least 1 unit owner")
                    );
                  }
                },
              },
            ]}
          >
            <List
              className={styles.leadList}
              itemLayout="horizontal"
              dataSource={selectedInterests}
              bordered
              renderItem={(item) => (
                <List.Item>
                  <div className={styles.leadListItem}>
                    <div className={styles.itemLeft}>{item.name_formatted}</div>
                    <div className={styles.itemRight}>
                      {`${formatNumber(item.nra, "0.00")}`}
                    </div>
                  </div>
                </List.Item>
              )}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnitOfferCreateForm);
