import React from "react";

import BaseLayer from "./BaseLayer";

import { LAYER_ID, API_BASE_ENDPOINT } from "../../layers/wells";

const WellsLayer = ({ mapRef }) => {
  return (
    <BaseLayer
      mapRef={mapRef}
      title="Wells"
      apiBaseEndpoint={API_BASE_ENDPOINT}
      layerId={LAYER_ID}
      selectedLayerIds={[]}
    />
  );
};

export default WellsLayer;
