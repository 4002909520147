import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";
import mapActions from "../../actions/map";
import { get, omit, isEmpty, uniq } from "lodash";
import * as turf from "@turf/turf";
import BaseControls from "../maps/BaseControls";
import { fitMapToBounds } from "../maps/common/utils";
import * as landtracUnitsLayer from "../maps/layers/landtracUnits";
import { Select, Spin, Button } from "antd";

import { useLayerFilter } from "../maps/hooks/useLayerFilter";
import { useInitialization } from "../maps/hooks/useInitialization";

import styles from "./OwnerMap.module.scss";

const OwnerMap = ({
  isLoadingEnverus,
  isLoadingLeads,
  enverusLandtracUnits,
  leads,
}) => {
  const mapRef = useRef(null);
  const mapContainerRef = useRef(null);
  const [mapLoaded, setMapLoaded] = useState(false);

  useInitialization({
    mapRef,
    mapContainerRef,
    setMapLoaded,
  });

  const [selectedCounty, setSelectedCounty] = useState("all");

  const dispatch = useDispatch();

  const setLandtracUnitsFilter = useLayerFilter(
    mapRef,
    mapLoaded,
    landtracUnitsLayer.LAYER_ID,
    enverusLandtracUnits
  );

  const setMapFilters = useCallback(() => {
    if (!mapLoaded || !mapRef.current) return;

    dispatch(mapActions.resetMap(mapRef));

    if (!isEmpty(enverusLandtracUnits)) {
      setLandtracUnitsFilter(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef, mapLoaded, setLandtracUnitsFilter]);

  // On data load
  useEffect(() => {
    if (!mapLoaded || isLoadingEnverus || isLoadingLeads) return;

    // If no leads, find most common county from enverus units
    const countyFrequency = enverusLandtracUnits.reduce((acc, unit) => {
      if (unit.county) {
        acc[unit.county] = (acc[unit.county] || 0) + 1;
      }
      return acc;
    }, {});

    // Try to get most recent lead's county first, but only if it has landtrac units
    const sortedLeads = [...leads].sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
    const mostRecentLeadCounty = get(sortedLeads[0], "unit.county");

    if (
      mostRecentLeadCounty &&
      enverusLandtracUnits.some((unit) => unit.county === mostRecentLeadCounty)
    ) {
      setSelectedCounty(mostRecentLeadCounty);
    } else if (Object.keys(countyFrequency).length > 0) {
      const mostCommonCounty = Object.entries(countyFrequency).reduce((a, b) =>
        b[1] > a[1] ? b : a
      )[0];
      setSelectedCounty(mostCommonCounty);
    } else {
      setSelectedCounty("all");
    }

    setMapFilters();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mapLoaded,
    isLoadingEnverus,
    isLoadingLeads,
    leads,
    enverusLandtracUnits,
  ]);

  // On change of selected county
  useEffect(() => {
    if (!mapLoaded || isLoadingEnverus || isLoadingLeads) return;

    let enverusLandtracUnitsData = enverusLandtracUnits.filter(
      (r) => !isEmpty(r.geom_polygon)
    );

    if (selectedCounty !== "all") {
      enverusLandtracUnitsData = enverusLandtracUnitsData.filter(
        (unit) => unit.county === selectedCounty
      );
    }

    const _enverusLandtracUnits = {
      type: "FeatureCollection",
      features: enverusLandtracUnitsData.map((unit) => ({
        type: "Feature",
        geometry: get(unit, "geom_polygon"),
        properties: omit(unit, ["geom_polygon"]),
      })),
    };

    if (!isEmpty(_enverusLandtracUnits.features)) {
      const features = turf.featureCollection([
        ..._enverusLandtracUnits.features,
      ]);

      fitMapToBounds(mapRef, features);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCounty, mapLoaded]);

  const countyOptions = [
    { label: "All", value: "all" },
    ...uniq(enverusLandtracUnits.map((unit) => unit.county))
      .filter(Boolean)
      .sort()
      .map((county) => ({
        label: county,
        value: county,
      })),
  ];

  return (
    <div className={styles.card}>
      <div className={styles.cardTitle}>
        <div className={styles.leftTitle}>
          <h2>Map</h2>
        </div>
        <div className={styles.rightTitle}>
          <Button
            type="secondary"
            size="small"
            className="App__mr-8"
            onClick={setMapFilters}
            disabled={isLoadingEnverus || isLoadingLeads}
          >
            Reset
          </Button>
          <Select
            size="small"
            disabled={isLoadingEnverus || isLoadingLeads}
            options={countyOptions}
            value={selectedCounty}
            onChange={setSelectedCounty}
            style={{ width: 200 }}
            showSearch
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          />
        </div>
      </div>
      <div className={styles.cardBody}>
        <div className={styles.mapContainer}>
          <div
            id="map-container"
            className={styles.map}
            ref={mapContainerRef}
          />
          {!isLoadingEnverus && mapLoaded ? (
            <BaseControls
              ref={mapRef}
              // initialShowFilters={false}
            />
          ) : (
            <div className={styles.mapLoading}>
              <Spin />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OwnerMap;
