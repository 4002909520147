import React from "react";
import PropTypes from "prop-types";
import { convertDataIndex } from "../../utils/search";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";

import { Input, Button, Dropdown } from "antd";

import styles from "./Filters.module.scss";

class MinMaxDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
    this.confirm = this.confirm.bind(this);
  }

  static propTypes = {
    dataIndex: PropTypes.string,
    name: PropTypes.string,
    dataType: PropTypes.string,
    searchFields: PropTypes.object,
    handleSearchNumberChange: PropTypes.func,
    handleSearch: PropTypes.func,
    handleReset: PropTypes.func,
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  handleClick = (e) => {
    if (this.node && this.node.contains(e.target)) {
      return;
    }
    this.confirm();
  };

  confirm() {
    this.setState({
      visible: false,
    });
  }

  showDropdown() {
    this.setState({
      visible: true,
    });
  }

  render() {
    const { visible } = this.state;
    const { name, dataIndex, searchFields } = this.props;
    const { handleSearchNumberChange, handleSearch, handleReset } = this.props;
    const searchData =
      searchFields[convertDataIndex(dataIndex)] !== undefined
        ? searchFields[convertDataIndex(dataIndex)]
        : {};

    const menu = (
      <div className={styles.searchDropDown} ref={(node) => (this.node = node)}>
        <Input
          placeholder={`Min ${name}`}
          value={searchData.gte !== undefined ? searchData.gte : null}
          onChange={(e) => handleSearchNumberChange(e, dataIndex, "min")}
          onPressEnter={(e) => e.keyCode === 13 && handleSearch(this.confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
          type="number"
          autoComplete="off"
        />
        <Input
          placeholder={`Max ${name}`}
          value={searchData.lte !== undefined ? searchData.lte : null}
          onChange={(e) => handleSearchNumberChange(e, dataIndex, "max")}
          onPressEnter={(e) => e.keyCode === 13 && handleSearch(this.confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
          type="number"
          autoComplete="off"
        />
        <Button
          type="primary"
          onClick={() => handleSearch(this.confirm)}
          icon={<SearchOutlined />}
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(dataIndex, this.confirm)}
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    );

    return (
      <Dropdown overlay={menu} placement="bottomRight" open={visible}>
        <Button
          className={styles.filterButton}
          onClick={() => this.showDropdown()}
        >
          <FilterOutlined className={styles.icon} />
        </Button>
      </Dropdown>
    );
  }
}

export default MinMaxDropdown;
