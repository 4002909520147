import React from "react";
import { LoadingOutlined } from "@ant-design/icons";

import { formatNumber } from "../../utils/numbers";

import styles from "./TotalRecords.module.scss";

const TotalRecords = (props) => {
  const { title, totalRecords, isLoadingCount } = props;

  return (
    <div className={styles.recordCountStat}>
      <div className={styles.title}>{title}</div>
      <div className={styles.metric}>
        {isLoadingCount ? (
          <div className={styles.tempCount}>
            {/* <div>{`${formatNumber(totalRecords, "0,0")}`}</div> */}
            <div className={styles.loadingCount}>
              <LoadingOutlined />
            </div>
          </div>
        ) : (
          <>{formatNumber(totalRecords, "0,0")}</>
        )}
      </div>
    </div>
  );
};

export default TotalRecords;
