import MapboxDraw from "@mapbox/mapbox-gl-draw";
import {
  SnapPolygonMode,
  // SnapModeDrawStyles,
  SnapDirectSelect,
} from "mapbox-gl-draw-snap-mode";

import * as turf from "@turf/turf";
import numeral from "numeral";
import { drawStyles } from "./DrawStyles";
import { DRAW_SNAP_LAYER_IDS } from "../../layers";
import { debounce } from "lodash";

// import StaticMode from "./drawModes/static";

const addDraw = (mapRef) => {
  const draw = new MapboxDraw({
    displayControlsDefault: false,
    defaultMode: "simple_select",
    modes: {
      // static: StaticMode,
      simple_select: MapboxDraw.modes.simple_select,
      // direct_select: MapboxDraw.modes.direct_select,
      draw_polygon: SnapPolygonMode,
      direct_select: SnapDirectSelect,
    },
    // styles: [...SnapModeDrawStyles],
    // styles: [...SnapModeDrawStyles, ...drawStyles],
    styles: [...drawStyles],
    userProperties: true,
    snap: true,
    snapOptions: {
      snapPx: 15, // defaults to 15
      snapVertexPriorityDistance: 0.1, // defaults to 1.25
      snapGetFeatures: (map, draw) => [
        ...map.queryRenderedFeatures({
          layers: DRAW_SNAP_LAYER_IDS,
        }),
        ...draw.getAll().features,
      ],
    },
  });

  mapRef.current.addControl(draw, "top-right");

  addDrawLengths(mapRef, draw);

  return draw;
};

const syncDrawState = (action) => {
  const { drawRef, value } = action;
  const { mode, snap } = value;

  if (!drawRef || !drawRef.current) return;

  const draw = drawRef.current;

  if (mode) {
    const currentMode = draw.getMode();
    if (currentMode === mode) return;
    draw.changeMode(mode);
  }

  if (snap !== undefined) {
    const currentSnap = draw.options.snap;
    if (currentSnap === snap) return;
    draw.options.snap = snap;
  }
};

const addDrawLengths = (mapRef, draw) => {
  mapRef.current.on("load", () => {
    try {
      // measurements source
      mapRef.current.addSource("_measurements", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [],
        },
      });

      // measurements layer
      mapRef.current.addLayer(
        {
          id: "_measurements",
          source: "_measurements",
          type: "symbol",
          paint: {
            "text-color": "#1c1c1c",
            "text-halo-color": "#ffffff",
            "text-halo-width": 5,
          },
          layout: {
            "text-field": "{label}",
            "text-size": 14,
            "text-font": ["Prompt Light", "Arial Unicode MS Regular"],
          },
        },
        mapRef.current.getStyle().layers[
          mapRef.current.getStyle().layers.length - 1
        ].id
      ); // Add as last layer to be on top

      // Create a debounced function outside the event handler
      const handleDrawRender = debounce(function (e, draw, mapRef) {
        let labelFeatures = [];
        let label;

        let all = draw.getAll();
        if (all && all.features) {
          all.features.forEach(function (feature) {
            switch (turf.getType(feature)) {
              case "Polygon":
                // label Polygons
                if (
                  feature.geometry.coordinates.length > 0 &&
                  feature.geometry.coordinates[0].length > 3
                ) {
                  let area = turf.area(feature.geometry) * 0.000247105;
                  label = numeral(area).format("00.0");
                  labelFeatures.push(
                    turf.centerOfMass(feature, {
                      properties: {
                        label: label,
                      },
                    })
                  );
                }
                break;
              case "MultiPolygon":
                // label MultiPolygons
                if (
                  feature.geometry.coordinates.length > 0 &&
                  feature.geometry.coordinates[0].length > 0
                ) {
                  let area = turf.area(feature.geometry) * 0.000247105;
                  label = numeral(area).format("00.0");
                  labelFeatures.push(
                    turf.centerOfMass(feature, {
                      properties: {
                        label: label,
                      },
                    })
                  );
                }
                break;
              default:
                break;
            }
          });
        }

        mapRef.current.getSource("_measurements").setData({
          type: "FeatureCollection",
          features: labelFeatures,
        });
      }, 100); // 200ms delay - adjust as needed

      // Then use it in your event handler
      mapRef.current.on("draw.render", function (e) {
        handleDrawRender(e, draw, mapRef);
      });
    } catch (error) {
      console.error("Error in useDrawLengths", error);
    }
  });
};

export { addDraw, addDrawLengths, syncDrawState };
