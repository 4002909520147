import React, { useState } from "react";
import { get } from "lodash";
import { apiBase } from "../../utils/apiBase";
import { clsx } from "clsx";

import { Modal, Form, Button, InputNumber, message } from "antd";

import { PlusOutlined } from "@ant-design/icons";

import AppConfigSelect from "../common/AppConfigSelect";
import QBOSearchSelect from "../common/QBOSearchSelect";

import styles from "./DealAssetCreateForm.module.scss";

const DealAssetCreateForm = (props) => {
  const { deal } = props;

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    apiBase
      .post(`deals/${deal.id}/assets`, {
        ...values,
      })
      .then(async (res) => {
        message.success("Successfully created deal asset.");
      })
      .catch((err) => {
        console.log(err);
        let msg = `${err.response.status}: Failed to create deal asset.`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
        resetComponent();
        setIsModalOpen(false);
        props.getDeal();
        props.getDealAssets();
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    resetComponent();
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    resetComponent();
  };

  const submitForm = () => {
    form
      .validateFields()
      .then(() => {
        form.submit();
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };

  const onFormChange = (changedValues, allValues) => {
    const { acres, decimal_interest } = allValues;
    if (acres && decimal_interest) {
      form.setFieldsValue({
        nra: acres * decimal_interest * 8,
      });
    }
  };

  const resetComponent = () => {
    form.resetFields();
  };

  const [form] = Form.useForm();

  return (
    <div>
      <Button type="secondary" icon={<PlusOutlined />} onClick={showModal} />
      <Modal
        title="Create deal asset"
        visible={isModalOpen}
        onOk={() => submitForm()}
        onCancel={handleCancel}
        cancelButtonProps={{ disabled: loading }}
        okButtonProps={{ disabled: loading, loading: loading }}
        maskClosable={false}
      >
        <Form
          name="dealAssetCreate"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onFormChange}
          autoComplete="off"
          layout="vertical"
          disabled={loading}
          initialValues={{
            "asset.state": "Texas",
          }}
        >
          <Form.Item
            label="Asset name"
            name="asset.qbo_data"
            rules={[{ required: true, message: "Asset name is required" }]}
          >
            <QBOSearchSelect
              entityId={get(deal, "entity_id")}
              searchObject="Class"
              searchField="Name"
              placeholder="Search assets"
              disabled={!get(deal, "entity.quickbooks_realm_id")}
            />
          </Form.Item>
          <Form.Item
            label="State"
            name="asset.state"
            rules={[{ required: true, message: "State is required" }]}
          >
            <AppConfigSelect showSearch allowClear appConfigKey="states" />
          </Form.Item>
          <Form.Item
            label="County"
            name="asset.county"
            rules={[{ required: true, message: "County is required" }]}
          >
            <AppConfigSelect showSearch allowClear appConfigKey="counties" />
          </Form.Item>
          {[
            { name: "acres", label: "Acres", required: true },
            {
              name: "decimal_interest",
              label: "Decimal interest",
              required: true,
            },
            { name: "nra", label: "NRA", required: true, disabled: true },
            {
              name: "purchase_price",
              label: "Purchase price",
              required: true,
              precision: 2,
              prefix: "$",
            },
          ].map((item, index) => (
            <Form.Item
              key={index}
              label={item.label}
              name={item.name}
              rules={[
                {
                  required: item.required,
                  message: `${item.label} is required.`,
                },
              ]}
            >
              <InputNumber
                disabled={item.disabled || loading}
                className={clsx("App__fullWidth", {
                  [styles.disabledItem]: item.disabled && !loading,
                })}
                {...(item.precision ? { precision: item.precision } : {})}
                {...(item.precision ? { prefix: item.prefix } : {})}
              />
            </Form.Item>
          ))}
        </Form>
      </Modal>
    </div>
  );
};

export default DealAssetCreateForm;
