import { message } from "antd";
import { apiBase } from "../utils/apiBase";
import { get } from "lodash";

export const types = {
  AGGREGATES_SET: "AGGREGATES_SET",
};

export const setAggregates = (value) => ({
  type: types.AGGREGATES_SET,
  value,
});

export const getAggregates = () => (dispatch) => {
  return apiBase
    .get(`aggregates`)
    .then((res) => dispatch(setAggregates(get(res, "data.aggregates", []))))
    .catch((err) => {
      dispatch(setAggregates({}));
      message.error("Failed load aggregates.");
    });
};

export const initialState = {
  data: [],
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case types.AGGREGATES_SET:
      return {
        ...state,
        data: action.value,
      };
    default:
      return state;
  }
}

const actions = {
  getAggregates,
};

export default actions;
