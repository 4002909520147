import React, { useState, useEffect, useCallback } from "react";

import { apiBase } from "../../utils/apiBase";
import { withParams } from "../../utils/withParams";
import { Helmet } from "react-helmet";
import { get } from "lodash";
import { clsx } from "clsx";
import ReactJson from "react-json-view";

import { message } from "antd";

import styles from "./EndatoSearch.module.scss";

const EndatoSearch = (props) => {
  const [loading, setLoading] = useState(false);
  const [endatoSearch, setEndatoSearch] = useState({});

  const getEndatoSearch = useCallback(async () => {
    try {
      setLoading(true);
      const res = await apiBase.get(`/endato-searches/${props.params.id}`);
      setEndatoSearch(get(res, "data", {}));
    } catch (err) {
      let msg = `${err.response.status}: Failed to load Endato search ${props.params.id}`;
      message.error(msg);
    } finally {
      setLoading(false);
    }
  }, [props.params.id]);

  useEffect(() => {
    getEndatoSearch();
  }, [getEndatoSearch]);

  const results = get(endatoSearch, "response_body", {});

  return (
    <div className="App__rowContent">
      <Helmet>
        <title>{`EndatoSearch: ${endatoSearch.id}`}</title>
      </Helmet>
      <div
        className={clsx({
          [styles.loading]: loading,
        })}
      >
        <div className={styles.jsonView}>
          <ReactJson
            src={results}
            collapsed={1}
            style={{
              fontSize: "12px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default withParams(EndatoSearch);
