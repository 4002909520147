import React, { Component } from "react";

import { QuestionCircleOutlined } from "@ant-design/icons";

import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";

import { Modal, Select, Tooltip } from "antd";
import { syncType, syncTypeOptions } from "../../common/pipedriveSyncs";

// import styles from "./InterestFilters.module.scss";

const CreatePipedriveSync = Form.create({
  name: "create_pipedrive_sync",
})(
  // eslint-disable-next-line
  class extends Component {
    render() {
      const { visible, isLoading, onCancel, onCreate, form } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal
          visible={visible}
          title="Create pipedrive sync"
          okText="Create"
          cancelText="Cancel"
          onCancel={onCancel}
          onOk={onCreate}
          confirmLoading={isLoading}
          maskClosable={false}
        >
          <Form layout="vertical">
            <Form.Item
              label={
                <span>
                  Sync type&nbsp;
                  <Tooltip
                    title="The type of sync to create."
                    placement="rightTop"
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator("type", {
                rules: [
                  {
                    required: true,
                  },
                ],
                initialValue: syncType.partial,
              })(
                <Select>
                  {syncTypeOptions.map((row, idx) => (
                    <Select.Option value={row.value} key={idx}>
                      {row.label}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

export default CreatePipedriveSync;
