import React, { useState } from "react";
import { useSelector } from "react-redux";
import { get } from "lodash";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Modal, Alert, Select, Tooltip, Checkbox, Form, Radio } from "antd";
import SearchSelect from "../common/SearchSelect";

import styles from "./PermitBulkEditForm.module.scss";

const PermitBulkEditForm = ({
  visible,
  isLoading,
  onCancel,
  onCreate,
  selectedPermits,
}) => {
  const [form] = Form.useForm();
  const [editFields, setEditFields] = useState(["label_ids"]);
  const [labelAction, setLabelAction] = useState("add"); // 'add' or 'remove'

  // Get labels from redux state
  const labels = useSelector((state) =>
    get(state.labels, "data", [])
      .filter((label) => label.record_type === "permit")
      .map((label) => ({
        value: label.id,
        label: label.value,
      }))
  );

  const options = [
    { label: "Labels", value: "label_ids" },
    { label: "Permit Plat", value: "permit_plat_id" },
  ];

  const handleFieldEdit = (checkedValues) => {
    setEditFields(checkedValues);
    // Reset form fields that are no longer selected
    const currentValues = form.getFieldsValue();
    const newValues = {};
    checkedValues.forEach((field) => {
      newValues[field] = currentValues[field];
    });
    form.setFieldsValue(newValues);
  };

  const resetForm = () => {
    setEditFields(["label_ids"]);
    setLabelAction("add");
    form.resetFields();
  };

  const title =
    selectedPermits.length === 0
      ? `Bulk Edit Permits - based on search filters`
      : `Bulk Edit Permits - ${selectedPermits.length} selected permits`;

  return (
    <Modal
      title={title}
      okText="Submit"
      cancelText="Cancel"
      open={visible}
      onCancel={() => {
        onCancel();
        resetForm();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            // Transform values based on the labelAction for label_ids
            const transformedValues = { ...values };
            if (editFields.includes("label_ids")) {
              transformedValues.label_action = labelAction;
            }
            onCreate(transformedValues);
            resetForm();
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      confirmLoading={isLoading}
      okButtonProps={{ disabled: editFields.length === 0 }}
      maskClosable={false}
    >
      <Alert
        message="Select the fields that you wish to bulk edit"
        type="info"
        style={{ marginBottom: 24 }}
      />

      <Checkbox.Group
        className={styles.checkBoxGroup}
        options={options}
        value={editFields}
        onChange={handleFieldEdit}
      />

      <Alert
        message="Set the new values for the selected fields"
        type="info"
        style={{ marginBottom: 24 }}
      />

      <Form form={form} layout="vertical">
        {editFields.includes("label_ids") && (
          <>
            <Form.Item
              label={
                <span>
                  Label Action&nbsp;
                  <Tooltip title="Choose whether to add or remove the selected labels">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
            >
              <Radio.Group
                value={labelAction}
                onChange={(e) => setLabelAction(e.target.value)}
              >
                <Radio value="add">Add Labels</Radio>
                <Radio value="remove">Remove Labels</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name="label_ids"
              label={
                <span>
                  Labels&nbsp;
                  <Tooltip title="Select the labels to add or remove">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              rules={[
                { required: true, message: "Please select at least one label" },
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Select labels"
                style={{ width: "100%" }}
                options={labels}
              />
            </Form.Item>
          </>
        )}

        {editFields.includes("permit_plat_id") && (
          <Form.Item
            name="permit_plat_id"
            label={
              <span>
                Permit Plat&nbsp;
                <Tooltip title="Select a permit plat to assign">
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
            rules={[{ required: true, message: "Please select a permit plat" }]}
          >
            <SearchSelect
              searchUri="permit-plats"
              searchField="name"
              placeholder="Search permit plats"
              renderLabel={(row) => `${row.name} (ID: ${row.id})`}
              renderExtra={false}
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default PermitBulkEditForm;
