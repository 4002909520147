import React, { Component } from "react";
import { withParams } from "../utils/withParams";
import { Link } from "react-router-dom";
import { apiBase } from "../utils/apiBase";
import { formatNumber, alphaSort } from "../utils/numbers";
import { formatDate } from "../utils/dates";
import { get, capitalize, cloneDeep } from "lodash";

import { DeceasedIcon, NTFIcon } from "./common/LabelIcon";
import TotalRecords from "./search/TotalRecords";

import { Row, Col, Table, Select, message, Tooltip, Input, Button } from "antd";
import { WarningOutlined } from "@ant-design/icons";

import styles from "./IDIBulkSearchDetail.module.scss";

class IDIBulkSearchDetail extends Component {
  state = {
    isLoading: false,
    idiBulkSearch: {},
    idiBulkSearchOwners: [],
    recordsPerPage: 1000,
    isIdiBulkSearchEdit: false,
    isLoadingIdiBulkSearchEdit: false,
    idiBulkSearchCopy: {},
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    await Promise.all([this.getIdiBulkSearch(), this.getIdiBulkSearchOwners()]);
    this.setState({ isLoading: false });
  }

  async getIdiBulkSearch() {
    return apiBase
      .get(`/idi-bulk-searches/${this.props.params.id}`)
      .then((res) => {
        this.setState({
          idiBulkSearch: res.data,
        });
      })
      .catch((err) => {
        let msg = `${get(
          err,
          "response.status"
        )}: Error loading IDI bulk search: ${this.props.params.id}`;
        message.error(msg);
      });
  }

  async getIdiBulkSearchOwners() {
    return apiBase
      .get(`/idi-bulk-searches/${this.props.params.id}/owners`)
      .then((res) => {
        this.setState({
          idiBulkSearchOwners: res.data
            .map((el, idx) => ({ ...el, ...{ key: idx } }))
            .sort((a, b) => a.id - b.id),
        });
      })
      .catch((err) => {
        let msg = `${get(
          err,
          "response.status"
        )}: Error loading IDI bulk search: ${this.props.params.id} owners`;
        message.error(msg);
      });
  }

  handleRowClick(e, _to) {
    e.stopPropagation();
    if (e.ctrlKey || e.metaKey) {
      window.open(_to, "_blank");
      return;
    }
    this.props.history(_to);
  }

  handleRecordsChange(value) {
    this.setState({
      recordsPerPage: value,
    });
  }

  setIdiBulkSearchEdit = () => {
    const { idiBulkSearch } = this.state;
    this.setState({
      isIdiBulkSearchEdit: true,
      idiBulkSearchCopy: cloneDeep(idiBulkSearch),
    });
  };

  handleIdiBulkSearchEditChange = (e) => {
    const { idiBulkSearch } = this.state;
    idiBulkSearch[e.target.name] = e.target.value;
    this.setState({ idiBulkSearch });
  };

  handleIdiBulkSearchEditSubmit = () => {
    const { idiBulkSearch } = this.state;
    let idiBulkSearchEdit = {
      idi_bulk_search_id: parseInt(idiBulkSearch.id, 10),
      name: idiBulkSearch.name,
    };
    this.setState({ isLoadingIdiBulkSearchEdit: true });
    apiBase
      .patch(`/idi-bulk-searches`, idiBulkSearchEdit)
      .then((res) => {
        this.setState({
          idiBulkSearch: res.data,
          isIdiBulkSearchEdit: false,
          isLoadingIdiBulkSearchEdit: false,
        });
        message.success("Successfully updated IDI bulk search");
      })
      .catch((err) => {
        this.setState({
          isLoadingIdiBulkSearchEdit: false,
        });
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
      });
  };

  cancelIdiBulkSearchEdit = () => {
    const { idiBulkSearchCopy } = this.state;
    this.setState({
      isIdiBulkSearchEdit: false,
      idiBulkSearch: idiBulkSearchCopy,
    });
  };

  render() {
    const {
      idiBulkSearch,
      idiBulkSearchOwners,
      isLoading,
      isIdiBulkSearchEdit,
      isLoadingIdiBulkSearchEdit,
    } = this.state;

    const columns = [
      {
        title: "Owner id",
        dataIndex: ["owner", "id"],
        className: "App__rowLink",
        render: (value) => <div className="App__rowIcon">{value}</div>,
        onCell: (record) => ({
          onClick: (e) => this.handleRowClick(e, `/owners/${record.owner.id}`),
        }),
        width: "100px",
        sorter: (a, b) => a.owner.id - b.owner.id,
      },
      {
        title: "Name formatted",
        dataIndex: ["owner", "name_formatted"],
        sorter: (a, b) =>
          alphaSort(a.owner.name_formatted, b.owner.name_formatted),
      },
      {
        title: "IDI name",
        dataIndex: ["owner", "last_idi_person", "name"],
        render: (value) => value,
        sorter: (a, b) =>
          alphaSort(
            get(a, "owner.last_idi_person.name", ""),
            get(b, "owner.last_idi_person.name", "")
          ),
      },
      {
        title: "First name",
        dataIndex: ["owner", "first_name"],
        sorter: (a, b) => alphaSort(a.owner.first_name, b.owner.first_name),
      },
      {
        title: "Last name",
        dataIndex: ["owner", "last_name"],
        sorter: (a, b) => alphaSort(a.owner.last_name, b.owner.last_name),
      },
      {
        title: "Address formatted",
        dataIndex: ["owner", "address_formatted"],
        width: "200px",
        sorter: (a, b) =>
          alphaSort(a.owner.address_formatted, b.owner.address_formatted),
      },
      {
        title: "IDI street",
        dataIndex: ["owner", "last_idi_person", "street"],
        width: "200px",
        render: (value) => value,
        sorter: (a, b) =>
          alphaSort(
            get(a, "owner.last_idi_person.street", ""),
            get(b, "owner.last_idi_person.street", "")
          ),
      },
      {
        title: "City",
        dataIndex: ["owner", "city"],
        sorter: (a, b) => alphaSort(a.owner.city, b.owner.city),
      },
      {
        title: "IDI city",
        dataIndex: ["owner", "last_idi_person", "city"],
        render: (value) => value,
        sorter: (a, b) =>
          alphaSort(
            get(a, "owner.last_idi_person.city", ""),
            get(b, "owner.last_idi_person.city", "")
          ),
      },
      {
        title: "State",
        dataIndex: ["owner", "state"],
        sorter: (a, b) => alphaSort(a.owner.state, b.owner.state),
      },
      {
        title: "IDI state",
        dataIndex: ["owner", "last_idi_person", "state"],
        render: (value) => value,
        sorter: (a, b) =>
          alphaSort(
            get(a, "owner.last_idi_person.state", ""),
            get(b, "owner.last_idi_person.state", "")
          ),
      },
      {
        title: "Zip code",
        dataIndex: ["owner", "zip_code"],
        sorter: (a, b) => alphaSort(a.owner.zip_code, b.owner.zip_code),
      },
      {
        title: "IDI zip code",
        dataIndex: ["owner", "last_idi_person", "zip_code"],
        render: (value) => value,
        sorter: (a, b) =>
          alphaSort(
            get(a, "owner.last_idi_person.zip_code", ""),
            get(b, "owner.last_idi_person.zip_code", "")
          ),
      },
      {
        title: "IDI deceased",
        dataIndex: ["owner", "last_idi_person", "deceased"],
        render: (value) => <DeceasedIcon value={value} />,
        sorter: (a, b) =>
          get(a, "owner.last_idi_person.deceased", false) -
          get(b, "owner.last_idi_person.deceased", false),
        width: 110,
      },
      {
        title: "NTF",
        dataIndex: ["owner", "ntf"],
        render: (value) => <NTFIcon value={value} />,
        sorter: (a, b) => get(a, "owner.ntf") - get(b, "owner.ntf"),
        width: 80,
      },
      {
        title: "IDI search id",
        dataIndex: ["idi_search", "id"],
        render: (value) => <Link to={`/idi-searches/${value}`}>{value}</Link>,
        sorter: (a, b) => a.idi_search.id - b.idi_search.id,
        width: 110,
      },
      {
        title: "IDI search total results",
        dataIndex: ["idi_search", "total_results"],
        sorter: (a, b) =>
          a.idi_search.total_results - b.idi_search.total_results,
        width: 110,
      },
      {
        title: "Last IDI person id",
        dataIndex: ["owner", "last_idi_person_id"],
        render: (value) => <Link to={`/idi-persons/${value}`}>{value}</Link>,
        sorter: (a, b) =>
          a.owner.last_idi_person_id - b.owner.last_idi_person_id,
        width: 110,
      },
      {
        title: "Last IDI person search total results",
        dataIndex: ["owner", "last_idi_person", "idi_search", "total_results"],
        width: 150,
        render: (value) => (
          <>
            {value}
            {value > 1 && (
              <Tooltip title="Be careful here, there are multiple results so you might have the wrong person. Consider a more specific search or look through the results and pick the correct one.">
                <WarningOutlined
                  style={{ color: "orange", marginLeft: "5px" }}
                />
              </Tooltip>
            )}
          </>
        ),
        sorter: (a, b) =>
          get(a, "owner.last_idi_person.idi_search.total_results", 0) -
          get(b, "owner.last_idi_person.idi_search.total_results", 0),
      },
    ];

    return (
      <div className="App__rowContentDetail">
        <Row>
          <Col span={24}>
            <div className={styles.card}>
              <div className={styles.cardTitle}>
                <div className={styles.leftTitle}>
                  <h2>IDI Bulk Search</h2>
                </div>
                <div className={styles.rightTitle}>
                  {!isIdiBulkSearchEdit ? (
                    <div>
                      <Button
                        onClick={() => this.setIdiBulkSearchEdit()}
                        className={styles.cardHeaderAction}
                      >
                        Edit
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <Button
                        onClick={() => this.cancelIdiBulkSearchEdit()}
                        className={styles.cardHeaderAction}
                        disabled={isLoadingIdiBulkSearchEdit}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => this.handleIdiBulkSearchEditSubmit()}
                        className={styles.cardHeaderAction}
                        type="primary"
                        loading={isLoadingIdiBulkSearchEdit}
                      >
                        Save
                      </Button>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.cardBody}>
                <Row gutter={24}>
                  <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className={styles.objectData}>
                      <span>Id:</span>
                      {idiBulkSearch.id}
                    </div>
                    <div className={styles.objectData}>
                      <span>Name:</span>
                      <div onClick={() => this.setIdiBulkSearchEdit()}>
                        <Input
                          value={idiBulkSearch.name}
                          onChange={(e) =>
                            this.handleIdiBulkSearchEditChange(e)
                          }
                          onPressEnter={(e) => {
                            this.handleIdiBulkSearchEditSubmit();
                            e.target.blur();
                          }}
                          className={
                            isIdiBulkSearchEdit
                              ? styles.inputActive
                              : styles.inputDisabled
                          }
                          disabled={isLoadingIdiBulkSearchEdit}
                          name="name"
                          type="string"
                          size="small"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className={styles.objectData}>
                      <span>Created At:</span>
                      {formatDate(
                        idiBulkSearch.created_at,
                        "YYYY-MM-DD, h:mm:ss A"
                      )}
                    </div>
                    <div className={styles.objectData}>
                      <span>Updated At:</span>
                      {formatDate(
                        idiBulkSearch.updated_at,
                        "YYYY-MM-DD, h:mm:ss A"
                      )}
                    </div>
                    <div className={styles.objectData}>
                      <span>Started At:</span>
                      {formatDate(
                        idiBulkSearch.started_at,
                        "YYYY-MM-DD, h:mm:ss A"
                      )}
                    </div>
                    <div className={styles.objectData}>
                      <span>Stopped At:</span>
                      {formatDate(
                        idiBulkSearch.stopped_at,
                        "YYYY-MM-DD, h:mm:ss A"
                      )}
                    </div>
                    <div className={styles.objectData}>
                      <span>Runtime (s):</span>
                      {idiBulkSearch.runtime_seconds}
                    </div>
                  </Col>
                  <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className={styles.objectData}>
                      <span>Status:</span>
                      {capitalize(idiBulkSearch.status)}
                    </div>
                    <div className={styles.objectData}>
                      <span>Progress:</span>
                      {`${formatNumber(idiBulkSearch.progress, "0.00")}%`}
                    </div>
                    <div className={styles.objectData}>
                      <span>Percentage Success:</span>
                      {idiBulkSearch.count_success > 0
                        ? `${(
                            (idiBulkSearch.count_with_results /
                              idiBulkSearch.count_success) *
                            100
                          ).toFixed(2)}%`
                        : "-"}
                    </div>
                    <div className={styles.objectData}>
                      <span>Count Owners:</span>
                      {idiBulkSearch.count_owners}
                    </div>
                    <div className={styles.objectData}>
                      <span>Count Leads:</span>
                      {idiBulkSearch.count_leads}
                    </div>
                    {/* <div className={styles.objectData}>
                      <span>Count Failed:</span>
                      {idiBulkSearch.count_failed}
                    </div>
                    <div className={styles.objectData}>
                      <span>Count Success:</span>
                      {idiBulkSearch.count_success}
                    </div> */}
                    <div className={styles.objectData}>
                      <span>Count success:</span>
                      {idiBulkSearch.count_with_results}
                    </div>
                    <div className={styles.objectData}>
                      <span>Count failed:</span>
                      {idiBulkSearch.count_without_results}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className={styles.card}>
              <div className={styles.cardTitle}>
                <div className={styles.leftTitle}>
                  <h2>Owners</h2>
                </div>
                <div className={styles.rightTitle}>
                  <Select
                    value={this.state.recordsPerPage}
                    onChange={this.handleRecordsChange.bind(this)}
                    className={"App__mr-5"}
                  >
                    <Select.Option value={25}>25</Select.Option>
                    <Select.Option value={50}>50</Select.Option>
                    <Select.Option value={100}>100</Select.Option>
                    <Select.Option value={1000}>1000</Select.Option>
                  </Select>
                  <TotalRecords
                    title={"Owners"}
                    totalRecords={idiBulkSearchOwners.length}
                  />
                </div>
              </div>
              <div className={styles.cardBody}>
                <Table
                  columns={columns}
                  dataSource={idiBulkSearchOwners.sort((a, b) => {
                    const aResults =
                      a.owner?.last_idi_person?.idi_search?.total_results || 0;
                    const bResults =
                      b.owner?.last_idi_person?.idi_search?.total_results || 0;
                    return aResults - bResults;
                  })}
                  pagination={{
                    pageSize: this.state.recordsPerPage,
                    showSizeChanger: false,
                  }}
                  className="App__smallTables"
                  showSorterTooltip={false}
                  loading={isLoading}
                  scroll={{ x: "100%", y: "calc(100vh - 660px)" }}
                  bordered
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withParams(IDIBulkSearchDetail);
