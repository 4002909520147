import React, { useState } from "react";
import { Modal, Form, Select, message, Button } from "antd";
import { apiBase } from "../../utils/apiBase";
import {
  reviewStatusesOptions,
  defaultReviewStatus,
} from "../../common/unitReviewStatus";

const UnitReviewStatusModal = (props) => {
  const { selectedUnits, handleSearch, resetSelectedUnits } = props;

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const onFinish = async (values) => {
    setLoading(true);

    let data = {
      unit_ids: selectedUnits.map((row) => row.id),
      review_status: values.review_status,
    };

    apiBase
      .post("/units/review", data)
      .then((res) => {
        handleSearch();
        resetSelectedUnits();
        setIsModalOpen(false);
        form.resetFields();
        let msg = `${res.status}: ${res.data.message}`;
        message.success(msg);
      })
      .catch((err) => {
        let msg = `${err.response.status}: ${err.response.data.message}`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const submitForm = () => {
    form
      .validateFields()
      .then(() => {
        form.submit();
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };

  const title =
    selectedUnits.length === 0
      ? `Update review status`
      : `Update review status - ${selectedUnits.length} selected units`;

  return (
    <>
      {selectedUnits.length > 0 && (
        <Button type="secondary" onClick={showModal} className="App__mr-5">
          Update review status
        </Button>
      )}
      <Modal
        title={title}
        open={isModalOpen}
        onOk={() => submitForm()}
        onCancel={handleCancel}
        okText="Update"
        cancelText="Cancel"
        okButtonProps={{ loading: loading }}
      >
        <Form
          form={form}
          name="unitReviewStatusChange"
          layout="vertical"
          onFinish={onFinish}
          initialValues={{ reviewStatus: defaultReviewStatus }}
        >
          <Form.Item label="Review Status" name="review_status">
            <Select options={reviewStatusesOptions} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UnitReviewStatusModal;
