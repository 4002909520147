import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "antd";
import clsx from "clsx";

import { LAYER_ID } from "../../layers/wells";
import mapActions from "../../../../actions/map";

import styles from "./Styles.module.scss";

const WellsStyles = ({ mapRef }) => {
  const dispatch = useDispatch();

  // Get layer state from Redux
  const annotationVisible = useSelector(
    (state) => state.map.styles[LAYER_ID].annotationVisible
  );

  return (
    <div className={styles.layer}>
      <div className={styles.layerHeader}>
        <Switch
          checked={annotationVisible}
          onChange={() =>
            dispatch(
              mapActions.setLayerAnnotationVisibility(
                mapRef,
                LAYER_ID,
                !annotationVisible
              )
            )
          }
          size="small"
          className={clsx(styles.switch, {
            [styles.switchOn]: annotationVisible,
            [styles.switchOff]: !annotationVisible,
          })}
        />
        <div className={styles.label}>Wells</div>
      </div>
      <div className={styles.layerBody}></div>
    </div>
  );
};

export default WellsStyles;
