import React from "react";
import { connect } from "react-redux";
import { get, isEmpty, find } from "lodash";
import { Tooltip } from "antd";
import { clsx } from "clsx";
import { guid } from "../../utils/numbers";

import styles from "./PipedrivePersonLabels.module.scss";

const mapStateToProps = (state) => ({
  appConfig: get(state.appConfig, "data", {}),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const backgroundColorMapper = {
  yellow: "#ffdf5c",
  blue: "#317ae2",
  gray: "#e4e6e9",
  red: "#f94839",
  pupple: "#721ea9",
  pink: "#fc6c85",
};

function PipedrivePersonLabels(props) {
  const { appConfig, pipedrivePersons } = props;
  const pipedriveLabelOptions = get(appConfig, "pipedrive_person_labels", []);

  if (Array.isArray(pipedrivePersons)) {
    return (
      <div className={styles.container}>
        {pipedrivePersons.map((pp, idx) => {
          const label_ids = !isEmpty(get(pp, "label_ids", [""]))
            ? get(pp, "label_ids", [""])
            : [""];
          return label_ids.map((label_id) => {
            const label = get(
              find(pipedriveLabelOptions, (o) => o.id === label_id),
              "label",
              "Empty"
            );
            const backgroundColor = get(
              find(pipedriveLabelOptions, (o) => o.id === label_id),
              "color",
              "gray"
            );

            return (
              <Tooltip placement="topLeft" title={get(pp, "id")} key={guid()}>
                <div
                  className={clsx(styles.pipedriveLabel, {
                    [styles.blackColor]: ["gray", "yellow"].includes(
                      backgroundColor
                    ),
                  })}
                  style={{
                    backgroundColor: get(
                      backgroundColorMapper,
                      backgroundColor,
                      backgroundColor
                    ),
                  }}
                  onClick={() =>
                    window.open(
                      `https://taborminerals.pipedrive.com/person/${get(
                        pp,
                        "id"
                      )}`
                    )
                  }
                >
                  {label}
                </div>
              </Tooltip>
            );
          });
        })}
      </div>
    );
  } else {
    return null;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PipedrivePersonLabels);
