import React from "react";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import mapActions from "../../../../actions/map";

import WellsLayer from "./WellsLayer";
import LandtracUnitsLayer from "./LandtracUnitsLayer";
import UnitsLayer from "./UnitsLayer";
import AssetsLayer from "./AssetsLayer";
import PermitLayer from "./PermitLayer";
import TractsLayer from "./TractsLayer";

import { ArrowLeftOutlined } from "@ant-design/icons";
import { CONTROL_IDS } from "../../constants";
import { Switch } from "antd";

import styles from "./Layers.module.scss";

const Layers = ({ mapRef, controlVisible, setControlVisible }) => {
  const dispatch = useDispatch();
  const zoomToFilter = useSelector((state) => state.map.zoomToFilter);

  return (
    <div
      className={clsx(styles.control, {
        [styles.invisible]: controlVisible !== CONTROL_IDS.FILTERS,
      })}
    >
      <div className={styles.controlHeader}>
        <div></div>
        <div>Layers</div>
        <div className={styles.controlCollapseButton}>
          <ArrowLeftOutlined onClick={() => setControlVisible(null)} />
        </div>
      </div>
      <div className={styles.controlBody}>
        <div className={styles.zoomToFilter}>
          <div className="App__mr-8">
            <Switch
              checked={zoomToFilter}
              onChange={() =>
                dispatch(mapActions.setZoomToFilter(!zoomToFilter))
              }
              size="small"
              className={clsx(styles.switch, {
                [styles.switchOn]: zoomToFilter,
                [styles.switchOff]: !zoomToFilter,
              })}
            />
          </div>
          <div>Zoom to filter</div>
        </div>
        <div className={styles.layersContainer}>
          <WellsLayer mapRef={mapRef} />
        </div>
        <div className={styles.layersContainer}>
          <LandtracUnitsLayer mapRef={mapRef} />
        </div>
        <div className={styles.layersContainer}>
          <UnitsLayer mapRef={mapRef} />
        </div>
        <div className={styles.layersContainer}>
          <AssetsLayer mapRef={mapRef} />
        </div>
        <div className={styles.layersContainer}>
          <PermitLayer mapRef={mapRef} />
        </div>
        <div className={styles.layersContainer}>
          <TractsLayer mapRef={mapRef} />
        </div>
      </div>
    </div>
  );
};

export default Layers;
