import React, { Component } from "react";
import { get } from "lodash";
import { columnTypes } from "../../utils/search";
import BaseSearch from "../search/BaseSearch";
import { arrayContainsOperators } from "../search/constants";
import FilterTags from "../search/FilterTags";
import Took from "../search/Took";
import TotalRecords from "../search/TotalRecords";
import PipedrivePersonLabels from "../common/PipedrivePersonLabels";
import { NTFIcon } from "../common/LabelIcon";

import ExportForm from "../forms/ExportForm";
import ChangeColumnsForm from "../forms/ChangeColumnsForm";
import { yearsOptions } from "../../common/taxYear";

import { DownloadOutlined, TableOutlined } from "@ant-design/icons";

import { Row, Col, Table, Select, Button, Tooltip, Pagination } from "antd";

import styles from "./Owners.module.scss";

class Owners extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  interestFilterStyles(record) {
    if (record.interest_filter === true) {
      return styles.badInterest;
    }
  }

  render() {
    const {
      isLoadingRecords,
      isLoadingCount,
      records,
      columnState,
      limit,
      page,
      totalRecords,
      tookRecords,
      tookCount,
      searchFields,
      isExportModalVisible,
      isChangeColumnsVisible,
      isLoadingExport,
    } = this.props;

    const {
      handleSearch,
      handleRecordsChange,
      handlePaginationChange,
      handleFilterClose,
      handleTableChange,
      handleSubmitExport,
      showExportModal,
      handleCancelExport,
      handleSubmitColumnChange,
      showColumnChangeModal,
      handleCancelColumnsChange,
      getColumnProps,
      exportFormRef,
      changeColumnFormRef,
    } = this.props;

    let columns = columnState
      .map((column, idx) => ({
        key: idx,
        ...column,
        ...getColumnProps(column),
      }))
      .filter((col) => col.visible === true);

    let ownerNameRegex = this.props.interestFilters.owner_name;
    records.forEach((row) => {
      row["interest_filter"] = RegExp(ownerNameRegex, "i").test(
        row.name_formatted
      );
    });

    return (
      <div>
        <Row className="App__rowContent">
          <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16}>
            <Button className="App__mr-5" onClick={() => handleSearch()}>
              Search
            </Button>
            <FilterTags
              searchFields={searchFields}
              columnState={columnState}
              handleFilterClose={handleFilterClose}
            />
          </Col>
          <Col
            span={8}
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            className="App__rightActions"
          >
            <Tooltip placement="bottomRight" title={"Export search results"}>
              <Button className="App__iconButton" onClick={showExportModal}>
                <DownloadOutlined />
              </Button>
            </Tooltip>
            <ExportForm
              wrappedComponentRef={exportFormRef}
              visible={isExportModalVisible}
              isLoading={isLoadingExport}
              onCancel={handleCancelExport}
              onCreate={handleSubmitExport}
              totalRecords={totalRecords}
            />
            <Tooltip placement="bottomRight" title={"Change visible columns"}>
              <Button
                onClick={showColumnChangeModal}
                className="App__iconButton"
              >
                <TableOutlined />
              </Button>
            </Tooltip>
            <ChangeColumnsForm
              wrappedComponentRef={changeColumnFormRef}
              visible={isChangeColumnsVisible}
              isLoading={isLoadingRecords}
              onCancel={handleCancelColumnsChange}
              onOk={handleSubmitColumnChange}
              columnState={columnState}
            />
            <Select
              value={limit}
              onChange={handleRecordsChange}
              className={"App__mr-5"}
            >
              <Select.Option value={25}>25</Select.Option>
              <Select.Option value={50}>50</Select.Option>
              <Select.Option value={100}>100</Select.Option>
              <Select.Option value={1000}>1000</Select.Option>
            </Select>
            <TotalRecords
              title={"Owners"}
              isLoadingCount={isLoadingCount}
              totalRecords={totalRecords}
            />
          </Col>
        </Row>
        <Row className="App__rowContent">
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={records}
              onChange={handleTableChange}
              pagination={false}
              showSorterTooltip={false}
              className="App__smallTables"
              rowClassName={this.interestFilterStyles}
              loading={isLoadingRecords}
              scroll={{ x: "100%" }}
              size="small"
              bordered
            />
            <div className="App__searchFooterContainer">
              <Took
                isLoadingRecords={isLoadingRecords}
                isLoadingCount={isLoadingCount}
                tookRecords={tookRecords}
                tookCount={tookCount}
              />
              <Pagination
                current={page}
                pageSize={limit}
                total={totalRecords}
                onChange={handlePaginationChange}
                className="App__pagination"
                showSizeChanger={false}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const OwnersWithSearch = (props) => {
  const baseColumnState = [
    {
      dataIndex: "id",
      title: "Id",
      editable: false,
      type: columnTypes.LINK,
      baseUrl: "/owners",
      sortable: true,
    },
    {
      title: "Pipedrive label",
      dataIndex: ["pipedrive_person_label_ids"],
      className: `App__w12`,
      exportable: false,
      type: columnTypes.SELECT_MULTI,
      globalStateKey: "appConfig.data.pipedrive_person_labels",
      globalStateOptions: (data) =>
        data.map((d) => ({ value: String(d.id), label: d.label })),
      operatorOptions: arrayContainsOperators,
      format: ({ record }) => {
        return (
          <PipedrivePersonLabels
            pipedrivePersons={get(record, "pipedrive_persons", [])}
          />
        );
      },
    },
    {
      title: "Name formatted",
      dataIndex: "name_formatted",
      type: columnTypes.STRING,
      first: true,
    },
    {
      title: "Name",
      dataIndex: "name",
      type: columnTypes.STRING,
    },
    {
      title: "First name",
      dataIndex: "first_name",
      type: columnTypes.STRING,
      // visible: false,
    },
    {
      title: "Middle name",
      dataIndex: "middle_name",
      type: columnTypes.STRING,
      visible: true,
    },
    {
      title: "Last name",
      dataIndex: "last_name",
      type: columnTypes.STRING,
      // visible: false,
    },
    {
      title: "Address formatted",
      dataIndex: "address_formatted",
      type: columnTypes.STRING,
    },
    {
      title: "Address",
      dataIndex: "address",
      type: columnTypes.STRING,
    },
    {
      title: "City",
      dataIndex: "city",
      type: columnTypes.STRING,
    },
    {
      title: "State",
      dataIndex: "state",
      type: columnTypes.STRING,
    },
    {
      title: "Zip code",
      dataIndex: "zip_code",
      type: columnTypes.STRING,
    },
    {
      title: "NTF",
      dataIndex: "ntf",
      type: columnTypes.SELECT,
      options: [
        { value: "false", label: "False" },
        { value: "true", label: "True" },
      ],
      className: `App__w6`,
      selectClassName: `App__w6`,
      format: ({ value }) => <NTFIcon value={value} />,
    },
    {
      title: "Unit Count",
      dataIndex: "unit_count",
      type: columnTypes.INTEGER,
    },
    {
      title: "Address unit count",
      dataIndex: "address_unit_count",
      type: columnTypes.INTEGER,
    },
    {
      title: "Mailing Street",
      dataIndex: "mailing_street",
      type: columnTypes.STRING,
      visible: false,
    },
    {
      title: "Mailing City",
      dataIndex: "mailing_city",
      type: columnTypes.STRING,
      visible: false,
    },
    {
      title: "Mailing State",
      dataIndex: "mailing_state",
      type: columnTypes.STRING,
      visible: false,
    },
    {
      title: "Mailing Zip Code",
      dataIndex: "mailing_zip_code",
      type: columnTypes.STRING,
      visible: false,
    },
    {
      title: "Tax year",
      dataIndex: "tax_year",
      className: `App__w10`,
      type: columnTypes.SELECT,
      options: yearsOptions,
    },
    {
      title: "Tax year count",
      dataIndex: "tax_year_count",
      className: `App__w12`,
      type: columnTypes.INTEGER,
    },
  ];

  return (
    <BaseSearch
      baseColumnState={baseColumnState}
      localSortColumns={[]}
      searchUri="owners"
    >
      <Owners {...props} />
    </BaseSearch>
  );
};

export default OwnersWithSearch;
