import React from "react";
import { connect } from "react-redux";
import { get } from "lodash";

import { Form, Select, InputNumber, Input } from "antd";
import { convertDataIndex } from "../../utils/search";
import clsx from "clsx";

import "@ant-design/compatible/assets/index.css";

import styles from "./TableUtils.module.scss";

const mapStateToProps = (state) => ({
  users: get(state.users, "data", []),
  leadStatus: get(state.appConfig, "data.lead_status", []),
});

const mapDispatchToProps = () => ({});

const formItemStyles = {
  margin: 0,
  minHeight: "24px",
};

const _EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  users,
  leadStatus,
  ...restProps
}) => {
  const _dataIndex = convertDataIndex(dataIndex);
  if (!editing) {
    return <td {...restProps}>{children}</td>;
  }

  return (
    <td {...restProps}>
      {_dataIndex === "user.alias" && (
        <Form.Item
          name={_dataIndex}
          style={formItemStyles}
          className={styles.formItem}
          rules={[
            {
              required: true,
              message: "Required",
            },
          ]}
        >
          <Select
            size="small"
            className={`App__fullWidth ${styles.fixSelection}`}
          >
            {users.map((user, idx) => (
              <Select.Option value={user.alias} key={idx}>
                {user.alias}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {_dataIndex === "status" && (
        <Form.Item
          name={_dataIndex}
          style={formItemStyles}
          className={styles.formItem}
          rules={[
            {
              required: true,
              message: "Required",
            },
          ]}
        >
          <Select
            size="small"
            className={`App__fullWidth ${styles.fixSelection}`}
          >
            {leadStatus.map((status, idx) => (
              <Select.Option value={status} key={idx}>
                {status}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {["offer_amount", "purchase_price", "dollar_per_nra"].includes(
        _dataIndex
      ) && (
        <Form.Item
          name={_dataIndex}
          style={formItemStyles}
          className={styles.formItem}
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        >
          <InputNumber
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            size="small"
            className="App__fullWidth"
          />
        </Form.Item>
      )}
      {["acres", "decimal_interest"].includes(_dataIndex) && (
        <Form.Item
          name={_dataIndex}
          style={formItemStyles}
          className={styles.formItem}
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        >
          <InputNumber className={"App__fullWidth"} size="small" />
        </Form.Item>
      )}
      {["asset_allocation", "revenue_allocation", "unit_allocation"].includes(
        _dataIndex
      ) && (
        <Form.Item
          name={_dataIndex}
          style={formItemStyles}
          className={styles.formItem}
          rules={[
            {
              required: true,
              message: "",
              type: "number",
              max: 100,
              min: 0,
            },
          ]}
        >
          <InputNumber
            precision={8}
            addonAfter={"%"}
            placeholder="0.00"
            className="App__fullWidth"
            size="small"
          />
        </Form.Item>
      )}
      {["nra", "allocation", "cost_basis", "capitalized_expenses"].includes(
        _dataIndex
      ) && (
        <Form.Item
          name={_dataIndex}
          style={formItemStyles}
          className={styles.formItem}
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        >
          <InputNumber
            disabled={true}
            className={clsx("App__fullWidth", {
              [styles.disabledItem]: true,
            })}
            size="small"
          />
        </Form.Item>
      )}
      {["description"].includes(_dataIndex) && (
        <Form.Item
          name={_dataIndex}
          style={formItemStyles}
          className={styles.formItem}
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        >
          <Input className={"App__fullWidth"} size="small" />
        </Form.Item>
      )}
    </td>
  );
};

const EditableCell = connect(
  mapStateToProps,
  mapDispatchToProps
)(_EditableCell);

export { EditableCell };
