import React, { useState, useCallback, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { clsx } from "clsx";

import { apiBase } from "../../utils/apiBase";
import { withParams } from "../../utils/withParams";
import { get, capitalize, isEmpty, cloneDeep } from "lodash";

import { formatDate } from "../../utils/dates";
import { formatNumber } from "../../utils/numbers";

import RawDataModal from "../common/RawDataModal";

import {
  HomeOutlined,
  MobileOutlined,
  QuestionCircleOutlined,
  ExclamationCircleFilled,
  RedoOutlined,
  UsergroupAddOutlined,
  WarningOutlined,
  DownOutlined,
  UpOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";

import { ReactComponent as TwilioIcon } from "../../assets/icons/twilio.svg";

import { Modal, message, Tooltip } from "antd";

import { CheckCircleTwoTone, WarningTwoTone } from "@ant-design/icons";

import styles from "./IDIPersonCard.module.scss";

const { confirm } = Modal;

const THIS_YEAR = new Date().getFullYear();
const ADDRESS_RECENT_YEAR = THIS_YEAR - 3;
const PHONE_RECENT_YEAR = THIS_YEAR - 3;

const _formatDate = (dateString) => {
  // Extract year, month, and day from the input string
  const year = dateString.substring(0, 4);
  const month = dateString.substring(4, 6);
  const day = dateString.substring(6, 8);

  // Format the date as MM/DD/YYYY
  return `${month}/${day}/${year}`;
};

const calculateBirthYear = (age) => {
  const ageNumber = Number(age);

  if (String(ageNumber) === "NaN") {
    return "";
  }

  // Get the current year
  const currentYear = new Date().getFullYear();
  const minYear = String(currentYear - 1 - ageNumber);
  const maxYear = String(currentYear - ageNumber);

  // Calculate the birth year
  return `(${minYear}, ${maxYear})`;
};

const transformDatePart = (datePart) => {
  return datePart === "00" ? "01" : datePart;
};

const getMonthsDifference = (lastSeen, firstSeen) => {
  try {
    // If there's no hyphen, return 0
    if (!lastSeen || !firstSeen) {
      return 0;
    }

    // Parse dates
    const start = new Date(
      String(firstSeen).substring(0, 4) +
        "-" +
        transformDatePart(String(firstSeen).substring(4, 6)) +
        "-" +
        transformDatePart(String(firstSeen).substring(6, 8))
    );
    const end = new Date(
      String(lastSeen).substring(0, 4) +
        "-" +
        transformDatePart(String(lastSeen).substring(4, 6)) +
        "-" +
        transformDatePart(String(lastSeen).substring(6, 8))
    );

    // Calculate months difference
    const monthsDiff =
      (end.getFullYear() - start.getFullYear()) * 12 +
      (end.getMonth() - start.getMonth());
    return monthsDiff || 0;
  } catch (e) {
    return 0;
  }
};

const getMostRecentYear = (lastSeen) => {
  try {
    if (!lastSeen) {
      return null;
    }

    const endYear = parseInt(String(lastSeen).substring(0, 4));
    return endYear;
  } catch (e) {
    return null;
  }
};

const processAddresses = (addresses, properties) => {
  return addresses.map((address) => {
    const newAddress = cloneDeep(address);
    if (get(address, "ownership")) {
      const property = properties.find(
        (p) => get(p, "address.data") === get(address, "data")
      );
      if (property) {
        newAddress["property"] = property;
      }
    }

    const firstSeen = get(newAddress, "meta.firstSeen", {});
    const lastSeen = get(newAddress, "meta.lastSeen", {});
    const monthsDifference = getMonthsDifference(lastSeen, firstSeen);
    newAddress["monthsDifference"] = monthsDifference;

    const mostRecentYear = getMostRecentYear(lastSeen);
    newAddress["mostRecentYear"] = mostRecentYear;

    return newAddress;
  });
};

const processPhones = (phones) => {
  return phones.map((phone) => {
    const newPhone = cloneDeep(phone);
    const firstSeen = get(newPhone, "meta.firstSeen", {});
    const lastSeen = get(newPhone, "meta.lastSeen", {});
    const monthsDifference = getMonthsDifference(lastSeen, firstSeen);
    newPhone["monthsDifference"] = monthsDifference;

    const mostRecentYear = getMostRecentYear(lastSeen);
    newPhone["mostRecentYear"] = mostRecentYear;

    return newPhone;
  });
};

const RelationType = ({
  title,
  relations,
  defaultCollapsed = false,
  handleSearch,
}) => {
  const [collapsed, setCollapsed] = useState(defaultCollapsed);

  if (!relations.length) return null;

  return (
    <div className={styles.relationType}>
      <div className={styles.relationHeader}>
        <div>{title}:</div>
        <div className={styles.count} onClick={() => setCollapsed(!collapsed)}>
          {relations.length} found{" "}
          {collapsed ? <UpOutlined /> : <DownOutlined />}
        </div>
      </div>
      {!collapsed && (
        <div className={styles.relationBody}>
          {relations.map((relation, idx) => {
            const firstName = capitalize(get(relation, "name.first"));
            const lastName = capitalize(get(relation, "name.last"));
            const age = get(relation, "dob.age");
            const pid = get(relation, "name.pid");

            return (
              <div className={styles.relation} key={idx}>
                <div onClick={() => handleSearch({ pid })}>
                  <span
                    className={styles.linkReal}
                  >{`${firstName} ${lastName} `}</span>
                  <span>({age})</span>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const RenderIndicator = ({ title, data }) => {
  if (isEmpty(data)) return <span>-</span>;

  return (
    <span className={styles.link}>
      <RawDataModal
        modalProps={{ title: title }}
        data={data}
        reactJsonProps={{ collapsed: true }}
        iconOnly={true}
      />
    </span>
  );
};

const IDIPersonCard = (props) => {
  const { isRecord, idiPersonId } = props;

  const [loading, setLoading] = useState(false);
  const [idiPerson, setIDIPerson] = useState({});
  const [twilioPhones, setTwilioPhones] = useState([]);
  const [uspsAddresses, setUSPSAddresses] = useState([]);

  const history = useNavigate(); // navigate

  const getIDIPerson = async (id) => {
    try {
      setLoading(true);
      const res = await apiBase.get(`/idi-persons/${id}`);
      setIDIPerson(get(res, "data", {}));
    } catch (err) {
      let msg = `${err.response.status}: Failed to load IDI person ${id}`;
      message.error(msg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (idiPersonId) {
      getIDIPerson(idiPersonId);
    }
  }, [idiPersonId]);

  useEffect(() => {
    setIDIPerson(props.idiPerson);
  }, [props.idiPerson]);

  const searchIDIPerson = ({ pid, refresh = false }) => {
    setLoading(true);
    apiBase
      .post(`/idi-persons`, { pid, refresh })
      .then((res) => {
        const idiPersonId = get(res, "data.id");
        if (get(idiPerson, "id") === idiPersonId) {
          setIDIPerson(get(res, "data"));
        } else {
          history(`/idi-persons/${idiPersonId}`);
        }
      })
      .catch((err) => {
        let msg = `${get(
          err,
          "response.status"
        )}: Failed to find person with id ${pid}.`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const endatoEnrichIDIPerson = ({ pid }) => {
    setLoading(true);
    apiBase
      .post(`/endato-searches/enrich`, { pid })
      .then((res) => {
        getIDIPerson(pid);
      })
      .catch((err) => {
        let msg = `${get(
          err,
          "response.status"
        )}: Failed to find person with id ${pid}.`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSearch = ({ pid, refresh = false }) => {
    const message = refresh
      ? "Are you sure you want to refresh this person?"
      : "Are you sure you want to search this person?";

    confirm({
      title: message,
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "info",
      cancelText: "No",
      okButtonProps: { disabled: loading, loading: loading },
      cancelButtonProps: { disabled: loading, loading: loading },
      onOk: () => searchIDIPerson({ pid, refresh }),
    });
  };

  const handleEndatoEnrich = ({ pid, refresh = false }) => {
    const message = refresh
      ? "Are you sure you want to enrich this person?"
      : "Are you sure you want to enrich this person?";

    confirm({
      title: message,
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "info",
      cancelText: "No",
      okButtonProps: { disabled: loading, loading: loading },
      cancelButtonProps: { disabled: loading, loading: loading },
      onOk: () => endatoEnrichIDIPerson({ pid, refresh }),
    });
  };

  const handleSearchTwilioPhones = useCallback(
    async ({ refresh = false }) => {
      const phones = get(idiPerson, "data.phone", []);
      const endatoPhones = get(
        idiPerson,
        "last_endato_person.data.person.phones",
        []
      );
      const whitepagesPhones = get(
        idiPerson,
        "last_whitepages_person.phones",
        []
      );
      const phoneNumbers = [
        ...phones,
        ...endatoPhones,
        ...whitepagesPhones,
      ].map((p) => p.number);

      const url = refresh ? "/phones/bulk-validate" : "/phones";

      setLoading(true);
      apiBase
        .post(url, { phone_numbers: phoneNumbers, refresh })
        .then((res) => {
          const _twilioPhones = get(res, "data").map((phone) => {
            const responseBody = get(phone, "vendor_data.response_body");
            return {
              number: phone.raw,
              valid: get(responseBody, "valid"),
              caller_name: get(responseBody, "caller_name.caller_name"),
              caller_type: get(responseBody, "caller_name.caller_type"),
              line_type: get(responseBody, "line_type_intelligence.type"),
              carrier: get(responseBody, "line_type_intelligence.carrier_name"),
            };
          });

          if (_twilioPhones.length > 0) {
            setTwilioPhones(_twilioPhones);
          }
        })
        .catch((err) => {
          let msg = `${get(
            err,
            "response.status"
          )}: Failed to validate phone numbers.`;
          message.error(msg);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [idiPerson]
  );

  useEffect(() => {
    const phones = get(idiPerson, "data.phone", []);
    const endatoPhones = get(
      idiPerson,
      "last_endato_person.data.person.phones",
      []
    );
    const whitepagesPhones = get(
      idiPerson,
      "last_whitepages_person.phones",
      []
    );
    const phoneNumbers = [...phones, ...endatoPhones, ...whitepagesPhones].map(
      (p) => p.number
    );
    if (twilioPhones.length === 0 && phoneNumbers.length > 0 && isRecord) {
      handleSearchTwilioPhones({
        refresh: false,
      });
    }
  }, [handleSearchTwilioPhones, idiPerson, twilioPhones, isRecord]);

  const handleSearchUSPSAddresses = useCallback(
    async ({ refresh = false }) => {
      const addresses = processAddresses(
        get(idiPerson, "data.address", []),
        get(idiPerson, "data.property", [])
      )
        .filter((address) => address.mostRecentYear >= ADDRESS_RECENT_YEAR)
        .map((address) => ({
          id: get(address, "data"),
          street_address: get(address, "complete"),
          city: get(address, "city"),
          state: get(address, "state"),
          zip: get(address, "zip"),
        }));

      const url = refresh
        ? "/usps-searches/bulk-validate"
        : "/usps-addresses/match";

      setLoading(true);
      apiBase
        .post(url, { addresses })
        .then((res) => {
          const _uspsAddresses = get(res, "data").map((address) => {
            const input = get(address, "input");
            const output = get(address, "output");
            return {
              input,
              output,
            };
          });
          if (_uspsAddresses.length > 0) {
            setUSPSAddresses(_uspsAddresses);
          }
        })
        .catch((err) => {
          let msg = `${get(
            err,
            "response.status"
          )}: Failed to validate addresses.`;
          message.error(msg);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [idiPerson]
  );

  useEffect(() => {
    const addresses = get(idiPerson, "data.address", []);
    if (uspsAddresses.length === 0 && addresses.length > 0 && isRecord) {
      handleSearchUSPSAddresses({
        refresh: false,
      });
    }
  }, [handleSearchUSPSAddresses, idiPerson, uspsAddresses, isRecord]);

  const idiPersonData = get(idiPerson, "data", {});
  const endatoData = get(idiPerson, "last_endato_person.data.person", {});
  const whitepagesData = get(idiPerson, "last_whitepages_person", {});
  const idiSearch = get(idiPerson, "idi_search", {});

  const pid = get(idiPersonData, "pid");
  const isDead = get(idiPersonData, "isDead");
  const names = get(idiPersonData, "name", []);
  const dobs = get(idiPersonData, "dob", []);
  const _addresses = get(idiPersonData, "address", []);
  const properties = get(idiPersonData, "property", []);

  const _phones = get(idiPersonData, "phone", []);
  const endatoPhones = get(endatoData, "phones", []);
  const whitepagesPhones = get(whitepagesData, "phones", []);

  const relationships = get(idiPersonData, "relationship", []);
  // const ipAddresses = get(idiPersonData, "ip", []);

  const emails = get(idiPersonData, "email", []);
  const endatoEmails = get(endatoData, "emails", []);
  const whitepagesEmails = get(whitepagesData, "emails", []);

  const firstAddress = get(_addresses, "[0]", {});

  const addresses = processAddresses(_addresses, properties);
  const phones = processPhones(_phones);
  const spouses = [],
    parents = [],
    siblings = [],
    children = [],
    otherRelatives = [],
    inLaws = [],
    neighbors = [],
    pastNeighbors = [],
    coWorker = [],
    associates = [],
    other = [];
  relationships.forEach((rel) => {
    switch (rel.type) {
      case "R":
        switch (rel.subType) {
          case "M":
            spouses.push(rel);
            break;
          case "P":
            parents.push(rel);
            break;
          case "S":
            siblings.push(rel);
            break;
          case "C":
            children.push(rel);
            break;
          case "I":
            inLaws.push(rel);
            break;
          case "R":
            otherRelatives.push(rel);
            break;
          default:
            break;
        }
        break;
      case "A":
        switch (rel.subType) {
          case "N":
            neighbors.push(rel);
            break;
          case "O":
            pastNeighbors.push(rel);
            break;
          case "W":
            coWorker.push(rel);
            break;
          default:
            associates.push(rel);
            break;
        }
        break;
      default:
        other.push(rel);
        break;
    }
  });

  return (
    <div
      className={clsx({
        [styles.loading]: loading,
      })}
    >
      <div className={styles.main}>
        <div className={styles.header}>
          <div className={styles.personalInfo}>
            <div className={styles.name}>
              <b className={styles.nameMain}>
                {isDead && <div className={styles.deathIcon}>D</div>}
                {`${get(names, "[0].data")}`}
              </b>
              {names.slice(1).map((name, idx) => (
                <div className={styles.other} key={idx}>
                  {name.data}
                </div>
              ))}
            </div>
            <div className={styles.age}>
              <b>AGE:</b>
              <span className={styles.ageNumber}>{`${get(
                dobs,
                "[0].age",
                "(??)"
              )}`}</span>
              <Tooltip title={calculateBirthYear(get(dobs, "[0].age", "(??)"))}>
                <QuestionCircleOutlined />
              </Tooltip>
              {dobs.map((dob, idx) => (
                <div className={styles.other} key={idx}>
                  <span className={styles.ageNumber}>
                    {get(dob, "age", "(??)")}
                  </span>
                  <Tooltip title={calculateBirthYear(get(dob, "age"))}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.personalSummary}>
            <div>
              <b>Likely Current Address:</b>
              <span className={styles.link}>{firstAddress.data}</span> (
              {firstAddress.county}){" "}
              {get(firstAddress, "ownership") && (
                <Tooltip
                  title={
                    get(firstAddress, "ownership") === "C"
                      ? "Current owner"
                      : "Previous owner"
                  }
                >
                  <span className={styles.link}>
                    <HomeOutlined />
                  </span>
                </Tooltip>
              )}
            </div>
            <div className={styles.row}>
              <b>All data:</b>
              <RenderIndicator data={idiPersonData} title="All data" />
            </div>
            <div className={styles.row}>
              <b>Endato data:</b>
              <RenderIndicator data={endatoData} title="All data" />
            </div>
            <div className={styles.row}>
              <b>Whitepages data:</b>
              <RenderIndicator data={whitepagesData} title="All data" />
            </div>
            <div>
              <b>Bankruptcies:</b>
              <RenderIndicator
                data={get(idiPersonData, "bankruptcy", [])}
                title="Bankruptcies"
              />
            </div>
            <div>
              <b>Liens:</b>
              <RenderIndicator
                data={get(idiPersonData, "lien", [])}
                title="Liens"
              />
            </div>
            <div>
              <b>Judgments:</b>
              <RenderIndicator
                data={get(idiPersonData, "judgment", [])}
                title="Judgments"
              />
            </div>
            <div>
              <b>Foreclosures:</b>
              <RenderIndicator
                data={get(idiPersonData, "foreclosure", [])}
                title="Foreclosures"
              />
            </div>
            <div>
              <b>Possible Criminal/Infractions:</b>
              <RenderIndicator
                data={get(idiPersonData, "criminal", [])}
                title="Possible Criminal/Infractions"
              />
            </div>
            <div>
              <b>Business Affiliations:</b>
              <RenderIndicator
                data={get(idiPersonData, "business", [])}
                title="Business Affiliations"
              />
            </div>
            <div>
              <b>Professional Licenses:</b>
              <RenderIndicator
                data={get(idiPersonData, "professional", [])}
                title="Professional Licenses"
              />
            </div>
          </div>
          <div className={styles.taborData}>
            <div className={styles.row}>
              <b>PID:</b>
              <span>
                <Link to={`/idi-persons/${pid}`}>{pid}</Link>
              </span>
            </div>
            {isRecord && (
              <>
                <div className={styles.row}>
                  <b>Id:</b>
                  <span>{get(idiPerson, "id")}</span>
                </div>
                <div className={styles.row}>
                  <b>Search ID:</b>
                  {get(idiPerson, "idi_search_id") ? (
                    <span>
                      <Link
                        to={`/idi-searches/${get(idiPerson, "idi_search_id")}`}
                      >
                        {get(idiPerson, "idi_search_id")}
                      </Link>
                    </span>
                  ) : (
                    <span>-</span>
                  )}
                </div>
                <div className={styles.row}>
                  <b>Search results:</b>
                  {get(idiSearch, "total_results") ? (
                    <span>
                      {get(idiSearch, "total_results") > 1 && (
                        <WarningOutlined
                          style={{ color: "orange", marginLeft: "5px" }}
                        />
                      )}
                      {get(idiSearch, "total_results")}
                    </span>
                  ) : (
                    <span>-</span>
                  )}
                </div>
                <div className={styles.row}>
                  <b>Created at:</b>
                  <span>
                    {formatDate(
                      get(idiPerson, "created_at"),
                      "YYYY-MM-DD, h:mm:ss A"
                    )}
                  </span>
                </div>
                <div className={styles.row}>
                  <b>Updated at:</b>
                  <span>
                    {formatDate(
                      get(idiPerson, "updated_at"),
                      "YYYY-MM-DD, h:mm:ss A"
                    )}
                  </span>
                </div>
                <div className={styles.row}>
                  <b>Refresh:</b>
                  <span
                    className={styles.linkReal}
                    onClick={() => handleSearch({ pid, refresh: true })}
                  >
                    <RedoOutlined />
                  </span>
                </div>
                <div className={styles.row}>
                  <b>Whitepages Person Id:</b>
                  <span>
                    {get(idiPerson, "last_whitepages_person_id") || "-"}
                  </span>
                </div>
                <div className={styles.row}>
                  <b>Endato Person Id:</b>
                  <span>{get(idiPerson, "last_endato_person_id") || "-"}</span>
                </div>
                <div className={styles.row}>
                  <b>Endato Enrich:</b>
                  <span
                    className={styles.linkReal}
                    onClick={() => handleEndatoEnrich({ pid, refresh: true })}
                  >
                    <PlusCircleOutlined />
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.contactInfo}>
            <div className={styles.addresses}>
              <b>Address (County/Parish/Borough) History:</b>
              {isRecord && (
                <span
                  className={styles.lookupNames}
                  onClick={() => handleSearchUSPSAddresses({ refresh: true })}
                >
                  <UsergroupAddOutlined />
                </span>
              )}
              {addresses.map((address, idx) => {
                const uspsAddress = get(
                  uspsAddresses.find(
                    (a) => get(address, "data") === get(a, "input.id")
                  ),
                  "output",
                  null
                );

                return (
                  <div className={styles.address} key={idx}>
                    <div>
                      <span className={styles.link}>{address.data}</span> (
                      {address.county}){" "}
                    </div>
                    <div>
                      <span style={{ marginRight: "5px" }}>
                        {get(address, "property") && (
                          <span className={styles.marginRightSmall}>
                            <span className={styles.marginRightSmall}>
                              <RenderIndicator
                                data={get(address, "property")}
                                title="Property"
                              />
                            </span>
                            {`$${formatNumber(
                              get(
                                address,
                                "property.assessment.marketValue",
                                0
                              ),
                              "0,0"
                            )}
                       (${get(
                         address,
                         "property.assessment.assessorYear"
                       )}) (${get(address, "ownership")})`}
                          </span>
                        )}
                        {get(address, "ownership") && (
                          <Tooltip
                            title={
                              get(address, "ownership") === "C"
                                ? "Current owner"
                                : "Previous owner"
                            }
                          >
                            <span className={styles.link}>
                              <HomeOutlined />
                            </span>
                          </Tooltip>
                        )}
                      </span>
                      <span
                        className={clsx(
                          styles.addressValidation,
                          get(uspsAddress, "is_valid") && styles.good
                        )}
                      >
                        {isEmpty(uspsAddress) ? (
                          ""
                        ) : (
                          <Tooltip
                            overlayStyle={{ width: "600px" }}
                            overlayInnerStyle={{ width: "600px" }}
                            title={
                              <pre>{JSON.stringify(uspsAddress, null, 2)}</pre>
                            }
                          >
                            {get(uspsAddress, "is_valid") ? (
                              <CheckCircleTwoTone twoToneColor="#52c41a" />
                            ) : (
                              <WarningTwoTone twoToneColor="#faad14" />
                            )}
                          </Tooltip>
                        )}
                      </span>
                      <span
                        className={clsx(
                          styles.dateRange,
                          address.mostRecentYear < ADDRESS_RECENT_YEAR &&
                            styles.bad,
                          address.mostRecentYear >= ADDRESS_RECENT_YEAR &&
                            styles.good
                        )}
                      >
                        {address.dateRange === "0000-0000"
                          ? "-"
                          : address.dateRange}
                      </span>
                      <span
                        className={clsx(
                          styles.monthsDifference,
                          address.monthsDifference < 12 && styles.warning,
                          address.monthsDifference >= 12 && styles.good
                        )}
                      >
                        {address.monthsDifference === "0000-0000"
                          ? "-"
                          : `(${address.monthsDifference})`}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={styles.outreachInfo}>
              <div className={styles.phones}>
                <div>
                  <b>
                    Phone numbers (Last Seen):{" "}
                    <Tooltip title="Phones numbers are sorted using a proprietary confidence algorithm that considers source, frequency, and last seen date.">
                      <QuestionCircleOutlined />
                    </Tooltip>
                    {isRecord && (
                      <span
                        className={styles.lookupNames}
                        onClick={() =>
                          handleSearchTwilioPhones({ refresh: true })
                        }
                      >
                        <UsergroupAddOutlined />
                      </span>
                    )}
                  </b>
                  {phones.map((phone, idx) => {
                    const twilioPhone = twilioPhones.find(
                      (p) =>
                        p.number.replace(/\D/g, "") ===
                        phone.number.replace(/\D/g, "")
                    );

                    return (
                      <div className={styles.phone} key={idx}>
                        <div>
                          {phone.type === "Mobile" ? "C: " : "R: "}
                          <span className={styles.link}>{phone.number}</span>
                          {get(twilioPhone, "valid") !== undefined && (
                            <span style={{ margin: "0px 10px" }}>
                              <span className={`${styles.twilioName}`}>
                                {get(twilioPhone, "caller_name") || "-"}
                              </span>
                            </span>
                          )}
                        </div>
                        <div style={{ display: "flex" }}>
                          {get(twilioPhone, "valid") !== undefined && (
                            <>
                              <span className={styles.twilioExtras}>
                                {/* <span style={{ marginRight: "5px" }}>
                                {get(twilioPhone, "carrier")}
                              </span> */}
                                <span style={{ marginRight: "5px" }}>
                                  {get(twilioPhone, "caller_type")}
                                </span>
                                <span style={{ marginRight: "5px" }}>
                                  {get(twilioPhone, "line_type")}
                                </span>
                                <span>
                                  {get(twilioPhone, "valid") ? (
                                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                                  ) : (
                                    <WarningTwoTone twoToneColor="#faad14" />
                                  )}
                                </span>
                              </span>
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginRight: "10px",
                                  marginLeft: "5px",
                                }}
                              >
                                <Tooltip
                                  overlayStyle={{ width: "400px" }}
                                  overlayInnerStyle={{ width: "400px" }}
                                  title={
                                    <pre>
                                      {JSON.stringify(twilioPhone, null, 2)}
                                    </pre>
                                  }
                                >
                                  <TwilioIcon width="13" height="13" />
                                </Tooltip>
                              </span>
                            </>
                          )}
                          <span
                            className={clsx(
                              get(phone, "meta.score") < 50 && styles.bad,
                              get(phone, "meta.score") < 75 &&
                                get(phone, "meta.score") >= 50 &&
                                styles.warning,
                              get(phone, "meta.score") >= 75 && styles.good
                            )}
                          >
                            {get(phone, "meta.score") !== undefined
                              ? `(${get(phone, "meta.score")}) `
                              : " "}
                          </span>
                          <span
                            className={clsx(
                              styles.dateRange,
                              phone.mostRecentYear < PHONE_RECENT_YEAR &&
                                styles.bad,
                              phone.mostRecentYear >= PHONE_RECENT_YEAR &&
                                styles.good
                            )}
                          >
                            {get(phone, "meta.lastSeen") === 0
                              ? ""
                              : _formatDate(
                                  String(get(phone, "meta.lastSeen"))
                                )}
                          </span>
                          {get(phone, "providerName") && (
                            <Tooltip title={get(phone, "providerName")}>
                              <MobileOutlined />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
                {isRecord && get(idiPerson, "last_endato_person_id") && (
                  <div style={{ marginTop: "10px" }}>
                    <b>Endato phone numbers (Last Seen): </b>
                    {endatoPhones.map((phone, idx) => {
                      const twilioPhone = twilioPhones.find(
                        (p) =>
                          p.number.replace(/\D/g, "") ===
                          phone.number.replace(/\D/g, "")
                      );

                      return (
                        <div className={styles.phone} key={idx}>
                          <div>
                            {phone.type === "mobile" ? "C: " : "R: "}
                            <span className={styles.link}>{phone.number}</span>
                            {get(twilioPhone, "valid") !== undefined && (
                              <span style={{ margin: "0px 10px" }}>
                                <span className={`${styles.twilioName}`}>
                                  {get(twilioPhone, "caller_name") || "-"}
                                </span>
                              </span>
                            )}
                          </div>
                          <div style={{ display: "flex" }}>
                            {get(twilioPhone, "valid") !== undefined && (
                              <>
                                <span className={styles.twilioExtras}>
                                  {/* <span style={{ marginRight: "5px" }}>
                                {get(twilioPhone, "carrier")}
                              </span> */}
                                  <span style={{ marginRight: "5px" }}>
                                    {get(twilioPhone, "caller_type")}
                                  </span>
                                  <span style={{ marginRight: "5px" }}>
                                    {get(twilioPhone, "line_type")}
                                  </span>
                                  <span>
                                    {get(twilioPhone, "valid") ? (
                                      <CheckCircleTwoTone twoToneColor="#52c41a" />
                                    ) : (
                                      <WarningTwoTone twoToneColor="#faad14" />
                                    )}
                                  </span>
                                </span>
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginRight: "10px",
                                    marginLeft: "5px",
                                  }}
                                >
                                  <Tooltip
                                    title={
                                      <pre>
                                        {JSON.stringify(twilioPhone, null, 2)}
                                      </pre>
                                    }
                                  >
                                    <TwilioIcon width="13" height="13" />
                                  </Tooltip>
                                </span>
                              </>
                            )}
                            {get(phone, "lastReportedDate") === 0
                              ? ""
                              : String(get(phone, "lastReportedDate"))}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {isRecord && get(idiPerson, "last_whitepages_person_id") && (
                  <div style={{ marginTop: "10px" }}>
                    <b>Whitepages phone numbers: </b>
                    {whitepagesPhones.map((phone, idx) => {
                      const twilioPhone = twilioPhones.find(
                        (p) =>
                          p.number.replace(/\D/g, "") ===
                          phone.number.replace(/\D/g, "")
                      );

                      return (
                        <div className={styles.phone} key={idx}>
                          <div>
                            {phone.type === "mobile" ? "C: " : "R: "}
                            <span className={styles.link}>{phone.number}</span>
                            {get(twilioPhone, "valid") !== undefined && (
                              <span style={{ margin: "0px 10px" }}>
                                <span className={`${styles.twilioName}`}>
                                  {get(twilioPhone, "caller_name") || "-"}
                                </span>
                              </span>
                            )}
                          </div>
                          <div style={{ display: "flex" }}>
                            {get(twilioPhone, "valid") !== undefined && (
                              <>
                                <span className={styles.twilioExtras}>
                                  {/* <span style={{ marginRight: "5px" }}>
                                {get(twilioPhone, "carrier")}
                              </span> */}
                                  <span style={{ marginRight: "5px" }}>
                                    {get(twilioPhone, "caller_type")}
                                  </span>
                                  <span style={{ marginRight: "5px" }}>
                                    {get(twilioPhone, "line_type")}
                                  </span>
                                  <span>
                                    {get(twilioPhone, "valid") ? (
                                      <CheckCircleTwoTone twoToneColor="#52c41a" />
                                    ) : (
                                      <WarningTwoTone twoToneColor="#faad14" />
                                    )}
                                  </span>
                                </span>
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginRight: "10px",
                                    marginLeft: "5px",
                                  }}
                                >
                                  <Tooltip
                                    title={
                                      <pre>
                                        {JSON.stringify(twilioPhone, null, 2)}
                                      </pre>
                                    }
                                  >
                                    <TwilioIcon width="13" height="13" />
                                  </Tooltip>
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              <div className={styles.emails}>
                <div>
                  <b>Emails: (Last Seen):</b>
                  {emails.map((email, idx) => (
                    <div className={styles.email} key={idx}>
                      <div>
                        <span className={styles.link}>{email.data}</span>
                        <span>
                          {get(email, "meta.lastSeen") === 0
                            ? ""
                            : `(${_formatDate(
                                String(get(email, "meta.lastSeen"))
                              )})`}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
                {isRecord && get(idiPerson, "last_endato_person_id") && (
                  <div style={{ marginTop: "10px" }}>
                    <b>Endato emails:</b>
                    {endatoEmails.map((email, idx) => (
                      <div className={styles.email} key={idx}>
                        <div>
                          <span className={styles.link}>
                            {String(email.email).toUpperCase()}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {isRecord && get(idiPerson, "last_whitepages_person_id") && (
                  <div style={{ marginTop: "10px" }}>
                    <b>Whitepages emails:</b>
                    {whitepagesEmails.map((email, idx) => (
                      <div className={styles.email} key={idx}>
                        <div>
                          <span className={styles.link}>
                            {String(email.email).toUpperCase()}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {/* <div className={styles.ipAddresses}>
            <b>
              Possible IP Addresses:{" "}
              <Tooltip title="IP Addresses are sorted using a proprietary confidence algorithm that considers source, frequency, and last seen date.">
                <QuestionCircleOutlined />
              </Tooltip>
            </b>
            {ipAddresses.map((ipAddress, idx) => (
              <div className={styles.ipAddress} key={idx}>
                <div>
                  <span className={styles.link}>{ipAddress.data}</span>
                  <span>
                    {get(ipAddress, "meta.lastSeen") === 0
                      ? ""
                      : `(${_formatDate(
                          String(get(ipAddress, "meta.lastSeen"))
                        )})`}
                  </span>
                </div>
              </div>
            ))}
          </div> */}
            </div>
          </div>
          <div className={styles.relations}>
            <b className={styles.sectionHeader}>
              Likely relatives and associates:
            </b>
            <div className={styles.relationTypes}>
              <RelationType
                title="Spouse"
                relations={spouses}
                handleSearch={handleSearch}
              />
              <RelationType
                title="Parents"
                relations={parents}
                handleSearch={handleSearch}
              />
              <RelationType
                title="Children"
                relations={children}
                handleSearch={handleSearch}
              />
              <RelationType
                title="Siblings"
                relations={siblings}
                handleSearch={handleSearch}
              />
              <RelationType
                title="In-Laws"
                relations={inLaws}
                handleSearch={handleSearch}
              />
              <RelationType
                title="Other relative"
                relations={otherRelatives}
                handleSearch={handleSearch}
              />
              <RelationType
                title="Neighbor"
                relations={neighbors}
                defaultCollapsed={true}
                handleSearch={handleSearch}
              />
              <RelationType
                title="Past neighbors"
                relations={pastNeighbors}
                defaultCollapsed={true}
                handleSearch={handleSearch}
              />
              <RelationType
                title="Co-Worker"
                relations={coWorker}
                defaultCollapsed={true}
                handleSearch={handleSearch}
              />
              <RelationType
                title="Unclassified associates"
                relations={associates}
                defaultCollapsed={true}
                handleSearch={handleSearch}
              />
              <RelationType
                title="Other"
                relations={other}
                defaultCollapsed={true}
                handleSearch={handleSearch}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withParams(IDIPersonCard);
