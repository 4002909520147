import React, { useState } from "react";
import { columnTypes } from "../../utils/search";
import BaseSearch from "../search/BaseSearch";
import Took from "../search/Took";
import TotalRecords from "../search/TotalRecords";
// import { get } from "lodash";

import ExportForm from "../forms/ExportForm";
import ChangeColumnsForm from "../forms/ChangeColumnsForm";
import OwnerNameAddressSimilarSplink from "./OwnerNameAddressSimilarSplink";
import FilterTags from "../search/FilterTags";

import { DownloadOutlined, TableOutlined } from "@ant-design/icons";

import { Row, Col, Table, Select, Button, Tooltip, Pagination } from "antd";

const baseColumnState = [
  {
    title: "Id",
    dataIndex: "id",
    type: columnTypes.STRING,
    className: `App__w12`,
  },
  {
    title: "Owner Id",
    dataIndex: "owner_id",
    type: columnTypes.LINK,
    baseUrl: "/owners",
  },
  {
    title: "Geo Id",
    dataIndex: "geocoded_address_id",
    type: columnTypes.STRING,
    className: `App__w12`,
  },
  {
    title: "Cluster Id",
    dataIndex: "cluster_id",
    type: columnTypes.STRING,
    className: `App__w12`,
  },
  {
    title: "Source",
    dataIndex: "source",
    type: columnTypes.STRING,
  },
  {
    title: "Name",
    dataIndex: "name",
    type: columnTypes.STRING,
  },
  {
    title: "Name ICO",
    dataIndex: "name_ico",
    type: columnTypes.STRING,
    visible: false,
  },
  {
    title: "Name formatted",
    dataIndex: "name_formatted",
    type: columnTypes.STRING,
  },
  {
    title: "First name",
    dataIndex: "first_name",
    type: columnTypes.STRING,
  },
  {
    title: "Middle name",
    dataIndex: "middle_name",
    type: columnTypes.STRING,
  },
  {
    title: "Last name",
    dataIndex: "last_name",
    type: columnTypes.STRING,
  },
  {
    title: "Street",
    dataIndex: "street",
    type: columnTypes.STRING,
  },
  {
    title: "Street formatted",
    dataIndex: "street_formatted",
    type: columnTypes.STRING,
  },
  {
    title: "City",
    dataIndex: "city",
    type: columnTypes.STRING,
  },
  {
    title: "State",
    dataIndex: "state",
    type: columnTypes.STRING,
  },
  {
    title: "Zip code",
    dataIndex: "zip_code",
    type: columnTypes.STRING,
  },
  {
    title: "Longitude",
    dataIndex: ["geocoded_address", "longitude"],
    type: columnTypes.INTEGER,
    format: (value) => (value ? Number(value).toFixed(6) : value),
  },
  {
    title: "Latitude",
    dataIndex: ["geocoded_address", "latitude"],
    type: columnTypes.INTEGER,
    format: (value) => (value ? Number(value).toFixed(6) : value),
  },
  {
    title: "Unique ID",
    dataIndex: "unique_id",
    type: columnTypes.STRING,
    visible: false,
  },
];

const OwnerNameAddresses = (props) => {
  const {
    isLoadingRecords,
    isLoadingCount,
    records,
    columnState,
    limit,
    page,
    totalRecords,
    tookRecords,
    tookCount,
    searchFields,
    isExportModalVisible,
    isChangeColumnsVisible,
    isLoadingExport,
    handleSearch,
    handleRecordsChange,
    handlePaginationChange,
    handleFilterClose,
    handleTableChange,
    handleSubmitExport,
    showExportModal,
    handleCancelExport,
    handleSubmitColumnChange,
    showColumnChangeModal,
    handleCancelColumnsChange,
    getColumnProps,
    exportFormRef,
    changeColumnFormRef,
  } = props;

  const [ownerNameAddress, setOwnerNameAddress] = useState(null);
  const [similarModalOpen, setSimilarModalOpen] = useState(false);

  let columns = columnState
    .map((column, idx) => ({
      key: idx,
      ...column,
      ...getColumnProps(column),
    }))
    .filter((col) => col.visible === true);

  columns.push({
    title: "",
    dataIndex: "actions",
    visible: true,
    editable: false,
    children: [
      {
        title: "Actions",
        dataIndex: "id",
        key: "actions",
        render: (_, record) => {
          return (
            <Button
              size="small"
              onClick={() => {
                setOwnerNameAddress(record);
                setSimilarModalOpen(true);
              }}
            >
              Similar
            </Button>
          );
        },
      },
    ],
  });

  return (
    <div>
      <Row className="App__rowContent">
        <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16}>
          <Button className="App__mr-5" onClick={() => handleSearch()}>
            Search
          </Button>
          <FilterTags
            searchFields={searchFields}
            columnState={columnState}
            handleFilterClose={handleFilterClose}
          />
        </Col>
        <Col
          span={8}
          xs={24}
          sm={24}
          md={8}
          lg={8}
          xl={8}
          className="App__rightActions"
        >
          <Tooltip placement="bottomRight" title={"Export search results"}>
            <Button className="App__iconButton" onClick={showExportModal}>
              <DownloadOutlined />
            </Button>
          </Tooltip>
          <ExportForm
            wrappedComponentRef={exportFormRef}
            visible={isExportModalVisible}
            isLoading={isLoadingExport}
            onCancel={handleCancelExport}
            onCreate={handleSubmitExport}
            totalRecords={totalRecords}
          />
          <Tooltip placement="bottomRight" title={"Change visible columns"}>
            <Button onClick={showColumnChangeModal} className="App__iconButton">
              <TableOutlined />
            </Button>
          </Tooltip>
          <ChangeColumnsForm
            wrappedComponentRef={changeColumnFormRef}
            visible={isChangeColumnsVisible}
            isLoading={isLoadingRecords}
            onCancel={handleCancelColumnsChange}
            onOk={handleSubmitColumnChange}
            columnState={columnState}
          />
          <Select
            value={limit}
            onChange={handleRecordsChange}
            className={"App__mr-5"}
          >
            <Select.Option value={25}>25</Select.Option>
            <Select.Option value={50}>50</Select.Option>
            <Select.Option value={100}>100</Select.Option>
            <Select.Option value={1000}>1000</Select.Option>
          </Select>
          <TotalRecords
            title={"Owner Name Addresses"}
            isLoadingCount={isLoadingCount}
            totalRecords={totalRecords}
          />
        </Col>
      </Row>
      <Row className="App__rowContent">
        <Col span={24}>
          <OwnerNameAddressSimilarSplink
            open={similarModalOpen}
            ownerNameAddressId={ownerNameAddress?.id}
            onCancel={() => setSimilarModalOpen(false)}
          />
          <Table
            columns={columns}
            dataSource={records}
            onChange={handleTableChange}
            pagination={false}
            showSorterTooltip={false}
            className="App__smallTables"
            loading={isLoadingRecords}
            scroll={{ x: "100%" }}
            size="small"
            bordered
          />
          <div className="App__searchFooterContainer">
            <Took
              isLoadingRecords={isLoadingRecords}
              isLoadingCount={isLoadingCount}
              tookRecords={tookRecords}
              tookCount={tookCount}
            />
            <Pagination
              current={page}
              pageSize={limit}
              total={totalRecords}
              onChange={handlePaginationChange}
              className="App__pagination"
              showSizeChanger={false}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

const OwnerNameAddressesWithSearch = (props) => (
  <BaseSearch
    baseColumnState={baseColumnState}
    localSortColumns={[]}
    searchUri="owner-name-addresses"
  >
    <OwnerNameAddresses {...props} />
  </BaseSearch>
);

export default OwnerNameAddressesWithSearch;
