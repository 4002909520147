import React, { Component } from "react";

import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";

import { Modal, InputNumber } from "antd";

const ExportForm = Form.create({ name: "export_form" })(
  // eslint-disable-next-line
  class extends Component {
    render() {
      const { visible, isLoading, onCancel, onCreate, form, totalRecords } =
        this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal
          open={visible}
          title="Export Units"
          okText="Export"
          cancelText="Cancel"
          onCancel={onCancel}
          onOk={onCreate}
          confirmLoading={isLoading}
          maskClosable={false}
        >
          <Form layout="vertical">
            <Form.Item label="Number of Records">
              {getFieldDecorator("number_of_records", {
                rules: [
                  {
                    required: true,
                    min: 0,
                    max: 10000,
                    type: "integer",
                    message:
                      "The number of records has to be positive and less and 10,000.",
                  },
                ],
                initialValue: totalRecords > 1000 ? 1000 : totalRecords,
              })(
                <InputNumber
                  className="App__fullWidth"
                  onKeyDown={(e) => e.keyCode === 13 && onCreate()}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                />
              )}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

export default ExportForm;
