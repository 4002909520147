import React, { useState, useEffect } from "react";
import { debounce } from "lodash";
import styles from "./ZoomPosition.module.scss";

const ZoomPosition = ({ mapRef, initialCenter, initialZoom }) => {
  const [center, setCenter] = useState(initialCenter);
  const [zoom, setZoom] = useState(initialZoom);

  useEffect(() => {
    if (!mapRef.current) return;
    // console.log("ZOOM");

    const map = mapRef.current;

    const debouncedMoveHandler = debounce(() => {
      const mapCenter = map.getCenter();
      const mapZoom = map.getZoom();
      setCenter([mapCenter.lng, mapCenter.lat]);
      setZoom(mapZoom);
    }, 100);

    map.on("move", debouncedMoveHandler);

    return () => {
      map.off("move", debouncedMoveHandler);
      debouncedMoveHandler.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef]);

  return (
    <div className={styles.latLngZoom}>
      Lng: {center[0].toFixed(4)} | Lat: {center[1].toFixed(4)} | Z:{" "}
      {zoom.toFixed(2)}
    </div>
  );
};

export default ZoomPosition;
