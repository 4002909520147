import React, { Component } from "react";
import { get, isEmpty } from "lodash";
import { apiBase } from "../utils/apiBase";
import { formatNumber } from "../utils/numbers";
import { columnTypes } from "../utils/search";
import { leadViews } from "../common/views";
import { withParams } from "../utils/withParams";
import { parse, stringify } from "query-string";

import { NTFIcon } from "./common/LabelIcon";
import PipedrivePersonLabels from "./common/PipedrivePersonLabels";
import BaseSearch from "./search/BaseSearch";
import { arrayContainsOperators } from "./search/constants";
import FilterTags from "./search/FilterTags";
import Took from "./search/Took";
import TotalRecords from "./search/TotalRecords";

import ExportForm from "./forms/ExportForm";
import ChangeColumnsForm from "./forms/ChangeColumnsForm";
import BulkEditForm from "./forms/BulkEditForm";
import LeadsEditForm from "./forms/LeadsEditForm";
import CreateMailExport from "./forms/CreateMailExport";
import CreateIDIBulkSearch from "./forms/CreateIDIBulkSearch";
import CreateUSPSBulkSearch from "./forms/CreateUSPSBulkSearch";

import { DEFAULT_PAGE_SIZE } from "../common/navs";

import { ReactComponent as IDILogo } from "../assets/icons/idi.svg";
import { ReactComponent as USPSLogo } from "../assets/icons/usps.svg";

import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  TableOutlined,
  MailOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";

import {
  Row,
  Col,
  Table,
  Select,
  Button,
  Modal,
  Tooltip,
  Pagination,
  message,
} from "antd";

import styles from "./Leads.module.scss";

const { confirm } = Modal;

const taxRollColumnState = [
  {
    dataIndex: "id",
    title: "Id",
    editable: false,
    type: columnTypes.LINK,
    baseUrl: "/leads",
  },
  {
    dataIndex: "owner_id",
    title: "Owner",
    editable: false,
    type: columnTypes.LINK,
    baseUrl: "/owners",
  },
  {
    dataIndex: "unit_id",
    title: "Unit",
    editable: false,
    type: columnTypes.LINK,
    baseUrl: "/units",
  },
  {
    title: "Pipedrive label",
    dataIndex: ["owner", "pipedrive_person_label_ids"],
    className: `App__w12`,
    exportable: false,
    type: columnTypes.SELECT_MULTI,
    globalStateKey: "appConfig.data.pipedrive_person_labels",
    globalStateOptions: (data) =>
      data.map((d) => ({ value: String(d.id), label: d.label })),
    operatorOptions: arrayContainsOperators,
    format: ({ record }) => {
      return (
        <PipedrivePersonLabels
          pipedrivePersons={get(record, "pipedrive_persons", [])}
        />
      );
    },
  },
  {
    dataIndex: ["owner", "last_idi_person_id"],
    title: "IDI person",
    editable: false,
    type: columnTypes.LINK,
    baseUrl: "/idi-persons",
    sortable: true,
  },
  {
    dataIndex: ["owner", "last_usps_address_id"],
    title: "USPS address",
    editable: false,
    type: columnTypes.LINK,
    baseUrl: "/usps-addresses",
    sortable: true,
  },
  {
    dataIndex: ["owner", "name_formatted"],
    title: "Owner name formatted",
    type: columnTypes.STRING,
    first: true,
  },
  {
    dataIndex: ["owner", "first_name"],
    title: "Owner first name",
    type: columnTypes.STRING,
  },
  {
    dataIndex: ["owner", "last_name"],
    title: "Owner last name",
    type: columnTypes.STRING,
  },
  {
    dataIndex: ["owner", "name"],
    title: "Owner name",
    type: columnTypes.STRING,
  },
  {
    dataIndex: ["owner", "name_ico"],
    title: "Owner ICO",
    type: columnTypes.STRING,
  },
  {
    title: "Owner address",
    dataIndex: ["owner", "address"],
    type: columnTypes.STRING,
  },
  {
    title: "Owner address formatted",
    dataIndex: ["owner", "address_formatted"],
    type: columnTypes.STRING,
  },
  {
    title: "Owner city",
    dataIndex: ["owner", "city"],
    type: columnTypes.STRING,
  },
  {
    title: "Owner State",
    dataIndex: ["owner", "state"],
    type: columnTypes.STRING,
  },
  {
    title: "Owner zip",
    dataIndex: ["owner", "zip_code"],
    type: columnTypes.STRING,
  },
  {
    title: "Unit name",
    dataIndex: ["unit", "name_formatted"],
    type: columnTypes.STRING,
  },
  {
    title: "Unit county",
    dataIndex: ["unit", "county"],
    type: columnTypes.STRING,
  },
  {
    title: "Operator",
    dataIndex: ["unit", "operator"],
    type: columnTypes.STRING,
  },
  {
    title: "NTF",
    dataIndex: ["owner", "ntf"],
    type: columnTypes.SELECT,
    options: [
      { value: "false", label: "False" },
      { value: "true", label: "True" },
    ],
    className: `App__w6`,
    selectClassName: `App__w6`,
    format: ({ value }) => <NTFIcon value={value} />,
  },
  {
    title: "Interest type",
    dataIndex: "interest_type",
    type: columnTypes.STRING,
    visible: false,
  },
  {
    title: "DOI",
    dataIndex: "decimal_interest",
    type: columnTypes.INTEGER,
    format: (value) => formatNumber(value, "0.000000"),
    visible: false,
  },
  {
    title: "NRA",
    dataIndex: "nra",
    type: columnTypes.INTEGER,
    format: (value) => formatNumber(value, "0.00"),
  },
  {
    title: "Offer amount",
    dataIndex: "offer_amount",
    type: columnTypes.INTEGER,
    format: (value) => `$${formatNumber(value, "0,0.00")}`,
    className: `App__w10`,
  },
  {
    title: "Unit count",
    dataIndex: ["owner", "unit_count"],
    type: columnTypes.INTEGER,
    visible: false,
  },
  {
    title: "Address unit count",
    dataIndex: ["owner", "address_unit_count"],
    type: columnTypes.INTEGER,
    visible: false,
  },
  {
    title: "Lead status",
    dataIndex: "status",
    type: columnTypes.STRING,
    className: `App__w10`,
  },
  {
    title: "Lead owner",
    dataIndex: ["user", "alias"],
    type: columnTypes.STRING,
    visible: false,
  },
  {
    title: "Tax year",
    dataIndex: "tax_year",
    type: columnTypes.INTEGER,
    visible: false,
  },
  {
    title: "Created at",
    dataIndex: "created_at",
    type: columnTypes.DATE_TIME,
    visible: false,
  },
];

const genericsColumnState = [
  {
    dataIndex: "id",
    title: "Lead",
    editable: false,
    type: columnTypes.LINK,
    baseUrl: "/leads",
  },
  {
    dataIndex: "owner_id",
    title: "Owner",
    editable: false,
    type: columnTypes.LINK,
    baseUrl: "/owners",
  },
  {
    dataIndex: "generic_file_record_id",
    title: "Generic",
    editable: false,
    type: columnTypes.LINK,
    baseUrl: "/generic-file-records",
  },
  {
    title: "Pipedrive label",
    dataIndex: ["owner", "pipedrive_person_label_ids"],
    className: `App__w12`,
    exportable: false,
    type: columnTypes.SELECT_MULTI,
    globalStateKey: "appConfig.data.pipedrive_person_labels",
    globalStateOptions: (data) =>
      data.map((d) => ({ value: String(d.id), label: d.label })),
    operatorOptions: arrayContainsOperators,
    format: ({ record }) => {
      return (
        <PipedrivePersonLabels
          pipedrivePersons={get(record, "pipedrive_persons", [])}
        />
      );
    },
  },
  {
    dataIndex: ["owner", "last_idi_person_id"],
    title: "IDI person",
    editable: false,
    type: columnTypes.LINK,
    baseUrl: "/idi-persons",
    sortable: true,
  },
  {
    dataIndex: ["owner", "last_usps_address_id"],
    title: "USPS address",
    editable: false,
    type: columnTypes.LINK,
    baseUrl: "/usps-addresses",
    sortable: true,
  },
  {
    dataIndex: ["owner", "name_formatted"],
    title: "Owner name formatted",
    type: columnTypes.STRING,
    first: true,
  },
  {
    dataIndex: ["owner", "first_name"],
    title: "Owner first name",
    type: columnTypes.STRING,
  },
  {
    dataIndex: ["owner", "last_name"],
    title: "Owner last name",
    type: columnTypes.STRING,
  },
  {
    dataIndex: ["owner", "name"],
    title: "Owner name",
    type: columnTypes.STRING,
  },
  {
    dataIndex: ["owner", "name_ico"],
    title: "Owner ICO",
    type: columnTypes.STRING,
    visible: false,
  },
  {
    title: "Owner address",
    dataIndex: ["owner", "address"],
    type: columnTypes.STRING,
    visible: false,
  },
  {
    title: "Owner address formatted",
    dataIndex: ["owner", "address_formatted"],
    type: columnTypes.STRING,
  },
  {
    title: "Owner City",
    dataIndex: ["owner", "city"],
    type: columnTypes.STRING,
  },
  {
    title: "Owner state",
    dataIndex: ["owner", "state"],
    type: columnTypes.STRING,
    className: `App__w8`,
  },
  {
    title: "Owner zip",
    dataIndex: ["owner", "zip_code"],
    type: columnTypes.STRING,
    className: `App__w8`,
  },
  {
    title: "Lessee",
    dataIndex: ["generic_file_record", "data", "lessee"],
    type: columnTypes.STRING,
  },
  {
    title: "Unit county",
    dataIndex: ["generic_file_record", "data", "unit_county"],
    type: columnTypes.STRING,
  },
  {
    title: "Book",
    dataIndex: ["generic_file_record", "data", "book"],
    type: columnTypes.STRING,
    visible: false,
  },
  {
    title: "Page",
    dataIndex: ["generic_file_record", "data", "page"],
    type: columnTypes.STRING,
    visible: false,
  },
  {
    title: "File date",
    dataIndex: ["generic_file_record", "data", "file_date"],
    type: columnTypes.STRING,
    visible: false,
  },
  {
    title: "Lease type",
    dataIndex: ["generic_file_record", "data", "lease_type"],
    type: columnTypes.STRING,
    visible: false,
  },
  {
    title: "Section",
    dataIndex: ["generic_file_record", "data", "section"],
    type: columnTypes.STRING,
    className: `App__w8`,
  },
  {
    title: "Township",
    dataIndex: ["generic_file_record", "data", "township"],
    type: columnTypes.STRING,
  },
  {
    title: "Range",
    dataIndex: ["generic_file_record", "data", "range"],
    type: columnTypes.STRING,
    className: `App__w8`,
  },
  {
    title: "Acres",
    dataIndex: ["generic_file_record", "data", "acres"],
    type: columnTypes.STRING,
    className: `App__w8`,
  },
  {
    title: "Royalty",
    dataIndex: ["generic_file_record", "data", "royalty"],
    type: columnTypes.STRING,
    className: `App__w8`,
  },
  {
    title: "NTF",
    dataIndex: ["owner", "ntf"],
    type: columnTypes.SELECT,
    options: [
      { value: "false", label: "False" },
      { value: "true", label: "True" },
    ],
    className: `App__w6`,
    selectClassName: `App__w6`,
    format: ({ value }) => <NTFIcon value={value} />,
  },
  {
    title: "Lead Status",
    dataIndex: "status",
    type: columnTypes.STRING,
  },
  {
    title: "Lead Owner",
    dataIndex: ["user", "alias"],
    type: columnTypes.STRING,
    visible: false,
  },
  {
    title: "Created at",
    dataIndex: "created_at",
    type: columnTypes.DATE_TIME,
    visible: false,
  },
];

class Leads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isLoadingInline: false,
      selectedLeads: [],
      selectedLeadKeys: [],
      editingLead: { owner: {}, unit: {} },
      isLeadEditVisible: false,
      isBulkEditModalVisible: false,
      isLoadingBulkEdit: false,
      isMailExportModalVisible: false,
      isLoadingMailExport: false,
      isIDIBulkSearchModalVisible: false,
      isUSPSBulkSearchModalVisible: false,
      isLoadingIDIBulkSearch: false,
      isLoadingUSPSBulkSearch: false,
    };
  }

  showDeleteConfirm = (leadId) => {
    const { isLoading } = this.state;
    confirm({
      title: "Are you sure you want to delete this lead?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      okButtonProps: { disabled: isLoading, loading: isLoading },
      cancelButtonProps: { disabled: isLoading, loading: isLoading },
      onOk: () => this.deleteLead(leadId),
    });
  };

  deleteLead = (leadId) => {
    const { executeSearch } = this.props;
    this.setState({ isLoading: true });
    apiBase
      .delete(`/leads/${leadId}`)
      .then((res) => {
        executeSearch();
        this.setState({ isLoading: false });
        let msg = `${res.status}: ${res.data.message}`;
        message.success(msg);
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to delete Lead.`;
        message.error(msg);
        this.setState({ isLoading: false });
      });
  };

  handleLeadEdit(key) {
    const index = this.props.records.findIndex((item) => key === item.key);
    const editingLead = this.props.records[index];
    this.setState({
      editingLead: editingLead,
      isLeadEditVisible: true,
    });
  }

  handleLeadCancel = () => {
    this.setState({
      editingLead: null,
      isLeadEditVisible: false,
    });
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  showBulkEditModal = () => {
    this.setState({ isBulkEditModalVisible: true });
  };

  handleCancelBulkEdit = () => {
    this.setState({ isBulkEditModalVisible: false });
  };

  saveFormRefBulkEdit = (formRef) => {
    this.formRefBulkEdit = formRef;
  };

  handleSubmitBulkEdit = () => {
    const { executeSearch, view } = this.props;
    const form = this.formRefBulkEdit.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.setState({ isLoadingBulkEdit: true });
      let data = {
        update_values: values,
        lead_ids: this.state.selectedLeads.map((row) => row.id),
        view,
      };
      const queryString = this.props.location.search;
      apiBase
        .patch(`/leads${queryString}`, data)
        .then((res) => {
          executeSearch();
          this.setState({
            isBulkEditModalVisible: false,
            isLoadingBulkEdit: false,
            selectedLeads: [],
            selectedLeadKeys: [],
          });
          let msg = `${res.status}: ${res.data.message}`;
          message.success(msg);
          form.resetFields();
        })
        .catch((err) => {
          let msg = `${err.response.status}: ${err.response.data.message}`;
          message.error(msg);
          this.setState({
            isBulkEditModalVisible: false,
            isLoadingBulkEdit: false,
            selectedLeads: [],
            selectedLeadKeys: [],
          });
          form.resetFields();
        });
    });
  };

  showMailExportModal = () => {
    this.setState({ isMailExportModalVisible: true });
  };

  handleCancelMailExport = () => {
    this.setState({ isMailExportModalVisible: false });
  };

  handleSubmitMailExport = (values) => {
    const { executeSearch, view } = this.props;

    this.setState({ isLoadingMailExport: true });
    let data = {
      ...values,
      lead_ids: this.state.selectedLeads.map((row) => row.id),
      view,
    };
    const queryString = this.props.location.search;
    return apiBase
      .post(`/mail-exports${queryString}`, data)
      .then((res) => {
        executeSearch();
        this.setState({
          isMailExportModalVisible: false,
          isLoadingMailExport: false,
          selectedLeads: [],
          selectedLeadKeys: [],
        });
        let msg = `${get(res, "status")}: Successfully created mail export.`;
        message.success(msg);
        this.props.history(`/mail-exports?limit=50&page=1`);
        // this.props.history(`/settings/mail-templates/${get(res, "data.id")}`);
      })
      .catch((err) => {
        let msg = `${err.response.status}: ${err.response.data.message}`;
        message.error(msg);
        this.setState({
          isMailExportModalVisible: false,
          isLoadingMailExport: false,
          selectedLeads: [],
          selectedLeadKeys: [],
        });
      });
  };

  showBulkDeleteConfirm = () => {
    const { isLoading } = this.state;
    confirm({
      title: "Are you sure you want to delete these leads?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      okButtonProps: { disabled: isLoading, loading: isLoading },
      cancelButtonProps: { disabled: isLoading, loading: isLoading },
      onOk: () => this.handleBulkDelete(),
    });
  };

  handleBulkDelete = () => {
    const { executeSearch } = this.props;
    this.setState({ isLoading: true });
    let data = {
      lead_ids: this.state.selectedLeads.map((row) => row.id),
    };
    apiBase
      .post("/leads/delete", data)
      .then((res) => {
        executeSearch();
        this.setState({
          isLoading: false,
          selectedLeads: [],
          selectedLeadKeys: [],
        });
        let msg = `${res.status}: ${res.data.message}`;
        message.success(msg);
      })
      .catch((err) => {
        let msg = `${err.response.status}: ${err.response.data.message}`;
        message.error(msg);
        this.setState({
          isLoading: false,
          selectedLeads: [],
          selectedLeadKeys: [],
        });
      });
  };

  showIDIBulkSearchModal = () => {
    this.setState({ isIDIBulkSearchModalVisible: true });
  };

  handleCancelIDIBulkSearch = () => {
    this.setState({ isIDIBulkSearchModalVisible: false });
  };

  handleSubmitIDIBulkSearch = (values) => {
    const { executeSearch, view } = this.props;

    this.setState({ isLoadingIDIBulkSearch: true });
    let data = {
      ...values,
      lead_ids: this.state.selectedLeads.map((row) => row.id),
      view,
    };
    const queryString = this.props.location.search;
    return apiBase
      .post(`/idi-bulk-searches${queryString}`, data)
      .then((res) => {
        executeSearch();
        this.setState({
          isIDIBulkSearchModalVisible: false,
          isLoadingIDIBulkSearch: false,
          selectedLeads: [],
          selectedLeadKeys: [],
        });
        let msg = `${get(
          res,
          "status"
        )}: Successfully initiated IDI bulk search.`;
        message.success(msg);
        // Redirect to IDI bulk searches page or show results as needed
        this.props.history(`/idi-bulk-searches/${get(res, "data.id")}`);
      })
      .catch((err) => {
        let msg = `${err.response.status}: ${err.response.data.message}`;
        message.error(msg);
        this.setState({
          isIDIBulkSearchModalVisible: false,
          isLoadingIDIBulkSearch: false,
          selectedLeads: [],
          selectedLeadKeys: [],
        });
      });
  };

  showUSPSBulkSearchModal = () => {
    this.setState({ isUSPSBulkSearchModalVisible: true });
  };

  handleCancelUSPSBulkSearch = () => {
    this.setState({ isUSPSBulkSearchModalVisible: false });
  };

  handleSubmitUSPSBulkSearch = (values) => {
    const { executeSearch, view } = this.props;

    this.setState({ isLoadingUSPSBulkSearch: true });
    let data = {
      ...values,
      lead_ids: this.state.selectedLeads.map((row) => row.id),
      view,
    };
    const queryString = this.props.location.search;
    return apiBase
      .post(`/usps-bulk-searches${queryString}`, data)
      .then((res) => {
        executeSearch();
        this.setState({
          isUSPSBulkSearchModalVisible: false,
          isLoadingUSPSBulkSearch: false,
          selectedLeads: [],
          selectedLeadKeys: [],
        });
        let msg = `${get(
          res,
          "status"
        )}: Successfully initiated USPS bulk search.`;
        message.success(msg);
        // Redirect to IDI bulk searches page or show results as needed
        this.props.history(`/usps-bulk-searches/${get(res, "data.id")}`);
      })
      .catch((err) => {
        let msg = `${err.response.status}: ${err.response.data.message}`;
        message.error(msg);
        this.setState({
          isUSPSBulkSearchModalVisible: false,
          isLoadingUSPSBulkSearch: false,
          selectedLeads: [],
          selectedLeadKeys: [],
        });
      });
  };

  onSelectLeadChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedLeadKeys: selectedRowKeys,
      selectedLeads: selectedRows,
    });
  };

  render() {
    const {
      isLoadingRecords,
      isLoadingCount,
      records,
      columnState,
      limit,
      page,
      totalRecords,
      tookRecords,
      tookCount,
      searchFields,
      isExportModalVisible,
      isChangeColumnsVisible,
      isLoadingExport,
    } = this.props;

    const {
      handleSearch,
      handleRecordsChange,
      handlePaginationChange,
      handleFilterClose,
      handleTableChange,
      handleSubmitExport,
      showExportModal,
      handleCancelExport,
      handleSubmitColumnChange,
      showColumnChangeModal,
      handleCancelColumnsChange,
      exportFormRef,
      changeColumnFormRef,
      getColumnProps,
    } = this.props;

    const rowSelection = {
      selectedRowKeys: this.state.selectedLeadKeys,
      onChange: this.onSelectLeadChange,
    };

    let columns = columnState
      .map((column, idx) => ({
        key: idx,
        ...column,
        ...getColumnProps(column),
      }))
      .filter((col) => col.visible === true);

    columns.push({
      title: "",
      dataIndex: "actions",
      visible: true,
      editable: false,
      children: [
        {
          dataIndex: "actions",
          render: (_, record) => {
            return (
              <div className="App__tableOperations">
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  size="small"
                  className="App__tableEditButton"
                  onClick={() => this.handleLeadEdit(record.key)}
                  loading={this.state.isLoadingInline}
                />
                <Button
                  shape="circle"
                  icon={<DeleteOutlined />}
                  size="small"
                  onClick={() => this.showDeleteConfirm(record.id)}
                  loading={this.state.isLoading}
                />
              </div>
            );
          },
        },
      ],
    });

    return (
      <div>
        {!isEmpty(this.state.editingLead) && (
          <LeadsEditForm
            lead={this.state.editingLead}
            visible={this.state.isLeadEditVisible}
            handleLeadCancel={this.handleLeadCancel}
            users={this.props.users}
            leadStatus={this.props.leadStatus}
            executeSearch={this.props.executeSearch}
          />
        )}
        <Row className="App__rowContent">
          <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16}>
            <Button className="App__mr-5" onClick={() => handleSearch()}>
              Search
            </Button>
            <Select
              defaultValue={this.props.view}
              onChange={this.props.handleLeadViewChange}
              className="App__mr-5"
            >
              <Select.Option value={leadViews.tax_roll}>Tax roll</Select.Option>
              <Select.Option value={leadViews.generics}>Generics</Select.Option>
            </Select>
            <FilterTags
              searchFields={searchFields}
              columnState={columnState}
              handleFilterClose={handleFilterClose}
            />
          </Col>
          <Col
            span={8}
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            className="App__rightActions"
          >
            {this.state.selectedLeadKeys.length > 0 && (
              <Tooltip
                placement="bottomRight"
                title={"Bulk delete selected leads"}
              >
                <Button
                  onClick={this.showBulkDeleteConfirm}
                  className={
                    this.state.isLoading === true
                      ? `${styles.bulkDeleteButton} ${styles.noPointerEvents}`
                      : styles.bulkDeleteButton
                  }
                >
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            )}
            <Tooltip placement="bottomRight" title={"Create mail export"}>
              <Button
                onClick={this.showMailExportModal}
                className={styles.mailExportButton}
              >
                <MailOutlined />
              </Button>
            </Tooltip>
            <CreateMailExport
              visible={this.state.isMailExportModalVisible}
              isLoading={this.state.isLoadingMailExport}
              onCancel={this.handleCancelMailExport}
              onCreate={this.handleSubmitMailExport}
              selectedLeads={this.state.selectedLeads}
            />
            <Tooltip placement="bottomRight" title={"Run USPS Bulk Search"}>
              <Button
                onClick={this.showUSPSBulkSearchModal}
                className={styles.iconBulkSearchButton}
              >
                <USPSLogo width="16" height="16" />
              </Button>
            </Tooltip>
            <CreateUSPSBulkSearch
              visible={this.state.isUSPSBulkSearchModalVisible}
              isLoading={this.state.isLoadingUSPSBulkSearch}
              onCancel={this.handleCancelUSPSBulkSearch}
              onCreate={this.handleSubmitUSPSBulkSearch}
              selectedLeads={this.state.selectedLeads}
            />
            <Tooltip placement="bottomRight" title={"Run IDI Bulk Search"}>
              <Button
                onClick={this.showIDIBulkSearchModal}
                className={styles.iconBulkSearchButton}
              >
                <IDILogo width="16" height="16" />
              </Button>
            </Tooltip>
            <CreateIDIBulkSearch
              visible={this.state.isIDIBulkSearchModalVisible}
              isLoading={this.state.isLoadingIDIBulkSearch}
              onCancel={this.handleCancelIDIBulkSearch}
              onCreate={this.handleSubmitIDIBulkSearch}
              selectedLeads={this.state.selectedLeads}
            />
            <Tooltip placement="bottomRight" title={"Bulk edit"}>
              <Button
                onClick={this.showBulkEditModal}
                className={styles.bulkEditButton}
              >
                <EditOutlined />
              </Button>
            </Tooltip>
            <BulkEditForm
              wrappedComponentRef={this.saveFormRefBulkEdit}
              visible={this.state.isBulkEditModalVisible}
              isLoading={this.state.isLoadingBulkEdit}
              onCancel={this.handleCancelBulkEdit}
              onCreate={this.handleSubmitBulkEdit}
              selectedLeads={this.state.selectedLeads}
              users={this.props.users}
              leadStatus={this.props.leadStatus}
            />
            <Tooltip placement="bottomRight" title={"Export search results"}>
              <Button className="App__iconButton" onClick={showExportModal}>
                <DownloadOutlined />
              </Button>
            </Tooltip>
            <ExportForm
              wrappedComponentRef={exportFormRef}
              visible={isExportModalVisible}
              isLoading={isLoadingExport}
              onCancel={handleCancelExport}
              onCreate={handleSubmitExport}
              totalRecords={totalRecords}
            />
            <Tooltip placement="bottomRight" title={"Change visible columns"}>
              <Button
                onClick={showColumnChangeModal}
                className="App__iconButton"
              >
                <TableOutlined />
              </Button>
            </Tooltip>
            <ChangeColumnsForm
              wrappedComponentRef={changeColumnFormRef}
              visible={isChangeColumnsVisible}
              isLoading={isLoadingRecords}
              onCancel={handleCancelColumnsChange}
              onOk={handleSubmitColumnChange}
              columnState={columnState}
            />
            <Select
              value={limit}
              onChange={handleRecordsChange}
              className={"App__mr-5"}
            >
              <Select.Option value={10}>10</Select.Option>
              <Select.Option value={25}>25</Select.Option>
              <Select.Option value={50}>50</Select.Option>
              <Select.Option value={100}>100</Select.Option>
              <Select.Option value={1000}>1000</Select.Option>
            </Select>
            <TotalRecords
              title={"Leads"}
              isLoadingCount={isLoadingCount}
              totalRecords={totalRecords}
            />
          </Col>
        </Row>
        <Row className="App__rowContent">
          <Col span={24}>
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={records}
              pagination={false}
              showSorterTooltip={false}
              rowClassName={styles.editableRow}
              className="App__smallTables"
              onChange={handleTableChange}
              loading={isLoadingRecords || this.state.isLoading}
              scroll={{ x: "100%" }}
              size="small"
              bordered
            />
            <div className="App__searchFooterContainer">
              <Took
                isLoadingRecords={isLoadingRecords}
                isLoadingCount={isLoadingCount}
                tookRecords={tookRecords}
                tookCount={tookCount}
              />
              <Pagination
                current={page}
                pageSize={limit}
                total={totalRecords}
                onChange={handlePaginationChange}
                className="App__pagination"
                showSizeChanger={false}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const LeadsWithSearch = (props) => {
  const query = parse(props.location.search);
  const view = query.view;

  const handleLeadViewChange = (value) => {
    const queryString = stringify({
      limit: DEFAULT_PAGE_SIZE,
      page: 1,
      view: value,
    });
    window.open(`/leads?${queryString}`, "_self");
  };

  return (
    <BaseSearch
      baseColumnState={
        view === leadViews.tax_roll ? taxRollColumnState : genericsColumnState
      }
      searchUri="leads"
    >
      <Leads
        {...props}
        handleLeadViewChange={handleLeadViewChange}
        view={view}
      />
    </BaseSearch>
  );
};

export default withParams(LeadsWithSearch);
