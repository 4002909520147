import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiBase } from "../../utils/apiBase";

import BaseControls from "../maps/BaseControls";
import mapActions from "../../actions/map";
import * as permitPlats from "../maps/layers/permitPlats";
import * as units from "../maps/layers/units";
import { useLayerFilter } from "../maps/hooks/useLayerFilter";
import { useInitialization } from "../maps/hooks/useInitialization";
import { fitMapToBounds } from "../maps/common/utils";

import DrawCardControls from "../maps/controls/DrawCardControls";

import { Spin, message } from "antd";

import * as turf from "@turf/turf";

import styles from "./PermitPlatMap.module.scss";

const PermitPlatMap = ({
  fetchLoading,
  permitPlat,
  permitPlatUnits,
  fetchData,
}) => {
  const mapRef = useRef(null);
  const mapContainerRef = useRef(null);
  const drawRef = useRef(null);
  const [mapLoaded, setMapLoaded] = useState(false);

  useInitialization({
    mapRef,
    mapContainerRef,
    drawRef,
    setMapLoaded,
  });

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const edit = useSelector((state) => state.map.controls.draw.edit);

  const setPermitPlatFilter = useLayerFilter(
    mapRef,
    mapLoaded,
    permitPlats.LAYER_ID,
    [permitPlat]
  );

  const setUnitsFilter = useLayerFilter(
    mapRef,
    mapLoaded,
    units.LAYER_ID,
    permitPlatUnits
  );

  // After map is loaded reset map and zoom to permit plat
  useEffect(() => {
    if (!mapLoaded || fetchLoading) return;
    dispatch(mapActions.resetMap(mapRef));

    if (!permitPlat.geom_polygon) return;
    const features = turf.feature(permitPlat.geom_polygon);
    fitMapToBounds(mapRef, features);

    setPermitPlatFilter(true);
    setUnitsFilter(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef, mapLoaded, fetchLoading, permitPlat, permitPlatUnits]);

  // Toggle edit
  useEffect(() => {
    const draw = drawRef.current;
    if (!mapLoaded || !draw || !permitPlat.geom_polygon) return;

    if (edit) {
      setPermitPlatFilter(false);
      setUnitsFilter(false);
      const features = turf.feature(permitPlat.geom_polygon);
      const existingFeatures = draw.getAll().features;
      if (existingFeatures.length === 0) {
        draw.add(features);
      }
    } else {
      draw.deleteAll();
      dispatch(mapActions.resetMap(mapRef));
      setPermitPlatFilter(true);
      setUnitsFilter(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapLoaded, edit, permitPlat.geom_polygon]);

  const handleSave = () => {
    const draw = drawRef.current;
    if (!draw) return;

    const features = draw.getAll();
    setLoading(true);

    apiBase
      .patch(`/permit-plats/${permitPlat.id}/map`, {
        geom_polygon: features,
      })
      .then((res) => {
        message.success("Permit plat map updated successfully!");
      })
      .catch((err) => {
        message.error("Error updating permit plat map");
      })
      .finally(() => {
        setLoading(false);
        mapRef.current
          .getSource(permitPlats.SOURCE_ID)
          .setTiles([permitPlats.TILE_ENDPOINT]);
        fetchData();
      });
  };

  return (
    <div className={styles.main}>
      <div className={styles.card}>
        <div className={styles.cardTitle}>
          <div className={styles.leftTitle}>
            <h2>Map</h2>
          </div>
          <div className={styles.rightTitle}>
            <DrawCardControls handleSave={handleSave} loading={loading} />
          </div>
        </div>
        <div className={styles.cardBody}>
          <div className={styles.mapContainer}>
            <div
              id="map-container"
              className={styles.map}
              ref={mapContainerRef}
            />
            {mapLoaded ? (
              <BaseControls ref={mapRef} />
            ) : (
              <div className={styles.mapLoading}>
                <Spin />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PermitPlatMap;
