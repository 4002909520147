import React, { useState, useEffect } from "react";
import { debounce } from "lodash";
import clsx from "clsx";

import styles from "./GridPosition.module.scss";

const GRID_LAYERS = ["Enverus grid sections"];

const renderGridPosition = (gridPosition) => {
  return (
    <div>
      {[
        gridPosition?.county,
        gridPosition?.range && `R${gridPosition.range}`,
        gridPosition?.township && `T${gridPosition.township}`,
        gridPosition?.block && `B${gridPosition.block}`,
        gridPosition?.section && `S${gridPosition.section}`,
        gridPosition?.abstract && `A${gridPosition.abstract}`,
      ]
        .filter(Boolean)
        .join(" | ")}
    </div>
  );
};

const GridPosition = ({ mapRef }) => {
  const [gridPosition, setGridPosition] = useState(null);

  const moveHandler = (e) => {
    const features = mapRef.current.queryRenderedFeatures(e.point, {
      layers: GRID_LAYERS,
    });

    const surveyPolygon = features.length > 0 ? features[0].properties : {};
    const objectId = surveyPolygon?.object_id || "";
    const currentObjectId = gridPosition?.object_id || "";

    if (objectId !== currentObjectId) {
      setGridPosition(surveyPolygon);
    }
  };

  useEffect(() => {
    if (!mapRef.current) return;
    // console.log("GRID POSITION");

    const map = mapRef.current;

    const debouncedMoveHandler = debounce(moveHandler, 50);
    map.on("mousemove", debouncedMoveHandler);

    return () => {
      map.off("mousemove", debouncedMoveHandler);
      debouncedMoveHandler.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef]);

  return (
    <div
      className={clsx(styles.gridPosition, { [styles.hidden]: !gridPosition })}
    >
      {gridPosition && renderGridPosition(gridPosition)}
    </div>
  );
};

export default GridPosition;
