import React, { useState } from "react";
import { get } from "lodash";
import { connect } from "react-redux";
import actions from "../../actions";
import { apiBase } from "../../utils/apiBase";

import { Modal, Form, Input, Button, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const mapStateToProps = (state) => ({
  entities: get(state.entities, "data", []),
  entity: get(state.entity, "data", {}),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const EntityCreateForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);

    apiBase
      .post(`entities`, values)
      .then(async (res) => {
        message.success("Successfully created entity.");
        await props.dispatch(actions.entities.getEntities());
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to create entity.`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
        resetComponent();
        setIsModalOpen(false);
        props.executeSearch();
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    resetComponent();
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    resetComponent();
  };

  const submitForm = () => {
    form
      .validateFields()
      .then(() => {
        form.submit();
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };

  const resetComponent = () => {
    form.resetFields();
  };

  const [form] = Form.useForm();

  return (
    <div>
      <Button type="secondary" icon={<PlusOutlined />} onClick={showModal} />
      <Modal
        title="Create entity"
        visible={isModalOpen}
        onOk={() => submitForm()}
        onCancel={handleCancel}
        cancelButtonProps={{ disabled: loading }}
        okButtonProps={{ disabled: loading, loading: loading }}
        maskClosable={false}
      >
        <Form
          name="entityCreate"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
          disabled={loading}
        >
          {[
            { name: "name", label: "Name", required: true },
            { name: "name_legal", label: "Legal name", required: false },
            { name: "address", label: "Address", required: false },
            { name: "city", label: "City", required: false },
            { name: "state", label: "State", required: false },
            { name: "zip_code", label: "Zip code", required: false },
          ].map((item, index) => (
            <Form.Item
              key={index}
              label={item.label}
              name={item.name}
              rules={[
                {
                  required: item.required,
                  message: `${item.label} is required.`,
                },
              ]}
            >
              <Input />
            </Form.Item>
          ))}
        </Form>
      </Modal>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityCreateForm);
