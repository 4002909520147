import React, { useState, useEffect } from "react";
import { apiBase } from "../../utils/apiBase";
import { get } from "lodash";
import { useNavigate } from "react-router-dom";

import {
  Modal,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Button,
  message,
} from "antd";

import styles from "./EndatoSearchCreateForm.module.scss";

const EndatoSearchCreateForm = (props) => {
  const { owner } = props;

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setLoading(true);

    let searchId = null;
    let statusCode = null;

    apiBase
      .post(`endato-searches/person`, {
        ...values,
        ...(values.date_of_birth
          ? { dob: values.date_of_birth.format("MM/DD/YYYY") }
          : {}),
        ...(get(owner, "id", null) ? { owner_id: owner.id } : {}),
      })
      .then(async (res) => {
        searchId = get(res, "data.id", null);
        statusCode = get(res, "data.response_status_code", null);
        const isError = get(res, "data.isError", null);
        const totalResults = get(res, "data.total_results", 0);
        if (!isError && totalResults > 0) {
          message.success(`${statusCode}: Successfully created Endato search.`);
        } else if (!isError && totalResults === 0) {
          message.warning(
            `${statusCode}: Successful search, no results found.`
          );
        } else {
          message.error(
            `${statusCode}: ${get(res, "data.error", "Error unknown error.")}`
          );
        }
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to create Endato search.`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
        resetComponent();
        setIsModalOpen(false);
        if (searchId && statusCode === 200 && !get(owner, "id", null)) {
          navigate(`/endato-searches/${searchId}`);
        } else {
          if (typeof props.refetch === "function") {
            props.refetch();
          }
        }
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    resetComponent();
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    resetComponent();
  };

  const submitForm = () => {
    form
      .validateFields()
      .then(() => {
        form.submit();
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };

  const resetComponent = () => {
    form.resetFields();
  };

  const [form] = Form.useForm();

  useEffect(() => {
    if (owner) {
      form.setFieldsValue({
        first_name: owner.first_name,
        middle_name: !!owner.middle_name ? owner.middle_name[0] : "",
        last_name: owner.last_name,
        street_address: owner.address_formatted,
        city: owner.city,
        state: owner.state,
        zip: owner.zip_code,
      });
    }
  }, [owner, form]);

  return (
    <div>
      <Button type="secondary" onClick={showModal}>
        Search Endato
      </Button>
      <Modal
        title="Create Endato search"
        visible={isModalOpen}
        onOk={() => submitForm()}
        onCancel={handleCancel}
        okText="Search"
        cancelButtonProps={{ disabled: loading }}
        okButtonProps={{ disabled: loading, loading: loading }}
        maskClosable={false}
      >
        <div className={styles.compactForm}>
          <Form
            name="endatoSearchCreate"
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
            disabled={loading}
            initialValues={
              owner
                ? {
                    first_name: owner.first_name,
                    middle_name: !!owner.middle_name
                      ? owner.middle_name[0]
                      : "",
                    last_name: owner.last_name,
                    street_address: owner.address_formatted,
                    city: owner.city,
                    state: owner.state,
                    zip: owner.zip_code,
                  }
                : {}
            }
          >
            <Form.Item label="First name" name="first_name">
              <Input />
            </Form.Item>
            <Form.Item label="Middle name" name="middle_name">
              <Input />
            </Form.Item>
            <Form.Item label="Last name" name="last_name">
              <Input />
            </Form.Item>
            <div className={styles.twoColumns}>
              <Form.Item label="Date of birth" name="date_of_birth">
                <DatePicker
                  className="App__fullWidth"
                  format={[
                    "M/DD/YY",
                    "M/D/YY",
                    "MM/D/YY",
                    "M/DD/YYYY",
                    "M/D/YYYY",
                    "MM/D/YYYY",
                  ]}
                />
              </Form.Item>
              <Form.Item
                label="Age"
                name="age"
                rules={[
                  {
                    type: "number",
                    min: 0,
                    max: 120,
                    message: "",
                  },
                ]}
              >
                <InputNumber className="App__fullWidth" precision={0} />
              </Form.Item>
            </div>
            <Form.Item label="Street address" name="street_address">
              <Input />
            </Form.Item>
            <div className={styles.twoColumns}>
              <Form.Item label="City" name="city">
                <Input />
              </Form.Item>
              <Form.Item label="County" name="county">
                <Input />
              </Form.Item>
            </div>
            <div className={styles.twoColumns}>
              <Form.Item label="State" name="state">
                <Input />
              </Form.Item>
              <Form.Item label="Zip" name="zip">
                <Input />
              </Form.Item>
            </div>
            <div className={styles.twoColumns}>
              <Form.Item label="Phone number" name="phone_number">
                <Input />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "This is not a valid email address.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default EndatoSearchCreateForm;
