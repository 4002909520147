import React, { Component } from "react";
import { get, uniq } from "lodash";
import { formatNumber, alphaSort } from "../../utils/numbers";
import { flattenLabels } from "../../common/pipedrivePersons";

import { Table } from "antd";

import styles from "./UnitOwners.module.scss";
import PipedrivePersonLabels from "../common/PipedrivePersonLabels";
import { NTFIcon } from "../common/LabelIcon";

class UnitOwners extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  interestFilterStyles(record) {
    if (record.interest_filter === true) {
      return styles.badInterest;
    }
  }

  handleRowClick(e, _to) {
    e.stopPropagation();
    if (e.ctrlKey || e.metaKey) {
      window.open(_to, "_blank");
      return;
    }
    this.props.history(_to);
  }

  render() {
    const { columnExtras } = this.props;

    const rowSelection = {
      selectedRowKeys: this.props.selectedInterestKeys,
      onChange: this.props.onSelectInterestChange,
    };

    const columns = [
      {
        title: "Owner",
        dataIndex: "owner_id",
        className: `App__rowLink App__w8`,
        render: (value) => <div className="App__rowIcon">{value}</div>,
        onCell: (record) => ({
          onClick: (e) =>
            this.handleRowClick(e, `/owners/${record["owner_id"]}`),
        }),
      },
      {
        title: "Label",
        dataIndex: "pipedrive_persons",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => {
          return alphaSort(
            flattenLabels(get(a, "pipedrive_persons", [])),
            flattenLabels(get(b, "pipedrive_persons", []))
          );
        },
        render: (value) => <PipedrivePersonLabels pipedrivePersons={value} />,
      },
      {
        title: "Lead status",
        dataIndex: "leads",
        className: `App__w9`,
        // ellipsis: {
        //   showTitle: false,
        // },
        render: (value) => {
          const _value = uniq(
            (value || []).map((item) => `${get(item, "status", "")} `)
          );
          return _value.join(" ");
        },
      },
      {
        title: "Name",
        dataIndex: "name",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => alphaSort(a.name, b.name),
      },
      {
        title: "Formatted Name",
        dataIndex: "name_formatted",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => alphaSort(a.name_formatted, b.name_formatted),
      },
      {
        title: "Address",
        dataIndex: "address",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => alphaSort(a.address, b.address),
      },
      {
        title: "Formatted Address",
        dataIndex: "address_formatted",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => alphaSort(a.address_formatted, b.address_formatted),
      },
      {
        title: "City",
        dataIndex: "city",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => alphaSort(a.city, b.city),
      },
      {
        title: "State",
        dataIndex: "state",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => alphaSort(a.state, b.state),
      },
      {
        title: "Zip code",
        dataIndex: "zip_code",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => alphaSort(a.zip_code, b.zip_code),
      },
      {
        title: "NTF",
        dataIndex: "ntf",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => alphaSort(a.ntf, b.ntf),
        render: (value) => <NTFIcon value={value} />,
      },
      {
        title: "Unit Count",
        dataIndex: "unit_count",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => a.unit_count - b.unit_count,
      },
      {
        title: "Address unit count",
        dataIndex: "address_unit_count",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => a.address_unit_count - b.address_unit_count,
      },
      {
        title: "Interest Type",
        dataIndex: "interest_type",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => alphaSort(a.interest_type, b.interest_type),
      },
      {
        title: "Decimal Interest",
        dataIndex: "decimal_interest",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => a.decimal_interest - b.decimal_interest,
        render: (value) =>
          value !== null ? formatNumber(value, "0.000000") : null,
      },
      {
        title: "NRA",
        dataIndex: "nra",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => a.nra - b.nra,
        render: (value) =>
          value !== null ? formatNumber(value, "0.00") : null,
      },
      {
        title: "Tax year",
        dataIndex: "tax_year",
      },
      ...(columnExtras
        ? [
            {
              title: "Owner name",
              dataIndex: "owner_name",
              sortDirections: ["descend", "ascend"],
              sorter: (a, b) => alphaSort(a.owner_name, b.owner_name),
            },
            {
              title: "Owner address",
              dataIndex: "owner_address",
              sortDirections: ["descend", "ascend"],
              sorter: (a, b) => alphaSort(a.owner_address, b.owner_address),
            },
            {
              title: "Owner Zip Code",
              dataIndex: "owner_zip_code",
              sortDirections: ["descend", "ascend"],
              sorter: (a, b) => alphaSort(a.owner_zip_code, b.owner_zip_code),
            },
            {
              title: "Legal description",
              dataIndex: "legal_description",
              sortDirections: ["descend", "ascend"],
              sorter: (a, b) =>
                alphaSort(a.legal_description, b.legal_description),
            },
          ]
        : []),
    ];

    return (
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={this.props.ownerUnitInterests}
        rowClassName={this.interestFilterStyles}
        className="App__smallTables"
        pagination={{
          pageSize: this.props.recordsPerPage,
          showSizeChanger: false,
        }}
        showSorterTooltip={false}
        loading={this.props.isLoadingInterests}
        scroll={{ x: "100%" }}
        bordered
      />
    );
  }
}

export default UnitOwners;
