import React, { useState } from "react";
import { Button, Modal } from "antd";
import ReactJson from "react-json-view";
import { get } from "lodash";

import { DatabaseOutlined } from "@ant-design/icons";

const RawDataModal = (props) => {
  const { modalProps, reactJsonProps, iconOnly, data } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = (e) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = (e) => {
    e.stopPropagation();
    setIsModalOpen(false);
  };

  return (
    <>
      {iconOnly ? (
        <DatabaseOutlined onClick={showModal} />
      ) : (
        <Button size="small" icon={<DatabaseOutlined />} onClick={showModal} />
      )}
      <Modal
        title="Basic Modal"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
        bodyStyle={{ padding: "12px 24px" }}
        {...modalProps}
      >
        <ReactJson
          src={data || {}}
          collapsed={false}
          style={{
            backgroundColor: "white",
            fontSize: "12px",
            border: "1px solid gainsboro",
            overflow: "auto",
            padding: "5px",
            ...get(reactJsonProps, "style", {}),
          }}
          displayDataTypes={false}
          {...reactJsonProps}
        />
      </Modal>
    </>
  );
};
export default RawDataModal;
