import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { get } from "lodash";

import { formatDate } from "../utils/dates";
import { apiBase } from "../utils/apiBase";
import {
  mailLetterType,
  mailLetterTypeTitle,
  mailLetterTypeColor,
} from "../common/mailLetterType";

import { alphaSort } from "../utils/numbers";
import { LabelIcon } from "./common/LabelIcon";
import TotalRecords from "./search/TotalRecords";
import CreateMailQueueExport from "./forms/CreateMailQueueExport";
import { Table, Button, message, Modal, Row, Col } from "antd";

import { DeleteOutlined, ExclamationCircleFilled } from "@ant-design/icons";

const { confirm } = Modal;

const mapStateToProps = (state) => ({
  users: get(state.users, "data", []),
});

const MailQueue = (props) => {
  const { users } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [mailLetters, setMailLetters] = useState([]);

  useEffect(() => {
    fetchMailLetters();
  }, []);

  const handleRowClick = (e, _to) => {
    e.stopPropagation();
    if (e.ctrlKey || e.metaKey) {
      window.open(_to, "_blank");
      return;
    }
    props.history(_to);
  };

  const fetchMailLetters = () => {
    setIsLoading(true);
    apiBase
      .get("/mail-letters/mail-queue")
      .then((res) => {
        setMailLetters(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const deleteMailLetter = (id) => {
    setIsLoading(true);
    apiBase
      .delete(`/mail-letters/${id}`)
      .then((res) => {
        message.success("Successfully deleted mail letter.");
        fetchMailLetters();
      })
      .catch((err) => {
        console.log(err);
        message.error("Failed to delete mail letter.");
        setIsLoading(false);
      });
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: "Are you sure you want to delete this mail letter?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      okButtonProps: { disabled: isLoading, loading: isLoading },
      cancelButtonProps: { disabled: isLoading, loading: isLoading },
      onOk: () => deleteMailLetter(id),
    });
  };

  const columns = [
    {
      title: "Letter",
      dataIndex: "id",
      className: `App__rowLink App__w8`,
      render: (value) => <div className="App__rowIcon">{value}</div>,
      onCell: (record) => ({
        onClick: (e) => handleRowClick(e, `/mail-letters/${record["id"]}`),
      }),
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Owner",
      dataIndex: "owner_id",
      className: `App__rowLink App__w10`,
      render: (value) => <div className="App__rowIcon">{value}</div>,
      onCell: (record) => ({
        onClick: (e) => {
          if (record["owner_id"]) {
            handleRowClick(e, `/owners/${record["owner_id"]}`);
          }
        },
      }),
      sorter: (a, b) => a.owner_id - b.owner_id,
    },
    {
      title: "Mail template",
      dataIndex: "mail_template_id",
      className: `App__rowLink App__w12`,
      render: (value) => <div className="App__rowIcon">{value}</div>,
      onCell: (record) => ({
        onClick: (e) => {
          if (record["mail_template_id"]) {
            handleRowClick(
              e,
              `/settings/mail-templates/${record["mail_template_id"]}`
            );
          }
        },
      }),
      sorter: (a, b) => a.mail_template_id - b.mail_template_id,
    },
    {
      title: "IDI Person",
      dataIndex: "idi_person_id",
      className: `App__rowLink App__w12`,
      render: (value) => <div className="App__rowIcon">{value}</div>,
      onCell: (record) => ({
        onClick: (e) => {
          if (record["idi_person_id"]) {
            handleRowClick(e, `/idi-persons/${record["idi_person_id"]}`);
          }
        },
      }),
      sorter: (a, b) => a.mail_template_id - b.mail_template_id,
    },
    {
      title: "Type",
      dataIndex: "type",
      sorter: (a, b) => alphaSort(a.type, b.type),
      render: (value) => (
        <LabelIcon
          content={mailLetterTypeTitle[value]}
          backgroundColor={mailLetterTypeColor[value]}
          color={value === mailLetterType.standard ? "black" : "white"}
        />
      ),
    },
    {
      title: "Created by",
      dataIndex: "user_id",
      sorter: (a, b) => a.user_id - b.user_id,
      render: (value, record) => {
        const user = users.find((user) => user.id === value);
        return user ? user.alias : null;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => alphaSort(a.name, b.name),
    },
    {
      title: "Name Attn",
      dataIndex: "name_attn",
      sorter: (a, b) => alphaSort(a.name_attn, b.name_attn),
    },
    {
      title: "Street",
      dataIndex: "street",
      sorter: (a, b) => alphaSort(a.street, b.street),
    },
    {
      title: "City",
      dataIndex: "city",
      sorter: (a, b) => alphaSort(a.city, b.city),
    },
    {
      title: "State",
      dataIndex: "state",
      sorter: (a, b) => alphaSort(a.state, b.state),
    },
    {
      title: "Zip",
      dataIndex: "zip_code",
      sorter: (a, b) => alphaSort(a.zip_code, b.zip_code),
    },
    {
      title: "Fractional Ownership",
      dataIndex: "fractional_ownership",
      className: `App__rowLink App__w16`,
      sorter: (a, b) => a.fractional_ownership - b.fractional_ownership,
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => alphaSort(a.created_at, b.created_at),
      render: (value) => formatDate(value, "YYYY-MM-DD HH:mm"),
    },
    {
      title: "Actions",
      dataIndex: "id",
      render: (id) => (
        <Button
          shape="circle"
          icon={<DeleteOutlined />}
          size="small"
          onClick={() => showDeleteConfirm(id)}
          disabled={isLoading}
        />
      ),
    },
  ];

  return (
    <div style={{ marginBottom: 20 }}>
      <Row className="App__rowContent">
        <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16}>
          <CreateMailQueueExport mailLetters={mailLetters} />
        </Col>
        <Col
          span={8}
          xs={24}
          sm={24}
          md={8}
          lg={8}
          xl={8}
          className="App__rightActions"
        >
          <TotalRecords
            title={"Mail Queue"}
            isLoadingCount={isLoading}
            totalRecords={mailLetters.length}
          />
        </Col>
      </Row>
      <Row className="App__rowContent">
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={mailLetters || []}
            className="App__smallTables"
            showSorterTooltip={false}
            loading={isLoading}
            pagination={false}
            scroll={{ x: "100%" }}
            size="small"
            bordered
          />
        </Col>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps)(MailQueue);
