import { Button, Col, Modal, Table, Row, Tooltip } from "antd";
import React, { useState } from "react";
import { apiBase } from "../../utils/apiBase";
import { withParams } from "../../utils/withParams";
import { formatNumber, alphaSort, downloadCSV } from "../../utils/numbers";
import { get } from "lodash";

import styles from "./UnitSimilarGroupings.module.scss";

import { QuestionCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";

const UnitSimilarGroupings = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [similarGroupings, setSimilarGroupings] = useState([]);

  const showModal = () => {
    setIsModalOpen(true);
    if (similarGroupings.length === 0) {
      setLoading(true);
      apiBase
        .get(`/units/${props.params.id}/similar`)
        .then((res) => {
          setSimilarGroupings(
            get(res, "data.units", []).map((row) => ({
              ...row,
              is_unit_id_equal: props.unit.id === row.unit_id,
            }))
          );
        })
        .finally(() => setLoading(false));
    }
  };
  const handleOk = () => {
    downloadCSV({
      filename: `unit-${unit.id}-similar-${new Date().toJSON()}.csv`,
      data: similarGroupings,
      columns: columns.map((c) => c.dataIndex),
    });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: "Unit",
      dataIndex: "unit_id",
      className: `App__rowLink App__w8`,
      render: (value) => <div className="App__rowIcon">{value}</div>,
      onCell: (record) => ({
        onClick: (e) => window.open(`/units/${record["unit_id"]}`, "_blank"),
      }),
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.unit_id - b.unit_id,
    },
    {
      title: "Unit Id Equal",
      dataIndex: "is_unit_id_equal",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.is_unit_id_equal - b.is_unit_id_equal,
      className: `App__w8`,
      render: (value) => (value ? <CheckCircleOutlined /> : null),
    },
    {
      title: "Tax year",
      dataIndex: "tax_year",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.tax_year - b.tax_year,
      className: `App__w8`,
    },
    {
      title: "Interest count",
      dataIndex: "doi_count",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.doi_count - b.doi_count,
      className: `App__w12`,
    },
    {
      title: "Owner count",
      dataIndex: "unique_owner_count",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.unique_owner_count - b.unique_owner_count,
      className: `App__w12`,
    },
    {
      title: "Cumulative DOI",
      dataIndex: "cumulative_doi",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.cumulative_doi - b.cumulative_doi,
      render: (value) => formatNumber(value, "0.00000000000000"),
      className: `App__w14`,
    },
    {
      title: "Similarity legal description",
      dataIndex: "similarity_legal_description",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        a.similarity_legal_description - b.similarity_legal_description,
      render: (value) => formatNumber(value, "0.0000"),
      className: `App__w12`,
    },
    {
      title: "Similarity unit name",
      dataIndex: "similarity_unit_name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.similarity_unit_name - b.similarity_unit_name,
      render: (value) => formatNumber(value, "0.0000"),
      className: `App__w12`,
    },
    {
      title: "Well number overlap",
      dataIndex: "well_overlap",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => alphaSort(a.well_overlap, b.well_overlap),
      className: `App__w12`,
    },
    {
      title: "Legal description",
      dataIndex: "legal_description",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => alphaSort(a.legal_description, b.legal_description),
    },
  ];

  const { unit } = props;

  return (
    <>
      <Button onClick={showModal}>Similar groupings</Button>
      <Modal
        title={
          <div>
            RRC county tax year groupings{" "}
            <Tooltip
              placement="bottomLeft"
              title={`These are interests grouped by 'rrc_id = ${unit.rrc_id}', 'county = "${unit.county}"' and 'tax_year'`}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        }
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Close"
        okText="Export"
        width={"100%"}
        bodyStyle={{ padding: "10px 20px 0px 20px" }}
        maskClosable={false}
      >
        <div>
          <Row>
            <Col span={24}>
              <div className={styles.cardBody}>
                <Row gutter={24}>
                  <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className={styles.objectData}>
                      <span>Unit id:</span>
                      {unit.id}
                    </div>
                    <div className={styles.objectData}>
                      <span>Name:</span>
                      {unit.name}
                    </div>
                    <div className={styles.objectData}>
                      <span>Name formatted:</span>
                      {unit.name_formatted}
                    </div>
                    <div className={styles.objectData}>
                      <span>Well:</span>
                      {unit.well}
                    </div>
                    <div className={styles.objectData}>
                      <span>Well numbers:</span>
                      {unit.well_numbers}
                    </div>
                  </Col>
                  <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className={styles.objectData}>
                      <span>Tax year:</span>
                      {unit.tax_year}
                    </div>
                    <div className={styles.objectData}>
                      <span>Cumulative DOI:</span>
                      {unit.cumulative_decimal_interest}
                    </div>
                    <div className={styles.objectData}>
                      <span>Owner count:</span>
                      {unit.unique_owner_count}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <div
                className={`${styles.objectData} ${styles.legalDescription}`}
              >
                <span>Legal description:</span>
                {unit.legal_description}
              </div>
            </Col>
          </Row>
        </div>
        <Row>
          <Col span={24}>
            <Table
              // rowSelection={rowSelection}
              loading={loading}
              columns={columns}
              dataSource={similarGroupings}
              // rowClassName={this.interestFilterStyles}
              className="App__smallTables"
              // pagination={{
              //   pageSize: this.props.recordsPerPage,
              //   showSizeChanger: false,
              // }}
              // showSorterTooltip={false}
              // loading={this.props.isLoadingInterests}
              pagination={{ showTotal: (total) => `Total ${total} groupings` }}
              scroll={{ x: "100%" }}
              bordered
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};
export default withParams(UnitSimilarGroupings);
