import React, { useState, useEffect } from "react";
import { withParams } from "../../utils/withParams";
import { SignJWT } from "jose";

import styles from "./CRMDashboard.module.scss";

const METABASE_SITE_URL = "https://metabase.taborminerals.com";
const METABASE_SECRET_KEY =
  "e1b37e189010962765ba1d4e878fe0a7c3e3325a748fc715acb192007d92c545";

const CRMDashboard = (props) => {
  const [iframeUrl, setIframeUrl] = useState("");

  useEffect(() => {
    const createTokenAndSetIframeUrl = async () => {
      var payload = {
        resource: { dashboard: 3 },
        params: {},
        exp: Math.round(Date.now() / 1000) + 10 * 60, // 10 minute expiration
      };

      const secret = new TextEncoder().encode(METABASE_SECRET_KEY);

      const token = await new SignJWT(payload)
        .setProtectedHeader({ alg: "HS256" })
        // .setIssuedAt()
        .setIssuer("https://metabase.taborminerals.com")
        // .setExpirationTime("2h")
        .sign(secret);

      const url =
        METABASE_SITE_URL +
        "/embed/dashboard/" +
        token +
        "#bordered=false&titled=false";

      setIframeUrl(url);
    };

    createTokenAndSetIframeUrl();
  }, [iframeUrl]);

  return (
    <div className={styles.main}>
      <a
        className={styles.hoverLink}
        href="https://metabase.taborminerals.com/dashboard/3-crm?period=week&previous_periods=1"
        target="_blank"
        rel="noreferrer"
      >
        Report link
      </a>
      <iframe
        title="Dashboard"
        width="100%"
        height="100%"
        src={iframeUrl}
        frameBorder={0}
        allowTransparency
      />
    </div>
  );
};

export default withParams(CRMDashboard);
