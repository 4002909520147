import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import styles from "./CustomControl.module.scss";
class CustomControl {
  constructor(opt) {
    this.component = opt?.component;
    this.store = opt?.store;
    this.root = null;
    this.draw = opt?.draw;
    this.isUnmounting = false;
  }

  onAdd(map) {
    this.map = map;
    this.container = document.createElement("div");
    this.container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";

    this.root = ReactDOM.createRoot(this.container);
    this.root.render(
      <Provider store={this.store}>
        <div className={styles.controlContainer}>{this.component}</div>
      </Provider>
    );

    return this.container;
  }

  onRemove(map) {
    // Prevent multiple unmount attempts
    if (this.isUnmounting) return;
    this.isUnmounting = true;

    // Handle React root unmounting
    if (this.root) {
      try {
        // Use requestAnimationFrame to ensure we're not in a render cycle
        requestAnimationFrame(() => {
          this.root.unmount();
          this.root = null;
        });
      } catch (error) {
        console.error("Error unmounting React root!:", error);
        this.root = null;
      }
    }

    // Handle draw control cleanup
    if (this.draw) {
      // try {
      //   this.draw.onRemove(map);
      // } catch (error) {
      //   console.error("Error removing draw control:", error);
      // }
      this.draw = null;
    }

    // Remove DOM element safely
    if (this.container && this.container.parentNode) {
      this.container.parentNode.removeChild(this.container);
    }

    this.map = undefined;
  }
}

export default CustomControl;
