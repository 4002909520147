import React, { useState, useEffect } from "react";
import { convertDataIndex } from "../../utils/search";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { get } from "lodash";
import { Button, Dropdown, Select, Space } from "antd";
import { defaultArrayComparisonOptions } from "./constants";

import styles from "./SelectMultiDropdown.module.scss";

const Mousetrap = require("mousetrap");

const SelectMultiDropdown = (props) => {
  const {
    title,
    dataIndex,
    searchFields,
    handleSearchSelectMultiChange,
    options,
    operatorOptions = defaultArrayComparisonOptions,
    handleSearch,
    handleReset,
    // isMulti,
  } = props;

  const [visible, setVisible] = useState(false);
  const [equality, setEquality] = useState("in");
  const [selectValues, setSelectValues] = useState([]);

  useEffect(() => {
    const convertedDataIndex = convertDataIndex(dataIndex);
    const searchData =
      searchFields[convertedDataIndex] !== undefined
        ? searchFields[convertedDataIndex]
        : {};

    const equalityDefault = get(
      Object.keys(searchData),
      "[0]",
      operatorOptions[0].value
    );
    const operator = get(Object.keys(searchData), "[0]");
    let selectValuesDefault;
    if (!!operator) {
      let _values = get(searchData, operator) || [null];
      _values = Array.isArray(_values) ? _values : [_values];
      selectValuesDefault = _values.map((value) =>
        value === "" ? null : value
      );
    } else {
      selectValuesDefault = [];
    }

    setSelectValues(selectValuesDefault);
    setEquality(equalityDefault);
  }, [searchFields, dataIndex, operatorOptions]);

  useEffect(() => {
    Mousetrap.bind("esc", (e) => {
      e.preventDefault();
      setVisible(false);
    });
    return () => {
      Mousetrap.unbind("esc");
    };
  });

  const confirm = () => {
    handleSearchSelectMultiChange(selectValues, dataIndex, equality);
    setVisible(false);
  };

  const reset = () => {
    handleReset(dataIndex);
    setVisible(false);
    setSelectValues([]);
    setEquality("in");
  };

  const menu = (
    <div className={styles.searchDropDown}>
      <Space.Compact block className={styles.main}>
        <Select
          placeholder={`Select ${title}`}
          value={selectValues}
          // defaultValue={selectValuesDefault}
          className={styles.selectValue}
          onChange={(value) => {
            setSelectValues(value);
          }}
          optionFilterProp="label"
          mode={"multiple"}
          // mode={isMulti ? "multiple" : null}
          options={options}
          allowClear
        />
        <Select
          value={equality}
          onChange={(value) => setEquality(value)}
          suffixIcon={null}
          className={styles.selectAddOn}
        >
          {operatorOptions.map((option) => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </Space.Compact>
      <Button
        type="primary"
        onClick={() => handleSearch(confirm)}
        icon={<SearchOutlined />}
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => reset()} style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  );

  return (
    <Dropdown
      overlay={menu}
      placement="bottomRight"
      trigger={["click"]}
      open={visible}
      onOpenChange={setVisible}
    >
      <Button className={styles.filterButton}>
        <FilterOutlined className={styles.icon} />
      </Button>
    </Dropdown>
  );
};

export default SelectMultiDropdown;
