import React, { createContext, useState, useContext } from "react";

const MapFilterContext = createContext();

export const MapFilterProvider = ({ defaultOpenField = "State", children }) => {
  const [openField, setOpenField] = useState(defaultOpenField);

  return (
    <MapFilterContext.Provider
      value={{
        openField,
        setOpenField,
      }}
    >
      {children}
    </MapFilterContext.Provider>
  );
};

export const useMapFilter = () => {
  const context = useContext(MapFilterContext);
  if (!context) {
    throw new Error("useMapFilter must be used within a MapFilterProvider");
  }
  return context;
};
