import React, { useState } from "react";
import clsx from "clsx";
import { Switch } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

import { CONTROL_IDS } from "../constants";

import styles from "./MapStyles.module.scss";
import WellsStyles from "./styles/WellsStyles";
const MapStyles = ({ mapRef, controlVisible, setControlVisible }) => {
  const [showSatellite, setShowSatellite] = useState(false);

  const toggleSatellite = (showSatellite) => {
    const currentStyle = mapRef.current.getStyle();

    let newStyle = {
      ...currentStyle,
      imports: [
        {
          id: "basemap",
          url: showSatellite
            ? "mapbox://styles/mapbox/standard-satellite"
            : "mapbox://styles/mapbox/standard",
          config: {
            font: "League Mono",
            lightPreset: "day",
            showPlaceLabels: true,
            showRoadsAndTransit: false,
            showPedestrianRoads: false,
            showPointOfInterestLabels: false,
            showTransitLabels: false,
            showRoadLabels: false,
          },
        },
      ],
    };

    mapRef.current.setStyle(newStyle, { diff: false });
    setShowSatellite(showSatellite);
  };

  return (
    <div
      className={clsx(styles.control, {
        [styles.invisible]: controlVisible !== CONTROL_IDS.MAP_STYLES,
      })}
    >
      <div className={styles.controlHeader}>
        <div></div>
        <div>Map Styles</div>
        <div className={styles.controlCollapseButton}>
          <ArrowLeftOutlined onClick={() => setControlVisible(null)} />
        </div>
      </div>
      <div className={styles.controlBody}>
        <div className={styles.satellite}>
          <div className="App__mr-8">
            <Switch
              checked={showSatellite}
              onChange={() => toggleSatellite(!showSatellite)}
              size="small"
              className={clsx(styles.switch, {
                [styles.switchOn]: showSatellite,
                [styles.switchOff]: !showSatellite,
              })}
            />
          </div>
          <div>Satellite</div>
        </div>
        <div className={styles.layersContainer}>
          <WellsStyles mapRef={mapRef} />
        </div>
      </div>
    </div>
  );
};

export default MapStyles;
