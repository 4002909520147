import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { get, debounce } from "lodash";
import actions from "../../actions";

import { Spin, Empty, message } from "antd";

import styles from "./BoxContentExplorer.module.scss";

const mapStateToProps = (state) => ({
  boxAuth: get(state.boxAuth, "data", {}),
  loadingBoxAuth: get(state.boxAuth, "loading", {}),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const pageOffset = 200;

// Content explorer instance
let contentExplorer = null;

const BoxContentExplorer = (props) => {
  const { loadingBoxAuth, boxAuth, dispatch } = props;
  const { folderId, fixedHeight } = props;

  const [height, setHeight] = useState(
    fixedHeight || window.innerHeight - pageOffset
  );

  const getBoxAuth = useCallback(async () => {
    try {
      await dispatch(actions.boxAuth.getBoxAuth());
    } catch (err) {
      let msg = `${err.response.status}: Failed to load box auth`;
      message.error(msg);
    }
  }, [dispatch]);

  // Fetch box auth token if not already fetched
  useEffect(() => {
    if (!boxAuth.token && !loadingBoxAuth) {
      getBoxAuth();
    }
  }, [boxAuth.token, loadingBoxAuth, getBoxAuth]);

  const responseInterceptor = useCallback(
    (response) => {
      if (
        get(response, "status") === 401 ||
        get(response, "message") === "Network Error"
      ) {
        // console.error("Refetching auth", response);
        getBoxAuth();
      }
      return response;
    },
    [getBoxAuth]
  );

  const updateContentExplorer = useCallback(() => {
    // console.log(`RENDERING CONTENT EXPLORER: ${folderId}`);
    if (!!contentExplorer?.hide) {
      contentExplorer.hide();
    }

    if (!!folderId && !contentExplorer) {
      contentExplorer = new window.Box.ContentExplorer();
    }

    if (!!folderId) {
      // contentExplorer.addListener("navigate", (data) =>
      //   console.log("navigate event fired", data)
      // );
      contentExplorer.show(folderId, boxAuth.token, {
        container: `#folder_${folderId}`,
        logoUrl: "box",
        responseInterceptor: responseInterceptor,
      });
    }
  }, [folderId, boxAuth.token, responseInterceptor]);

  // Should re-render content explorer when folderId changes or auth token changes
  useEffect(() => {
    if (!!boxAuth.token && !loadingBoxAuth) {
      updateContentExplorer();
    }
  }, [folderId, boxAuth.token, loadingBoxAuth, updateContentExplorer]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleResize = useCallback(
    () =>
      debounce(() => {
        setHeight(window.innerHeight - pageOffset);
      }, 300),
    []
  );

  useEffect(() => {
    if (!fixedHeight) {
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [handleResize, fixedHeight]);

  return (
    <div>
      {!folderId && (
        <div className={styles.noFolder}>
          <Empty description="No folder selected, please select a folder" />
        </div>
      )}

      {(loadingBoxAuth || !boxAuth.token) && (
        <div className={styles.loading}>
          <Spin />
        </div>
      )}
      {folderId && (
        <div id={`folder_${folderId}`} style={{ height: height }}></div>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BoxContentExplorer);
