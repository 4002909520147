import React from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import { formatNumber } from "../../utils/numbers";

import RecordLabels from "../common/RecordLabels";
import MappedIcon from "../common/MappedIcon";

const PermitPlatUnits = ({ units, loading }) => {
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      className: "App__w8",
      render: (text) => <Link to={`/units/${text}`}>{text}</Link>,
    },
    {
      title: "Labels",
      dataIndex: "label_ids",
      render: (_, record) => {
        return (
          <RecordLabels
            record_type="unit"
            record_id={record.id}
            label_ids={record.label_ids}
          />
        );
      },
    },
    {
      title: "Mapped",
      dataIndex: "geom_polygon",
      key: "geom_polygon",
      className: "App__w8",
      render: (value) => (value ? <MappedIcon /> : null),
    },
    {
      title: "Acres",
      dataIndex: "acres",
      key: "acres",
      render: (value) => (value !== null ? formatNumber(value, "0.00") : null),
    },
    {
      title: "Unique Owners",
      dataIndex: "unique_owner_count",
      key: "unique_owner_count",
      className: "App__w10",
    },
    {
      title: "Offers",
      dataIndex: "offer_count",
      key: "offer_count",
      className: "App__w10",
    },
    {
      title: "County",
      dataIndex: "county",
      key: "county",
      className: `App__w12`,
    },
    {
      title: "RRC ID",
      dataIndex: "rrc_id",
      key: "rrc_id",
      className: `App__w12`,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Operator",
      dataIndex: "operator",
      key: "operator",
    },
    {
      title: "Tax Year",
      dataIndex: "tax_year",
      key: "tax_year",
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={units}
      rowKey="id"
      showSorterTooltip={false}
      pagination={false}
      loading={loading}
      className="App__smallTables"
      bordered
    />
  );
};

export default PermitPlatUnits;
