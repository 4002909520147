import { capitalize } from "lodash";

const reviewStatuses = ["good", "bad"];

const reviewStatusesOptions = reviewStatuses.map((value) => ({
  value: value,
  label: capitalize(value),
}));

const defaultReviewStatus = "good";

export { reviewStatuses, reviewStatusesOptions, defaultReviewStatus };
