import React, { useState, useEffect } from "react";
import { apiBase } from "../../utils/apiBase";
import { get, isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { usStateOptions } from "../../common/usStates";

import {
  Modal,
  Form,
  Input,
  Button,
  message,
  Select,
  Checkbox,
  Tooltip,
  Alert,
} from "antd";

import { QuestionCircleOutlined } from "@ant-design/icons";

import styles from "./USPSSearchCreateForm.module.scss";

const USPSSearchCreateForm = (props) => {
  const { owner } = props;
  const idiPerson = get(owner, "last_idi_person");

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addressValidationResult, setAddressValidationResult] = useState(null);
  const [addressValidationErrors, setAddressValidationErrors] = useState([]);
  const [forceSubmit, setForceSubmit] = useState(false);
  const history = useNavigate();

  const validateAddress = async () => {
    setLoading(true);
    try {
      const values = form.getFieldsValue(true);
      const search_data = {
        street_address: values.street_address,
        city: values.city,
        state: values.state,
        zip: values.zip,
      };

      const res = await apiBase.post(`/usps-searches`, {
        ...search_data,
      });

      const statusCode = get(res, "data.response_status_code");
      const responseBody = get(res, "data.response_body");

      setAddressValidationResult(get(res, "data", {}));

      const uspsAddress = get(responseBody, "address");
      const additionalInfo = get(responseBody, "additionalInfo");

      const isVacant = get(additionalInfo, "vacant", null);
      const dpvConfirmation = get(additionalInfo, "DPVConfirmation", null);

      const zipCode = get(uspsAddress, "ZIPCode", "") || "";
      const zip4 = get(uspsAddress, "ZIPPlus4", "") || "";
      const deliveryPoint = get(additionalInfo, "deliveryPoint", "") || "";
      const zip11 = `${zipCode}${zip4}${deliveryPoint}`;

      let _addressValidationErrors = [];
      if (statusCode !== 200) {
        _addressValidationErrors.push(`Error: Status code "${statusCode}".`);
      }

      if (dpvConfirmation !== "Y" && dpvConfirmation !== null) {
        _addressValidationErrors.push(
          `Error: DPVConfirmation "${dpvConfirmation}".`
        );
      }

      if (isVacant !== "N" && isVacant !== null) {
        _addressValidationErrors.push(`Error: Vacant "${isVacant}".`);
      }

      if (zip11.length !== 11) {
        _addressValidationErrors.push(`Error: ZIP11 "${zip11}".`);
      }

      setAddressValidationErrors(_addressValidationErrors);

      if (_addressValidationErrors.length > 0) {
        message.warning("There are address validation errors.");
      } else {
        message.success("Successfully validated address.");
      }

      return _addressValidationErrors;
    } catch (err) {
      let msg = `${get(
        err,
        "response.status",
        "Unknown"
      )}: Failed to validate address`;
      console.log(err);
      message.error(msg);
    } finally {
      setLoading(false);
    }
  };

  const resetValidation = () => {
    setAddressValidationResult(null);
    setAddressValidationErrors([]);
    setForceSubmit(false);
  };

  const onFormChange = async (changedValues, allValues) => {
    if (
      forceSubmit ||
      addressValidationErrors.length > 0 ||
      !!addressValidationResult
    ) {
      resetValidation();
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    if (!forceSubmit) {
      const _addressValidationErrors = await validateAddress(values);
      if (_addressValidationErrors.length > 0 || !_addressValidationErrors) {
        setLoading(false);
        return;
      }
    }

    let searchId = null;
    let statusCode = null;

    apiBase
      .post(`usps-searches`, {
        ...values,
        ...(get(owner, "id", null) ? { owner_id: owner.id } : {}),
        force_submit: forceSubmit,
      })
      .then(async (res) => {
        searchId = get(res, "data.id", null);
        statusCode = get(res, "data.response_status_code", null);
        const error = get(res, "data.error", null);
        if (statusCode === 200) {
          message.success(`${statusCode}: Successfully created USPS search.`);
        } else if (isEmpty(error)) {
          message.warning(
            `${statusCode}: Successful search, no results found.`
          );
        } else {
          message.error(`${statusCode}: ${get(error, "message", "")}`);
        }
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to create USPS search.`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
        resetValidation();
        resetComponent();
        setIsModalOpen(false);
        if (searchId && statusCode === 200 && !get(owner, "id", null)) {
          history(`/usps-searches/${searchId}`);
        } else {
          if (typeof props.refetch === "function") {
            props.refetch();
          }
        }
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    resetComponent();
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    resetComponent();
    resetValidation();
  };

  const submitForm = () => {
    form
      .validateFields()
      .then(() => {
        form.submit();
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };

  const resetComponent = () => {
    form.resetFields();
  };

  const [form] = Form.useForm();

  useEffect(() => {
    if (owner) {
      const idiPerson = get(owner, "last_idi_person");
      form.setFieldsValue({
        street_address: idiPerson?.street || owner.address_formatted,
        city: idiPerson?.city || owner.city,
        state: idiPerson?.state || owner.state,
        zip: idiPerson?.zip_code || owner.zip_code,
      });
    }
  }, [owner, form]);

  return (
    <div>
      <Button type="secondary" onClick={showModal}>
        Search USPS
      </Button>
      <Modal
        title="Create USPS search"
        visible={isModalOpen}
        onOk={() => submitForm()}
        onCancel={handleCancel}
        okText="Search"
        cancelButtonProps={{ disabled: loading }}
        okButtonProps={{ disabled: loading, loading: loading }}
        maskClosable={false}
      >
        <div className={styles.compactForm}>
          <Form
            name="uspsSearchCreate"
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onValuesChange={onFormChange}
            autoComplete="off"
            layout="vertical"
            disabled={loading}
            initialValues={
              owner
                ? {
                    street_address:
                      idiPerson?.street || owner.address_formatted,
                    city: idiPerson?.city || owner.city,
                    state: idiPerson?.state || owner.state,
                    zip: idiPerson?.zip_code || owner.zip_code,
                  }
                : {}
            }
          >
            <Form.Item label="Street address" name="street_address">
              <Input onPressEnter={(e) => submitForm()} />
            </Form.Item>
            <Form.Item label="City" name="city">
              <Input onPressEnter={(e) => submitForm()} />
            </Form.Item>
            <div className={styles.twoColumns}>
              <Form.Item label="State" name="state">
                <Select
                  options={usStateOptions}
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
              </Form.Item>
              <Form.Item label="ZIP Code" name="zip">
                <Input onPressEnter={(e) => submitForm()} />
              </Form.Item>
            </div>
            {addressValidationErrors.length > 0 && (
              <Form.Item
                label={
                  <span>
                    Address validation errors&nbsp;
                    <Tooltip
                      overlayStyle={{ width: "800px" }}
                      overlayInnerStyle={{ width: "800px" }}
                      title={
                        <pre>
                          {JSON.stringify(addressValidationResult, null, 2)}
                        </pre>
                      }
                      placement="rightTop"
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
              >
                {addressValidationErrors.map((error) => (
                  <Alert
                    key={error}
                    message={error}
                    type="error"
                    style={{ marginBottom: "5px" }}
                  />
                ))}
                <Checkbox
                  checked={forceSubmit}
                  onChange={(e) => setForceSubmit(e.target.checked)}
                >
                  Force submit
                </Checkbox>
              </Form.Item>
            )}
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default USPSSearchCreateForm;
