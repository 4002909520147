import React, { Component } from "react";
import { MailOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Input, Button, Alert, message } from "antd";
import { apiBase } from "../utils/apiBase";

import styles from "./ForgotPassword.module.scss";

const explination =
  "Enter the email associated with you user. We will send you an email to reset your password. ";

class ForgotPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    // To disabled submit button at the beginning.
    this.props.form.validateFields();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    this.props.form.validateFields((err, values) => {
      if (err) {
        message.error(err);
        return;
      }
      let data = {
        user_email: values.user_email,
      };
      apiBase
        .patch("users/forgot-password", data)
        .then((res) => {
          let msg = `${res.status}: ${res.data.message}`;
          message.success(msg);
          this.setState({ isLoading: false });
          this.props.history("/login");
        })
        .catch((err) => {
          let msg = `${err.response.status}: ${err.response.data.message}`;
          message.error(msg);
          this.setState({ isLoading: false });
        });
    });
  };

  hasErrors = (fieldsError) => {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  };

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;
    return (
      <div className={styles.mainLogin}>
        <div className={styles.loginSvgImage}>
          <div className={styles.loginLogo} />
        </div>
        <Form
          layout="vertical"
          onSubmit={this.handleSubmit}
          className={styles.loginForm}
        >
          <Form.Item label="Email">
            {getFieldDecorator("user_email", {
              rules: [
                {
                  type: "email",
                  required: true,
                  message: "Please enter a valid email.",
                },
              ],
            })(
              <Input
                prefix={<MailOutlined />}
                placeholder="tom@mineralengine.com"
                size="large"
              />
            )}
          </Form.Item>
          <div>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.loginFormButton}
              size="large"
              disabled={this.hasErrors(getFieldsError())}
              loading={this.state.isLoading}
            >
              Submit
            </Button>
          </div>
        </Form>
        <div className={styles.alert}>
          <Alert message={explination} type="info" showIcon />
        </div>
      </div>
    );
  }
}

const ForgotPassword = Form.create({ name: "forgot_password" })(
  ForgotPasswordForm
);

export default ForgotPassword;
