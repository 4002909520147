import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as turf from "@turf/turf";

import { apiBase } from "../../utils/apiBase";
import BaseControls from "../maps/BaseControls";
import mapActions from "../../actions/map";

import * as assets from "../maps/layers/assets";
import * as tracts from "../maps/layers/tracts";

import { useLayerFilter } from "../maps/hooks/useLayerFilter";
import { useInitialization } from "../maps/hooks/useInitialization";
import { fitMapToBounds } from "../maps/common/utils";
import DrawCardControls from "../maps/controls/DrawCardControls";

import { Spin, message } from "antd";

import styles from "./TractMap.module.scss";

const TractMap = ({ tract, assets: _assets = [], getTract }) => {
  const mapRef = useRef(null);
  const mapContainerRef = useRef(null);
  const drawRef = useRef(null);
  const [mapLoaded, setMapLoaded] = useState(false);

  useInitialization({
    mapRef,
    mapContainerRef,
    drawRef,
    setMapLoaded,
  });

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const edit = useSelector((state) => state.map.controls.draw.edit);

  const setTractFilter = useLayerFilter(mapRef, mapLoaded, tracts.LAYER_ID, [
    tract,
  ]);

  const setAssetsFilter = useLayerFilter(
    mapRef,
    mapLoaded,
    assets.LAYER_ID,
    _assets
  );

  // After map is loaded reset map and zoom to tract
  useEffect(() => {
    if (!mapLoaded) return;
    dispatch(mapActions.resetMap(mapRef));

    if (!tract.geom_polygon) return;
    const features = turf.feature(tract.geom_polygon);
    fitMapToBounds(mapRef, features);

    setTractFilter(true);
    setAssetsFilter(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef, mapLoaded, tract.geom_polygon, _assets]);

  // Toggle edit
  useEffect(() => {
    const draw = drawRef.current;
    if (!mapLoaded || !draw || !tract.geom_polygon) return;

    if (edit) {
      setTractFilter(false);
      setAssetsFilter(false);
      const features = turf.feature(tract.geom_polygon);
      const existingFeatures = draw.getAll().features;
      if (existingFeatures.length === 0) {
        draw.add(features);
      }
    } else {
      draw.deleteAll();
      dispatch(mapActions.resetMap(mapRef));
      setTractFilter(true);
      setAssetsFilter(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapLoaded, edit, tract.geom_polygon, _assets]);

  const handleSave = () => {
    const draw = drawRef.current;
    if (!draw) return;

    const features = draw.getAll();
    setLoading(true);
    apiBase
      .patch(`/tracts/${tract.id}/map`, {
        geom_polygon: features,
      })
      .then((res) => {
        message.success("Tract map updated successfully!");
      })
      .catch((err) => {
        message.error("Error updating tract map");
      })
      .finally(() => {
        setLoading(false);
        mapRef.current
          .getSource(tracts.SOURCE_ID)
          .setTiles([tracts.TILE_ENDPOINT]);
        getTract();
      });
  };

  return (
    <div className={styles.main}>
      <div className={styles.card}>
        <div className={styles.cardTitle}>
          <div className={styles.leftTitle}>
            <h2>Map</h2>
          </div>
          <div className={styles.rightTitle}>
            <DrawCardControls handleSave={handleSave} loading={loading} />
          </div>
        </div>
        <div className={styles.cardBody}>
          <div className={styles.mapContainer}>
            <div
              id="map-container"
              className={styles.map}
              ref={mapContainerRef}
            />
            {mapLoaded ? (
              <BaseControls ref={mapRef} />
            ) : (
              <div className={styles.mapLoading}>
                <Spin />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TractMap;
