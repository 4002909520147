import { useEffect } from "react";

import mapboxgl from "mapbox-gl";

import {
  MAPBOX_ACCESS_TOKEN,
  MAPBOX_STYLE,
  MAX_ZOOM,
  INITIAL_ZOOM,
  INITIAL_CENTER,
} from "../constants";

// import { addAll, SOURCE_IDS } from "../layers";
import { addAll } from "../layers";

// import MapboxDraw from "@mapbox/mapbox-gl-draw";

import { addDraw } from "../controls/toolCustomization/DrawUtils";
import CustomControl from "../controls/toolCustomization/CustomControl";
import ClickLayerControl from "../controls/ClickLayerControl";
import DrawControls from "../controls/DrawControls";
import { useStore } from "react-redux";

export const useInitialization = ({
  mapRef,
  mapContainerRef,
  drawRef,
  initialZoom = INITIAL_ZOOM,
  initialCenter = INITIAL_CENTER,
  initialBounds,
  setMapLoaded,
}) => {
  const store = useStore();

  useEffect(() => {
    console.log("INITIALIZATION");
    setMapLoaded(false);

    mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: MAPBOX_STYLE,
      maxZoom: MAX_ZOOM,
      ...(initialBounds
        ? {
            bounds: initialBounds,
            fitBoundsOptions: { padding: 100, maxZoom: 14 },
          }
        : {
            center: initialCenter,
            zoom: initialZoom,
          }),
    });

    mapRef.current.addControl(
      new mapboxgl.ScaleControl({
        maxWidth: 80,
        // unit: "imperial",
      })
    );

    mapRef.current.addControl(new mapboxgl.FullscreenControl());

    mapRef.current.addControl(
      new CustomControl({
        component: <ClickLayerControl mapRef={mapRef} />,
        store,
      }),
      "top-right"
    );

    let drawControl = null;
    if (!!drawRef) {
      const draw = addDraw(mapRef);
      drawRef.current = draw;

      drawControl = new CustomControl({
        component: <DrawControls mapRef={mapRef} drawRef={drawRef} />,
        store,
        draw,
      });

      mapRef.current.addControl(drawControl, "top-right");
    }

    mapRef.current.on("load", () => {
      mapRef.current.resize();
      addAll(mapRef);

      setMapLoaded(true);

      // const checkSources = () => {
      //   const sources = mapRef.current.getStyle().sources;
      //   const allSourcesReady = SOURCE_IDS.every((id) => {
      //     const source = mapRef.current.getSource(id);
      //     return source && sources[id] && source.loaded();
      //   });

      //   if (allSourcesReady) {
      //     // Map is now loaded
      //     if (setMapLoaded) {
      //       setMapLoaded(true);
      //       // Other code....
      //     }
      //     mapRef.current.off("sourcedata", checkSources);
      //   }
      // };

      // mapRef.current.on("sourcedata", checkSources);
    });

    return () => {
      if (!mapRef.current) return;

      setMapLoaded(false);

      if (drawControl) {
        // drawRef.current.onRemove();
        drawRef.current = null;
        mapRef.current.removeControl(drawControl);
      }

      mapRef.current.remove();
      mapRef.current = null;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef, drawRef, mapContainerRef, setMapLoaded]);
};
