import React, { useState, useEffect, useCallback } from "react";
import { apiBase } from "../../utils/apiBase";
import { withParams } from "../../utils/withParams";
import { Helmet } from "react-helmet";
import { get, pick, isEmpty, omit } from "lodash";
import ReactJson from "react-json-view";
import { formatNumber } from "../../utils/numbers";

import AppConfigSelect from "../common/AppConfigSelect";

import AssetDeals from "./AssetDeals";
import AssetTracts from "./AssetTracts";
import AssetWells from "./AssetWells";
import AssetRoyalties from "./AssetRoyalties";
import AssetSales from "./AssetSales";
import AssetRoyaltyCreateForm from "./AssetRoyaltyCreateForm";
import AssetRoyaltyBulkCreateForm from "./AssetRoyaltyBulkCreateForm";
import AssetSaleCreateForm from "./AssetSaleCreateForm";
import AssetTractCreateForm from "./AssetTractCreateForm";
import TractAssetLinkForm from "../common/TractAssetLinkForm";
import EntitySelect from "../common/EntitySelect";
import AssetMap from "./AssetMap";
import AssetCharts from "./AssetCharts";
import MappedIcon from "../common/MappedIcon";
import Files from "../common/Files";

import {
  Row,
  Col,
  Button,
  Input,
  Checkbox,
  Tooltip,
  Form,
  Modal,
  message,
} from "antd";

import {
  ExclamationCircleFilled,
  DatabaseOutlined,
  LinkOutlined,
} from "@ant-design/icons";

import styles from "./Asset.module.scss";

const { confirm } = Modal;

const labelProps = {
  labelAlign: "left",
  labelCol: {
    xxl: { span: 7, offset: 0 },
    xl: { span: 9 },
    lg: { span: 10 },
  },
};

const formFields = [
  "entity_id",
  "name",
  "county",
  "state",
  "qbo_id",
  "tracking_url",
  "cost_basis",
  "decimal_interest",
  "acres",
  "nra",
  "sold",
];

const Asset = (props) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [asset, setAsset] = useState({});
  const [assetDeals, setAssetDeals] = useState([]);
  const [assetTracts, setAssetTracts] = useState([]);
  const [assetRoyalties, setAssetRoyalties] = useState([]);
  const [assetSales, setAssetSales] = useState([]);

  const getAsset = useCallback(async () => {
    try {
      setLoading(true);
      const res = await apiBase.get(`/assets/${props.params.id}`);
      setAsset(get(res, "data", {}));
    } catch (err) {
      let msg = `${err.response.status}: Failed to load asset ${props.params.id}`;
      message.error(msg);
    } finally {
      setLoading(false);
    }
  }, [props.params.id]);

  const getAssetDeals = useCallback(async () => {
    try {
      setLoading(true);
      const res = await apiBase.get(`/assets/${props.params.id}/deals`);
      setAssetDeals(get(res, "data", {}));
    } catch (err) {
      let msg = `${err.response.status}: Failed to load asset deals ${props.params.id}`;
      message.error(msg);
    } finally {
      setLoading(false);
    }
  }, [props.params.id]);

  const getAssetTracts = useCallback(async () => {
    try {
      setLoading(true);
      const res = await apiBase.get(`/assets/${props.params.id}/tracts`);
      setAssetTracts(get(res, "data", {}));
    } catch (err) {
      let msg = `${err.response.status}: Failed to load asset tracts ${props.params.id}`;
      message.error(msg);
    } finally {
      setLoading(false);
    }
  }, [props.params.id]);

  const getAssetRoyalties = useCallback(async () => {
    try {
      setLoading(true);
      const res = await apiBase.get(`/assets/${props.params.id}/royalties`);
      setAssetRoyalties(get(res, "data", []));
    } catch (err) {
      let msg = `${err.response.status}: Failed to load asset royalty revenue ${props.params.id}`;
      message.error(msg);
    } finally {
      setLoading(false);
    }
  }, [props.params.id]);

  const getAssetSales = useCallback(async () => {
    try {
      setLoading(true);
      const res = await apiBase.get(`/assets/${props.params.id}/sales`);
      setAssetSales(get(res, "data", []));
    } catch (err) {
      let msg = `${err.response.status}: Failed to load asset sales ${props.params.id}`;
      message.error(msg);
    } finally {
      setLoading(false);
    }
  }, [props.params.id]);

  useEffect(() => {
    getAsset();
    getAssetDeals();
    getAssetRoyalties();
    getAssetSales();
    getAssetTracts();
  }, [
    getAsset,
    getAssetDeals,
    getAssetRoyalties,
    getAssetSales,
    getAssetTracts,
  ]);

  useEffect(() => {
    if (!isEmpty(asset)) {
      form.setFieldsValue({
        ...pick(asset, formFields),
      });
    }
  }, [form, asset]);

  const updateSubmit = (values) => {
    setLoading(true);
    apiBase
      .patch(
        `assets/${asset.id}`,
        omit(values, ["cost_basis", "decimal_interest", "acres", "nra"])
      )
      .then((res) => {
        setAsset(res.data);
        let msg = `${res.status}: Successfully updated asset.`;
        message.success(msg);
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
        form.resetFields();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteAsset = async (assetId) => {
    setLoading(true);
    apiBase
      .delete(`/assets/${assetId}`)
      .then((res) => {
        setLoading(false);
        // window.open("/portfolio/assets?limit=50&page=1", "_self");
        props.history("/portfolio/assets?limit=50&page=1");
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to delete asset.`;
        message.error(msg);
        setLoading(false);
      });
  };

  const handleDelete = () => {
    confirm({
      title: "Are you sure you want to delete this asset?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      okButtonProps: { disabled: loading, loading: loading },
      cancelButtonProps: { disabled: loading, loading: loading },
      onOk: () => deleteAsset(asset.id),
    });
  };

  const onFinishFailed = () => {
    message.error("Form fields reqired");
  };

  return (
    <div className="App__rowContentDetail">
      <Helmet>
        <title>{`Asset: ${asset.name}`}</title>
      </Helmet>
      <Row>
        <Col span={12} xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
          <div className={styles.card}>
            <div className={styles.cardTitle}>
              <div className={styles.leftTitle}>
                <h2>Asset</h2>
                {asset.geom_polygon && <MappedIcon />}
              </div>
              <div className={styles.rightTitle}>
                <div>
                  <Button
                    onClick={() => handleDelete()}
                    className={styles.cardHeaderAction}
                    disabled={loading}
                    loading={loading}
                  >
                    Delete
                  </Button>
                  <Button
                    onClick={() => form.resetFields()}
                    className={styles.cardHeaderAction}
                    disabled={loading}
                  >
                    Reset
                  </Button>
                  <Button
                    className={styles.cardHeaderAction}
                    type="primary"
                    loading={loading}
                    htmlType="submit"
                    onClick={() => {
                      form.submit();
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
            <div className={styles.cardBody}>
              <Row>
                <Col span={24}>
                  {!isEmpty(asset) && (
                    <div className={styles.compactForm}>
                      <Form
                        name="basic"
                        layout="horizontal"
                        form={form}
                        initialValues={{
                          ...pick(asset, formFields),
                        }}
                        onFinish={updateSubmit}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        disabled={loading}
                      >
                        <Row gutter={8}>
                          <Col
                            span={12}
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                          >
                            <Form.Item
                              label="Entity"
                              name="entity_id"
                              rules={[{ required: true, message: "" }]}
                              {...labelProps}
                            >
                              <EntitySelect size="small" />
                            </Form.Item>
                            <Form.Item
                              label="Name"
                              name="name"
                              rules={[{ required: true, message: "" }]}
                              {...labelProps}
                            >
                              <Input
                                onPressEnter={() => form.submit()}
                                size="small"
                              />
                            </Form.Item>
                            <Form.Item
                              label="State"
                              name="state"
                              rules={[{ required: true, message: "" }]}
                              {...labelProps}
                            >
                              <AppConfigSelect
                                appConfigKey="states"
                                showSearch
                                allowClear
                                size="small"
                              />
                            </Form.Item>
                            <Form.Item
                              label="County"
                              name="county"
                              rules={[{ required: true, message: "" }]}
                              {...labelProps}
                            >
                              <AppConfigSelect
                                appConfigKey="counties"
                                showSearch
                                allowClear
                                size="small"
                              />
                            </Form.Item>
                            <Form.Item
                              label="Quickbooks Id"
                              name="qbo_id"
                              rules={[{ required: false, message: "" }]}
                              {...labelProps}
                            >
                              <Input
                                onPressEnter={() => form.submit()}
                                size="small"
                              />
                            </Form.Item>
                            <Form.Item label="Quickbooks class" {...labelProps}>
                              <Tooltip
                                overlayClassName="App__toolTipCard"
                                placement="bottom"
                                title={
                                  <ReactJson
                                    src={asset.qbo_data || {}}
                                    collapsed={true}
                                    style={{
                                      backgroundColor: "white",
                                      width: "484px",
                                      fontSize: "12px",
                                    }}
                                    displayDataTypes={false}
                                  />
                                }
                              >
                                <DatabaseOutlined />
                              </Tooltip>
                            </Form.Item>
                            <Form.Item
                              label="Tracking URL (old)"
                              {...labelProps}
                            >
                              <Input.Group compact>
                                <Form.Item name="tracking_url" noStyle>
                                  <Input
                                    style={{
                                      width: "calc(100% - 24px)",
                                    }}
                                    size="small"
                                  />
                                </Form.Item>
                                <Button
                                  size="small"
                                  icon={<LinkOutlined />}
                                  onClick={() => {
                                    if (asset.tracking_url) {
                                      window.open(asset.tracking_url, "_blank");
                                    }
                                  }}
                                />
                              </Input.Group>
                            </Form.Item>
                            <Form.Item
                              label="Sold"
                              name="sold"
                              valuePropName="checked"
                              rules={[{ required: false, message: "" }]}
                              {...labelProps}
                            >
                              <Checkbox size="small" />
                            </Form.Item>
                          </Col>
                          <Col
                            span={12}
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                          >
                            <div className={styles.assetMetricsContainer}>
                              <table className={styles.assetMetrics}>
                                <thead className={styles.header}>
                                  <tr>
                                    <th className={styles.w40}></th>
                                    <th className={styles.w30}>Original</th>
                                    <th className={styles.w30}>Current</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Cost basis</td>
                                    <td>{`$${formatNumber(
                                      asset.cost_basis,
                                      "0,0.00"
                                    )}`}</td>
                                    <td>{`$${formatNumber(
                                      asset.cost_basis_current,
                                      "0,0.00"
                                    )}`}</td>
                                  </tr>
                                  <tr>
                                    <td>Decimal interest</td>
                                    <td>
                                      {formatNumber(
                                        asset.decimal_interest,
                                        "0.00000000"
                                      )}
                                    </td>
                                    <td>
                                      {formatNumber(
                                        asset.decimal_interest_current,
                                        "0.00000000"
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Acres</td>
                                    <td>
                                      {formatNumber(asset.acres, "0.000000")}
                                    </td>
                                    <td>
                                      {/* {formatNumber(
                                        asset.acres_current,
                                        "0.000000"
                                      )} */}
                                      <span>-</span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>NRA</td>
                                    <td>
                                      {formatNumber(asset.nra, "0.000000")}
                                    </td>
                                    <td>
                                      {formatNumber(
                                        asset.nra_current,
                                        "0.000000"
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>$/NRA</td>
                                    <td>
                                      {`$${formatNumber(
                                        asset.cost_basis / asset.nra,
                                        "0,0.00"
                                      )}`}
                                    </td>
                                    <td>
                                      {/* {formatNumber(
                                        asset.nra_current,
                                        "0.000000"
                                      )} */}
                                      <span>-</span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className={styles.assetMetricsContainer}>
                              <table className={styles.assetMetrics}>
                                <thead>
                                  <tr>
                                    <th className={styles.w40}></th>
                                    <th className={styles.w60}></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Cost basis</td>
                                    <td>{`$${formatNumber(
                                      asset.cost_basis,
                                      "0,0.00"
                                    )}`}</td>
                                  </tr>
                                  <tr>
                                    <td>Royalty revenue</td>
                                    <td>{`$${formatNumber(
                                      asset.royalty_revenue,
                                      "0,0.00"
                                    )}`}</td>
                                  </tr>
                                  <tr>
                                    <td>Sale revenue</td>
                                    <td>{`$${formatNumber(
                                      asset.sale_revenue,
                                      "0,0.00"
                                    )}`}</td>
                                  </tr>
                                  <tr className={styles.returnRow}>
                                    <td className={styles.bold}>Return</td>
                                    <td>{`$${formatNumber(
                                      asset.total_return,
                                      "0,0.00"
                                    )}`}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.cardTitle}>
              <div className={styles.leftTitle}>
                <h2>Deals</h2>
              </div>
              <div className={styles.rightTitle}></div>
            </div>
            <div className={styles.cardBody}>
              <Row>
                <Col span={24}>
                  <AssetDeals
                    asset={asset}
                    getAsset={getAsset}
                    assetDeals={assetDeals}
                    getAssetDeals={getAssetDeals}
                    parentLoading={loading}
                  />
                </Col>
              </Row>
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.cardTitle}>
              <div className={styles.leftTitle}>
                <h2>Tracts</h2>
              </div>
              <div className={styles.rightTitle}>
                <TractAssetLinkForm
                  asset={asset}
                  getAssetTracts={getAssetTracts}
                />
                <AssetTractCreateForm
                  asset={asset}
                  getAsset={getAsset}
                  getAssetTracts={getAssetTracts}
                />
              </div>
            </div>
            <div className={styles.cardBody}>
              <Row>
                <Col span={24}>
                  <AssetTracts
                    asset={asset}
                    getAsset={getAsset}
                    assetTracts={assetTracts}
                    getAssetTracts={getAssetTracts}
                    parentLoading={loading}
                  />
                </Col>
              </Row>
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.cardTitle}>
              <div className={styles.leftTitle}>
                <h2>Royalty revenue</h2>
              </div>
              <div className={styles.rightTitle}>
                <div>
                  <AssetRoyaltyBulkCreateForm
                    asset={asset}
                    getAsset={getAsset}
                    getAssetRoyalties={getAssetRoyalties}
                  />
                </div>
                <div className={styles.cardHeaderAction}>
                  <AssetRoyaltyCreateForm
                    asset={asset}
                    getAsset={getAsset}
                    getAssetRoyalties={getAssetRoyalties}
                  />
                </div>
              </div>
            </div>
            <div className={styles.cardBody}>
              <Row>
                <Col span={24}>
                  <AssetRoyalties
                    assetRoyalties={assetRoyalties}
                    parentLoading={loading}
                  />
                </Col>
              </Row>
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.cardTitle}>
              <div className={styles.leftTitle}>
                <h2>Sales revenue</h2>
              </div>
              <div className={styles.rightTitle}>
                <div>
                  <AssetSaleCreateForm
                    asset={asset}
                    getAsset={getAsset}
                    getAssetSales={getAssetSales}
                  />
                </div>
              </div>
            </div>
            <div className={styles.cardBody}>
              <Row>
                <Col span={24}>
                  <AssetSales assetSales={assetSales} parentLoading={loading} />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col span={12} xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
          <Row>
            <Col span={24}>
              <AssetMap
                asset={asset}
                tracts={assetTracts.map((assetTract) => assetTract.tract)}
                getAsset={getAsset}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className={styles.card}>
                <div className={styles.cardTitle}>
                  <div className={styles.leftTitle}>
                    <h2>Wells</h2>
                  </div>
                  <div className={styles.rightTitle}></div>
                </div>
                <div className={styles.cardBody}>
                  <Row>
                    <Col span={24}>
                      <AssetWells asset={asset} getAsset={getAsset} />
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Files recordId={asset.id} recordType="asset" />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <AssetCharts assetId={asset.id} />
        </Col>
      </Row>
    </div>
  );
};

export default withParams(Asset);
