import React, { Component } from "react";
import { withParams } from "../utils/withParams";
import { Navigate } from "react-router-dom";
import { LoadingOutlined, LockOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Input, Button, Alert, message } from "antd";
import { apiBase } from "../utils/apiBase";
import jwt_decode from "jwt-decode";

import styles from "./UserSetup.module.scss";

const invalidExplination =
  "Please ask you system administrator to re-send the setup email.";

class Setup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isValidToken: false,
      confirmDirty: false,
      userId: null,
    };
  }

  componentDidMount() {
    try {
      const authTokenDecoded = jwt_decode(this.props.params.token);
      this.setState({
        userId: authTokenDecoded.user_id || null,
      });
    } finally {
      this.props.form.validateFields();
      this.validateToken();
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    this.props.form.validateFields((err, values) => {
      if (err || this.state.userId === null) {
        message.error(err);
        return;
      }
      let data = {
        password: values.password,
        user_id: this.state.userId,
        setup_token: this.props.params.token,
      };
      this.setState({ isLoading: true });
      apiBase
        .patch(`users/setup`, data)
        .then((res) => {
          const result = this.props.loginUser({
            email: res.data.email,
            password: values.password,
          });
          if (!result) {
            this.setState({ isLoading: false });
          }
        })
        .catch((err) => {
          let msg = `${err.response.status}: ${err.response.data.message}`;
          message.error(msg);
          this.setState({ isLoading: false });
        });
    });
  };

  validateToken = () => {
    apiBase
      .post("/users/check-setup-token", {
        setup_token: this.props.params.token,
      })
      .then(() => {
        this.setState({
          isValidToken: true,
          isLoading: false,
        });
      })
      .catch((err) => {
        message.error(`${err.response.status}: ${err.response.data.message}`);
        this.setState({ isLoading: false });
      });
  };

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && (this.state.confirmDirty || null)) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  hasErrors = (fieldsError) => {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  };

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;
    if (this.props.isAuthenticated === true) {
      return <Navigate to={`/units?limit=50&page=1`} />;
    } else if (this.state.isLoading === true) {
      return (
        <div className={styles.mainLogin}>
          <LoadingOutlined style={{ fontSize: "50px" }} />
        </div>
      );
    } else if (this.state.isValidToken === true) {
      return (
        <div className={styles.mainLogin}>
          <div className={styles.loginSvgImage}>
            <div className={styles.loginLogo} />
          </div>
          <Form
            layout="vertical"
            onSubmit={this.handleSubmit}
            className={styles.loginForm}
          >
            <Form.Item label="Password">
              {getFieldDecorator("password", {
                rules: [
                  { required: true, message: "Please enter a password." },
                  { validator: this.validateToNextPassword },
                ],
              })(
                <Input prefix={<LockOutlined />} type="password" size="large" />
              )}
            </Form.Item>
            <Form.Item label="Confirm Password">
              {getFieldDecorator("confirm", {
                rules: [
                  { required: true, message: "Please confirm your password." },
                  { validator: this.compareToFirstPassword },
                ],
              })(
                <Input
                  prefix={<LockOutlined />}
                  type="password"
                  onBlur={this.handleConfirmBlur}
                  size="large"
                />
              )}
            </Form.Item>
            <div>
              <Button
                type="primary"
                htmlType="submit"
                className={styles.loginFormButton}
                size="large"
                disabled={this.hasErrors(getFieldsError())}
                loading={this.state.isLoading}
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      );
    } else {
      return (
        <div className={styles.mainLogin}>
          <div className={styles.loginSvgImage}>
            <div className={styles.loginLogo} />
          </div>
          <div className={styles.alert}>
            <Alert
              message="Error"
              description={invalidExplination}
              type="error"
              showIcon
            />
          </div>
        </div>
      );
    }
  }
}

const UserSetup = Form.create({ name: "setup" })(Setup);

export default withParams(UserSetup);
