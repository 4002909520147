import React from "react";
import { Routes, Route } from "react-router-dom";

import PermitPlats from "../views/permitPlats/PermitPlats";
import PermitPlat from "../views/permitPlats/PermitPlat";
import Permits from "../views/permits/Permits";
import Permit from "../views/permits/Permit";
import Units from "../views/units/Units";
import Owners from "../views/owners/Owners";
import Interests from "../views/interests/Interests";
import OwnerNameAddresses from "../views/owners/OwnerNameAddresses";

import Offers from "../views/Offers";
import Leads from "../views/Leads";

import MailExports from "../views/MailExports";
import MailExportDetail from "../views/MailExportDetail";
import MailLetter from "../views/MailLetter";
import MailQueue from "../views/MailQueue";
import PipedriveUploads from "../views/pipedriveUploads/PipedriveUploads";
import PipedriveUploadDetail from "../views/pipedriveUploads/PipedriveUploadDetail";

import GenericFiles from "../views/GenericFiles";
import GenericFileDetail from "../views/GenericFileDetail";
import GenericFileRecords from "../views/GenericFileRecords";
import GenericFileRecordsDetail from "../views/GenericFileRecordsDetail";

import OwnerDetail from "../views/owners/OwnerDetail";
import UnitDetail from "../views/units/UnitDetail";
import OfferDetail from "../views/OfferDetail";
import LeadDetail from "../views/LeadDetail";

import Entities from "../views/entities/Entities";
import Entity from "../views/entities/Entity";
import Assets from "../views/assets/Assets";
import Asset from "../views/assets/Asset";
import Tracts from "../views/tracts/Tracts";
import Tract from "../views/tracts/Tract";
import Deals from "../views/deals/Deals";
import Deal from "../views/deals/Deal";
import Operators from "../views/operators/Operators";
import Operator from "../views/operators/Operator";
import Wells from "../views/wells/Wells";
import Well from "../views/wells/Well";
import Royalties from "../views/royalties/Royalties";
import Royalty from "../views/royalties/Royalty";
import Sales from "../views/sales/Sales";
import Sale from "../views/sales/Sale";

import PortfolioDashboard from "../views/reports/PortfolioDashboard";
import CRMDashboard from "../views/reports/CRMDashboard";

import Users from "../settings/Users";
import UserDetail from "../settings/UserDetail";

import PortfolioSettings from "../settings/PortfolioSettings";
import Labels from "../settings/Labels";
import CRMSettings from "../settings/CRMSettings";
import MailTemplates from "../settings/MailTemplates";
import MailTemplate from "../settings/MailTemplate";
import PipedriveSyncs from "../settings/PipedriveSyncs";
import EnverusSyncs from "../settings/EnverusSyncs";
import RRCSyncs from "../settings/RRCSyncs";
import Jobs from "../settings/Jobs";
import Phones from "../settings/Phones";

import IDISearches from "../views/idi/IDISearches";
import IDISearch from "../views/idi/IDISearch";
import IDIPersons from "../views/idi/IDIPersons";
import IDIPerson from "../views/idi/IDIPerson";
import IDIBulkSearches from "../views/IDIBulkSearches";
import IDIBulkSearchDetail from "../views/IDIBulkSearchDetail";

import EndatoSearches from "../views/endato/EndatoSearches";
import EndatoSearch from "../views/endato/EndatoSearch";
import EndatoPersons from "../views/endato/EndatoPersons";
import EndatoPerson from "../views/endato/EndatoPerson";

import USPSSearches from "../views/usps/USPSSearches";
import USPSSearch from "../views/usps/USPSSearch";
import USPSAddresses from "../views/usps/USPSAddresses";
import USPSAddress from "../views/usps/USPSAddress";
import USPSBulkSearches from "../views/USPSBulkSearches";
import USPSBulkSearchDetail from "../views/USPSBulkSearchDetail";

import SimpleMap from "../views/maps/SimpleMap";

const Router = (props) => {
  return (
    <Routes>
      <Route path="permit-plats" element={<PermitPlats {...props} />} />
      <Route path="permit-plats/:id" element={<PermitPlat {...props} />} />
      <Route path="permits" element={<Permits {...props} />} />
      <Route path="permits/:id" element={<Permit {...props} />} />
      <Route path="units" element={<Units {...props} />} />
      <Route path="units/:id" element={<UnitDetail {...props} />} />
      <Route path="owners" element={<Owners {...props} />} />
      <Route path="owners/:id" element={<OwnerDetail {...props} />} />
      <Route
        path="owner-name-addresses"
        element={<OwnerNameAddresses {...props} />}
      />
      <Route path="interests" element={<Interests {...props} />} />
      <Route path="offers" element={<Offers {...props} />} />
      <Route path="offers/:id" element={<OfferDetail {...props} />} />
      <Route path="generic-files" element={<GenericFiles {...props} />} />
      <Route
        path="generic-files/:id"
        element={<GenericFileDetail {...props} />}
      />
      <Route
        path="generic-file-records"
        element={<GenericFileRecords {...props} />}
      />
      <Route
        path="generic-file-records/:id"
        element={<GenericFileRecordsDetail {...props} />}
      />
      <Route
        path="pipedrive-uploads"
        element={<PipedriveUploads {...props} />}
      />
      <Route
        path="pipedrive-uploads/:id"
        element={<PipedriveUploadDetail {...props} />}
      />
      <Route path="mail-exports" element={<MailExports {...props} />} />
      <Route
        path="mail-exports/:id"
        element={<MailExportDetail {...props} />}
      />
      <Route path="mail-letters/:id" element={<MailLetter {...props} />} />
      <Route path="mail-queue" element={<MailQueue {...props} />} />
      <Route path="leads" element={<Leads {...props} />} />
      <Route path="leads/:id" element={<LeadDetail {...props} />} />
      <Route path="phones" element={<Phones {...props} />} />
      <Route path="idi-searches" element={<IDISearches {...props} />} />
      <Route path="idi-searches/:id" element={<IDISearch {...props} />} />
      <Route path="idi-persons" element={<IDIPersons {...props} />} />
      <Route path="idi-persons/:id" element={<IDIPerson {...props} />} />
      <Route
        path="idi-bulk-searches"
        element={<IDIBulkSearches {...props} />}
      />
      <Route
        path="idi-bulk-searches/:id"
        element={<IDIBulkSearchDetail {...props} />}
      />
      <Route path="endato-searches" element={<EndatoSearches {...props} />} />
      <Route path="endato-searches/:id" element={<EndatoSearch {...props} />} />
      <Route path="endato-persons" element={<EndatoPersons {...props} />} />
      <Route path="endato-persons/:id" element={<EndatoPerson {...props} />} />
      <Route path="usps-searches" element={<USPSSearches {...props} />} />
      <Route path="usps-searches/:id" element={<USPSSearch {...props} />} />
      <Route path="usps-addresses" element={<USPSAddresses {...props} />} />
      <Route path="usps-addresses/:id" element={<USPSAddress {...props} />} />
      <Route
        path="usps-bulk-searches"
        element={<USPSBulkSearches {...props} />}
      />
      <Route
        path="usps-bulk-searches/:id"
        element={<USPSBulkSearchDetail {...props} />}
      />
      <Route path="crm/reports/main" element={<CRMDashboard {...props} />} />
      <Route path="map" element={<SimpleMap {...props} />} />
      <Route path="portfolio/entities" element={<Entities {...props} />} />
      <Route path="portfolio/entities/:id" element={<Entity {...props} />} />
      <Route path="portfolio/assets" element={<Assets {...props} />} />
      <Route path="portfolio/assets/:id" element={<Asset {...props} />} />
      <Route path="portfolio/tracts" element={<Tracts {...props} />} />
      <Route path="portfolio/tracts/:id" element={<Tract {...props} />} />
      <Route path="portfolio/deals" element={<Deals {...props} />} />
      <Route path="portfolio/deals/:id" element={<Deal {...props} />} />
      <Route path="portfolio/operators" element={<Operators {...props} />} />
      <Route path="portfolio/operators/:id" element={<Operator {...props} />} />
      <Route path="portfolio/wells" element={<Wells {...props} />} />
      <Route path="portfolio/wells/:id" element={<Well {...props} />} />
      <Route path="portfolio/royalties" element={<Royalties {...props} />} />
      <Route path="portfolio/royalties/:id" element={<Royalty {...props} />} />
      <Route path="portfolio/sales" element={<Sales {...props} />} />
      <Route path="portfolio/sales/:id" element={<Sale {...props} />} />
      <Route
        path="portfolio/reports/main"
        element={<PortfolioDashboard {...props} />}
      />
      <Route path="settings/users" element={<Users {...props} />} />
      <Route path="settings/users/:id" element={<UserDetail {...props} />} />
      <Route path="settings/labels" element={<Labels {...props} />} />
      <Route path="settings/crm" element={<CRMSettings {...props} />} />
      <Route
        path="settings/portfolio"
        element={<PortfolioSettings {...props} />}
      />
      <Route
        path="settings/mail-templates"
        element={<MailTemplates {...props} />}
      />
      <Route
        path="settings/mail-templates/:id"
        element={<MailTemplate {...props} />}
      />
      <Route
        path="settings/pipedrive-syncs"
        element={<PipedriveSyncs {...props} />}
      />
      <Route
        path="settings/enverus-syncs"
        element={<EnverusSyncs {...props} />}
      />
      <Route path="settings/rrc-syncs" element={<RRCSyncs {...props} />} />
      <Route path="settings/jobs" element={<Jobs {...props} />} />
    </Routes>
  );
};
export default Router;
