import React, { useState, useEffect, useCallback } from "react";

import { apiBase } from "../../utils/apiBase";
import { withParams } from "../../utils/withParams";
import { withRouter } from "../../utils/withRouter";
import { columnTypes } from "../../utils/search";
import { formatDate } from "../../utils/dates";
import { alphaSort, humanFileSize } from "../../utils/numbers";
import FileUploadModal from "./FileUploadModal";
import RawDataModal from "./RawDataModal";
import { Row, Col, Table, Button, Modal, Badge, message } from "antd";
import {
  DeleteOutlined,
  ExclamationCircleFilled,
  EyeOutlined,
  EyeInvisibleOutlined,
  CloudDownloadOutlined,
} from "@ant-design/icons";

import styles from "./Files.module.scss";

const { confirm } = Modal;

const MODAL = {
  UPLOAD: "UPLOAD",
};

const DEFAULT_PAGINATION = {
  pageSize: 10,
  size: "small",
};

const Files = ({ recordId, recordType, pagination = DEFAULT_PAGINATION }) => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [modal, setModal] = useState(null);

  const getFiles = useCallback(async () => {
    if (!recordId || !recordType) return;

    try {
      setLoading(true);
      const res = await apiBase.get(
        `/files?record_id=${recordId}&record_type=${recordType}`
      );
      setFiles(res?.data || []);
    } catch (err) {
      let msg = `${err.response.status}: Failed to load files`;
      message.error(msg);
    } finally {
      setLoading(false);
    }
  }, [recordId, recordType]);

  useEffect(() => {
    getFiles();
  }, [getFiles]);

  const deleteFile = async (id) => {
    setLoading(true);
    apiBase
      .delete(`/files/${id}`)
      .then((res) => {
        let msg = `${res.status}: File successfully deleted.`;
        message.success(msg);
        getFiles();
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to delete file.`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const downloadFile = (file) => {
    const link = document.createElement("a");
    link.href = file.s3_url;
    link.setAttribute("download", file.file_metadata?.name || "");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: "Are you sure you want to delete this file?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      okButtonProps: { disabled: loading, loading: loading },
      cancelButtonProps: { disabled: loading, loading: loading },
      onOk: () => deleteFile(id),
    });
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      type: columnTypes.INTEGER,
    },
    {
      title: "Created at",
      dataIndex: "created_at",
      type: columnTypes.DATE_TIME,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => alphaSort(a.created_at, b.created_at),
      render: (value) => formatDate(value, "YYYY-MM-DD"),
    },
    {
      title: "Name",
      dataIndex: ["file_metadata", "name"],
      type: columnTypes.STRING,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => alphaSort(a.file_metadata?.name, b.file_metadata?.name),
    },
    {
      title: "Type",
      dataIndex: ["file_metadata", "type"],
      type: columnTypes.STRING,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => alphaSort(a.file_metadata?.type, b.file_metadata?.type),
    },
    {
      title: "Size",
      dataIndex: ["file_metadata", "size"],
      type: columnTypes.STRING,
      sortDirections: ["descend", "ascend"],
      render: (_, record) =>
        record.file_metadata?.size
          ? humanFileSize(record.file_metadata.size, true, 2)
          : null,
      sorter: (a, b) => alphaSort(a.file_metadata?.size, b.file_metadata?.size),
    },
    {
      title: "Metadata",
      dataIndex: "file_metadata",
      key: "file_metadata",
      className: `App__w10`,
      render: (_, record) => (
        <RawDataModal
          modalProps={{ title: "Metadata" }}
          data={record.file_metadata}
        />
      ),
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      className: styles.actionsColumn,
      render: (_, record) => {
        return (
          <div className="App__tableOperations">
            <Button
              shape="circle"
              icon={<DeleteOutlined />}
              size="small"
              onClick={() => showDeleteConfirm(record.id)}
              disabled={loading}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className={styles.card}>
      <div className={styles.cardTitle}>
        <div className={styles.leftTitle}>
          <Badge
            count={files.length}
            offset={[20, -2]}
            overflowCount={99}
            style={{
              backgroundColor: "#fff",
              color: "#999",
              boxShadow: "0 0 0 1px #d9d9d9 inset",
            }}
          >
            <h2 style={{ whiteSpace: "nowrap" }}>Files</h2>
          </Badge>
        </div>
        <div className={styles.rightTitle}>
          <Button
            className={styles.cardHeaderAction}
            type="secondary"
            loading={loading}
            htmlType="submit"
            onClick={() => setModal(MODAL.UPLOAD)}
          >
            Upload
          </Button>
        </div>
      </div>
      <div className={styles.cardBody}>
        <Row>
          <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
            <div>
              <Table
                pagination={pagination}
                columns={columns}
                dataSource={files.map((file, idx) => ({
                  ...file,
                  key: idx,
                }))}
                expandable={{
                  expandRowByClick: true,
                  expandedRowRender: (record) => {
                    if (record.file_metadata?.type === "application/pdf") {
                      return (
                        <div style={{ height: "70vh" }}>
                          <iframe
                            title="file"
                            width={"100%"}
                            height={"100%"}
                            src={`${record.s3_url}&embedded=true`}
                          ></iframe>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  },
                  expandIcon: ({ expanded, onExpand, record }) => {
                    if (record.file_metadata?.type === "application/pdf") {
                      return expanded ? (
                        <EyeInvisibleOutlined
                          onClick={(e) => {
                            e.preventDefault();
                            onExpand(record, e);
                          }}
                        />
                      ) : (
                        <EyeOutlined
                          onClick={(e) => {
                            e.preventDefault();
                            onExpand(record, e);
                          }}
                        />
                      );
                    } else {
                      return (
                        <CloudDownloadOutlined
                          onClick={(e) => {
                            e.preventDefault();
                            downloadFile(record);
                          }}
                        />
                      );
                    }
                  },
                  rowExpandable: (record) =>
                    record.file_metadata?.type === "application/pdf",
                }}
                showSorterTooltip={false}
                className="App__smallTables"
                size="small"
                loading={loading}
                bordered
              />
            </div>
          </Col>
        </Row>
      </div>
      {modal === MODAL.UPLOAD && (
        <FileUploadModal
          recordId={recordId}
          recordType={recordType}
          onSubmit={() => {
            getFiles();
            setModal(null);
          }}
          onCancel={() => setModal(null)}
        />
      )}
    </div>
  );
};

export default withRouter(withParams(Files));
