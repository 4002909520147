import Numeral from "numeral";
import Papa from "papaparse";

const formatNumber = (value, format) => {
  if (value === null || value === undefined) {
    return undefined;
  } else {
    const _value = Numeral(value).format(format);
    // Annoying bug
    if (_value === "NaN") {
      return value.toFixed(9);
    } else {
      return _value;
    }
  }
};

function calculatePercentageChange(previousValue, currentValue) {
  if (previousValue === 0) {
    return currentValue === 0 ? 0 : null;
  }

  const change =
    ((currentValue - previousValue) / Math.abs(previousValue)) * 100;

  return change;
}

const alphaSort = (a, b) => {
  let a_new, b_new;
  a_new = a !== null && a !== undefined ? String(a) : "";
  b_new = b !== null && b !== undefined ? String(b) : "";
  return a_new.localeCompare(b_new);
};

const dateSort = (a, b) => {
  // let a_new, b_new;
  // a_new = (a !== null && a !== undefined) ? String(a) : '';
  // b_new = (b !== null && b !== undefined) ? String(b) : '';
  return (a, b) => new Date(b) - new Date(a);
};

const formatCSVData = (dataArray) => {
  let cleanArray;
  if (dataArray === []) {
    return [];
  }
  cleanArray = dataArray.map((row) => {
    let cleanRow = {};
    Object.keys(row).forEach((key) => {
      if (row[key] !== null && typeof row[key] === "object") {
        cleanRow[key] = {};
        Object.keys(row[key]).forEach((keySub) => {
          if (row[key][keySub] !== null && row[key][keySub] !== undefined) {
            cleanRow[key][keySub] = String(row[key][keySub]).replace(/"/g, "'");
          } else {
            cleanRow[key][keySub] = row[key][keySub];
          }
        });
      } else {
        if (row[key] !== null && row[key] !== undefined) {
          cleanRow[key] = String(row[key]).replace(/"/g, "'");
        } else {
          cleanRow[key] = row[key];
        }
      }
    });
    return cleanRow;
  });
  return cleanArray;
};

const downloadCSV = (args) => {
  let filename = args.filename || "export.csv";
  let columns = args.columns || null;

  let csv = Papa.unparse({ data: args.data, fields: columns });
  if (csv == null) return;

  var blob = new Blob([csv]);
  if (window.navigator.msSaveOrOpenBlob)
    // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
    window.navigator.msSaveBlob(blob, args.filename);
  else {
    var a = window.document.createElement("a");
    a.href = window.URL.createObjectURL(blob, { type: "text/plain" });
    a.download = filename;
    document.body.appendChild(a);
    a.click(); // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
    document.body.removeChild(a);
  }
};

const flattenObject = (obj, prefix = "") => {
  return Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? prefix + "." : "";
    if (
      typeof obj[k] === "object" &&
      obj[k] !== null &&
      !Array.isArray(obj[k])
    ) {
      Object.assign(acc, flattenObject(obj[k], pre + k));
    } else {
      acc[pre + k] = obj[k];
    }
    return acc;
  }, {});
};

// const flattenObject = (obj, parentKey = null) => {
//   const flattened = {};
//   Object.keys(obj).forEach((key) => {
//     if (typeof obj[key] === "object" && obj[key] !== null) {
//       Object.assign(flattened, flattenObject(obj[key], key));
//     } else {
//       if (parentKey !== null) {
//         flattened[`${parentKey}.${key}`] = obj[key];
//       } else {
//         flattened[key] = obj[key];
//       }
//     }
//   });

//   return flattened;
// };

function median(numbers) {
  const sorted = numbers.slice().sort((a, b) => a - b);
  const middle = Math.floor(sorted.length / 2);

  if (sorted.length % 2 === 0) {
    return Math.floor((sorted[middle - 1] + sorted[middle]) / 2);
  }

  return sorted[middle];
}

function guid() {
  return "xxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function humanFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
}

export {
  guid,
  formatNumber,
  alphaSort,
  dateSort,
  formatCSVData,
  downloadCSV,
  flattenObject,
  median,
  humanFileSize,
  calculatePercentageChange,
};
