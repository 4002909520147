import { capitalize } from "lodash";

const uploadStatus = {
  queued: "queued",
  running: "running",
  completed: "completed",
  warning: "warning",
  failed: "failed",
};

const uploadStatusOptions = Object.keys(uploadStatus).map((key) => ({
  value: key,
  label: capitalize(key),
}));

export { uploadStatus, uploadStatusOptions };
