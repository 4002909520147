import React, { useState, useEffect } from "react";

import { get } from "lodash";
import { apiBase } from "../../utils/apiBase";
import { alphaSort } from "../../utils/numbers";
import { formatDate } from "../../utils/dates";
import { DeceasedIcon } from "../common/LabelIcon";

import { Form, Table, Button, Modal, Input, Space, message } from "antd";
import {
  ExclamationCircleFilled,
  DeleteOutlined,
  PlusOutlined,
  CheckCircleTwoTone,
} from "@ant-design/icons";

import styles from "./OwnerIDIPersons.module.scss";

const { confirm } = Modal;

const OwnerIDIPersons = (props) => {
  const { idiPersons, recordsPerPage, isLoadingIDIPersons, ownerId } = props;

  const [form] = Form.useForm();
  const [data, setData] = useState(idiPersons);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIDIPersonKeys, setSelectedIDIPersonKeys] = useState([]);
  const [selectedIDIPersons, setSelectedIDIPersons] = useState([]);
  const [idiPersonId, setIdiPersonId] = useState(null);

  useEffect(() => setData(idiPersons), [idiPersons]);

  const handleRowClick = (e, _to) => {
    e.stopPropagation();
    if (e.ctrlKey || e.metaKey) {
      window.open(_to, "_blank");
      return;
    }
    props.history(_to);
  };

  const onSelectIDIPersonChange = (selectedRowKeys, selectedRows) => {
    setSelectedIDIPersonKeys(selectedRowKeys);
    setSelectedIDIPersons(selectedRows);
  };

  const showDeleteConfirm = () => {
    confirm({
      title: "Are you sure you want to delete these IDI person links?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      okButtonProps: { disabled: isLoading, loading: isLoading },
      cancelButtonProps: { disabled: isLoading, loading: isLoading },
      onOk: () => deleteIDIPersons(),
    });
  };

  const deleteIDIPersons = () => {
    setIsLoading(true);
    apiBase
      .post(`/idi-person-owners/delete`, {
        idi_person_owners: selectedIDIPersons.map((person) => ({
          owner_id: ownerId,
          idi_person_id: person.id,
        })),
      })
      .then((res) => {
        setSelectedIDIPersonKeys([]);
        setSelectedIDIPersons([]);
        let msg = `${res.status}: ${res.data.message}`;
        message.success(msg);
      })
      .catch((err) => {
        let msg = get(err, "response.data.message")
          ? `${get(err, "response.status")}: ${get(
              err,
              "response.data.message"
            )}`
          : `${get(
              err,
              "response.status"
            )}: Failed to delete IDI person links.`;
        message.error(msg);
      })
      .finally(async () => {
        await props.getIDIPersons();
        setIsLoading(false);
      });
  };

  const addIDIPerson = () => {
    setIsLoading(true);
    apiBase
      .post(`/idi-person-owners`, {
        owner_id: ownerId,
        idi_person_id: idiPersonId,
      })
      .then((res) => {
        setData([...data, res.data]);
        props.getIDIPersons();
        message.success("IDI person linked successfully");
      })
      .catch((err) => {
        message.error(
          `Failed to link IDI person: ${err.response.data.message}`
        );
      })
      .finally(() => {
        setIdiPersonId(null);
        setIsLoading(false);
      });
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      className: `App__rowLink App__w6`,
      render: (value) => <div className="App__rowIcon">{value}</div>,
      onCell: (record) => ({
        onClick: (e) => handleRowClick(e, `/idi-persons/${record["id"]}`),
      }),
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "IDI search",
      dataIndex: "idi_search_id",
      className: `App__rowLink App__w10`,
      render: (value) => <div className="App__rowIcon">{value}</div>,
      onCell: (record) => ({
        onClick: (e) =>
          handleRowClick(e, `/idi-searches/${record["idi_search_id"]}`),
      }),
      sorter: (a, b) => a.idi_search_id - b.idi_search_id,
    },
    {
      title: "PID",
      dataIndex: "pid",
      className: `App__w14`,
      sorter: (a, b) => alphaSort(a.pid, b.pid),
    },
    {
      title: "Main",
      dataIndex: "main",
      className: `App__w6`,
      sorter: (a, b) => alphaSort(a.main, b.main),
      render: (value) =>
        value ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : null,
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => alphaSort(a.name, b.name),
    },
    {
      title: "First name",
      dataIndex: "first_name",
      sorter: (a, b) => alphaSort(a.first_name, b.first_name),
    },
    {
      title: "Last name",
      dataIndex: "last_name",
      sorter: (a, b) => alphaSort(a.last_name, b.last_name),
    },
    {
      title: "Street",
      dataIndex: "street",
      sorter: (a, b) => alphaSort(a.street, b.street),
    },
    {
      title: "City",
      dataIndex: "city",
      sorter: (a, b) => alphaSort(a.city, b.city),
    },
    {
      title: "State",
      dataIndex: "state",
      sorter: (a, b) => alphaSort(a.state, b.state),
    },
    {
      title: "Zip",
      dataIndex: "zip_code",
      sorter: (a, b) => alphaSort(a.zip_code, b.zip_code),
    },
    {
      title: "Deceased",
      dataIndex: "deceased",
      sorter: (a, b) => get(a, "deceased", false) - get(b, "deceased", false),
      render: (value) => <DeceasedIcon value={value} />,
    },
    {
      title: "Created at",
      dataIndex: "created_at",
      sorter: (a, b) => alphaSort(a.created_at, b.created_at),
      render: (value) => formatDate(value, "YYYY-MM-DD, h:mm:ss A"),
    },
    {
      title: "Updated at",
      dataIndex: "updated_at",
      sorter: (a, b) => alphaSort(a.updated_at, b.updated_at),
      render: (value) => formatDate(value, "YYYY-MM-DD, h:mm:ss A"),
    },
  ];

  const rowSelection = {
    selectedRowKeys: selectedIDIPersonKeys,
    onChange: onSelectIDIPersonChange,
  };

  return (
    <>
      <Form form={form} component={false}>
        <div className={styles.headerActions}>
          <div>
            {selectedIDIPersonKeys.length > 0 && (
              <Button
                onClick={showDeleteConfirm}
                icon={<DeleteOutlined />}
                type="secondary"
              >
                Delete link
              </Button>
            )}
          </div>
          <div>
            <Space.Compact>
              <Input
                placeholder="IDI person id or pid"
                value={idiPersonId}
                onChange={(e) => setIdiPersonId(e.target.value)}
              />
              <Button
                type="secondary"
                icon={<PlusOutlined />}
                onClick={addIDIPerson}
              />
            </Space.Compact>
          </div>
        </div>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          pagination={{
            pageSize: recordsPerPage,
            showSizeChanger: false,
          }}
          showSorterTooltip={false}
          className="App__smallTables"
          loading={isLoadingIDIPersons || isLoading}
          scroll={{ x: "100%" }}
          bordered
        />
      </Form>
    </>
  );
};

export default OwnerIDIPersons;
