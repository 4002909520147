import React, { useState, useEffect, useCallback } from "react";

import { apiBase } from "../../utils/apiBase";
import { withParams } from "../../utils/withParams";
import { Helmet } from "react-helmet";
import { get, isEmpty } from "lodash";
import { clsx } from "clsx";
import ReactJson from "react-json-view";

import { message } from "antd";

import styles from "./USPSSearch.module.scss";

const USPSSearch = (props) => {
  const [loading, setLoading] = useState(false);
  const [uspsSearch, setUSPSSearch] = useState({});

  const getUSPSSearch = useCallback(async () => {
    try {
      setLoading(true);
      const res = await apiBase.get(`/usps-searches/${props.params.id}`);
      setUSPSSearch(get(res, "data", {}));
    } catch (err) {
      let msg = `${err.response.status}: Failed to load IDI search ${props.params.id}`;
      message.error(msg);
    } finally {
      setLoading(false);
    }
  }, [props.params.id]);

  useEffect(() => {
    getUSPSSearch();
  }, [getUSPSSearch]);

  const results = get(uspsSearch, "response_body", {});
  const error = get(uspsSearch, "error");

  return (
    <div className="App__rowContent">
      <Helmet>
        <title>{`USPSSearch: ${uspsSearch.id}`}</title>
      </Helmet>
      <div
        className={clsx({
          [styles.loading]: loading,
        })}
      >
        <div className={styles.jsonView}>
          {isEmpty(error) ? (
            <ReactJson
              src={results}
              style={{
                fontSize: "12px",
              }}
            />
          ) : (
            <ReactJson
              src={error}
              style={{
                fontSize: "12px",
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default withParams(USPSSearch);
