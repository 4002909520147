import React, { Component } from "react";
import { get } from "lodash";
import { formatNumber } from "../utils/numbers";
import { formatDate } from "../utils/dates";
import { columnTypes } from "../utils/search";
import { apiBase } from "../utils/apiBase";
import { capitalize } from "lodash";

import BaseSearch from "./search/BaseSearch";
import Took from "./search/Took";
import TotalRecords from "./search/TotalRecords";
import { statusOptions } from "../common/genericFiles";

import FilterTags from "./search/FilterTags";
import ExportForm from "./forms/ExportForm";
import ChangeColumnsForm from "./forms/ChangeColumnsForm";
import UploadGenericFileForm from "./forms/UploadGenericFileForm";

import ReactJson from "react-json-view";
import {
  DownloadOutlined,
  TableOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
  DatabaseOutlined,
} from "@ant-design/icons";

import {
  Row,
  Col,
  Table,
  Select,
  Button,
  Tooltip,
  Pagination,
  Modal,
  message,
} from "antd";

import styles from "./GenericFiles.module.scss";

const { confirm } = Modal;

// const downloadFile = ({ dataIndex }) => {
//   return {
//     dataIndex,
//     className: "App__rowLink",
//     title: () => <div />,
//     render: (_, record) => {
//       if (record["status"] === "completed" && record[dataIndex]) {
//         return (
//           <div className="App__rowIcon">
//             <DownloadOutlined />
//           </div>
//         );
//       }
//     },
//     onCell: (record) => ({
//       onClick: () => {
//         if (record[dataIndex]) {
//           window.open(record[dataIndex], "_blank");
//         }
//       },
//     }),
//   };
// };

const baseColumnState = [
  {
    dataIndex: "id",
    title: "Id",
    editable: false,
    type: columnTypes.LINK,
    baseUrl: "/generic-files",
  },
  {
    title: "Name",
    dataIndex: "name",
    type: columnTypes.STRING,
    first: true,
  },
  {
    title: "File name",
    dataIndex: "file_name",
    type: columnTypes.STRING,
    first: true,
  },
  {
    dataIndex: "upload_status",
    title: "Status",
    type: columnTypes.SELECT,
    options: statusOptions,
    format: ({ value }) => capitalize(value),
  },
  {
    key: "file_row_count",
    dataIndex: "file_metadata",
    title: "Row count",
    className: `App__w12`,
    children: [
      {
        key: "file_row_count",
        dataIndex: "file_metadata",
        title: () => <div />,
        render: (value) => formatNumber(value?.data?.details?.shape?.rows),
      },
    ],
  },
  {
    key: "file_column_count",
    dataIndex: "file_metadata",
    title: "Column count",
    className: `App__w12`,
    children: [
      {
        key: "file_column_count",
        dataIndex: "file_metadata",
        title: () => <div />,
        render: (value) => formatNumber(value?.data?.details?.shape?.columns),
      },
    ],
  },
  {
    key: "file_metadata",
    dataIndex: "file_metadata",
    title: "File metadata",
    className: `App__w10`,
    children: [
      {
        dataIndex: "file_metadata",
        title: () => <div />,
        render: (value) => (
          <Tooltip
            overlayClassName={styles.toolTipCard}
            placement="bottom"
            title={
              <ReactJson
                src={value}
                collapsed={true}
                style={{
                  backgroundColor: "white",
                  width: "484px",
                  fontSize: "12px",
                }}
                displayDataTypes={false}
              />
            }
          >
            <DatabaseOutlined />
          </Tooltip>
        ),
      },
    ],
  },
  {
    key: "column_mapping",
    dataIndex: "column_mapping",
    title: "Column mapping",
    className: `App__w12`,
    children: [
      {
        dataIndex: "column_mapping",
        title: () => <div />,
        render: (value) => (
          <Tooltip
            overlayClassName={styles.toolTipCard}
            placement="bottom"
            title={
              <ReactJson
                src={value}
                collapsed={true}
                style={{
                  backgroundColor: "white",
                  width: "484px",
                  fontSize: "12px",
                }}
                displayDataTypes={false}
              />
            }
          >
            <DatabaseOutlined />
          </Tooltip>
        ),
      },
    ],
  },
  {
    title: "Created By",
    dataIndex: ["user", "alias"],
    type: columnTypes.STRING,
  },
  {
    title: "Created at",
    dataIndex: "created_at",
    type: columnTypes.DATE_TIME,
    format: (value) => formatDate(value, "YYYY-MM-DD, h:mm:ss A"),
  },
];

class GenericFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  showDeleteConfirm = (genericFileId) => {
    const { isLoading } = this.state;
    confirm({
      title: "Are you sure you want to delete this generic file upload?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      okButtonProps: { disabled: isLoading, loading: isLoading },
      cancelButtonProps: { disabled: isLoading, loading: isLoading },
      onOk: () => this.deleteGenericFile(genericFileId),
    });
  };

  deleteGenericFile = (genericFileId) => {
    const { executeSearch } = this.props;
    this.setState({ isLoading: true });
    apiBase
      .delete(`/generic-files/${genericFileId}`)
      .then((res) => {
        this.setState({ isLoading: false });
        executeSearch();
        let msg = `${get(res, "status")}: ${get(res, "data.message")}`;
        message.success(msg);
      })
      .catch((err) => {
        console.log(err);
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const {
      isLoadingRecords,
      isLoadingCount,
      records,
      columnState,
      limit,
      page,
      totalRecords,
      tookRecords,
      tookCount,
      searchFields,
      isExportModalVisible,
      isChangeColumnsVisible,
      isLoadingExport,
    } = this.props;

    const {
      handleSearch,
      handleRecordsChange,
      handlePaginationChange,
      handleFilterClose,
      handleTableChange,
      handleSubmitExport,
      showExportModal,
      handleCancelExport,
      handleSubmitColumnChange,
      showColumnChangeModal,
      handleCancelColumnsChange,
      exportFormRef,
      changeColumnFormRef,
      getColumnProps,
      executeSearch,
    } = this.props;

    const { isLoading } = this.state;

    let columns = columnState
      .map((column, idx) => ({
        key: idx,
        ...column,
        ...getColumnProps(column),
      }))
      .filter((col) => col.visible === true);

    columns.push({
      title: "",
      dataIndex: "actions",
      visible: true,
      editable: false,
      children: [
        {
          dataIndex: "actions",
          render: (_, record) => {
            return (
              <div className="App__tableOperations">
                <Button
                  shape="circle"
                  icon={<DeleteOutlined />}
                  size="small"
                  onClick={() => this.showDeleteConfirm(record.id)}
                  loading={isLoading}
                  disabled={isLoading}
                />
              </div>
            );
          },
        },
      ],
    });

    return (
      <div>
        <Row className="App__rowContent">
          <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16}>
            <Button className="App__mr-5" onClick={() => handleSearch()}>
              Search
            </Button>
            <FilterTags
              searchFields={searchFields}
              columnState={columnState}
              handleFilterClose={handleFilterClose}
            />
          </Col>
          <Col
            span={8}
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            className="App__rightActions"
          >
            <div className={styles.uploadButton}>
              <UploadGenericFileForm executeSearch={executeSearch} />
            </div>
            <Tooltip placement="bottomRight" title={"Export search results"}>
              <Button className="App__iconButton" onClick={showExportModal}>
                <DownloadOutlined />
              </Button>
            </Tooltip>
            <ExportForm
              wrappedComponentRef={exportFormRef}
              visible={isExportModalVisible}
              isLoading={isLoadingExport}
              onCancel={handleCancelExport}
              onCreate={handleSubmitExport}
              totalRecords={totalRecords}
            />
            <Tooltip placement="bottomRight" title={"Change visible columns"}>
              <Button
                onClick={showColumnChangeModal}
                className="App__iconButton"
              >
                <TableOutlined />
              </Button>
            </Tooltip>
            <ChangeColumnsForm
              wrappedComponentRef={changeColumnFormRef}
              visible={isChangeColumnsVisible}
              isLoading={isLoadingRecords}
              onCancel={handleCancelColumnsChange}
              onOk={handleSubmitColumnChange}
              columnState={columnState}
            />
            <Select
              value={limit}
              onChange={handleRecordsChange}
              className={"App__mr-5"}
            >
              <Select.Option value={25}>25</Select.Option>
              <Select.Option value={50}>50</Select.Option>
              <Select.Option value={100}>100</Select.Option>
              <Select.Option value={1000}>1000</Select.Option>
            </Select>
            <TotalRecords
              title={"Generic files"}
              isLoadingCount={isLoadingCount}
              totalRecords={totalRecords}
            />
          </Col>
        </Row>
        <Row className="App__rowContent">
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={records}
              onChange={handleTableChange}
              pagination={false}
              showSorterTooltip={false}
              className="App__smallTables"
              loading={isLoadingRecords || this.state.isLoading}
              scroll={{ x: "100%" }}
              size="small"
              bordered
            />
            <div className="App__searchFooterContainer">
              <Took
                isLoadingRecords={isLoadingRecords}
                isLoadingCount={isLoadingCount}
                tookRecords={tookRecords}
                tookCount={tookCount}
              />
              <Pagination
                current={page}
                pageSize={limit}
                total={totalRecords}
                onChange={handlePaginationChange}
                className="App__pagination"
                showSizeChanger={false}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const GenericFilesWithSearch = (props) => (
  <BaseSearch
    baseColumnState={baseColumnState}
    localSortColumns={[]}
    searchUri="generic-files"
  >
    <GenericFiles {...props} />
  </BaseSearch>
);

export default GenericFilesWithSearch;
