import * as wells from "../views/maps/layers/wells";
import * as landtracUnits from "../views/maps/layers/landtracUnits";
import * as units from "../views/maps/layers/units";
import * as assets from "../views/maps/layers/assets";
import * as permitPlats from "../views/maps/layers/permitPlats";
import * as tracts from "../views/maps/layers/tracts";

import * as layers from "../views/maps/layers";
import { syncDrawState } from "../views/maps/controls/toolCustomization/DrawUtils";

export const types = {
  MAP_RESET: "MAP_RESET",
  // Controls
  MAP_CONTROLS_SET_CLICK_LAYER: "MAP_CONTROLS_SET_CLICK_LAYER",
  MAP_CONTROLS_SET_DRAW: "MAP_CONTROLS_SET_DRAW",
  // Layers
  MAP_SET_ZOOM_TO_FILTER: "MAP_SET_ZOOM_TO_FILTER",
  MAP_LAYER_RESET_FILTERS: "MAP_LAYER_RESET_FILTERS",
  MAP_LAYER_SET_VISIBILITY: "MAP_LAYER_SET_VISIBILITY",
  MAP_LAYER_SET_FILTERS_VISIBILITY: "MAP_LAYER_SET_FILTERS_VISIBILITY",
  MAP_LAYER_SET_FILTER_COLUMN_OPEN: "MAP_LAYER_SET_FILTER_COLUMN_OPEN",
  MAP_LAYER_SET_FILTER_COLUMN_VALUE: "MAP_LAYER_SET_FILTER_COLUMN_VALUE",
  // Styles
  MAP_LAYER_SET_ANNOTATION_VISIBILITY: "MAP_LAYER_SET_ANNOTATION_VISIBILITY",
};

export const resetMap = (mapRef) => ({
  type: types.MAP_RESET,
  mapRef,
});

export const setClickLayer = (value) => ({
  type: types.MAP_CONTROLS_SET_CLICK_LAYER,
  value,
});

export const setDraw = (drawRef, drawOptions) => ({
  type: types.MAP_CONTROLS_SET_DRAW,
  drawRef,
  value: drawOptions,
});

export const setZoomToFilter = (value) => ({
  type: types.MAP_SET_ZOOM_TO_FILTER,
  value,
});

export const setLayerFiltersVisibility = (layerId, value) => ({
  type: types.MAP_LAYER_SET_FILTERS_VISIBILITY,
  layerId,
  value,
});

export const setLayerFilterColumnOpen = (layerId, column, value) => ({
  type: types.MAP_LAYER_SET_FILTER_COLUMN_OPEN,
  layerId,
  column,
  value,
});

export const setLayerAnnotationVisibility = (mapRef, layerId, value) => ({
  type: types.MAP_LAYER_SET_ANNOTATION_VISIBILITY,
  mapRef,
  layerId,
  value,
});

export const setLayerVisibility = (mapRef, layerId, value) => ({
  type: types.MAP_LAYER_SET_VISIBILITY,
  mapRef,
  layerId,
  value,
});

export const setLayerFilterColumnValue = (mapRef, layerId, column, value) => ({
  type: types.MAP_LAYER_SET_FILTER_COLUMN_VALUE,
  mapRef,
  layerId,
  column,
  value,
});

export const resetLayerFilters = (mapRef, layerId) => ({
  type: types.MAP_LAYER_RESET_FILTERS,
  mapRef,
  layerId,
});

export const initialState = {
  loading: false,
  zoomToFilter: true,
  controls: {
    clickLayer: true,
    draw: {
      edit: false,
      mode: null,
      snap: true,
      savedGeometry: null,
    },
  },
  styles: {
    [wells.LAYER_ID]: {
      annotationVisible: true,
    },
  },
  layers: {
    [wells.LAYER_ID]: {
      layerVisible: true,
      filtersVisible: false,
      filterColumnOpen: null,
      filters: wells.INITIAL_FILTER_STATE,
    },
    [landtracUnits.LAYER_ID]: {
      layerVisible: false,
      filtersVisible: false,
      filterColumnOpen: null,
      filters: landtracUnits.INITIAL_FILTER_STATE,
    },
    [units.LAYER_ID]: {
      layerVisible: false,
      filtersVisible: false,
      filterColumnOpen: null,
      filters: units.INITIAL_FILTER_STATE,
    },
    [assets.LAYER_ID]: {
      layerVisible: false,
      filtersVisible: false,
      filterColumnOpen: null,
      filters: assets.INITIAL_FILTER_STATE,
    },
    [permitPlats.LAYER_ID]: {
      layerVisible: false,
      filtersVisible: false,
      filterColumnOpen: null,
      filters: permitPlats.INITIAL_FILTER_STATE,
    },
    [tracts.LAYER_ID]: {
      layerVisible: false,
      filtersVisible: false,
      filterColumnOpen: null,
      filters: tracts.INITIAL_FILTER_STATE,
    },
  },
};

export function reducer(state = initialState, action) {
  const { type, layerId, value } = action;

  switch (type) {
    case types.MAP_RESET:
      layers.resetMap(action, initialState);
      return initialState;
    case types.MAP_CONTROLS_SET_CLICK_LAYER:
      return {
        ...state,
        controls: {
          ...state.controls,
          clickLayer: value,
        },
      };
    case types.MAP_CONTROLS_SET_DRAW:
      syncDrawState(action);
      return {
        ...state,
        controls: {
          ...state.controls,
          draw: {
            ...state.controls.draw,
            ...value,
          },
        },
      };
    case types.MAP_SET_ZOOM_TO_FILTER:
      return {
        ...state,
        zoomToFilter: value,
      };
    case types.MAP_LAYER_SET_FILTERS_VISIBILITY:
      return {
        ...state,
        layers: {
          ...state.layers,
          [layerId]: {
            ...state.layers[layerId],
            filtersVisible: value,
          },
        },
      };
    case types.MAP_LAYER_SET_FILTER_COLUMN_OPEN:
      return {
        ...state,
        layers: {
          ...state.layers,
          [layerId]: {
            ...state.layers[layerId],
            filterColumnOpen: value,
          },
        },
      };
    case types.MAP_LAYER_SET_ANNOTATION_VISIBILITY:
      layers.setAnnotationVisibility(action);
      return {
        ...state,
        styles: {
          ...state.styles,
          [layerId]: {
            ...state.styles[layerId],
            annotationVisible: value,
          },
        },
      };
    case types.MAP_LAYER_SET_VISIBILITY:
      layers.setLayerVisibility(action, state.styles);
      return {
        ...state,
        layers: {
          ...state.layers,
          [layerId]: {
            ...state.layers[layerId],
            layerVisible: value,
          },
        },
      };
    case types.MAP_LAYER_SET_FILTER_COLUMN_VALUE:
      layers.setLayerFilterExpression(action);
      return {
        ...state,
        layers: {
          ...state.layers,
          [layerId]: {
            ...state.layers[layerId],
            filters: {
              ...state.layers[layerId].filters,
              [action.column]: {
                ...state.layers[layerId].filters[action.column],
                value: value,
              },
            },
          },
        },
      };
    case types.MAP_LAYER_RESET_FILTERS:
      layers.setLayerFilterExpression(action);
      return {
        ...state,
        layers: {
          ...state.layers,
          [layerId]: {
            ...state.layers[layerId],
            filterColumnOpen: null,
            filters: initialState.layers[layerId].filters,
          },
        },
      };
    default:
      return state;
  }
}

const actions = {
  resetMap,
  // Controls
  setClickLayer,
  setDraw,
  // Layers
  setZoomToFilter,
  resetLayerFilters,
  setLayerVisibility,
  setLayerFiltersVisibility,
  setLayerFilterColumnValue,
  setLayerFilterColumnOpen,
  // Styles
  setLayerAnnotationVisibility,
};

export default actions;
