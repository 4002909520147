import { message } from "antd";
import { apiBase } from "../utils/apiBase";
import { get } from "lodash";

export const types = {
  ENTITIES_SET: "ENTITIES_SET",
};

export const setEntities = (value) => ({
  type: types.ENTITIES_SET,
  value,
});

export const getEntities = () => (dispatch) => {
  return apiBase
    .get(`entities`)
    .then((res) => dispatch(setEntities(get(res, "data.entities", []))))
    .catch((err) => {
      dispatch(setEntities({}));
      message.error("Failed load entities.");
    });
};

export const initialState = {
  data: [],
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case types.ENTITIES_SET:
      return {
        ...state,
        data: action.value,
      };
    default:
      return state;
  }
}

const actions = {
  getEntities,
};

export default actions;
