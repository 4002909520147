import React, { useState } from "react";
import { apiBase } from "../../utils/apiBase";
import { withParams } from "../../utils/withParams";
import { withRouter } from "../../utils/withRouter";
import { get } from "lodash";
import { formatDate } from "../../utils/dates";
import { formatNumber, alphaSort } from "../../utils/numbers";

import { Form, Table, Button, Modal, message } from "antd";

import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";

import { EditableCell } from "../utils/TableUtils";

const { confirm } = Modal;

const AssetDeals = (props) => {
  const { assetDeals, parentLoading } = props;

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;

  const deleteDealAsset = async (id) => {
    setLoading(true);
    apiBase
      .delete(`/deal-assets/${id}`)
      .then((res) => {
        let msg = `${res.status}: Successfully deleted asset deal.`;
        message.success(msg);
        props.getAssetDeals();
        props.getAsset();
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to delete asset deal.`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: "Are you sure you want to delete this asset deal?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      okButtonProps: {
        disabled: loading || parentLoading,
        loading: loading || parentLoading,
      },
      cancelButtonProps: {
        disabled: loading || parentLoading,
        loading: loading || parentLoading,
      },
      onOk: () => deleteDealAsset(id),
    });
  };

  function handleRowClick(e, _to) {
    e.stopPropagation();
    if (e.ctrlKey || e.metaKey) {
      window.open(_to, "_blank");
      return;
    }
    props.history(_to);
  }

  const edit = (record) => {
    form.setFieldsValue({
      cost_basis: record.cost_basis,
      acres: record.acres,
      decimal_interest: record.decimal_interest,
      nra: record.nra,
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const onFormChange = (changedValues, allValues) => {
    const { acres, decimal_interest } = allValues;
    if (acres && decimal_interest) {
      form.setFieldsValue({
        nra: acres * decimal_interest * 8,
      });
    }
  };

  const save = async (record) => {
    try {
      const row = await form.validateFields();
      setLoading(true);
      apiBase
        .patch(`deal-assets/${record.id}`, row)
        .then((res) => {
          let msg = `${res.status}: Successfully updated asset deal.`;
          message.success(msg);
          props.getAssetDeals();
          props.getAsset();
        })
        .catch((err) => {
          let msg = `${get(err, "response.status")}: ${get(
            err,
            "response.data.message"
          )}`;
          message.error(msg);
        })
        .finally(() => {
          setEditingKey("");
          setLoading(false);
        });
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: ["deal", "name"],
      className: `App__rowLink`,
      render: (value) => <div>{value}</div>,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => alphaSort(a.deal.name, b.deal.name),
      onCell: (record) => ({
        onClick: (e) =>
          handleRowClick(e, `/portfolio/deals/${record["deal_id"]}`),
      }),
    },
    {
      title: "Code",
      dataIndex: ["deal", "code"],
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => alphaSort(a.deal.code, b.deal.code),
    },
    {
      title: "Cost basis",
      dataIndex: "cost_basis",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.cost_basis - b.cost_basis,
      render: (value) =>
        value !== null ? `$${formatNumber(value, "0,0.00")}` : null,
    },
    {
      title: "Effective date",
      dataIndex: ["deal", "effective_date"],
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => alphaSort(a.deal.effective_date, b.deal.effective_date),
      render: (value) => formatDate(value, "YYYY-MM-DD"),
      editable: true,
    },
    {
      title: "Acres",
      dataIndex: "acres",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.acres - b.acres,
      render: (value) =>
        value !== null ? formatNumber(value, "0.0000") : null,
      editable: true,
    },
    {
      title: "DOI",
      dataIndex: "decimal_interest",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.decimal_interest - b.decimal_interest,
      render: (value) =>
        value !== null ? formatNumber(value, "0.00000000") : null,
      editable: true,
    },
    {
      title: "NRA",
      dataIndex: "nra",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.nra - b.nra,
      render: (value) =>
        value !== null ? formatNumber(value, "0.0000") : null,
      editable: true,
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "actions",
      render: (value, record) => {
        const editable = isEditing(record);
        return (
          <div className="App__tableOperations">
            {editable ? (
              <>
                <Button
                  shape="circle"
                  icon={<CheckOutlined />}
                  size="small"
                  className="App__tableEditButton"
                  onClick={() => save(record)}
                  disabled={loading || parentLoading}
                />
                <Button
                  shape="circle"
                  icon={<CloseOutlined />}
                  size="small"
                  className="App__tableEditButton"
                  onClick={() => cancel()}
                  disabled={loading || parentLoading}
                />
              </>
            ) : (
              <Button
                shape="circle"
                icon={<EditOutlined />}
                size="small"
                className="App__tableEditButton"
                onClick={() => edit(record)}
                disabled={loading || parentLoading}
              />
            )}
            <Button
              shape="circle"
              icon={<DeleteOutlined />}
              size="small"
              onClick={() => showDeleteConfirm(record.id)}
              disabled={loading || parentLoading}
            />
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false} onValuesChange={onFormChange}>
      <Table
        pagination={false}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        columns={mergedColumns}
        dataSource={assetDeals.map((assetDeal, idx) => ({
          ...assetDeal,
          key: idx,
        }))}
        showSorterTooltip={false}
        className="App__smallTables"
        loading={loading || parentLoading}
        scroll={{ x: "100%" }}
        bordered
      />
    </Form>
  );
};

export default withRouter(withParams(AssetDeals));
