import React, { useState } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import { apiBase } from "../../utils/apiBase";

import { Modal, Form, Input, Select, Button, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import AppConfigSelect from "../common/AppConfigSelect";
import SearchSelect from "../common/SearchSelect";

const mapStateToProps = (state) => ({
  appConfig: get(state.appConfig, "data", {}),
  entity: get(state.entity, "data", {}),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const WellCreateForm = (props) => {
  const { appConfig } = props;
  const { well_status: wellStatus } = appConfig;

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    // console.log(values);
    apiBase
      .post(`wells`, {
        ...values,
      })
      .then(async (res) => {
        message.success("Successfully created well.");
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to create well.`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
        resetComponent();
        setIsModalOpen(false);
        props.executeSearch();
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    resetComponent();
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    resetComponent();
  };

  const submitForm = () => {
    form
      .validateFields()
      .then(() => {
        form.submit();
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };

  const resetComponent = () => {
    form.resetFields();
  };

  const [form] = Form.useForm();

  return (
    <div>
      <Button type="secondary" icon={<PlusOutlined />} onClick={showModal} />
      <Modal
        title="Create well"
        visible={isModalOpen}
        onOk={() => submitForm()}
        onCancel={handleCancel}
        cancelButtonProps={{ disabled: loading }}
        okButtonProps={{ disabled: loading, loading: loading }}
        maskClosable={false}
      >
        <Form
          name="wellCreate"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
          disabled={loading}
          initialValues={{
            status: wellStatus[0],
            type: "oil",
            state: "Texas",
          }}
        >
          <Form.Item
            label="Operator"
            name="operator_id"
            rules={[{ required: true, message: "" }]}
          >
            <SearchSelect
              searchUri="operators"
              searchField="name"
              placeholder="Search operators"
            />
          </Form.Item>
          {[
            { name: "name", label: "Name", required: true },
            { name: "api_number", label: "API number", required: false },
          ].map((item, index) => (
            <Form.Item
              key={index}
              label={item.label}
              name={item.name}
              rules={[
                {
                  required: item.required,
                  message: `${item.label} is required.`,
                },
              ]}
            >
              <Input />
            </Form.Item>
          ))}
          <Form.Item
            label="State"
            name="state"
            rules={[{ required: true, message: "State is required" }]}
          >
            <AppConfigSelect showSearch allowClear appConfigKey="states" />
          </Form.Item>
          <Form.Item
            label="County"
            name="county"
            rules={[{ required: true, message: "County is required" }]}
          >
            <AppConfigSelect showSearch allowClear appConfigKey="counties" />
          </Form.Item>
          <Form.Item
            label="Type"
            name="type"
            rules={[{ required: true, message: "" }]}
          >
            <Select>
              <Select.Option value={"oil"}>Oil</Select.Option>
              <Select.Option value={"gas"}>Gas</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Status"
            name="status"
            rules={[{ required: true, message: "" }]}
          >
            <AppConfigSelect appConfigKey="well_status" />
          </Form.Item>
          <Form.Item
            label="Assets"
            name="assets"
            rules={[{ required: false, message: "" }]}
          >
            <SearchSelect
              mode="multiple"
              searchUri="assets"
              searchField="name"
              placeholder="Search assets"
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WellCreateForm);
