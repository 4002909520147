import { message } from "antd";
import { apiBase } from "../utils/apiBase";
import { get } from "lodash";

export const types = {
  LABELS_SET: "LABELS_SET",
};

export const setLabels = (value) => ({
  type: types.LABELS_SET,
  value,
});

export const getLabels = () => (dispatch) => {
  return apiBase
    .get(`labels`)
    .then((res) => dispatch(setLabels(get(res, "data.labels", []))))
    .catch((err) => {
      dispatch(setLabels({}));
      message.error("Failed to load labels.");
    });
};

export const updateLabels = (data) => (dispatch) => {
  return apiBase
    .post(`labels`, data)
    .then((res) => dispatch(setLabels(get(res, "data.labels", []))))
    .catch((err) => {
      message.error("Failed to update labels.");
    });
};

export const initialState = {
  data: [],
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case types.LABELS_SET:
      return {
        ...state,
        data: action.value,
      };
    default:
      return state;
  }
}

const actions = {
  getLabels,
  updateLabels,
};

export default actions;
