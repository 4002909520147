import React, { useState, useEffect } from "react";
import { alphaSort, formatNumber } from "../../utils/numbers";
import { apiBase } from "../../utils/apiBase";
import { formatDate } from "../../utils/dates";
import { get } from "lodash";

import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";

import { Form, Table, Button, Modal, message } from "antd";

import { EditableCell } from "../utils/TableUtils";

const { confirm } = Modal;

const UnitOffers = (props) => {
  const { unitOffers } = props;

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(unitOffers);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;

  useEffect(() => {
    setData(unitOffers);
  }, [unitOffers]);

  const updateSubmit = (offerId, values) => {
    setLoading(true);
    apiBase
      .patch(`offers/${offerId}`, values)
      .then((res) => {
        let msg = `${res.status}: Successfully updated offer.`;
        message.success(msg);
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
        form.resetFields();
      })
      .finally(() => {
        setLoading(false);
        props.reloadUnit();
      });
  };

  const deleteOffer = async (offerId) => {
    setLoading(true);
    apiBase
      .delete(`/offers/${offerId}`)
      .then((res) => {
        let msg = `${res.status}: Successfully deleted offer.`;
        message.success(msg);
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to delete deal.`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
        props.reloadUnit();
      });
  };

  const showDeleteConfirm = (offerId) => {
    confirm({
      title: "Are you sure you want to delete this offer?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      okButtonProps: { disabled: loading, loading: loading },
      cancelButtonProps: { disabled: loading, loading: loading },
      onOk: () => deleteOffer(offerId),
    });
  };

  function handleRowClick(e, _to) {
    e.stopPropagation();
    if (e.ctrlKey || e.metaKey) {
      window.open(_to, "_blank");
      return;
    }
    props.history(_to);
  }

  const edit = (record) => {
    form.setFieldsValue({
      dollar_per_nra: get(record, "dollar_per_nra"),
      acres: get(record, "acres"),
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (record) => {
    try {
      const row = await form.validateFields();
      let newOffer = {
        dollar_per_nra: row.dollar_per_nra,
        acres: row.acres,
      };
      const newData = [...data];
      const index = newData.findIndex((item) => record.id === item.id);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
      await updateSubmit(record.id, newOffer);
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "Offer",
      dataIndex: "id",
      className: `App__rowLink App__w8`,
      render: (value) => <div className="App__rowIcon">{value}</div>,
      onCell: (record) => ({
        onClick: (e) => handleRowClick(e, `/offers/${record["id"]}`),
      }),
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.id - b.id,
    },
    {
      editable: true,
      title: "Offer Per NRA($)",
      dataIndex: "dollar_per_nra",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.dollar_per_nra - b.dollar_per_nra,
      render: (value) =>
        value !== null ? `$${formatNumber(value, "0,0.00")}` : null,
    },
    {
      editable: true,
      title: "Unit Size (Acres)",
      dataIndex: "acres",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.acres - b.acres,
    },
    {
      title: "Total Lead Offers ($)",
      dataIndex: "total_lead_offers",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.total_lead_offers - b.total_lead_offers,
      render: (value) =>
        value !== null ? `$${formatNumber(value, "0,0.00")}` : null,
    },
    {
      title: "Number Lead Offers",
      dataIndex: "number_lead_offers",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.number_lead_offers - b.number_lead_offers,
    },
    {
      title: "Created By",
      dataIndex: ["user", "alias"],
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => alphaSort(get(a, "user.alias"), get(b, "user.alias")),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => alphaSort(a.created_at, b.created_at),
      render: (value) => formatDate(value, "YYYY-MM-DD, h:mm:ss A"),
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "actions",
      render: (value, record) => {
        const editable = isEditing(record);
        return (
          <div className="App__tableOperations">
            {editable ? (
              <>
                <Button
                  shape="circle"
                  icon={<CheckOutlined />}
                  size="small"
                  className="App__tableEditButton"
                  onClick={() => save(record)}
                  disabled={loading}
                />
                <Button
                  shape="circle"
                  icon={<CloseOutlined />}
                  size="small"
                  className="App__tableEditButton"
                  onClick={() => cancel()}
                  disabled={loading}
                />
              </>
            ) : (
              <Button
                shape="circle"
                icon={<EditOutlined />}
                size="small"
                className="App__tableEditButton"
                onClick={() => edit(record)}
                disabled={loading}
              />
            )}
            <Button
              shape="circle"
              icon={<DeleteOutlined />}
              size="small"
              onClick={() => showDeleteConfirm(record.id)}
              disabled={loading}
            />
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        columns={mergedColumns}
        dataSource={data.map((row, idx) => ({
          ...row,
          key: idx,
        }))}
        pagination={false}
        showSorterTooltip={false}
        className="App__smallTables"
        loading={loading}
        // scroll={{ x: "auto" }}
        bordered
      />
    </Form>
  );
};

export default UnitOffers;
