import React, { Component } from "react";
import { connect } from "react-redux";
import { uniq, get } from "lodash";
import { AssetCountyCountIcon } from "../common/LabelIcon";

import { formatNumber, alphaSort } from "../../utils/numbers";

import { Table } from "antd";

import styles from "./OwnerUnits.module.scss";

const mapStateToProps = (state) => ({
  aggregates: get(state.aggregates, "data", {}),
});

class OwnerUnits extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleRowClick(e, _to) {
    e.stopPropagation();
    if (e.ctrlKey || e.metaKey) {
      window.open(_to, "_blank");
      return;
    }
    this.props.history(_to);
  }

  render() {
    const { columnExtras, aggregates } = this.props;
    const assetCountyCount = get(aggregates, "asset_county_count", {});

    const columns = [
      {
        title: "Unit",
        dataIndex: "unit_id",
        className: `App__rowLink App__w8`,
        render: (value) => <div className="App__rowIcon">{value}</div>,
        onCell: (record) => ({
          onClick: (e) => this.handleRowClick(e, `/units/${record["unit_id"]}`),
        }),
      },
      {
        title: "Lead status",
        dataIndex: "leads",
        className: `App__w9`,
        // ellipsis: {
        //   showTitle: false,
        // },
        render: (value) => {
          const _value = uniq(
            (value || []).map((item) => `${get(item, "status", "")} `)
          );
          return _value.join(" ");
        },
      },
      {
        title: "Name",
        dataIndex: "name",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => alphaSort(a.name, b.name),
      },
      {
        title: "Well",
        dataIndex: "well",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => alphaSort(a.well, b.well),
      },
      {
        title: "RRC (#)",
        dataIndex: "rrc_id",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => alphaSort(a.rrc_id, b.rrc_id),
      },
      {
        title: "County",
        dataIndex: "county",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => alphaSort(a.county, b.county),
        render: (value) => {
          const count = get(assetCountyCount, value, 0);
          return (
            <div className={styles.countyCount}>
              <span className={styles.countyCountLabel}>{value}</span>
              {parseInt(count) > 0 && <AssetCountyCountIcon value={count} />}
            </div>
          );
        },
      },
      {
        title: "Operator",
        dataIndex: "operator",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => alphaSort(a.operator, b.operator),
      },
      {
        title: "Interest Type",
        dataIndex: "interest_type",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => alphaSort(a.interest_type, b.interest_type),
      },
      {
        title: "Acres",
        dataIndex: "acres",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => a.acres - b.acres,
      },
      {
        title: "Decimal Interest",
        dataIndex: "decimal_interest",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => a.decimal_interest - b.decimal_interest,
        render: (value) =>
          value !== null ? formatNumber(value, "0.000000") : null,
      },
      {
        title: "NRA",
        dataIndex: "nra",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => a.nra - b.nra,
        render: (value) =>
          value !== null ? formatNumber(value, "0.00") : null,
      },
      {
        title: "Tax year",
        dataIndex: "tax_year",
        sorter: (a, b) => a.tax_year - b.tax_year,
      },
      ...(columnExtras
        ? [
            {
              title: "Owner name",
              dataIndex: "owner_name",
              sortDirections: ["descend", "ascend"],
              sorter: (a, b) => alphaSort(a.owner_name, b.owner_name),
            },
            {
              title: "Owner address",
              dataIndex: "owner_address",
              sortDirections: ["descend", "ascend"],
              sorter: (a, b) => alphaSort(a.owner_address, b.owner_address),
            },
            {
              title: "Owner Zip Code",
              dataIndex: "owner_zip_code",
              sortDirections: ["descend", "ascend"],
              sorter: (a, b) => alphaSort(a.owner_zip_code, b.owner_zip_code),
            },
            {
              title: "Legal description",
              dataIndex: "legal_description",
              sortDirections: ["descend", "ascend"],
              sorter: (a, b) =>
                alphaSort(a.legal_description, b.legal_description),
            },
          ]
        : []),
    ];

    return (
      <Table
        // rowSelection={rowSelection}
        columns={columns}
        dataSource={this.props.ownerUnitInterests}
        className="App__smallTables"
        showSorterTooltip={false}
        pagination={{
          pageSize: this.props.recordsPerPage,
          showSizeChanger: false,
        }}
        loading={this.props.isLoadingInterests}
        scroll={{ x: "100%" }}
        bordered
      />
    );
  }
}

export default connect(mapStateToProps)(OwnerUnits);
