import * as units from "./units";
import * as assets from "./assets";
import * as landtracUnits from "./landtracUnits";
import * as permitPlats from "./permitPlats";
import * as wells from "./wells";
import * as tracts from "./tracts";

// https://colorbrewer2.org/#type=qualitative&scheme=Dark2&n=6
// #4bbf90
// #ff5f23
// #721ea9
// #FE00FE
// #5FB300
// #f0ad4e
// #317ae2
// #D20C0C
// #FFFFFF
// #7f888f

const layerModules = {
  [wells.LAYER_ID]: wells,
  [landtracUnits.LAYER_ID]: landtracUnits,
  [permitPlats.LAYER_ID]: permitPlats,
  [units.LAYER_ID]: units,
  [assets.LAYER_ID]: assets,
  [tracts.LAYER_ID]: tracts,
};

const SOURCE_IDS = [
  units.SOURCE_ID,
  assets.SOURCE_ID,
  landtracUnits.SOURCE_ID,
  permitPlats.SOURCE_ID,
  tracts.SOURCE_ID,
];

const DRAW_SNAP_LAYER_IDS = [
  units.LAYER_ID,
  assets.LAYER_ID,
  landtracUnits.LAYER_ID,
  permitPlats.LAYER_ID,
  tracts.LAYER_ID,
  "Enverus grid sections",
];

const addAll = (mapRef) => {
  Object.values(layerModules).forEach((module) => {
    if (module.addAll) {
      module.addAll(mapRef);
    }
  });
};

const setHighlightFilters = (mapRef, layerId, id) => {
  if (!mapRef || !mapRef.current) return;

  removeHighlightFilters(mapRef, layerId);

  const module = layerModules[layerId];

  if (module && module.BORDER_HIGHLIGHT_LAYER_ID) {
    mapRef.current.setFilter(module.BORDER_HIGHLIGHT_LAYER_ID, [
      "==",
      "id",
      id,
    ]);
  }
};

const removeHighlightFilters = (mapRef, layerId) => {
  if (!mapRef || !mapRef.current) return;

  const module = layerModules[layerId];

  if (
    module &&
    module.UNIQUE_COLUMN &&
    module.BORDER_HIGHLIGHT_LAYER_ID &&
    mapRef.current.getLayer(module.BORDER_HIGHLIGHT_LAYER_ID)
  ) {
    mapRef.current.setFilter(module.BORDER_HIGHLIGHT_LAYER_ID, [
      "in",
      module.UNIQUE_COLUMN,
      "",
    ]);
  }

  if (!layerId) {
    Object.values(layerModules).forEach((module) => {
      if (
        module.UNIQUE_COLUMN &&
        module.BORDER_HIGHLIGHT_LAYER_ID &&
        mapRef.current.getLayer(module.BORDER_HIGHLIGHT_LAYER_ID)
      ) {
        mapRef.current.setFilter(module.BORDER_HIGHLIGHT_LAYER_ID, [
          "in",
          module.UNIQUE_COLUMN,
          "",
        ]);
      }
    });
  }
};

// TODO - Generalize - move setAnnotationVisibility to layer module
const setAnnotationVisibility = (action) => {
  const { layerId } = action;
  const module = layerModules[layerId];
  if (module && module.setAnnotationVisibility) {
    module.setAnnotationVisibility(action);
  }
};

const setLayerVisibility = (action, styles = null) => {
  const { layerId, mapRef, value } = action;
  if (!mapRef || !mapRef.current) return;

  const module = layerModules[layerId];
  if (!module || !module.FILTER_LAYER_IDS) return;

  const map = mapRef.current;
  const visibility = value ? "visible" : "none";

  module.FILTER_LAYER_IDS.forEach((id) => {
    if (map.getLayer(id)) {
      map.setLayoutProperty(id, "visibility", visibility);
    }
  });

  // Don't enable annotation visibility if the layer is not visible
  if (
    module &&
    module.LAYER_ANNOTATION_ID &&
    styles &&
    map.getLayer(module.LAYER_ANNOTATION_ID)
  ) {
    const annotationVisible = styles[module.LAYER_ID].annotationVisible;
    map.setLayoutProperty(
      module.LAYER_ANNOTATION_ID,
      "visibility",
      annotationVisible && visibility === "visible" ? "visible" : "none"
    );
  }
};

const setLayerFilterExpression = (action) => {
  const { layerId, mapRef, column, value = [], filterExpression } = action;
  if (!mapRef || !mapRef.current) return;

  const module = layerModules[layerId];
  if (!module || !module.FILTER_LAYER_IDS) return;

  const map = mapRef.current;
  const values = value.map((item) => item.value);

  let _filterExpression = null;
  if (filterExpression) {
    _filterExpression = filterExpression;
  } else if (column && values.length > 0) {
    _filterExpression = ["all", ["in", column, ...values]];
  }

  module.FILTER_LAYER_IDS.forEach((id) => {
    if (map.getLayer(id)) {
      map.setFilter(id, _filterExpression);
    }
  });

  // Also filter annotations
  if (
    module &&
    module.LAYER_ANNOTATION_ID &&
    map.getLayer(module.LAYER_ANNOTATION_ID)
  ) {
    map.setFilter(module.LAYER_ANNOTATION_ID, _filterExpression);
  }
};

const resetMap = (action, initialState) => {
  const { mapRef } = action;

  if (!mapRef || !mapRef.current) return;

  Object.values(layerModules).forEach((module) => {
    const layerId = module.LAYER_ID;
    const layerVisible = initialState.layers[layerId].layerVisible;

    setLayerVisibility({ mapRef, layerId, value: layerVisible });
    setLayerFilterExpression({
      mapRef,
      layerId,
    });
    removeHighlightFilters(mapRef, layerId);
  });
};

export {
  layerModules,
  addAll,
  SOURCE_IDS,
  DRAW_SNAP_LAYER_IDS,
  removeHighlightFilters,
  setHighlightFilters,
  setAnnotationVisibility,
  setLayerVisibility,
  setLayerFilterExpression,
  resetMap,
};
