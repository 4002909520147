import React from "react";

const BaseDetails = ({ currentFeature }) => {
  const { properties } = currentFeature;

  return (
    <>
      {Object.entries(properties).map(([key, value]) => (
        <div key={key}>
          <strong>{key}:</strong> {value}
        </div>
      ))}
    </>
  );
};
export default BaseDetails;
