const MAPBOX_ACCESS_TOKEN =
  process.env.NODE_ENV === "development"
    ? "pk.eyJ1IjoiZ3Rob21wc29uMiIsImEiOiJjbHdsNnI1eHYwOTZiMmpwZ2kyc3Fnbm1xIn0.hQDwOvGPr55Ui1AElnjFoA"
    : "pk.eyJ1IjoiZ3Rob21wc29uMiIsImEiOiJjbHdsNnI1eHYwOTZiMmpwZ2kyc3Fnbm1xIn0.hQDwOvGPr55Ui1AElnjFoA";

const MAPBOX_STYLE =
  process.env.NODE_ENV === "development"
    ? "mapbox://styles/gthompson2/cm47ipf2m008m01rz6zm5c104/draft"
    : //  ? "mapbox://styles/gthompson2/cm47ipf2m008m01rz6zm5c104"
      "mapbox://styles/gthompson2/cm47ipf2m008m01rz6zm5c104";

const MAPBOX_USER_ID = "gthompson2";

const INITIAL_CENTER = [-98.307, 30.613];
const INITIAL_ZOOM = 7.01;
const MAX_ZOOM = 18;

const CONTROL_IDS = {
  FILTERS: "filters",
  GRID_SEARCH: "gridSearch",
  MAP_STYLES: "mapStyles",
};

const WELL_LAYERS = ["Bottoms", "Surface", "Laterals"];

// const UNITS_SOURCE_ID = "units";
// const UNITS = "Units";
// const UNITS_BORDER = "Units border";
// const UNITS_BORDER_HIGHLIGHT = "Units border highlighted";
// const UNITS_LAYERS = [UNITS, UNITS_BORDER];

// const ASSETS_SOURCE_ID = "assets";
// const ASSETS = "Assets";
// const ASSETS_BORDER = "Assets border";
// const ASSETS_BORDER_HIGHLIGHT = "Assets border highlighted";
// const ASSETS_LAYERS = [ASSETS, ASSETS_BORDER];

export {
  MAPBOX_USER_ID,
  MAPBOX_ACCESS_TOKEN,
  MAPBOX_STYLE,
  INITIAL_CENTER,
  INITIAL_ZOOM,
  MAX_ZOOM,
  WELL_LAYERS,
  CONTROL_IDS,
};
