export const types = {
  ENTITY_SET: "ENTITY_SET",
};

export const setEntity = (value) => ({
  type: types.ENTITY_SET,
  value,
});

export const initialState = {
  data: {},
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case types.ENTITY_SET:
      return {
        ...state,
        data: action.value,
      };
    default:
      return state;
  }
}

const actions = {
  setEntity,
};

export default actions;
