import React from "react";
import { columnTypes } from "../../utils/search";
import { formatDate } from "../../utils/dates";
import { get, isEmpty } from "lodash";

import BaseSearch from "../search/BaseSearch";
import Took from "../search/Took";
import TotalRecords from "../search/TotalRecords";

import { TableOutlined } from "@ant-design/icons";

import IDISearchCreateForm from "../idi/IDISearchCreateForm";
import ChangeColumnsForm from "../forms/ChangeColumnsForm";
import RawDataColumn from "../common/RawDataColumn";
import FilterTags from "../search/FilterTags";
import { Row, Col, Table, Select, Button, Tooltip, Pagination } from "antd";

const renderSearch = (value) => {
  const firstName = get(value, "firstName", "") || "";
  const middleName = get(value, "middleName", "") || "";
  const lastName = get(value, "lastName", "") || "";
  const address = get(value, "address", "") || "";
  const city = get(value, "city", "") || "";
  const state = get(value, "state", "") || "";
  const zip = get(value, "zip", "") || "";
  const pidList = get(value, "pidlist", []);
  return !isEmpty(pidList)
    ? pidList.join(",")
    : `${firstName} ${middleName} ${lastName} ${address} ${city} ${state} ${zip}`
        .replace(/\s+/g, " ")
        .trim();
};

const renderErrorMessage = (record) => {
  const message = get(record, "error.message", "");
  const level = get(record, "error.level", "");
  return `${level} - ${message}`;
};

const baseColumnState = [
  {
    dataIndex: "id",
    title: "Id",
    editable: false,
    type: columnTypes.LINK,
    baseUrl: "/idi-searches",
    className: `App__rowLink App__w8`,
  },
  {
    title: "Owner",
    dataIndex: "owner_id",
    editable: false,
    type: columnTypes.LINK,
    baseUrl: "/owners",
    className: `App__rowLink App__w8`,
  },
  {
    title: "Created By",
    dataIndex: ["user", "alias"],
    type: columnTypes.STRING,
    className: `App__w12`,
  },
  {
    key: "search_name",
    dataIndex: "request_body",
    title: "Search name",
    children: [
      {
        key: "search_name",
        dataIndex: "request_body",
        title: () => <div />,
        render: (value) => (
          <Tooltip placement="left" title={renderSearch(value)}>
            {renderSearch(value)}
          </Tooltip>
        ),
        ellipsis: {
          showTitle: true,
        },
      },
    ],
  },
  {
    key: "total_results",
    dataIndex: "total_results",
    title: "Total results",
    className: `App__w12`,
    type: columnTypes.INTEGER,
  },
  {
    dataIndex: "response_status_code",
    title: "Status code",
    type: columnTypes.STRING,
    className: `App__w12`,
  },
  {
    key: "error_message",
    dataIndex: "error",
    title: "Error message",
    children: [
      {
        key: "error_message",
        dataIndex: "error",
        title: () => <div />,
        render: (_, record) => (
          <Tooltip placement="left" title={renderErrorMessage(record)}>
            {renderErrorMessage(record)}
          </Tooltip>
        ),
        ellipsis: {
          showTitle: true,
        },
      },
    ],
  },
  {
    key: "error",
    dataIndex: "error",
    title: "Error",
    className: `App__w12`,
    children: [
      {
        key: "error",
        dataIndex: "error",
        title: () => <div />,
        render: (_, record) => <RawDataColumn data={get(record, "error")} />,
      },
    ],
  },
  {
    key: "request_body",
    dataIndex: "request_body",
    title: "Request body",
    className: `App__w12`,
    children: [
      {
        key: "request_body",
        dataIndex: "request_body",
        title: () => <div />,
        render: (_, record) => (
          <RawDataColumn data={get(record, "request_body")} />
        ),
      },
    ],
  },
  {
    title: "Created at",
    dataIndex: "created_at",
    type: columnTypes.DATE_TIME,
    format: (value) => formatDate(value, "YYYY-MM-DD, h:mm:ss A"),
    className: `App__w16`,
  },
];

const IDISearches = (props) => {
  const {
    isLoadingRecords,
    isLoadingCount,
    records,
    columnState,
    limit,
    page,
    totalRecords,
    tookRecords,
    tookCount,
    searchFields,
    isChangeColumnsVisible,
  } = props;

  const {
    handleSearch,
    handleRecordsChange,
    handlePaginationChange,
    handleFilterClose,
    handleTableChange,
    handleSubmitColumnChange,
    showColumnChangeModal,
    handleCancelColumnsChange,
    changeColumnFormRef,
    getColumnProps,
    executeSearch,
  } = props;

  let columns = columnState
    .map((column, idx) => ({
      key: idx,
      ...column,
      ...getColumnProps(column),
    }))
    .filter((col) => col.visible === true);

  return (
    <div>
      <Row className="App__rowContent">
        <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16}>
          <Button className="App__mr-5" onClick={() => handleSearch()}>
            Search
          </Button>
          <FilterTags
            searchFields={searchFields}
            columnState={columnState}
            handleFilterClose={handleFilterClose}
          />
        </Col>
        <Col
          span={8}
          xs={24}
          sm={24}
          md={8}
          lg={8}
          xl={8}
          className="App__rightActions"
        >
          <div className="App__mr-5">
            <IDISearchCreateForm refetch={executeSearch} />
          </div>
          <Tooltip placement="bottomRight" title={"Change visible columns"}>
            <Button onClick={showColumnChangeModal} className="App__iconButton">
              <TableOutlined />
            </Button>
          </Tooltip>
          <ChangeColumnsForm
            wrappedComponentRef={changeColumnFormRef}
            visible={isChangeColumnsVisible}
            isLoading={isLoadingRecords}
            onCancel={handleCancelColumnsChange}
            onOk={handleSubmitColumnChange}
            columnState={columnState}
          />
          <Select
            value={limit}
            onChange={handleRecordsChange}
            className={"App__mr-5"}
          >
            <Select.Option value={25}>25</Select.Option>
            <Select.Option value={50}>50</Select.Option>
            <Select.Option value={100}>100</Select.Option>
          </Select>
          <TotalRecords
            title={"IDI Searches"}
            isLoadingCount={isLoadingCount}
            totalRecords={totalRecords}
          />
        </Col>
      </Row>
      <Row className="App__rowContent">
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={records}
            onChange={handleTableChange}
            showSorterTooltip={false}
            pagination={false}
            className="App__smallTables"
            loading={isLoadingRecords}
            scroll={{ x: "100%" }}
            size="small"
            bordered
          />
          <div className="App__searchFooterContainer">
            <Took
              isLoadingRecords={isLoadingRecords}
              isLoadingCount={isLoadingCount}
              tookRecords={tookRecords}
              tookCount={tookCount}
            />
            <Pagination
              current={page}
              pageSize={limit}
              total={totalRecords}
              onChange={handlePaginationChange}
              className="App__pagination"
              showSizeChanger={false}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

const IDISearchesWithSearch = (props) => (
  <BaseSearch
    baseColumnState={baseColumnState}
    localSortColumns={[]}
    searchUri="idi-searches"
  >
    <IDISearches {...props} />
  </BaseSearch>
);

export default IDISearchesWithSearch;
