import { useState, useEffect } from "react";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { apiBase } from "../../../../utils/apiBase";
import mapActions from "../../../../actions/map";

import { get } from "lodash";
import * as turf from "@turf/turf";
import clsx from "clsx";
import { SaveOutlined } from "@ant-design/icons";

import styles from "./BasePopup.module.scss";

const TractDetails = ({ currentFeature }) => {
  const { properties } = currentFeature;

  const dispatch = useDispatch();
  const user = useSelector((state) => get(state.user, "data", {}));

  const [loading, setLoading] = useState(false);
  const [tract, setTract] = useState(null);

  useEffect(() => {
    setLoading(true);
    apiBase
      .get(`/tracts/${properties.id}`)
      .then((res) => {
        setTract(res.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [properties.id]);

  const saveGeometry = () => {
    if (!tract || !tract.geom_polygon) return;
    const features = turf.feature(tract.geom_polygon);
    dispatch(mapActions.setDraw(null, { savedGeometry: features }));
  };

  const isAdmin = user.role === "ADMIN";

  return (
    <>
      <div
        className={clsx(styles.properties, {
          [styles.loading]: loading,
        })}
      >
        {Object.entries(properties).map(([key, value]) => (
          <div className={styles.property} key={key}>
            <strong>{key}:</strong>
            {key === "id" && isAdmin ? (
              <a
                className="App__ml-5"
                href={`/portfolio/tracts/${value}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {value}
              </a>
            ) : (
              <span className="App__ml-5">{value}</span>
            )}
          </div>
        ))}
      </div>
      <hr className={styles.separator} />
      <div className={styles.buttons}>
        <Button type="secondary" size="small" onClick={saveGeometry}>
          <SaveOutlined />
        </Button>
      </div>
    </>
  );
};

export default TractDetails;
