import React, { useState } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import { apiBase } from "../../utils/apiBase";
import moment from "moment";

import {
  Modal,
  Form,
  Input,
  InputNumber,
  Button,
  DatePicker,
  message,
} from "antd";

import { PlusOutlined } from "@ant-design/icons";

import EntitySelect from "../common/EntitySelect";

const mapStateToProps = (state) => ({
  entity: get(state.entity, "data", {}),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const DealCreateForm = (props) => {
  const { entity } = props;

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    apiBase
      .post(`deals`, {
        ...values,
        effective_date: values.effective_date.toISOString(),
      })
      .then(async (res) => {
        message.success("Successfully created deal.");
      })
      .catch((err) => {
        console.log(err);
        let msg = `${err.response.status}: Failed to create deal.`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
        resetComponent();
        setIsModalOpen(false);
        props.executeSearch();
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    resetComponent();
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    resetComponent();
  };

  const submitForm = () => {
    form
      .validateFields()
      .then(() => {
        form.submit();
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };

  const resetComponent = () => {
    form.resetFields();
  };

  const [form] = Form.useForm();

  return (
    <div>
      <Button type="secondary" icon={<PlusOutlined />} onClick={showModal} />
      <Modal
        title="Create deal"
        visible={isModalOpen}
        onOk={() => submitForm()}
        onCancel={handleCancel}
        cancelButtonProps={{ disabled: loading }}
        okButtonProps={{ disabled: loading, loading: loading }}
        maskClosable={false}
      >
        <Form
          name="dealCreate"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
          disabled={loading}
          initialValues={{
            entity_id: entity.id,
            effective_date: moment(),
          }}
        >
          <Form.Item
            label="Entity"
            name="entity_id"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <EntitySelect />
          </Form.Item>
          {[
            { name: "code", label: "Code", required: true },
            { name: "name", label: "Name", required: true },
          ].map((item, index) => (
            <Form.Item
              key={index}
              label={item.label}
              name={item.name}
              rules={[
                {
                  required: item.required,
                  message: `${item.label} is required.`,
                },
              ]}
            >
              <Input />
            </Form.Item>
          ))}
          <Form.Item
            label="Purchase price"
            name="purchase_price"
            rules={[
              {
                required: true,
                message: `Purchase price is required.`,
              },
            ]}
          >
            <InputNumber
              className="App__fullWidth"
              precision={2}
              prefix={"$"}
            />
          </Form.Item>
          <Form.Item
            label="Effective date"
            name="effective_date"
            rules={[
              {
                required: true,
                message: "Effective date is required.",
              },
            ]}
          >
            <DatePicker
              className="App__fullWidth"
              format={[
                "M/DD/YY",
                "M/D/YY",
                "MM/D/YY",
                "M/DD/YYYY",
                "M/D/YYYY",
                "MM/D/YYYY",
              ]}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DealCreateForm);
