import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { get, isEmpty } from "lodash";

import mapActions from "../../../actions/map";
import { layerModules } from "../layers";
import { removeHighlightFilters } from "../layers";

export const useLayerFilter = (mapRef, mapLoaded, layerId, records) => {
  const dispatch = useDispatch();

  return useCallback(
    (enabled) => {
      if (!mapLoaded || !mapRef.current) return;

      const module = layerModules[layerId];
      if (!module) return;

      if (isEmpty(records)) return;

      if (enabled) {
        dispatch(mapActions.setLayerVisibility(mapRef, layerId, true));
        dispatch(
          mapActions.setLayerFilterColumnValue(
            mapRef,
            layerId,
            module.UNIQUE_COLUMN,
            records.map((r) => ({
              value: get(r, module.UNIQUE_COLUMN, "id"),
              label: get(r, module.UNIQUE_COLUMN, "id"),
            }))
          )
        );
      } else {
        dispatch(mapActions.setLayerVisibility(mapRef, layerId, false));
        dispatch(mapActions.resetLayerFilters(mapRef, layerId));
        removeHighlightFilters(mapRef, layerId);
      }
    },
    [dispatch, mapLoaded, mapRef, layerId, records]
  );
};
