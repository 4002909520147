import React, { useState } from "react";
import { apiBase } from "../../utils/apiBase";

import SearchSelect from "../common/SearchSelect";

import { Modal, Form, Button, message, InputNumber } from "antd";

import { PlusOutlined } from "@ant-design/icons";

// import styles from "./WellAssetCreateForm.module.scss";

const WellAssetCreateForm = (props) => {
  const { well } = props;

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    apiBase
      .post(`wells/${well.id}/assets`, {
        ...values,
      })
      .then(async (res) => {
        message.success("Successfully created well asset.");
      })
      .catch((err) => {
        console.log(err);
        let msg = `${err.response.status}: Failed to create well asset.`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
        resetComponent();
        setIsModalOpen(false);
        props.getWell();
        props.getWellAssets();
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    resetComponent();
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    resetComponent();
  };

  const submitForm = () => {
    form
      .validateFields()
      .then(() => {
        form.submit();
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };

  const onFormChange = (changedValues, allValues) => {};

  const resetComponent = () => {
    form.resetFields();
  };

  const [form] = Form.useForm();

  return (
    <div>
      <Button type="secondary" icon={<PlusOutlined />} onClick={showModal} />
      <Modal
        title="Create well asset"
        visible={isModalOpen}
        onOk={() => submitForm()}
        onCancel={handleCancel}
        cancelButtonProps={{ disabled: loading }}
        okButtonProps={{ disabled: loading, loading: loading }}
        maskClosable={false}
      >
        <Form
          name="wellAssetCreate"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onFormChange}
          autoComplete="off"
          layout="vertical"
          disabled={loading}
          initialValues={{
            "asset.state": "TX",
          }}
        >
          <Form.Item
            label="Decimal interest"
            name="decimal_interest"
            rules={[
              {
                required: false,
                message: `Decimal interest is required.`,
              },
            ]}
          >
            <InputNumber disabled={loading} className="App__fullWidth" />
          </Form.Item>
          <Form.Item
            label="Asset allocation"
            name="asset_allocation"
            rules={[
              {
                required: false,
                message: `Asset allocaton is required.`,
              },
            ]}
          >
            <InputNumber
              precision={8}
              addonAfter={"%"}
              placeholder="0.00"
              className="App__fullWidth"
            />
          </Form.Item>
          <Form.Item
            label="Revenue allocation"
            name="revenue_allocation"
            rules={[
              {
                required: false,
                message: `Revenue allocaton is required.`,
              },
            ]}
          >
            <InputNumber
              precision={8}
              addonAfter={"%"}
              placeholder="0.00"
              className="App__fullWidth"
            />
          </Form.Item>
          <Form.Item
            label="Assets"
            name="asset_id"
            rules={[{ required: true, message: "" }]}
          >
            <SearchSelect
              searchUri="assets"
              searchField="name"
              placeholder="Search assets"
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default WellAssetCreateForm;
