import React from "react";
import { Link } from "react-router-dom";
import { get, isEmpty } from "lodash";
import PipedrivePersonLabels from "../common/PipedrivePersonLabels";
import { Row, Col, Input, Checkbox } from "antd";
import { WarningTwoTone, LinkOutlined } from "@ant-design/icons";
import { DecileIcon } from "../common/LabelIcon";

import styles from "./OwnerEdit.module.scss";

const OwnerEdit = (props) => {
  const {
    owner,
    yearPropertyCount,
    handleOwnerEditChange,
    handleOwnerEditSubmit,
    setOwnerEdit,
    isOwnerEdit,
  } = props;

  const meObjectLinks = get(owner, "me_object_links", []);
  const pipedrivePersons = get(owner, "pipedrive_persons", []);

  return (
    <Row>
      <Col span={8} xs={24} sm={24} md={24} lg={24} xl={8}>
        <div className={styles.objectData}>
          <span>Id:</span>
          {owner.id}
        </div>
        <div className={styles.objectData}>
          <span>Formatted Name:</span>
          <div onClick={() => setOwnerEdit()}>
            <Input
              value={owner.name_formatted}
              onChange={(e) => handleOwnerEditChange(e)}
              onPressEnter={(e) => {
                handleOwnerEditSubmit();
                e.target.blur();
              }}
              className={
                isOwnerEdit ? styles.inputActive : styles.inputDisabled
              }
              name="name_formatted"
              type="string"
              size="small"
              autoComplete="off"
            />
          </div>
        </div>
        <div className={styles.objectData}>
          <span>First Name:</span>
          <div onClick={() => setOwnerEdit()}>
            <Input
              value={owner.first_name}
              onChange={(e) => handleOwnerEditChange(e)}
              onPressEnter={(e) => {
                handleOwnerEditSubmit();
                e.target.blur();
              }}
              className={
                isOwnerEdit ? styles.inputActive : styles.inputDisabled
              }
              name="first_name"
              type="string"
              size="small"
              autoComplete="off"
            />
          </div>
        </div>
        <div className={styles.objectData}>
          <span>Middle Name:</span>
          <div onClick={() => setOwnerEdit()}>
            <Input
              value={owner.middle_name}
              onChange={(e) => handleOwnerEditChange(e)}
              onPressEnter={(e) => {
                handleOwnerEditSubmit();
                e.target.blur();
              }}
              className={
                isOwnerEdit ? styles.inputActive : styles.inputDisabled
              }
              name="middle_name"
              type="string"
              size="small"
              autoComplete="off"
            />
          </div>
        </div>
        <div className={styles.objectData}>
          <span>Last Name:</span>
          <div onClick={() => setOwnerEdit()}>
            <Input
              value={owner.last_name}
              onChange={(e) => handleOwnerEditChange(e)}
              onPressEnter={(e) => {
                handleOwnerEditSubmit();
                e.target.blur();
              }}
              className={
                isOwnerEdit ? styles.inputActive : styles.inputDisabled
              }
              name="last_name"
              type="string"
              size="small"
              autoComplete="off"
            />
          </div>
        </div>
        <div className={styles.objectData}>
          <span>Name:</span>
          {owner.name}
        </div>
        <div className={styles.objectData}>
          <span>ICO:</span>
          {owner.name_ico}
        </div>
        <div className={styles.objectData}>
          <span>Master owner name address id:</span>
          {owner.master_owner_name_address_id}
        </div>
        <div className={styles.objectData}>
          <span>Cluster id:</span>
          {owner.cluster_id}
        </div>
        {owner.last_model_prediction_id && (
          <div className={styles.lastPrediction}>
            <span>Last model prediction id:</span>
            {owner.last_model_prediction_id}
            <span className={styles.decile}>PD:</span>
            <DecileIcon
              value={get(owner, "last_model_prediction.outputs.pd_decile")}
            />
            <span className={styles.decile}>EV:</span>
            <DecileIcon
              value={get(owner, "last_model_prediction.outputs.ev_decile")}
            />
          </div>
        )}
      </Col>
      <Col span={8} xs={24} sm={24} md={24} lg={24} xl={8}>
        <div className={styles.objectData}>
          <span>Pipedrive persons:</span>
          <PipedrivePersonLabels pipedrivePersons={pipedrivePersons} />
        </div>
        <div className={styles.objectData}>
          <span>Formatted address:</span>
          <div onClick={() => setOwnerEdit()}>
            <Input
              value={owner.address_formatted}
              onChange={(e) => handleOwnerEditChange(e)}
              onPressEnter={(e) => {
                handleOwnerEditSubmit();
                e.target.blur();
              }}
              className={
                isOwnerEdit ? styles.inputActive : styles.inputDisabled
              }
              name="address_formatted"
              type="string"
              size="small"
              autoComplete="off"
            />
          </div>
        </div>
        <div className={styles.objectData}>
          <span>City:</span>
          <div onClick={() => setOwnerEdit()}>
            <Input
              value={owner.city}
              onChange={(e) => handleOwnerEditChange(e)}
              onPressEnter={(e) => {
                handleOwnerEditSubmit();
                e.target.blur();
              }}
              className={
                isOwnerEdit ? styles.inputActive : styles.inputDisabled
              }
              name="city"
              type="string"
              size="small"
              autoComplete="off"
            />
          </div>
        </div>
        <div className={styles.objectData}>
          <span>State:</span>
          <div onClick={() => setOwnerEdit()}>
            <Input
              value={owner.state}
              onChange={(e) => handleOwnerEditChange(e)}
              onPressEnter={(e) => {
                handleOwnerEditSubmit();
                e.target.blur();
              }}
              className={
                isOwnerEdit ? styles.inputActive : styles.inputDisabled
              }
              name="state"
              type="string"
              size="small"
              autoComplete="off"
            />
          </div>
        </div>
        <div className={styles.objectData}>
          <span>Zip code:</span>
          <div onClick={() => setOwnerEdit()}>
            <Input
              value={owner.zip_code}
              onChange={(e) => handleOwnerEditChange(e)}
              onPressEnter={(e) => {
                handleOwnerEditSubmit();
                e.target.blur();
              }}
              className={
                isOwnerEdit ? styles.inputActive : styles.inputDisabled
              }
              name="zip_code"
              type="string"
              size="small"
              autoComplete="off"
            />
          </div>
        </div>
        <div className={styles.objectData}>
          <span>Address:</span>
          {props.owner.address}
        </div>
        <div className={styles.objectData}>
          <span>Tax Year:</span>
          {props.owner.tax_year}
        </div>
        <div className={styles.objectData}>
          <span>Tax Year Count:</span>
          {props.owner.tax_year_count}
        </div>
        <div className={styles.objectData}>
          <span>Tax Year Count:</span>
          {props.owner.tax_year_count}
        </div>
        <div className={styles.objectData}>
          <span>Old database offer:</span>
          {meObjectLinks.length > 0 && (
            <div className={styles.previouslyOffered}>
              <WarningTwoTone twoToneColor="#faad14" />
              <div className={styles.oldId}>
                {meObjectLinks
                  .map((value) => get(value, "me_record_id"))
                  .join(", ")}
              </div>
            </div>
          )}
        </div>
      </Col>
      <Col span={8} xs={24} sm={24} md={24} lg={24} xl={8}>
        <div className={styles.objectData}>
          <span>IDI person:</span>
          <div onClick={() => setOwnerEdit()}>
            <div className={styles.extraData}>
              <Input
                value={owner.last_idi_person_id}
                onChange={(e) => handleOwnerEditChange(e)}
                onPressEnter={(e) => {
                  handleOwnerEditSubmit();
                  e.target.blur();
                }}
                className={
                  isOwnerEdit ? styles.inputActive : styles.inputDisabled
                }
                name="last_idi_person_id"
                type="string"
                size="small"
                autoComplete="off"
              />
              {!isEmpty(owner.last_idi_person) && (
                <Link
                  to={`/idi-persons/${owner.last_idi_person_id}`}
                  className={styles.linkButton}
                >
                  <LinkOutlined />
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className={styles.objectData}>
          <span>USPS Address:</span>
          <div onClick={() => setOwnerEdit()}>
            <div className={styles.extraData}>
              <Input
                value={owner.last_usps_address_id}
                onChange={(e) => handleOwnerEditChange(e)}
                onPressEnter={(e) => {
                  handleOwnerEditSubmit();
                  e.target.blur();
                }}
                className={
                  isOwnerEdit ? styles.inputActive : styles.inputDisabled
                }
                name="last_usps_address_id"
                type="string"
                size="small"
                autoComplete="off"
              />
              {!isEmpty(owner.last_usps_address) && (
                <Link
                  to={`/usps-addresses/${owner.last_usps_address_id}`}
                  className={styles.linkButton}
                >
                  <LinkOutlined />
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className={styles.objectData}>
          <span>Mailing street:</span>
          <div onClick={() => setOwnerEdit()}>
            <Input
              value={owner.mailing_street}
              onChange={(e) => handleOwnerEditChange(e)}
              onPressEnter={(e) => {
                handleOwnerEditSubmit();
                e.target.blur();
              }}
              className={
                isOwnerEdit ? styles.inputActive : styles.inputDisabled
              }
              name="mailing_street"
              type="string"
              size="small"
              autoComplete="off"
            />
          </div>
        </div>
        <div className={styles.objectData}>
          <span>Mailing city:</span>
          <div onClick={() => setOwnerEdit()}>
            <Input
              value={owner.mailing_city}
              onChange={(e) => handleOwnerEditChange(e)}
              onPressEnter={(e) => {
                handleOwnerEditSubmit();
                e.target.blur();
              }}
              className={
                isOwnerEdit ? styles.inputActive : styles.inputDisabled
              }
              name="mailing_city"
              type="string"
              size="small"
              autoComplete="off"
            />
          </div>
        </div>
        <div className={styles.objectData}>
          <span>Mailing state:</span>
          <div onClick={() => setOwnerEdit()}>
            <Input
              value={owner.mailing_state}
              onChange={(e) => handleOwnerEditChange(e)}
              onPressEnter={(e) => {
                handleOwnerEditSubmit();
                e.target.blur();
              }}
              className={
                isOwnerEdit ? styles.inputActive : styles.inputDisabled
              }
              name="mailing_state"
              type="string"
              size="small"
              autoComplete="off"
            />
          </div>
        </div>
        <div className={styles.objectData}>
          <span>Mailing zip code:</span>
          <div onClick={() => setOwnerEdit()}>
            <Input
              value={owner.mailing_zip_code}
              onChange={(e) => handleOwnerEditChange(e)}
              onPressEnter={(e) => {
                handleOwnerEditSubmit();
                e.target.blur();
              }}
              className={
                isOwnerEdit ? styles.inputActive : styles.inputDisabled
              }
              name="mailing_zip_code"
              type="string"
              size="small"
              autoComplete="off"
            />
          </div>
        </div>
        <div className={styles.objectData}>
          <span>NTF:</span>
          <div onClick={() => setOwnerEdit()}>
            <Checkbox
              checked={owner.ntf}
              name="ntf"
              onChange={(e) => handleOwnerEditChange(e)}
            />
          </div>
        </div>
        <div className={styles.objectData}>
          <span>Unit Count:</span>
          {yearPropertyCount ? yearPropertyCount : owner.unit_count}
        </div>
        <div className={styles.objectData}>
          <span>Address unit count:</span>
          {owner.address_unit_count}
        </div>
        <div className={styles.objectData}>
          <span>Generics count:</span>
          {owner.generic_file_record_count}
        </div>
      </Col>
    </Row>
  );
};

export default OwnerEdit;
