import React from "react";
import { EnvironmentTwoTone } from "@ant-design/icons";
import { Tooltip } from "antd";
function MappedIcon() {
  return (
    <div className="App__ml-8">
      <Tooltip title="Mapped" placement="bottom">
        <EnvironmentTwoTone twoToneColor="#52c41a" />
      </Tooltip>
    </div>
  );
}

export default MappedIcon;
