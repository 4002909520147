import { get } from "lodash";

const databaseColumns = {
  owner_name: "owner_name",
  owner_name_formatted: "owner_name_formatted",
  owner_first_name: "owner_first_name",
  owner_middle_name: "owner_middle_name",
  owner_last_name: "owner_last_name",
  owner_name_ico: "owner_name_ico",
  owner_address: "owner_address",
  owner_address_formatted: "owner_address_formatted",
  owner_city: "owner_city",
  owner_state: "owner_state",
  owner_zip_code: "owner_zip_code",
  owner_ntf: "owner_ntf",
};

const columnOptions = [
  {
    label: "Owner name",
    value: databaseColumns.owner_name,
  },
  {
    label: "Owner name formatted",
    value: databaseColumns.owner_name_formatted,
  },
  {
    label: "Owner first name",
    value: databaseColumns.owner_first_name,
  },
  {
    label: "Owner middle name",
    value: databaseColumns.owner_middle_name,
  },
  {
    label: "Owner last name",
    value: databaseColumns.owner_last_name,
  },
  {
    label: "Owner name ICO",
    value: databaseColumns.owner_name_ico,
  },
  {
    label: "Owner address",
    value: databaseColumns.owner_address,
  },
  {
    label: "Owner address formatted",
    value: databaseColumns.owner_address_formatted,
  },
  {
    label: "Owner city",
    value: databaseColumns.owner_city,
  },
  {
    label: "Owner state",
    value: databaseColumns.owner_state,
  },
  {
    label: "Owner zip code",
    value: databaseColumns.owner_zip_code,
  },
  {
    label: "Owner NTF",
    value: databaseColumns.owner_ntf,
  },
];

const getInitialColumnMappings = (sampleObject) => {
  return Object.keys(sampleObject).map((sampleColumnName) => {
    const databaseColumnOption = columnOptions.find(
      (option) => option.value === sampleColumnName
    );

    return {
      file_column_name: sampleColumnName,
      file_example: get(sampleObject, sampleColumnName),
      database_column_name: get(databaseColumnOption, "value"),
    };
  });
};

export { columnOptions, getInitialColumnMappings };
