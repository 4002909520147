import { useState, useEffect } from "react";
import { pick, get } from "lodash";
import { apiBase } from "../../../../utils/apiBase";
import { formatDate } from "../../../../utils/dates";
import clsx from "clsx";

import AssociatedUnits from "./associations/AssociatedUnits";

import { Button, notification } from "antd";

import styles from "./BasePopup.module.scss";

const WellDetails = ({ currentFeature }) => {
  const { properties } = currentFeature;

  // This component doesn't need to fetch landtrac units
  // It's for well details, not landtrac units

  const [loading, setLoading] = useState(false);
  const [well, setWell] = useState(null);

  useEffect(() => {
    setLoading(true);
    apiBase
      .get(`/enverus-wells/${properties.id}`)
      .then((res) => {
        setWell(res.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [properties.id]);

  const openNotification = () => {
    notification.info({
      message: `Associated Units`,
      description: <AssociatedUnits apiUWI10={properties.api_uwi_10} />,
      placement: "topLeft",
      duration: 0,
      className: styles.notification,
      icon: <></>,
    });
  };

  return (
    <>
      <div
        className={clsx(styles.properties, {
          [styles.loading]: loading,
        })}
      >
        {Object.entries(
          pick(properties || well, [
            "id",
            "api_uwi_10",
            "api_uwi_14",
            "rrc_id",
            "name",
            "well_number",
            "type",
            "symbol",
            "status",
            "operator",
            "state",
            "county",
          ])
        ).map(([key, value]) => (
          <div className={styles.property} key={key}>
            <strong>{key}:</strong>
            <span className="App__ml-5">{value}</span>
          </div>
        ))}
        {well && (
          <>
            <div className={styles.property}>
              <strong>completion_date:</strong>
              <span className="App__ml-5">
                {formatDate(get(well, "data.CompletionDate"), "YYYY-MM-DD")}
              </span>
            </div>
            <div className={styles.property}>
              <strong>last_producing_month:</strong>
              <span className="App__ml-5">
                {formatDate(get(well, "data.LastProducingMonth"), "YYYY-MM-DD")}
              </span>
            </div>
          </>
        )}
      </div>
      <hr className={styles.separator} />
      <div className={styles.buttons}>
        <Button
          className={styles.unitsButton}
          type="secondary"
          size="small"
          onClick={() => openNotification()}
        >
          Associated Units
        </Button>
      </div>
    </>
  );
};
export default WellDetails;
