import React, { Component } from "react";
import { withParams } from "../utils/withParams";
import { Link } from "react-router-dom";
import { apiBase } from "../utils/apiBase";
import { formatNumber, alphaSort } from "../utils/numbers";
import { formatDate } from "../utils/dates";
import { get, capitalize, cloneDeep } from "lodash";

import {
  mailLetterType,
  mailLetterTypeTitle,
  mailLetterTypeColor,
} from "../common/mailLetterType";

import { NTFIcon, DeceasedIcon } from "../views/common/LabelIcon";
import { LabelIcon } from "./common/LabelIcon";
import { leadTypes } from "../common/mailExports";
import TotalRecords from "../views/search/TotalRecords";
import { DownloadOutlined } from "@ant-design/icons";

import { Row, Col, Table, Select, Button, Input, message } from "antd";

import styles from "./MailExportDetail.module.scss";
class MailExportDetail extends Component {
  state = {
    isLoading: false,
    mailExport: {},
    mailExportCopy: {},
    mailLetters: [],
    recordsPerPage: 100,
    isMailExportEdit: false,
    isLoadingMailExportEdit: false,
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    await Promise.all([this.getMailExport(), this.getMailLetters()]);
    this.setState({ isLoading: false });
  }

  async getMailExport() {
    return apiBase
      .get(`/mail-exports/${this.props.params.id}`)
      .then((res) => {
        this.setState({
          mailExport: res.data,
        });
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: Error loading mail export: ${
          this.props.params.id
        }`;
        message.error(msg);
      });
  }

  async getMailLetters() {
    return apiBase
      .get(`/mail-exports/${this.props.params.id}/mail-letters`)
      .then((res) => {
        this.setState({
          mailLetters: res.data
            .map((el, idx) => ({ ...el, ...{ key: idx } }))
            .sort((a, b) => a.id - b.id),
        });
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: Error loading mail export: ${
          this.props.params.id
        } letters`;
        message.error(msg);
      });
  }

  regenerateExport = () => {
    const { mailExport } = this.state;
    if (mailExport.status !== "compeleted") {
      this.setState({ isLoading: true });
      return apiBase
        .get(`/mail-exports/${this.props.params.id}/regenerate`)
        .then((res) => {
          this.setState({
            mailExport: res.data,
            isLoading: false,
          });
          message.success(
            "Successfully triggered regeneration of export. Please refresh to see progress"
          );
        })
        .catch((err) => {
          let msg = `${get(
            err,
            "response.status"
          )}: Error loading mail export: ${this.props.params.id}`;
          message.error(msg);
          this.setState({ isLoading: false });
        });
    }
  };

  regenerateCSVs = () => {
    const { mailExport } = this.state;
    if (mailExport.status !== "compeleted") {
      this.setState({ isLoading: true });
      return apiBase
        .get(`/mail-exports/${this.props.params.id}/regenerate-csvs`)
        .then((res) => {
          this.setState({
            mailExport: res.data,
            isLoading: false,
          });
          message.success(
            "Successfully triggered regeneration of csvs. Please refresh to see progress"
          );
        })
        .catch((err) => {
          let msg = `${get(
            err,
            "response.status"
          )}: Error loading mail export: ${this.props.params.id}`;
          message.error(msg);
          this.setState({ isLoading: false });
        });
    }
  };

  regenerateLabels = () => {
    const { mailExport } = this.state;
    if (mailExport.status !== "compeleted") {
      this.setState({ isLoading: true });
      return apiBase
        .get(`/mail-exports/${this.props.params.id}/regenerate-labels`)
        .then((res) => {
          this.setState({
            mailExport: res.data,
            isLoading: false,
          });
          message.success(
            "Successfully triggered regeneration of labels. Please refresh to see progress"
          );
        })
        .catch((err) => {
          let msg = `${get(
            err,
            "response.status"
          )}: Error loading mail export: ${this.props.params.id}`;
          message.error(msg);
          this.setState({ isLoading: false });
        });
    }
  };

  regenerateScores = () => {
    const { mailExport } = this.state;
    if (mailExport.status !== "compeleted") {
      this.setState({ isLoading: true });
      return apiBase
        .get(`/mail-exports/${this.props.params.id}/regenerate-scores`)
        .then((res) => {
          this.setState({
            mailExport: res.data,
            isLoading: false,
          });
          message.success(
            "Successfully triggered regeneration of labels. Please refresh to see progress"
          );
        })
        .catch((err) => {
          let msg = `${get(
            err,
            "response.status"
          )}: Error loading mail export: ${this.props.params.id}`;
          message.error(msg);
          this.setState({ isLoading: false });
        });
    }
  };

  createPipedriveUpload = () => {
    const { mailExport } = this.state;
    if (mailExport.status !== "compeleted") {
      this.setState({ isLoading: true });
      return apiBase
        .post(`/pipedrive-uploads`, { mail_export_id: mailExport.id })
        .then((res) => {
          const pipedriveUploadId = get(res, "data.id");
          this.props.history(`/pipedrive-uploads/${pipedriveUploadId}`);
        })
        .catch((err) => {
          let msg = `${get(
            err,
            "response.status"
          )}: Error creating pipedrive upload: ${this.props.params.id}`;
          message.error(msg);
          this.setState({ isLoading: false });
        });
    }
  };

  setMailExportEdit = () => {
    const { mailExport } = this.state;
    this.setState({
      isMailExportEdit: true,
      mailExportCopy: cloneDeep(mailExport),
    });
  };

  handleMailExportEditChange = (e) => {
    const { mailExport } = this.state;
    mailExport[e.target.name] = e.target.value;
    this.setState({ mailExport });
  };

  handleMailExportEditSubmit = () => {
    const { mailExport } = this.state;
    let mailExportEdit = {
      mail_export_id: parseInt(mailExport.id, 10),
      name: mailExport.name,
    };
    this.setState({ isLoadingMailExportEdit: true });
    apiBase
      .patch(`/mail-exports`, mailExportEdit)
      .then((res) => {
        this.setState({
          mailExport: res.data,
          isMailExportEdit: false,
          isLoadingMailExportEdit: false,
        });
        message.success("Successfully updated mail export");
      })
      .catch((err) => {
        this.setState({
          isLoadingMailExportEdit: false,
        });
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
      });
  };

  cancelMailExportEdit() {
    const { mailExportCopy } = this.state;
    this.setState({
      isMailExportEdit: false,
      mailExport: mailExportCopy,
    });
  }

  handleRowClick(e, _to) {
    e.stopPropagation();
    if (e.ctrlKey || e.metaKey) {
      window.open(_to, "_blank");
      return;
    }
    this.props.history(_to);
  }

  handleRecordsChange(value) {
    this.setState({
      recordsPerPage: value,
    });
  }

  render() {
    const {
      mailExport,
      mailLetters,
      isMailExportEdit,
      isLoadingMailExportEdit,
      isLoading,
    } = this.state;

    const columns = [
      {
        title: "Letter",
        dataIndex: "id",
        className: "App__rowLink",
        render: (value) => <div className="App__rowIcon">{value}</div>,
        onCell: (record) => ({
          onClick: (e) =>
            this.handleRowClick(e, `/mail-letters/${record["id"]}`),
        }),
        width: "70px",
        sorter: (a, b) => a.id - b.id,
      },
      {
        title: "Owner",
        dataIndex: "owner_id",
        className: "App__rowLink",
        render: (value) => <div className="App__rowIcon">{value}</div>,
        onCell: (record) => ({
          onClick: (e) =>
            this.handleRowClick(e, `/owners/${record["owner_id"]}`),
        }),
        width: "70px",
        sorter: (a, b) => a.owner_id - b.owner_id,
      },
      {
        title: "Letter URL",
        dataIndex: "download_url",
        className: `App__rowLink App__w12`,
        render: () => (
          <div className="App__rowIcon">
            <DownloadOutlined />
          </div>
        ),
        onCell: (record) => ({
          onClick: () => {
            window.open(record["download_url"], "_blank");
          },
        }),
      },
      {
        title: "Type",
        dataIndex: "type",
        sorter: (a, b) => alphaSort(a.type, b.type),
        render: (value) => (
          <LabelIcon
            content={mailLetterTypeTitle[value]}
            backgroundColor={mailLetterTypeColor[value]}
            color={value === mailLetterType.standard ? "black" : "white"}
          />
        ),
      },
      ...(mailExport.lead_type === leadTypes.secondary
        ? [
            {
              title: "Name",
              dataIndex: "name",
              sorter: (a, b) => alphaSort(a.name, b.name),
            },
            {
              title: "Name Attn",
              dataIndex: "name_attn",
              sorter: (a, b) => alphaSort(a.name_attn, b.name_attn),
            },
            {
              title: "Street",
              dataIndex: "street",
              sorter: (a, b) => alphaSort(a.street, b.street),
            },
            {
              title: "City",
              dataIndex: "city",
              sorter: (a, b) => alphaSort(a.city, b.city),
            },
            {
              title: "State",
              dataIndex: "state",
              sorter: (a, b) => alphaSort(a.state, b.state),
            },
            {
              title: "Zip",
              dataIndex: "zip_code",
              sorter: (a, b) => alphaSort(a.zip_code, b.zip_code),
            },
          ]
        : [
            {
              title: "Owner name formatted",
              dataIndex: ["owner", "name_formatted"],
              sorter: (a, b) =>
                alphaSort(a.owner.name_formatted, b.owner.name_formatted),
            },
            {
              title: "Owner mailing street",
              dataIndex: ["owner", "mailing_street"],
              sorter: (a, b) =>
                alphaSort(a.owner.mailing_street, b.owner.mailing_street),
            },
            {
              title: "Owner mailing city",
              dataIndex: ["owner", "mailing_city"],
              sorter: (a, b) =>
                alphaSort(a.owner.mailing_city, b.owner.mailing_city),
            },
            {
              title: "Owner mailing state",
              dataIndex: ["owner", "mailing_state"],
              sorter: (a, b) =>
                alphaSort(a.owner.mailing_state, b.owner.mailing_state),
            },
            {
              title: "Owner mailing zip",
              dataIndex: ["owner", "mailing_zip_code"],
              sorter: (a, b) =>
                alphaSort(a.owner.mailing_zip_code, b.owner.mailing_zip_code),
            },
          ]),
      {
        title: "Last IDI person id",
        dataIndex: ["owner", "last_idi_person_id"],
        render: (value) => <Link to={`/idi-persons/${value}`}>{value}</Link>,
        sorter: (a, b) =>
          a.owner.last_idi_person_id - b.owner.last_idi_person_id,
        width: 140,
      },
      {
        title: "Last USPS address id",
        dataIndex: ["owner", "last_usps_address_id"],
        render: (value) => <Link to={`/usps-addresses/${value}`}>{value}</Link>,
        sorter: (a, b) =>
          a.owner.last_usps_address_id - b.owner.last_usps_address_id,
        width: 160,
      },
      {
        title: "IDI deceased",
        dataIndex: ["owner", "last_idi_person", "deceased"],
        render: (value) => <DeceasedIcon value={value} />,
        sorter: (a, b) =>
          get(a, "owner.last_idi_person.deceased", false) -
          get(b, "owner.last_idi_person.deceased", false),
        width: 110,
      },
      {
        title: "NTF",
        dataIndex: ["owner", "ntf"],
        sorter: (a, b) => alphaSort(a.owner.ntf, b.owner.ntf),
        render: (value) => <NTFIcon value={value} />,
        width: 80,
      },
      {
        title: "Leads",
        dataIndex: "lead_count",
        sorter: (a, b) => a["leads"].length - b["leads"].length,
        render: (_, record) => {
          return record["leads"].length;
        },
        width: 80,
      },
      {
        title: "Total NRA",
        dataIndex: "total_nra",
        sorter: (a, b) =>
          a["leads"].reduce((acc, val) => (acc += val["nra"]), 0) -
          b["leads"].reduce((acc, val) => (acc += val["nra"]), 0),
        render: (_, record) => {
          return formatNumber(
            record["leads"].reduce((acc, val) => (acc += val["nra"]), 0) *
              (get(record, "fractional_ownership", 1) || 1),
            "0.00"
          );
        },
        width: 100,
      },
      {
        title: "Offer amount",
        dataIndex: "total_offer",
        sorter: (a, b) =>
          a["leads"].reduce((acc, val) => (acc += val["offer_amount"]), 0) -
          b["leads"].reduce((acc, val) => (acc += val["offer_amount"]), 0),
        render: (_, record) => {
          return `$${formatNumber(
            record["leads"].reduce(
              (acc, val) => (acc += val["offer_amount"]),
              0
            ) * (get(record, "fractional_ownership", 1) || 1),
            "0,0.00"
          )}`;
        },
        width: 110,
      },
    ];

    return (
      <div className="App__rowContentDetail">
        <Row>
          <Col span={24}>
            <div className={styles.card}>
              <div className={styles.cardTitle}>
                <div className={styles.leftTitle}>
                  <h2>Mail export</h2>
                </div>
                <div className={styles.rightTitle}>
                  <div>
                    <Button
                      onClick={this.createPipedriveUpload}
                      className={styles.cardHeaderAction}
                      disabled={
                        ["queued", "running"].includes(mailExport.status) ||
                        mailExport.last_pipedrive_upload_id ||
                        isLoading
                      }
                      loading={isLoading}
                    >
                      Create pipedrive upload
                    </Button>
                  </div>
                  <div>
                    <Button
                      onClick={this.regenerateScores}
                      className={styles.cardHeaderAction}
                      disabled={
                        ["queued", "running"].includes(mailExport.status) ||
                        isLoading
                      }
                      loading={isLoading}
                    >
                      Regenerate scores
                    </Button>
                  </div>
                  <div>
                    <Button
                      onClick={this.regenerateLabels}
                      className={styles.cardHeaderAction}
                      disabled={
                        ["queued", "running"].includes(mailExport.status) ||
                        isLoading
                      }
                      loading={isLoading}
                    >
                      Regenerate labels
                    </Button>
                  </div>
                  <div>
                    <Button
                      onClick={this.regenerateCSVs}
                      className={styles.cardHeaderAction}
                      disabled={
                        ["queued", "running"].includes(mailExport.status) ||
                        isLoading
                      }
                      loading={isLoading}
                    >
                      Regenerate csvs
                    </Button>
                  </div>
                  <div>
                    <Button
                      onClick={this.regenerateExport}
                      className={styles.cardHeaderAction}
                      disabled={
                        ["queued", "running"].includes(mailExport.status) ||
                        isLoading
                      }
                      loading={isLoading}
                    >
                      Regenerate all
                    </Button>
                  </div>
                  {!isMailExportEdit ? (
                    <div>
                      <Button
                        onClick={() => this.setMailExportEdit()}
                        className={styles.cardHeaderAction}
                      >
                        Edit
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <Button
                        onClick={() => this.cancelMailExportEdit()}
                        className={styles.cardHeaderAction}
                        disabled={isLoadingMailExportEdit}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => this.handleMailExportEditSubmit()}
                        className={styles.cardHeaderAction}
                        type="primary"
                        loading={isLoadingMailExportEdit}
                      >
                        Save
                      </Button>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.cardBody}>
                <Row gutter={24}>
                  <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className={styles.objectData}>
                      <span>Id:</span>
                      {mailExport.id}
                    </div>
                    <div className={styles.objectData}>
                      <span>Name:</span>
                      <div onClick={() => this.setMailExportEdit()}>
                        <Input
                          value={mailExport.name}
                          onChange={(e) => this.handleMailExportEditChange(e)}
                          onPressEnter={(e) => {
                            this.handleMailExportEditSubmit();
                            e.target.blur();
                          }}
                          className={
                            isMailExportEdit
                              ? styles.inputActive
                              : styles.inputDisabled
                          }
                          disabled={isLoadingMailExportEdit}
                          name="name"
                          type="string"
                          size="small"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className={styles.objectData}>
                      <span>Mail template:</span>
                      <div
                        className={styles.objectLink}
                        onClick={(e) => {
                          this.handleRowClick(
                            e,
                            `/settings/mail-templates/${mailExport.mail_template_id}`
                          );
                        }}
                      >
                        {mailExport.mail_template_id}
                      </div>
                    </div>
                    <div className={styles.objectData}>
                      <span>Labels mail template:</span>
                      <div
                        className={styles.objectLink}
                        onClick={(e) => {
                          this.handleRowClick(
                            e,
                            `/settings/mail-templates/${mailExport.labels_mail_template_id}`
                          );
                        }}
                      >
                        {mailExport.labels_mail_template_id}
                      </div>
                    </div>
                    <div className={styles.objectData}>
                      <span>Last pipedrive upload:</span>
                      <div
                        className={styles.objectLink}
                        onClick={(e) => {
                          this.handleRowClick(
                            e,
                            `/pipedrive-uploads/${mailExport.last_pipedrive_upload_id}`
                          );
                        }}
                      >
                        {mailExport.last_pipedrive_upload_id}
                      </div>
                    </div>
                    <div className={styles.objectData}>
                      <span>Lead type:</span>
                      {capitalize(mailExport.lead_type).replace("_", " ")}
                    </div>
                    <div
                      className={`${styles.objectData} ${styles.linkNoColor}`}
                    >
                      <span>Created By:</span>
                      <Link
                        to={`/settings/users/${get(mailExport, "user.id")}`}
                      >
                        {get(mailExport, "user.alias")}
                      </Link>
                    </div>
                    {[
                      { key: "leads_url", title: "Leads (.csv)" },
                      { key: "owners_url", title: "Owners (.csv)" },
                      { key: "combined_pdf_url", title: "Letters (.pdf)" },
                      { key: "labels_pdf_url", title: "Labels (.pdf)" },
                    ].map((object) => {
                      return (
                        <div className={styles.objectData} key={object.key}>
                          <span>{object.title}:</span>
                          <div
                            className={styles.objectLink}
                            onClick={() => {
                              if (mailExport.status !== "completed") return;
                              window.open(mailExport[object.key], "_blank");
                            }}
                          >
                            {mailExport[object.key] && <DownloadOutlined />}
                          </div>
                        </div>
                      );
                    })}
                  </Col>
                  <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className={styles.objectData}>
                      <span>Status:</span>
                      {capitalize(mailExport.status)}
                    </div>
                    <div className={styles.objectData}>
                      <span>Progress:</span>
                      {`${formatNumber(mailExport.progress, "0.00")}%`}
                    </div>
                    <div className={styles.objectData}>
                      <span>Lead count:</span>
                      {mailExport.lead_count}
                    </div>
                    <div className={styles.objectData}>
                      <span>Owner count:</span>
                      {mailExport.owner_letter_count}
                    </div>
                    <div className={styles.objectData}>
                      <span>Letter success count:</span>
                      {mailExport.owner_letter_success_count}
                    </div>
                    <div className={styles.objectData}>
                      <span>Letter fail count:</span>
                      {mailExport.owner_letter_fail_count}
                    </div>
                    <div className={styles.objectData}>
                      <span>Runtime (s):</span>
                      {mailExport.runtime_seconds}
                    </div>
                    <div className={styles.objectData}>
                      <span>Created At:</span>
                      {formatDate(
                        mailExport.created_at,
                        "YYYY-MM-DD, h:mm:ss A"
                      )}
                    </div>
                    <div className={styles.objectData}>
                      <span>Updated At:</span>
                      {formatDate(
                        mailExport.updated_at,
                        "YYYY-MM-DD, h:mm:ss A"
                      )}
                    </div>
                    <div className={styles.objectData}>
                      <span>Started At:</span>
                      {formatDate(
                        mailExport.started_at,
                        "YYYY-MM-DD, h:mm:ss A"
                      )}
                    </div>
                    <div className={styles.objectData}>
                      <span>Stopped At:</span>
                      {formatDate(
                        mailExport.stopped_at,
                        "YYYY-MM-DD, h:mm:ss A"
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className={styles.card}>
              <div className={styles.cardTitle}>
                <div className={styles.leftTitle}>
                  <h2>Letters</h2>
                </div>
                <div className={styles.rightTitle}>
                  <Select
                    value={this.state.recordsPerPage}
                    onChange={this.handleRecordsChange.bind(this)}
                    className={"App__mr-5"}
                  >
                    <Select.Option value={50}>50</Select.Option>
                    <Select.Option value={100}>100</Select.Option>
                    <Select.Option value={1000}>1000</Select.Option>
                  </Select>
                  <TotalRecords
                    title={"Letters"}
                    totalRecords={mailLetters.length}
                  />
                </div>
              </div>
              <div className={styles.cardBody}>
                <Table
                  columns={columns}
                  dataSource={mailLetters}
                  pagination={{
                    pageSize: this.state.recordsPerPage,
                    showSizeChanger: false,
                  }}
                  className="App__smallTables"
                  showSorterTooltip={false}
                  loading={this.state.isLoading}
                  scroll={{ x: "100%" }}
                  bordered
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withParams(MailExportDetail);
