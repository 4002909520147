import React from "react";
import { Modal, Alert, Form, Input } from "antd";

const CreateIDIBulkSearch = (props) => {
  const { visible, isLoading, onCancel, onCreate, selectedLeads } = props;

  const [form] = Form.useForm();

  const title =
    selectedLeads.length === 0
      ? `Create IDI bulk search - based on search filters (max 1000 leads)`
      : `Create IDI bulk search - ${selectedLeads.length} selected leads`;

  return (
    <Modal
      title={title}
      okText="Create"
      cancelText="Cancel"
      visible={visible}
      onCancel={onCancel}
      cancelButtonProps={{ disabled: isLoading }}
      okButtonProps={{ disabled: isLoading, loading: isLoading }}
      maskClosable={false}
      width={600}
      onOk={() => {
        form
          .validateFields()
          .then(async (values) => {
            await onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          })
          .finally(() => {
            form.resetFields();
          });
      }}
    >
      <Alert
        message={
          "Make sure that you have either selected the leads you want to search or you are very confident about your search filters. This will search all records selected or for the current search filters using up an IDI search credit for each owner record."
        }
        type="warning"
        style={{ marginBottom: 24 }}
      />
      <Form
        form={form}
        name="createIDIBulkSearch"
        layout={"vertical"}
        autoComplete="off"
        disabled={isLoading}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "A name is required",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateIDIBulkSearch;
