import React, { useState, useRef } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { sumBy, unionBy, isEmpty, get } from "lodash";
import { apiBase } from "../../utils/apiBase";
import { formatNumber, humanFileSize, guid } from "../../utils/numbers";
import moment from "moment";

import SearchSelect from "../common/SearchSelect";

import {
  Modal,
  Form,
  Upload,
  Progress,
  InputNumber,
  Button,
  DatePicker,
  Table,
  Tooltip,
  Checkbox,
  message,
} from "antd";

import {
  PlusOutlined,
  InboxOutlined,
  MinusCircleOutlined,
  CheckCircleTwoTone,
  DeleteOutlined,
  SyncOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

import EntitySelect from "../common/EntitySelect";

import styles from "./RoyaltyCreateForm.module.scss";

const mapStateToProps = (state) => ({
  entity: get(state.entity, "data", {}),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const RoyaltyCreateForm = (props) => {
  const { entity } = props;

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [validationError, setValidationError] = useState(null);

  const [progress, setProgress] = useState(null);
  const [uploadingUID, setUploadingUID] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [fileSizeLoaded, setFileSizeLoaded] = useState(false);
  const [fileSizeTotal, setFileSizeTotal] = useState(false);

  const axiosSource = useRef();

  const [entityId, setEntityId] = useState(entity.id);
  const [wellAssets, setWellAssets] = useState({});
  const [allocations, setAllocations] = useState({});
  const [amountDiff, setAmountDiff] = useState(0);

  const onFinish = async (values) => {
    setLoading(true);
    apiBase
      .post(`royalties`, {
        ...values,
      })
      .then(async (res) => {
        message.success("Successfully created royalty.");
        resetComponent();
        setIsModalOpen(false);
        props.executeSearch();
      })
      .catch((err) => {
        let msg = `${err.response.status}: ${get(
          err,
          "response.data.message",
          "Failed to create royalty."
        )}`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    resetComponent();
    // setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    resetComponent();
  };

  const validateAggregates = (values) => {
    let _validationError = null;
    if (Math.abs(parseFloat(amountDiff).toFixed(2)) > 0.01) {
      _validationError =
        "The sum of the well allocations must equal the total amount.";
    }

    if (
      unionBy(get(values, "lines", []), "well_id").length !==
      get(values, "lines", []).length
    ) {
      _validationError =
        "The well allocations should be unique. There are duplicate wells.";
    }

    if (_validationError) {
      message.warning("There are validation errors.");
    }

    setValidationError(_validationError);
    return _validationError;
  };

  const submitForm = () => {
    form
      .validateFields()
      .then((values) => {
        const _validationError = validateAggregates(values);
        if (_validationError) {
          return;
        }

        form.submit();
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };

  const resetComponent = () => {
    form.resetFields();
  };

  const getWellAssets = async (wellId) => {
    try {
      const res = await apiBase.get(`/wells/${wellId}/assets`);
      const wellAssets = get(res, "data", []);
      return wellAssets.filter((wa) => wa.entity_id === entityId);
    } catch (err) {
      let msg = `${err.response.status}: Failed to load well assets ${wellId}`;
      message.error(msg);
    }
  };

  const syncWellAssets = async (lines) => {
    const wellIds = new Set();

    // Add any well assets that are not already added to the wellAssets object
    const linesWithWellIds = lines.filter((l) => l?.well_id);
    for (const line of linesWithWellIds) {
      wellIds.add(line["well_id"]);
      if (!Object.keys(wellAssets).includes(String(line["well_id"]))) {
        const wellAssetsId = await getWellAssets(line["well_id"]);
        wellAssets[line["well_id"]] = wellAssetsId;
      }
    }

    // Remove any well assets that are not in the wellAssets object
    for (const wellId of Object.keys(wellAssets)) {
      if (!wellIds.has(parseInt(wellId))) {
        delete wellAssets[wellId];
      }
    }

    setWellAssets(wellAssets);
  };

  const updateAllocations = async (lines) => {
    const wellIds = new Set();

    const linesWithData = lines.filter((l) => l?.well_id && l?.amount);
    for (const line of linesWithData) {
      wellIds.add(line["well_id"]);

      let remainingAmount = line["amount"];

      const _wellAssets = wellAssets[line["well_id"]] || [];
      allocations[line["well_id"]] = _wellAssets.map((wa, index, array) => {
        const isLastWa = index === array.length - 1;
        let amount;

        if (isLastWa && get(wa, "revenue_allocation", 0) > 0) {
          amount = remainingAmount;
        } else {
          amount =
            Math.round(
              line["amount"] * get(wa, "revenue_allocation", 0) * 100
            ) / 100;
          remainingAmount -= amount;
        }

        return {
          asset_name: get(wa, "asset.name"),
          revenue_allocation: get(wa, "revenue_allocation", 0),
          amount: amount,
        };
      });
    }

    // Remove any well assets that are not in the wellAssets object
    for (const wellId of Object.keys(allocations)) {
      if (!wellIds.has(parseInt(wellId))) {
        delete allocations[wellId];
      }
    }

    setAllocations(allocations);
  };

  const onFormChange = async (changedValues, allValues) => {
    const { entity_id, amount, statutory_interest_amount, lines } = allValues;

    if (validationError) {
      setValidationError(null);
    }

    if (entity_id !== entityId) {
      setEntityId(entity_id);
      setWellAssets({});
    }

    // Sync well allocations
    await syncWellAssets(lines);

    // Perform allocation calculations
    updateAllocations(lines);

    // Calculate amount difference
    const totalAllocated = sumBy(lines, "amount");
    setAmountDiff(amount - statutory_interest_amount - totalAllocated);
  };

  const createUploadUrl = (values) => {
    return apiBase
      .post(`files/upload-url`, values)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to create upload url`;
        message.error(msg);
        return false;
      });
  };

  const uploadProgress = (e) => {
    setProgress(Math.floor((e.loaded / e.total) * 100));
    setFileSizeLoaded(humanFileSize(e.loaded, true, 2));
    setFileSizeTotal(humanFileSize(e.total, true, 2));
  };

  const uploadFile = async (options) => {
    const { onSuccess, onError, file } = options;

    if (file.uid !== uploadingUID && uploadingUID !== null) {
      axiosSource.current.cancel();
    }

    axiosSource.current = axios.CancelToken.source();

    const name = get(file, "name");
    form.setFieldValue("name", name);

    setFileName(name);
    setUploadingUID(file.id);
    setProgress(0);

    // Upload URL
    const s3Key = `royalties/${guid()}_${name}`;
    const response = await createUploadUrl({ key: s3Key });
    if (response === false) {
      return onError();
    }
    const uploadUrl = response.data;
    file.s3Key = uploadUrl.s3_key_full;
    delete uploadUrl.s3_key_full;

    // Form Data
    const formData = new FormData();
    Object.keys(uploadUrl.fields).forEach((key) => {
      formData.append(key, uploadUrl.fields[key]);
    });
    formData.append("file", file);

    const postConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: uploadProgress,
      cancelToken: axiosSource.current.token,
    };

    try {
      await axios.post(uploadUrl.url, formData, postConfig);
      form.setFieldsValue({ file });
      onSuccess({ file });
    } catch (err) {
      if (!axios.isCancel(err)) {
        onError();
        return;
      }
    }
  };

  const resetFileField = () => {
    setFileName(null);
    setUploadingUID(null);
    setProgress(null);
    setFileSizeLoaded(null);
    setFileSizeTotal(null);
  };

  const cancelUpload = (e) => {
    e.preventDefault();
    if (axiosSource.current) {
      axiosSource.current.cancel();
    }
    form.setFieldsValue({ file: null });
    resetFileField();
  };

  const beforeUpload = (file) => {
    // const isDelimited = file.type === "text/csv" || file.type === "text/tsv";
    // if (!isDelimited) {
    //   message.error("This isn't a delimited file");
    // }

    const isLt100Mb = file.size / 1024 / 1024 < 100;
    if (!isLt100Mb) {
      message.error("File must me smaller than 100Mb");
    }

    return isLt100Mb;
  };

  const [form] = Form.useForm();

  return (
    <div>
      <Button type="secondary" icon={<PlusOutlined />} onClick={showModal} />
      <Modal
        title="Create royalty"
        width={720}
        visible={isModalOpen}
        onOk={() => submitForm()}
        onCancel={handleCancel}
        cancelButtonProps={{ disabled: loading }}
        okButtonProps={{ disabled: loading, loading: loading }}
        maskClosable={false}
      >
        <Form
          name="royaltyCreate"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onFormChange}
          autoComplete="off"
          layout="vertical"
          disabled={loading}
          initialValues={{
            entity_id: entity.id,
            date: moment(),
            lines: [],
            statutory_interest_amount: 0,
          }}
        >
          <Form.Item
            label="Entity"
            name="entity_id"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <EntitySelect />
          </Form.Item>
          <Form.Item
            label="Operator"
            name="operator_id"
            rules={[{ required: true, message: "" }]}
          >
            <SearchSelect
              searchUri="operators"
              searchField="name"
              placeholder="Search operators"
            />
          </Form.Item>
          <div className={styles.twoColumns}>
            <Form.Item label="Distributions account">
              {get(entity, "quickbooks_distributions_account.Name", "")}
            </Form.Item>
            <Form.Item
              label="Use alternate distributions"
              name="qbo_distributions_use_alternate"
              rules={[{ required: false, message: "" }]}
              valuePropName="checked"
            >
              <Checkbox
                disabled={isEmpty(
                  get(entity, "quickbooks_distributions_account_alternate.Name")
                )}
              >
                {get(
                  entity,
                  "quickbooks_distributions_account_alternate.Name",
                  ""
                )}
              </Checkbox>
            </Form.Item>
          </div>
          <div className={styles.twoColumns}>
            <Form.Item
              label={
                <span>
                  Total check amount&nbsp;
                  <Tooltip
                    title="This is the total check amount. This number is used to validate the other amounts inputted into the form."
                    placement="rightTop"
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="amount"
              rules={[
                {
                  required: true,
                  message: `Amount is required.`,
                },
              ]}
            >
              <InputNumber
                className="App__fullWidth"
                precision={2}
                prefix={"$"}
              />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  Statutory interest amount&nbsp;
                  <Tooltip
                    title="This amount is the interest amount on the check. If there is no interest just leave this as 0.00."
                    placement="rightTop"
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="statutory_interest_amount"
              rules={[
                {
                  required: true,
                  message: `Statutory interest is required.`,
                },
              ]}
            >
              <InputNumber
                className="App__fullWidth"
                precision={2}
                prefix={"$"}
              />
            </Form.Item>
          </div>
          <Form.Item
            label="Effective date"
            name="date"
            rules={[
              {
                required: true,
                message: "Date is required.",
              },
            ]}
          >
            <DatePicker
              className="App__fullWidth"
              format={[
                "M/DD/YY",
                "M/D/YY",
                "MM/D/YY",
                "M/DD/YYYY",
                "M/D/YYYY",
                "MM/D/YYYY",
              ]}
            />
          </Form.Item>
          <Form.Item
            label="File"
            name="file"
            rules={[
              {
                required: false,
                message: "",
              },
            ]}
          >
            <>
              <div className={progress !== null ? styles.hide : null}>
                <Upload.Dragger
                  name="config"
                  customRequest={uploadFile}
                  beforeUpload={beforeUpload}
                  multiple={false}
                  maxCount={1}
                  listType="picture"
                  showUploadList={false}
                >
                  <>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                    <p className="ant-upload-hint">Formats ('.pdf')</p>
                    <p className="ant-upload-hint">Max file size: 100Mb</p>
                  </>
                </Upload.Dragger>
              </div>
              {progress !== null && (
                <div className={styles.fileContainer}>
                  <div className={styles.iconContainer}>
                    {progress < 100 ? (
                      <SyncOutlined className={styles.icon} spin />
                    ) : (
                      <CheckCircleTwoTone
                        twoToneColor="#52c41a"
                        className={styles.icon}
                      />
                    )}
                  </div>
                  <div className={styles.file}>
                    <div className={styles.spaced}>
                      <div>{`${fileName}`}</div>
                      <div>
                        <Button
                          onClick={(e) => cancelUpload(e)}
                          icon={<DeleteOutlined />}
                          size={"small"}
                          disabled={loading}
                        />
                      </div>
                    </div>
                    <Progress percent={progress} showInfo={false} />
                    <div className={`${styles.spaced} ${styles.gray}`}>
                      {fileSizeLoaded && fileSizeTotal ? (
                        <div>{`${fileSizeLoaded} of ${fileSizeTotal}`}</div>
                      ) : (
                        <div>Loading...</div>
                      )}
                      <div>{`${progress}%`}</div>
                    </div>
                  </div>
                </div>
              )}
            </>
          </Form.Item>
          <div className={styles.section}>
            <div className={styles.sectionHeading}>Well allocations</div>
            <Form.Item shouldUpdate={true} noStyle>
              {({ getFieldValue }) => {
                const lines = getFieldValue("lines");
                return (
                  <Form.List name="lines">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          {fields.map(({ key, name, ...restField }) => {
                            const wellId = get(
                              lines,
                              `[${name}].well_id`,
                              null
                            );

                            const wellAllocations = get(
                              allocations,
                              `${wellId}`,
                              []
                            ).map((wa, idx) => ({ ...wa, key: idx }));

                            return (
                              <div key={key}>
                                <div className={styles.line}>
                                  <Form.Item
                                    {...restField}
                                    className={styles.childWell}
                                    name={[name, "well_id"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Missing well",
                                      },
                                    ]}
                                  >
                                    <SearchSelect
                                      searchUri="wells"
                                      searchField="name"
                                      placeholder="Search wells"
                                    />
                                  </Form.Item>

                                  <Form.Item
                                    {...restField}
                                    className={styles.childAmount}
                                    name={[name, "amount"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Missing amount",
                                      },
                                    ]}
                                  >
                                    <InputNumber
                                      precision={2}
                                      prefix={"$"}
                                      placeholder="0.00"
                                    />
                                  </Form.Item>
                                  <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                  />
                                </div>
                                <div className={styles.allocationTable}>
                                  {wellAllocations.length > 0 && (
                                    <Table
                                      pagination={false}
                                      size="small"
                                      dataSource={wellAllocations}
                                      columns={[
                                        {
                                          key: "asset_name",
                                          dataIndex: "asset_name",
                                          title: "Asset name",
                                        },
                                        {
                                          key: "revenue_allocation",
                                          dataIndex: "revenue_allocation",
                                          title: "Revenue allocation",
                                          render: (value) =>
                                            value !== null
                                              ? `${formatNumber(
                                                  value * 100,
                                                  "0.00"
                                                )}%`
                                              : null,
                                        },
                                        {
                                          key: "amount",
                                          dataIndex: "amount",
                                          title: "Amount",
                                          width: 200,
                                          render: (value) =>
                                            value !== null
                                              ? `$${formatNumber(
                                                  value,
                                                  "0,0.00"
                                                )}`
                                              : null,
                                        },
                                      ]}
                                    />
                                  )}
                                </div>
                              </div>
                            );
                          })}
                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                              disabled={!entityId}
                            >
                              Add well
                            </Button>
                          </Form.Item>
                          <div className={styles.section}>
                            <div>Amount difference</div>
                            <div
                              className={
                                Math.abs(amountDiff) > 0.01 ? styles.error : ""
                              }
                            >{`$${formatNumber(amountDiff, "0,0.00")}`}</div>
                          </div>
                        </>
                      );
                    }}
                  </Form.List>
                );
              }}
            </Form.Item>
            {validationError && (
              <div className={styles.section}>
                <div className={styles.wellAllocationsSection}>
                  Validation errors:
                </div>
                <div className={styles.error}>{validationError}</div>
              </div>
            )}
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RoyaltyCreateForm);
