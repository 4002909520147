import React, { useState } from "react";

import BaseSearch from "../search/BaseSearch";
import Took from "../search/Took";
import TotalRecords from "../search/TotalRecords";
import { columnTypes } from "../../utils/search";
import { arrayContainsOperators } from "../search/constants";
import { formatDate } from "../../utils/dates";
import { apiBase } from "../../utils/apiBase";
import ExportForm from "../forms/ExportForm";
import ChangeColumnsForm from "../forms/ChangeColumnsForm";
import FilterTags from "../search/FilterTags";

import {
  Row,
  Col,
  Table,
  Select,
  Button,
  Tooltip,
  Pagination,
  message,
} from "antd";

import {
  DownloadOutlined,
  TableOutlined,
  EnvironmentTwoTone,
  EditOutlined,
} from "@ant-design/icons";

import RecordLabels from "../common/RecordLabels";
import PermitPlatBulkEditForm from "./PermitPlatBulkEditForm";

const baseColumnState = [
  {
    dataIndex: "id",
    title: "Id",
    editable: false,
    type: columnTypes.LINK,
    baseUrl: "/permit-plats",
    sortable: true,
  },
  {
    dataIndex: "geom_polygon",
    title: "Mapped",
    type: columnTypes.SELECT,
    options: [
      { value: "false", label: "False" },
      { value: "true", label: "True" },
    ],
    className: `App__w10`,
    selectClassName: `App__w10`,
    format: ({ record }) => {
      return record.geom_polygon ? (
        <div className="App__tableIcon">
          <EnvironmentTwoTone twoToneColor="#52c41a" />
        </div>
      ) : null;
    },
  },
  {
    title: "Labels",
    dataIndex: ["label_ids"],
    className: `App__w40`,
    exportable: false,
    type: columnTypes.SELECT_MULTI,
    globalStateKey: "labels.data",
    globalStateOptions: (data) => {
      const recordLabels = data
        .filter((d) => d.record_type === "permit_plat")
        .map((d) => ({ value: String(d.id), label: d.value }));
      recordLabels.push({ value: "-1", label: "No Label" });
      return recordLabels;
    },
    operatorOptions: arrayContainsOperators,
    format: ({ record }) => {
      return (
        <RecordLabels
          record_type="permit_plat"
          record_id={record.id}
          label_ids={record.label_ids}
        />
      );
    },
  },
  {
    dataIndex: "county",
    title: "County",
    type: columnTypes.STRING,
    // className: `App__w14`,
  },
  {
    dataIndex: "name",
    title: "Name",
    type: columnTypes.STRING,
  },
  {
    dataIndex: "permit_count",
    title: "Permit count",
    type: columnTypes.INTEGER,
  },
  {
    dataIndex: "unit_count",
    title: "Unit count",
    type: columnTypes.INTEGER,
  },
  {
    title: "Created at",
    dataIndex: "created_at",
    type: columnTypes.DATE_TIME,
    format: (value) => formatDate(value, "YYYY-MM-DD"),
    className: `App__w10`,
  },
  {
    title: "Updated at",
    dataIndex: "updated_at",
    type: columnTypes.DATE_TIME,
    format: (value) => formatDate(value, "YYYY-MM-DD"),
    className: `App__w10`,
  },
];

const PermitPlats = (props) => {
  const {
    isLoadingRecords,
    isLoadingCount,
    records,
    columnState,
    limit,
    page,
    totalRecords,
    tookRecords,
    tookCount,
    searchFields,
    isExportModalVisible,
    isChangeColumnsVisible,
    isLoadingExport,
    executeSearch,
    location,
  } = props;

  const {
    handleSearch,
    handleRecordsChange,
    handlePaginationChange,
    handleFilterClose,
    handleTableChange,
    handleSubmitExport,
    showExportModal,
    handleCancelExport,
    handleSubmitColumnChange,
    showColumnChangeModal,
    handleCancelColumnsChange,
    exportFormRef,
    changeColumnFormRef,
    getColumnProps,
  } = props;

  const [selectedPermitPlats, setSelectedPermitPlats] = useState([]);
  const [selectedPermitPlatKeys, setSelectedPermitPlatKeys] = useState([]);
  const [isBulkEditModalVisible, setIsBulkEditModalVisible] = useState(false);
  const [isLoadingBulkEdit, setIsLoadingBulkEdit] = useState(false);

  let columns = columnState
    .map((column, idx) => ({
      key: idx,
      ...column,
      ...getColumnProps(column),
    }))
    .filter((col) => col.visible === true);

  const onSelectPermitPlatChange = (selectedRowKeys, selectedRows) => {
    setSelectedPermitPlatKeys(selectedRowKeys);
    setSelectedPermitPlats(selectedRows);
  };

  const showBulkEditModal = () => {
    setIsBulkEditModalVisible(true);
  };

  const handleCancelBulkEdit = () => {
    setIsBulkEditModalVisible(false);
  };

  const handleSubmitBulkEdit = (values) => {
    setIsLoadingBulkEdit(true);

    let data = {
      update_values: values,
      permit_plat_ids: selectedPermitPlats.map((row) => row.id),
    };

    const queryString = location.search;

    apiBase
      .patch(`/permit-plats${queryString}`, data)
      .then((res) => {
        executeSearch();
        setIsBulkEditModalVisible(false);
        setIsLoadingBulkEdit(false);
        setSelectedPermitPlats([]);
        setSelectedPermitPlatKeys([]);
        let msg = `${res.status}: ${res.data.message}`;
        message.success(msg);
      })
      .catch((err) => {
        let msg = `${err.response.status}: ${err.response.data.message}`;
        message.error(msg);
        setIsBulkEditModalVisible(false);
        setIsLoadingBulkEdit(false);
        setSelectedPermitPlats([]);
        setSelectedPermitPlatKeys([]);
      });
  };

  const rowSelection = {
    selectedRowKeys: selectedPermitPlatKeys,
    onChange: onSelectPermitPlatChange,
  };

  return (
    <div>
      <Row className="App__rowContent">
        <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16}>
          <Button className="App__mr-5" onClick={() => handleSearch()}>
            Search
          </Button>
          <FilterTags
            searchFields={searchFields}
            columnState={columnState}
            handleFilterClose={handleFilterClose}
          />
        </Col>
        <Col
          span={8}
          xs={24}
          sm={24}
          md={8}
          lg={8}
          xl={8}
          className="App__rightActions"
        >
          <Tooltip placement="bottomRight" title={"Bulk edit"}>
            <Button onClick={showBulkEditModal} className="App__iconButton">
              <EditOutlined />
            </Button>
          </Tooltip>
          <PermitPlatBulkEditForm
            visible={isBulkEditModalVisible}
            isLoading={isLoadingBulkEdit}
            onCancel={handleCancelBulkEdit}
            onCreate={handleSubmitBulkEdit}
            selectedPermitPlats={selectedPermitPlats}
          />
          <Tooltip placement="bottomRight" title={"Export search results"}>
            <Button className="App__iconButton" onClick={showExportModal}>
              <DownloadOutlined />
            </Button>
          </Tooltip>
          <ExportForm
            wrappedComponentRef={exportFormRef}
            visible={isExportModalVisible}
            isLoading={isLoadingExport}
            onCancel={handleCancelExport}
            onCreate={handleSubmitExport}
            totalRecords={totalRecords}
          />
          <Tooltip placement="bottomRight" title={"Change visible columns"}>
            <Button onClick={showColumnChangeModal} className="App__iconButton">
              <TableOutlined />
            </Button>
          </Tooltip>
          <ChangeColumnsForm
            wrappedComponentRef={changeColumnFormRef}
            visible={isChangeColumnsVisible}
            isLoading={isLoadingRecords}
            onCancel={handleCancelColumnsChange}
            onOk={handleSubmitColumnChange}
            columnState={columnState}
          />
          <Select
            value={limit}
            onChange={handleRecordsChange}
            className={"App__mr-5"}
          >
            <Select.Option value={25}>25</Select.Option>
            <Select.Option value={50}>50</Select.Option>
            <Select.Option value={100}>100</Select.Option>
          </Select>
          <TotalRecords
            title={"Permit Plats"}
            isLoadingCount={isLoadingCount}
            totalRecords={totalRecords}
          />
        </Col>
      </Row>
      <Row className="App__rowContent">
        <Col span={24}>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={records}
            onChange={handleTableChange}
            showSorterTooltip={false}
            pagination={false}
            className="App__smallTables"
            loading={isLoadingRecords || isLoadingBulkEdit}
            scroll={{ x: "100%" }}
            size="small"
            bordered
          />
          <div className="App__searchFooterContainer">
            <Took
              isLoadingRecords={isLoadingRecords}
              isLoadingCount={isLoadingCount}
              tookRecords={tookRecords}
              tookCount={tookCount}
            />
            <Pagination
              current={page}
              pageSize={limit}
              total={totalRecords}
              onChange={handlePaginationChange}
              className="App__pagination"
              showSizeChanger={false}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

const PermitPlatsWithSearch = (props) => (
  <BaseSearch
    baseColumnState={baseColumnState}
    localSortColumns={[]}
    searchUri="permit-plats"
  >
    <PermitPlats {...props} />
  </BaseSearch>
);

export default PermitPlatsWithSearch;
