import { useState, useEffect } from "react";
import { get } from "lodash";
import { apiBase } from "../../../../../utils/apiBase";
import { formatNumber, alphaSort } from "../../../../../utils/numbers";

import { Table, Tooltip } from "antd";

const AssociatedUnits = ({ apiUWI10 }) => {
  const [loading, setLoading] = useState(false);
  const [associatedUnits, setAssociatedUnits] = useState([]);

  useEffect(() => {
    setLoading(true);
    apiBase
      .get(`/units/${apiUWI10}/associated-units`)
      .then((res) => {
        const sortedUnits = get(res, "data.units", []).sort(
          (a, b) => (b.tax_year || 0) - (a.tax_year || 0)
        );
        setAssociatedUnits(sortedUnits);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [apiUWI10]);

  const handleRowClick = (e, _to) => {
    e.stopPropagation();
    if (e.ctrlKey || e.metaKey) {
      window.open(_to, "_blank");
      return;
    }
    window.location.href = _to;
  };

  return (
    <>
      <Table
        dataSource={associatedUnits}
        rowKey={"id"}
        columns={[
          {
            title: "Id",
            dataIndex: "id",
            className: `App__rowLink`,
            width: 80,
            render: (value) => <div className="App__rowIcon">{value}</div>,
            onCell: (record) => ({
              onClick: (e) => handleRowClick(e, `/units/${record["id"]}`),
            }),
            sortDirections: ["descend", "ascend"],
            sorter: (a, b) => a.id - b.id,
          },
          {
            title: "Name",
            dataIndex: "name",
            ellipsis: {
              showTitle: false,
            },
            render: (name) => (
              <Tooltip placement="topLeft" title={name}>
                {name}
              </Tooltip>
            ),
            sortDirections: ["descend", "ascend"],
            sorter: (a, b) => alphaSort(a.name, b.name),
          },
          {
            title: "RRC (#)",
            dataIndex: "rrc_id",
            width: 80,
            sortDirections: ["descend", "ascend"],
            sorter: (a, b) => alphaSort(a.rrc_id, b.rrc_id),
          },
          // {
          //   title: "County",
          //   dataIndex: "county",
          //   sortDirections: ["descend", "ascend"],
          //   sorter: (a, b) => alphaSort(a.county, b.county),
          // },
          {
            title: "Tax year",
            dataIndex: "tax_year",
            width: 80,
            sortDirections: ["descend", "ascend"],
            sorter: (a, b) => a.tax_year - b.tax_year,
          },
          {
            title: "Owner count",
            dataIndex: "unique_owner_count",
            width: 100,
            sortDirections: ["descend", "ascend"],
            sorter: (a, b) => a.unique_owner_count - b.unique_owner_count,
          },
          {
            title: "Cum DOI",
            dataIndex: "cumulative_decimal_interest",
            width: 100,
            render: (value) =>
              value !== null ? formatNumber(value, "0.000000") : null,
            sortDirections: ["descend", "ascend"],
            sorter: (a, b) =>
              a.cumulative_decimal_interest - b.cumulative_decimal_interest,
          },
          {
            title: "Offer count",
            dataIndex: "offer_count",
            width: 100,
            sortDirections: ["descend", "ascend"],
            sorter: (a, b) => a.offer_count - b.offer_count,
          },
        ]}
        loading={loading}
        className="App__smallTables"
        size="small"
        pagination={false}
        showSorterTooltip={false}
        scroll={{ y: 800 }}
        bordered
      />
    </>
  );
};

export default AssociatedUnits;
