import React, { Component } from "react";
import { formatDate } from "../../utils/dates";
import { columnTypes } from "../../utils/search";
import { capitalize } from "lodash";

import BaseSearch from "../search/BaseSearch";
import FilterTags from "../search/FilterTags";
import Took from "../search/Took";
import TotalRecords from "../search/TotalRecords";
import { formatNumber } from "../../utils/numbers";
import { uploadStatusOptions } from "../../common/pipedriveUploads";

import ReactJson from "react-json-view";
import { DatabaseOutlined } from "@ant-design/icons";

import { Row, Col, Table, Select, Button, Pagination, Tooltip } from "antd";

import styles from "./PipedriveUploads.module.scss";

const baseColumnState = [
  {
    dataIndex: "id",
    title: "Id",
    editable: false,
    type: columnTypes.LINK,
    baseUrl: "/pipedrive-uploads",
  },
  {
    dataIndex: "mail_export_id",
    title: "Mail export",
    editable: false,
    type: columnTypes.LINK,
    baseUrl: "/mail-exports",
    className: `App__rowLink App__w8`,
  },
  {
    dataIndex: "name",
    title: "Name",
    type: columnTypes.STRING,
  },
  {
    dataIndex: "status",
    title: "Status",
    type: columnTypes.SELECT,
    options: uploadStatusOptions,
    format: ({ value }) => capitalize(value),
  },
  {
    dataIndex: ["data", "progress"],
    title: "Progress",
    type: columnTypes.INTEGER,
    format: (value) => `${formatNumber(value, "0.00")}%`,
  },
  {
    key: "runtime_seconds",
    dataIndex: "runtime_seconds",
    title: "Runtime (s)",
    className: `App__w10`,
    children: [
      {
        key: "runtime_seconds",
        dataIndex: "runtime_seconds",
        title: () => <div />,
        render: (value) => value,
      },
    ],
  },
  {
    dataIndex: ["data", "total"],
    title: "Total",
    className: `App__w10`,
    children: [
      {
        key: "total",
        dataIndex: ["data", "total"],
        title: () => <div />,
        render: (value) => value,
      },
    ],
  },
  {
    dataIndex: ["data", "processed"],
    title: "Processed",
    className: `App__w10`,
    children: [
      {
        key: "processed",
        dataIndex: ["data", "processed"],
        title: () => <div />,
        render: (value) => value,
      },
    ],
  },
  {
    dataIndex: ["data", "created"],
    title: "Created",
    className: `App__w10`,
    children: [
      {
        key: "created",
        dataIndex: ["data", "created"],
        title: () => <div />,
        render: (value) => value,
      },
    ],
  },
  {
    dataIndex: ["data", "updated"],
    title: "Updated",
    className: `App__w10`,
    children: [
      {
        key: "updated",
        dataIndex: ["data", "updated"],
        title: () => <div />,
        render: (value) => value,
      },
    ],
  },
  {
    dataIndex: ["data", "failed"],
    title: "Failed",
    className: `App__w10`,
    children: [
      {
        key: "failed",
        dataIndex: ["data", "failed"],
        title: () => <div />,
        render: (value) => value,
      },
    ],
  },
  {
    key: "data",
    dataIndex: "data",
    title: "Upload data",
    className: `App__w10`,
    children: [
      {
        dataIndex: "data",
        title: () => <div />,
        render: (value) => (
          <Tooltip
            overlayClassName={styles.toolTipCard}
            placement="bottom"
            title={
              <ReactJson
                src={value}
                collapsed={false}
                style={{
                  backgroundColor: "white",
                  width: "484px",
                  fontSize: "12px",
                }}
                displayDataTypes={false}
              />
            }
          >
            <DatabaseOutlined />
          </Tooltip>
        ),
      },
    ],
  },
  {
    title: "Started at",
    dataIndex: "started_at",
    type: columnTypes.DATE_TIME,
    format: (value) => formatDate(value, "YYYY-MM-DD, h:mm:ss A"),
    className: `App__w20`,
  },
  {
    title: "Stopped at",
    dataIndex: "stopped_at",
    type: columnTypes.DATE_TIME,
    format: (value) => formatDate(value, "YYYY-MM-DD, h:mm:ss A"),
    className: `App__w20`,
  },
];

class PipedriveUploads extends Component {
  render() {
    const {
      isLoadingRecords,
      isLoadingCount,
      records,
      columnState,
      limit,
      page,
      totalRecords,
      tookRecords,
      tookCount,
      searchFields,
    } = this.props;

    const {
      handleSearch,
      handleRecordsChange,
      handlePaginationChange,
      handleFilterClose,
      handleTableChange,
      getColumnProps,
    } = this.props;

    let columns = columnState
      .map((column, idx) => ({
        key: idx,
        ...column,
        ...getColumnProps(column),
      }))
      .filter((col) => col.visible === true);

    return (
      <div>
        <Row className="App__rowContent">
          <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16}>
            <Button className="App__mr-5" onClick={() => handleSearch()}>
              Search
            </Button>
            <FilterTags
              searchFields={searchFields}
              columnState={columnState}
              handleFilterClose={handleFilterClose}
            />
          </Col>
          <Col
            span={8}
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            className="App__rightActions"
          >
            <Select
              value={limit}
              onChange={handleRecordsChange}
              className={"App__mr-5"}
            >
              <Select.Option value={25}>25</Select.Option>
              <Select.Option value={50}>50</Select.Option>
              <Select.Option value={100}>100</Select.Option>
            </Select>
            <TotalRecords
              title={"Pipedrive uploads"}
              isLoadingCount={isLoadingCount}
              totalRecords={totalRecords}
            />
          </Col>
        </Row>
        <Row className="App__rowContent">
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={records}
              onChange={handleTableChange}
              pagination={false}
              showSorterTooltip={false}
              className="App__smallTables"
              loading={isLoadingRecords}
              scroll={{ x: "100%" }}
              size="small"
              bordered
            />
            <div className="App__searchFooterContainer">
              <Took
                isLoadingRecords={isLoadingRecords}
                isLoadingCount={isLoadingCount}
                tookRecords={tookRecords}
                tookCount={tookCount}
              />
              <Pagination
                current={page}
                pageSize={limit}
                total={totalRecords}
                onChange={handlePaginationChange}
                className="App__pagination"
                showSizeChanger={false}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const PipedriveUploadsWithSearch = (props) => (
  <BaseSearch
    baseColumnState={baseColumnState}
    localSortColumns={[]}
    searchUri="pipedrive-uploads"
  >
    <PipedriveUploads {...props} />
  </BaseSearch>
);

export default PipedriveUploadsWithSearch;
