import React, { useMemo, useRef, useState } from "react";
import debounce from "lodash/debounce";
import { get } from "lodash";
import { apiBase } from "../../utils/apiBase";
import { stringify } from "query-string";

import { Select, Spin } from "antd";

import styles from "./SearchSelect.module.scss";

async function fetchData({ searchUri, query }) {
  const queryString = stringify({
    limit: query.limit ? parseInt(query.limit, 10) : 10,
    offset: query.offset ? parseInt(query.offset, 10) : 0,
    query,
  });

  return apiBase.get(`${searchUri}?${queryString}`).then((res) => {
    return get(res, `data`, []).map((row) => {
      return {
        value: row.id,
        label: (
          <div className={styles.optionSimple}>
            <div className={styles.main}>{row.name}</div>
            <div className={styles.extra}>{row.id}</div>
          </div>
        ),
      };
    });
  });
}

function BoxSearchSelect({
  debounceTimeout = 400,
  fetchOptions = fetchData,
  searchUri = "box/folder/search",
  defaultOptions = [],
  ...props
}) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState(defaultOptions);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (query) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions({ searchUri, query }).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout, searchUri]);

  return (
    <Select
      placeholder="Select items"
      className="App__fullWidth"
      // dropdownStyle={{ height: "600px" }}
      listHeight={400}
      allowClear
      showSearch
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
}

export default BoxSearchSelect;
