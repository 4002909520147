import React, { Component } from "react";
import { Navigate, Link } from "react-router-dom";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Input, Button } from "antd";

import styles from "./Login.module.scss";

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: "",
      isLoading: false,
    };
  }

  componentDidMount() {
    // To disabled submit button at the beginning.
    this.props.form.validateFields();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({ isLoading: true });
        const result = await this.props.loginUser(values);
        if (!result) {
          this.setState({ isLoading: false });
        }
      }
    });
  };

  hasErrors = (fieldsError) => {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  };

  render() {
    const { getFieldDecorator, isFieldTouched, getFieldError, getFieldsError } =
      this.props.form;

    const emailError = isFieldTouched("email") && getFieldError("email");
    const passwordError =
      isFieldTouched("password") && getFieldError("password");

    if (this.props.isAuthenticated === true) {
      return <Navigate to={`/units?limit=50&page=1`} />;
    } else {
      return (
        <div className={styles.mainLogin}>
          <div className={styles.loginSvgImage}>
            <div className={styles.loginLogo} />
          </div>
          <Form
            layout="vertical"
            onSubmit={this.handleSubmit}
            className={styles.loginForm}
          >
            <Form.Item
              label="Email"
              validateStatus={emailError ? "error" : ""}
              help={emailError || ""}
            >
              {getFieldDecorator("email", {
                rules: [
                  {
                    type: "email",
                    required: true,
                    message: "Please enter a valid email.",
                  },
                ],
              })(
                <Input
                  prefix={<MailOutlined />}
                  placeholder="admin@mineralengine.com"
                  size="large"
                />
              )}
            </Form.Item>
            <Form.Item
              label="Password"
              validateStatus={passwordError ? "error" : ""}
              help={passwordError || ""}
            >
              {getFieldDecorator("password", {
                rules: [
                  { required: true, message: "Please input your password." },
                ],
              })(
                <Input prefix={<LockOutlined />} type="password" size="large" />
              )}
            </Form.Item>
            <div>
              <Button
                type="primary"
                htmlType="submit"
                className={styles.loginFormButton}
                size="large"
                disabled={this.hasErrors(getFieldsError())}
                loading={this.state.isLoading}
              >
                Log In
              </Button>
              <div className={styles.loginFooter}>
                <Link to="/forgot-password" className={styles.forgotPassword}>
                  Forgot password
                </Link>
              </div>
            </div>
          </Form>
        </div>
      );
    }
  }
}

const Login = Form.create({ name: "login" })(LoginForm);

export default Login;
