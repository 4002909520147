import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { get } from "lodash";

import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import BaseDetails from "./details/BasePopup";
import WellDetails from "./details/WellPopup";
import LandtracUnitDetails from "./details/LandtracUnitPopup";
import UnitDetails from "./details/UnitPopup";
import PermitPlatPopup from "./details/PermitPlatPopup";
import AssetDetails from "./details/AssetPopup";
import TractDetails from "./details/TractPopup";

import { WELL_LAYERS } from "../constants";

import { CLICKABLE_LAYERS as UNITS_CLICKABLE_LAYERS } from "../layers/units";
import { CLICKABLE_LAYERS as ASSETS_CLICKABLE_LAYERS } from "../layers/assets";
import { CLICKABLE_LAYERS as LANDTRAC_UNITS_CLICKABLE_LAYERS } from "../layers/landtracUnits";
import { CLICKABLE_LAYERS as PERMIT_PLATS_CLICKABLE_LAYERS } from "../layers/permitPlats";
import { CLICKABLE_LAYERS as TRACTS_CLICKABLE_LAYERS } from "../layers/tracts";
import { setHighlightFilters } from "../layers";

import styles from "./Popup.module.scss";

const Popup = ({ mapRef, features, onClose }) => {
  const [featureIndex, setFeatureIndex] = useState(0);

  const currentFeature = features[featureIndex];
  const layerId = get(currentFeature, "layer.id");
  const properties = get(currentFeature, "properties", {});

  const isWell = WELL_LAYERS.includes(layerId);
  const isUnit = UNITS_CLICKABLE_LAYERS.includes(layerId);
  const isAsset = ASSETS_CLICKABLE_LAYERS.includes(layerId);
  const isLandtracUnit = LANDTRAC_UNITS_CLICKABLE_LAYERS.includes(layerId);
  const isPermitPlat = PERMIT_PLATS_CLICKABLE_LAYERS.includes(layerId);
  const isTract = TRACTS_CLICKABLE_LAYERS.includes(layerId);
  const renderHeader = () => {
    if (isWell) {
      return "WELL";
    } else if (isLandtracUnit) {
      return "LANDTRAC UNIT";
    } else if (isUnit) {
      return "UNIT";
    } else if (isAsset) {
      return "ASSET";
    } else if (isPermitPlat) {
      return "PERMIT PLAT";
    } else if (isTract) {
      return "TRACT";
    }
  };

  useEffect(() => {
    if (!mapRef?.current) return;

    setHighlightFilters(mapRef, layerId, properties.id);
  }, [mapRef, layerId, properties.id]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>{renderHeader()}</div>
        <div className={styles.rightAction} onClick={onClose}>
          <CloseOutlined />
        </div>
      </div>

      <div className={styles.body}>
        {isWell ? (
          <WellDetails currentFeature={currentFeature} />
        ) : isLandtracUnit ? (
          <LandtracUnitDetails currentFeature={currentFeature} />
        ) : isUnit ? (
          <UnitDetails currentFeature={currentFeature} />
        ) : isAsset ? (
          <AssetDetails currentFeature={currentFeature} />
        ) : isPermitPlat ? (
          <PermitPlatPopup currentFeature={currentFeature} />
        ) : isTract ? (
          <TractDetails currentFeature={currentFeature} />
        ) : (
          <BaseDetails currentFeature={currentFeature} />
        )}
      </div>

      <div className={styles.footer}>
        <Button
          onClick={() => setFeatureIndex((prev) => Math.max(0, prev - 1))}
          disabled={featureIndex === 0}
          type="primary"
          className={styles.button}
          size="small"
        >
          <ArrowLeftOutlined />
        </Button>
        <span>
          Record: {featureIndex + 1} of {features.length}
        </span>
        <Button
          onClick={() =>
            setFeatureIndex((prev) => Math.min(features.length - 1, prev + 1))
          }
          disabled={featureIndex === features.length - 1}
          type="primary"
          className={styles.button}
          size="small"
        >
          <ArrowRightOutlined />
        </Button>
      </div>
    </div>
  );
};

export default Popup;
