import React, { Component } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import { formatNumber } from "../../utils/numbers";
import { formatDate } from "../../utils/dates";
import { columnTypes } from "../../utils/search";

import BaseSearch from "../search/BaseSearch";
import FilterTags from "../search/FilterTags";
import Took from "../search/Took";
import TotalRecords from "../search/TotalRecords";

import { DownloadOutlined, TableOutlined } from "@ant-design/icons";

import RoyaltyCreateForm from "./RoyaltyCreateForm";
import ExportForm from "../forms/ExportForm";
import ChangeColumnsForm from "../forms/ChangeColumnsForm";

import { Row, Col, Table, Select, Button, Tooltip, Pagination } from "antd";

const mapStateToProps = (state) => ({
  entities: get(state.entities, "data", []),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const baseColumnState = [
  {
    dataIndex: "id",
    title: "Royalty",
    editable: false,
    type: columnTypes.LINK,
    baseUrl: "/portfolio/royalties",
    sortable: true,
  },
  {
    dataIndex: "entity_id",
    title: "Entity",
    className: `App__w14`,
    type: columnTypes.SELECT,
    globalStateKey: "entities.data",
    globalStateOptions: (data) =>
      data.map((d) => ({ value: d.id, label: d.name })),
  },
  {
    title: "Date",
    dataIndex: "date",
    type: columnTypes.DATE_TIME,
    format: (value) => formatDate(value, "YYYY-MM-DD"),
    className: `App__w14`,
  },
  {
    title: "Amount",
    dataIndex: "amount",
    type: columnTypes.INTEGER,
    format: (value) => `$${formatNumber(value, "0,0.00")}`,
    className: `App__w14`,
  },
  {
    title: "Statutory Interest",
    dataIndex: "statutory_interest_amount",
    type: columnTypes.INTEGER,
    format: (value) => `$${formatNumber(value, "0,0.00")}`,
    className: `App__w14`,
  },
  {
    title: "Operator",
    dataIndex: ["operator", "name"],
    type: columnTypes.STRING,
  },
  {
    title: "QBO Id",
    dataIndex: "qbo_id",
    type: columnTypes.STRING,
    format: (value) => value || "-",
  },
  {
    title: "Created at",
    dataIndex: "created_at",
    type: columnTypes.DATE_TIME,
    format: (value) => formatDate(value, "YYYY-MM-DD"),
    className: `App__w14`,
  },
];

class Royalties extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      isLoadingRecords,
      isLoadingCount,
      records,
      columnState,
      limit,
      page,
      totalRecords,
      tookRecords,
      tookCount,
      searchFields,
      isExportModalVisible,
      isChangeColumnsVisible,
      isLoadingExport,
    } = this.props;

    const {
      handleSearch,
      handleRecordsChange,
      handlePaginationChange,
      handleFilterClose,
      handleTableChange,
      handleSubmitExport,
      showExportModal,
      handleCancelExport,
      handleSubmitColumnChange,
      showColumnChangeModal,
      handleCancelColumnsChange,
      exportFormRef,
      changeColumnFormRef,
      getColumnProps,
      executeSearch,
    } = this.props;

    let columns = columnState
      .map((column, idx) => ({
        key: idx,
        ...column,
        ...getColumnProps(column),
      }))
      .filter((col) => col.visible === true);

    return (
      <div>
        <Row className="App__rowContent">
          <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16}>
            <Button className="App__mr-5" onClick={() => handleSearch()}>
              Search
            </Button>
            <FilterTags
              searchFields={searchFields}
              columnState={columnState}
              handleFilterClose={handleFilterClose}
            />
          </Col>
          <Col
            span={8}
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            className="App__rightActions"
          >
            <div className="App__mr-5">
              <RoyaltyCreateForm executeSearch={executeSearch} />
            </div>
            <Tooltip placement="bottomRight" title={"Export search results"}>
              <Button className="App__iconButton" onClick={showExportModal}>
                <DownloadOutlined />
              </Button>
            </Tooltip>
            <ExportForm
              wrappedComponentRef={exportFormRef}
              visible={isExportModalVisible}
              isLoading={isLoadingExport}
              onCancel={handleCancelExport}
              onCreate={handleSubmitExport}
              totalRecords={totalRecords}
            />
            <Tooltip placement="bottomRight" title={"Change visible columns"}>
              <Button
                onClick={showColumnChangeModal}
                className="App__iconButton"
              >
                <TableOutlined />
              </Button>
            </Tooltip>
            <ChangeColumnsForm
              wrappedComponentRef={changeColumnFormRef}
              visible={isChangeColumnsVisible}
              isLoading={isLoadingRecords}
              onCancel={handleCancelColumnsChange}
              onOk={handleSubmitColumnChange}
              columnState={columnState}
            />
            <Select
              value={limit}
              onChange={handleRecordsChange}
              className={"App__mr-5"}
            >
              <Select.Option value={25}>25</Select.Option>
              <Select.Option value={50}>50</Select.Option>
              <Select.Option value={100}>100</Select.Option>
            </Select>
            <TotalRecords
              title={"Royaltys"}
              isLoadingCount={isLoadingCount}
              totalRecords={totalRecords}
            />
          </Col>
        </Row>
        <Row className="App__rowContent">
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={records}
              onChange={handleTableChange}
              showSorterTooltip={false}
              pagination={false}
              className="App__smallTables"
              loading={isLoadingRecords}
              scroll={{ x: "100%" }}
              size="small"
              bordered
            />
            <div className="App__searchFooterContainer">
              <Took
                isLoadingRecords={isLoadingRecords}
                isLoadingCount={isLoadingCount}
                tookRecords={tookRecords}
                tookCount={tookCount}
              />
              <Pagination
                current={page}
                pageSize={limit}
                total={totalRecords}
                onChange={handlePaginationChange}
                className="App__pagination"
                showSizeChanger={false}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const RoyaltysWithSearch = (props) => {
  return (
    <BaseSearch
      baseColumnState={baseColumnState}
      localSortColumns={[]}
      searchUri="royalties"
      baseUri="/portfolio"
    >
      <Royalties {...props} />
    </BaseSearch>
  );
};

// export default RoyaltysWithSearch;
export default connect(mapStateToProps, mapDispatchToProps)(RoyaltysWithSearch);
