// LAYER_ID,
// BORDER_HIGHLIGHT_LAYER_ID,
// API_BASE_ENDPOINT,

const LAYER_ID = "wells";

const SOURCE_ID = "enverus_well_annotations";

const LAYER_BHL_ID = "Bottoms";
const LAYER_SHL_ID = "Surface";
const LAYER_LATERAL_ID = "Laterals";
const LAYER_ANNOTATION_ID = "well_annotations";

const FILTER_LAYER_IDS = [LAYER_BHL_ID, LAYER_SHL_ID, LAYER_LATERAL_ID];
const CLICKABLE_LAYERS = [LAYER_BHL_ID, LAYER_SHL_ID, LAYER_LATERAL_ID];

const API_BASE_ENDPOINT = "enverus-wells";

const INITIAL_FILTER_STATE = {
  state: {
    column: "state",
    label: "State",
    value: [],
  },
  county: {
    column: "county",
    label: "County",
    value: [],
  },
  name: {
    column: "name",
    label: "Name",
    value: [],
  },
  api_uwi_10: {
    column: "api_uwi_10",
    label: "API 10",
    value: [],
  },
  rrc_id: {
    column: "rrc_id",
    label: "RRC ID",
    value: [],
  },
  status: {
    column: "status",
    label: "Status",
    value: [],
  },
};

const ADD_BEFORE = "Bottoms";

const ANNOTATION_STYLES = {
  id: LAYER_ANNOTATION_ID,
  minzoom: 13,
  layout: {
    "text-size": 10,
    "text-font": ["Prompt Regular", "Arial Unicode MS Regular"],
    "text-padding": 0,
    "text-offset": [0, 1.5],
    "text-field": ["to-string", ["get", "name"]],
    "text-allow-overlap": false,
    "text-ignore-placement": false,
    "text-justify": "auto",
    "text-variable-anchor": ["top", "bottom"],
    "symbol-sort-key": ["get", "id"],
    visibility: "visible",
  },
  type: "symbol",
  source: "enverus_well_annotations",
  "source-layer": "enverus_well_annotations",
  paint: {
    "text-halo-color": "#ffffff",
    "text-halo-width": 1,
    "symbol-z-offset": 0,
  },
};

const addSource = (mapRef) => {
  if (!mapRef.current.getSource(SOURCE_ID)) {
    mapRef.current.addSource(SOURCE_ID, {
      type: "vector",
      url: "mapbox://gthompson2.enverus_well_annotations",
      minzoom: 10,
      maxzoom: 14,
    });
  }
};

const addLayers = (mapRef) => {
  if (!mapRef.current.getLayer(LAYER_ANNOTATION_ID)) {
    mapRef.current.addLayer(ANNOTATION_STYLES, ADD_BEFORE);
  }
};

const addAll = (mapRef) => {
  addSource(mapRef);
  addLayers(mapRef);
};

const setAnnotationVisibility = (action) => {
  const { mapRef, value } = action;

  if (!mapRef || !mapRef.current) return;

  const map = mapRef.current;

  if (map.getLayer(LAYER_ANNOTATION_ID)) {
    const visibility = value ? "visible" : "none";
    const textVariableAnchor = value ? ["top", "bottom"] : ["bottom"];
    map.setLayoutProperty(LAYER_ANNOTATION_ID, "visibility", visibility);
    map.setLayoutProperty(
      LAYER_ANNOTATION_ID,
      "text-variable-anchor",
      textVariableAnchor
    );
  }
};

export {
  LAYER_ID,
  LAYER_ANNOTATION_ID,
  FILTER_LAYER_IDS,
  CLICKABLE_LAYERS,
  API_BASE_ENDPOINT,
  INITIAL_FILTER_STATE,
  addAll,
  setAnnotationVisibility,
};
