import axios from "axios";
import { get } from "lodash";

const TIMEOUT = 30000; // 30 seconds

const apiBase = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/api`,
  timeout: process.env.NODE_ENV === "development" ? TIMEOUT * 10 : 30000,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
  },
});

apiBase.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (get(error, "response.status") === 503) {
      window.localStorage.clear();
      window.location.href = "/maintanence";
    }
    return Promise.reject(error);
  }
);

export { apiBase };
