import { capitalize } from "lodash";

const status = {
  queued: "queued",
  running: "running",
  completed: "completed",
  warning: "warning",
  failed: "failed",
};

const statusOptions = Object.keys(status).map((key) => ({
  value: key,
  label: capitalize(key),
}));

export { status, statusOptions };
