import { apiBase } from "../../utils/apiBase";
import { nonEmptyString } from "../../utils/strings";
import { message } from "antd";

const updateLead = async (oldLead, newLead) => {
  if (JSON.stringify(oldLead) !== JSON.stringify(newLead)) {
    let leadEdit = {
      offer_amount: newLead.offer_amount,
      nra: newLead.nra,
      interest_type: newLead.interest_type,
      decimal_interest: newLead.decimal_interest,
      status: newLead.status,
      user_id: newLead.user_id,
    };

    Object.keys(leadEdit).forEach((key) => {
      if (typeof leadEdit[key] === "string") {
        leadEdit[key] = nonEmptyString(leadEdit[key]);
      }
    });

    return apiBase
      .patch(`/leads/${newLead.id}`, leadEdit)
      .then((res) => {
        let msg = `${res.status}: Successfully updated Lead.`;
        message.success(msg);
      })
      .catch((err) => {
        let msg = `${err.response.status}: ${err.response.data.message}`;
        message.error(msg);
      });
  }
};

const updateOwner = async (oldOwner, newOwner) => {
  if (JSON.stringify(oldOwner) !== JSON.stringify(newOwner)) {
    let ownerEdit = {
      owner_id: newOwner.id,
      name_formatted: newOwner.name_formatted,
      first_name: newOwner.first_name,
      middle_name: newOwner.middle_name,
      last_name: newOwner.last_name,
      address_formatted: newOwner.address_formatted,
      city: newOwner.city,
      state: newOwner.state,
      zip_code: newOwner.zip_code,
      ntf: newOwner.ntf || false,
      last_idi_person_id: newOwner.last_idi_person_id,
      last_usps_address_id: newOwner.last_usps_address_id,
    };

    Object.keys(ownerEdit).forEach((key) => {
      if (typeof ownerEdit[key] === "string") {
        ownerEdit[key] = nonEmptyString(ownerEdit[key]);
      }
    });

    return apiBase
      .patch(`/owners`, ownerEdit)
      .then((res) => {
        let msg = `${res.status}: Successfully updated Owner.`;
        message.success(msg);
      })
      .catch((err) => {
        let msg = `${err.response.status}: ${err.response.data.message}`;
        message.error(msg);
      });
  }
};

const updateUnit = async (oldUnit, newUnit) => {
  if (JSON.stringify(oldUnit) !== JSON.stringify(newUnit)) {
    let unitEdit = {
      unit_id: newUnit.id,
      name_formatted: newUnit.name_formatted,
      operator: newUnit.operator,
      acres: newUnit.acres,
    };
    return apiBase
      .patch(`/units`, unitEdit)
      .then((res) => {
        let msg = `${res.status}: Successfully updated Unit.`;
        message.success(msg);
      })
      .catch((err) => {
        let msg = `${err.response.status}: ${err.response.data.message}`;
        message.error(msg);
      });
  }
};

export { updateLead, updateOwner, updateUnit };
