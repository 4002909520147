const moment = require("moment");

const formatDate = (value, format, utc = false) => {
  if (value === null || value === undefined) {
    return undefined;
  } else {
    if (utc) {
      return moment.utc(new Date(value)).format(format);
    } else {
      return moment(new Date(value)).format(format);
    }
  }
};

export { formatDate };
