import React, { Component } from "react";
import { withParams } from "../utils/withParams";
import { Link } from "react-router-dom";
import { apiBase } from "../utils/apiBase";
import { formatNumber, alphaSort } from "../utils/numbers";
import { formatDate } from "../utils/dates";
import { get, capitalize, cloneDeep } from "lodash";

import { NTFIcon } from "./common/LabelIcon";
import TotalRecords from "./search/TotalRecords";

import { Row, Col, Table, Select, message, Input, Button } from "antd";

import styles from "./USPSBulkSearchDetail.module.scss";

const renderAddress = (record, type) => {
  let street, city, state, zip_code;
  if (type === "owner") {
    street = get(record, "owner.address_formatted", "");
    city = get(record, "owner.city", "");
    state = get(record, "owner.state", "");
    zip_code = get(record, "owner.zip_code", "");
  } else if (type === "idi_person") {
    street = get(record, "owner.last_idi_person.street", "");
    city = get(record, "owner.last_idi_person.city", "");
    state = get(record, "owner.last_idi_person.state", "");
    zip_code = get(record, "owner.last_idi_person.zip_code", "");
  } else if (type === "usps_address") {
    street = get(record, "owner.last_usps_address.street", "");
    city = get(record, "owner.last_usps_address.city", "");
    state = get(record, "owner.last_usps_address.state", "");
    zip_code = get(record, "owner.last_usps_address.zip_code", "");
  } else if (type === "mailing") {
    street = get(record, "owner.mailing_street", "");
    city = get(record, "owner.mailing_city", "");
    state = get(record, "owner.mailing_state", "");
    zip_code = get(record, "owner.mailing_zip_code", "");
  }

  if (!!street && !!city && !!state && !!zip_code) {
    return (
      <div>
        <div>{street}</div>
        <div>{`${city}, ${state} ${zip_code}`}</div>
      </div>
    );
  } else {
    return <div>-</div>;
  }
};

class USPSBulkSearchDetail extends Component {
  state = {
    isLoading: false,
    uspsBulkSearch: {},
    uspsBulkSearchOwners: [],
    recordsPerPage: 1000,
    isUspsBulkSearchEdit: false,
    isLoadingUspsBulkSearchEdit: false,
    uspsBulkSearchCopy: {},
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    await Promise.all([
      this.getUspsBulkSearch(),
      this.getUspsBulkSearchOwners(),
    ]);
    this.setState({ isLoading: false });
  }

  async getUspsBulkSearch() {
    return apiBase
      .get(`/usps-bulk-searches/${this.props.params.id}`)
      .then((res) => {
        this.setState({
          uspsBulkSearch: res.data,
        });
      })
      .catch((err) => {
        let msg = `${get(
          err,
          "response.status"
        )}: Error loading USPS bulk search: ${this.props.params.id}`;
        message.error(msg);
      });
  }

  async getUspsBulkSearchOwners() {
    return apiBase
      .get(`/usps-bulk-searches/${this.props.params.id}/owners`)
      .then((res) => {
        this.setState({
          uspsBulkSearchOwners: res.data
            .map((el, idx) => ({ ...el, ...{ key: idx } }))
            .sort((a, b) => a.id - b.id),
        });
      })
      .catch((err) => {
        let msg = `${get(
          err,
          "response.status"
        )}: Error loading USPS bulk search: ${this.props.params.id} owners`;
        message.error(msg);
      });
  }

  handleRowClick(e, _to) {
    e.stopPropagation();
    if (e.ctrlKey || e.metaKey) {
      window.open(_to, "_blank");
      return;
    }
    this.props.history(_to);
  }

  handleRecordsChange(value) {
    this.setState({
      recordsPerPage: value,
    });
  }

  setUspsBulkSearchEdit = () => {
    const { uspsBulkSearch } = this.state;
    this.setState({
      isUspsBulkSearchEdit: true,
      uspsBulkSearchCopy: cloneDeep(uspsBulkSearch),
    });
  };

  handleUspsBulkSearchEditChange = (e) => {
    const { uspsBulkSearch } = this.state;
    uspsBulkSearch[e.target.name] = e.target.value;
    this.setState({ uspsBulkSearch });
  };

  handleUspsBulkSearchEditSubmit = () => {
    const { uspsBulkSearch } = this.state;
    let uspsBulkSearchEdit = {
      usps_bulk_search_id: parseInt(uspsBulkSearch.id, 10),
      name: uspsBulkSearch.name,
    };
    this.setState({ isLoadingUspsBulkSearchEdit: true });
    apiBase
      .patch(`/usps-bulk-searches`, uspsBulkSearchEdit)
      .then((res) => {
        this.setState({
          uspsBulkSearch: res.data,
          isUspsBulkSearchEdit: false,
          isLoadingUspsBulkSearchEdit: false,
        });
        message.success("Successfully updated USPS bulk search");
      })
      .catch((err) => {
        this.setState({
          isLoadingUspsBulkSearchEdit: false,
        });
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
      });
  };

  cancelUspsBulkSearchEdit = () => {
    const { uspsBulkSearchCopy } = this.state;
    this.setState({
      isUspsBulkSearchEdit: false,
      uspsBulkSearch: uspsBulkSearchCopy,
    });
  };

  render() {
    const {
      uspsBulkSearch,
      uspsBulkSearchOwners,
      isLoading,
      isUspsBulkSearchEdit,
      isLoadingUspsBulkSearchEdit,
    } = this.state;

    const columns = [
      {
        title: "Owner id",
        dataIndex: ["owner", "id"],
        className: "App__rowLink",
        render: (value) => <div className="App__rowIcon">{value}</div>,
        onCell: (record) => ({
          onClick: (e) => this.handleRowClick(e, `/owners/${record.owner.id}`),
        }),
        width: "100px",
        sorter: (a, b) => a.owner.id - b.owner.id,
      },
      {
        title: "Name formatted",
        dataIndex: ["owner", "name_formatted"],
        sorter: (a, b) =>
          alphaSort(a.owner.name_formatted, b.owner.name_formatted),
      },
      {
        title: "Tax roll address",
        dataIndex: ["owner", "address_formatted"],
        render: (_, record) => {
          return renderAddress(record, "owner");
        },
        sorter: (a, b) =>
          alphaSort(
            get(a, "owner.address_formatted", ""),
            get(b, "owner.address_formatted", "")
          ),
      },
      {
        title: "IDI Address",
        dataIndex: ["owner", "last_idi_person"],
        render: (_, record) => {
          return renderAddress(record, "idi_person");
        },
        sorter: (a, b) =>
          alphaSort(
            get(a, "owner.last_idi_person.street", ""),
            get(b, "owner.last_idi_person.street", "")
          ),
      },
      {
        title: "USPS Address",
        dataIndex: ["owner", "last_usps_address"],
        render: (_, record) => {
          return renderAddress(record, "usps_address");
        },
        sorter: (a, b) =>
          alphaSort(
            get(a, "owner.last_usps_address.street", ""),
            get(b, "owner.last_usps_address.street", "")
          ),
      },
      {
        title: "Mailing address",
        dataIndex: ["owner", "mailing_address"],
        render: (_, record) => {
          return renderAddress(record, "mailing");
        },
        sorter: (a, b) =>
          alphaSort(
            get(a, "owner.mailing_street", ""),
            get(b, "owner.mailing_street", "")
          ),
      },
      {
        title: "USPS Zip 11",
        dataIndex: ["owner", "last_usps_address", "zip_code_11"],
        render: (value) => value,
        sorter: (a, b) =>
          get(a, "owner.last_usps_address.zip_code_11") ===
          get(b, "owner.last_usps_address.zip_code_11")
            ? 0
            : get(a, "owner.last_usps_address.zip_code_11")
            ? 1
            : -1,
      },
      {
        title: "USPS vacant address",
        dataIndex: ["owner", "last_usps_address", "vacant"],
        render: (value) => value,
        sorter: (a, b) =>
          get(a, "owner.last_usps_address.vacant") ===
          get(b, "owner.last_usps_address.vacant")
            ? 0
            : get(a, "owner.last_usps_address.vacant")
            ? 1
            : -1,
      },
      {
        title: "USPS DPV confirmation",
        dataIndex: ["owner", "last_usps_address", "dpv_confirmation"],
        render: (value) => value,
        sorter: (a, b) =>
          get(a, "owner.last_usps_address.dpv_confirmation") ===
          get(b, "owner.last_usps_address.dpv_confirmation")
            ? 0
            : get(a, "owner.last_usps_address.dpv_confirmation")
            ? 1
            : -1,
      },
      {
        title: "Last USPS address id",
        dataIndex: ["owner", "last_usps_address_id"],
        render: (value) => <Link to={`/usps-addresses/${value}`}>{value}</Link>,
        sorter: (a, b) =>
          get(a, "owner.last_usps_address_id") -
          get(b, "owner.last_usps_address_id"),
        width: 160,
      },
      {
        title: "Last IDI person id",
        dataIndex: ["owner", "last_idi_person_id"],
        render: (value) => <Link to={`/idi-persons/${value}`}>{value}</Link>,
        sorter: (a, b) =>
          get(a, "owner.last_idi_person_id") -
          get(b, "owner.last_idi_person_id"),
        width: 160,
      },
      {
        title: "NTF",
        dataIndex: ["owner", "ntf"],
        render: (value) => <NTFIcon value={value} />,
        sorter: (a, b) => get(a, "owner.ntf") - get(b, "owner.ntf"),
        width: 80,
      },
    ];

    return (
      <div className="App__rowContentDetail">
        <Row>
          <Col span={24}>
            <div className={styles.card}>
              <div className={styles.cardTitle}>
                <div className={styles.leftTitle}>
                  <h2>USPS Bulk Search</h2>
                </div>
                <div className={styles.rightTitle}>
                  {!isUspsBulkSearchEdit ? (
                    <div>
                      <Button
                        onClick={() => this.setUspsBulkSearchEdit()}
                        className={styles.cardHeaderAction}
                      >
                        Edit
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <Button
                        onClick={() => this.cancelUspsBulkSearchEdit()}
                        className={styles.cardHeaderAction}
                        disabled={isLoadingUspsBulkSearchEdit}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => this.handleUspsBulkSearchEditSubmit()}
                        className={styles.cardHeaderAction}
                        type="primary"
                        loading={isLoadingUspsBulkSearchEdit}
                      >
                        Save
                      </Button>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.cardBody}>
                <Row gutter={24}>
                  <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className={styles.objectData}>
                      <span>Id:</span>
                      {uspsBulkSearch.id}
                    </div>
                    <div className={styles.objectData}>
                      <span>Name:</span>
                      <div onClick={() => this.setUspsBulkSearchEdit()}>
                        <Input
                          value={uspsBulkSearch.name}
                          onChange={(e) =>
                            this.handleUspsBulkSearchEditChange(e)
                          }
                          onPressEnter={(e) => {
                            this.handleUspsBulkSearchEditSubmit();
                            e.target.blur();
                          }}
                          className={
                            isUspsBulkSearchEdit
                              ? styles.inputActive
                              : styles.inputDisabled
                          }
                          disabled={isLoadingUspsBulkSearchEdit}
                          name="name"
                          type="string"
                          size="small"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className={styles.objectData}>
                      <span>Created At:</span>
                      {formatDate(
                        uspsBulkSearch.created_at,
                        "YYYY-MM-DD, h:mm:ss A"
                      )}
                    </div>
                    <div className={styles.objectData}>
                      <span>Updated At:</span>
                      {formatDate(
                        uspsBulkSearch.updated_at,
                        "YYYY-MM-DD, h:mm:ss A"
                      )}
                    </div>
                    <div className={styles.objectData}>
                      <span>Started At:</span>
                      {formatDate(
                        uspsBulkSearch.started_at,
                        "YYYY-MM-DD, h:mm:ss A"
                      )}
                    </div>
                    <div className={styles.objectData}>
                      <span>Stopped At:</span>
                      {formatDate(
                        uspsBulkSearch.stopped_at,
                        "YYYY-MM-DD, h:mm:ss A"
                      )}
                    </div>
                    <div className={styles.objectData}>
                      <span>Runtime (s):</span>
                      {uspsBulkSearch.runtime_seconds}
                    </div>
                  </Col>
                  <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className={styles.objectData}>
                      <span>Status:</span>
                      {capitalize(uspsBulkSearch.status)}
                    </div>
                    <div className={styles.objectData}>
                      <span>Progress:</span>
                      {`${formatNumber(uspsBulkSearch.progress, "0.00")}%`}
                    </div>
                    <div className={styles.objectData}>
                      <span>Percentage Success:</span>
                      {uspsBulkSearch.count_success > 0
                        ? `${(
                            (uspsBulkSearch.count_success /
                              uspsBulkSearch.count_owners) *
                            100
                          ).toFixed(2)}%`
                        : "-"}
                    </div>
                    <div className={styles.objectData}>
                      <span>Count Owners:</span>
                      {uspsBulkSearch.count_owners}
                    </div>
                    <div className={styles.objectData}>
                      <span>Count Leads:</span>
                      {uspsBulkSearch.count_leads}
                    </div>
                    <div className={styles.objectData}>
                      <span>Count Failed:</span>
                      {uspsBulkSearch.count_failed}
                    </div>
                    <div className={styles.objectData}>
                      <span>Count Success:</span>
                      {uspsBulkSearch.count_success}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className={styles.card}>
              <div className={styles.cardTitle}>
                <div className={styles.leftTitle}>
                  <h2>Owners</h2>
                </div>
                <div className={styles.rightTitle}>
                  <Select
                    value={this.state.recordsPerPage}
                    onChange={this.handleRecordsChange.bind(this)}
                    className={"App__mr-5"}
                  >
                    <Select.Option value={25}>25</Select.Option>
                    <Select.Option value={50}>50</Select.Option>
                    <Select.Option value={100}>100</Select.Option>
                    <Select.Option value={1000}>1000</Select.Option>
                  </Select>
                  <TotalRecords
                    title={"Owners"}
                    totalRecords={uspsBulkSearchOwners.length}
                  />
                </div>
              </div>
              <div className={styles.cardBody}>
                <Table
                  columns={columns}
                  dataSource={uspsBulkSearchOwners}
                  pagination={{
                    pageSize: this.state.recordsPerPage,
                    showSizeChanger: false,
                  }}
                  className="App__smallTables"
                  showSorterTooltip={false}
                  loading={isLoading}
                  scroll={{ x: "100%", y: "calc(100vh - 660px)" }}
                  bordered
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withParams(USPSBulkSearchDetail);
