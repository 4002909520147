import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";

import { Col, Modal, Table, Row, message, Tooltip } from "antd";
import { apiBase } from "../../utils/apiBase";
import { withParams } from "../../utils/withParams";
import { withRouter } from "../../utils/withRouter";
import { alphaSort } from "../../utils/numbers";
import { get, find } from "lodash";

import styles from "./OwnerNameAddressSimilarSplink.module.scss";

import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

const mapStateToProps = (state) => ({
  appConfig: get(state.appConfig, "data", {}),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const OwnerNameAddressSimilarSplink = (props) => {
  const { open, ownerNameAddressId, onCancel } = props;

  const [loading, setLoading] = useState(false);
  const [ownerNameAddress, setOwnerNameAddress] = useState(null);
  const [similarOwnerNameAddresses, setSimilarOwnerNameAddresses] =
    useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [matched, setMatched] = useState(false);

  const fetchData = useCallback(async () => {
    if (!ownerNameAddressId) {
      return;
    }

    setLoading(true);
    try {
      const res = await apiBase.get(
        `/owner-name-addresses/${ownerNameAddressId}/similar`
      );
      setSimilarOwnerNameAddresses(get(res, "data.owner_name_address_similar"));
      setOwnerNameAddress(get(res, "data.owner_name_address"));
      setMatched(get(res, "data.owner_name_address_matched"));
    } catch (error) {
      message.error("Failed to fetch similar owners.");
    } finally {
      setLoading(false);
    }
  }, [ownerNameAddressId]);

  useEffect(() => {
    fetchData();
  }, [fetchData, ownerNameAddressId]);

  const handleOk = async () => {
    setLoading(true);
    try {
      const ownerNameAddressPairsToSave = similarOwnerNameAddresses.map(
        (owner) => ({
          owner_name_address_id: owner.id,
          owner_name_address_pair_id: owner.id,
          match: selectedRowKeys.includes(owner.id),
        })
      );

      await apiBase.post(
        `/owner-name-addresses/${ownerNameAddressId}/similar`,
        {
          owner_name_address_pairs: ownerNameAddressPairsToSave,
        }
      );
      message.success("Selected owner name address pairs saved successfully.");
      onCancel();
    } catch (error) {
      message.error("Failed to save selected owner name address pairs.");
    } finally {
      setSelectedRowKeys([]);
      setLoading(false);
      props.getOwnerNameAddresses();
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  const dataDifferent = (record, key) => {
    return (
      <span
        style={{
          color:
            get(record, key) !== get(ownerNameAddress, key) ? "red" : "green",
        }}
      >
        {get(record, key)}
      </span>
    );
  };

  // function handleRowClick(e, _to) {
  //   e.stopPropagation();
  //   if (e.ctrlKey || e.metaKey) {
  //     window.open(_to, "_blank");
  //     return;
  //   }
  //   props.history(_to);
  //   fetchData();
  // }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const ownerLinker = get(props.appConfig, "owner_linker", {});
  const comparisons = get(ownerLinker, "comparisons", []);
  const locationComparison = get(
    find(
      comparisons,
      (comparison) => comparison.output_column_name === "location"
    ),
    "comparison_levels",
    []
  )
    .filter((r) => r.is_null_level !== true)
    .reverse();
  const personNameComparison = get(
    find(
      comparisons,
      (comparison) => comparison.output_column_name === "person_name"
    ),
    "comparison_levels",
    []
  )
    .filter((r) => r.is_null_level !== true)
    .reverse();
  const entityNameComparison = get(
    find(
      comparisons,
      (comparison) => comparison.output_column_name === "entity_name"
    ),
    "comparison_levels",
    []
  )
    .filter((r) => r.is_null_level !== true)
    .reverse();
  const unitOwnershipsComparison = get(
    find(
      comparisons,
      (comparison) => comparison.output_column_name === "unit_ownerships"
    ),
    "comparison_levels",
    []
  )
    .filter((r) => r.is_null_level !== true)
    .reverse();

  const columns = [
    {
      dataIndex: "id",
      className: `App__w8`,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Name",
      dataIndex: "name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => alphaSort(get(a, "name", ""), get(b, "name", "")),
      render: (text, record) => dataDifferent(record, "name"),
    },
    {
      title: "Name formatted",
      dataIndex: "name_formatted",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        alphaSort(get(a, "name_formatted", ""), get(b, "name_formatted", "")),
      render: (text, record) => dataDifferent(record, "name_formatted"),
    },
    {
      title: "First name",
      dataIndex: "first_name",
      sortDirections: ["descend", "ascend"],
      width: 150,
      sorter: (a, b) =>
        alphaSort(get(a, "first_name", ""), get(b, "first_name", "")),
      render: (text, record) => dataDifferent(record, "first_name"),
    },
    {
      title: "Middle name",
      dataIndex: "middle_name",
      sortDirections: ["descend", "ascend"],
      width: 150,
      sorter: (a, b) =>
        alphaSort(get(a, "middle_name", ""), get(b, "middle_name", "")),
      render: (text, record) => dataDifferent(record, "middle_name"),
    },
    {
      title: "Last name",
      dataIndex: "last_name",
      sortDirections: ["descend", "ascend"],
      width: 150,
      sorter: (a, b) =>
        alphaSort(get(a, "last_name", ""), get(b, "last_name", "")),
      render: (text, record) => dataDifferent(record, "last_name"),
    },
    {
      title: "Street raw",
      dataIndex: "street",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => alphaSort(get(a, "street", ""), get(b, "street", "")),
      render: (text, record) => dataDifferent(record, "street"),
    },
    {
      title: "Street",
      dataIndex: "street_formatted",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        alphaSort(
          get(a, "street_formatted", ""),
          get(b, "street_formatted", "")
        ),
      render: (text, record) => dataDifferent(record, "street_formatted"),
    },
    {
      title: "City",
      dataIndex: "city",
      sortDirections: ["descend", "ascend"],
      width: 150,
      sorter: (a, b) => alphaSort(get(a, "city", ""), get(b, "city", "")),
      render: (text, record) => dataDifferent(record, "city"),
    },
    {
      title: "State",
      dataIndex: "state",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => alphaSort(get(a, "state", ""), get(b, "state", "")),
      render: (text, record) => dataDifferent(record, "state"),
      width: 75,
    },
    {
      title: "Zip code",
      dataIndex: "zip_code",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        alphaSort(get(a, "zip_code", ""), get(b, "zip_code", "")),
      render: (text, record) => dataDifferent(record, "zip_code"),
      width: 100,
    },
    {
      title: "Latitude",
      dataIndex: "latitude",
      render: (value) => Number(value).toFixed(6),
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.latitude - b.latitude,
      width: 100,
    },
    {
      title: "Longitude",
      dataIndex: "longitude",
      render: (value) => Number(value).toFixed(6),
      sortDirections: ["descend", "ascend"],
      width: 100,
    },
    {
      title: "Source",
      dataIndex: "source",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => alphaSort(get(a, "source", ""), get(b, "source", "")),
      width: 100,
      ellipsis: true,
    },
    {
      title: "Gamma location",
      dataIndex: "gamma_location",
      ellipsis: true,
      render: (value) => {
        return (
          <Tooltip title={value}>
            {get(
              find(locationComparison, (r, index) => index === value),
              "label_for_charts",
              value
            )}
          </Tooltip>
        );
      },
    },
    {
      title: "Gamma person name",
      dataIndex: "gamma_person_name",
      ellipsis: true,
      render: (value) => {
        return (
          <Tooltip title={value}>
            {get(
              find(personNameComparison, (r, index) => index === value),
              "label_for_charts",
              value
            )}
          </Tooltip>
        );
      },
    },
    {
      title: "Gamma entity name",
      dataIndex: "gamma_entity_name",
      ellipsis: true,
      render: (value) => {
        return (
          <Tooltip title={value}>
            {get(
              find(entityNameComparison, (r, index) => index === value),
              "label_for_charts",
              value
            )}
          </Tooltip>
        );
      },
    },
    {
      title: "Gamma unit ownerships",
      dataIndex: "gamma_unit_ownerships",
      ellipsis: true,
      render: (value) => {
        return (
          <Tooltip title={value}>
            {get(
              find(unitOwnershipsComparison, (r, index) => index === value),
              "label_for_charts"
            )}
          </Tooltip>
        );
      },
    },
    {
      title: "Match key",
      dataIndex: "match_key",
      sortDirections: ["descend", "ascend"],
      width: 100,
      ellipsis: true,
      sorter: (a, b) => alphaSort(a.match_key, b.match_key),
      render: (value) => {
        const matchKeys = get(
          ownerLinker,
          "blocking_rules_to_generate_predictions",
          []
        );

        return (
          <Tooltip
            title={get(matchKeys, `[${parseInt(value)}].blocking_rule`, value)}
          >
            <span>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: "Match probability",
      dataIndex: "match_probability",
      sortDirections: ["descend", "ascend"],
      width: 150,
      sorter: (a, b) => a.match_probability - b.match_probability,
      render: (value) => Number(value).toFixed(6),
    },
  ];

  return (
    <>
      <Modal
        title={
          <div>
            Splink matched name addresses{" "}
            {matched ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
          </div>
        }
        visible={open}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Close"
        okText="Save selections"
        width={"100%"}
        bodyStyle={{ padding: "10px 20px 0px 20px" }}
      >
        <div>
          <Row>
            <Col span={24}>
              <div className={`${styles.cardBody} ${styles.ownerData}`}>
                <Row gutter={24}>
                  <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className={styles.objectData}>
                      <span>Id:</span>
                      {get(ownerNameAddress, "id")}
                    </div>
                    <div className={styles.objectData}>
                      <span>Name:</span>
                      {get(ownerNameAddress, "name")}
                    </div>
                    <div className={styles.objectData}>
                      <span>Name formatted:</span>
                      {get(ownerNameAddress, "name_formatted", "")}
                    </div>
                    <div className={styles.objectData}>
                      <span>First name:</span>
                      {get(ownerNameAddress, "first_name", "")}
                    </div>
                    <div className={styles.objectData}>
                      <span>Middle name:</span>
                      {get(ownerNameAddress, "middle_name", "")}
                    </div>
                    <div className={styles.objectData}>
                      <span>Last name:</span>
                      {get(ownerNameAddress, "last_name", "")}
                    </div>
                    <div className={styles.objectData}>
                      <span>Entity name:</span>
                      {get(ownerNameAddress, "entity_name", "")}
                    </div>
                    <div className={styles.objectData}>
                      <span>Source:</span>
                      {get(ownerNameAddress, "source", "")}
                    </div>
                  </Col>
                  <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className={styles.objectData}>
                      <span>Address:</span>
                      {get(ownerNameAddress, "street", "")}
                    </div>
                    <div className={styles.objectData}>
                      <span>Address formatted:</span>
                      {get(ownerNameAddress, "street_formatted", "")}
                    </div>
                    <div className={styles.objectData}>
                      <span>City:</span>
                      {get(ownerNameAddress, "city", "")}
                    </div>
                    <div className={styles.objectData}>
                      <span>State:</span>
                      {get(ownerNameAddress, "state", "")}
                    </div>
                    <div className={styles.objectData}>
                      <span>Zip code:</span>
                      {get(ownerNameAddress, "zip_code", "")}
                    </div>
                    <div className={styles.objectData}>
                      <span>Latitude:</span>
                      {get(ownerNameAddress, "latitude", "")}
                    </div>
                    <div className={styles.objectData}>
                      <span>Longitude:</span>
                      {get(ownerNameAddress, "longitude", "")}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        <Row>
          <Col span={24}>
            <Table
              rowSelection={rowSelection}
              loading={loading}
              columns={columns}
              dataSource={similarOwnerNameAddresses}
              className={styles.smallTables}
              pagination={{
                pageSize: 1000,
                showTotal: (total) => `Total ${total} similar name addresses`,
              }}
              scroll={{ x: "100%" }}
              bordered
              size="small"
              rowKey="id"
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};
export default withRouter(
  withParams(
    connect(mapStateToProps, mapDispatchToProps)(OwnerNameAddressSimilarSplink)
  )
);
