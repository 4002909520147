import React from "react";
import { Modal, Form, Input } from "antd";

const CreateMailTemplate = (props) => {
  const { visible, isLoading, onCancel, onCreate } = props;
  const [form] = Form.useForm();

  return (
    <Modal
      title="Create mail template"
      okText="Create"
      cancelText="Cancel"
      visible={visible}
      onCancel={onCancel}
      cancelButtonProps={{ disabled: isLoading }}
      okButtonProps={{ disabled: isLoading, loading: isLoading }}
      maskClosable={false}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        name="mailTemplateCreate"
        layout={"vertical"}
        autoComplete="off"
        disabled={isLoading}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "A name is required",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateMailTemplate;
