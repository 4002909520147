import React, { useState, useEffect } from "react";
import { apiBase } from "../../utils/apiBase";
import { get, uniqBy, isEmpty, orderBy } from "lodash";
import { useNavigate } from "react-router-dom";

import {
  Modal,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Button,
  Divider,
  Select,
  Tooltip,
  message,
} from "antd";

import { QuestionCircleOutlined } from "@ant-design/icons";

import styles from "./IDISearchCreateForm.module.scss";

const IDISearchCreateForm = (props) => {
  const { owner, ownerNameAddresses } = props;
  const geocodedAddresses = orderBy(
    uniqBy(ownerNameAddresses, "geocoded_address_id"),
    (o) => Math.max(...get(o, "tax_years", [])),
    ["desc"]
  );

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useNavigate(); // navigate

  const onFinish = async (values) => {
    setLoading(true);

    let searchId = null;
    let statusCode = null;

    apiBase
      .post(`idi-searches`, {
        ...values,
        ...(values.date_of_birth
          ? { date_of_birth: values.date_of_birth.format("MM/DD/YYYY") }
          : {}),
        ...(get(owner, "id", null) ? { owner_id: owner.id } : {}),
      })
      .then(async (res) => {
        searchId = get(res, "data.id", null);
        statusCode = get(res, "data.response_status_code", null);
        const error = get(res, "data.error", null);
        const totalResults = get(res, "data.total_results", 0);
        if (isEmpty(error) && totalResults > 0) {
          message.success(`${statusCode}: Successfully created idi search.`);
        } else if (isEmpty(error) && totalResults === 0) {
          message.warning(
            `${statusCode}: Successful search, no results found.`
          );
        } else {
          message.error(`${statusCode}: ${get(error, "message", "")}`);
        }
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to create idi search.`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
        resetComponent();
        setIsModalOpen(false);
        if (searchId && statusCode === 200 && !get(owner, "id", null)) {
          history(`/idi-searches/${searchId}`);
        } else {
          if (typeof props.refetch === "function") {
            props.refetch();
          }
        }
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    resetComponent();
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    resetComponent();
  };

  const submitForm = () => {
    form
      .validateFields()
      .then(() => {
        form.submit();
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };

  const resetComponent = () => {
    form.resetFields();
  };

  const onFormChange = async (changedValues, allValues) => {
    const { geocoded_address_id } = changedValues;

    if ("geocoded_address_id" in changedValues) {
      const address = geocodedAddresses.find(
        (a) => a.geocoded_address_id === geocoded_address_id
      );
      form.setFieldsValue({
        street_address: get(address, "street_formatted", ""),
        city: get(address, "city", ""),
        state: get(address, "state", ""),
        zip: get(address, "zip_code", ""),
      });
    }
  };

  const [form] = Form.useForm();

  useEffect(() => {
    if (owner) {
      form.setFieldsValue({
        first_name: owner.first_name,
        middle_name: !!owner.middle_name ? owner.middle_name[0] : "",
        last_name: owner.last_name,
        street_address: owner.address_formatted,
        city: owner.city,
        state: owner.state,
        zip: owner.zip_code,
      });
    }
  }, [owner, form]);

  return (
    <div>
      <Button type="secondary" onClick={showModal}>
        Search IDI
      </Button>
      <Modal
        title="Create IDI search"
        visible={isModalOpen}
        onOk={() => submitForm()}
        onCancel={handleCancel}
        okText="Search"
        cancelButtonProps={{ disabled: loading }}
        okButtonProps={{ disabled: loading, loading: loading }}
        maskClosable={false}
      >
        <div className={styles.compactForm}>
          <Form
            name="idiSearchCreate"
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onValuesChange={owner ? onFormChange : null}
            autoComplete="off"
            layout="vertical"
            disabled={loading}
            initialValues={
              owner
                ? {
                    first_name: owner.first_name,
                    middle_name: !!owner.middle_name
                      ? owner.middle_name[0]
                      : "",
                    last_name: owner.last_name,
                    street_address: owner.address_formatted,
                    city: owner.city,
                    state: owner.state,
                    zip: owner.zip_code,
                  }
                : {}
            }
          >
            <Form.Item label="First name" name="first_name">
              <Input />
            </Form.Item>
            <Form.Item label="Middle name" name="middle_name">
              <Input />
            </Form.Item>
            <Form.Item label="Last name" name="last_name">
              <Input />
            </Form.Item>
            <div className={styles.threeColumns}>
              <Form.Item label="Date of birth" name="date_of_birth">
                <DatePicker
                  className="App__fullWidth"
                  format={[
                    "M/DD/YY",
                    "M/D/YY",
                    "MM/D/YY",
                    "M/DD/YYYY",
                    "M/D/YYYY",
                    "MM/D/YYYY",
                  ]}
                />
              </Form.Item>
              <Form.Item
                label="Min age"
                name="min_age"
                rules={[
                  {
                    type: "number",
                    min: 0,
                    max: 120,
                    message: "",
                  },
                ]}
              >
                <InputNumber className="App__fullWidth" precision={0} />
              </Form.Item>
              <Form.Item
                label="Max age"
                name="max_age"
                rules={[
                  {
                    type: "number",
                    min: 0,
                    max: 120,
                    message: "",
                  },
                ]}
              >
                <InputNumber className="App__fullWidth" precision={0} />
              </Form.Item>
            </div>
            <Divider style={{ margin: "12px 0" }} />
            {owner && (
              <Form.Item
                label={
                  <span>
                    Address&nbsp;
                    <Tooltip
                      title="These are the different addresses that have been seen for this owner. You can use this to pre-populate the details below."
                      placement="rightTop"
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                name="geocoded_address_id"
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select address"
                  optionFilterProp="label"
                  options={geocodedAddresses.map((address, idx) => ({
                    label: `${get(address, "street_formatted", "")}, ${get(
                      address,
                      "city",
                      ""
                    )}, ${get(address, "state", "")} ${get(
                      address,
                      "zip_code",
                      ""
                    )} [${address.geocoded_address_id}]`,
                    value: address.geocoded_address_id,
                  }))}
                />
              </Form.Item>
            )}
            <Form.Item label="Street address" name="street_address">
              <Input />
            </Form.Item>
            <div className={styles.twoColumns}>
              <Form.Item label="City" name="city">
                <Input />
              </Form.Item>
              <Form.Item label="County" name="county">
                <Input />
              </Form.Item>
            </div>
            <div className={styles.twoColumns}>
              <Form.Item label="State" name="state">
                <Input />
              </Form.Item>
              <Form.Item label="Zip" name="zip">
                <Input />
              </Form.Item>
            </div>
            <div className={styles.twoColumns}>
              <Form.Item label="Phone number" name="phone_number">
                <Input />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "This is not a valid email address.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default IDISearchCreateForm;
