import React, { Component } from "react";
import { get, uniq } from "lodash";
import { columnTypes } from "../utils/search";
import { apiBase } from "../utils/apiBase";
import BaseSearch from "./search/BaseSearch";
import Took from "./search/Took";
import TotalRecords from "./search/TotalRecords";
import { NTFIcon } from "../views/common/LabelIcon";

import PipedrivePersonLabels from "./common/PipedrivePersonLabels";
import ExportForm from "./forms/ExportForm";
import ChangeColumnsForm from "./forms/ChangeColumnsForm";
import CreateGenericLeads from "./forms/CreateGenericLeads";
import FilterTags from "./search/FilterTags";

import {
  DownloadOutlined,
  TableOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
  EyeOutlined,
} from "@ant-design/icons";

import {
  Row,
  Col,
  Table,
  Select,
  Button,
  Tooltip,
  Pagination,
  Modal,
  message,
} from "antd";

import styles from "./GenericFileRecords.module.scss";

const { confirm } = Modal;

const baseColumnState = [
  {
    title: "Generic file Id",
    dataIndex: "generic_file_id",
    className: `App__rowLink App__w10`,
    classNameChild: "App__rowIcon",
    editable: false,
    type: columnTypes.LINK,
    baseUrl: "/generic-files",
    searchable: true,
  },
  {
    title: "Generic",
    dataIndex: "id",
    editable: false,
    type: columnTypes.LINK,
    baseUrl: "/generic-file-records",
  },
  {
    title: "Owner",
    dataIndex: "owner_id",
    editable: false,
    type: columnTypes.LINK,
    baseUrl: "/owners",
  },
  {
    dataIndex: ["pipedrive_person", "label"],
    title: "Pipedrive label",
    className: `App__w12`,
    exportable: false,
    visible: true,
    children: [
      {
        dataIndex: ["pipedrive_person", "label"],
        title: () => <div />,
        render: (_, record) => {
          return (
            <PipedrivePersonLabels
              pipedrivePersons={get(record, "pipedrive_persons", [])}
            />
          );
        },
      },
    ],
  },
  {
    title: "Lead status",
    dataIndex: ["owner", "leads"],
    className: `App__w9`,
    children: [
      {
        dataIndex: ["owner", "leads"],
        title: () => <div />,
        render: (value) => {
          const _value = uniq(
            (value || []).map((item) => `${get(item, "status", "")} `)
          );
          return _value.join(" ");
        },
      },
    ],
  },
  {
    title: "Owner name",
    dataIndex: "owner_name",
    type: columnTypes.STRING,
  },
  {
    title: "Owner name formatted",
    dataIndex: "owner_name_formatted",
    type: columnTypes.STRING,
    visible: false,
  },
  {
    title: "Owner name ICO",
    dataIndex: "owner_name_ico",
    type: columnTypes.STRING,
    visible: false,
  },
  {
    title: "Owner address",
    dataIndex: "owner_address",
    type: columnTypes.STRING,
  },
  {
    title: "Owner address formatted",
    dataIndex: "owner_address_formatted",
    type: columnTypes.STRING,
    visible: false,
  },
  { title: "Owner city", dataIndex: "owner_city", type: columnTypes.STRING },
  { title: "Owner state", dataIndex: "owner_state", type: columnTypes.STRING },
  {
    title: "Owner zip code",
    dataIndex: "owner_zip_code",
    type: columnTypes.STRING,
    className: `App__w12`,
  },
  {
    title: "Owner generics count",
    dataIndex: ["owner", "generic_file_record_count"],
    type: columnTypes.INTEGER,
    className: `App__w16`,
  },
  {
    title: "Lessee",
    dataIndex: ["data", "lessee"],
    type: columnTypes.STRING,
  },
  {
    title: "Unit county",
    dataIndex: ["data", "unit_county"],
    type: columnTypes.STRING,
  },
  {
    title: "Book",
    dataIndex: ["data", "book"],
    type: columnTypes.STRING,
  },
  {
    title: "Page",
    dataIndex: ["data", "page"],
    type: columnTypes.STRING,
  },
  {
    title: "File date",
    dataIndex: ["data", "file_date"],
    type: columnTypes.STRING,
  },
  {
    title: "File year",
    dataIndex: ["data", "file_year"],
    type: columnTypes.STRING,
  },
  {
    title: "File number",
    dataIndex: ["data", "file_number"],
    type: columnTypes.STRING,
  },
  {
    title: "Lease type",
    dataIndex: ["data", "lease_type"],
    type: columnTypes.STRING,
  },
  {
    title: "Section",
    dataIndex: ["data", "section"],
    type: columnTypes.STRING,
  },
  {
    title: "Township",
    dataIndex: ["data", "township"],
    type: columnTypes.STRING,
  },
  {
    title: "Range",
    dataIndex: ["data", "range"],
    type: columnTypes.STRING,
  },
  {
    title: "Acres",
    dataIndex: ["data", "acres"],
    type: columnTypes.STRING,
  },
  {
    title: "Royalty",
    dataIndex: ["data", "royalty"],
    type: columnTypes.STRING,
  },
  {
    title: "NTF",
    dataIndex: "owner_ntf",
    type: columnTypes.SELECT,
    options: [
      { value: "false", label: "False" },
      { value: "true", label: "True" },
    ],
    className: `App__w6`,
    selectClassName: `App__w6`,
    format: ({ value }) => <NTFIcon value={value} />,
  },
];

class GenericFileRecords extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isLeadCreateModalVisible: false,
      isLoadingLeadCreate: false,
      selectedGenericFileRecords: [],
      selectedGenericFileRecordsKeys: [],
    };
  }

  interestFilterStyles(record) {
    if (record.interest_filter === true) {
      return styles.badInterest;
    }
  }

  showDeleteConfirm = (genericFileRecordId) => {
    const { isLoading } = this.state;
    confirm({
      title: "Are you sure you want to delete this generic file record?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      okButtonProps: { disabled: isLoading, loading: isLoading },
      cancelButtonProps: { disabled: isLoading, loading: isLoading },
      onOk: () => this.deleteGenericFileRecord(genericFileRecordId),
    });
  };

  deleteGenericFileRecord = (genericFileRecordId) => {
    const { executeSearch } = this.props;
    this.setState({ isLoading: true });
    apiBase
      .delete(`/generic-file-records/${genericFileRecordId}`)
      .then((res) => {
        this.setState({ isLoading: false });
        executeSearch();
        let msg = `${get(res, "status")}: ${get(res, "data.message")}`;
        message.success(msg);
      })
      .catch((err) => {
        console.log(err);
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
        this.setState({ isLoading: false });
      });
  };

  showLeadCreateModal = () => {
    this.setState({ isLeadCreateModalVisible: true });
  };

  handleCancelLeadCreate = () => {
    this.setState({ isLeadCreateModalVisible: false });
  };

  handleSubmitLeadCreate = (values) => {
    const { executeSearch, view } = this.props;

    this.setState({ isLoadingLeadCreate: true });
    let data = {
      ...values,
      generic_file_record_ids: this.state.selectedGenericFileRecords.map(
        (row) => row.id
      ),
      view,
    };
    const queryString = this.props.location.search;
    return apiBase
      .post(`/generic-file-records/leads${queryString}`, data)
      .then((res) => {
        executeSearch();
        this.setState({
          isLeadCreateModalVisible: false,
          isLoadingLeadCreate: false,
          selectedGenericFileRecords: [],
          selectedGenericFileRecordsKeys: [],
        });
        let msg = `${get(res, "status")}: Successfully created leads.`;
        message.success(msg);
        // this.props.history(`/generics?limit=50&page=1`);
      })
      .catch((err) => {
        let msg = `${err.response.status}: ${err.response.data.message}`;
        message.error(msg);
        this.setState({
          isLeadCreateModalVisible: false,
          isLoadingLeadCreate: false,
          selectedGenericFileRecords: [],
          selectedGenericFileRecordsKeys: [],
        });
      });
  };

  onSelectLeadChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedGenericFileRecordsKeys: selectedRowKeys,
      selectedGenericFileRecords: selectedRows,
    });
  };

  render() {
    const {
      isLoadingRecords,
      isLoadingCount,
      records,
      columnState,
      limit,
      page,
      totalRecords,
      tookRecords,
      tookCount,
      searchFields,
      isExportModalVisible,
      isChangeColumnsVisible,
      isLoadingExport,
    } = this.props;

    const {
      handleSearch,
      handleRecordsChange,
      handlePaginationChange,
      handleFilterClose,
      handleTableChange,
      handleSubmitExport,
      showExportModal,
      handleCancelExport,
      handleSubmitColumnChange,
      showColumnChangeModal,
      handleCancelColumnsChange,
      getColumnProps,
      exportFormRef,
      changeColumnFormRef,
    } = this.props;

    const { isLoading } = this.state;

    const rowSelection = {
      selectedRowKeys: this.state.selectedGenericFileRecordsKeys,
      onChange: this.onSelectLeadChange,
    };

    let columns = columnState
      .map((column, idx) => ({
        key: idx,
        ...column,
        ...getColumnProps(column),
      }))
      .filter((col) => col.visible === true);

    columns.push({
      title: "",
      dataIndex: "actions",
      visible: true,
      editable: false,
      children: [
        {
          dataIndex: "actions",
          render: (_, record) => {
            return (
              <div className="App__tableOperations">
                <Button
                  shape="circle"
                  icon={<DeleteOutlined />}
                  size="small"
                  onClick={() => this.showDeleteConfirm(record.id)}
                  loading={isLoading}
                  disabled={isLoading}
                />
              </div>
            );
          },
        },
      ],
    });

    let ownerNameRegex = this.props.interestFilters.owner_name;
    records.forEach((row) => {
      row["interest_filter"] = RegExp(ownerNameRegex, "i").test(
        row.name_formatted
      );
    });

    return (
      <div>
        <Row className="App__rowContent">
          <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16}>
            <Button className="App__mr-5" onClick={() => handleSearch()}>
              Search
            </Button>
            <FilterTags
              searchFields={searchFields}
              columnState={columnState}
              handleFilterClose={handleFilterClose}
            />
          </Col>
          <Col
            span={8}
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            className="App__rightActions"
          >
            <Tooltip placement="bottomRight" title={"Create leads"}>
              <Button
                onClick={this.showLeadCreateModal}
                className="App__iconButton"
              >
                <EyeOutlined />
              </Button>
            </Tooltip>
            <CreateGenericLeads
              visible={this.state.isLeadCreateModalVisible}
              isLoading={this.state.isLoadingLeadCreate}
              onCancel={this.handleCancelLeadCreate}
              onCreate={this.handleSubmitLeadCreate}
              selectedGenericFileRecords={this.state.selectedGenericFileRecords}
            />
            <Tooltip placement="bottomRight" title={"Export search results"}>
              <Button className="App__iconButton" onClick={showExportModal}>
                <DownloadOutlined />
              </Button>
            </Tooltip>
            <ExportForm
              wrappedComponentRef={exportFormRef}
              visible={isExportModalVisible}
              isLoading={isLoadingExport}
              onCancel={handleCancelExport}
              onCreate={handleSubmitExport}
              totalRecords={totalRecords}
            />
            <Tooltip placement="bottomRight" title={"Change visible columns"}>
              <Button
                onClick={showColumnChangeModal}
                className="App__iconButton"
              >
                <TableOutlined />
              </Button>
            </Tooltip>
            <ChangeColumnsForm
              wrappedComponentRef={changeColumnFormRef}
              visible={isChangeColumnsVisible}
              isLoading={isLoadingRecords}
              onCancel={handleCancelColumnsChange}
              onOk={handleSubmitColumnChange}
              columnState={columnState}
            />
            <Select
              value={limit}
              onChange={handleRecordsChange}
              className={"App__mr-5"}
            >
              <Select.Option value={25}>25</Select.Option>
              <Select.Option value={50}>50</Select.Option>
              <Select.Option value={100}>100</Select.Option>
              <Select.Option value={1000}>1000</Select.Option>
            </Select>
            <TotalRecords
              title={"Owners"}
              isLoadingCount={isLoadingCount}
              totalRecords={totalRecords}
            />
          </Col>
        </Row>
        <Row className="App__rowContent">
          <Col span={24}>
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={records}
              onChange={handleTableChange}
              pagination={false}
              showSorterTooltip={false}
              className="App__smallTables"
              rowClassName={this.interestFilterStyles}
              loading={isLoadingRecords}
              scroll={{ x: "100%" }}
              size="small"
              bordered
            />
            <div className="App__searchFooterContainer">
              <Took
                isLoadingRecords={isLoadingRecords}
                isLoadingCount={isLoadingCount}
                tookRecords={tookRecords}
                tookCount={tookCount}
              />
              <Pagination
                current={page}
                pageSize={limit}
                total={totalRecords}
                onChange={handlePaginationChange}
                className="App__pagination"
                showSizeChanger={false}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const GenericFileRecordsWithSearch = (props) => (
  <BaseSearch
    baseColumnState={baseColumnState}
    localSortColumns={[]}
    searchUri="generic-file-records"
  >
    <GenericFileRecords {...props} />
  </BaseSearch>
);

export default GenericFileRecordsWithSearch;
