import React, { Component } from "react";
import { formatNumber } from "../utils/numbers";
import { formatDate } from "../utils/dates";
import { columnTypes } from "../utils/search";
import { apiBase } from "../utils/apiBase";

import BaseSearch from "./search/BaseSearch";
import Took from "./search/Took";
import TotalRecords from "./search/TotalRecords";

import ExportForm from "./forms/ExportForm";
import ChangeColumnsForm from "./forms/ChangeColumnsForm";
import FilterTags from "./search/FilterTags";

import {
  DeleteOutlined,
  DownloadOutlined,
  TableOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";

import {
  Row,
  Col,
  Table,
  Select,
  Button,
  Tooltip,
  Pagination,
  Modal,
  message,
} from "antd";

const { confirm } = Modal;

const baseColumnState = [
  {
    dataIndex: "id",
    title: "Id",
    editable: false,
    type: columnTypes.LINK,
    baseUrl: "/offers",
  },
  {
    dataIndex: "unit_id",
    title: "Unit Id",
    editable: false,
    type: columnTypes.LINK,
    baseUrl: "/units",
  },
  {
    title: "Unit Name",
    dataIndex: ["unit", "name_formatted"],
    type: columnTypes.STRING,
    first: true,
  },
  {
    title: "Unit County",
    dataIndex: ["unit", "county"],
    type: columnTypes.STRING,
  },
  {
    title: "Unit Operator",
    dataIndex: ["unit", "operator"],
    type: columnTypes.STRING,
  },
  {
    title: "Offer Per NRA($)",
    dataIndex: "dollar_per_nra",
    type: columnTypes.INTEGER,
    format: (value) => `$${formatNumber(value, "0,0.00")}`,
  },
  {
    title: "Unit Size (Acres)",
    dataIndex: "acres",
    type: columnTypes.INTEGER,
  },
  {
    title: "Total Lead Offers ($)",
    dataIndex: "total_lead_offers",
    type: columnTypes.INTEGER,
    format: (value) => `$${formatNumber(value, "0,0.00")}`,
  },
  {
    title: "Number Lead Offers",
    dataIndex: "number_lead_offers",
    type: columnTypes.INTEGER,
  },
  {
    title: "Created By",
    dataIndex: ["user", "alias"],
    type: columnTypes.STRING,
  },
  {
    title: "Created Date",
    dataIndex: "created_at",
    type: columnTypes.DATE_TIME,
    format: (value) => formatDate(value, "YYYY-MM-DD, h:mm:ss A"),
  },
];

class Offers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  showDeleteConfirm = (offerId) => {
    const { isLoading } = this.state;
    confirm({
      title: "Are you sure you want to delete this offer?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      okButtonProps: { disabled: isLoading, loading: isLoading },
      cancelButtonProps: { disabled: isLoading, loading: isLoading },
      onOk: () => this.deleteOffer(offerId),
    });
  };

  deleteOffer = (offerId) => {
    const { executeSearch } = this.props;
    this.setState({ isLoading: true });
    apiBase
      .delete(`/offers/${offerId}`)
      .then((res) => {
        this.setState({ isLoading: false });
        executeSearch();
        let msg = `${res.status}: ${res.data.message}`;
        message.success(msg);
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to delete Offer.`;
        message.error(msg);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const {
      isLoadingRecords,
      isLoadingCount,
      records,
      columnState,
      limit,
      page,
      totalRecords,
      tookRecords,
      tookCount,
      searchFields,
      isExportModalVisible,
      isChangeColumnsVisible,
      isLoadingExport,
    } = this.props;

    const {
      handleSearch,
      handleRecordsChange,
      handlePaginationChange,
      handleFilterClose,
      handleTableChange,
      handleSubmitExport,
      showExportModal,
      handleCancelExport,
      handleSubmitColumnChange,
      showColumnChangeModal,
      handleCancelColumnsChange,
      exportFormRef,
      changeColumnFormRef,
      getColumnProps,
    } = this.props;

    let columns = columnState
      .map((column, idx) => ({
        key: idx,
        ...column,
        ...getColumnProps(column),
      }))
      .filter((col) => col.visible === true);

    columns.push({
      title: "",
      dataIndex: "actions",
      key: "actions",
      children: [
        {
          dataIndex: "actions",
          key: "actions",
          render: (_, record) => (
            <Button
              shape="circle"
              icon={<DeleteOutlined />}
              size="small"
              onClick={() => this.showDeleteConfirm(record.id)}
              loading={this.state.isLoading}
            />
          ),
        },
      ],
    });

    return (
      <div>
        <Row className="App__rowContent">
          <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16}>
            <Button className="App__mr-5" onClick={() => handleSearch()}>
              Search
            </Button>
            <FilterTags
              searchFields={searchFields}
              columnState={columnState}
              handleFilterClose={handleFilterClose}
            />
          </Col>
          <Col
            span={8}
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            className="App__rightActions"
          >
            <Tooltip placement="bottomRight" title={"Export search results"}>
              <Button className="App__iconButton" onClick={showExportModal}>
                <DownloadOutlined />
              </Button>
            </Tooltip>
            <ExportForm
              wrappedComponentRef={exportFormRef}
              visible={isExportModalVisible}
              isLoading={isLoadingExport}
              onCancel={handleCancelExport}
              onCreate={handleSubmitExport}
              totalRecords={totalRecords}
            />
            <Tooltip placement="bottomRight" title={"Change visible columns"}>
              <Button
                onClick={showColumnChangeModal}
                className="App__iconButton"
              >
                <TableOutlined />
              </Button>
            </Tooltip>
            <ChangeColumnsForm
              wrappedComponentRef={changeColumnFormRef}
              visible={isChangeColumnsVisible}
              isLoading={isLoadingRecords}
              onCancel={handleCancelColumnsChange}
              onOk={handleSubmitColumnChange}
              columnState={columnState}
            />
            <Select
              value={limit}
              onChange={handleRecordsChange}
              className={"App__mr-5"}
            >
              <Select.Option value={25}>25</Select.Option>
              <Select.Option value={50}>50</Select.Option>
              <Select.Option value={100}>100</Select.Option>
              <Select.Option value={1000}>1000</Select.Option>
            </Select>
            <TotalRecords
              title={"Offers"}
              isLoadingCount={isLoadingCount}
              totalRecords={totalRecords}
            />
          </Col>
        </Row>
        <Row className="App__rowContent">
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={records}
              onChange={handleTableChange}
              pagination={false}
              showSorterTooltip={false}
              className="App__smallTables"
              loading={isLoadingRecords || this.state.isLoading}
              scroll={{ x: "100%" }}
              size="small"
              bordered
            />
            <div className="App__searchFooterContainer">
              <Took
                isLoadingRecords={isLoadingRecords}
                isLoadingCount={isLoadingCount}
                tookRecords={tookRecords}
                tookCount={tookCount}
              />
              <Pagination
                current={page}
                pageSize={limit}
                total={totalRecords}
                onChange={handlePaginationChange}
                className="App__pagination"
                showSizeChanger={false}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const OffersWithSearch = (props) => (
  <BaseSearch
    baseColumnState={baseColumnState}
    localSortColumns={[]}
    searchUri="offers"
  >
    <Offers {...props} />
  </BaseSearch>
);

export default OffersWithSearch;
