import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withParams } from "../utils/withParams";
import { get, pick } from "lodash";
import { clsx } from "clsx";
import actions from "../actions";

import { Row, Col, Button, Select, Form, message } from "antd";

import styles from "./PortfolioSettings.module.scss";

const mapStateToProps = (state) => ({
  appConfig: get(state.appConfig, "data", {}),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const labelProps = {
  labelAlign: "left",
  labelCol: {
    xxl: { span: 7, offset: 0 },
    xl: { span: 9 },
    lg: { span: 10 },
  },
};

const PortfolioSettings = (props) => {
  const { dispatch, appConfig } = props;

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(actions.appConfig.getAppConfig());
  }, [dispatch]);

  const onFinish = async (values) => {
    setLoading(true);
    await dispatch(actions.appConfig.updateAppConfig(values));
    setLoading(false);
  };

  const onFinishFailed = () => {
    message.error("Form fields reqired");
  };

  return (
    <div className="App__rowContentDetail">
      <Row>
        <Col span={24}>
          <div className={styles.card}>
            <div className={styles.cardTitle}>
              <div className={styles.leftTitle}>
                <h2>Portfolio settings</h2>
              </div>
              <div className={styles.rightTitle}>
                <div>
                  <Button
                    onClick={() => form.resetFields()}
                    className={styles.cardHeaderAction}
                    disabled={loading}
                  >
                    Reset
                  </Button>
                  <Button
                    className={styles.cardHeaderAction}
                    type="primary"
                    loading={loading}
                    htmlType="submit"
                    onClick={() => {
                      form.submit();
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
            <div className={styles.cardBody}>
              <Row gutter={24}>
                <Col span={24}>
                  <Form
                    name="basic"
                    layout="vertical"
                    form={form}
                    initialValues={{
                      ...pick(appConfig, ["well_status", "states", "counties"]),
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    disabled={loading}
                  >
                    {[
                      {
                        name: "well_status",
                        label: "Well status",
                        required: false,
                      },
                      {
                        name: "states",
                        label: "States",
                        required: false,
                      },
                      {
                        name: "counties",
                        label: "Counties",
                        required: false,
                      },
                    ].map((item, index) => (
                      <Form.Item
                        key={index}
                        label={item.label}
                        name={item.name}
                        rules={[
                          {
                            required: item.required,
                            message: `${item.label} is required`,
                          },
                        ]}
                        {...labelProps}
                      >
                        <Select
                          mode="tags"
                          disabled={item.disabled}
                          style={{ width: "100%" }}
                          onPressEnter={() => form.submit()}
                          className={clsx({
                            [styles.disabledItem]: item.disabled,
                          })}
                        >
                          {get(appConfig, `${item.name}`, []).map(
                            (row, idx) => (
                              <Select.Option key={idx} value={row}>
                                {row}
                              </Select.Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                    ))}
                  </Form>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withParams(PortfolioSettings));
