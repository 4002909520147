import React, { useState, useEffect, useCallback } from "react";
import { apiBase } from "../../utils/apiBase";
import { withParams } from "../../utils/withParams";
import { withRouter } from "../../utils/withRouter";
import { get } from "lodash";
import { formatNumber, alphaSort } from "../../utils/numbers";

import { Form, Table, Button, Modal, message } from "antd";

import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";

import { EditableCell } from "../utils/TableUtils";

const { confirm } = Modal;

const AssetWells = (props) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [assetWells, setAssetWells] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;

  const getAssetWells = useCallback(async () => {
    try {
      setLoading(true);
      const res = await apiBase.get(`/assets/${props.params.id}/wells`);
      setAssetWells(get(res, "data", {}));
    } catch (err) {
      let msg = `${err.response.status}: Failed to load asset wells ${props.params.id}`;
      message.error(msg);
    } finally {
      setLoading(false);
    }
  }, [props.params.id]);

  useEffect(() => {
    getAssetWells();
  }, [getAssetWells]);

  const deleteAssetWell = async (id) => {
    setLoading(true);
    apiBase
      .delete(`/well-assets/${id}`)
      .then((res) => {
        let msg = `${res.status}: Successfully deleted asset well.`;
        message.success(msg);
        getAssetWells();
        props.getAsset();
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to delete asset well.`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: "Are you sure you want to delete this asset well?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      okButtonProps: { disabled: loading, loading: loading },
      cancelButtonProps: { disabled: loading, loading: loading },
      onOk: () => deleteAssetWell(id),
    });
  };

  function handleRowClick(e, _to) {
    e.stopPropagation();
    if (e.ctrlKey || e.metaKey) {
      window.open(_to, "_blank");
      return;
    }
    props.history(_to);
  }

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
      decimal_interest: record.decimal_interest,
      revenue_allocation: record.revenue_allocation * 100,
      asset_allocation: record.asset_allocation * 100,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const onFormChange = (changedValues, allValues) => {};

  const save = async (record) => {
    try {
      const row = await form.validateFields();
      setLoading(true);
      apiBase
        .patch(`well-assets/${record.id}`, row)
        .then((res) => {
          let msg = `${res.status}: Successfully updated asset well.`;
          message.success(msg);
          getAssetWells();
          props.getAsset();
        })
        .catch((err) => {
          let msg = `${get(err, "response.status")}: ${get(
            err,
            "response.data.message"
          )}`;
          message.error(msg);
        })
        .finally(() => {
          setEditingKey("");
          setLoading(false);
        });
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: ["well", "name"],
      className: `App__rowLink`,
      render: (value) => <div>{value}</div>,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => alphaSort(a.well.name, b.well.name),
      onCell: (record) => ({
        onClick: (e) =>
          handleRowClick(e, `/portfolio/wells/${record["well_id"]}`),
      }),
    },
    {
      title: "API Number",
      dataIndex: ["well", "api_number"],
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => alphaSort(a.well.api_number, b.well.api_number),
    },
    {
      title: "Operator",
      dataIndex: ["well", "operator", "name"],
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => alphaSort(a.well.operator.name, b.well.operator.name),
    },
    {
      title: "Status",
      dataIndex: ["well", "status"],
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => alphaSort(a.well.status, b.well.status),
    },
    {
      title: "Decimal interest",
      dataIndex: "decimal_interest",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.decimal_interest - b.decimal_interest,
      render: (value) =>
        value !== null ? formatNumber(value, "0.00000000") : null,
      editable: true,
    },
    {
      title: "Asset allocation",
      dataIndex: "asset_allocation",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.asset_allocation - b.asset_allocation,
      render: (value) =>
        value !== null ? `${formatNumber(value * 100, "0.00")}%` : null,
      editable: true,
    },
    {
      title: "Revenue allocation",
      dataIndex: "revenue_allocation",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.revenue_allocation - b.revenue_allocation,
      render: (value) =>
        value !== null ? `${formatNumber(value * 100, "0.00")}%` : null,
      editable: true,
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "actions",
      render: (value, record) => {
        const editable = isEditing(record);
        return (
          <div className="App__tableOperations">
            {editable ? (
              <>
                <Button
                  shape="circle"
                  icon={<CheckOutlined />}
                  size="small"
                  className="App__tableEditButton"
                  onClick={() => save(record)}
                  disabled={loading}
                />
                <Button
                  shape="circle"
                  icon={<CloseOutlined />}
                  size="small"
                  className="App__tableEditButton"
                  onClick={() => cancel()}
                  disabled={loading}
                />
              </>
            ) : (
              <Button
                shape="circle"
                icon={<EditOutlined />}
                size="small"
                className="App__tableEditButton"
                onClick={() => edit(record)}
                disabled={loading}
              />
            )}
            <Button
              shape="circle"
              icon={<DeleteOutlined />}
              size="small"
              onClick={() => showDeleteConfirm(record.id)}
              disabled={loading}
            />
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false} onValuesChange={onFormChange}>
      <Table
        pagination={false}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        columns={mergedColumns}
        dataSource={assetWells.map((assetWell, idx) => ({
          ...assetWell,
          key: idx,
        }))}
        showSorterTooltip={false}
        className="App__smallTables"
        loading={loading}
        bordered
      />
    </Form>
  );
};

export default withRouter(withParams(AssetWells));
