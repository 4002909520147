import React, { useState, useEffect } from "react";
import { apiBase } from "../../utils/apiBase";
import { formatNumber } from "../../utils/numbers";
import { clsx } from "clsx";
import { get } from "lodash";

import { Modal, Form, Input, InputNumber, Button, message } from "antd";

import { PlusOutlined } from "@ant-design/icons";

import styles from "./SaleLineCreateForm.module.scss";

const SaleLineCreateForm = (props) => {
  const { sale } = props;
  const asset = get(sale, "asset", {});

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [validationError, setValidationError] = useState(null);
  const [totalFractionConveyed, setTotalFractionConveyed] = useState(
    (1 - asset.decimal_interest_current / asset.decimal_interest) * 100
  );
  const [remainingDecimalInterest, setRemainingDecimalInterest] = useState(
    asset.decimal_interest_current
  );
  const [totalAmount, setTotalAmount] = useState(sale.amount);

  useEffect(() => {
    setRemainingDecimalInterest(asset.decimal_interest_current);
    setTotalFractionConveyed(
      (1 - asset.decimal_interest_current / asset.decimal_interest) * 100
    );
  }, [asset]);

  const onFinish = async (values) => {
    setLoading(true);
    apiBase
      .post(`sales/${sale.id}/sale-lines`, {
        ...values,
      })
      .then(async (res) => {
        message.success("Successfully created sale line.");
      })
      .catch((err) => {
        console.log(err);
        let msg = `${err.response.status}: Failed to create sale line.`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
        resetComponent();
        setIsModalOpen(false);
        props.getSale();
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    resetComponent();
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    resetComponent();
  };

  const validateAggregates = (values) => {
    let _validationError = null;
    if (parseFloat(totalFractionConveyed) - parseFloat(100) > 0.00000001) {
      _validationError =
        "The sum of fraction conveyed can't be greater than 100%.";
    }

    if (parseFloat(remainingDecimalInterest).toFixed(8) < -0.00000001) {
      _validationError =
        "The remaining decimal interest cannot be less than 0.";
    }

    if (_validationError) {
      message.warning("There are validation errors.");
    }

    setValidationError(_validationError);
    return _validationError;
  };

  const submitForm = () => {
    form
      .validateFields()
      .then((values) => {
        const _validationError = validateAggregates(values);
        if (_validationError) {
          return;
        }

        form.submit();
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };

  const onFormChange = (changedValues, allValues) => {
    const { fraction_conveyed, amount } = allValues;

    if (validationError) {
      setValidationError(null);
    }

    let _totalFractionConveyed =
      (1 - asset.decimal_interest_current / asset.decimal_interest) * 100;
    let _remaningDecimalInterest = asset.decimal_interest_current;
    let _totalAmount = 0;

    const decimalIntrest = fraction_conveyed
      ? (fraction_conveyed / 100) * asset.decimal_interest
      : 0;

    _totalFractionConveyed += fraction_conveyed ? fraction_conveyed : 0;
    _remaningDecimalInterest -= decimalIntrest;
    _totalAmount += (amount ? amount : 0) + sale.amount;

    form.setFieldsValue({
      decimal_interest: decimalIntrest,
    });

    setTotalFractionConveyed(_totalFractionConveyed);
    setRemainingDecimalInterest(_remaningDecimalInterest);
    setTotalAmount(_totalAmount);
  };

  const resetComponent = () => {
    form.resetFields();
  };

  const [form] = Form.useForm();

  return (
    <div>
      <Button type="secondary" icon={<PlusOutlined />} onClick={showModal} />
      <Modal
        title="Create sale line"
        width={600}
        visible={isModalOpen}
        onOk={() => submitForm()}
        onCancel={handleCancel}
        cancelButtonProps={{ disabled: loading }}
        okButtonProps={{ disabled: loading, loading: loading }}
        maskClosable={false}
      >
        <Form
          name="saleLineCreate"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onFormChange}
          autoComplete="off"
          layout="vertical"
          disabled={loading}
          initialValues={{}}
        >
          <Form.Item
            label="Grantee"
            name="grantee"
            className={styles.grantee}
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <Input placeholder="Grantee" />
          </Form.Item>
          <Form.Item
            label="Sale Code"
            name="sale_code"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input placeholder="Sale code" />
          </Form.Item>
          <Form.Item
            className={styles.childAmount}
            label="Fraction conveyed"
            name="fraction_conveyed"
            rules={[
              {
                required: true,
                message: "",
                type: "number",
                max: 100,
                min: 0,
              },
            ]}
          >
            <InputNumber
              precision={10}
              addonAfter={"%"}
              placeholder="0.00"
              className="App__fullWidth"
            />
          </Form.Item>
          <Form.Item
            className={styles.childAmount}
            label="Decimal Interest"
            name="decimal_interest"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <InputNumber
              disabled={true}
              className={clsx("App__fullWidth", {
                [styles.disabledItem]: true,
              })}
              precision={8}
              placeholder="0.00"
            />
          </Form.Item>
          <Form.Item
            className={styles.childAmount}
            label="Amount "
            name="amount"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <InputNumber
              precision={2}
              prefix={"$"}
              placeholder="0.00"
              className="App__fullWidth"
            />
          </Form.Item>
          <div className={`${styles.section} ${styles.validationSection}`}>
            <div>
              <div>Initial asset DOI</div>
              <div>{`${formatNumber(
                asset.decimal_interest,
                "0.00000000"
              )}`}</div>
            </div>
            <div>
              <div>Remaining asset DOI</div>
              <div
                className={
                  parseFloat(remainingDecimalInterest).toFixed(8) < 0
                    ? styles.error
                    : ""
                }
              >{`${formatNumber(remainingDecimalInterest, "0.00000000")}`}</div>
            </div>
            <div>
              <div>Total asset fraction conveyed</div>
              <div
                className={
                  parseFloat(remainingDecimalInterest).toFixed(8) > 100
                    ? styles.error
                    : ""
                }
              >{`${formatNumber(totalFractionConveyed, "0.000000")}%`}</div>
            </div>
            <div>
              <div>Total sale amount</div>
              <div>{`$${formatNumber(totalAmount, "0,0.00")}`}</div>
            </div>
          </div>
          {validationError && (
            <div className={styles.section}>
              <div className={styles.wellAllocationsSection}>
                Validation errors:
              </div>
              <div className={styles.error}>{validationError}</div>
            </div>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default SaleLineCreateForm;
