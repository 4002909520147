import React, { useState, useRef } from "react";
import { apiBase } from "../../utils/apiBase";
import { withParams } from "../../utils/withParams";
import { get } from "lodash";

import { Row, Col, Button, Modal, Dropdown, Tooltip, message } from "antd";

import {
  DownOutlined,
  UpOutlined,
  EllipsisOutlined,
  EditOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";

import Quill from "quill";
import QuillEditor from "./QuillEditor";

import styles from "./Note.module.scss";

const { confirm } = Modal;

const Delta = Quill.import("delta");

const moment = require("moment");

const Note = (props) => {
  const { getNotes, recordId, recordType, newNote, note } = props;
  const id = get(note, "id");
  const content = get(note, "content", "{}");
  const expanded = get(note, "expanded", false);

  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isExpanded, setIsExpanded] = useState(expanded);

  const quillRef = useRef();

  const toggleExpandNote = () => {
    setIsExpanded(!isExpanded);
    apiBase
      .patch(`notes/${id}/expand`, {
        expanded: !isExpanded,
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
      });
  };

  const updateSubmit = () => {
    setLoading(true);
    apiBase
      .patch(`notes/${id}`, {
        content: JSON.stringify(quillRef.current?.getContents()),
        record_id: recordId,
        record_type: recordType,
      })
      .then((res) => {
        let msg = `${res.status}: Successfully updated note.`;
        message.success(msg);
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
      })
      .finally(() => {
        setIsEdit(false);
        setLoading(false);
        getNotes();
      });
  };

  const createNote = () => {
    setLoading(true);
    apiBase
      .post(`notes`, {
        content: JSON.stringify(quillRef.current?.getContents()),
        record_id: recordId,
        record_type: recordType,
      })
      .then((res) => {
        let msg = `${res.status}: Successfully created note.`;
        message.success(msg);
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
      })
      .finally(() => {
        quillRef.current?.setContents(new Delta());
        setLoading(false);
        getNotes();
      });
  };

  const deleteNote = () => {
    setLoading(true);
    apiBase
      .delete(`notes/${id}`)
      .then((res) => {
        let msg = `${res.status}: Successfully deleted note.`;
        message.success(msg);
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
        getNotes();
      });
  };

  const handleDelete = () => {
    confirm({
      title: "Are you sure you want to delete this note?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => deleteNote(),
    });
  };

  return (
    <div>
      <Row>
        <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className={styles.container}>
            {!isEdit && !newNote && (
              <div className={styles.controls}>
                <div className={styles.userEditInfo}>
                  <EditOutlined className="App__mr-4" />
                  <div className={styles.date}>
                    {moment(get(note, "updated_at")).local().format("LLLL")},
                  </div>
                  <div>{`${get(note, "created_by_user.first_name")} ${get(
                    note,
                    "created_by_user.last_name"
                  )}`}</div>
                  <span>&nbsp;-&nbsp;</span>
                  {get(note, "last_edit_by_user.first_name") && (
                    <div>{`${get(note, "last_edit_by_user.first_name")} ${get(
                      note,
                      "last_edit_by_user.last_name"
                    )}`}</div>
                  )}
                </div>
                <div>
                  <Tooltip title={isExpanded ? "Shrink" : "Expand"}>
                    <Button
                      type="text"
                      size="small"
                      icon={isExpanded ? <DownOutlined /> : <UpOutlined />}
                      onClick={() => toggleExpandNote()}
                    />
                  </Tooltip>
                  <Tooltip title="More actions">
                    <Dropdown
                      menu={{
                        items: [
                          {
                            key: "0",
                            label: (
                              <div onClick={() => setIsEdit(true)}>Edit</div>
                            ),
                          },
                          {
                            key: "1",
                            label: (
                              <div onClick={() => handleDelete()}>Delete</div>
                            ),
                          },
                        ],
                      }}
                      trigger={["click"]}
                      placement="bottomRight"
                    >
                      <Button
                        type="text"
                        size="small"
                        icon={<EllipsisOutlined />}
                      />
                    </Dropdown>
                  </Tooltip>
                </div>
              </div>
            )}
            <QuillEditor
              ref={quillRef}
              defaultValue={JSON.parse(content)}
              isEdit={isEdit}
              isExpanded={isExpanded}
              newNote={newNote}
            />
            {(isEdit || newNote) && (
              <div className={styles.footer}>
                {!newNote && (
                  <Button
                    className="App__mr-5"
                    type="button"
                    onClick={() => setIsEdit(false)}
                  >
                    Cancel
                  </Button>
                )}
                <Button
                  type="button"
                  onClick={() => (newNote ? createNote() : updateSubmit())}
                  loading={loading}
                >
                  {newNote ? "Create" : "Save"}
                </Button>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withParams(Note);
