import React, { useState } from "react";
import clsx from "clsx";
import { get } from "lodash";
import * as turf from "@turf/turf";
import { useDispatch, useSelector } from "react-redux";
import { Form, Switch, Button } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { ReactComponent as FilterCloseIcon } from "../../../../assets/icons/filter-close.svg";

import { fitMapToBounds } from "../../common/utils";
import { apiBase } from "../../../../utils/apiBase";
import MapFilterV2 from "../../common/MapFilterV2";
import mapActions from "../../../../actions/map";

import { setLayerFilterExpression } from "../../layers";

import styles from "./Layers.module.scss";

const BaseLayer = ({
  mapRef,
  title = "Base Layer",
  apiBaseEndpoint,
  layerId,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  // Get layer state from Redux
  const zoomToFilter = useSelector((state) => state.map.zoomToFilter);
  const layerState = useSelector((state) => state.map.layers[layerId]);
  const { layerVisible, filtersVisible, filters } = layerState;

  // Map filter values from Redux state
  const filterValues = Object.fromEntries(
    Object.entries(filters).map(([key, filter]) => [key, filter.value || []])
  );

  const resetForm = () => {
    form.resetFields();

    // Reset all filter values
    dispatch(mapActions.resetLayerFilters(mapRef, layerId));
  };

  const onFinish = () => {
    if (Object.values(filterValues).every((arr) => arr.length === 0)) {
      return;
    }

    setLoading(true);
    apiBase
      .post(`${apiBaseEndpoint}/bbox`, {
        ...Object.fromEntries(
          Object.entries(filterValues).map(([key, values]) => [
            key,
            values.map((item) => item.value),
          ])
        ),
      })
      .then(async (res) => {
        if (zoomToFilter) {
          const features = turf.feature(get(res, "data.bbox"));
          fitMapToBounds(mapRef, features);
        }

        const filterExpression = [
          "all",
          ...Object.entries(filterValues).flatMap(([field, values]) =>
            values.length > 0
              ? [["in", field, ...values.map((result) => result.value)]]
              : []
          ),
        ];

        setLayerFilterExpression({
          mapRef,
          layerId,
          filterExpression,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const isFilterActive = Object.values(filterValues).some(
    (arr) => arr.length > 0
  );

  return (
    <Form
      name={layerId}
      form={form}
      onFinish={onFinish}
      layout="vertical"
      disabled={loading}
      className={styles.compactForm}
    >
      <div className={styles.layer}>
        <div className={styles.layerHeader}>
          <Switch
            checked={layerVisible}
            onChange={() =>
              dispatch(
                mapActions.setLayerVisibility(mapRef, layerId, !layerVisible)
              )
            }
            size="small"
            className={clsx(styles.switch, {
              [styles.switchOn]: layerVisible,
              [styles.switchOff]: !layerVisible,
            })}
          />
          <div
            className={styles.filterButton}
            onClick={() => {
              dispatch(
                mapActions.setLayerFiltersVisibility(layerId, !filtersVisible)
              );
            }}
          >
            {filtersVisible ? <MinusOutlined /> : <PlusOutlined />}
          </div>
          <div className={styles.label}>{title}</div>
          <div className={styles.filterClose}>
            {isFilterActive && (
              <div className={styles.filterCloseButton} onClick={resetForm}>
                <FilterCloseIcon />
              </div>
            )}
          </div>
        </div>
        {filtersVisible && (
          <div className={styles.layerBody}>
            {Object.values(filters).map(({ column }) => (
              <MapFilterV2
                mapRef={mapRef}
                key={column}
                layerId={layerId}
                column={column}
                searchUri={`${apiBaseEndpoint}/search`}
                isMultiSelect={true}
              />
            ))}
            <div className={styles.filterCtaContainer}>
              <Button
                size="small"
                type="primary"
                className={styles.resetButton}
                onClick={resetForm}
              >
                Clear
              </Button>
              <Button
                size="small"
                type="primary"
                className={styles.applyButton}
                onClick={onFinish}
              >
                Apply
              </Button>
            </div>
          </div>
        )}
      </div>
    </Form>
  );
};

export default BaseLayer;
