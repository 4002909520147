import React, { useState, useRef } from "react";

import { Modal, message } from "antd";
import FileUpload from "./FileUpload";

const FileUploadModal = ({ uploaderProps = { maxSize: 100 }, ...props }) => {
  const [loading, setLoading] = useState(false);
  const fileUploadRef = useRef(null);

  const handleOk = async () => {
    if (!fileUploadRef?.current?.upload) {
      message.error("No file uploader found");
      return;
    }

    setLoading(true);
    await fileUploadRef.current.upload();
    setLoading(false);

    props.onSubmit();
  };

  return (
    <Modal
      title="Upload files"
      open
      onOk={handleOk}
      onCancel={props.onCancel}
      width={800}
      cancelButtonProps={{ disabled: loading }}
      okButtonProps={{ disabled: loading, loading: loading }}
      closable={false}
      {...props}
    >
      <FileUpload
        ref={fileUploadRef}
        recordId={props.recordId}
        recordType={props.recordType}
        {...uploaderProps}
      />
    </Modal>
  );
};

export default FileUploadModal;
