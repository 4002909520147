import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { get } from "lodash";

import { apiBase } from "../../utils/apiBase";
import {
  mailLetterType,
  mailLetterTypeOptions,
} from "../../common/mailLetterType";

import IDIPersonCard from "../../views/idi/IDIPersonCard";

import {
  Row,
  Col,
  Modal,
  Form,
  Input,
  Select,
  Checkbox,
  InputNumber,
  Alert,
  Empty,
  Tooltip,
  message,
} from "antd";

import { QuestionCircleOutlined } from "@ant-design/icons";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const OwnerMailletterCreate = (props) => {
  const { owner, idiPersons, mailLetter, isModalOpen, setIsModalOpen } = props;

  const [loading, setLoading] = useState(false);
  const [mailTemplates, setMailTemplates] = useState([]);
  const [idiPerson, setIdiPerson] = useState(null);
  const [idiPersonAddresses, setIdiPersonAddresses] = useState([]);
  const [addressValidationResult, setAddressValidationResult] = useState(null);
  const [addressValidationErrors, setAddressValidationErrors] = useState([]);
  const [forceSubmit, setForceSubmit] = useState(false);

  async function getMailTemplates() {
    const res = await apiBase.get("/mail-templates");
    setMailTemplates(get(res.data, "mail_templates", []));
  }

  useEffect(() => {
    getMailTemplates();
  }, []);

  const validateAddress = async () => {
    setLoading(true);
    try {
      const values = form.getFieldsValue(true);
      const search_data = {
        street_address: values.street,
        city: values.city,
        state: values.state,
        zip: values.zip_code,
      };

      const res = await apiBase.post(`/usps-searches`, {
        ...search_data,
      });

      const statusCode = get(res, "data.response_status_code");
      const responseBody = get(res, "data.response_body");

      setAddressValidationResult(get(res, "data", {}));

      const uspsAddress = get(responseBody, "address");
      const additionalInfo = get(responseBody, "additionalInfo");

      const isVacant = get(additionalInfo, "vacant", null);
      const dpvConfirmation = get(additionalInfo, "DPVConfirmation", null);

      const zipCode = get(uspsAddress, "ZIPCode", "") || "";
      const zip4 = get(uspsAddress, "ZIPPlus4", "") || "";
      const deliveryPoint = get(additionalInfo, "deliveryPoint", "") || "";
      const zip11 = `${zipCode}${zip4}${deliveryPoint}`;

      let _addressValidationErrors = [];
      if (statusCode !== 200) {
        _addressValidationErrors.push(`Error: Status code "${statusCode}".`);
      }

      if (dpvConfirmation !== "Y" && dpvConfirmation !== null) {
        _addressValidationErrors.push(
          `Error: DPVConfirmation "${dpvConfirmation}".`
        );
      }

      if (isVacant !== "N" && isVacant !== null) {
        _addressValidationErrors.push(`Error: Vacant "${isVacant}".`);
      }

      if (zip11.length !== 11) {
        _addressValidationErrors.push(`Error: ZIP11 "${zip11}".`);
      }

      setAddressValidationErrors(_addressValidationErrors);

      if (_addressValidationErrors.length > 0) {
        message.warning("There are address validation errors.");
      } else {
        message.success("Successfully validated address.");
      }

      return _addressValidationErrors;
    } catch (err) {
      let msg = `${get(
        err,
        "response.status",
        "Unknown"
      )}: Failed to validate address`;
      console.log(err);
      message.error(msg);
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    if (!forceSubmit) {
      const _addressValidationErrors = await validateAddress(values);
      if (_addressValidationErrors.length > 0 || !_addressValidationErrors) {
        setLoading(false);
        return;
      }
    }

    apiBase
      .post(`mail-letters`, {
        ...values,
        mail_letter_id: mailLetter.id,
        owner_id: owner.id,
      })
      .then(async (res) => {
        message.success("Successfully created mail letter.");
        await props.getOwnerLetters();
        setIsModalOpen(false);
        resetComponent();
      })
      .catch((err) => {
        console.log(err);
        let msg = `${err.response.status}: Failed to create mail letter.`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    resetComponent();
  };

  const submitForm = () => {
    form
      .validateFields()
      .then(() => {
        form.submit();
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };

  const resetComponent = () => {
    form.resetFields();
  };

  const resetValidation = () => {
    setAddressValidationResult(null);
    setAddressValidationErrors([]);
    setForceSubmit(false);
  };

  const onFormChange = async (changedValues, allValues) => {
    const { idi_person_id, idi_person_address_id } = changedValues;

    if (idi_person_id !== undefined) {
      const idiPerson = idiPersons.find((p) => p.id === idi_person_id);
      const idiPersonData = get(idiPerson, "data", {});
      const addresses = get(idiPersonData, "address", []);
      setIdiPersonAddresses(addresses);
    }

    // Reset address fields if IDI Person is changed
    if ("idi_person_id" in changedValues) {
      form.setFieldsValue({
        idi_person_address_id: undefined,
        street: undefined,
        city: undefined,
        state: undefined,
        zip_code: undefined,
      });
      resetValidation();
    }

    // Set address fields if IDI Person address is changed
    if ("idi_person_address_id" in changedValues) {
      const address = get(idiPersonAddresses, `[${idi_person_address_id}]`, {});
      form.setFieldsValue({
        street: get(address, "complete"),
        city: get(address, "city"),
        state: get(address, "state"),
        zip_code: get(address, "zip"),
      });
      resetValidation();
    }
  };

  const [form] = Form.useForm();

  return (
    <div>
      <Modal
        title="Create mail letter"
        visible={isModalOpen}
        onOk={() => submitForm()}
        onCancel={handleCancel}
        cancelButtonProps={{ disabled: loading }}
        okButtonProps={{ disabled: loading, loading: loading }}
        maskClosable={false}
        width={"97.5%"}
        bodyStyle={{ padding: "12px 24px" }}
      >
        <Row gutter={16}>
          <Col span={6} xs={24} sm={24} md={24} lg={24} xl={24} xxl={6}>
            <Form
              name="mailLetterCreate"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              onValuesChange={onFormChange}
              autoComplete="off"
              layout="vertical"
              disabled={loading}
              initialValues={{
                name: get(owner, "name_formatted", null),
                mail_template_id: get(mailLetter, "mail_template_id", null),
                fractional_ownership: 1,
              }}
            >
              <Form.Item
                label="Type"
                name="type"
                rules={[
                  {
                    required: true,
                    message: "Type is required.",
                  },
                ]}
              >
                <Select
                  options={mailLetterTypeOptions.filter(
                    (option) => option.value !== mailLetterType.standard
                  )}
                />
              </Form.Item>
              <Form.Item
                label="Mail template"
                name="mail_template_id"
                rules={[
                  {
                    required: true,
                    message: "A mail template is required",
                  },
                ]}
              >
                <Select>
                  {mailTemplates.map((mailTemplate, idx) => (
                    <Select.Option value={mailTemplate.id} key={idx}>
                      {mailTemplate.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="IDI Person" name="idi_person_id">
                <Select
                  allowClear
                  showSearch
                  placeholder="Select IDI Person"
                  optionFilterProp="label"
                  options={idiPersons.map((idiPerson) => {
                    const isMainIDIPerson =
                      get(idiPerson, "id") === owner.last_idi_person_id;

                    let label = `${idiPerson.name} [${idiPerson.id}]`;
                    if (isMainIDIPerson) {
                      label = `${label} [Main]`;
                    }

                    return {
                      label: label,
                      value: idiPerson.id,
                    };
                  })}
                  onChange={(value) => {
                    setIdiPerson(idiPersons.find((p) => p.id === value));
                  }}
                />
              </Form.Item>
              <Form.Item
                label="IDI Person address"
                name="idi_person_address_id"
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select IDI Person address"
                  optionFilterProp="label"
                  options={idiPersonAddresses.map((address, idx) => ({
                    label: `${get(address, "data", "")} [${idx}]`,
                    value: idx,
                  }))}
                />
              </Form.Item>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Name is required.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={
                  <span>
                    Name Attention&nbsp;
                    <Tooltip
                      title="This name will be added below the name on the letter e.g. Betty Thompson Estate Attn: Bill Thompson."
                      placement="rightTop"
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                name="name_attn"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Street"
                name="street"
                rules={[
                  {
                    required: true,
                    message: "Street is required.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="City"
                name="city"
                rules={[
                  {
                    required: true,
                    message: "City is required.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="State"
                name="state"
                rules={[
                  {
                    required: true,
                    message: "State is required.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Zip Code"
                name="zip_code"
                rules={[
                  {
                    required: true,
                    message: "Zip code is required.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="fractional_ownership"
                label={
                  <span>
                    Fractional ownership&nbsp;
                    <Tooltip
                      title="This will be used as a multiplier against any dollar amounts on the letter. This should mostly be 1 unless you are confident about the fractional ownership."
                      placement="rightTop"
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
              >
                <InputNumber
                  className="App__fullWidth"
                  precision={6}
                  min={0}
                  max={1}
                  step={0.000001}
                />
              </Form.Item>

              {addressValidationErrors.length > 0 && (
                <Form.Item
                  label={
                    <span>
                      Address validation errors&nbsp;
                      <Tooltip
                        overlayStyle={{ width: "800px" }}
                        overlayInnerStyle={{ width: "800px" }}
                        title={
                          <pre>
                            {JSON.stringify(addressValidationResult, null, 2)}
                          </pre>
                        }
                        placement="rightTop"
                      >
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </span>
                  }
                >
                  {addressValidationErrors.map((error) => (
                    <Alert
                      key={error}
                      message={error}
                      type="error"
                      style={{ marginBottom: "5px" }}
                    />
                  ))}
                  <Checkbox
                    checked={forceSubmit}
                    onChange={(e) => setForceSubmit(e.target.checked)}
                  >
                    Force submit
                  </Checkbox>
                </Form.Item>
              )}
            </Form>
          </Col>
          <Col
            span={18}
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            xxl={18}
            style={
              idiPerson
                ? {}
                : {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }
            }
          >
            {idiPerson ? (
              <IDIPersonCard idiPerson={idiPerson} />
            ) : (
              <Empty description="No IDI Person selected" />
            )}
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OwnerMailletterCreate);
