import React from "react";
import { get } from "lodash";
import { Row, Col, Input, Select } from "antd";
import { formatDate } from "../../utils/dates";

import { reviewStatusesOptions } from "../../common/unitReviewStatus";

import { WarningTwoTone } from "@ant-design/icons";

import styles from "./UnitEdit.module.scss";

const UnitEdit = (props) => {
  const {
    unit,
    yearCumulativeDOI,
    yearOwnerCount,
    yearMedianOwnerCount,
    handleUnitEditChange,
    handleUnitEditSubmit,
    setUnitEdit,
    isUnitEdit,
  } = props;

  const meObjectLinks = get(unit, "me_object_links", []);

  return (
    <>
      <Row>
        <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
          <div className={styles.objectData}>
            <span>Id:</span>
            {unit.id}
          </div>
          <div className={styles.objectData}>
            <span>Name formatted:</span>
            <div onClick={() => setUnitEdit()}>
              <Input
                value={unit.name_formatted}
                onChange={(e) => handleUnitEditChange(e)}
                onPressEnter={(e) => {
                  handleUnitEditSubmit();
                  e.target.blur();
                }}
                className={
                  isUnitEdit ? styles.inputActive : styles.inputDisabled
                }
                name="name_formatted"
                type="string"
                size="small"
                autoComplete="off"
              />
            </div>
          </div>
          <div className={styles.objectData}>
            <span>Name:</span>
            {unit.name}
          </div>
          <div className={styles.objectData}>
            <span>Operator:</span>
            <div onClick={() => setUnitEdit()}>
              <Input
                value={unit.operator}
                onChange={(e) => handleUnitEditChange(e)}
                onPressEnter={(e) => {
                  handleUnitEditSubmit();
                  e.target.blur();
                }}
                className={
                  isUnitEdit ? styles.inputActive : styles.inputDisabled
                }
                name="operator"
                type="string"
                size="small"
                autoComplete="off"
              />
            </div>
          </div>
          <div className={styles.objectData}>
            <span>Well:</span>
            {unit.well}
          </div>
          <div className={styles.objectData}>
            <span>County:</span>
            {unit.county}
          </div>
          <div className={styles.objectData}>
            <span>RRC ID:</span>
            {unit.rrc_id}
          </div>
          <div className={styles.objectData}>
            <span>Review status:</span>
            <div onClick={() => setUnitEdit()}>
              <Select
                value={unit.review_status}
                className="App__w8"
                name="review_status"
                size="small"
                onChange={(value) =>
                  handleUnitEditChange({
                    target: { name: "review_status", value },
                  })
                }
                options={reviewStatusesOptions}
              />
            </div>
          </div>
          <div className={styles.objectData}>
            <span>Reviewed at:</span>
            {unit.review_at && formatDate(unit.review_at, "MM/DD/YYYY")}
          </div>
        </Col>
        <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
          <div className={styles.objectData}>
            <span>Cumulative DOI:</span>
            {yearCumulativeDOI
              ? yearCumulativeDOI
              : unit.cumulative_decimal_interest}
          </div>
          <div className={styles.objectData}>
            <span>Owner Count:</span>
            {yearOwnerCount ? yearOwnerCount : unit.unique_owner_count}
          </div>
          <div className={styles.objectData}>
            <span>Median owner unit count:</span>
            {yearMedianOwnerCount
              ? yearMedianOwnerCount
              : unit.median_owner_unit_count}
          </div>
          <div className={styles.objectData}>
            <span>Unit Size (Acres):</span>
            <div onClick={() => setUnitEdit()}>
              <Input
                value={unit.acres}
                onChange={(e) => handleUnitEditChange(e)}
                onPressEnter={(e) => {
                  handleUnitEditSubmit();
                  e.target.blur();
                }}
                className={
                  isUnitEdit ? styles.inputActive : styles.inputDisabled
                }
                name="acres"
                type="number"
                size="small"
                autoComplete="off"
              />
            </div>
          </div>
          <div className={styles.objectData}>
            <span>Tax Year:</span>
            {unit.tax_year}
          </div>
          <div className={styles.objectData}>
            <span>Tax Year first:</span>
            {unit.tax_year_first}
          </div>
          <div className={styles.objectData}>
            <span>Tax Years:</span>
            {unit.tax_years ? unit.tax_years.sort().reverse().join(", ") : ""}
          </div>
          <div className={styles.objectData}>
            <span>Tax Year Count:</span>
            {unit.tax_year_count}
          </div>
          <div className={styles.objectData}>
            <span>Previous database offer:</span>
            {meObjectLinks.length > 0 && (
              <div className={styles.previouslyOffered}>
                <WarningTwoTone twoToneColor="#faad14" />
                {meObjectLinks.map((value, idx) => {
                  return (
                    <div key={idx} className={styles.oldId}>
                      {get(value, "me_record_id")}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <div className={`${styles.objectData} ${styles.legalDescription}`}>
            <span>Legal description:</span>
            {unit.legal_description}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default UnitEdit;
