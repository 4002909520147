import React from "react";
import styles from "./LabelIcon.module.scss";

function LabelIcon(props) {
  const { content, backgroundColor, className, color = "white" } = props;

  return (
    <div
      className={`${styles.base} ${className}`}
      style={{ backgroundColor, color }}
    >
      {content}
    </div>
  );
}

function DeceasedIcon({ value }) {
  if ([true, "true"].includes(value)) {
    return <LabelIcon content={"D"} className={styles.dead} />;
  } else if ([false, "false"].includes(value)) {
    return <>-</>;
  } else {
    return <></>;
  }
}

function NTFIcon({ value }) {
  if ([true, "true"].includes(value)) {
    return <LabelIcon content={"NTF"} backgroundColor={"var(--pink)"} />;
  } else if ([false, "false"].includes(value)) {
    return <>-</>;
  } else {
    return <></>;
  }
}

function UnitReviewStatusIcon({ value }) {
  if (["good"].includes(value)) {
    return <LabelIcon content={"Good"} backgroundColor={"var(--green)"} />;
  } else if (["bad"].includes(value)) {
    return <LabelIcon content={"Bad"} backgroundColor={"var(--red)"} />;
  } else {
    return <></>;
  }
}

function DecileIcon({ value }) {
  if (value === 10) {
    return (
      <LabelIcon
        content={value}
        backgroundColor={"var(--green)"}
        className={styles.decile}
      />
    );
  } else if (value === 9) {
    return (
      <LabelIcon
        content={value}
        backgroundColor={"var(--green)"}
        className={styles.decile}
      />
    );
  } else if (value >= 4) {
    return (
      <LabelIcon
        content={value}
        backgroundColor={"var(--orange)"}
        className={styles.decile}
      />
    );
  } else {
    return (
      <LabelIcon
        content={value}
        backgroundColor={"var(--red)"}
        className={styles.decile}
      />
    );
  }
}

function AssetCountyCountIcon({ value }) {
  return (
    <LabelIcon
      content={value}
      className={styles.assetCount}
      color={"var(--primaryColor)"}
    />
  );
}

export {
  LabelIcon,
  DeceasedIcon,
  NTFIcon,
  UnitReviewStatusIcon,
  DecileIcon,
  AssetCountyCountIcon,
};
