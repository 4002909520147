import React, { useState } from "react";
import { get, cloneDeep } from "lodash";
import {
  Drawer,
  Button,
  Col,
  Row,
  Input,
  Select,
  InputNumber,
  Checkbox,
  Divider,
  Form,
} from "antd";

import {
  updateOwner,
  updateUnit,
  updateLead,
} from "../../views/utils/LeadUtils";

const LeadsEditForm = ({
  handleLeadCancel,
  lead,
  visible,
  leadStatus,
  users,
  executeSearch,
}) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const isGenericLead = !!get(lead, "generic_file_record_id");

  const onFinish = async (values) => {
    setLoading(true);

    const oldLead = cloneDeep(lead);
    const newLead = { ...oldLead, ...values };

    const newOwner = { ...oldLead.owner, ...values.owner };
    newLead["owner"] = newOwner;

    if (!isGenericLead) {
      const newUnit = { ...oldLead.unit, ...values.unit };
      newLead["unit"] = newUnit;
      await Promise.all([
        updateLead(oldLead, newLead),
        updateOwner(oldLead.owner, newOwner),
        updateUnit(oldLead.unit, newUnit),
      ]);
    } else {
      // TODO Add generic file record update
      await Promise.all([
        updateLead(oldLead, newLead),
        updateOwner(oldLead.owner, newOwner),
      ]);
    }

    setLoading(false);
    executeSearch();
    handleLeadCancel();
  };

  return (
    <div>
      <Drawer
        title="Edit Lead and associated objects"
        width={720}
        onClose={handleLeadCancel}
        visible={visible}
      >
        <Form
          form={form}
          layout="vertical"
          disabled={loading}
          onFinish={onFinish}
          initialValues={{
            offer_amount: lead.offer_amount,
            user_id: lead.user_id,
            status: lead.status,
            owner: {
              name: lead.owner.name,
              name_ico: lead.owner.name_ico,
              name_formatted: lead.owner.name_formatted,
              first_name: lead.owner.first_name,
              middle_name: lead.owner.middle_name,
              last_name: lead.owner.last_name,
              ntf: lead.owner.ntf,
              address: lead.owner.address,
              address_formatted: lead.owner.address_formatted,
              city: lead.owner.city,
              state: lead.owner.state,
              zip_code: lead.owner.zip_code,
            },
            unit: {
              name_formatted: lead.unit?.name_formatted,
              operator: lead.unit?.operator,
            },
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="offer_amount"
                label="Offer Amount"
                rules={[
                  {
                    required: !isGenericLead,
                    message: "Please enter an offer amount.",
                  },
                ]}
              >
                <InputNumber
                  className="App__fullWidth"
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  autoComplete="off"
                />
              </Form.Item>
              <Form.Item
                name="user_id"
                label="Lead Owner"
                rules={[
                  {
                    required: true,
                    message: "Please provide a lead owner.",
                  },
                ]}
              >
                <Select>
                  {users.map((user, idx) => (
                    <Select.Option value={user.id} key={idx}>
                      {user.alias}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="status"
                label="Status"
                rules={[
                  {
                    required: true,
                    message: "Please provide a lead status.",
                  },
                ]}
              >
                <Select>
                  {leadStatus.map((status, idx) => (
                    <Select.Option value={status} key={idx}>
                      {status}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left" style={{ fontSize: 12 }}>
            Owner
          </Divider>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name={["owner", "name"]} label="Name">
                <Input disabled autoComplete="off" />
              </Form.Item>
              <Form.Item name={["owner", "name_ico"]} label="Name ICO">
                <Input disabled autoComplete="off" />
              </Form.Item>
              <Form.Item
                name={["owner", "name_formatted"]}
                label="Formatted Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter a formatted name.",
                  },
                ]}
              >
                <Input
                  autoComplete="off"
                  onPressEnter={(e) => {
                    form.submit();
                    e.target.blur();
                  }}
                />
              </Form.Item>
              <Form.Item name={["owner", "first_name"]} label="First name">
                <Input
                  autoComplete="off"
                  onPressEnter={(e) => {
                    form.submit();
                    e.target.blur();
                  }}
                />
              </Form.Item>
              <Form.Item name={["owner", "last_name"]} label="Last name">
                <Input
                  autoComplete="off"
                  onPressEnter={(e) => {
                    form.submit();
                    e.target.blur();
                  }}
                />
              </Form.Item>
              <Form.Item
                name={["owner", "ntf"]}
                label="NTF"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={["owner", "address"]} label="Address">
                <Input
                  disabled
                  autoComplete="off"
                  onPressEnter={(e) => {
                    form.submit();
                    e.target.blur();
                  }}
                />
              </Form.Item>
              <Form.Item
                name={["owner", "address_formatted"]}
                label="Formatted address"
              >
                <Input
                  autoComplete="off"
                  onPressEnter={(e) => {
                    form.submit();
                    e.target.blur();
                  }}
                />
              </Form.Item>
              <Form.Item name={["owner", "city"]} label="City">
                <Input
                  autoComplete="off"
                  onPressEnter={(e) => {
                    form.submit();
                    e.target.blur();
                  }}
                />
              </Form.Item>
              <Form.Item name={["owner", "state"]} label="State">
                <Input
                  autoComplete="off"
                  onPressEnter={(e) => {
                    form.submit();
                    e.target.blur();
                  }}
                />
              </Form.Item>
              <Form.Item name={["owner", "zip_code"]} label="Zip code">
                <Input
                  autoComplete="off"
                  onPressEnter={(e) => {
                    form.submit();
                    e.target.blur();
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          {!isGenericLead && (
            <>
              <Divider orientation="left" style={{ fontSize: 12 }}>
                Unit
              </Divider>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name={["unit", "name_formatted"]}
                    label="Name formatted"
                    rules={[
                      {
                        required: true,
                        message: "Please enter a unit name formatted.",
                      },
                    ]}
                  >
                    <Input
                      autoComplete="off"
                      onPressEnter={(e) => {
                        form.submit();
                        e.target.blur();
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={["unit", "operator"]}
                    label="Operator"
                    rules={[
                      {
                        required: true,
                        message: "Please enter a unit operator.",
                      },
                    ]}
                  >
                    <Input
                      autoComplete="off"
                      onPressEnter={(e) => {
                        form.submit();
                        e.target.blur();
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form>
        <div
          style={{
            position: "absolute",
            left: 0,
            bottom: 0,
            width: "100%",
            borderTop: "1px solid #e9e9e9",
            padding: "10px 16px",
            background: "#fff",
            textAlign: "right",
          }}
        >
          <Button
            onClick={handleLeadCancel}
            style={{ marginRight: 8 }}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            onClick={() => form.submit()}
            loading={loading}
            type="primary"
          >
            Submit
          </Button>
        </div>
      </Drawer>
    </div>
  );
};

export default LeadsEditForm;
