export const defaultArrayComparisonOptions = [
  {
    label: "In",
    value: "in",
  },
  {
    label: "Not in",
    value: "nin",
  },
];

export const arrayContainsOperators = [
  {
    label: "Overlaps",
    value: "overlaps",
  },
  {
    label: "Does not overlap",
    value: "noverlaps",
  },
  {
    label: "Contains",
    value: "contains",
  },
  {
    label: "Does not contain",
    value: "ncontains",
  },
  {
    label: "Empty",
    value: "empty",
  },
  {
    label: "Is not empty",
    value: "nempty",
  },
];
