import React, { useState } from "react";
import { connect } from "react-redux";
import { get } from "lodash";

import { apiBase } from "../../utils/apiBase";
import { formatDate } from "../../utils/dates";
import { formatNumber, alphaSort } from "../../utils/numbers";
import {
  mailLetterType,
  mailLetterTypeTitle,
  mailLetterTypeColor,
} from "../../common/mailLetterType";

import { LabelIcon } from "../common/LabelIcon";
import OwnerMailletterCreate from "./OwnerMailletterCreate";

import { Table, Button, message, Modal } from "antd";

import {
  DownloadOutlined,
  PlusOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";

const { confirm } = Modal;

const mapStateToProps = (state) => ({
  users: get(state.users, "data", []),
});

const OwnerMailLetters = (props) => {
  const {
    owner,
    idiPersons,
    users,
    isLoadingOwnerLetters: parentLoading,
  } = props;

  const [loading, setLoading] = useState(false);
  const [mailLetter, setMailLetter] = useState(null);
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const handleRowClick = (e, _to) => {
    e.stopPropagation();
    if (e.ctrlKey || e.metaKey) {
      window.open(_to, "_blank");
      return;
    }
    props.history(_to);
  };

  const deleteMailLetter = async (id) => {
    setLoading(true);
    apiBase
      .delete(`/mail-letters/${id}`)
      .then((res) => {
        let msg = `${res.status}: Successfully deleted mail letter.`;
        message.success(msg);
        props.getOwnerLetters();
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to delete mail letter.`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: "Are you sure you want to delete this mail letter?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      okButtonProps: {
        disabled: loading,
        loading: loading,
      },
      cancelButtonProps: {
        disabled: loading,
        loading: loading,
      },
      onOk: () => deleteMailLetter(id),
    });
  };

  const columns = [
    {
      title: "Letter",
      dataIndex: "id",
      className: `App__rowLink App__w8`,
      render: (value) => <div className="App__rowIcon">{value}</div>,
      onCell: (record) => ({
        onClick: (e) => handleRowClick(e, `/mail-letters/${record["id"]}`),
      }),
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Mail Export",
      dataIndex: "mail_export_id",
      className: `App__rowLink App__w10`,
      render: (value) => <div className="App__rowIcon">{value}</div>,
      onCell: (record) => ({
        onClick: (e) => {
          if (record["mail_export_id"]) {
            handleRowClick(e, `/mail-exports/${record["mail_export_id"]}`);
          }
        },
      }),
      sorter: (a, b) => a.mail_export_id - b.mail_export_id,
    },
    {
      title: "Mail Template",
      dataIndex: "mail_template_id",
      className: `App__rowLink App__w12`,
      render: (value) => <div className="App__rowIcon">{value}</div>,
      onCell: (record) => ({
        onClick: (e) => {
          if (record["mail_template_id"]) {
            handleRowClick(
              e,
              `/settings/mail-templates/${record["mail_template_id"]}`
            );
          }
        },
      }),
      sorter: (a, b) => a.mail_template_id - b.mail_template_id,
    },
    {
      title: "IDI Person",
      dataIndex: "idi_person_id",
      className: `App__rowLink App__w10`,
      render: (value) => <div className="App__rowIcon">{value}</div>,
      onCell: (record) => ({
        onClick: (e) => {
          if (record["idi_person_id"]) {
            handleRowClick(e, `/idi-persons/${record["idi_person_id"]}`);
          }
        },
      }),
      sorter: (a, b) => a.idi_person_id - b.idi_person_id,
    },
    {
      title: "Letter URL",
      dataIndex: "download_url",
      className: `App__rowLink App__w12`,
      render: (value) =>
        value ? (
          <div className="App__rowIcon">
            <DownloadOutlined />
          </div>
        ) : null,
      onCell: (record) => ({
        onClick: () => {
          if (record["download_url"]) {
            window.open(record["download_url"], "_blank");
          }
        },
      }),
    },
    {
      title: "Type",
      dataIndex: "type",
      sorter: (a, b) => alphaSort(a.type, b.type),
      render: (value) => (
        <LabelIcon
          content={mailLetterTypeTitle[value]}
          backgroundColor={mailLetterTypeColor[value]}
          color={value === mailLetterType.standard ? "black" : "white"}
        />
      ),
    },
    {
      title: "Created by",
      dataIndex: "user_id",
      sorter: (a, b) => a.user_id - b.user_id,
      render: (value, record) => {
        const user = users.find((user) => user.id === value);
        return user ? user.alias : null;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => alphaSort(a.name, b.name),
    },
    {
      title: "Name Attn",
      dataIndex: "name_attn",
      sorter: (a, b) => alphaSort(a.name_attn, b.name_attn),
    },
    {
      title: "Street",
      dataIndex: "street",
      sorter: (a, b) => alphaSort(a.street, b.street),
    },
    {
      title: "City",
      dataIndex: "city",
      sorter: (a, b) => alphaSort(a.city, b.city),
    },
    {
      title: "State",
      dataIndex: "state",
      sorter: (a, b) => alphaSort(a.state, b.state),
    },
    {
      title: "Zip",
      dataIndex: "zip_code",
      sorter: (a, b) => alphaSort(a.zip_code, b.zip_code),
    },
    {
      title: "Fractional Ownership",
      dataIndex: "fractional_ownership",
      sorter: (a, b) => a.fractional_ownership - b.fractional_ownership,
      render: (value) => formatNumber(value, "0.00%"),
    },
    {
      title: "Leads",
      dataIndex: "lead_count",
      sorter: (a, b) => a["leads"].length - b["leads"].length,
      render: (_, record) => {
        return record["leads"].length;
      },
    },
    {
      title: "Total NRA",
      dataIndex: "total_nra",
      sorter: (a, b) =>
        a["leads"].reduce((acc, val) => (acc += val["nra"]), 0) -
        b["leads"].reduce((acc, val) => (acc += val["nra"]), 0),
      render: (_, record) => {
        return formatNumber(
          record["leads"].reduce((acc, val) => (acc += val["nra"]), 0) *
            (get(record, "fractional_ownership", 1) || 1),
          "0.00"
        );
      },
    },
    {
      title: "Offer amount",
      dataIndex: "total_offer",
      sorter: (a, b) =>
        a["leads"].reduce((acc, val) => (acc += val["offer_amount"]), 0) -
        b["leads"].reduce((acc, val) => (acc += val["offer_amount"]), 0),
      render: (_, record) => {
        return `$${formatNumber(
          record["leads"].reduce(
            (acc, val) => (acc += val["offer_amount"]),
            0
          ) * (get(record, "fractional_ownership", 1) || 1),
          "0,0.00"
        )}`;
      },
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => alphaSort(a.created_at, b.created_at),
      render: (value) => formatDate(value, "YYYY-MM-DD"),
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "actions",
      width: 75,
      render: (_, record) => {
        return (
          <>
            {!record.mail_export_id && (
              <Button
                shape="circle"
                icon={<DeleteOutlined />}
                size="small"
                onClick={() => showDeleteConfirm(record.id)}
                disabled={loading || parentLoading}
              />
            )}
            {record.type === mailLetterType.standard && (
              <Button
                type="secondary"
                size="small"
                icon={<PlusOutlined />}
                onClick={() => {
                  setMailLetter(record);
                  setCreateModalOpen(true);
                }}
              />
            )}
          </>
        );
      },
    },
  ];

  const { letters, recordsPerPage, isLoadingInterests } = props;

  return (
    <>
      <Table
        columns={columns}
        dataSource={letters}
        className="App__smallTables"
        showSorterTooltip={false}
        pagination={{ pageSize: recordsPerPage, showSizeChanger: false }}
        loading={isLoadingInterests}
        scroll={{ x: "100%" }}
        bordered
      />
      <OwnerMailletterCreate
        isModalOpen={createModalOpen}
        setIsModalOpen={setCreateModalOpen}
        getOwnerLetters={props.getOwnerLetters}
        owner={owner}
        idiPersons={idiPersons}
        mailLetter={mailLetter}
        refetch={props.refetch}
      />
    </>
  );
};

export default connect(mapStateToProps)(OwnerMailLetters);
