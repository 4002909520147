import React, { useState } from "react";
import { withRouter } from "../../utils/withRouter";
import { apiBase } from "../../utils/apiBase";
import { get } from "lodash";

import { Button, Form, Modal, message, Input, Tooltip, Table } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const PermitPlatCreateForm = (props) => {
  const { selectedPermits, onSuccess, history } = props;
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    form.resetFields();
    setVisible(false);
  };

  const onFinish = (values) => {
    setLoading(true);
    apiBase
      .post(`/permit-plats/bulk`, {
        name: values.name,
        permit_ids: selectedPermits.map((permit) => permit.id),
      })
      .then((res) => {
        let msg = `${res.status}: Successfully created permit plat.`;
        message.success(msg);
        if (onSuccess) onSuccess(res.data);
        handleCancel();
        history(`/permit-plats/${res.data.id}`);
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
        setLoading(false);
      });
  };

  const onFinishFailed = () => {
    message.error("Please check the form for errors");
  };

  return (
    <>
      <Tooltip placement="bottomRight" title={"Create permit plat"}>
        <Button
          onClick={() => setVisible(true)}
          className="App__iconButton App__mr-5"
        >
          <PlusOutlined />
        </Button>
      </Tooltip>
      <Modal
        title="Create Permit Plat"
        open={visible}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel} disabled={loading}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => form.submit()}
          >
            Create
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Plat Name"
            name="name"
            rules={[{ required: true, message: "Please enter a plat name" }]}
          >
            <Input placeholder="Enter plat name" />
          </Form.Item>
          <Table
            dataSource={selectedPermits.map((permit) => ({
              key: permit.id,
              id: permit.id,
              lease: permit.lease,
            }))}
            columns={[
              { title: "ID", dataIndex: "id" },
              { title: "Lease", dataIndex: "lease" },
            ]}
            pagination={false}
            className="App__smallTables"
            size="small"
            bordered
          />
        </Form>
      </Modal>
    </>
  );
};

export default withRouter(PermitPlatCreateForm);
