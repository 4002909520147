import * as turf from "@turf/turf";

const fitMapToBounds = (mapRef, features) => {
  if (!mapRef.current) return;

  const bbox = turf.bbox(features);
  mapRef.current.fitBounds(
    [
      [bbox[0], bbox[1]],
      [bbox[2], bbox[3]],
    ],
    {
      padding: 100,
      animate: false,
    }
  );
};

export { fitMapToBounds };
