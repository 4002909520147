import React, { useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import debounce from "lodash/debounce";
import { get, find } from "lodash";
import { apiBase } from "../../utils/apiBase";
import { stringifyFilters } from "../../utils/queryParser";
import { stringify } from "query-string";

import { Select, Spin } from "antd";

import styles from "./SearchSelect.module.scss";

const mapStateToProps = (state) => ({
  entities: get(state.entities, "data", []),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

async function fetchData({
  searchUri,
  searchField,
  query,
  renderLabel = (row) => row.name,
  renderExtra = true,
  entities,
}) {
  const queryString = stringify({
    limit: query.limit ? parseInt(query.limit, 10) : 25,
    page: query.page ? parseInt(query.page, 10) : 1,
    filters: stringifyFilters({ [searchField]: query }),
  });

  return apiBase
    .get(`${searchUri}?${queryString}`)
    .then((res) => {
      const dataKey = searchUri.replace(/-/g, "_");
      return get(res, `data.${dataKey}`, []).map((row) => {
        const entity = find(entities, { id: row.entity_id });
        return {
          value: row.id,
          label: (
            <div className={styles.optionSimple}>
              <div className={styles.main}>{renderLabel(row)}</div>
              {renderExtra && (
                <div className={styles.extra}>
                  {!!entity ? `[${row.id}, ${entity.name}]` : `${row.id}`}
                </div>
              )}
            </div>
          ),
        };
      });
    })
    .catch((err) => {
      console.log(err);
    });
}

function SearchSelect({
  debounceTimeout = 400,
  fetchOptions = fetchData,
  searchUri = "assets",
  searchField = "name",
  renderLabel = (row) => row.name,
  renderExtra = true,
  defaultOptions = [],
  ...props
}) {
  const entities = get(props, "entities", []);

  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState(defaultOptions);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (query) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions({
        searchUri,
        searchField,
        query,
        entities,
        renderLabel,
        renderExtra,
      }).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchOptions, debounceTimeout, searchUri, searchField, entities]);

  return (
    <Select
      placeholder="Select items"
      className="App__fullWidth"
      // dropdownStyle={{ height: "600px" }}
      listHeight={400}
      allowClear
      showSearch
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchSelect);
