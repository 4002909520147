import { MAPBOX_USER_ID } from "../constants";

const SOURCE_ID = "enverus_landtrac_units";
const LAYER_ID = "enverus_landtrac_units";
const BORDER_HIGHLIGHT_LAYER_ID = "enverus_landtrac_units_highlight";
const FILTER_LAYER_IDS = [LAYER_ID];
const CLICKABLE_LAYERS = [LAYER_ID];
const UNIQUE_COLUMN = "api_uwi_10";

const API_BASE_ENDPOINT = "enverus-landtrac-units";

const INITIAL_FILTER_STATE = {
  county: {
    column: "county",
    label: "County",
    value: [],
  },
  unit_name: {
    column: "unit_name",
    label: "Unit Name",
    value: [],
  },
  api_uwi_10: {
    column: "api_uwi_10",
    label: "API 10",
    value: [],
  },
  rrc_id: {
    column: "rrc_id",
    label: "RRC ID",
    value: [],
  },
};

const STYLES = {
  "fill-outline-color": "#000000",
  "fill-color": "#f0ad4e",
  "fill-opacity": 0.3,
};

const BORDER_HIGHLIGHT_STYLES = {
  "line-color": "#000",
  "line-width": 2,
};

const ADD_BEFORE = "Laterals";

const addSource = (mapRef) => {
  if (!mapRef.current.getSource(SOURCE_ID)) {
    mapRef.current.addSource(SOURCE_ID, {
      type: "vector",
      url: `mapbox://${MAPBOX_USER_ID}.${SOURCE_ID}`,
    });
  }
};

const addLayers = (mapRef) => {
  if (!mapRef.current.getLayer(LAYER_ID)) {
    mapRef.current.addLayer(
      {
        id: LAYER_ID,
        type: "fill",
        source: SOURCE_ID,
        "source-layer": SOURCE_ID,
        layout: {
          visibility: "none",
        },
        paint: STYLES,
      },
      ADD_BEFORE
    );
  }

  if (!mapRef.current.getLayer(BORDER_HIGHLIGHT_LAYER_ID)) {
    mapRef.current.addLayer(
      {
        id: BORDER_HIGHLIGHT_LAYER_ID,
        type: "line",
        source: SOURCE_ID,
        "source-layer": SOURCE_ID,
        layout: {},
        paint: BORDER_HIGHLIGHT_STYLES,
        filter: ["in", UNIQUE_COLUMN, ""],
      },
      ADD_BEFORE
    );
  }
};

const addAll = (mapRef) => {
  addSource(mapRef);
  addLayers(mapRef);
};

export {
  SOURCE_ID,
  LAYER_ID,
  BORDER_HIGHLIGHT_LAYER_ID,
  FILTER_LAYER_IDS,
  CLICKABLE_LAYERS,
  UNIQUE_COLUMN,
  STYLES,
  BORDER_HIGHLIGHT_STYLES,
  API_BASE_ENDPOINT,
  INITIAL_FILTER_STATE,
  addAll,
};
