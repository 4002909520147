import { createStore, compose, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { reducers, initialState } from "./actions";

const rootReducer = combineReducers({
  ...reducers,
});

const enhancers = [];
const middleware = [thunk];

if (process.env.NODE_ENV === "development") {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }

  const { createLogger } = require("redux-logger");
  const logger = createLogger({
    predicate: (getState, action) =>
      !["SET_EDITOR_SERVER"].includes(action.type),
  });

  middleware.push(logger);
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);
const store = createStore(rootReducer, initialState, composedEnhancers);

export default store;
