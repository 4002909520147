import React, { useState, useEffect } from "react";
import { convertDataIndex } from "../../utils/search";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import moment from "moment";

import { Button, Dropdown, DatePicker } from "antd";

import styles from "./Filters.module.scss";

const Mousetrap = require("mousetrap");

const DateRangeDropdown = (props) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    Mousetrap.bind("esc", (e) => {
      e.preventDefault();
      setVisible(false);
    });
    return () => {
      Mousetrap.unbind("esc");
    };
  });

  const confirm = () => {
    setVisible(false);
  };

  const { dataIndex, searchFields } = props;
  const { handleSearchDateChange, handleSearch, handleReset } = props;
  const searchData =
    searchFields[convertDataIndex(dataIndex)] !== undefined
      ? searchFields[convertDataIndex(dataIndex)]
      : {};

  const menu = (
    <div className={styles.searchDropDown}>
      <DatePicker
        placeholder={`After`}
        value={searchData.gte !== undefined ? moment(searchData.gte) : null}
        onChange={(date) => handleSearchDateChange(date, dataIndex, "min")}
        style={{ width: 188, marginBottom: 8, display: "block" }}
        format={[
          "M/DD/YY",
          "M/D/YY",
          "MM/D/YY",
          "M/DD/YYYY",
          "M/D/YYYY",
          "MM/D/YYYY",
        ]}
      />
      <DatePicker
        placeholder={`Before`}
        value={searchData.lte !== undefined ? moment(searchData.lte) : null}
        onChange={(date) => handleSearchDateChange(date, dataIndex, "max")}
        style={{ width: 188, marginBottom: 8, display: "block" }}
        format={[
          "M/DD/YY",
          "M/D/YY",
          "MM/D/YY",
          "M/DD/YYYY",
          "M/D/YYYY",
          "MM/D/YYYY",
        ]}
      />
      <Button
        type="primary"
        onClick={() => handleSearch(confirm)}
        icon={<SearchOutlined />}
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button
        onClick={() => handleReset(dataIndex, confirm)}
        style={{ width: 90 }}
      >
        Reset
      </Button>
    </div>
  );

  return (
    <Dropdown
      overlay={menu}
      placement="bottomRight"
      trigger={["click"]}
      open={visible}
      onOpenChange={setVisible}
    >
      <Button className={styles.filterButton}>
        <FilterOutlined className={styles.icon} />
      </Button>
    </Dropdown>
  );
};

export default DateRangeDropdown;
