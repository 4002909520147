import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import BaseControls from "./BaseControls";
import mapActions from "../../actions/map";

import styles from "./SimpleMap.module.scss";

import { useInitialization } from "./hooks/useInitialization";

import { Spin } from "antd";

const SimpleMap = (props) => {
  const mapRef = useRef(null);
  const mapContainerRef = useRef(null);
  const [mapLoaded, setMapLoaded] = useState(false);

  const dispatch = useDispatch();

  useInitialization({
    mapRef,
    mapContainerRef,
    setMapLoaded,
  });

  useEffect(() => {
    if (!mapLoaded) return;

    dispatch(mapActions.resetMap(mapRef));
  }, [dispatch, mapRef, mapLoaded]);

  return (
    <div className={styles.main}>
      <div className={styles.mapContainer}>
        <div id="map-container" className={styles.map} ref={mapContainerRef} />
        {mapLoaded ? (
          <BaseControls ref={mapRef} />
        ) : (
          <div className={styles.mapLoading}>
            <Spin />
          </div>
        )}
      </div>
    </div>
  );
};

export default SimpleMap;
