import React, { useState, useEffect, useCallback } from "react";
import { apiBase } from "../../utils/apiBase";
import { withParams } from "../../utils/withParams";
import { get } from "lodash";

import { Row, Col, Spin, message, Divider } from "antd";

import Note from "./Note";

import styles from "./Notes.module.scss";

const Notes = (props) => {
  const { recordId, recordType } = props;

  const [loading, setLoading] = useState(true);
  const [notes, setNotes] = useState([]);

  const getNotes = useCallback(async () => {
    if (!recordId || !recordType) return;

    try {
      setLoading(true);
      const res = await apiBase.get(
        `/notes?record_id=${recordId}&record_type=${recordType}`
      );
      setNotes(get(res, "data", {}));
    } catch (err) {
      let msg = `${err.response.status}: Failed to load notes ${recordId}`;
      message.error(msg);
    } finally {
      setLoading(false);
    }
  }, [recordId, recordType]);

  useEffect(() => {
    getNotes();
  }, [getNotes]);

  return (
    <div>
      <Row>
        <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
          <div>
            <Note
              newNote={true}
              recordId={recordId}
              recordType={recordType}
              getNotes={getNotes}
            />
            <Divider className={styles.divider} />
            {!loading ? (
              notes.length > 0 &&
              notes.map((note, idx) => {
                return (
                  <Note
                    key={idx}
                    note={note}
                    recordId={recordId}
                    recordType={recordType}
                    getNotes={getNotes}
                  />
                );
              })
            ) : (
              <Spin className={styles.spinner} />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withParams(Notes);
