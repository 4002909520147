import React, { useState, useEffect, useCallback } from "react";
import { apiBase } from "../../utils/apiBase";
import { withParams } from "../../utils/withParams";
import { Helmet } from "react-helmet";
import { get, pick, isEmpty } from "lodash";

import { Row, Col, Form, message, Button, Input, Modal } from "antd";
import { formatDate } from "../../utils/dates";
import RecordLabelsEdit from "../common/RecordLabelsEdit";
import PermitPlatMap from "./PermitPlatMap";

import PermitPlatPermits from "./PermitPlatPermits";
import PermitPlatUnits from "./PermitPlatUnits";
import Files from "../common/Files";
import AppConfigSelect from "../common/AppConfigSelect";
import MappedIcon from "../common/MappedIcon";
import { ExclamationCircleFilled } from "@ant-design/icons";

import styles from "./PermitPlat.module.scss";

const formFields = [
  "id",
  "geom_polygon",
  "label_ids",
  "created_at",
  "updated_at",
  "name",
  "permit_count",
  "unit_count",
  "county",
];

const { confirm } = Modal;

const PermitPlat = (props) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [permitPlat, setPermitPlat] = useState({});
  const [permitPlatEdit, setPermitPlatEdit] = useState(false);
  const [permitPlatCopy, setPermitPlatCopy] = useState({});
  const [units, setUnits] = useState([]);

  const getPermitPlat = useCallback(async () => {
    try {
      const res = await apiBase.get(`/permit-plats/${props.params.id}`);
      setPermitPlat(get(res, "data", {}));
    } catch (err) {
      let msg = `${err.response.status}: Failed to load permit plat ${props.params.id}`;
      message.error(msg);
    }
  }, [props.params.id]);

  const getPermitPlatUnits = useCallback(async () => {
    try {
      const res = await apiBase.get(`/permit-plats/${props.params.id}/units`);
      setUnits(get(res, "data", []));
    } catch (err) {
      message.error(`Failed to load permit plat units: ${err.message}`);
    }
  }, [props.params.id]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    await getPermitPlat();
    await getPermitPlatUnits();
    setLoading(false);
  }, [getPermitPlat, getPermitPlatUnits]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (!isEmpty(permitPlat)) {
      form.setFieldsValue({
        ...pick(permitPlat, formFields),
      });
    }
  }, [form, permitPlat]);

  const onFinishFailed = () => {
    message.error("Form fields required");
  };

  const setPermitPlatEditMode = () => {
    if (!permitPlatEdit) {
      let permitPlatCopy = { ...permitPlat };
      setPermitPlatCopy(permitPlatCopy);
      setPermitPlatEdit(true);
    }
  };

  const cancelPermitPlatEdit = () => {
    setPermitPlat(permitPlatCopy);
    setPermitPlatEdit(false);
    form.setFieldsValue({
      ...pick(permitPlatCopy, formFields),
    });
  };

  const updateSubmit = (values) => {
    setLoading(true);
    apiBase
      .patch(`permit-plats/${permitPlat.id}`, values)
      .then((res) => {
        setPermitPlat(res.data);
        setPermitPlatEdit(false);
        message.success("Successfully updated permit plat");
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
        form.resetFields();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deletePermitPlat = async (permitPlatId) => {
    setLoading(true);
    apiBase
      .delete(`/permit-plats/${permitPlatId}`)
      .then((res) => {
        setLoading(false);
        props.history("/permit-plats?limit=50&page=1");
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to delete permit plat.`;
        message.error(msg);
        setLoading(false);
      });
  };

  const handleDelete = () => {
    confirm({
      title: "Are you sure you want to delete this permit plat?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      okButtonProps: { disabled: loading, loading: loading },
      cancelButtonProps: { disabled: loading, loading: loading },
      onOk: () => deletePermitPlat(permitPlat.id),
    });
  };

  return (
    <div className={`App__rowContentDetail`}>
      <Helmet>
        <title>{`Permit Plat: ${permitPlat.id}`}</title>
      </Helmet>
      <Row>
        <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
          <Row>
            <Col span={24}>
              <div className={styles.card}>
                <div className={styles.cardTitle}>
                  <div className={styles.leftTitle}>
                    <h2 className={styles.title}>Permit Plat</h2>
                    {permitPlat.geom_polygon && <MappedIcon />}
                    <RecordLabelsEdit
                      record_type="permit_plat"
                      record_id={permitPlat.id}
                      label_ids={permitPlat.label_ids || []}
                    />
                  </div>
                  <div className={styles.rightTitle}>
                    {!permitPlatEdit ? (
                      <>
                        <Button
                          className={`App__mr-5 ${styles.cardHeaderAction}`}
                          onClick={handleDelete}
                          disabled={loading}
                          loading={loading}
                        >
                          Delete
                        </Button>
                        <Button
                          onClick={setPermitPlatEditMode}
                          disabled={loading}
                          loading={loading}
                        >
                          Edit
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          onClick={cancelPermitPlatEdit}
                          className="App__mr-8"
                          disabled={loading}
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={() => form.submit()}
                          type="primary"
                          disabled={loading}
                          loading={loading}
                        >
                          Save
                        </Button>
                      </>
                    )}
                  </div>
                </div>
                <div className={styles.cardBody}>
                  <div className={styles.compactForm}>
                    <Form
                      name="basic"
                      form={form}
                      initialValues={{
                        ...pick(permitPlat, formFields),
                      }}
                      onFinish={updateSubmit}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                      disabled={loading}
                    >
                      <Row>
                        <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                          <div className={styles.objectData}>
                            <span>Id:</span>
                            {permitPlat.id}
                          </div>
                          <div
                            className={`${styles.objectData} ${styles.formInput}`}
                          >
                            <span>Name:</span>
                            <div onClick={() => setPermitPlatEditMode()}>
                              <Form.Item
                                name="name"
                                rules={[
                                  {
                                    required: true,
                                    message: "Name is required",
                                  },
                                ]}
                                noStyle
                              >
                                <Input
                                  onPressEnter={() => form.submit()}
                                  size="small"
                                  className={
                                    permitPlatEdit
                                      ? styles.inputActive
                                      : styles.inputDisabled
                                  }
                                />
                              </Form.Item>
                            </div>
                          </div>
                          <div className={styles.objectData}>
                            <span>County:</span>
                            <Form.Item
                              name="county"
                              rules={[{ required: false, message: "" }]}
                              noStyle
                            >
                              <AppConfigSelect
                                appConfigKey="counties"
                                showSearch
                                allowClear
                                size="small"
                                onClick={() => setPermitPlatEditMode()}
                                className={
                                  permitPlatEdit
                                    ? styles.inputActive
                                    : styles.inputDisabled
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                          <div className={styles.objectData}>
                            <span>Permit count:</span>
                            {permitPlat.permit_count}
                          </div>
                          <div className={styles.objectData}>
                            <span>Unit count:</span>
                            {permitPlat.unit_count}
                          </div>
                          <div className={styles.objectData}>
                            <span>Updated at:</span>
                            {formatDate(
                              permitPlat.updated_at,
                              "YYYY-MM-DD, h:mm:ss A"
                            )}
                          </div>
                          <div className={styles.objectData}>
                            <span>Created at:</span>
                            {formatDate(
                              permitPlat.created_at,
                              "YYYY-MM-DD, h:mm:ss A"
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Files
                recordId={permitPlat.id}
                recordType="permit_plat"
                pagination={false}
              />
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <PermitPlatPermits
                permitPlatId={props.params.id}
                fetchData={fetchData}
              />
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <div className={styles.card}>
                <div className={styles.cardTitle}>
                  <div className={styles.leftTitle}>
                    <h2>Units</h2>
                  </div>
                </div>
                <div className={styles.cardBody}>
                  <PermitPlatUnits units={units} loading={loading} />
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
          <PermitPlatMap
            fetchLoading={loading}
            permitPlat={permitPlat}
            permitPlatUnits={units}
            fetchData={fetchData}
          />
        </Col>
      </Row>
    </div>
  );
};

export default withParams(PermitPlat);
