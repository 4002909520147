import React, { Component } from "react";
import { withParams } from "../../utils/withParams";
import { withMediaQuery } from "../../utils/withMediaQuery";
import { apiBase } from "../../utils/apiBase";
import { CSVLink } from "react-csv";
import { formatCSVData } from "../../utils/numbers";
import { years } from "../../common/taxYear";
import { get, uniqWith, orderBy } from "lodash";
import { parse } from "query-string";
import { Helmet } from "react-helmet";
import { nonEmptyString } from "../../utils/strings";

import TotalRecords from "../search/TotalRecords";
import OwnerEdit from "./OwnerEdit";

import OwnerUnits from "./OwnerUnits";
import OwnerLeads from "./OwnerLeads";
import OwnerMailLetters from "./OwnerMailLetters";
// import OwnerSimilarSplink from "./OwnerSimilarSplink";
import OwnerGenerics from "./OwnerGenerics";
import OwnerOwnerNameAddresses from "./OwnerOwnerNameAddresses";
import OwnerIDIPersons from "./OwnerIDIPersons";
import IDIPersonCard from "../idi/IDIPersonCard";
import IDISearchCreateForm from "../idi/IDISearchCreateForm";
import USPSSearchCreateForm from "../usps/USPSSearchCreateForm";
import OwnerModelPrediction from "./OwnerModelPrediction";

import OwnerEnverusLandtracUnits from "./OwnerEnverusLandtracUnits";
import OwnerMap from "./OwnerMap";

import {
  Row,
  Col,
  Select,
  Button,
  Badge,
  Dropdown,
  Typography,
  message,
  notification,
  Checkbox,
} from "antd";

import { MoreOutlined } from "@ant-design/icons";

import styles from "./OwnerDetail.module.scss";

const csvHeadersInterests = [
  { label: "id", key: "id" },
  { label: "unit_id", key: "unit_id" },
  { label: "name", key: "name" },
  { label: "name_formatted", key: "name_formatted" },
  { label: "rrc_id", key: "rrc_id" },
  { label: "county", key: "county" },
  { label: "operator", key: "operator" },
  { label: "interest_type", key: "interest_type" },
  { label: "acres", key: "acres" },
  { label: "decimal_interest", key: "decimal_interest" },
  { label: "nra", key: "nra" },
  { label: "tax_year", key: "tax_year" },
];

const csvHeadersLeads = [
  { label: "id", key: "id" },
  { label: "unit_id", key: "unit_id" },
  { label: "unit.name", key: "unit.name" },
  { label: "unit.name_formatted", key: "unit.name_formatted" },
  { label: "unit.county", key: "unit.county" },
  { label: "unit.operator", key: "unit.operator" },
  { label: "interest_type", key: "interest_type" },
  { label: "decimal_interest", key: "decimal_interest" },
  { label: "nra", key: "nra" },
  { label: "offer_amount", key: "offer_amount" },
  { label: "status", key: "status" },
  { label: "user.alias", key: "user.alias" },
  { label: "tax_year", key: "tax_year" },
  { label: "updated_at", key: "updated_at" },
  { label: "created_at", key: "created_at" },
];

const csvHeadersGenerics = [
  { label: "unit_name", key: "unit_name" },
  { label: "unit_operator", key: "unit_operator" },
  { label: "unit_county", key: "unit_county" },
  { label: "unit_state", key: "unit_state" },
  { label: "lease_year", key: "lease_year" },
  { label: "volume_page", key: "volume_page" },
  { label: "instrument_number", key: "instrument_number" },
  { label: "instrument_type", key: "instrument_type" },
  { label: "interest_type", key: "interest_type" },
  { label: "tract_description", key: "tract_description" },
  { label: "area_acres", key: "area_acres" },
  { label: "royalty", key: "royalty" },
  { label: "legal_description", key: "legal_description" },
  { label: "owner_name", key: "owner_name" },
  { label: "owner_name_formatted", key: "owner_name_formatted" },
  { label: "owner_name_ico", key: "owner_name_ico" },
  { label: "owner_address", key: "owner_address" },
  { label: "owner_address_formatted", key: "owner_address_formatted" },
  { label: "owner_city", key: "owner_city" },
  { label: "owner_state", key: "owner_state" },
  { label: "owner_zip_code", key: "owner_zip_code" },
  { label: "owner_ntf", key: "owner_ntf" },
  { label: "generic_file_id", key: "generic_file_id" },
];

const tabData = [
  {
    tabIdx: 1,
    title: "Units",
    name: "units",
    objectsName: "ownerUnitInterests",
    csvHeaders: csvHeadersInterests,
  },
  {
    tabIdx: 2,
    title: "Generics",
    name: "generics",
    objectsName: "generics",
    csvHeaders: csvHeadersGenerics,
  },
  {
    tabIdx: 3,
    title: "Leads",
    name: "leads",
    objectsName: "leads",
    csvHeaders: csvHeadersLeads,
  },
  {
    tabIdx: 4,
    title: "Letters",
    name: "letters",
    objectsName: "letters",
    // csvHeaders: csvHeadersInterests,
  },
  {
    tabIdx: 5,
    title: "Name Addresses",
    name: "ownerNameAddresses",
    objectsName: "ownerNameAddresses",
  },
  {
    tabIdx: 6,
    title: "IDI Persons",
    name: "idiPersons",
    objectsName: "idiPersons",
  },
  {
    tabIdx: 7,
    title: "Env Landtrac Units",
    name: "enverusLandtracUnits",
    objectsName: "enverusLandtracUnits",
  },
];

class OwnerDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      owner: {},
      ownerUnitInterests: [],
      ownerUnitInterestsCopy: null,
      columnExtras: false,
      interestTaxYear: null,
      interestTaxYears: years,
      yearPropertyCount: null,
      enverusLandtracUnits: [],
      leads: [],
      letters: [],
      generics: [],
      ownerNameAddresses: [],
      idiPersons: [],
      ownerCopy: {},
      isOwnerEdit: false,
      tabKey: 1,
      recordsPerPage: 100,
      isLoadingLeads: false,
      isLoadingGenerics: false,
      isLoadingInterests: false,
      isLoadingOwnerEdit: false,
      isLoadingOwnerLetters: false,
      isLoadingOwnerNameAddresses: false,
      isLoadingIDIPersons: false,
      isLoadingEnverus: false,
      isModelPredictionModalOpen: false,
    };

    this.getOwner = this.getOwner.bind(this);
    this.getOwnerNameAddresses = this.getOwnerNameAddresses.bind(this);
    this.getIDIPersons = this.getIDIPersons.bind(this);
    this.getOwnerLetters = this.getOwnerLetters.bind(this);
    this.getEnverusData = this.getEnverusData.bind(this);
    this.setIsModelPredictionModalOpen =
      this.setIsModelPredictionModalOpen.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (
      this.state.ownerUnitInterests.length > 0 &&
      this.props.interestFilters.owner_name !== undefined
    ) {
      if (
        this.state.ownerUnitInterests[0].interest_filter === undefined &&
        this.state.owner.name_formatted !== undefined
      ) {
        let ownerNameRegex = this.props.interestFilters.owner_name || null;
        let interestTypeRegex =
          this.props.interestFilters.interest_type || null;
        let ownerUnitInterests = this.state.ownerUnitInterests;
        let owner = this.state.owner;
        ownerUnitInterests = ownerUnitInterests.map((row) => {
          row["interest_filter"] =
            RegExp(ownerNameRegex, "i").test(owner.name_formatted) ||
            RegExp(interestTypeRegex, "i").test(row.interest_type);
          return row;
        });
        this.setState({ ownerUnitInterests });
      }
    }

    if (this.props.params.id !== prevProps.params.id) {
      this.fetchData();
    }

    if (
      this.props.location.search &&
      this.props.location.search !== prevProps.location.search
    ) {
      this.setStateFromLocation();
    }
  }

  fetchData = () => {
    this.getOwner();
    this.getOwnerUnits();
    this.getOwnerLeads();
    this.getOwnerLetters();
    this.getOwnerGenerics();
    this.getOwnerNameAddresses();
    this.getIDIPersons();
    this.getEnverusData();
    this.setStateFromLocation();
  };

  setStateFromLocation() {
    const query = parse(this.props.location.search);
    this.setState({
      interestTaxYear: get(query, "tax_year", null),
    });
  }

  getOwner() {
    apiBase
      .get(`owners/${this.props.params.id}`)
      .then((res) => {
        this.setState({
          owner: res.data,
        });
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
      });
  }

  getOwnerUnits() {
    this.setState({ isLoadingInterests: true });
    apiBase
      .get(`/owners/${this.props.params.id}/units`)
      .then((res) => {
        const query = parse(this.props.location.search);
        const interestTaxYear = get(
          query,
          "tax_year",
          // maxTaxYear === -Infinity ? null : maxTaxYear
          "unique"
        );

        let ownerUnitInterests = this.filterOwnerUnitInterests(
          interestTaxYear,
          res.data
        );

        let ownerUnitInterestsCopy = res.data;
        ownerUnitInterests = ownerUnitInterests.map((el, idx) => ({
          ...el,
          ...{ key: idx },
        }));
        ownerUnitInterestsCopy = ownerUnitInterestsCopy.map((el, idx) => ({
          ...el,
          ...{ key: idx },
        }));

        this.setState({
          ownerUnitInterests: ownerUnitInterests,
          totalRecords: ownerUnitInterests.length,
          ownerUnitInterestsCopy: ownerUnitInterestsCopy,
          interestTaxYear: interestTaxYear,
          interestTaxYears: [...new Set(res.data.map((row) => row.tax_year))]
            .sort()
            .reverse(),
          isLoadingInterests: false,
        });
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: Error loading Owner: ${
          this.props.params.id
        } Units`;
        message.error(msg);
        this.setState({ isLoadingInterests: false });
      });
  }

  getOwnerLeads() {
    this.setState({ isLoadingLeads: true });
    apiBase
      .get(`/owners/${this.props.params.id}/leads`)
      .then((res) => {
        let leads = res.data.map((el, idx) => ({ ...el, ...{ key: idx } }));
        // let tabKey = leads.length > 0 ? 2 : 1;
        this.setState({
          leads: leads,
          // tabKey: tabKey,
          isLoadingLeads: false,
        });
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: Error loading Owner: ${
          this.props.params.id
        } Leads`;
        message.error(msg);
        this.setState({ isLoadingLeads: false });
      });
  }

  getOwnerGenerics() {
    this.setState({ isLoadingGenerics: true });
    apiBase
      .get(`/owners/${this.props.params.id}/generic-file-records`)
      .then((res) => {
        let generics = res.data.map((el, idx) => ({ ...el, ...{ key: idx } }));
        let tabKey = generics.length > 0 ? 2 : 1;
        this.setState({
          generics: generics,
          tabKey: tabKey,
          isLoadingGenerics: false,
        });
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: Error loading Owner: ${
          this.props.params.id
        } Generics`;
        message.error(msg);
        this.setState({ isLoadingGenerics: false });
      });
  }

  getOwnerLetters() {
    this.setState({ isLoadingOwnerLetters: true });
    apiBase
      .get(`/owners/${this.props.params.id}/mail-letters`)
      .then((res) => {
        let letters = res.data.map((el, idx) => ({ ...el, ...{ key: idx } }));
        this.setState({
          letters: letters,
          isLoadingOwnerLetters: false,
        });
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: Error loading Owner: ${
          this.props.params.id
        } Letters`;
        message.error(msg);
        this.setState({ isLoadingOwnerLetters: false });
      });
  }

  getOwnerNameAddresses() {
    this.setState({ isLoadingOwnerNameAddresses: true });
    apiBase
      .get(`/owners/${this.props.params.id}/owner-name-addresses`)
      .then((res) => {
        let ownerNameAddresses = res.data.map((el, idx) => ({
          ...el,
          ...{ key: idx },
        }));
        this.setState({
          ownerNameAddresses: ownerNameAddresses,
          isLoadingOwnerNameAddresses: false,
        });
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: Error loading Owner: ${
          this.props.params.id
        } Owner Name Addresses`;
        message.error(msg);
        this.setState({ isLoadingOwnerNameAddresses: false });
      });
  }

  getIDIPersons() {
    this.setState({ isLoadingIDIPersons: true });
    apiBase
      .get(`/owners/${this.props.params.id}/idi-persons`)
      .then((res) => {
        let idiPersons = res.data.map((el, idx) => ({
          ...el,
          ...{ key: idx },
        }));
        this.setState({
          idiPersons: idiPersons,
          isLoadingIDIPersons: false,
        });
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: Error loading Owner: ${
          this.props.params.id
        } IDI Persons`;
        message.error(msg);
        this.setState({ isLoadingIDIPersons: false });
      });
  }

  getEnverusData() {
    this.setState({ isLoadingEnverus: true });
    apiBase
      .get(`/owners/${this.props.params.id}/enverus`)
      .then((res) => {
        this.setState({
          enverusLandtracUnits: res.data.enverus_landtrac_units,
          isLoadingEnverus: false,
        });
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
        this.setState({ isLoadingEnverus: false });
      });
  }

  handleOwnerEditSubmit = () => {
    let ownerEdit = {
      owner_id: parseInt(this.props.params.id),
      name_formatted: this.state.owner.name_formatted,
      first_name: this.state.owner.first_name,
      middle_name: this.state.owner.middle_name,
      last_name: this.state.owner.last_name,
      address_formatted: this.state.owner.address_formatted,
      city: this.state.owner.city,
      state: this.state.owner.state,
      zip_code: this.state.owner.zip_code,
      ntf: this.state.owner.ntf,
      last_idi_person_id: parseInt(this.state.owner.last_idi_person_id),
      last_usps_address_id: parseInt(this.state.owner.last_usps_address_id),
      mailing_street: this.state.owner.mailing_street,
      mailing_city: this.state.owner.mailing_city,
      mailing_state: this.state.owner.mailing_state,
      mailing_zip_code: this.state.owner.mailing_zip_code,
    };

    Object.keys(ownerEdit).forEach((key) => {
      if (typeof ownerEdit[key] === "string") {
        ownerEdit[key] = nonEmptyString(ownerEdit[key]);
      }
    });

    this.setState({ isLoadingOwnerEdit: true });
    apiBase
      .patch(`/owners`, ownerEdit)
      .then((res) => {
        this.setState({
          owner: res.data,
          isOwnerEdit: false,
          isLoadingOwnerEdit: false,
        });
        let msg = `${get(res, "status")}: Successfully updated owner.`;
        message.success(msg);
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
        this.setState({
          isOwnerEdit: false,
          isLoadingOwnerEdit: false,
        });
      });
  };

  deleteLead = (leadId) => {
    this.setState({ isLoadingLeads: true });
    apiBase
      .delete(`/leads/${leadId}`)
      .then((res) => {
        let leads = this.state.leads;
        leads = leads.filter((lead) => lead.id !== leadId);
        this.setState({
          leads: leads.map((el, idx) => ({ ...el, ...{ key: idx } })),
          totalRecords: leads.length,
          isLoadingLeads: false,
        });
        let msg = `${res.status}: ${res.data.message}`;
        message.success(msg);
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: Failed to delete Lead.`;
        message.error(msg);
        this.setState({ isLoadingLeads: false });
      });
  };

  deleteGeneric = (genericId) => {
    this.setState({ isLoadingGenerics: true });
    apiBase
      .delete(`/generic-file-records/${genericId}`)
      .then((res) => {
        let generics = this.state.generics;
        generics = generics.filter((generic) => generic.id !== genericId);
        this.setState({
          generics: generics.map((el, idx) => ({ ...el, ...{ key: idx } })),
          totalRecords: generics.length,
          isLoadingGenerics: false,
        });
        let msg = `${res.status}: ${res.data.message}`;
        message.success(msg);
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: Failed to delete generic.`;
        message.error(msg);
        this.setState({ isLoadingGenerics: false });
      });
  };

  handleLeadEditSubmit = (leadId, newLead) => {
    this.setState({ isLoadingLeads: true });
    apiBase
      .patch(`leads/${leadId}`, newLead)
      .then((res) => {
        this.setState({ isLoadingLeads: false });
        let msg = `${res.status}: Successfully updated Lead.`;
        message.success(msg);
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
        this.setState({ isLoadingLeads: false });
      });
  };

  handleStopSmartLeadsEmails = () => {
    this.setState({ isLoadingStopSmartLeadsEmails: true });
    apiBase
      .get(`/owners/${this.props.params.id}/stop-smart-leads-emails`)
      .then((res) => {
        this.setState({ isLoadingStopSmartLeadsEmails: false });

        const args = {
          message: (
            <div className={styles.notificationTitle}>
              SmartLeads global block list updated
            </div>
          ),
          description: (
            <div>
              <p>
                Status codes:{" "}
                {JSON.stringify(get(res, "data.status_codes", []))}
              </p>
              {get(res, "data.emails", []).map((email, idx) => (
                <p key={idx}>
                  <Typography.Text
                    keyboard
                    copyable
                    className={styles.notificationBody}
                  >
                    {email}
                  </Typography.Text>
                </p>
              ))}
            </div>
          ),
          style: {
            width: 450,
          },
          duration: 30,
        };
        notification.success(args);
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
        this.setState({ isLoadingStopSmartLeadsEmails: false });
      });
  };

  filterOwnerUnitInterests = (value, ownerUnitInterestsCopy) => {
    let newOwnerUnitInterests = ownerUnitInterestsCopy;

    if (typeof value === "string" && value === "unique") {
      newOwnerUnitInterests = uniqWith(
        orderBy(newOwnerUnitInterests, ["tax_year"], ["desc"]),
        (ouiA, ouiB) => ouiA.unit_id === ouiB.unit_id
      );
    } else if (typeof value === "string" && value === "all") {
      newOwnerUnitInterests = ownerUnitInterestsCopy;
    } else {
      newOwnerUnitInterests = ownerUnitInterestsCopy.filter(
        (row) => row.tax_year === parseInt(value, 10)
      );
    }

    newOwnerUnitInterests = orderBy(
      newOwnerUnitInterests,
      ["tax_year", "decimal_interest", "name"],
      ["desc", "desc", "asc"]
    );

    return newOwnerUnitInterests;
  };

  handleYearChange = (value) => {
    const newOwnerUnitInterests = this.filterOwnerUnitInterests(
      value,
      this.state.ownerUnitInterestsCopy
    );

    this.setState({
      interestTaxYear: value,
      ownerUnitInterests: newOwnerUnitInterests,
      yearPropertyCount: uniqWith(
        newOwnerUnitInterests,
        (ouiA, ouiB) =>
          ouiA.decimal_interest === ouiB.decimal_interest &&
          ouiA.name === ouiB.name
      ).length,
    });

    this.props.history(`${this.props.location.pathname}?tax_year=${value}`);
  };

  interestFilterStyles(record) {
    if (record.interest_filter === true) {
      return styles.badInterest;
    }
  }

  handleOwnerEditChange = (e, name = null) => {
    let owner = { ...this.state.owner };
    if (name === null) {
      if (e.target.name === "ntf") {
        owner[e.target.name] = e.target.checked;
      } else {
        owner[e.target.name] = e.target.value;
      }
    } else {
      let ownerUnitInterests = this.state.ownerUnitInterests;
      ownerUnitInterests = ownerUnitInterests.map((row) => {
        row["interest_filter"] = e === "true" ? true : false;
        return row;
      });
      owner[name] = e;
      this.setState({ owner, ownerUnitInterests });
    }
    this.setState({ owner });
  };

  setOwnerEdit() {
    if (this.state.isOwnerEdit === false) {
      let ownerCopy;
      if (Object.keys(this.state.owner).length === 0) {
        ownerCopy = { ...this.state.owner };
      } else {
        ownerCopy = { ...this.state.owner };
      }
      this.setState({
        isOwnerEdit: true,
        ownerCopy: ownerCopy,
        owner: ownerCopy,
      });
    }
  }

  cancelOwnerEdit() {
    let ownerCopy = { ...this.state.ownerCopy };
    this.setState({
      isOwnerEdit: false,
      owner: ownerCopy,
    });
  }

  setIsModelPredictionModalOpen(value) {
    this.setState({ isModelPredictionModalOpen: value });
  }

  changeTab(tabKey) {
    this.setState({ tabKey: tabKey });
  }

  handleRecordsChange(value) {
    this.setState({
      recordsPerPage: value,
    });
  }

  onToggleExtras = (e) => {
    this.setState({
      columnExtras: e.target.checked,
    });
  };

  render() {
    const {
      owner,
      ownerNameAddresses,
      interestTaxYears,
      columnExtras,
      isModelPredictionModalOpen,
      enverusLandtracUnits,
      isLoadingEnverus,
      isLoadingLeads,
    } = this.state;

    const { isSmallScreen } = this.props;

    const idiPersonId = this.state.isOwnerEdit
      ? get(this.state.ownerCopy, "last_idi_person_id", null)
      : get(owner, "last_idi_person_id", null);

    return (
      <div className="App__rowContentDetail">
        <Helmet>
          <title>{`Owner: ${owner.name_formatted}`}</title>
        </Helmet>
        <Row>
          {/* <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}> */}
          <Col span={12} xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
            <div className={styles.card}>
              <div className={styles.cardTitle}>
                <div className={styles.leftTitle}>
                  <h2>Owner</h2>
                </div>
                <div className={styles.rightTitle}>
                  <div className="App__mr-5">
                    <IDISearchCreateForm
                      owner={owner}
                      ownerNameAddresses={ownerNameAddresses}
                      refetch={() => {
                        this.getOwner();
                        this.getIDIPersons();
                      }}
                    />
                  </div>
                  <div className="App__mr-5">
                    <USPSSearchCreateForm
                      owner={owner}
                      refetch={this.getOwner}
                    />
                  </div>
                  <OwnerModelPrediction
                    ownerId={owner.id}
                    isModalOpen={isModelPredictionModalOpen}
                    setIsModalOpen={this.setIsModelPredictionModalOpen}
                  />
                  {/* <OwnerSimilarSplink owner={owner} /> */}
                  {!this.state.isOwnerEdit ? (
                    <div>
                      <Button onClick={this.setOwnerEdit.bind(this)}>
                        Edit
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <Button
                        onClick={this.cancelOwnerEdit.bind(this)}
                        className="App__mr-5"
                        disabled={this.state.isLoadingOwnerEdit}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={this.handleOwnerEditSubmit.bind(this)}
                        type="primary"
                        loading={this.state.isLoadingOwnerEdit}
                      >
                        Save
                      </Button>
                    </div>
                  )}
                  <Dropdown
                    className="App__ml-5"
                    trigger={["click"]}
                    menu={{
                      items: [
                        {
                          key: "1",
                          label: (
                            <div onClick={this.setIsModelPredictionModalOpen}>
                              Fetch PD Score
                            </div>
                          ),
                        },
                        {
                          key: "2",
                          label: (
                            <div onClick={this.handleStopSmartLeadsEmails}>
                              Stop smart leads emails
                            </div>
                          ),
                        },
                      ],
                    }}
                    placement="bottomLeft"
                  >
                    <Button icon={<MoreOutlined />} />
                  </Dropdown>
                </div>
              </div>
              <div className={styles.cardBody}>
                <OwnerEdit
                  owner={owner}
                  handleOwnerEditChange={this.handleOwnerEditChange.bind(this)}
                  handleOwnerEditSubmit={this.handleOwnerEditSubmit.bind(this)}
                  setOwnerEdit={this.setOwnerEdit.bind(this)}
                  isOwnerEdit={this.state.isOwnerEdit}
                  yearPropertyCount={this.state.yearPropertyCount}
                />
              </div>
            </div>
            {!!idiPersonId && (
              <Row>
                <Col span={24}>
                  <div className={styles.idiPerson}>
                    <IDIPersonCard idiPersonId={idiPersonId} isRecord={true} />
                  </div>
                </Col>
              </Row>
            )}
          </Col>
          <Col span={12} xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
            {!isSmallScreen && (
              <OwnerMap
                isLoadingEnverus={isLoadingEnverus}
                isLoadingLeads={isLoadingLeads}
                enverusLandtracUnits={enverusLandtracUnits}
                leads={this.state.leads}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className={styles.cardTabs}>
              <div className={styles.cardTitle}>
                <div className={styles.leftTitle}>
                  {tabData.map((tab, idx) => {
                    return (
                      <div
                        key={idx}
                        className={
                          this.state.tabKey === tab.tabIdx
                            ? styles.isActive
                            : null
                        }
                        onClick={() => this.changeTab(tab.tabIdx)}
                      >
                        <Badge
                          count={this.state[tab.objectsName].length}
                          offset={[20, -2]}
                          overflowCount={99}
                          style={{
                            backgroundColor: "#fff",
                            color: "#999",
                            boxShadow: "0 0 0 1px #d9d9d9 inset",
                          }}
                        >
                          <h2 style={{ whiteSpace: "nowrap" }}>{tab.title}</h2>
                        </Badge>
                      </div>
                    );
                  })}
                </div>
                <div className={styles.rightTitle}>
                  {[1, 2].includes(this.state.tabKey) && (
                    <Checkbox
                      onChange={this.onToggleExtras}
                      className={styles.columnExtras}
                    >
                      Column extras
                    </Checkbox>
                  )}
                  {tabData
                    .filter(
                      (tab) =>
                        tab.tabIdx === this.state.tabKey && !!tab.csvHeaders
                    )
                    .map((tab, idx) => {
                      return (
                        <CSVLink
                          key={idx}
                          headers={tab.csvHeaders}
                          data={formatCSVData(this.state[tab.objectsName])}
                          filename={`owner-${
                            tab.name
                          }-${new Date().toJSON()}.csv`}
                          className={styles.csvDownload}
                        >
                          <i className="fas fa-download" />
                        </CSVLink>
                      );
                    })}
                  {this.state.tabKey !== 6 && <></>}
                  <Select
                    defaultValue={this.state.recordsPerPage}
                    onChange={this.handleRecordsChange.bind(this)}
                    className={"App__mr-5"}
                  >
                    <Select.Option value={10}>10</Select.Option>
                    <Select.Option value={25}>25</Select.Option>
                    <Select.Option value={50}>50</Select.Option>
                    <Select.Option value={100}>100</Select.Option>
                    <Select.Option value={1000}>1000</Select.Option>
                  </Select>
                  {this.state.tabKey === 1 && this.state.interestTaxYear && (
                    <Select
                      value={this.state.interestTaxYear}
                      onChange={this.handleYearChange}
                      className={styles.taxYearDropDown}
                    >
                      <Select.Option value={"all"} key={"all"}>
                        All
                      </Select.Option>
                      <Select.Option value={"unique"} key={"unique"}>
                        Unique
                      </Select.Option>
                      {interestTaxYears.map((year) => (
                        <Select.Option value={year} key={year}>
                          {year}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                  {tabData
                    .filter((tab) => tab.tabIdx === this.state.tabKey)
                    .map((tab, idx) => {
                      return (
                        <TotalRecords
                          key={idx}
                          title={tab.title}
                          totalRecords={this.state[tab.objectsName].length}
                        />
                      );
                    })}
                </div>
              </div>
              <div className={styles.cardBody}>
                {this.state.tabKey === 1 ? (
                  <OwnerUnits
                    ownerUnitInterests={this.state.ownerUnitInterests}
                    isLoadingInterests={this.state.isLoadingInterests}
                    recordsPerPage={this.state.recordsPerPage}
                    columnExtras={columnExtras}
                    {...this.props}
                  />
                ) : this.state.tabKey === 2 ? (
                  <OwnerGenerics
                    generics={this.state.generics}
                    isLoadingLeads={this.state.isLoadingGenerics}
                    recordsPerPage={this.state.recordsPerPage}
                    deleteGeneric={this.deleteGeneric}
                    columnExtras={columnExtras}
                    {...this.props}
                  />
                ) : this.state.tabKey === 3 ? (
                  <OwnerLeads
                    leads={this.state.leads}
                    isLoadingLeads={this.state.isLoadingLeads}
                    recordsPerPage={this.state.recordsPerPage}
                    deleteLead={this.deleteLead}
                    handleLeadEditSubmit={this.handleLeadEditSubmit}
                    {...this.props}
                  />
                ) : this.state.tabKey === 4 ? (
                  <OwnerMailLetters
                    letters={this.state.letters}
                    owner={this.state.owner}
                    idiPersons={this.state.idiPersons}
                    getOwnerLetters={this.getOwnerLetters}
                    isLoadingOwnerLetters={this.state.isLoadingOwnerLetters}
                    recordsPerPage={this.state.recordsPerPage}
                    {...this.props}
                  />
                ) : this.state.tabKey === 5 ? (
                  <OwnerOwnerNameAddresses
                    ownerId={this.props.params.id}
                    ownerNameAddresses={this.state.ownerNameAddresses}
                    isLoadingOwnerNameAddresses={
                      this.state.isLoadingOwnerNameAddresses
                    }
                    getOwnerNameAddresses={this.getOwnerNameAddresses}
                    recordsPerPage={this.state.recordsPerPage}
                    {...this.props}
                  />
                ) : this.state.tabKey === 6 ? (
                  <OwnerIDIPersons
                    ownerId={this.props.params.id}
                    idiPersons={this.state.idiPersons}
                    isLoadingIDIPersons={this.state.isLoadingIDIPersons}
                    recordsPerPage={this.state.recordsPerPage}
                    getIDIPersons={this.getIDIPersons}
                    {...this.props}
                  />
                ) : (
                  <OwnerEnverusLandtracUnits
                    enverusLandtracUnits={this.state.enverusLandtracUnits}
                    isLoadingEnverus={this.state.isLoadingEnverus}
                    recordsPerPage={this.state.recordsPerPage}
                    {...this.props}
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withParams(withMediaQuery(OwnerDetail));
