import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import Mousetrap from "mousetrap";
import mapActions from "../../../actions/map";

// import { DeleteOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import {
  DeleteOutlined,
  EditOutlined,
  AppstoreAddOutlined,
} from "@ant-design/icons";

import { ReactComponent as MagnetIcon } from "../../../assets/icons/magnet.svg";
import { ReactComponent as PolygonIcon } from "../../../assets/icons/polygon.svg";

import styles from "./DrawControls.module.scss";

const DrawControls = (props) => {
  const { mapRef, drawRef } = props;
  const draw = drawRef.current;

  const dispatch = useDispatch();

  const drawOptions = useSelector((state) => state.map.controls.draw);
  const { mode, snap, edit, savedGeometry } = drawOptions;

  // Disable click layer when editing
  useEffect(() => {
    if (edit) {
      dispatch(mapActions.setClickLayer(false));
    } else {
      dispatch(mapActions.setClickLayer(true));
      dispatch(mapActions.setDraw(drawRef, { mode: "simple_select" }));
    }
  }, [dispatch, mapRef, drawRef, edit]);

  const clickDelete = useCallback(
    (e) => {
      if (!edit) return;

      e.preventDefault();
      const selectedIds = draw.getSelectedIds();
      if (selectedIds.length > 0) {
        draw.delete(selectedIds);
      }
    },
    [edit, draw]
  );

  useEffect(() => {
    if (!mapRef.current) return;

    mapRef.current.on("draw.modechange", (e) => {
      dispatch(mapActions.setDraw(drawRef, { mode: e.mode }));
    });

    Mousetrap.bind("del", clickDelete);
    Mousetrap.bind("backspace", clickDelete);

    return () => {
      Mousetrap.unbind("del");
      Mousetrap.unbind("backspace");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef, dispatch, clickDelete]);

  const toggleEdit = () => {
    dispatch(mapActions.setDraw(drawRef, { edit: !edit }));
  };

  const togglePolygon = () => {
    const newMode = mode === "draw_polygon" ? "simple_select" : "draw_polygon";

    dispatch(mapActions.setDraw(drawRef, { mode: newMode }));
  };

  const toggleSnap = () => {
    if (!edit) return;

    dispatch(mapActions.setDraw(drawRef, { snap: !snap }));
  };

  const addSavedGeometry = () => {
    draw.deleteAll();
    draw.add(savedGeometry);
    dispatch(mapActions.setDraw(drawRef, { savedGeometry: null }));
  };

  return (
    <div className={styles.main}>
      <div onClick={toggleEdit} className={styles.control}>
        <EditOutlined
          height={14}
          width={14}
          className={clsx(`${styles.control} ${styles.antdIcon}`, {
            [styles.active]: edit,
          })}
        />
      </div>
      {edit && (
        <>
          <div
            onClick={togglePolygon}
            className={clsx(styles.control, {
              [styles.disabled]: !edit,
            })}
          >
            <PolygonIcon
              height={14}
              width={14}
              className={clsx({
                [styles.active]: mode === "draw_polygon",
              })}
            />
          </div>
          <div
            onClick={toggleSnap}
            className={clsx(styles.control, {
              [styles.disabled]: !edit,
            })}
          >
            <MagnetIcon
              height={14}
              width={14}
              className={clsx({
                [styles.active]: snap,
              })}
            />
          </div>
          {savedGeometry && (
            <div
              onClick={addSavedGeometry}
              className={`${styles.control} ${styles.antdIcon}`}
            >
              <AppstoreAddOutlined />
            </div>
          )}
          {/* <div
            // onClick={clickSave}
            className={`${styles.control} ${styles.antdIcon}`}
          >
            <SaveOutlined />
          </div> */}
          <div
            onClick={clickDelete}
            className={clsx(`${styles.control} ${styles.antdIcon}`, {
              [styles.disabled]: !edit,
            })}
          >
            <DeleteOutlined />
          </div>
        </>
      )}
    </div>
  );
};

export default DrawControls;
