import { useLocation, useNavigate } from "react-router-dom";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const history = useNavigate(); // navigate
    return <Component {...props} location={location} history={history} />;
  }

  return ComponentWithRouterProp;
}

export { withRouter };
