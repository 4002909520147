import { stringify, parse } from "query-string";
import { cloneDeep } from "lodash";

const possibleOperators = [
  "in",
  "nin",
  "overlaps",
  "noverlaps",
  "contains",
  "ncontains",
  "empty",
  "nempty",
];

const stringifyFilters = (searchFields) => {
  const searchFieldsCopy = cloneDeep(searchFields);
  Object.keys(searchFieldsCopy).forEach((key) => {
    if (
      typeof searchFieldsCopy[key] === "object" &&
      !Array.isArray(searchFieldsCopy[key])
    ) {
      Object.keys(searchFieldsCopy[key]).forEach((subKey) => {
        searchFieldsCopy[`${key}[${subKey}]`] = searchFieldsCopy[key][subKey];
      });
      delete searchFieldsCopy[key];
    }
  });
  return stringify(searchFieldsCopy, {
    arrayFormat: "separator",
    arrayFormatSeparator: "|",
  });
};

const parseFilters = (searchFields) => {
  const searchFieldsCopy = parse(searchFields, {
    arrayFormat: "separator",
    arrayFormatSeparator: "|",
  });
  Object.keys(searchFieldsCopy).forEach((key) => {
    if (key.match(/\[([^)]+)\]/)) {
      const match = key.match(/\[([^)]+)\]/);
      let newSubKey = match[1];
      let newMainKey = key.split(match[0])[0];
      if (!searchFieldsCopy[newMainKey]) searchFieldsCopy[newMainKey] = {};
      if (possibleOperators.includes(newSubKey)) {
        searchFieldsCopy[newMainKey][newSubKey] = Array.isArray(
          searchFieldsCopy[key]
        )
          ? searchFieldsCopy[key]
          : [searchFieldsCopy[key]];
      } else {
        searchFieldsCopy[newMainKey][newSubKey] = searchFieldsCopy[key];
      }
      delete searchFieldsCopy[key];
    }
  });

  return searchFieldsCopy;
};

const parseSortQuery = (sortQuery) => {
  let sort = {};
  if (sortQuery) {
    const [direction, column] = sortQuery.split(/(\+|-)/).filter(Boolean);
    const order = direction === "+" ? "ascend" : "descend";
    sort = { column, order };
  }

  return sort;
};

const sortMapper = {
  ascend: "+",
  descend: "-",
};

export { stringifyFilters, parseFilters, parseSortQuery, sortMapper };
