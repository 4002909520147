import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { apiBase } from "../../utils/apiBase";
import { withParams } from "../../utils/withParams";
import { get, pick, isEmpty } from "lodash";
// import { clsx } from "clsx";
import moment from "moment";
// import ReactJson from "react-json-view";

import SaleLineCreateForm from "./SaleLineCreateForm";
import BoxContentExplorer from "../common/BoxContentExplorer";
import BoxSearchSelect from "../common/BoxSearchSelect";

import { formatNumber } from "../../utils/numbers";

import {
  Row,
  Col,
  Form,
  Input,
  Button,
  InputNumber,
  DatePicker,
  // Tooltip,
  Modal,
  Table,
  message,
  Divider,
} from "antd";

import {
  ExclamationCircleFilled,
  DeleteOutlined,
  // DatabaseOutlined,
} from "@ant-design/icons";

import styles from "./Sale.module.scss";

const { confirm } = Modal;

const labelProps = {
  labelAlign: "left",
  labelCol: {
    xxl: { span: 7, offset: 0 },
    xl: { span: 9 },
    lg: { span: 10 },
  },
};

const formFields = [
  "entity_id",
  "user_id",
  "amount",
  "energy_net_fee_percentage",
  "description",
  "asset_id",
  "box_folder_id",
];

const Sale = (props) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [sale, setSale] = useState({});

  const getSale = useCallback(async () => {
    try {
      setLoading(true);
      const res = await apiBase.get(`/sales/${props.params.id}`);
      setSale(get(res, "data", {}));
    } catch (err) {
      let msg = `${err.response.status}: Failed to load sale ${props.params.id}`;
      message.error(msg);
    } finally {
      setLoading(false);
    }
  }, [props.params.id]);

  useEffect(() => {
    getSale();
  }, [getSale]);

  useEffect(() => {
    if (!isEmpty(sale)) {
      form.setFieldsValue({
        ...pick(sale, formFields),
      });
    }
  }, [form, sale]);

  const updateSubmit = (values) => {
    setLoading(true);
    apiBase
      .patch(`sales/${sale.id}`, values)
      .then((res) => {
        setSale(res.data);
        let msg = `${res.status}: Successfully updated sale.`;
        message.success(msg);
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
        form.resetFields();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteSale = async (saleId) => {
    setLoading(true);
    apiBase
      .delete(`/sales/${saleId}`)
      .then((res) => {
        setLoading(false);
        props.history("/portfolio/sales?limit=50&page=1");
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to delete sale.`;
        message.error(msg);
        setLoading(false);
      });
  };

  const deleteSaleLine = async (id) => {
    setLoading(true);
    apiBase
      .delete(`/sales/${sale.id}/sale-lines/${id}`)
      .then((res) => {
        let msg = `${res.status}: Successfully deleted sale line.`;
        message.success(msg);
        getSale();
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to delete sale line.`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = () => {
    confirm({
      title: "Are you sure you want to delete this sale?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      okButtonProps: { disabled: loading, loading: loading },
      cancelButtonProps: { disabled: loading, loading: loading },
      onOk: () => deleteSale(sale.id),
    });
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: "Are you sure you want to delete this sale line?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      okButtonProps: {
        disabled: loading,
        loading: loading,
      },
      cancelButtonProps: {
        disabled: loading,
        loading: loading,
      },
      onOk: () => deleteSaleLine(id),
    });
  };

  // const quickbooksSync = async (saleId) => {
  //   setLoading(true);
  //   apiBase
  //     .get(`/sales/${saleId}/quickbooks-sync`)
  //     .then((res) => {
  //       setLoading(false);
  //       getSale();
  //       let msg = `${res.status}: Successfully synced to quickbooks.`;
  //       message.success(msg);
  //     })
  //     .catch((err) => {
  //       let msg = `${err.response.status}: Failed to create sale.`;
  //       console.error(err);
  //       message.error(msg);
  //       setLoading(false);
  //     });
  // };

  // const handleQuickbooksSync = () => {
  //   confirm({
  //     title: "Are you sure you want to sync to quickbooks?",
  //     icon: <ExclamationCircleFilled />,
  //     okText: "Yes",
  //     okType: "danger",
  //     cancelText: "No",
  //     okButtonProps: { disabled: loading, loading: loading },
  //     cancelButtonProps: { disabled: loading, loading: loading },
  //     onOk: () => quickbooksSync(sale.id),
  //   });
  // };

  const onFinishFailed = () => {
    message.error("Form fields reqired");
  };

  const saleLines = get(sale, "sale_lines", []);

  return (
    <div className="App__rowContentDetail">
      <Row gutter={4}>
        <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className={styles.card}>
            <div className={styles.cardTitle}>
              <div className={styles.leftTitle}>
                <h2>Sale</h2>
              </div>
              <div className={styles.rightTitle}>
                <div>
                  {/* <Button
                    onClick={() => handleQuickbooksSync()}
                    className={styles.cardHeaderAction}
                    disabled={loading}
                    loading={loading}
                  >
                    Quickbooks Sync
                  </Button> */}

                  <Button
                    onClick={() => handleDelete()}
                    className={styles.cardHeaderAction}
                    disabled={loading}
                    loading={loading}
                  >
                    Delete
                  </Button>
                  <Button
                    onClick={() => form.resetFields()}
                    className={styles.cardHeaderAction}
                    disabled={loading}
                  >
                    Reset
                  </Button>
                  <Button
                    className={styles.cardHeaderAction}
                    type="primary"
                    loading={loading}
                    htmlType="submit"
                    onClick={() => {
                      form.submit();
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
            <div className={styles.cardBody}>
              <Row gutter={24}>
                <Col span={24}>
                  {!isEmpty(sale) && (
                    <div className={styles.compactForm}>
                      <Form
                        name="basic"
                        layout="horizontal"
                        form={form}
                        initialValues={{
                          ...pick(sale, formFields),
                          effective_date: sale.effective_date
                            ? moment(sale.effective_date)
                            : null,
                          sale_date: sale.sale_date
                            ? moment(sale.sale_date)
                            : null,
                          box_folder_id: sale.box_folder_id,
                        }}
                        onFinish={updateSubmit}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        disabled={loading}
                      >
                        <Row gutter={16}>
                          <Col
                            span={12}
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                          >
                            <Form.Item label="Entity" {...labelProps}>
                              <Link
                                to={`/portfolio/entities/${get(
                                  sale,
                                  "entity_id"
                                )}`}
                              >
                                {get(sale, "entity.name")}
                              </Link>
                            </Form.Item>
                            <Form.Item label="Asset" {...labelProps}>
                              <Link
                                to={`/portfolio/assets/${get(
                                  sale,
                                  "asset_id"
                                )}`}
                              >
                                {get(sale, "asset.name")}
                              </Link>
                            </Form.Item>
                            <Form.Item label="User" {...labelProps}>
                              <Link
                                to={`/settings/users/${get(sale, "user_id")}`}
                              >
                                {get(sale, "user.alias")}
                              </Link>
                            </Form.Item>
                            <Form.Item label="Amount" {...labelProps}>
                              {`$${formatNumber(sale.amount, "0,0.00")}`}
                            </Form.Item>
                            {[
                              {
                                name: "effective_date",
                                label: "Effective date",
                              },
                              {
                                name: "sale_date",
                                label: "Sale date",
                              },
                            ].map((item, index) => (
                              <Form.Item
                                key={index}
                                label={item.label}
                                name={item.name}
                                {...labelProps}
                              >
                                <DatePicker
                                  className="App__fullWidth"
                                  size="small"
                                  format={[
                                    "M/DD/YY",
                                    "M/D/YY",
                                    "MM/D/YY",
                                    "M/DD/YYYY",
                                    "M/D/YYYY",
                                    "MM/D/YYYY",
                                  ]}
                                />
                              </Form.Item>
                            ))}
                            <Form.Item
                              label="Amount"
                              name="amount"
                              {...labelProps}
                            >
                              <InputNumber
                                precision={2}
                                formatter={(value) =>
                                  `$ ${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                                }
                                parser={(value) =>
                                  value.replace(/\$\s?|(,*)/g, "")
                                }
                                className={`App__fullWidth ${styles.disabledItem}`}
                                disabled
                                size="small"
                              />
                            </Form.Item>
                            <Form.Item
                              label="Energy net fee percentage"
                              name="energy_net_fee_percentage"
                              rules={[
                                {
                                  required: false,
                                  message: "",
                                  type: "number",
                                  max: 100,
                                  min: 0,
                                },
                              ]}
                              {...labelProps}
                            >
                              <InputNumber
                                precision={6}
                                addonAfter={"%"}
                                placeholder="0.00"
                                className="App__fullWidth"
                                size="small"
                              />
                            </Form.Item>
                            <Form.Item
                              label="Box folder"
                              name="box_folder_id"
                              {...labelProps}
                            >
                              <BoxSearchSelect
                                searchUri="box/folder/search"
                                placeholder="Search box folder"
                                defaultOptions={[
                                  {
                                    value: get(sale, "box_folder_id"),
                                    label: get(sale, "box_folder_name"),
                                  },
                                ]}
                                size="small"
                              />
                            </Form.Item>
                            {/* <Form.Item label="Quickbooks Id" {...labelProps}>
                              <Input
                                disabled={true}
                                className={clsx({
                                  [styles.disabledItem]: true,
                                })}
                                size="small"
                                value={sale.qbo_id || "-"}
                              />
                            </Form.Item>
                            <Form.Item
                              label="Quickbooks deposit"
                              {...labelProps}
                            >
                              {sale.qbo_data ? (
                                <Tooltip
                                  overlayClassName="App__toolTipCard"
                                  placement="bottom"
                                  title={
                                    <ReactJson
                                      src={sale.qbo_data || {}}
                                      collapsed={true}
                                      style={{
                                        backgroundColor: "white",
                                        width: "484px",
                                        fontSize: "12px",
                                      }}
                                      displayDataTypes={false}
                                    />
                                  }
                                >
                                  <DatabaseOutlined />
                                </Tooltip>
                              ) : (
                                <span>-</span>
                              )}
                            </Form.Item> */}
                            <Form.Item
                              label="Description"
                              name="description"
                              {...labelProps}
                            >
                              <Input.TextArea rows={4} size="small" />
                            </Form.Item>
                            <Divider />
                            {saleLines && (
                              <div className={styles.objectLines}>
                                <div className={styles.header}>
                                  <div className={styles.leftTitle}>
                                    <h3>Sale lines</h3>
                                  </div>
                                  <div className={styles.rightTitle}>
                                    <SaleLineCreateForm
                                      sale={sale}
                                      getSale={getSale}
                                    />
                                  </div>
                                </div>
                                <div className={styles.content}>
                                  <Table
                                    pagination={false}
                                    size="small"
                                    dataSource={saleLines.map((item, idx) => ({
                                      ...item,
                                      key: idx,
                                    }))}
                                    bordered
                                    columns={[
                                      {
                                        key: "grantee",
                                        dataIndex: "grantee",
                                        title: "Grantee",
                                      },
                                      {
                                        key: "sale_code",
                                        dataIndex: "sale_code",
                                        title: "Sale Code",
                                      },
                                      {
                                        key: "fraction_conveyed",
                                        dataIndex: "fraction_conveyed",
                                        title: "Fraction Conveyed",
                                        render: (value) =>
                                          value !== null
                                            ? `${formatNumber(
                                                value * 100,
                                                "0.00"
                                              )}%`
                                            : null,
                                      },
                                      {
                                        key: "decimal_interest",
                                        dataIndex: "decimal_interest",
                                        title: "Decimal Interest",
                                        render: (value) =>
                                          value !== null
                                            ? `${formatNumber(
                                                value,
                                                "0.00000000"
                                              )}`
                                            : null,
                                      },
                                      {
                                        key: "amount",
                                        dataIndex: "amount",
                                        title: "",
                                        align: "right",
                                        width: 200,
                                        render: (value) =>
                                          value !== null
                                            ? `$${formatNumber(
                                                value,
                                                "0,0.00"
                                              )}`
                                            : null,
                                      },
                                      {
                                        title: "Actions",
                                        dataIndex: "id",
                                        key: "actions",
                                        render: (value, record) => {
                                          return (
                                            <div className="App__tableOperations">
                                              <Button
                                                shape="circle"
                                                icon={<DeleteOutlined />}
                                                size="small"
                                                onClick={() =>
                                                  showDeleteConfirm(record.id)
                                                }
                                                disabled={loading}
                                              />
                                            </div>
                                          );
                                        },
                                      },
                                    ]}
                                  />
                                </div>
                              </div>
                            )}
                          </Col>
                          <Col
                            span={12}
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                          >
                            {/* {sale.s3_url ? (
                              <div style={{ height: "80vh" }}>
                                <iframe
                                  title="sale-document"
                                  width={"100%"}
                                  height={"100%"}
                                  src={`${sale.s3_url}&embedded=true`}
                                ></iframe>
                              </div>
                            ) : (
                              <div>No documents</div>
                            )} */}
                            <Form.Item shouldUpdate={true} noStyle>
                              {({ getFieldValue }) => {
                                const folderId = getFieldValue("box_folder_id");
                                return (
                                  <BoxContentExplorer folderId={folderId} />
                                );
                              }}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withParams(Sale);
