import React, { Component } from "react";

import { QuestionCircleOutlined } from "@ant-design/icons";

import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";

import { Modal, Alert, Select, Tooltip, Checkbox } from "antd";

import styles from "./BulkEditForm.module.scss";

const BulkEditForm = Form.create({ name: "bulk_edit" })(
  // eslint-disable-next-line
  class extends Component {
    state = {
      options: [
        { label: "Lead Status", value: "lead_status" },
        { label: "Lead Owner", value: "user_id" },
        { label: "Lead Owner NTF", value: "ntf" },
      ],
      editFields: ["lead_status"],
    };

    handleFieldEdit = (checkedValues) => {
      this.setState({
        editFields: checkedValues,
      });
    };

    render() {
      const {
        visible,
        isLoading,
        onCancel,
        onCreate,
        form,
        selectedLeads,
        users,
        leadStatus,
      } = this.props;
      const { getFieldDecorator } = form;
      const title =
        selectedLeads.length === 0
          ? `Bulk Edit Leads - based on search filters`
          : `Bulk Edit Leads - ${selectedLeads.length} selected leads`;

      return (
        <Modal
          title={title}
          okText="Submit"
          cancelText="Cancel"
          visible={visible}
          onCancel={onCancel}
          onOk={onCreate}
          confirmLoading={isLoading}
          okButtonProps={{ disabled: this.state.editFields.length === 0 }}
          maskClosable={false}
        >
          <Alert
            message={"Select the fields that you wish to bulk edit"}
            type="info"
            style={{ marginBottom: 24 }}
          />
          <Checkbox.Group
            className={styles.checkBoxGroup}
            defaultValue={this.state.editFields}
            options={this.state.options}
            onChange={this.handleFieldEdit}
          />{" "}
          <Alert
            message={"Set the new values for the selected fields"}
            type="info"
            style={{ marginBottom: 24 }}
          />
          <Form layout="vertical">
            {this.state.editFields.indexOf("lead_status") > -1 && (
              <Form.Item
                label={
                  <span>
                    Lead Status&nbsp;
                    <Tooltip
                      title="When the offer is made we create a lead for each interest, this is the lead status assigned to the new leads."
                      placement="rightTop"
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator("status", {
                  rules: [
                    {
                      required: true,
                      type: "string",
                      message: "Please provide a status",
                    },
                  ],
                  initialValue: null,
                })(
                  <Select size="large">
                    {leadStatus.map((status, idx) => (
                      <Select.Option value={status} key={idx}>
                        {status}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            )}
            {this.state.editFields.indexOf("user_id") > -1 && (
              <Form.Item
                label={
                  <span>
                    Lead Owner&nbsp;
                    <Tooltip
                      title="When the offer is made we create a lead for each interest, this is the lead owner assigned to the new leads"
                      placement="rightTop"
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator("user_id", {
                  rules: [
                    {
                      required: true,
                      type: "integer",
                      message: "Please provide a Lead Owner",
                    },
                  ],
                  initialValue: null,
                })(
                  <Select size="large">
                    {users.map((user, idx) => (
                      <Select.Option value={user.id} key={idx}>
                        {user.alias}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            )}
            {this.state.editFields.indexOf("ntf") > -1 && (
              <Form.Item
                label={
                  <span>
                    Lead Owner NTF&nbsp;
                    <Tooltip
                      title="Does the owner need to be found?"
                      placement="rightTop"
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator("ntf", {
                  initialValue: false,
                })(<Checkbox />)}
              </Form.Item>
            )}
          </Form>
        </Modal>
      );
    }
  }
);

export default BulkEditForm;
