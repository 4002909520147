import React from "react";
import { Tooltip } from "antd";
import ReactJson from "react-json-view";
import { DatabaseOutlined } from "@ant-design/icons";

import styles from "./RawDataColumn.module.scss";

const RawDataColumn = (props) => {
  const { data, placement = "leftBottom" } = props;
  const result = data || {};
  return (
    <Tooltip
      overlayClassName={styles.toolTipCard}
      placement={placement}
      title={
        <ReactJson
          src={result}
          collapsed={false}
          style={{
            backgroundColor: "white",
            width: "484px",
            fontSize: "12px",
          }}
          displayDataTypes={false}
        />
      }
    >
      <DatabaseOutlined />
    </Tooltip>
  );
};

export default RawDataColumn;
