import React, { Component } from "react";
import { apiBase } from "../utils/apiBase";
import { get, isEmpty } from "lodash";
import { withParams } from "../utils/withParams";
import { Row, Col, Tabs, Form, Input, Button, message } from "antd";

import { LoadingOutlined } from "@ant-design/icons";

import Editor from "@monaco-editor/react";

import styles from "./MailTemplate.module.scss";

const convertToObject = (_string) => {
  try {
    return JSON.parse(_string);
  } catch (err) {
    return null;
  }
};

class MailTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mailTemplate: {},
      isLoading: false,
      templateValue: "",
      templateContext: "",
      renderedValue: null,
      renderStatus: null,
    };
  }

  componentDidMount() {
    this.loadTemplate();
  }

  componentDidUpdate() {
    if (
      this.state.mailTemplate.id &&
      this.state.mailTemplate.id !== parseInt(this.props.params.id)
    ) {
      this.loadTemplate();
    }
  }

  loadTemplate() {
    const mailTemplateId = this.props.params.id;
    this.setState({ isLoading: true });
    apiBase
      .get(`/mail-templates/${mailTemplateId}`)
      .then((res) => {
        const templateValue = get(res, "data.template");
        const templateContext = JSON.stringify(
          get(res, "data.context"),
          null,
          2
        );
        this.setState({
          mailTemplate: res.data,
          templateValue,
          templateContext,
          isLoading: false,
        });
        this.renderTemplate(templateValue, templateContext);
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to load Mail template ${mailTemplateId}`;
        message.error(msg);
        this.setState({
          isLoading: false,
        });
      });
  }

  renderTemplate = (templateValue, templateContext) => {
    apiBase
      .post(`/mail-templates/render`, {
        template: templateValue,
        context: templateContext,
      })
      .then((res) => {
        const { data, status } = res.data;
        this.setState({
          renderedValue: data,
          renderStatus: status,
        });
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to render template`;
        message.error(msg);
      });
  };

  handleTemplateChange = (value, event) => {
    const { templateContext } = this.state;
    this.setState(
      {
        templateValue: value,
      },
      () => {
        this.renderTemplate(value, templateContext);
      }
    );
  };

  handleContextChange = (value, event) => {
    const { templateValue } = this.state;
    this.setState(
      {
        templateContext: value,
      },
      () => {
        this.renderTemplate(templateValue, value);
      }
    );
  };

  onFinish = (values) => {
    const { mailTemplate, templateValue, templateContext } = this.state;

    const context = convertToObject(templateContext);
    if (!context) {
      message.error("Context is an invalid object");
      return;
    }

    this.setState({ isLoading: true });
    apiBase
      .patch(`/mail-templates/${mailTemplate.id}`, {
        ...values,
        pdfkit_options: JSON.parse(values.pdfkit_options),
        template: templateValue,
        context: JSON.parse(templateContext),
      })
      .then((res) => {
        let msg = `${get(res, "status")}: Successfully updated template.`;
        this.setState({ mailTemplate: res.data });
        message.success(msg);
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    const {
      mailTemplate,
      templateValue,
      templateContext,
      renderedValue,
      renderStatus,
      isLoading,
    } = this.state;

    return (
      <div className={`${styles.fullHeight}`}>
        <Row className={`App__rowContent ${styles.fullHeight}`}>
          <Col span={12} className={styles.form}>
            {isEmpty(mailTemplate) ? (
              <LoadingOutlined />
            ) : (
              <Form
                className={styles.fullHeight}
                name="mailTemplateEdit"
                layout={"vertical"}
                initialValues={{
                  name: mailTemplate.name,
                  description: mailTemplate.description,
                  pdfkit_options: JSON.stringify(
                    mailTemplate.pdfkit_options,
                    null,
                    2
                  ),
                }}
                onFinish={this.onFinish}
                autoComplete="off"
                disabled={isLoading}
              >
                <Tabs
                  className={styles.fullHeight}
                  tabBarExtraContent={
                    <>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                      >
                        Save
                      </Button>
                    </>
                  }
                  defaultActiveKey="1"
                >
                  <Tabs.TabPane tab="Details" key="details">
                    <Form.Item
                      label="Name"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "A name is required",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item label="Description" name="description">
                      <Input.TextArea />
                    </Form.Item>
                    <Form.Item
                      label="Options"
                      name="pdfkit_options"
                      tooltip={
                        <div>
                          <a
                            href="https://doc.qt.io/archives/qt-4.8/qprinter.html#PaperSize-enum"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Letter sizes
                          </a>
                          <br />
                          <a
                            href="https://wkhtmltopdf.org/usage/wkhtmltopdf.txt"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Options
                          </a>
                        </div>
                      }
                    >
                      <Input.TextArea rows={18} />
                    </Form.Item>
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab="Template"
                    key="template"
                    className={styles.fullHeight}
                  >
                    <Editor
                      className={styles.editor}
                      height="calc(100% - 20px)"
                      defaultLanguage="html"
                      theme="vs-dark"
                      options={{
                        fontSize: 14,
                      }}
                      defaultValue={templateValue}
                      onChange={this.handleTemplateChange}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab="Context"
                    key="context"
                    className={styles.fullHeight}
                  >
                    <Editor
                      className={styles.editor}
                      height="calc(100% - 20px)"
                      defaultLanguage="json"
                      theme="vs-dark"
                      options={{
                        fontSize: 14,
                      }}
                      defaultValue={templateContext}
                      onChange={this.handleContextChange}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab="PDF"
                    key="pdf"
                    className={styles.fullHeight}
                  >
                    {isLoading ? (
                      <p>Loading...</p>
                    ) : !mailTemplate.example_url ? (
                      <p>Save to generate example pdf</p>
                    ) : (
                      <div className={styles.pdfContainer}>
                        <object
                          data={mailTemplate.example_url}
                          type="application/pdf"
                          width="100%"
                          height="100%"
                          aria-label="Example pdf"
                        />
                      </div>
                    )}
                  </Tabs.TabPane>
                </Tabs>
              </Form>
            )}
          </Col>
          <Col span={12} className={styles.paddingBottom}>
            {renderStatus === "error" ? (
              <pre>{renderedValue}</pre>
            ) : (
              <iframe
                title="Rendered template"
                className={styles.renderedArea}
                srcDoc={renderedValue}
              />
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default withParams(MailTemplate);
