const navigationPathMapper = {
  permit_plats: new RegExp("permit-plats"),
  permits: new RegExp("permits"),
  units: new RegExp("units"),
  owners: new RegExp("owners"),
  owner_name_addresses: new RegExp("owner-name-addresses"),
  interests: new RegExp("interests"),
  generic_file_records: new RegExp("generic-file-records"),
  offers: new RegExp("offers"),
  leads: new RegExp("leads"),
  mail_exports: new RegExp("mail-exports"),
  mail_queue: new RegExp("/mail-queue"),
  usps_bulk_searches: new RegExp("usps-bulk-searches"),
  idi_bulk_searches: new RegExp("idi-bulk-searches"),
  pipedrive_uploads: new RegExp("pipedrive-uploads"),
  generic_files: new RegExp("generic-files"),
  crmReportsMain: new RegExp("crm/reports/main"),
  map: new RegExp("map"),
  entities: new RegExp("portfolio/entities"),
  assets: new RegExp("portfolio/assets"),
  tracts: new RegExp("portfolio/tracts"),
  deals: new RegExp("portfolio/deals"),
  wells: new RegExp("portfolio/wells"),
  operators: new RegExp("portfolio/operators"),
  royalties: new RegExp("portfolio/royalties"),
  sales: new RegExp("portfolio/sales"),
  profile: new RegExp("settings/users/"),
  users: new RegExp("settings/users$"),
  interest_filters: new RegExp("settings/interest-filters"),
  lead_customization: new RegExp("settings/lead-customization"),
  pipedrive_syncs: new RegExp("settings/pipedrive-syncs"),
  rrc_syncs: new RegExp("settings/rrc-syncs"),
  jobs: new RegExp("settings/jobs"),
  mail_templates: new RegExp("settings/mail-templates"),
  crm_settings: new RegExp("settings/crm"),
  portfolio_settings: new RegExp("settings/portfolio"),
  labels: new RegExp("settings/labels"),
};

const getNavSelectedKeys = (pathname) => {
  for (const [key, value] of Object.entries(navigationPathMapper)) {
    if (value.test(pathname)) {
      return [key];
    }
  }
};

export { getNavSelectedKeys };
