import React, { useState } from "react";
import { apiBase } from "../../utils/apiBase";
import { sumBy, get } from "lodash";
import { formatNumber } from "../../utils/numbers";
import { clsx } from "clsx";

import {
  Modal,
  Form,
  Input,
  Button,
  Checkbox,
  message,
  DatePicker,
  InputNumber,
} from "antd";

import { PlusOutlined } from "@ant-design/icons";

import styles from "./DealExpenseCreateForm.module.scss";

const DealExpenseCreateForm = (props) => {
  const { deal, dealAssets } = props;

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [validationError, setValidationError] = useState(null);

  const onFinish = async (values) => {
    setLoading(true);
    apiBase
      .post(`deals/${deal.id}/expense`, {
        ...values,
      })
      .then(async (res) => {
        message.success("Successfully created deal expense.");
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to create deal expense.`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
        resetComponent();
        setIsModalOpen(false);
        props.getDeal();
        props.getDealAssets();
        props.getDealExpenses();
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    resetComponent();
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    resetComponent();
  };

  const submitForm = () => {
    form
      .validateFields()
      .then((values) => {
        const _validationError = validateAggregates(values);
        if (_validationError) {
          return;
        }

        form.submit();
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };

  const onFormChange = (changedValues, allValues) => {
    const { dealAssets } = props;

    if (validationError) {
      setValidationError(null);
    }

    if (
      get(allValues, "manual_allocation") === false &&
      get(changedValues, "amount")
    ) {
      // If amount changes and we are not in manual allocation
      const assetAllocations = dealAssets.map((dealAsset) => {
        const assetAllocationAmount =
          get(allValues, "amount", 0) * get(dealAsset, "allocation", 0);

        return {
          deal_asset_id: dealAsset.id,
          amount: assetAllocationAmount,
          asset_name: get(dealAsset, "asset.name"),
        };
      });

      form.setFieldsValue({
        asset_allocations: assetAllocations,
      });
    }

    if (get(changedValues, "manual_allocation") === false) {
      // If we set to manual allocation false then reset values
      form.setFieldsValue({
        asset_allocations: dealAssets.map((dealAsset) => ({
          deal_asset_id: dealAsset.id,
          amount: get(allValues, "amount", 0) * get(dealAsset, "allocation", 0),
          asset_name: get(dealAsset, "asset.name"),
        })),
      });
    }
  };

  const validateAggregates = (values) => {
    let _validationError = null;

    const amount = get(values, "amount", 0);
    const assetAllocationsAmount = sumBy(
      get(values, "asset_allocations", []),
      "amount"
    );
    const amountDiff =
      Math.abs(amount - assetAllocationsAmount) > 0.01
        ? amount - assetAllocationsAmount
        : 0;

    if (Math.abs(parseFloat(amountDiff).toFixed(2)) > 0.01) {
      _validationError =
        "The sum of the well allocations must equal the total amount.";
    }

    if (_validationError) {
      message.warning("There are validation errors.");
    }

    setValidationError(_validationError);
    return _validationError;
  };

  const resetComponent = () => {
    form.resetFields();
  };

  const [form] = Form.useForm();

  return (
    <div>
      <Button type="secondary" icon={<PlusOutlined />} onClick={showModal} />
      <Modal
        title="Create deal expense"
        visible={isModalOpen}
        onOk={() => submitForm()}
        onCancel={handleCancel}
        cancelButtonProps={{ disabled: loading }}
        okButtonProps={{ disabled: loading, loading: loading }}
        maskClosable={false}
      >
        <Form
          name="dealExpenseCreate"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onFormChange}
          autoComplete="off"
          layout="vertical"
          disabled={loading}
          initialValues={{
            manual_allocation: false,
            asset_allocations: dealAssets.map((dealAsset) => ({
              deal_asset_id: dealAsset.id,
              amount: 0,
              asset_name: get(dealAsset, "asset.name"),
            })),
          }}
        >
          <Form.Item
            label="Date"
            name="date"
            rules={[
              {
                required: true,
                message: `Date is required.`,
              },
            ]}
          >
            <DatePicker
              className="App__fullWidth"
              format={[
                "M/DD/YY",
                "M/D/YY",
                "MM/D/YY",
                "M/DD/YYYY",
                "M/D/YYYY",
                "MM/D/YYYY",
              ]}
            />
          </Form.Item>
          <Form.Item label={"Description"} name="description">
            <Input.TextArea />
          </Form.Item>
          <div className={styles.amount}>
            <Form.Item
              className={styles.amountField}
              label="Amount"
              name="amount"
              rules={[
                {
                  required: true,
                  message: `Amount is required.`,
                },
              ]}
            >
              <InputNumber
                className="App__fullWidth"
                precision={2}
                prefix={"$"}
              />
            </Form.Item>
            <Form.Item
              label="Manual allocation"
              name="manual_allocation"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </div>
          <div className={`${styles.section} ${styles.compactList}`}>
            <div className={styles.sectionHeading}>Deal allocations</div>
            <Form.Item shouldUpdate={true} noStyle>
              {({ getFieldValue }) => {
                const manualAllocation = getFieldValue("manual_allocation");
                return (
                  <Form.List name="asset_allocations">
                    {(fields) => {
                      return (
                        <>
                          {fields.map((field, idx) => {
                            return (
                              <div key={idx}>
                                <div className={styles.line}>
                                  <Form.Item
                                    {...field}
                                    className={styles.childWell}
                                    name={[field.name, "asset_name"]}
                                    key={[field.key, "asset_name"]}
                                    noStyle
                                  >
                                    <Input
                                      className={styles.disabledItem}
                                      disabled
                                    />
                                  </Form.Item>

                                  <Form.Item
                                    {...field}
                                    className={styles.childAmount}
                                    name={[field.name, "amount"]}
                                    key={[field.key, "amount"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Missing amount",
                                      },
                                    ]}
                                  >
                                    <InputNumber
                                      precision={2}
                                      prefix={"$"}
                                      placeholder="0.00"
                                      disabled={!manualAllocation}
                                      className={clsx({
                                        [styles.disabledItem]:
                                          !manualAllocation,
                                      })}
                                    />
                                  </Form.Item>
                                </div>
                              </div>
                            );
                          })}
                          <Form.Item shouldUpdate={true} noStyle>
                            {({ getFieldValue }) => {
                              const amount = getFieldValue("amount");
                              const assetAllocationsAmount = sumBy(
                                getFieldValue("asset_allocations"),
                                "amount"
                              );
                              const amountDiff =
                                Math.abs(amount - assetAllocationsAmount) > 0.01
                                  ? amount - assetAllocationsAmount
                                  : 0;
                              return (
                                <div className={styles.section}>
                                  <div>Amount difference</div>
                                  <div
                                    className={
                                      Math.abs(amountDiff) > 0.01
                                        ? styles.error
                                        : ""
                                    }
                                  >{`$${formatNumber(
                                    amountDiff,
                                    "0,0.00"
                                  )}`}</div>
                                </div>
                              );
                            }}
                          </Form.Item>
                        </>
                      );
                    }}
                  </Form.List>
                );
              }}
            </Form.Item>
            {validationError && (
              <div className={styles.section}>
                <div className={styles.wellAllocationsSection}>
                  Validation errors:
                </div>
                <div className={styles.error}>{validationError}</div>
              </div>
            )}
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default DealExpenseCreateForm;
