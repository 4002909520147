import React from "react";
import { connect } from "react-redux";
import { get, isEmpty } from "lodash";

import { NavLink } from "react-router-dom";
import {
  EnvironmentOutlined,
  HomeOutlined,
  SolutionOutlined,
  FireOutlined,
  SwapOutlined,
  TeamOutlined,
  DollarCircleOutlined,
  LineChartOutlined,
} from "@ant-design/icons";

import { DEFAULT_PAGE_SIZE } from "../../common/navs";

import { Menu } from "antd";

import styles from "./Nav.module.scss";

const mapStateToProps = (state) => ({
  entity: get(state.entity, "data", {}),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const Portfilio = (props) => {
  const { collapsed, navSelectedKeys, entity } = props;

  let filters = "";
  if (!isEmpty(entity)) {
    filters = `filters=entity_id%3D${entity.id}`;
  }

  return (
    <Menu
      theme="light"
      mode="inline"
      className={collapsed ? null : styles.siderMenu}
      selectedKeys={navSelectedKeys}
    >
      <Menu.ItemGroup key="g1" title={collapsed ? "" : "Portfilio"}>
        <Menu.Item key="entities" className={styles.siderMenuItem}>
          <NavLink to={`/portfolio/entities?limit=${DEFAULT_PAGE_SIZE}&page=1`}>
            <HomeOutlined />
            <span>Entities</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="deals" className={styles.siderMenuItem}>
          <NavLink
            to={`/portfolio/deals?${filters}&limit=${DEFAULT_PAGE_SIZE}&page=1`}
          >
            <SolutionOutlined />
            <span>Deals</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="assets" className={styles.siderMenuItem}>
          <NavLink
            to={`/portfolio/assets?${filters}&limit=${DEFAULT_PAGE_SIZE}&page=1`}
          >
            <EnvironmentOutlined />
            <span>Assets</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="tracts" className={styles.siderMenuItem}>
          <NavLink
            to={`/portfolio/tracts?${filters}&limit=${DEFAULT_PAGE_SIZE}&page=1`}
          >
            <EnvironmentOutlined />
            <span>Tracts</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="operators" className={styles.siderMenuItem}>
          <NavLink
            to={`/portfolio/operators?limit=${DEFAULT_PAGE_SIZE}&page=1`}
          >
            <TeamOutlined />
            <span>Operators</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="wells" className={styles.siderMenuItem}>
          <NavLink to={`/portfolio/wells?limit=${DEFAULT_PAGE_SIZE}&page=1`}>
            <FireOutlined />
            <span>Wells</span>
          </NavLink>
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.ItemGroup key="g2" title={collapsed ? "" : "Revenue"}>
        <Menu.Item key="royalties" className={styles.siderMenuItem}>
          <NavLink
            to={`/portfolio/royalties?${filters}&limit=${DEFAULT_PAGE_SIZE}&page=1`}
          >
            <DollarCircleOutlined />
            <span>Royalties</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="sales" className={styles.siderMenuItem}>
          <NavLink
            to={`/portfolio/sales?${filters}&limit=${DEFAULT_PAGE_SIZE}&page=1`}
          >
            <DollarCircleOutlined />
            <span>Sales</span>
          </NavLink>
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.ItemGroup key="g3" title={collapsed ? "" : "Communications"}>
        <Menu.Item key="transfers" className={styles.siderMenuItem}>
          <NavLink
            to={`/portfolio/transfers?${filters}&limit=${DEFAULT_PAGE_SIZE}&page=1`}
          >
            <SwapOutlined />
            <span>Transfers</span>
          </NavLink>
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.ItemGroup key="g4" title={collapsed ? "" : "Reports"}>
        <Menu.Item key="main" className={styles.siderMenuItem}>
          <NavLink
            to={`/portfolio/reports/main?${filters}&limit=${DEFAULT_PAGE_SIZE}&page=1`}
          >
            <LineChartOutlined />
            <span>Main</span>
          </NavLink>
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Portfilio);
