import React, { useState } from "react";
import { apiBase } from "../../utils/apiBase";
import moment from "moment";

import { Modal, Form, Button, Input, message } from "antd";

import { PlusOutlined } from "@ant-design/icons";

const AssetRoyaltyCreateForm = (props) => {
  const { asset } = props;

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    apiBase
      .post(`assets/${asset.id}/royalties/bulk`, {
        ...values,
      })
      .then(async (res) => {
        message.success("Successfully bulk created asset royalties.");
      })
      .catch((err) => {
        console.log(err);
        let msg = `${err.response.status}: Failed to bulk create asset royalties.`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
        resetComponent();
        setIsModalOpen(false);
        props.getAsset();
        props.getAssetRoyalties();
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    resetComponent();
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    resetComponent();
  };

  const submitForm = () => {
    form
      .validateFields()
      .then(() => {
        form.submit();
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };

  const resetComponent = () => {
    form.resetFields();
  };

  const [form] = Form.useForm();

  return (
    <div>
      <Button type="secondary" icon={<PlusOutlined />} onClick={showModal}>
        Bulk add
      </Button>
      <Modal
        title="Bulk create asset royalty"
        visible={isModalOpen}
        onOk={() => submitForm()}
        onCancel={handleCancel}
        cancelButtonProps={{ disabled: loading }}
        okButtonProps={{ disabled: loading, loading: loading }}
        maskClosable={false}
      >
        <Form
          name="bulkCreateAssetRoyalty"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
          disabled={loading}
          initialValues={{ date: moment() }}
        >
          <Form.Item
            label="Data"
            name="data"
            rules={[
              {
                required: true,
                message: "Data is required.",
              },
            ]}
          >
            <Input.TextArea className="App__fullWidth" rows={10} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AssetRoyaltyCreateForm;
