import React from "react";
import { connect } from "react-redux";
import { get, omit } from "lodash";
import { Select } from "antd";

const mapStateToProps = (state) => ({
  appConfig: get(state.appConfig, "data", {}),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

function AppConfigSelect(props) {
  const {
    appConfig,
    appConfigKey,
    optionsMapper = (d) => ({
      label: d,
      value: d,
    }),
  } = props;

  const options = get(appConfig, appConfigKey, []).map((d) => optionsMapper(d));

  return (
    <Select
      options={options}
      {...omit(props, [
        "dispatch",
        "appConfig",
        "appConfigKey",
        "optionsMapper",
      ])}
    />
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AppConfigSelect);
