import { message } from "antd";
import { apiBase } from "../utils/apiBase";

export const types = {
  BOX_AUTH_SET: "BOX_AUTH_SET",
  BOX_AUTH_SET_LOADING: "BOX_AUTH_SET_LOADING",
};

export const setLoadingBoxAuth = (value) => ({
  type: types.BOX_AUTH_SET_LOADING,
  value,
});

export const setBoxAuth = (value) => ({
  type: types.BOX_AUTH_SET,
  value,
});

export const getBoxAuth = () => (dispatch) => {
  dispatch(setLoadingBoxAuth(true));
  return apiBase
    .get(`box/token`)
    .then((res) => dispatch(setBoxAuth(res.data)))
    .catch((err) => {
      dispatch(setBoxAuth({}));
      message.error("Failed get box auth.");
    })
    .finally(() => {
      dispatch(setLoadingBoxAuth(false));
    });
};

export const initialState = {
  data: {},
  loading: false,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case types.BOX_AUTH_SET_LOADING:
      return {
        ...state,
        loading: action.value,
      };
    case types.BOX_AUTH_SET:
      return {
        ...state,
        data: action.value,
      };
    default:
      return state;
  }
}

const actions = {
  setBoxAuth,
  getBoxAuth,
};

export default actions;
