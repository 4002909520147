import React, { useState, useEffect, useCallback } from "react";
import { apiBase } from "../../utils/apiBase";
import { withParams } from "../../utils/withParams";
import { Helmet } from "react-helmet";
import { get, pick, isEmpty, omit } from "lodash";
import { clsx } from "clsx";
import moment from "moment";

import DealAssets from "./DealAssets";
import DealAssetCreateForm from "./DealAssetCreateForm";
import DealExpenses from "./DealExpenses";
import DealExpenseCreateForm from "./DealExpenseCreateForm";
import EntitySelect from "../common/EntitySelect";

import Notes from "../common/Notes";
import BoxSearchSelect from "../common/BoxSearchSelect";
import BoxContentExplorer from "../common/BoxContentExplorer";

import { transferStatusOptions } from "../../common/deals";

import {
  Row,
  Col,
  Button,
  Input,
  InputNumber,
  DatePicker,
  Form,
  Modal,
  Select,
  message,
} from "antd";

import { ExclamationCircleFilled, LinkOutlined } from "@ant-design/icons";

import styles from "./Deal.module.scss";

const { confirm } = Modal;

const labelProps = {
  labelAlign: "left",
  labelCol: {
    xxl: { span: 7, offset: 0 },
    xl: { span: 9 },
    lg: { span: 10 },
  },
};

const formFields = [
  "entity_id",
  "code",
  "name",
  "tracking_url",
  "review_url",
  "transfer_status",
  "pipedrive_deal_id",
  "purchase_price",
  "capitalized_expenses",
  "cost_basis",
  "nra",
  "box_folder_id",
];

const Deal = (props) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [deal, setDeal] = useState({});
  const [dealAssets, setDealAssets] = useState([]);
  const [dealExpenses, setDealExpenses] = useState([]);
  const [tabKey, setTabKey] = useState(2);

  const getDeal = useCallback(async () => {
    try {
      setLoading(true);
      const res = await apiBase.get(`/deals/${props.params.id}`);
      setDeal(get(res, "data", {}));
    } catch (err) {
      let msg = `${err.response.status}: Failed to load deal ${props.params.id}`;
      message.error(msg);
    } finally {
      setLoading(false);
    }
  }, [props.params.id]);

  const getDealAssets = useCallback(async () => {
    try {
      setLoading(true);
      const res = await apiBase.get(`/deals/${props.params.id}/assets`);
      setDealAssets(get(res, "data", {}));
    } catch (err) {
      let msg = `${err.response.status}: Failed to load deal assets ${props.params.id}`;
      message.error(msg);
    } finally {
      setLoading(false);
    }
  }, [props.params.id]);

  const getDealExpenses = useCallback(async () => {
    try {
      setLoading(true);
      const res = await apiBase.get(`/deals/${props.params.id}/expenses`);
      setDealExpenses(get(res, "data", {}));
    } catch (err) {
      let msg = `${err.response.status}: Failed to load deal expenses ${props.params.id}`;
      message.error(msg);
    } finally {
      setLoading(false);
    }
  }, [props.params.id]);

  useEffect(() => {
    getDeal();
    getDealAssets();
    getDealExpenses();
  }, [getDeal, getDealAssets, getDealExpenses]);

  useEffect(() => {
    if (!isEmpty(deal)) {
      form.setFieldsValue({
        ...pick(deal, formFields),
        effective_date: deal.effective_date
          ? moment(deal.effective_date)
          : null,
        acquired_date: deal.acquired_date ? moment(deal.acquired_date) : null,
      });
    }
  }, [form, deal]);

  const updateSubmit = (values) => {
    setLoading(true);
    apiBase
      .patch(`deals/${deal.id}`, omit(values, ["cost_basis", "nra"]))
      .then((res) => {
        setDeal(res.data);
        let msg = `${res.status}: Successfully updated deal.`;
        message.success(msg);
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
        form.resetFields();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteDeal = async (dealId) => {
    setLoading(true);
    apiBase
      .delete(`/deals/${dealId}`)
      .then((res) => {
        setLoading(false);
        props.history("/portfolio/deals?limit=50&page=1");
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to delete deal.`;
        message.error(msg);
        setLoading(false);
      });
  };

  const handleDelete = () => {
    confirm({
      title: "Are you sure you want to delete this deal?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      okButtonProps: { disabled: loading, loading: loading },
      cancelButtonProps: { disabled: loading, loading: loading },
      onOk: () => deleteDeal(deal.id),
    });
  };

  const onFinishFailed = () => {
    message.error("Form fields reqired");
  };

  return (
    <div className="App__rowContentDetail">
      <Helmet>
        <title>{`Deal: ${deal.name}`}</title>
      </Helmet>
      <Row gutter={4}>
        <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24} xxl={14}>
          <div className={styles.card}>
            <div className={styles.cardTitle}>
              <div className={styles.leftTitle}>
                <h2>Deal</h2>
              </div>
              <div className={styles.rightTitle}>
                <div>
                  <Button
                    onClick={() => handleDelete()}
                    className={styles.cardHeaderAction}
                    disabled={loading}
                    loading={loading}
                  >
                    Delete
                  </Button>
                  <Button
                    onClick={() => form.resetFields()}
                    className={styles.cardHeaderAction}
                    disabled={loading}
                  >
                    Reset
                  </Button>
                  <Button
                    className={styles.cardHeaderAction}
                    type="primary"
                    loading={loading}
                    htmlType="submit"
                    onClick={() => {
                      form.submit();
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
            <div className={styles.cardBody}>
              <Row gutter={24}>
                <Col span={24}>
                  {!isEmpty(deal) && (
                    <div className={styles.compactForm}>
                      <Form
                        name="basic"
                        layout="horizontal"
                        form={form}
                        initialValues={{
                          ...pick(deal, formFields),
                          effective_date: deal.effective_date
                            ? moment(deal.effective_date)
                            : null,
                          acquired_date: deal.acquired_date
                            ? moment(deal.acquired_date)
                            : null,
                          box_folder_id: deal.box_folder_id,
                        }}
                        onFinish={updateSubmit}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        disabled={loading}
                      >
                        <Row gutter={16}>
                          <Col
                            span={12}
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                          >
                            <Form.Item
                              label="Entity"
                              name="entity_id"
                              rules={[{ required: true, message: "" }]}
                              {...labelProps}
                            >
                              <EntitySelect size="small" />
                            </Form.Item>
                            {[
                              {
                                name: "code",
                                label: "Code",
                                required: true,
                              },
                              {
                                name: "name",
                                label: "Name",
                                required: true,
                              },
                            ].map((item, index) => (
                              <Form.Item
                                key={index}
                                label={item.label}
                                name={item.name}
                                rules={[
                                  { required: item.required, message: "" },
                                ]}
                                {...labelProps}
                              >
                                <Input
                                  onPressEnter={() => form.submit()}
                                  size="small"
                                />
                              </Form.Item>
                            ))}
                            <Form.Item label="Review link" {...labelProps}>
                              <Input.Group compact>
                                <Form.Item name="review_url" noStyle>
                                  <Input
                                    style={{
                                      width: "calc(100% - 24px)",
                                    }}
                                    size="small"
                                  />
                                </Form.Item>
                                <Button
                                  size="small"
                                  icon={<LinkOutlined />}
                                  onClick={() => {
                                    if (deal.review_url) {
                                      window.open(deal.review_url, "_blank");
                                    }
                                  }}
                                />
                              </Input.Group>
                            </Form.Item>
                            <Form.Item
                              label="Tracking link (old)"
                              {...labelProps}
                            >
                              <Input.Group compact>
                                <Form.Item name="tracking_url" noStyle>
                                  <Input
                                    style={{
                                      width: "calc(100% - 24px)",
                                    }}
                                    size="small"
                                  />
                                </Form.Item>
                                <Button
                                  size="small"
                                  icon={<LinkOutlined />}
                                  onClick={() => {
                                    if (deal.tracking_url) {
                                      window.open(deal.tracking_url, "_blank");
                                    }
                                  }}
                                />
                              </Input.Group>
                            </Form.Item>
                            <Form.Item
                              label="Pipedrive deal ID"
                              {...labelProps}
                            >
                              <Input.Group compact>
                                <Form.Item name="pipedrive_deal_id" noStyle>
                                  <Input
                                    style={{
                                      width: "calc(100% - 24px)",
                                    }}
                                    size="small"
                                  />
                                </Form.Item>
                                <Button
                                  size="small"
                                  icon={<LinkOutlined />}
                                  onClick={() => {
                                    if (deal.pipedrive_deal_id) {
                                      window.open(
                                        `https://taborminerals.pipedrive.com/deal/${deal.pipedrive_deal_id}`,
                                        "_blank"
                                      );
                                    }
                                  }}
                                />
                              </Input.Group>
                            </Form.Item>
                            <Form.Item
                              label="Box folder"
                              name="box_folder_id"
                              {...labelProps}
                            >
                              <BoxSearchSelect
                                searchUri="box/folder/search"
                                placeholder="Search box folder"
                                defaultOptions={[
                                  {
                                    value: get(deal, "box_folder_id"),
                                    label: get(deal, "box_folder_name"),
                                  },
                                ]}
                                size="small"
                              />
                            </Form.Item>
                          </Col>
                          <Col
                            span={12}
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                          >
                            {[
                              {
                                name: "purchase_price",
                                label: "Purchase price",
                                precision: 2,
                                formatter: (value) =>
                                  `$ ${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  ),
                                parser: (value) =>
                                  value.replace(/\$\s?|(,*)/g, ""),
                              },
                              {
                                name: "capitalized_expenses",
                                label: "Capitalized expenses",
                                precision: 2,
                                formatter: (value) =>
                                  `$ ${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  ),
                                parser: (value) =>
                                  value.replace(/\$\s?|(,*)/g, ""),
                              },
                              {
                                name: "cost_basis",
                                label: "Cost basis",
                                precision: 2,
                                formatter: (value) =>
                                  `$ ${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  ),
                                parser: (value) =>
                                  value.replace(/\$\s?|(,*)/g, ""),
                              },
                              {
                                name: "nra",
                                label: "NRA",
                                precision: 4,
                              },
                            ].map((item, index) => (
                              <Form.Item
                                key={index}
                                label={item.label}
                                name={item.name}
                                {...labelProps}
                              >
                                <InputNumber
                                  disabled={true}
                                  precision={item.precision}
                                  {...(item.formatter
                                    ? { formatter: item.formatter }
                                    : {})}
                                  {...(item.parser
                                    ? { parser: item.parser }
                                    : {})}
                                  className={clsx("App__fullWidth", {
                                    [styles.disabledItem]: !loading,
                                  })}
                                  size="small"
                                />
                              </Form.Item>
                            ))}
                            {[
                              {
                                name: "effective_date",
                                label: "Effective date",
                              },
                              { name: "acquired_date", label: "Acquired date" },
                            ].map((item, index) => (
                              <Form.Item
                                key={index}
                                label={item.label}
                                name={item.name}
                                {...labelProps}
                              >
                                <DatePicker
                                  className="App__fullWidth"
                                  size="small"
                                  format={[
                                    "M/DD/YY",
                                    "M/D/YY",
                                    "MM/D/YY",
                                    "M/DD/YYYY",
                                    "M/D/YYYY",
                                    "MM/D/YYYY",
                                  ]}
                                />
                              </Form.Item>
                            ))}
                            <Form.Item
                              label="Transfer status"
                              name="transfer_status"
                              {...labelProps}
                            >
                              <Select
                                size="small"
                                options={transferStatusOptions}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.cardTitle}>
              <div className={styles.leftTitle}>
                <h2>Assets</h2>
              </div>
              <div className={styles.rightTitle}>
                <div>
                  <DealAssetCreateForm
                    deal={deal}
                    getDeal={getDeal}
                    getDealAssets={getDealAssets}
                  />
                </div>
              </div>
            </div>
            <div className={styles.cardBody}>
              <Row gutter={24}>
                <Col span={24}>
                  <DealAssets
                    deal={deal}
                    getDeal={getDeal}
                    dealAssets={dealAssets}
                    getDealAssets={getDealAssets}
                    parentLoading={loading}
                  />
                </Col>
              </Row>
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.cardTitle}>
              <div className={styles.leftTitle}>
                <h2>Deal expenses</h2>
              </div>
              <div className={styles.rightTitle}>
                <div>
                  <DealExpenseCreateForm
                    deal={deal}
                    dealAssets={dealAssets}
                    getDeal={getDeal}
                    getDealAssets={getDealAssets}
                    getDealExpenses={getDealExpenses}
                  />
                </div>
              </div>
            </div>
            <div className={styles.cardBody}>
              <Row gutter={24}>
                <Col span={24}>
                  <DealExpenses
                    deal={deal}
                    getDeal={getDeal}
                    getDealAssets={getDealAssets}
                    dealExpenses={dealExpenses}
                    getDealExpenses={getDealExpenses}
                    parentLoading={loading}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24} xxl={10}>
          <div className={styles.cardTabs}>
            <div className={styles.cardTitle}>
              <div className={styles.leftTitle}>
                <div
                  className={tabKey === 1 ? styles.isActive : null}
                  onClick={() => setTabKey(1)}
                >
                  <h2>Notes</h2>
                </div>
                <div
                  className={tabKey === 2 ? styles.isActive : null}
                  onClick={() => setTabKey(2)}
                >
                  <h2>Documents</h2>
                </div>
              </div>
              <div className={styles.rightTitle}></div>
            </div>
            <div className={styles.cardBody}>
              {tabKey === 1 ? (
                <Notes recordId={deal.id} recordType={"deal"} />
              ) : (
                <BoxContentExplorer folderId={deal.box_folder_id || null} />
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withParams(Deal);
