const years = [
  2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012,
  2011, 2010, 2009, 2008,
];

const yearsOptions = years.map((value) => ({
  value: value,
  label: String(value),
}));

const defaultYear = 2023;

export { years, yearsOptions, defaultYear };
