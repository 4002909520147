import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withParams } from "../utils/withParams";
import { get } from "lodash";
// import { clsx } from "clsx";
import actions from "../actions";
import { LabelIcon } from "../views/common/LabelIcon";

import {
  Row,
  Col,
  Form,
  Button,
  Input,
  Select,
  message,
  InputNumber,
  Empty,
} from "antd";

import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";

import styles from "./Labels.module.scss";

const Labels = (props) => {
  const dispatch = useDispatch();
  const labels = useSelector((state) => get(state.labels, "data", []));

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selectedLabelType, setSelectedLabelType] = useState("permit");

  useEffect(() => {
    dispatch(actions.labels.getLabels());
  }, [dispatch]);

  useEffect(() => {
    if (labels && Object.keys(labels).length > 0) {
      const filteredLabels = labels
        .filter((label) => label.record_type === selectedLabelType)
        .sort((a, b) => a.order - b.order);
      form.setFieldsValue({
        labels: filteredLabels,
      });
    }
  }, [labels, selectedLabelType, form]);

  const labelTypes = [
    { value: "unit", label: "Unit" },
    { value: "permit", label: "Permit" },
    { value: "permit_plat", label: "Permit plat" },
  ];

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await dispatch(
        actions.labels.updateLabels({
          record_type: selectedLabelType,
          labels: values.labels,
        })
      );
      message.success("Labels updated successfully");
    } catch (error) {
      message.error("Failed to update labels");
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = () => {
    message.error("Form fields required");
  };

  const handleLabelTypeChange = (value) => {
    setSelectedLabelType(value);
  };

  return (
    <div className="App__rowContent">
      <Row gutter={16}>
        <Col span={24}>
          <div className={styles.card}>
            <div className={styles.cardTitle}>
              <div className={styles.leftTitle}>
                <h2>Labels</h2>
              </div>
              <div className={styles.rightTitle}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Select
                    style={{ width: 200 }}
                    value={selectedLabelType}
                    onChange={handleLabelTypeChange}
                    options={labelTypes}
                  />
                  <Button
                    className={styles.cardHeaderAction}
                    type="primary"
                    loading={loading}
                    htmlType="submit"
                    onClick={() => {
                      form.submit();
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
            <div className={styles.cardBody}>
              <Row gutter={24}>
                <Col span={24}>
                  <Form
                    name="basic"
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    disabled={loading}
                    className={styles.compactForm}
                  >
                    <Form.List name="labels">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.length === 0 ? (
                            <Empty
                              description="No labels found"
                              style={{ marginBottom: "20px" }}
                            />
                          ) : (
                            <Row style={{ marginBottom: "8px" }}>
                              <Col span={8}>
                                <strong>Value</strong>
                              </Col>
                              <Col span={4}>
                                <strong>Color</strong>
                              </Col>
                              <Col span={4}>
                                <strong>Background Color</strong>
                              </Col>
                              <Col span={3}>
                                <strong>Order</strong>
                              </Col>
                              <Col span={3}>
                                <strong>Example</strong>
                              </Col>
                              <Col span={2}>
                                <strong>Action</strong>
                              </Col>
                            </Row>
                          )}
                          {fields.map(({ key, name, ...restField }) => (
                            <Row key={key}>
                              <Col span={8}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "value"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Name is required",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Label name" />
                                </Form.Item>
                              </Col>
                              <Col span={4}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "color"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Color is required",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Color (e.g. #FFFFFF)" />
                                </Form.Item>
                              </Col>
                              <Col span={4}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "background_color"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Background color is required",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Background (e.g. #FF0000)" />
                                </Form.Item>
                              </Col>
                              <Col span={3}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "order"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Order is required",
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    placeholder="Order"
                                    style={{ width: "100%" }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={3}>
                                <Form.Item shouldUpdate={true}>
                                  {({ getFieldValue }) => {
                                    const value = getFieldValue([
                                      "labels",
                                      name,
                                      "value",
                                    ]);
                                    const color = getFieldValue([
                                      "labels",
                                      name,
                                      "color",
                                    ]);
                                    const backgroundColor = getFieldValue([
                                      "labels",
                                      name,
                                      "background_color",
                                    ]);
                                    return (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          padding: "0px 10px 0px 10px",
                                        }}
                                      >
                                        <LabelIcon
                                          content={value || "Label"}
                                          backgroundColor={
                                            backgroundColor || "black"
                                          }
                                          color={color || "white"}
                                          className={styles.pipedriveLabel}
                                        />
                                      </div>
                                    );
                                  }}
                                </Form.Item>
                              </Col>
                              <Col span={2}>
                                <Button
                                  type="text"
                                  danger
                                  icon={<DeleteOutlined />}
                                  onClick={() => remove(name)}
                                />
                              </Col>
                              <Form.Item
                                {...restField}
                                name={[name, "id"]}
                                hidden
                              >
                                <Input />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, "record_type"]}
                                hidden
                                initialValue={selectedLabelType}
                              >
                                <Input />
                              </Form.Item>
                            </Row>
                          ))}

                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() =>
                                add({
                                  record_type: selectedLabelType,
                                  order: fields.length + 1,
                                })
                              }
                              block
                              icon={<PlusOutlined />}
                            >
                              Add Label
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Form>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withParams(Labels);
