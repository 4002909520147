import React from "react";
import { connect } from "react-redux";
import { get, find, omit } from "lodash";
import actions from "../../actions";
import { Select } from "antd";

const mapStateToProps = (state) => ({
  entities: get(state.entities, "data", []),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

function EntitySelect(props) {
  const { entities, dispatch } = props;

  const onChange = (value) => {
    dispatch(actions.entity.setEntity(find(entities, { id: value })));
    props.onChange(value);
  };

  return (
    <Select
      placeholder="Select an entity"
      options={entities.map((entity) => ({
        value: entity.id,
        label: entity.name,
      }))}
      {...omit(props, ["dispatch", "entities"])}
      onChange={onChange}
    />
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EntitySelect);
