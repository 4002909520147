import React, { useState } from "react";
import { useSelector } from "react-redux";
import { get } from "lodash";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Modal, Select, Tooltip, Form, Radio } from "antd";

const PermitPlatBulkEditForm = ({
  visible,
  isLoading,
  onCancel,
  onCreate,
  selectedPermitPlats,
}) => {
  const [form] = Form.useForm();
  const [labelAction, setLabelAction] = useState("add"); // 'add' or 'remove'

  // Get labels from redux state
  const labels = useSelector((state) =>
    get(state.labels, "data", [])
      .filter((label) => label.record_type === "permit_plat")
      .map((label) => ({
        value: label.id,
        label: label.value,
      }))
  );

  const resetForm = () => {
    setLabelAction("add");
    form.resetFields();
  };

  const title =
    selectedPermitPlats.length === 0
      ? `Bulk Edit Permit Plats - based on search filters`
      : `Bulk Edit Permit Plats - ${selectedPermitPlats.length} selected permit plats`;

  return (
    <Modal
      title={title}
      okText="Submit"
      cancelText="Cancel"
      open={visible}
      onCancel={() => {
        onCancel();
        resetForm();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            const transformedValues = { ...values, label_action: labelAction };
            onCreate(transformedValues);
            resetForm();
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      confirmLoading={isLoading}
      maskClosable={false}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label={
            <span>
              Label Action&nbsp;
              <Tooltip title="Choose whether to add or remove the selected labels">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
        >
          <Radio.Group
            value={labelAction}
            onChange={(e) => setLabelAction(e.target.value)}
          >
            <Radio value="add">Add Labels</Radio>
            <Radio value="remove">Remove Labels</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="label_ids"
          label={
            <span>
              Labels&nbsp;
              <Tooltip title="Select the labels to add or remove">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
          rules={[
            { required: true, message: "Please select at least one label" },
          ]}
        >
          <Select
            mode="multiple"
            placeholder="Select labels"
            style={{ width: "100%" }}
            options={labels}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PermitPlatBulkEditForm;
