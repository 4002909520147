import { capitalize } from "lodash";

const syncStatus = {
  queued: "queued",
  running: "running",
  completed: "completed",
  warning: "warning",
  failed: "failed",
};

const statusOptions = Object.keys(syncStatus).map((key) => ({
  value: key,
  label: capitalize(key),
}));

const leadTypes = {
  tax_roll: "tax_roll",
  generics: "generics",
  secondary: "secondary",
};

const leadTypeOptions = Object.keys(leadTypes).map((key) => ({
  value: key,
  label: capitalize(key).replace("_", " "),
}));

export { syncStatus, statusOptions, leadTypes, leadTypeOptions };
