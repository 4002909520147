import React, { Component } from "react";
import { apiBase } from "../utils/apiBase";
import { orderBy } from "lodash";
import { alphaSort } from "../utils/numbers";
import { formatDate } from "../utils/dates";
import TotalRecords from "../views/search/TotalRecords";

import {
  MailOutlined,
  QuestionCircleOutlined,
  UserAddOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";

import {
  Row,
  Col,
  Table,
  Button,
  Modal,
  Input,
  Radio,
  Tooltip,
  Tag,
  message,
} from "antd";

const UserCreateForm = Form.create({ name: "user_create" })(
  // eslint-disable-next-line
  class extends Component {
    render() {
      const { visible, isLoading, onCancel, onCreate, form } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal
          visible={visible}
          title="Create a new User"
          okText="Create"
          cancelText="Cancel"
          onCancel={onCancel}
          onOk={onCreate}
          confirmLoading={isLoading}
          maskClosable={false}
        >
          <Form layout="vertical">
            <Form.Item label="First Name">
              {getFieldDecorator("first_name", {
                rules: [
                  {
                    required: true,
                    message: "Please provide the users first name",
                  },
                ],
              })(
                <Input
                  type="string"
                  prefix={<UserOutlined />}
                  placeholder="John"
                  size="large"
                />
              )}
            </Form.Item>
            <Form.Item label="Last Name">
              {getFieldDecorator("last_name", {
                rules: [
                  {
                    required: true,
                    message: "Please provide the users last name",
                  },
                ],
              })(
                <Input
                  type="string"
                  prefix={<UserOutlined />}
                  placeholder="Smith"
                  size="large"
                />
              )}
            </Form.Item>
            <Form.Item
              label={
                <span>
                  Alias&nbsp;
                  <Tooltip
                    title="This will be the display name for user activities"
                    placement="rightTop"
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator("alias", {
                rules: [
                  {
                    required: true,
                    message: "Please provide an alias for the user",
                  },
                ],
              })(
                <Input
                  type="string"
                  prefix={<UserOutlined />}
                  placeholder="jsmith"
                  size="large"
                />
              )}
            </Form.Item>
            <Form.Item label="Email">
              {getFieldDecorator("email", {
                rules: [
                  {
                    required: true,
                    message: "Please provide the email address",
                  },
                ],
              })(
                <Input
                  type="email"
                  prefix={<MailOutlined />}
                  placeholder="john@mineralcorp.com"
                  size="large"
                />
              )}
            </Form.Item>
            <Form.Item
              label={
                <span>
                  Role&nbsp;
                  <Tooltip
                    title="The 'USER' role has a limited set of permissions, e.g. the role cannot add new users or configure the app. Note this can be changed at any time."
                    placement="rightTop"
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator("role", {
                rules: [
                  {
                    required: true,
                    message: "Please specify the user role",
                  },
                ],
                initialValue: "USER",
              })(
                <Radio.Group>
                  <Radio value={"USER"}>USER</Radio>
                  <Radio value={"ADMIN"}>ADMIN</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

class Users extends Component {
  state = {
    users: [],
    visible: false,
    isLoadingSendInvite: false,
    isLoading: false,
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      } else {
        this.setState({ isLoading: true });
        apiBase
          .post("/users", values)
          .then((res) => {
            let users = this.state.users;
            this.setState({
              users: [...users, res.data],
              visible: false,
              isLoading: false,
            });
            let msg = `${res.status}: Successfully created new user.`;
            message.success(msg);
            form.resetFields();
          })
          .catch((err) => {
            let msg = `${err.response.status}: ${err.response.data.message}`;
            message.error(msg);
            this.setState({
              visible: false,
              isLoading: false,
            });
            form.resetFields();
          });
      }
    });
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  componentDidMount() {
    this.getUsers();
  }

  getUsers() {
    this.setState({ isLoading: true });
    apiBase
      .get(`/users`)
      .then((res) => {
        this.setState({
          users: res.data.map((el, idx) => ({ ...el, ...{ key: idx } })),
          isLoading: false,
        });
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to load Users`;
        message.error(msg);
      });
  }

  handleRowClick(e, _to) {
    e.stopPropagation();
    if (e.ctrlKey || e.metaKey) {
      window.open(_to, "_blank");
      return;
    }
    this.props.history(_to);
  }

  handleResendInvite = (e, userId) => {
    // e.preventDefault()
    this.setState({ isLoadingSendInvite: true });
    apiBase
      .get(`/users/${userId}/invite`)
      .then((res) => {
        this.setState({ isLoadingSendInvite: false });
        let msg = `${res.status}: Successfully Resent Invite.`;
        message.success(msg);
      })
      .catch((err) => {
        let msg = `${err.response.status}: ${err.response.data.message}`;
        message.error(msg);
        this.setState({ isLoadingSendInvite: false });
      });
  };

  render() {
    const columns = [
      {
        title: "User",
        dataIndex: "id",
        className: "App__rowLink",
        render: (value) => <div className="App__rowIcon">{value}</div>,
        onCell: (record) => ({
          onClick: (e) =>
            this.handleRowClick(e, `/settings/users/${record["id"]}`),
        }),
      },
      {
        title: "First Name",
        dataIndex: "first_name",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => alphaSort(a.first_name, b.first_name),
      },
      {
        title: "Last Name",
        dataIndex: "last_name",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => alphaSort(a.last_name, b.last_name),
      },
      {
        title: "Alias",
        dataIndex: "alias",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => alphaSort(a.alias, b.alias),
      },
      {
        title: "Email",
        dataIndex: "email",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => alphaSort(a.email, b.email),
      },
      {
        title: "Email Verified",
        dataIndex: "email_verified",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => alphaSort(a.email_verified, b.email_verified),
        render: (value, record) => {
          if (value === true) {
            return <Tag color="green">Verified</Tag>;
          } else {
            return (
              <div>
                <Tag>Invited</Tag>
                <Button
                  size="small"
                  onClick={(e) => this.handleResendInvite(e, record["id"])}
                  loading={this.state.isLoadingSendInvite}
                >
                  Resend Invite
                </Button>
              </div>
            );
          }
        },
      },
      {
        title: "Active",
        dataIndex: "active",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => alphaSort(a.active, b.active),
        render: (value) => {
          if (value === true) {
            return <Tag color="green">Active</Tag>;
          } else {
            return <Tag color="red">Not Active</Tag>;
          }
        },
      },
      {
        title: "Role",
        dataIndex: "role",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => alphaSort(a.role, b.role),
      },
      {
        title: "Created Date",
        dataIndex: "created_at",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => alphaSort(a.created_at, b.created_at),
        render: (value) => formatDate(value, "YYYY-MM-DD, h:mm:ss A"),
      },
      {
        title: "Updated Date",
        dataIndex: "updated_at",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => alphaSort(a.updated_at, b.updated_at),
        render: (value) => formatDate(value, "YYYY-MM-DD, h:mm:ss A"),
      },
    ];

    return (
      <div>
        <Row className="App__rowContent">
          <Col span={8}>
            <Button size="large" shape="circle" onClick={this.showModal}>
              <UserAddOutlined />
            </Button>
            <UserCreateForm
              wrappedComponentRef={this.saveFormRef}
              visible={this.state.visible}
              isLoading={this.state.isLoading}
              onCancel={this.handleCancel}
              onCreate={this.handleCreate}
            />
          </Col>
          <Col span={8} offset={8} className="App__rightActions">
            <TotalRecords
              title={"Users"}
              totalRecords={this.state.users.length}
            />
          </Col>
        </Row>
        <Row className="App__rowContent">
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={orderBy(this.state.users, ["id"], ["asc"])}
              pagination={{ defaultPageSize: 25, showSizeChanger: false }}
              className="App__smallTables"
              loading={this.state.isLoading}
              showSorterTooltip={false}
              scroll={{ x: "100%" }}
              size="small"
              bordered
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default Users;
