import React, { useState } from "react";
import { apiBase } from "../../utils/apiBase";
import { withParams } from "../../utils/withParams";
import { withRouter } from "../../utils/withRouter";
import { get } from "lodash";
import { formatNumber, alphaSort } from "../../utils/numbers";
import { formatDate } from "../../utils/dates";

import { Form, Table, Button, Modal, message } from "antd";

import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";

import { EditableCell } from "../utils/TableUtils";

const { confirm } = Modal;

const UnitPermits = (props) => {
  const { unitPermits, getUnitPermits } = props;

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;

  const deleteUnitPermit = async (id) => {
    setLoading(true);
    apiBase
      .delete(`/permit-units/${id}`)
      .then((res) => {
        let msg = `${res.status}: Successfully deleted permit unit.`;
        message.success(msg);
        getUnitPermits();
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to delete permit unit.`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: "Are you sure you want to delete this permit unit?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      okButtonProps: { disabled: loading, loading: loading },
      cancelButtonProps: { disabled: loading, loading: loading },
      onOk: () => deleteUnitPermit(id),
    });
  };

  function handleRowClick(e, _to) {
    e.stopPropagation();
    if (e.ctrlKey || e.metaKey) {
      window.open(_to, "_blank");
      return;
    }
    props.history(_to);
  }

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
      unit_allocation: record.unit_allocation * 100,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (record) => {
    try {
      const row = await form.validateFields();
      setLoading(true);
      apiBase
        .patch(`permit-units/${record.id}`, {
          ...row,
          unit_allocation: row.unit_allocation / 100,
        })
        .then((res) => {
          let msg = `${res.status}: Successfully updated permit unit.`;
          message.success(msg);
          getUnitPermits();
        })
        .catch((err) => {
          let msg = `${get(err, "response.status")}: ${get(
            err,
            "response.data.message"
          )}`;
          message.error(msg);
        })
        .finally(() => {
          setEditingKey("");
          setLoading(false);
        });
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "Id",
      dataIndex: ["permit", "id"],
      className: `App__rowLink App__w8`,
      render: (value) => <div>{value}</div>,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => alphaSort(a.permit.id, b.permit.id),
      onCell: (record) => ({
        onClick: (e) => handleRowClick(e, `/permits/${record.permit.id}`),
      }),
    },
    {
      title: "Permit Plat",
      dataIndex: ["permit", "permit_plat_id"],
      className: `App__rowLink App__w10`,
      render: (value) => <div>{value}</div>,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        alphaSort(a.permit.permit_plat_id, b.permit.permit_plat_id),
      onCell: (record) => ({
        onClick: (e) =>
          handleRowClick(e, `/permit-plats/${record.permit.permit_plat_id}`),
      }),
    },
    {
      title: "Submitted",
      dataIndex: ["permit", "submitted_date"],
      className: `App__w12`,
      render: (value) => (
        <div>{value ? formatDate(value, "YYYY-MM-DD") : null}</div>
      ),
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        alphaSort(a.permit.submitted_date, b.permit.submitted_date),
    },
    {
      title: "Lease",
      dataIndex: ["permit", "lease"],
      render: (value) => <div>{value}</div>,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => alphaSort(a.permit.lease, b.permit.lease),
    },
    {
      title: "Operator",
      dataIndex: ["permit", "operator_name"],
      render: (value) => <div>{value}</div>,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        alphaSort(a.permit.operator_name, b.permit.operator_name),
    },
    {
      title: "Unit allocation",
      dataIndex: "unit_allocation",
      className: `App__w16`,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.unit_allocation - b.unit_allocation,
      render: (value) =>
        value !== null ? `${formatNumber(value * 100, "0.00")}%` : null,
      editable: true,
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "actions",
      className: `App__w12`,
      render: (value, record) => {
        const editable = isEditing(record);
        return (
          <div className="App__tableOperations ">
            {editable ? (
              <>
                <Button
                  shape="circle"
                  icon={<CheckOutlined />}
                  size="small"
                  className="App__tableEditButton"
                  onClick={() => save(record)}
                  disabled={loading}
                />
                <Button
                  shape="circle"
                  icon={<CloseOutlined />}
                  size="small"
                  className="App__tableEditButton"
                  onClick={() => cancel()}
                  disabled={loading}
                />
              </>
            ) : (
              <Button
                shape="circle"
                icon={<EditOutlined />}
                size="small"
                className="App__tableEditButton"
                onClick={() => edit(record)}
                disabled={loading}
              />
            )}
            <Button
              shape="circle"
              icon={<DeleteOutlined />}
              size="small"
              onClick={() => showDeleteConfirm(record.id)}
              disabled={loading}
            />
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        pagination={false}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        columns={mergedColumns}
        dataSource={unitPermits.map((unitPermit, idx) => ({
          ...unitPermit,
          key: idx,
        }))}
        showSorterTooltip={false}
        className="App__smallTables"
        loading={loading}
        bordered
      />
    </Form>
  );
};

export default withRouter(withParams(UnitPermits));
