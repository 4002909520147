import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Select } from "antd";
import { get } from "lodash";
import { apiBase } from "../../utils/apiBase";

const CreateMailExport = (props) => {
  const { visible, isLoading, onCancel, onCreate, selectedLeads } = props;
  const [form] = Form.useForm();

  const [mailTemplates, setMailTemplates] = useState([]);

  async function getMailTemplates() {
    const res = await apiBase.get("/mail-templates");
    setMailTemplates(get(res.data, "mail_templates", []));
  }

  useEffect(() => {
    getMailTemplates();
  }, []);

  const title =
    selectedLeads.length === 0
      ? `Create mail export - based on search filters`
      : `Create mail export - ${selectedLeads.length} selected leads`;

  return (
    <Modal
      title={title}
      okText="Create"
      cancelText="Cancel"
      visible={visible}
      onCancel={onCancel}
      cancelButtonProps={{ disabled: isLoading }}
      okButtonProps={{ disabled: isLoading, loading: isLoading }}
      maskClosable={false}
      onOk={() => {
        form
          .validateFields()
          .then(async (values) => {
            await onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          })
          .finally(() => {
            form.resetFields();
          });
      }}
    >
      {mailTemplates.length === 0 ? (
        <p>No mail templates exist please create one</p>
      ) : (
        <Form
          form={form}
          name="mailTemplateCreate"
          layout={"vertical"}
          autoComplete="off"
          disabled={isLoading}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "A name is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Mail template"
            name="mail_template_id"
            rules={[
              {
                required: true,
                message: "A mail template is required",
              },
            ]}
          >
            <Select>
              {mailTemplates.map((mailTemplate, idx) => (
                <Select.Option value={mailTemplate.id} key={idx}>
                  {mailTemplate.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Labels mail template"
            name="labels_mail_template_id"
            rules={[
              {
                required: true,
                message: "A labelsmail template is required",
              },
            ]}
          >
            <Select>
              {mailTemplates.map((mailTemplate, idx) => (
                <Select.Option value={mailTemplate.id} key={idx}>
                  {mailTemplate.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default CreateMailExport;
