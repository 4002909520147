const transferStatus = {
  not_transferred: "Not Transferred",
  notified: "Notified",
  completed: "Completed",
};

const transferStatusOptions = Object.keys(transferStatus).map((key) => ({
  value: key,
  label: transferStatus[key],
}));

export { transferStatus, transferStatusOptions };
