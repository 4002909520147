import React, { useState } from "react";
import { apiBase } from "../../utils/apiBase";
import { withParams } from "../../utils/withParams";
import { withRouter } from "../../utils/withRouter";
import { formatDate } from "../../utils/dates";
import { formatNumber, alphaSort } from "../../utils/numbers";

import { Form, Table, Button, Modal, message } from "antd";

import { DeleteOutlined, ExclamationCircleFilled } from "@ant-design/icons";

import { EditableCell } from "../utils/TableUtils";

const { confirm } = Modal;

const DealExpenses = (props) => {
  const { dealExpenses, parentLoading } = props;

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const deleteDealAsset = async (id) => {
    setLoading(true);
    apiBase
      .delete(`/deal-expenses/${id}`)
      .then((res) => {
        let msg = `${res.status}: Successfully deleted deal expense.`;
        message.success(msg);
        props.getDealExpenses();
        props.getDeal();
        props.getDealAssets();
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to delete deal expense.`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: "Are you sure you want to delete this deal expense?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      okButtonProps: {
        disabled: loading || parentLoading,
        loading: loading || parentLoading,
      },
      cancelButtonProps: {
        disabled: loading || parentLoading,
        loading: loading || parentLoading,
      },
      onOk: () => deleteDealAsset(id),
    });
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Date",
      dataIndex: "date",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => alphaSort(a.date, b.date),
      render: (value) => formatDate(value, "YYYY-MM-DD"),
    },
    {
      title: "Description",
      dataIndex: "description",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.description - b.description,
      editable: true,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.amount - b.amount,
      render: (value) =>
        value !== null ? `$${formatNumber(value, "0,0.00")}` : null,
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "actions",
      render: (value, record) => {
        return (
          <div className="App__tableOperations">
            <Button
              shape="circle"
              icon={<DeleteOutlined />}
              size="small"
              onClick={() => showDeleteConfirm(record.id)}
              disabled={loading || parentLoading}
            />
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        pagination={false}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        columns={mergedColumns}
        dataSource={dealExpenses.map((dealExpense, idx) => ({
          ...dealExpense,
          key: idx,
        }))}
        showSorterTooltip={false}
        className="App__smallTables"
        loading={loading || parentLoading}
        scroll={{ x: "100%" }}
        bordered
      />
    </Form>
  );
};

export default withRouter(withParams(DealExpenses));
