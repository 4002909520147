import React, { useState, useCallback, useEffect } from "react";
import { Table, message } from "antd";
import { Link } from "react-router-dom";
import { formatDate } from "../../utils/dates";
import { formatNumber, alphaSort } from "../../utils/numbers";
import { apiBase } from "../../utils/apiBase";
import { get } from "lodash";

import RecordLabels from "../common/RecordLabels";
import PermitUnitCreateForm from "../permits/PermitUnitCreateForm";

import styles from "./PermitPlatPermits.module.scss";

const PermitPlatPermits = ({ permitPlatId, fetchData }) => {
  const [loading, setLoading] = useState(false);
  const [permits, setPermits] = useState([]);
  const [selectedPermits, setSelectedPermits] = useState([]);
  const [selectedPermitKeys, setSelectedPermitKeys] = useState([]);

  const getPermitPlatPermits = useCallback(async () => {
    try {
      setLoading(true);
      const res = await apiBase.get(`/permit-plats/${permitPlatId}/permits`);
      setPermits(get(res, "data", []));
    } catch (err) {
      message.error(`Failed to load permit plat permits: ${err.message}`);
    } finally {
      setLoading(false);
    }
  }, [permitPlatId]);

  useEffect(() => {
    getPermitPlatPermits();
  }, [getPermitPlatPermits]);

  const onSelectPermitChange = (selectedRowKeys, selectedRows) => {
    setSelectedPermitKeys(selectedRowKeys);
    setSelectedPermits(selectedRows);
  };

  const rowSelection = {
    selectedRowKeys: selectedPermitKeys,
    onChange: onSelectPermitChange,
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text) => <Link to={`/permits/${text}`}>{text}</Link>,
    },
    {
      title: "Labels",
      dataIndex: "label_ids",
      // className: `App__w20`,
      render: (_, record) => {
        return (
          <RecordLabels
            record_type="permit"
            record_id={record.id}
            label_ids={record.label_ids}
          />
        );
      },
    },
    {
      title: "Universal Doc Number",
      dataIndex: "universal_doc_number",
      key: "universal_doc_number",
      className: `App__w14 App__rowLink`,
      // render: (value) => <div className="App__rowIcon">{value}</div>,
      onCell: (record) => ({
        onClick: (e) => {
          e.stopPropagation();
          window.open(record.lease_url, "_blank");
        },
      }),
    },
    {
      title: "API Number",
      dataIndex: "api_number",
      key: "api_number",
      className: `App__w12`,
    },
    {
      title: "County",
      dataIndex: "county",
      key: "county",
      className: `App__w12`,
    },
    {
      title: "RRC ID",
      dataIndex: "rrc_id",
      key: "rrc_id",
      className: `App__w12`,
    },
    {
      title: "Submitted Date",
      dataIndex: "submitted_date",
      key: "submitted_date",
      render: (text) => formatDate(text, "YYYY-MM-DD"),
    },
    {
      title: "Lease",
      dataIndex: "lease",
      key: "lease",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => alphaSort(a.lease, b.lease),
    },
    {
      title: "Well Number",
      dataIndex: "well_number",
      key: "well_number",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => alphaSort(a.well_number, b.well_number),
    },
    {
      title: "Unit Count",
      dataIndex: "unit_count",
      key: "unit_count",
    },
    {
      title: "Total Unit Allocation",
      dataIndex: "total_unit_allocation",
      key: "total_unit_allocation",
      render: (value) =>
        value !== null ? `${formatNumber(value * 100, "0.00")}%` : null,
    },
  ];

  return (
    <div className={styles.card}>
      <div className={styles.cardTitle}>
        <div className={styles.leftTitle}>
          <h2>Permits</h2>
        </div>
        <div className={styles.rightTitle}>
          {selectedPermits.length > 0 && (
            <PermitUnitCreateForm
              permits={selectedPermits}
              fetchData={() => {
                fetchData();
                getPermitPlatPermits();
              }}
            />
          )}
        </div>
      </div>
      <div className={styles.cardBody}>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={permits}
          rowKey="id"
          showSorterTooltip={false}
          pagination={false}
          loading={loading}
          className="App__smallTables"
          bordered
        />
      </div>
    </div>
  );
};

export default PermitPlatPermits;
