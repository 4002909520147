import React, { useState } from "react";
import { connect } from "react-redux";
import { apiBase } from "../../utils/apiBase";
import { stringifyFilters } from "../../utils/queryParser";
import { leadViews } from "../../common/views";
import { get } from "lodash";
import { stringify } from "query-string";

import { Modal, Form, Button, Select, message } from "antd";

import styles from "./CreateGenericFileLeads.module.scss";

const mapStateToProps = (state) => ({
  user: get(state.user, "data", {}),
  users: get(state.users, "data", {}),
  leadStatus: get(state.appConfig, "data.lead_status", []),
});

const mapDispatchToProps = () => ({});

const CreateGenericFileLeads = (props) => {
  const { users, user, leadStatus, genericFile } = props;

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [form] = Form.useForm();

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const submitForm = () => {
    form
      .validateFields()
      .then(() => {
        form.submit();
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };

  const onFinish = (values) => {
    setLoading(true);
    apiBase
      .post(`/generic-files/${genericFile.id}/leads`, values)
      .then(async (res) => {
        // await new Promise((resolve) => setTimeout(resolve, 2000));
        message.success("Successfully created leads.");

        const filters = stringifyFilters({ status: values.status });
        const queryString = stringify({
          limit: 100,
          page: 1,
          filters,
          view: leadViews.generics,
        });

        window.open(`/leads?${queryString}`, "_blank");
      })
      .catch((err) => {
        let msg = `${get(
          err,
          "response.status"
        )}: Error generating leads for generic file: ${genericFile.id}`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
        form.resetFields();
        setIsModalOpen(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    form.resetFields();
    setIsModalOpen(false);
  };

  return (
    <div className={styles.container}>
      <Button
        type="secondary"
        onClick={() => setIsModalOpen(true)}
        loading={props.parentIsLoading}
        disabled={
          ["queued", "running"].includes(genericFile.upload_status) ||
          props.parentIsLoading
        }
      >
        Create leads
      </Button>
      <Modal
        title="Create generic file leads"
        okText="Create"
        cancelText="Cancel"
        visible={isModalOpen}
        onOk={() => submitForm()}
        onCancel={handleCancel}
        cancelButtonProps={{ disabled: loading }}
        okButtonProps={{ disabled: loading, loading: loading }}
        maskClosable={false}
      >
        <Form
          name="basic"
          form={form}
          initialValues={{
            user_id: user.id,
            status: null,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
          disabled={loading}
        >
          <Form.Item
            label="Status"
            name="status"
            rules={[
              {
                required: true,
                message: "A status is required",
              },
            ]}
          >
            <Select>
              {leadStatus.map((status, idx) => (
                <Select.Option value={status} key={idx}>
                  {status}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Lead owner"
            name="user_id"
            rules={[
              {
                required: true,
                message: "A status is required",
              },
            ]}
          >
            <Select>
              {users.map((user, idx) => (
                <Select.Option value={user.id} key={idx}>
                  {user.alias}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateGenericFileLeads);
