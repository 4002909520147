import React, { useState, useEffect } from "react";
import { apiBase } from "../utils/apiBase";
import { withParams } from "../utils/withParams";
import { get, isEmpty } from "lodash";

import {
  Row,
  Col,
  Button,
  Input,
  InputNumber,
  Select,
  Form,
  message,
} from "antd";

import styles from "./LeadDetail.module.scss";

const LeadUpdateForm = (props) => {
  const onFinish = (values) => {
    props.updateSubmit(values);
  };

  const onFinishFailed = (errorInfo) => {
    message.error("Form fields reqired");
  };

  const lead = props.lead || {};
  const { form, loading, users, leadStatus } = props;

  return (
    <div className={styles.compactForm}>
      <Form
        name="basic"
        layout="horizontal"
        form={form}
        initialValues={{
          offer_amount: lead.offer_amount,
          status: lead.status,
          nra: lead.nra,
          user_id: lead.user_id,
          interest_type: lead.interest_type,
          decimal_interest: lead.decimal_interest,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        disabled={loading}
      >
        <Row gutter={16}>
          <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
            <div className={styles.objectData}>
              <span>Owner Id:</span>
              <span
                className={styles.objectLink}
                onClick={(e) => {
                  props.history(`/owners/${lead.owner_id}`);
                }}
              >
                {lead.owner_id}
              </span>
            </div>
            <div className={styles.objectData}>
              <span>Generic Id:</span>
              {lead.generic_file_record_id ? (
                <span
                  className={styles.objectLink}
                  onClick={(e) => {
                    props.history(
                      `/generic-file-records/${lead.generic_file_record_id}`
                    );
                  }}
                >
                  {lead.generic_file_record_id}
                </span>
              ) : (
                <div>-</div>
              )}
            </div>
            <Form.Item
              label="Offer Amount"
              name="offer_amount"
              rules={[{ required: !!lead.offer_id, message: "" }]}
            >
              <InputNumber
                className="App__fullWidth"
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              />
            </Form.Item>
            <Form.Item
              label="Lead status"
              name="status"
              rules={[{ required: true, message: "" }]}
            >
              <Select>
                {leadStatus.map((status, idx) => (
                  <Select.Option value={status} key={idx}>
                    {status}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Net Royalty Acreage"
              name="nra"
              rules={[{ required: !!lead.offer_id, message: "" }]}
            >
              <InputNumber className="App__fullWidth" />
            </Form.Item>
          </Col>
          <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
            <div className={styles.objectData}>
              <span>Unit Id:</span>
              {lead.unit_id ? (
                <span
                  className={styles.objectLink}
                  onClick={(e) => {
                    props.history(`/units/${lead.unit_id}`);
                  }}
                >
                  {lead.unit_id}
                </span>
              ) : (
                <div>-</div>
              )}
            </div>
            <div className={styles.objectData}>
              <span>Offer Id:</span>
              {lead.offer_id ? (
                <span
                  className={styles.objectLink}
                  onClick={(e) => {
                    props.history(`/units/${lead.offer_id}`);
                  }}
                >
                  {lead.offer_id}
                </span>
              ) : (
                <div>-</div>
              )}
            </div>
            <Form.Item
              label="Lead Owner"
              name="user_id"
              rules={[{ required: true, message: "" }]}
            >
              <Select>
                {users.map((user, idx) => (
                  <Select.Option value={user.id} key={idx}>
                    {user.alias}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Interest Type"
              name="interest_type"
              rules={[{ required: !!lead.offer_id, message: "" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Decimal Interest"
              name="decimal_interest"
              rules={[{ required: !!lead.offer_id, message: "" }]}
            >
              <InputNumber className="App__fullWidth" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const LeadDetail = (props) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [lead, setLead] = useState({});

  useEffect(() => {
    const getLead = async () => {
      try {
        setLoading(true);
        const res = await apiBase.get(`/leads/${props.params.id}`);
        setLead(get(res, "data", {}));
      } catch (err) {
        let msg = `${err.response.status}: Failed to load Lead ${props.params.id}`;
        message.error(msg);
      } finally {
        setLoading(false);
      }
    };
    getLead();
  }, [props.params.id]);

  function updateSubmit(values) {
    setLoading(true);
    apiBase
      .patch(`leads/${lead.id}`, values)
      .then((res) => {
        setLead(res.data);
        let msg = `${res.status}: Successfully updated Lead.`;
        message.success(msg);
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
        form.resetFields();
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div className="App__rowContentDetail">
      <Row gutter={16}>
        <Col span={24}>
          <div className={styles.card}>
            <div className={styles.cardTitle}>
              <div className={styles.leftTitle}>
                <h2>Lead</h2>
              </div>
              <div className={styles.rightTitle}>
                <div>
                  <Button
                    onClick={() => form.resetFields()}
                    className={styles.cardHeaderAction}
                    disabled={loading}
                  >
                    Reset
                  </Button>
                  <Button
                    className={styles.cardHeaderAction}
                    type="primary"
                    loading={loading}
                    htmlType="submit"
                    onClick={() => {
                      form.submit();
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
            <div className={styles.cardBody}>
              <Row gutter={24}>
                <Col span={24}>
                  {!isEmpty(lead) && (
                    <LeadUpdateForm
                      form={form}
                      loading={loading}
                      lead={lead}
                      users={props.users}
                      leadStatus={props.leadStatus}
                      updateSubmit={updateSubmit}
                      history={props.history}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withParams(LeadDetail);
