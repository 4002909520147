const enverusStatusOptions = [
  { value: null, label: "NULL" },
  { value: "PRODUCING", label: "PRODUCING" },
  { value: "PERMITTED", label: "PERMITTED" },
  { value: "PERMIT EXPIRED", label: "PERMIT EXPIRED" },
  { value: "PERMIT CANCELLED", label: "PERMIT CANCELLED" },
  { value: "P & A", label: "P & A" },
  { value: "INJECTING", label: "INJECTING" },
  { value: "INACTIVE PRODUCER", label: "INACTIVE PRODUCER" },
  { value: "DUC", label: "DUC" },
  { value: "DRILLING", label: "DRILLING" },
  { value: "DRILLED", label: "DRILLED" },
  { value: "COMPLETED", label: "COMPLETED" },
];

export { enverusStatusOptions };
