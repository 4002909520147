import React, { Component } from "react";
import { withParams } from "../utils/withParams";
import { Link } from "react-router-dom";
import { apiBase } from "../utils/apiBase";
import { formatNumber } from "../utils/numbers";
import { formatDate } from "../utils/dates";
import { get, sumBy, cloneDeep } from "lodash";

import TotalRecords from "../views/search/TotalRecords";
import { LabelIcon } from "../views/common/LabelIcon";
import {
  mailLetterType,
  mailLetterTypeTitle,
  mailLetterTypeColor,
} from "../common/mailLetterType";

import { DownloadOutlined } from "@ant-design/icons";

import { Row, Col, Table, Select, Input, Button, message } from "antd";

import styles from "./MailLetter.module.scss";

class MailLetter extends Component {
  state = {
    isLoading: false,
    mailLetter: {},
    mailTemplates: [],
    mailLetterCopy: {},
    mailLetterLeads: [],
    isMailLetterEdit: false,
    isLoadingMailLetterEdit: false,
    recordsPerPage: 25,
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    await Promise.all([
      this.getMailLetter(),
      this.getMailLetterLeads(),
      this.getMailTemplates(),
    ]);
    this.setState({ isLoading: false });
  }

  async getMailTemplates() {
    return apiBase
      .get(`/mail-templates`)
      .then((res) => {
        this.setState({
          mailTemplates: get(res, "data.mail_templates", []),
        });
      })
      .catch((err) => {
        let msg = `${get(
          err,
          "response.status"
        )}: Error loading mail templates: ${this.props.params.id}`;
        message.error(msg);
      });
  }

  async getMailLetter() {
    return apiBase
      .get(`/mail-letters/${this.props.params.id}`)
      .then((res) => {
        this.setState({
          mailLetter: res.data,
        });
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: Error loading mail letter: ${
          this.props.params.id
        }`;
        message.error(msg);
      });
  }

  async getMailLetterLeads() {
    return apiBase
      .get(`/mail-letters/${this.props.params.id}/leads`)
      .then((res) => {
        this.setState({
          mailLetterLeads: res.data.map((el, idx) => ({
            ...el,
            ...{ key: idx },
          })),
        });
      })
      .catch((err) => {
        let msg = `${get(
          err,
          "response.status"
        )}: Error loading mail letter leads: ${this.props.params.id} letters`;
        message.error(msg);
      });
  }

  handleRowClick(e, _to) {
    e.stopPropagation();
    if (e.ctrlKey || e.metaKey) {
      window.open(_to, "_blank");
      return;
    }
    this.props.history(_to);
  }

  handleRecordsChange(value) {
    this.setState({
      recordsPerPage: value,
    });
  }

  setMailLetterEdit = () => {
    const { mailLetter } = this.state;
    this.setState({
      isMailLetterEdit: true,
      mailLetterCopy: cloneDeep(mailLetter),
    });
  };

  handleMailLetterEditChange = (e) => {
    const { mailLetter } = this.state;
    mailLetter[e.target.name] = e.target.value;
    this.setState({ mailLetter });
  };

  handleMailLetterEditSubmit = () => {
    const { mailLetter } = this.state;
    let mailLetterEdit = {
      mail_letter_id: parseInt(mailLetter.id, 10),
      name: mailLetter.name,
      name_attn: mailLetter.name_attn,
      street: mailLetter.street,
      city: mailLetter.city,
      state: mailLetter.state,
      zip_code: mailLetter.zip_code,
      fractional_ownership: mailLetter.fractional_ownership,
    };
    this.setState({ isLoadingMailLetterEdit: true });
    apiBase
      .patch(`/mail-letters`, mailLetterEdit)
      .then((res) => {
        this.setState({
          mailLetter: res.data,
          isMailLetterEdit: false,
          isLoadingMailLetterEdit: false,
        });
        message.success("Successfully updated mail letter");
      })
      .catch((err) => {
        this.setState({
          isLoadingMailLetterEdit: false,
        });
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
      });
  };

  cancelMailLetterEdit() {
    const { mailLetterCopy } = this.state;
    this.setState({
      isMailLetterEdit: false,
      mailLetter: mailLetterCopy,
    });
  }

  render() {
    const {
      mailLetter,
      mailLetterLeads,
      mailTemplates,
      isMailLetterEdit,
      isLoadingMailLetterEdit,
    } = this.state;

    const columns = [
      {
        title: "Lead",
        dataIndex: "id",
        className: `App__rowLink App__w6`,
        render: (value) => <div className="App__rowIcon">{value}</div>,
        onCell: (record) => ({
          onClick: (e) => this.handleRowClick(e, `/leads/${record["id"]}`),
        }),
        width: "45px",
      },
      {
        title: "Unit",
        dataIndex: "lead.unit_id",
        className: `App__rowLink App__w8`,
        render: (value, record) => (
          <div className="App__rowIcon">{record["unit_id"]}</div>
        ),
        onCell: (record) => ({
          onClick: (e) => this.handleRowClick(e, `/units/${record["unit_id"]}`),
        }),
        width: "45px",
      },
      {
        title: "Generic",
        dataIndex: "lead.generic_file_record_id",
        className: `App__rowLink App__w8`,
        render: (value, record) => (
          <div className="App__rowIcon">{record["generic_file_record_id"]}</div>
        ),
        onCell: (record) => ({
          onClick: (e) =>
            this.handleRowClick(
              e,
              `/generic-file-records/${record["generic_file_record_id"]}`
            ),
        }),
        width: "45px",
      },
      {
        title: "Unit name",
        dataIndex: ["unit", "name_formatted"],
      },
      {
        title: "Unit county",
        dataIndex: ["unit", "county"],
      },
      {
        title: "Operator",
        dataIndex: ["unit", "operator"],
      },
      {
        title: "DOI",
        dataIndex: "decimal_interest",
        render: (value) =>
          value !== null ? formatNumber(value, "0.000000") : null,
      },
      {
        title: "NRA",
        dataIndex: "nra",
        render: (value) =>
          value !== null ? formatNumber(value, "0.00") : null,
      },
      {
        title: "Offer Amount",
        dataIndex: "offer_amount",
        render: (value) =>
          value !== null ? `$${formatNumber(value, "0,0.00")}` : null,
      },
      {
        title: "Tax year",
        dataIndex: "tax_year",
      },
    ];

    return (
      <div className="App__rowContentDetail">
        <Row>
          <Col span={24}>
            <div className={styles.card}>
              <div className={styles.cardTitle}>
                <div className={styles.leftTitle}>
                  <h2>Mail letter</h2>
                </div>
                <div className={styles.rightTitle}>
                  {!isMailLetterEdit ? (
                    <div>
                      <Button
                        onClick={() => this.setMailLetterEdit()}
                        className={styles.cardHeaderAction}
                      >
                        Edit
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <Button
                        onClick={() => this.cancelMailLetterEdit()}
                        className={styles.cardHeaderAction}
                        disabled={isLoadingMailLetterEdit}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => this.handleMailLetterEditSubmit()}
                        className={styles.cardHeaderAction}
                        type="primary"
                        loading={isLoadingMailLetterEdit}
                      >
                        Save
                      </Button>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.cardBody}>
                <Row gutter={24}>
                  <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className={styles.objectData}>
                      <span>Id:</span>
                      {mailLetter.id}
                    </div>
                    <div className={styles.objectData}>
                      <span>Owner:</span>
                      <div
                        className={styles.objectLink}
                        onClick={(e) => {
                          this.handleRowClick(
                            e,
                            `/owners/${mailLetter.owner_id}`
                          );
                        }}
                      >
                        {mailLetter.owner_id}
                      </div>
                    </div>
                    <div className={styles.objectData}>
                      <span>Mail export:</span>
                      {mailLetter.mail_export_id ? (
                        <div
                          className={styles.objectLink}
                          onClick={(e) => {
                            this.handleRowClick(
                              e,
                              `/mail-exports/${mailLetter.mail_export_id}`
                            );
                          }}
                        >
                          {mailLetter.mail_export_id}
                        </div>
                      ) : (
                        <>-</>
                      )}
                    </div>
                    <div className={styles.objectData}>
                      <span>Mail template Id:</span>
                      <div>
                        {mailLetter.type === mailLetterType.standard ? (
                          <>
                            {mailLetter.mail_template_id ? (
                              <div
                                className={styles.objectLink}
                                onClick={(e) => {
                                  this.handleRowClick(
                                    e,
                                    `/settings/mail-templates/${mailLetter.mail_template_id}`
                                  );
                                }}
                              >
                                {mailLetter.mail_template_id}
                              </div>
                            ) : (
                              <>-</>
                            )}
                          </>
                        ) : (
                          <Select
                            disabled={
                              mailLetter.type === mailLetterType.standard
                            }
                            value={mailLetter.mail_template_id}
                            className="App__w16"
                            name="mail_template_id"
                            size="small"
                            onChange={(value) =>
                              this.handleMailLetterEditChange({
                                target: { name: "mail_template_id", value },
                              })
                            }
                            options={mailTemplates.map((mailTemplate) => ({
                              label: mailTemplate.name,
                              value: mailTemplate.id,
                            }))}
                          />
                        )}
                      </div>
                    </div>
                    <div className={styles.objectData}>
                      <span>Download url:</span>
                      {mailLetter["download_url"] ? (
                        <span
                          className={styles.objectLink}
                          onClick={() => {
                            window.open(mailLetter["download_url"], "_blank");
                          }}
                        >
                          <DownloadOutlined />
                        </span>
                      ) : (
                        <>-</>
                      )}
                    </div>
                    <div className={styles.objectData}>
                      <span>Type:</span>
                      <LabelIcon
                        content={mailLetterTypeTitle[mailLetter.type]}
                        backgroundColor={mailLetterTypeColor[mailLetter.type]}
                        color={
                          mailLetter.type === mailLetterType.standard
                            ? "black"
                            : "white"
                        }
                      />
                    </div>
                    <div
                      className={`${styles.objectData} ${styles.linkNoColor}`}
                    >
                      <span>Created By:</span>
                      {get(mailLetter, "user.id") && (
                        <Link
                          to={`/settings/users/${get(mailLetter, "user.id")}`}
                        >
                          {get(mailLetter, "user.alias")}
                        </Link>
                      )}
                    </div>
                    <div className={styles.objectData}>
                      <span>Created At:</span>
                      {formatDate(
                        mailLetter.created_at,
                        "YYYY-MM-DD, h:mm:ss A"
                      )}
                    </div>
                    <div className={styles.objectData}>
                      <span>Updated At:</span>
                      {formatDate(
                        mailLetter.updated_at,
                        "YYYY-MM-DD, h:mm:ss A"
                      )}
                    </div>
                  </Col>
                  <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className={styles.objectData}>
                      <span>Name:</span>
                      <div
                        className={styles.inputWrapper}
                        onClick={() => this.setMailLetterEdit()}
                      >
                        <Input
                          value={mailLetter.name}
                          onChange={(e) => this.handleMailLetterEditChange(e)}
                          onPressEnter={(e) => {
                            this.handleMailLetterEditSubmit();
                            e.target.blur();
                          }}
                          className={
                            isMailLetterEdit
                              ? styles.inputActive
                              : styles.inputDisabled
                          }
                          disabled={
                            isLoadingMailLetterEdit ||
                            mailLetter.type === mailLetterType.standard
                          }
                          name="name"
                          type="string"
                          size="small"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className={styles.objectData}>
                      <span>Name Attention:</span>
                      <div
                        className={styles.inputWrapper}
                        onClick={() => this.setMailLetterEdit()}
                      >
                        <Input
                          value={mailLetter.name_attn}
                          onChange={(e) => this.handleMailLetterEditChange(e)}
                          onPressEnter={(e) => {
                            this.handleMailLetterEditSubmit();
                            e.target.blur();
                          }}
                          className={
                            isMailLetterEdit
                              ? styles.inputActive
                              : styles.inputDisabled
                          }
                          disabled={
                            isLoadingMailLetterEdit ||
                            mailLetter.type === mailLetterType.standard
                          }
                          name="name_attn"
                          type="string"
                          size="small"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className={styles.objectData}>
                      <span>Street:</span>
                      <div
                        className={styles.inputWrapper}
                        onClick={() => this.setMailLetterEdit()}
                      >
                        <Input
                          value={mailLetter.street}
                          onChange={(e) => this.handleMailLetterEditChange(e)}
                          onPressEnter={(e) => {
                            this.handleMailLetterEditSubmit();
                            e.target.blur();
                          }}
                          className={
                            isMailLetterEdit
                              ? styles.inputActive
                              : styles.inputDisabled
                          }
                          disabled={
                            isLoadingMailLetterEdit ||
                            mailLetter.type === mailLetterType.standard
                          }
                          name="street"
                          type="string"
                          size="small"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className={styles.objectData}>
                      <span>City:</span>
                      <div
                        className={styles.inputWrapper}
                        onClick={() => this.setMailLetterEdit()}
                      >
                        <Input
                          value={mailLetter.city}
                          onChange={(e) => this.handleMailLetterEditChange(e)}
                          onPressEnter={(e) => {
                            this.handleMailLetterEditSubmit();
                            e.target.blur();
                          }}
                          className={
                            isMailLetterEdit
                              ? styles.inputActive
                              : styles.inputDisabled
                          }
                          disabled={
                            isLoadingMailLetterEdit ||
                            mailLetter.type === mailLetterType.standard
                          }
                          name="city"
                          type="string"
                          size="small"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className={styles.objectData}>
                      <span>State:</span>
                      <div
                        className={styles.inputWrapper}
                        onClick={() => this.setMailLetterEdit()}
                      >
                        <Input
                          value={mailLetter.state}
                          onChange={(e) => this.handleMailLetterEditChange(e)}
                          onPressEnter={(e) => {
                            this.handleMailLetterEditSubmit();
                            e.target.blur();
                          }}
                          className={
                            isMailLetterEdit
                              ? styles.inputActive
                              : styles.inputDisabled
                          }
                          disabled={
                            isLoadingMailLetterEdit ||
                            mailLetter.type === mailLetterType.standard
                          }
                          name="state"
                          type="string"
                          size="small"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className={styles.objectData}>
                      <span>Zip Code:</span>
                      {get(
                        mailLetter,
                        "zip_code",
                        get(mailLetter, "owner.zip_code")
                      )}
                    </div>
                    <div className={styles.objectData}>
                      <span>Total NRA:</span>
                      {formatNumber(sumBy(mailLetterLeads, "nra"), "0.00")}
                    </div>
                    <div className={styles.objectData}>
                      <span>Total amount:</span>
                      {`$${formatNumber(
                        sumBy(mailLetterLeads, "offer_amount"),
                        "0,0.00"
                      )}`}
                    </div>
                    <div className={styles.objectData}>
                      <span>Fractional ownership:</span>
                      <div
                        className={styles.inputWrapper}
                        onClick={() => this.setMailLetterEdit()}
                      >
                        <Input
                          value={mailLetter.fractional_ownership}
                          onChange={(e) => this.handleMailLetterEditChange(e)}
                          onPressEnter={(e) => {
                            this.handleMailLetterEditSubmit();
                            e.target.blur();
                          }}
                          className={
                            isMailLetterEdit
                              ? styles.inputActive
                              : styles.inputDisabled
                          }
                          disabled={
                            isLoadingMailLetterEdit ||
                            mailLetter.type === mailLetterType.standard
                          }
                          name="fractional_ownership"
                          type="string"
                          size="small"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className={styles.card}>
              <div className={styles.cardTitle}>
                <div className={styles.leftTitle}>
                  <h2>Leads</h2>
                </div>
                <div className={styles.rightTitle}>
                  <Select
                    defaultValue={25}
                    onChange={this.handleRecordsChange.bind(this)}
                    className={"App__mr-5"}
                  >
                    <Select.Option value={10}>10</Select.Option>
                    <Select.Option value={25}>25</Select.Option>
                    <Select.Option value={50}>50</Select.Option>
                    <Select.Option value={100}>100</Select.Option>
                  </Select>
                  <TotalRecords
                    title={"Leads"}
                    totalRecords={mailLetterLeads.length}
                  />
                </div>
              </div>
              <div className={styles.cardBody}>
                <Table
                  columns={columns}
                  dataSource={mailLetterLeads}
                  pagination={{
                    pageSize: this.state.recordsPerPage,
                    showSizeChanger: false,
                  }}
                  className="App__smallTables"
                  showSorterTooltip={false}
                  loading={this.state.isLoading}
                  scroll={{ x: "100%" }}
                  bordered
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withParams(MailLetter);
