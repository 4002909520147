import React from "react";
import { withParams } from "../../utils/withParams";
import { withRouter } from "../../utils/withRouter";
import { formatNumber, alphaSort } from "../../utils/numbers";
import { formatDate } from "../../utils/dates";

import { Table } from "antd";

const AssetRoyalties = (props) => {
  const { assetRoyalties, parentLoading } = props;

  function handleRowClick(e, _to) {
    e.stopPropagation();
    if (e.ctrlKey || e.metaKey) {
      window.open(_to, "_blank");
      return;
    }
    props.history(_to);
  }

  const columns = [
    {
      title: "Royalty",
      dataIndex: "royalty_id",
      className: `App__rowLink App__w8`,
      render: (value) => <div className="App__rowIcon">{value}</div>,
      onCell: (record) => ({
        onClick: (e) =>
          handleRowClick(e, `/portfolio/royalties/${record["royalty_id"]}`),
      }),
    },
    {
      title: "Date",
      dataIndex: "date",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => alphaSort(a.date, b.date),
      render: (value) => formatDate(value, "YYYY-MM-DD"),
    },
    {
      title: "Operator",
      dataIndex: "operator_name",
      className: `App__rowLinkNamed`,
      render: (value) => <div className="App__rowIcon">{value}</div>,
      onCell: (record) => ({
        onClick: (e) =>
          handleRowClick(e, `/portfolio/operators/${record["operator_id"]}`),
      }),
    },
    {
      title: "Well",
      dataIndex: "well_name",
      className: `App__rowLinkNamed`,
      render: (value) => <div className="App__rowIcon">{value}</div>,
      onCell: (record) => ({
        onClick: (e) =>
          handleRowClick(e, `/portfolio/wells/${record["well_id"]}`),
      }),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.amount - b.amount,
      render: (value) =>
        value !== null ? `$${formatNumber(value, "0,0.00")}` : null,
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    };
  });

  return (
    <Table
      pagination={{ size: "small" }}
      columns={mergedColumns}
      dataSource={assetRoyalties.map((royaltyLine, idx) => ({
        ...royaltyLine,
        key: idx,
      }))}
      showSorterTooltip={false}
      className="App__smallTables"
      loading={parentLoading}
      scroll={{ x: "100%" }}
      bordered
    />
  );
};

export default withRouter(withParams(AssetRoyalties));
