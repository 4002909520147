import React, { useState } from "react";
import { guid } from "../../utils/numbers";
import { columnTypes } from "../../utils/search";

import BaseSearch from "../search/BaseSearch";
import Took from "../search/Took";
import TotalRecords from "../search/TotalRecords";

import { formatDate } from "../../utils/dates";
import {
  DownloadOutlined,
  EditOutlined,
  TableOutlined,
} from "@ant-design/icons";

import WellCreateForm from "./WellCreateForm";
import WellEditForm from "./WellEditForm";
import ExportForm from "../forms/ExportForm";
import ChangeColumnsForm from "../forms/ChangeColumnsForm";
import FilterTags from "../search/FilterTags";

import { enverusStatusOptions } from "../../common/wells";

import { Row, Col, Table, Select, Button, Tooltip, Pagination } from "antd";

// import styles from "./Wells.module.scss";

const baseColumnState = [
  {
    dataIndex: "id",
    title: "Well",
    editable: false,
    type: columnTypes.LINK,
    baseUrl: "/portfolio/wells",
    sortable: true,
  },
  {
    dataIndex: "name",
    title: "Name",
    type: columnTypes.STRING,
  },
  {
    dataIndex: "enverus_name",
    title: "Enverus name",
    type: columnTypes.STRING,
    visible: false,
  },
  {
    dataIndex: "api_number",
    title: "API number",
    type: columnTypes.STRING,
  },
  {
    dataIndex: ["operator", "name"],
    title: "Operator",
    type: columnTypes.STRING,
  },
  {
    dataIndex: "type",
    title: "Type",
    type: columnTypes.STRING,
  },
  {
    dataIndex: "county",
    title: "County",
    type: columnTypes.STRING,
  },
  {
    dataIndex: "state",
    title: "State",
    type: columnTypes.STRING,
  },
  {
    dataIndex: "status",
    title: "Status",
    type: columnTypes.STRING,
    // SELECT
  },
  {
    dataIndex: "enverus_status",
    title: "Enverus status",
    type: columnTypes.SELECT_MULTI,
    options: enverusStatusOptions,
  },
  {
    title: "Created at",
    dataIndex: "created_at",
    type: columnTypes.DATE_TIME,
    format: (value) => formatDate(value, "YYYY-MM-DD"),
    className: `App__w14`,
  },
];

const Wells = (props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);

  const {
    isLoadingRecords,
    isLoadingCount,
    records,
    columnState,
    limit,
    page,
    totalRecords,
    tookRecords,
    tookCount,
    searchFields,
    isExportModalVisible,
    isChangeColumnsVisible,
    isLoadingExport,
  } = props;

  const {
    handleSearch,
    handleRecordsChange,
    handlePaginationChange,
    handleFilterClose,
    handleTableChange,
    handleSubmitExport,
    showExportModal,
    handleCancelExport,
    handleSubmitColumnChange,
    showColumnChangeModal,
    handleCancelColumnsChange,
    exportFormRef,
    changeColumnFormRef,
    getColumnProps,
    executeSearch,
  } = props;

  let columns = columnState
    .map((column, idx) => ({
      key: idx,
      ...column,
      ...getColumnProps(column),
    }))
    .filter((col) => col.visible === true);

  columns.push({
    title: "",
    dataIndex: "actions",
    visible: true,
    editable: false,
    children: [
      {
        dataIndex: "actions",
        render: (_, record) => {
          return (
            <div className="App__tableOperations">
              <Button
                shape="circle"
                icon={<EditOutlined />}
                size="small"
                className="App__tableEditButton"
                onClick={() => {
                  setEditingRecord({
                    ...record,
                    key: guid(),
                  });
                  setIsDrawerOpen(true);
                }}
              />
            </div>
          );
        },
      },
    ],
  });

  return (
    <div>
      <WellEditForm
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        well={editingRecord}
        setEditingRecord={setEditingRecord}
        executeSearch={executeSearch}
      />
      <Row className="App__rowContent">
        <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16}>
          <Button className="App__mr-5" onClick={() => handleSearch()}>
            Search
          </Button>
          <FilterTags
            searchFields={searchFields}
            columnState={columnState}
            handleFilterClose={handleFilterClose}
          />
        </Col>
        <Col
          span={8}
          xs={24}
          sm={24}
          md={8}
          lg={8}
          xl={8}
          className="App__rightActions"
        >
          <div className="App__mr-5">
            <WellCreateForm executeSearch={executeSearch} />
          </div>
          <Tooltip placement="bottomRight" title={"Export search results"}>
            <Button className="App__iconButton" onClick={showExportModal}>
              <DownloadOutlined />
            </Button>
          </Tooltip>
          <ExportForm
            wrappedComponentRef={exportFormRef}
            visible={isExportModalVisible}
            isLoading={isLoadingExport}
            onCancel={handleCancelExport}
            onCreate={handleSubmitExport}
            totalRecords={totalRecords}
          />
          <Tooltip placement="bottomRight" title={"Change visible columns"}>
            <Button onClick={showColumnChangeModal} className="App__iconButton">
              <TableOutlined />
            </Button>
          </Tooltip>
          <ChangeColumnsForm
            wrappedComponentRef={changeColumnFormRef}
            visible={isChangeColumnsVisible}
            isLoading={isLoadingRecords}
            onCancel={handleCancelColumnsChange}
            onOk={handleSubmitColumnChange}
            columnState={columnState}
          />
          <Select
            value={limit}
            onChange={handleRecordsChange}
            className={"App__mr-5"}
          >
            <Select.Option value={25}>25</Select.Option>
            <Select.Option value={50}>50</Select.Option>
            <Select.Option value={100}>100</Select.Option>
          </Select>
          <TotalRecords
            title={"Wells"}
            isLoadingCount={isLoadingCount}
            totalRecords={totalRecords}
          />
        </Col>
      </Row>
      <Row className="App__rowContent">
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={records}
            onChange={handleTableChange}
            showSorterTooltip={false}
            pagination={false}
            className="App__smallTables"
            loading={isLoadingRecords}
            scroll={{ x: "100%" }}
            size="small"
            bordered
          />
          <div className="App__searchFooterContainer">
            <Took
              isLoadingRecords={isLoadingRecords}
              isLoadingCount={isLoadingCount}
              tookRecords={tookRecords}
              tookCount={tookCount}
            />
            <Pagination
              current={page}
              pageSize={limit}
              total={totalRecords}
              onChange={handlePaginationChange}
              className="App__pagination"
              showSizeChanger={false}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

const WellsWithSearch = (props) => (
  <BaseSearch
    baseColumnState={baseColumnState}
    localSortColumns={[]}
    searchUri="wells"
    baseUri="/portfolio"
  >
    <Wells {...props} />
  </BaseSearch>
);

export default WellsWithSearch;
