import React, { useState } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import { apiBase } from "../../utils/apiBase";

import QBOSearchSelect from "../common/QBOSearchSelect";

import { Modal, Form, Button, message } from "antd";

// import styles from "./EntityQuickbooksAccountsForm.module.scss";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const EntityQuickbooksAccountsForm = (props) => {
  const { entity } = props;

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    apiBase
      .patch(`entities/${entity.id}/quickbooks-accounts`, {
        ...values,
      })
      .then(async (res) => {
        message.success("Successfully configued quickbooks accounts.");
      })
      .catch((err) => {
        console.log(err);
        let msg = `${err.response.status}: Failed to configue quickbooks accounts.`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
        resetComponent();
        setIsModalOpen(false);
        props.getEntity();
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    resetComponent();
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    resetComponent();
  };

  const submitForm = () => {
    form
      .validateFields()
      .then(() => {
        form.submit();
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };

  const resetComponent = () => {
    form.resetFields();
  };

  const [form] = Form.useForm();

  return (
    <>
      <Button type="secondary" onClick={showModal}>
        Configure accounts
      </Button>
      <Modal
        title="Configure quickbooks accounts"
        visible={isModalOpen}
        onOk={() => submitForm()}
        onCancel={handleCancel}
        cancelButtonProps={{ disabled: loading }}
        okButtonProps={{ disabled: loading, loading: loading }}
        maskClosable={false}
      >
        <Form
          name="entityQuickbooksAccountsForm"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
          disabled={loading}
          initialValues={{
            quickbooks_cost_basis_account: get(
              entity,
              "quickbooks_cost_basis_account"
            ),
            quickbooks_distributions_account: get(
              entity,
              "quickbooks_distributions_account"
            ),
            quickbooks_distributions_account_alternate: get(
              entity,
              "quickbooks_distributions_account_alternate"
            ),
            quickbooks_oil_royalty_revenue_account: get(
              entity,
              "quickbooks_oil_royalty_revenue_account"
            ),
            quickbooks_gas_royalty_revenue_account: get(
              entity,
              "quickbooks_gas_royalty_revenue_account"
            ),
            quickbooks_statutory_interest_account: get(
              entity,
              "quickbooks_statutory_interest_account"
            ),
          }}
        >
          <Form.Item
            label="Cost basis account"
            name="quickbooks_cost_basis_account"
          >
            <QBOSearchSelect
              entityId={entity.id}
              searchObject="Account"
              searchField="Name"
              placeholder="Search accounts"
              addNew={false}
            />
          </Form.Item>
          <Form.Item
            label="Distributions account"
            name="quickbooks_distributions_account"
          >
            <QBOSearchSelect
              entityId={entity.id}
              searchObject="Account"
              searchField="Name"
              placeholder="Search accounts"
              addNew={false}
            />
          </Form.Item>
          <Form.Item
            label="Distributions account (alternate)"
            name="quickbooks_distributions_account_alternate"
          >
            <QBOSearchSelect
              entityId={entity.id}
              searchObject="Account"
              searchField="Name"
              placeholder="Search accounts"
              addNew={false}
            />
          </Form.Item>
          <Form.Item
            label="Oil royalty revenue account"
            name="quickbooks_oil_royalty_revenue_account"
          >
            <QBOSearchSelect
              entityId={entity.id}
              searchObject="Account"
              searchField="Name"
              placeholder="Search accounts"
              addNew={false}
            />
          </Form.Item>
          <Form.Item
            label="Gas royalty revenue account"
            name="quickbooks_gas_royalty_revenue_account"
          >
            <QBOSearchSelect
              entityId={entity.id}
              searchObject="Account"
              searchField="Name"
              placeholder="Search accounts"
              addNew={false}
            />
          </Form.Item>
          <Form.Item
            label="Statutory interest account"
            name="quickbooks_statutory_interest_account"
          >
            <QBOSearchSelect
              entityId={entity.id}
              searchObject="Account"
              searchField="Name"
              placeholder="Search accounts"
              addNew={false}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EntityQuickbooksAccountsForm);
