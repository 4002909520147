import React, { Component } from "react";
import { withParams } from "../../utils/withParams";
import { apiBase } from "../../utils/apiBase";
import { CSVLink } from "react-csv";
import { formatCSVData, median } from "../../utils/numbers";
import { years } from "../../common/taxYear";
import { get, uniqBy, orderBy } from "lodash";
import { parse } from "query-string";
import { Helmet } from "react-helmet";

import TotalRecords from "../search/TotalRecords";
import UnitOffers from "./UnitOffers";
import UnitPermits from "./UnitPermits";

import UnitEdit from "./UnitEdit";
import UnitOwners from "./UnitOwners";
import UnitLeads from "./UnitLeads";
import UnitSimilarGroupings from "./UnitSimilarGroupings";
import UnitOfferCreateForm from "./UnitOfferCreateForm";

import UnitEnverusWells from "./UnitEnverusWells";
import UnitEnverusPermits from "./UnitEnverusPermits";
import UnitEnverusLandtracUnits from "./UnitEnverusLandtracUnits";

import RecordLabelsEdit from "../common/RecordLabelsEdit";
import MappedIcon from "../common/MappedIcon";

import UnitMap from "./UnitMap";
// import Notes from "../common/Notes";

import { Row, Col, Select, Button, Badge, message, Checkbox } from "antd";

import styles from "./UnitDetail.module.scss";

const csvHeadersInterests = [
  { label: "id", key: "id" },
  { label: "owner_id", key: "owner_id" },
  { label: "name", key: "name" },
  { label: "name_formatted", key: "name_formatted" },
  { label: "address_formatted", key: "address_formatted" },
  { label: "city", key: "city" },
  { label: "state", key: "state" },
  { label: "zip", key: "zip_code" },
  { label: "unit_count", key: "unit_count" },
  { label: "interest_type", key: "interest_type" },
  { label: "decimal_interest", key: "decimal_interest" },
  { label: "nra", key: "nra" },
  { label: "tax_year", key: "tax_year" },
];

const csvHeadersLeads = [
  { label: "id", key: "id" },
  { label: "owner_id", key: "owner_id" },
  { label: "owner.name_formatted", key: "owner.name_formatted" },
  { label: "owner.address_formatted", key: "owner.address_formatted" },
  { label: "owner.city", key: "owner.city" },
  { label: "owner.state", key: "owner.state" },
  { label: "owner.zip_code", key: "owner.zip_code" },
  { label: "interest_type", key: "interest_type" },
  { label: "decimal_interest", key: "decimal_interest" },
  { label: "nra", key: "nra" },
  { label: "offer_amount", key: "offer_amount" },
  { label: "status", key: "status" },
  { label: "user.alias", key: "user.alias" },
  { label: "tax_year", key: "tax_year" },
  { label: "updated_at", key: "updated_at" },
  { label: "created_at", key: "created_at" },
];

class UnitDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unit: {},
      ownerUnitInterests: [],
      enverusWells: [],
      enverusPermits: [],
      enverusLandtracUnits: [],
      unitOffers: [],
      unitPermits: [],
      ownerUnitInterestsCopy: null,
      interestTaxYear: null,
      interestTaxYears: years,
      yearCumulativeDOI: null,
      yearOwnerCount: null,
      yearMedianOwnerCount: null,
      leads: [],
      unitCopy: {},
      selectedInterests: [],
      selectedInterestKeys: [],
      isUnitEdit: false,
      tabKey: 1,
      // tabKeySecondary: 1,
      recordsPerPage: 1000,
      isLoadingLeads: true,
      isLoadingInterests: true,
      isLoadingEnverus: true,
      isLoadingUnitEdit: false,
    };
  }

  componentDidMount() {
    this.reloadUnit();
    this.setStateFromLocation();
  }

  componentDidUpdate(prevProps) {
    if (
      this.state.ownerUnitInterests.length > 0 &&
      this.props.interestFilters.owner_name !== undefined
    ) {
      if (this.state.ownerUnitInterests[0].interest_filter === undefined) {
        let ownerNameRegex = this.props.interestFilters.owner_name || null;
        let interestTypeRegex =
          this.props.interestFilters.interest_type || null;
        let ownerUnitInterests = this.state.ownerUnitInterests;
        ownerUnitInterests = ownerUnitInterests.map((row, idx) => {
          row["interest_filter"] =
            RegExp(ownerNameRegex, "i").test(row.name) ||
            RegExp(interestTypeRegex, "i").test(row.interest_type);
          return row;
        });
        this.setState({ ownerUnitInterests });
      }
    }

    if (
      this.props.location.search &&
      this.props.location.search !== prevProps.location.search
    ) {
      this.setStateFromLocation();
    }
  }

  setStateFromLocation() {
    const query = parse(this.props.location.search);
    this.setState({
      interestTaxYear: get(query, "tax_year", null),
    });
  }

  getUnit() {
    apiBase
      .get(`/units/${this.props.params.id}`)
      .then((res) => {
        this.setState({
          unit: res.data,
        });
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
      });
  }

  getUnitOffers() {
    apiBase
      .get(`/units/${this.props.params.id}/offers`)
      .then((res) => {
        this.setState({
          unitOffers: res.data,
        });
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
      });
  }

  getUnitOwners() {
    this.setState({ isLoadingInterests: true });
    apiBase
      .get(`/units/${this.props.params.id}/owners`)
      .then((res) => {
        const maxTaxYear = Math.max(
          ...new Set(res.data.map((row) => row.tax_year))
        );

        const query = parse(this.props.location.search);
        const interestTaxYear = get(query, "tax_year", maxTaxYear);

        let ownerUnitInterests = res.data.filter(
          (row) => parseInt(row.tax_year, 0) === parseInt(interestTaxYear, 0)
        );
        let ownerUnitInterestsCopy = res.data;
        ownerUnitInterests = ownerUnitInterests.map((el, idx) => ({
          ...el,
          ...{ key: idx },
        }));
        ownerUnitInterestsCopy = ownerUnitInterestsCopy.map((el, idx) => ({
          ...el,
          ...{ key: idx },
        }));

        this.setState({
          ownerUnitInterests: orderBy(
            ownerUnitInterests,
            ["decimal_interest", "name"],
            ["desc", "asc"]
          ),
          totalRecords: ownerUnitInterests.length,
          ownerUnitInterestsCopy: orderBy(
            ownerUnitInterestsCopy,
            ["decimal_interest", "name"],
            ["desc", "asc"]
          ),
          interestTaxYear: interestTaxYear,
          interestTaxYears: [...new Set(res.data.map((row) => row.tax_year))]
            .sort()
            .reverse(),
          isLoadingInterests: false,
        });
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: Error loading Unit: ${
          this.props.params.id
        } Owners`;
        message.error(msg);
        this.setState({ isLoadingInterests: false });
      });
  }

  getUnitLeads() {
    this.setState({ isLoadingLeads: true });
    apiBase
      .get(`/units/${this.props.params.id}/leads`)
      .then((res) => {
        let leads = res.data.map((el, idx) => ({ ...el, ...{ key: idx } }));
        // let tabKey = leads.length > 0 ? 2 : 1;
        this.setState({
          leads: leads,
          // tabKey: tabKey,
          isLoadingLeads: false,
        });
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: Error loading Unit: ${
          this.props.params.id
        } Leads`;
        message.error(msg);
        this.setState({ isLoadingLeads: false });
      });
  }

  getEnverusData() {
    this.setState({ isLoadingEnverus: true });
    apiBase
      .get(`/units/${this.props.params.id}/enverus`)
      .then((res) => {
        this.setState({
          enverusWells: res.data.enverus_wells,
          enverusPermits: res.data.enverus_permits,
          enverusLandtracUnits: res.data.enverus_landtrac_units,
          isLoadingEnverus: false,
        });
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
        this.setState({ isLoadingEnverus: false });
      });
  }

  getUnitPermits() {
    apiBase
      .get(`/units/${this.props.params.id}/permits`)
      .then((res) => {
        this.setState({
          unitPermits: res.data,
        });
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
      });
  }

  reloadUnit() {
    this.getUnit();
    this.getUnitOwners();
    this.getUnitLeads();
    this.getUnitOffers();
    this.getEnverusData();
    this.getUnitPermits();
  }

  handleUnitEditSubmit = () => {
    let unitEdit = {
      unit_id: parseInt(this.props.params.id),
      name_formatted: this.state.unit.name_formatted,
      operator: this.state.unit.operator,
      acres: parseFloat(this.state.unit.acres),
      review_status: this.state.unit.review_status,
    };
    this.setState({ isLoadingUnitEdit: true });
    apiBase
      .patch(`/units`, unitEdit)
      .then((res) => {
        if (this.state.unit.acres !== this.state.unitCopy.acres) {
          this.getUnitOwners();
        }
        this.setState({
          unit: res.data,
          isUnitEdit: false,
          isLoadingUnitEdit: false,
        });
        let msg = `${get(res, "status")}: Successfully updated unit.`;
        message.success(msg);
      })
      .catch((err) => {
        this.setState({
          isLoadingUnitEdit: false,
        });
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
      });
  };

  deleteLead = (leadId) => {
    this.setState({ isLoadingLeads: true });
    apiBase
      .delete(`/leads/${leadId}`)
      .then((res) => {
        let leads = this.state.leads;
        leads = leads.filter((lead) => lead.id !== leadId);
        this.setState({
          leads: leads.map((el, idx) => ({ ...el, ...{ key: idx } })),
          totalRecords: leads.length,
          isLoadingLeads: false,
        });
        let msg = `${res.status}: ${res.data.message}`;
        message.success(msg);
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: Failed to delete Lead.`;
        message.error(msg);
        this.setState({ isLoadingLeads: false });
      });
  };

  handleLeadEditSubmit = (leadId, newLead) => {
    this.setState({ isLoadingLeads: true });
    apiBase
      .patch(`leads/${leadId}`, newLead)
      .then((res) => {
        this.setState({ isLoadingLeads: false });
        let msg = `${res.status}: Successfully updated Lead.`;
        message.success(msg);
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
        this.setState({ isLoadingLeads: false });
      });
  };

  handleYearChange = (value) => {
    let newOwnerUnitInterests = this.state.ownerUnitInterestsCopy.filter(
      (row) => row.tax_year === parseInt(value, 0)
    );

    this.setState({
      interestTaxYear: value,
      ownerUnitInterests: newOwnerUnitInterests,
      yearCumulativeDOI: newOwnerUnitInterests
        .map((row) => get(row, "decimal_interest", 0))
        .reduce((acc, val) => (acc += val), 0),
      yearOwnerCount: uniqBy(newOwnerUnitInterests, "owner_id").length,
      yearMedianOwnerCount: median(
        newOwnerUnitInterests.map((oui) => oui.unit_count)
      ),
    });

    this.props.history(`${this.props.location.pathname}?tax_year=${value}`);
  };

  interestFilterStyles(record) {
    if (record.interest_filter === true) {
      return styles.badInterest;
    }
  }

  handleUnitEditChange = (e) => {
    let unit = { ...this.state.unit };

    if (e.target.name === "review_status") {
      unit[e.target.name] = e.target.value;
    } else {
      unit[e.target.name] = e.target.value;
    }
    this.setState({ unit });
  };

  setUnitEdit() {
    if (this.state.isUnitEdit === false) {
      let unitCopy;
      if (Object.keys(this.state.unit).length === 0) {
        unitCopy = { ...this.state.unit };
      } else {
        unitCopy = { ...this.state.unit };
      }
      this.setState({
        isUnitEdit: true,
        unitCopy: unitCopy,
        unit: unitCopy,
      });
    }
  }

  cancelUnitEdit() {
    let unitCopy = { ...this.state.unitCopy };
    this.setState({
      isUnitEdit: false,
      unit: unitCopy,
    });
  }

  changeTab(tabKey) {
    this.setState({ tabKey: tabKey });
  }

  // setTabKeySecondary = (tabKey) => {
  //   this.setState({ tabKeySecondary: tabKey });
  // };

  handleRecordsChange(value) {
    this.setState({
      recordsPerPage: value,
    });
  }

  onSelectInterestChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedInterestKeys: selectedRowKeys,
      selectedInterests: selectedRows,
    });
  };

  resetInterestSelected = () => {
    this.setState({
      selectedInterestKeys: [],
      selectedInterests: [],
    });
  };

  onToggleExtras = (e) => {
    this.setState({
      columnExtras: e.target.checked,
    });
  };

  render() {
    const {
      unit,
      unitPermits,
      interestTaxYears,
      yearCumulativeDOI,
      yearOwnerCount,
      yearMedianOwnerCount,
      columnExtras,
      // tabKeySecondary,
      enverusWells,
      enverusPermits,
      enverusLandtracUnits,
    } = this.state;

    return (
      <div className="App__rowContentDetail">
        <Helmet>
          <title>{`Unit: ${unit.name_formatted}`}</title>
        </Helmet>
        <Row>
          <Col span={12} xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
            <div className={styles.card}>
              <div className={styles.cardTitle}>
                <div className={styles.leftTitle}>
                  <h2>Unit</h2>
                  {unit.geom_polygon && <MappedIcon />}
                  <RecordLabelsEdit
                    record_type="unit"
                    record_id={unit.id}
                    label_ids={unit.label_ids}
                  />
                </div>
                <div className={styles.rightTitle}>
                  <UnitSimilarGroupings unit={unit} />
                  {!this.state.isUnitEdit ? (
                    <div>
                      <Button
                        onClick={this.setUnitEdit.bind(this)}
                        className={styles.cardHeaderAction}
                      >
                        Edit
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <Button
                        onClick={this.cancelUnitEdit.bind(this)}
                        className={styles.cardHeaderAction}
                        disabled={this.state.isUnitEditLoading}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={this.handleUnitEditSubmit.bind(this)}
                        className={styles.cardHeaderAction}
                        type="primary"
                        loading={this.state.isLoadingUnitEdit}
                      >
                        Save
                      </Button>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.cardBody}>
                <UnitEdit
                  unit={unit}
                  handleUnitEditChange={this.handleUnitEditChange.bind(this)}
                  handleUnitEditSubmit={this.handleUnitEditSubmit.bind(this)}
                  setUnitEdit={this.setUnitEdit.bind(this)}
                  isUnitEdit={this.state.isUnitEdit}
                  yearCumulativeDOI={yearCumulativeDOI}
                  yearOwnerCount={yearOwnerCount}
                  yearMedianOwnerCount={yearMedianOwnerCount}
                />
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.cardTitle}>
                <div className={styles.leftTitle}>
                  <h2>Offers</h2>
                </div>
                <div className={styles.rightTitle}>
                  <div>
                    <UnitOfferCreateForm
                      unit={unit}
                      reloadUnit={this.reloadUnit.bind(this)}
                      selectedInterests={this.state.selectedInterests}
                      resetInterestSelected={this.resetInterestSelected.bind(
                        this
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.cardBody}>
                <UnitOffers
                  unitOffers={this.state.unitOffers}
                  reloadUnit={this.reloadUnit.bind(this)}
                  {...this.props}
                />
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.cardTitle}>
                <div className={styles.leftTitle}>
                  <h2>Permits</h2>
                </div>
                <div className={styles.rightTitle}>
                  <div></div>
                </div>
              </div>
              <div className={styles.cardBody}>
                <UnitPermits
                  unitPermits={unitPermits}
                  getUnitPermits={this.getUnitPermits.bind(this)}
                  {...this.props}
                />
              </div>
            </div>

            {/* <div className={styles.card}>
              <div className={styles.cardTitle}>
                <div className={styles.leftTitle}>
                  <h2>Notes</h2>
                </div>
                <div className={styles.rightTitle}></div>
              </div>
              <div className={styles.cardBody}>
                <div className={styles.notesContainer}>
                  <Notes recordId={unit.id} recordType={"unit"} />
                </div>
              </div>
            </div> */}
          </Col>
          <Col span={12} xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
            <UnitMap
              unit={unit}
              getUnit={this.getUnit.bind(this)}
              enverusLandtracUnits={enverusLandtracUnits}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className={styles.cardTabs}>
              <div className={styles.cardTitle}>
                <div className={styles.leftTitle}>
                  <div
                    className={this.state.tabKey === 1 ? styles.isActive : null}
                    onClick={() => this.changeTab(1)}
                  >
                    <Badge
                      count={this.state.ownerUnitInterests.length}
                      offset={[20, -2]}
                      overflowCount={99}
                      style={{
                        backgroundColor: "#fff",
                        color: "#999",
                        boxShadow: "0 0 0 1px #d9d9d9 inset",
                      }}
                    >
                      <h2>Owners</h2>
                    </Badge>
                  </div>
                  <div
                    className={this.state.tabKey === 2 ? styles.isActive : null}
                    onClick={() => this.changeTab(2)}
                  >
                    <Badge
                      count={this.state.leads.length}
                      offset={[20, -2]}
                      overflowCount={99}
                      style={{
                        backgroundColor: "#fff",
                        color: "#999",
                        boxShadow: "0 0 0 1px #d9d9d9 inset",
                      }}
                    >
                      <h2>Leads</h2>
                    </Badge>
                  </div>
                  <div
                    className={this.state.tabKey === 3 ? styles.isActive : null}
                    onClick={() => this.changeTab(3)}
                  >
                    <Badge
                      count={this.state.enverusWells.length}
                      offset={[20, -2]}
                      overflowCount={99}
                      style={{
                        backgroundColor: "#fff",
                        color: "#999",
                        boxShadow: "0 0 0 1px #d9d9d9 inset",
                      }}
                    >
                      <h2>Env Wells</h2>
                    </Badge>
                  </div>
                  <div
                    className={this.state.tabKey === 4 ? styles.isActive : null}
                    onClick={() => this.changeTab(4)}
                  >
                    <Badge
                      count={this.state.enverusPermits.length}
                      offset={[20, -2]}
                      overflowCount={99}
                      style={{
                        backgroundColor: "#fff",
                        color: "#999",
                        boxShadow: "0 0 0 1px #d9d9d9 inset",
                      }}
                    >
                      <h2>Env Permits</h2>
                    </Badge>
                  </div>
                  <div
                    className={this.state.tabKey === 5 ? styles.isActive : null}
                    onClick={() => this.changeTab(5)}
                  >
                    <Badge
                      count={this.state.enverusLandtracUnits.length}
                      offset={[20, -2]}
                      overflowCount={99}
                      style={{
                        backgroundColor: "#fff",
                        color: "#999",
                        boxShadow: "0 0 0 1px #d9d9d9 inset",
                      }}
                    >
                      <h2>Env Landtrac Units</h2>
                    </Badge>
                  </div>
                </div>
                <div className={styles.rightTitle}>
                  {this.state.tabKey === 1 ? (
                    <>
                      <Checkbox
                        onChange={this.onToggleExtras}
                        className={styles.columnExtras}
                      >
                        Column extras
                      </Checkbox>
                      <CSVLink
                        headers={csvHeadersInterests}
                        data={formatCSVData(this.state.ownerUnitInterests)}
                        filename={`unit-owners-${new Date().toJSON()}.csv`}
                        className={styles.csvDownload}
                      >
                        <i className="fas fa-download" />
                      </CSVLink>
                      <Select
                        defaultValue={this.state.recordsPerPage}
                        onChange={this.handleRecordsChange.bind(this)}
                        className={"App__mr-5"}
                      >
                        <Select.Option value={10}>10</Select.Option>
                        <Select.Option value={25}>25</Select.Option>
                        <Select.Option value={50}>50</Select.Option>
                        <Select.Option value={100}>100</Select.Option>
                        <Select.Option value={1000}>1000</Select.Option>
                      </Select>
                      <Select
                        value={this.state.interestTaxYear}
                        onChange={this.handleYearChange}
                        className={styles.taxYearDropDown}
                      >
                        {interestTaxYears.map((year) => (
                          <Select.Option value={year} key={year}>
                            {year}
                          </Select.Option>
                        ))}
                      </Select>
                      <TotalRecords
                        title={"Interests"}
                        totalRecords={this.state.ownerUnitInterests.length}
                      />
                    </>
                  ) : (
                    <>
                      <CSVLink
                        headers={csvHeadersLeads}
                        data={formatCSVData(this.state.leads)}
                        filename={`unit-leads-${new Date().toJSON()}.csv`}
                        className={styles.csvDownload}
                      >
                        <i className="fas fa-download" />
                      </CSVLink>
                      <Select
                        defaultValue={25}
                        onChange={this.handleRecordsChange.bind(this)}
                        className={"App__mr-5"}
                      >
                        <Select.Option value={10}>10</Select.Option>
                        <Select.Option value={25}>25</Select.Option>
                        <Select.Option value={50}>50</Select.Option>
                        <Select.Option value={100}>100</Select.Option>
                        <Select.Option value={1000}>1000</Select.Option>
                      </Select>
                      <TotalRecords
                        title={"Leads"}
                        totalRecords={this.state.leads.length}
                      />
                    </>
                  )}
                </div>
              </div>
              <div className={styles.cardBody}>
                {this.state.tabKey === 1 ? (
                  <UnitOwners
                    ownerUnitInterests={this.state.ownerUnitInterests}
                    onSelectInterestChange={this.onSelectInterestChange}
                    selectedInterestKeys={this.state.selectedInterestKeys}
                    isLoadingInterests={this.state.isLoadingInterests}
                    recordsPerPage={this.state.recordsPerPage}
                    columnExtras={columnExtras}
                    {...this.props}
                  />
                ) : this.state.tabKey === 2 ? (
                  <UnitLeads
                    leads={this.state.leads}
                    isLoadingLeads={this.state.isLoadingLeads}
                    recordsPerPage={this.state.recordsPerPage}
                    deleteLead={this.deleteLead}
                    handleLeadEditSubmit={this.handleLeadEditSubmit}
                    {...this.props}
                  />
                ) : this.state.tabKey === 3 ? (
                  <UnitEnverusWells
                    enverusWells={enverusWells}
                    isLoading={this.state.isLoadingEnverus}
                    recordsPerPage={this.state.recordsPerPage}
                    columnExtras={columnExtras}
                    {...this.props}
                  />
                ) : this.state.tabKey === 4 ? (
                  <UnitEnverusPermits
                    enverusPermits={enverusPermits}
                    isLoading={this.state.isLoadingEnverus}
                    recordsPerPage={this.state.recordsPerPage}
                    columnExtras={columnExtras}
                    {...this.props}
                  />
                ) : this.state.tabKey === 5 ? (
                  <UnitEnverusLandtracUnits
                    enverusLandtracUnits={enverusLandtracUnits}
                    isLoading={this.state.isLoadingEnverus}
                    recordsPerPage={this.state.recordsPerPage}
                    columnExtras={columnExtras}
                    {...this.props}
                  />
                ) : null}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withParams(UnitDetail);
