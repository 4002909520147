import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Modal, Form, Select } from "antd";
import { get } from "lodash";
import { apiBase } from "../../utils/apiBase";

const mapStateToProps = (state) => ({
  user: get(state.user, "data", {}),
  users: get(state.users, "data", {}),
  leadStatus: get(state.appConfig, "data.lead_status", []),
});

const mapDispatchToProps = () => ({});

const CreateGenericLeads = (props) => {
  const { users, leadStatus } = props;
  const { visible, isLoading, onCancel, onCreate, selectedGenericFileRecords } =
    props;

  const [form] = Form.useForm();

  const [mailTemplates, setMailTemplates] = useState([]);

  async function getMailTemplates() {
    const res = await apiBase.get("/mail-templates");
    setMailTemplates(get(res.data, "mail_templates", []));
  }

  useEffect(() => {
    getMailTemplates();
  }, []);

  const title =
    selectedGenericFileRecords.length === 0
      ? `Create leads - based on search filters`
      : `Create leads - ${selectedGenericFileRecords.length} selected generics`;

  return (
    <Modal
      title={title}
      okText="Create"
      cancelText="Cancel"
      visible={visible}
      onCancel={onCancel}
      cancelButtonProps={{ disabled: isLoading }}
      okButtonProps={{ disabled: isLoading, loading: isLoading }}
      maskClosable={false}
      onOk={() => {
        form
          .validateFields()
          .then(async (values) => {
            await onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          })
          .finally(() => {
            form.resetFields();
          });
      }}
    >
      {mailTemplates.length === 0 ? (
        <p>No mail templates exist please create one</p>
      ) : (
        <Form
          form={form}
          name="createGenericLeads"
          layout={"vertical"}
          autoComplete="off"
          disabled={isLoading}
          initialValues={{
            status: "New",
            user_id:
              users.find((user) => user.email === "team@taborminerals.com")
                ?.id || users[0].id,
          }}
        >
          <Form.Item
            label="Status"
            name="status"
            rules={[
              {
                required: true,
                message: "A status is required",
              },
            ]}
          >
            <Select>
              {leadStatus.map((status, idx) => (
                <Select.Option value={status} key={idx}>
                  {status}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Lead owner"
            name="user_id"
            rules={[
              {
                required: true,
                message: "A status is required",
              },
            ]}
          >
            <Select>
              {users.map((user, idx) => (
                <Select.Option value={user.id} key={idx}>
                  {user.alias}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateGenericLeads);
