import React, { useState, useEffect } from "react";
import { apiBase } from "../utils/apiBase";
import { withParams } from "../utils/withParams";
import { nonEmptyString } from "../utils/strings";
import { get, pick, isEmpty } from "lodash";
import { clsx } from "clsx";

import { Row, Col, Button, Input, Checkbox, Form, message } from "antd";

import ReactJson from "react-json-view";

import styles from "./GenericFileRecordsDetail.module.scss";

const labelProps = {
  labelAlign: "left",
  labelCol: {
    xxl: { span: 7, offset: 0 },
    xl: { span: 9 },
    lg: { span: 10 },
  },
};

const GenericFileRecordUpdateForm = (props) => {
  const onFinish = (values) => {
    props.updateSubmit(values);
  };

  const onFinishFailed = () => {
    message.error("Form fields required");
  };

  const generic = props.generic || {};
  const { form, loading } = props;

  return (
    <div className={styles.compactForm}>
      <Form
        name="basic"
        layout="horizontal"
        form={form}
        initialValues={{
          data: generic.data,
          ...pick(generic, [
            "owner_name",
            "owner_name_formatted",
            "owner_first_name",
            "owner_middle_name",
            "owner_last_name",
            "owner_name_ico",
            "owner_address",
            "owner_address_formatted",
            "owner_city",
            "owner_state",
            "owner_zip_code",
            "owner_ntf",
          ]),
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        disabled={loading}
      >
        <Row gutter={16}>
          <Col span={12} xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
            <Form.Item shouldUpdate={true} noStyle>
              {({ getFieldValue, setFieldsValue }) => {
                const data = getFieldValue("data");
                console.log("data", data);
                return (
                  <ReactJson
                    src={data || {}}
                    onEdit={(edit) => {
                      setFieldsValue({ data: edit.updated_src });
                    }}
                    displayDataTypes={false}
                    enableClipboard={false}
                    name={null}
                    style={{
                      backgroundColor: "white",
                      fontSize: "12px",
                      border: "1px solid gainsboro",
                      overflow: "auto",
                      padding: "5px",
                    }}
                  />
                );
              }}
            </Form.Item>
          </Col>
          <Col span={12} xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
            <Form.Item label={"Generic file Id"} {...labelProps}>
              {generic.generic_file_id ? (
                <span
                  className={styles.objectLink}
                  onClick={(e) => {
                    props.history(`/generic-files/${generic.generic_file_id}`);
                  }}
                >
                  {generic.generic_file_id}
                </span>
              ) : (
                <div>-</div>
              )}
            </Form.Item>
            <Form.Item label={"Owner Id"} {...labelProps}>
              <span
                className={styles.objectLink}
                onClick={(e) => {
                  props.history(`/owners/${generic.owner_id}`);
                }}
              >
                {generic.owner_id}
              </span>
            </Form.Item>
            {[
              {
                name: "owner_name",
                label: "Owner name",
                disabled: true,
              },
              {
                name: "owner_first_name",
                label: "Owner first name",
                disabled: true,
              },
              {
                name: "owner_last_name",
                label: "Owner last name",
                disabled: true,
              },
              {
                name: "owner_name_formatted",
                label: "Owner name formatted",
                disabled: true,
              },
              {
                name: "owner_name_ico",
                label: "Owner name ICO",
                disabled: true,
              },
              {
                name: "owner_address",
                label: "Owner address",
                disabled: true,
              },
              {
                name: "owner_address_formatted",
                label: "Owner address formatted",
                disabled: true,
              },
              {
                name: "owner_city",
                label: "Owner city",
                disabled: true,
                required: true,
              },
              {
                name: "owner_state",
                label: "Owner state",
                disabled: true,
              },
              {
                name: "owner_zip_code",
                label: "Owner zip code",
                disabled: true,
              },
            ].map((item, index) => (
              <Form.Item
                key={index}
                label={item.label}
                name={item.name}
                rules={[{ required: item.required, message: "" }]}
                {...labelProps}
              >
                <Input
                  disabled={item.disabled}
                  onPressEnter={() => form.submit()}
                  className={clsx({
                    [styles.disabledItem]: item.disabled,
                  })}
                  size="small"
                />
              </Form.Item>
            ))}
            <Form.Item
              label="Owner NTF"
              name="owner_ntf"
              valuePropName="checked"
              {...labelProps}
            >
              <Checkbox disabled={true} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const GenericFileRecordsDetail = (props) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [generic, setGeneric] = useState({});

  useEffect(() => {
    const getGenericFileRecord = async () => {
      try {
        setLoading(true);
        const res = await apiBase.get(
          `/generic-file-records/${props.params.id}`
        );
        setGeneric(get(res, "data", {}));
      } catch (err) {
        let msg = `${err.response.status}: Failed to load generic ${props.params.id}`;
        message.error(msg);
      } finally {
        setLoading(false);
      }
    };
    getGenericFileRecord();
  }, [props.params.id]);

  function updateSubmit(values) {
    setLoading(true);

    const data = form.getFieldValue("data");
    Object.keys(data).forEach((key) => {
      data[key] = nonEmptyString(data[key]);
    });

    apiBase
      .patch(`generic-file-records/${generic.id}`, { data: data })
      .then((res) => {
        setGeneric(res.data);
        let msg = `${res.status}: Successfully updated Generic.`;
        message.success(msg);
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
        form.resetFields();
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div className="App__rowContentDetail">
      <Row gutter={16}>
        <Col span={24}>
          <div className={styles.card}>
            <div className={styles.cardTitle}>
              <div className={styles.leftTitle}>
                <h2>Generic file record</h2>
              </div>
              <div className={styles.rightTitle}>
                <div>
                  <Button
                    onClick={() => form.resetFields()}
                    className={styles.cardHeaderAction}
                    disabled={loading}
                  >
                    Reset
                  </Button>
                  <Button
                    className={styles.cardHeaderAction}
                    type="primary"
                    loading={loading}
                    htmlType="submit"
                    onClick={() => {
                      form.submit();
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
            <div className={styles.cardBody}>
              <Row gutter={24}>
                <Col span={24}>
                  {!isEmpty(generic) && (
                    <GenericFileRecordUpdateForm
                      form={form}
                      loading={loading}
                      generic={generic}
                      updateSubmit={updateSubmit}
                      history={props.history}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withParams(GenericFileRecordsDetail);
