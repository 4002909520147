import React, { useMemo, useRef, useState } from "react";
import debounce from "lodash/debounce";
import { get, pick } from "lodash";
import { apiBase } from "../../utils/apiBase";

import { Select, Spin, Divider, Input, Button, message } from "antd";

import { PlusOutlined } from "@ant-design/icons";

import styles from "./QBOSearchSelect.module.scss";

function renderDefaultOption(row, searchField) {
  return (
    <div className={styles.optionSimple}>
      <div className={styles.main}>{get(row, searchField)}</div>
      <div className={styles.extra}>{`[ID: ${row.Id}]`}</div>
    </div>
  );
}

async function fetchObjects({ searchUri, searchObject, searchField, query }) {
  const data = {
    endpoint: `/query?query=SELECT * FROM ${searchObject} WHERE ${searchField} LIKE '%${query}%'`,
  };

  return apiBase.post(`${searchUri}`, data).then((res) => {
    return get(res, `data.QueryResponse.${searchObject}`, []).map((row) => ({
      data: row,
      value: row.Id,
      label: renderDefaultOption(row, searchField),
    }));
  });
}

function QBOSearchSelect({
  debounceTimeout = 400,
  fetchOptions = fetchObjects,
  entityId,
  searchUri = `/entities/${entityId}/quickbooks/search`,
  createUri = `/entities/${entityId}/quickbooks/create`,
  searchObject = "Class",
  searchField = "Name",
  addNew = true,
  defaultOptions = [],
  ...props
}) {
  const [isLoadingAdd, setIsLoadingAdd] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState(defaultOptions);
  const fetchRef = useRef(0);

  const [fieldValue, setFieldValue] = useState("");

  const onFieldValueChange = (event) => {
    setFieldValue(event.target.value);
  };

  const addItem = (e) => {
    e.preventDefault();

    // Create item
    setIsLoadingAdd(true);
    apiBase
      .post(`${createUri}`, {
        endpoint: `${searchObject.toLowerCase()}?minorversion=70`,
        data: { [searchField]: fieldValue },
      })
      .then((res) => {
        const qboData = get(res, `data.${searchObject}`);
        const id = get(qboData, `Id`);
        setOptions([
          ...options,
          {
            value: id,
            data: qboData,
            label: (
              <div className={styles.optionSimple}>
                <div className={styles.main}>{get(qboData, searchField)}</div>
                <div className={styles.extra}>{`[ID: ${id}]`}</div>
              </div>
            ),
          },
        ]);
      })
      .catch((err) => {
        console.log(err);
        let msg = `${err.response.status}: Failed to create ${searchObject}.`;
        message.error(msg);
      })
      .finally(() => {
        setIsLoadingAdd(false);
        setFieldValue("");
      });
  };

  const debounceFetcher = useMemo(() => {
    const loadOptions = (query) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions({ searchUri, searchObject, searchField, query }).then(
        (newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return;
          }
          setOptions(newOptions);
          setFetching(false);
        }
      );
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout, searchUri, searchObject, searchField]);

  const triggerChange = (changedValue) => {
    props.onChange(
      get(
        options.find((option) => option.value === changedValue),
        "data"
      )
    );
  };

  return (
    <Select
      placeholder="Select items"
      {...pick(props, ["id", "placeholder"])}
      className="App__fullWidth"
      // dropdownStyle={{ height: "600px" }}
      listHeight={400}
      allowClear
      showSearch
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : <div>No results</div>}
      value={
        props.value
          ? {
              value: get(props.value, "Id"),
              label: renderDefaultOption(props.value, searchField),
            }
          : null
      }
      onChange={triggerChange}
      options={options}
      dropdownRender={(menu) => (
        <>
          {menu}
          {addNew && (
            <>
              <Divider
                style={{
                  margin: "8px 0",
                }}
              />
              <div className={styles.addNew}>
                <Input
                  placeholder={`Enter name of new ${searchObject}`}
                  value={fieldValue}
                  onChange={onFieldValueChange}
                  style={{ flexGrow: 1 }}
                  disabled={isLoadingAdd}
                />
                <Button
                  type="secondary"
                  icon={<PlusOutlined />}
                  onClick={addItem}
                  disabled={isLoadingAdd}
                />
              </div>
            </>
          )}
        </>
      )}
    />
  );
}

export default QBOSearchSelect;
