import React from "react";
import { connect } from "react-redux";
import { get, omit } from "lodash";
// import actions from "../../actions";
import { Select } from "antd";

const mapStateToProps = (state) => ({
  users: get(state.users, "data", []),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

function UserSelect(props) {
  const { users } = props;

  return (
    <Select
      placeholder="Select an user"
      options={users.map((user) => ({
        value: user.id,
        label: user.alias,
      }))}
      {...omit(props, ["dispatch", "users"])}
    />
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSelect);
