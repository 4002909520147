import React, { useState } from "react";
import { apiBase } from "../../utils/apiBase";
import moment from "moment";

import { Modal, Form, DatePicker, Button, InputNumber, message } from "antd";

import { PlusOutlined } from "@ant-design/icons";

import SearchSelect from "../common/SearchSelect";

const AssetRoyaltyCreateForm = (props) => {
  const { asset } = props;

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    apiBase
      .post(`assets/${asset.id}/royalties`, {
        ...values,
      })
      .then(async (res) => {
        message.success("Successfully created created asset royalty.");
      })
      .catch((err) => {
        console.log(err);
        let msg = `${err.response.status}: Failed to create asset royalty.`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
        resetComponent();
        setIsModalOpen(false);
        props.getAsset();
        props.getAssetRoyalties();
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    resetComponent();
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    resetComponent();
  };

  const submitForm = () => {
    form
      .validateFields()
      .then(() => {
        form.submit();
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };

  const resetComponent = () => {
    form.resetFields();
  };

  const [form] = Form.useForm();

  return (
    <div>
      <Button type="secondary" icon={<PlusOutlined />} onClick={showModal} />
      <Modal
        title="Create asset royalty"
        visible={isModalOpen}
        onOk={() => submitForm()}
        onCancel={handleCancel}
        cancelButtonProps={{ disabled: loading }}
        okButtonProps={{ disabled: loading, loading: loading }}
        maskClosable={false}
      >
        <Form
          name="createAssetRoyalty"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
          disabled={loading}
          initialValues={{ date: moment() }}
        >
          <Form.Item
            label="Operator"
            name="operator_id"
            rules={[{ required: false, message: "Operator is required" }]}
          >
            <SearchSelect
              searchUri="operators"
              searchField="name"
              placeholder="Search operators"
            />
          </Form.Item>
          <Form.Item
            label="Amount"
            name="amount"
            rules={[
              {
                required: true,
                message: "Amount is required.",
              },
            ]}
          >
            <InputNumber className="App__fullWidth" precision={2} prefix="$" />
          </Form.Item>
          <Form.Item
            label="Effective date"
            name="date"
            rules={[
              {
                required: true,
                message: "Date is required.",
              },
            ]}
          >
            <DatePicker
              className="App__fullWidth"
              format={[
                "M/DD/YY",
                "M/D/YY",
                "MM/D/YY",
                "M/DD/YYYY",
                "M/D/YYYY",
                "MM/D/YYYY",
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Well"
            name={"well_id"}
            rules={[
              {
                required: false,
                message: "Missing well",
              },
            ]}
          >
            <SearchSelect
              searchUri="wells"
              searchField="name"
              placeholder="Search wells"
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AssetRoyaltyCreateForm;
