import React, { useState, useEffect } from "react";
import { get } from "lodash";
import { apiBase } from "../../utils/apiBase";

import { Modal, Form, Input, Select, Button, message } from "antd";
import { withRouter } from "../../utils/withRouter";

const CreateMailQueueExport = (props) => {
  const { mailLetters } = props;

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [mailTemplates, setMailTemplates] = useState([]);

  async function getMailTemplates() {
    const res = await apiBase.get("/mail-templates");
    setMailTemplates(get(res.data, "mail_templates", []));
  }

  useEffect(() => {
    getMailTemplates();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    apiBase
      .post(`mail-exports/mail-queue`, {
        ...values,
      })
      .then((res) => {
        const mailExportId = get(res, "data.id", null);
        message.success("Successfully created mail export from queue.");
        props.history(`/mail-exports/${mailExportId}`);
      })
      .catch((err) => {
        console.log(err);
        let msg = `${get(err, "response.status", "Unknown")}: ${get(
          err,
          "response.data.message",
          "Failed to create mail export."
        )}`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    resetComponent();
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    resetComponent();
  };

  const submitForm = () => {
    form
      .validateFields()
      .then(() => {
        form.submit();
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };

  const resetComponent = () => {
    form.resetFields();
  };

  const [form] = Form.useForm();

  return (
    <div>
      <Button
        type="secondary"
        onClick={showModal}
        disabled={mailLetters.length === 0}
      >
        Create Mail Export
      </Button>
      <Modal
        title={`Create Mail Export - ${mailLetters.length} letters`}
        visible={isModalOpen}
        onOk={() => submitForm()}
        onCancel={handleCancel}
        cancelButtonProps={{ disabled: loading }}
        okButtonProps={{ disabled: loading, loading: loading }}
        maskClosable={false}
      >
        <Form
          name="mailExportCreate"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
          disabled={loading}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Name is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Labels mail template"
            name="labels_mail_template_id"
            rules={[
              {
                required: true,
                message: "A labelsmail template is required",
              },
            ]}
          >
            <Select>
              {mailTemplates.map((mailTemplate, idx) => (
                <Select.Option value={mailTemplate.id} key={idx}>
                  {mailTemplate.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default withRouter(CreateMailQueueExport);
