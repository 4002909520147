import React, { useState, useRef, useEffect } from "react";
import { get, debounce } from "lodash";
import clsx from "clsx";
import { Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  FilterFilled,
  SearchOutlined,
  LoadingOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import { formatNumber } from "../../../utils/numbers";
import { apiBase } from "../../../utils/apiBase";
import mapActions from "../../../actions/map";

import styles from "./MapFilterV2.module.scss";

const MapFilterV2 = ({
  mapRef,
  layerId,
  column,
  searchUri,
  isMultiSelect = false,
  counts = true,
  disabled = false,
}) => {
  const dispatch = useDispatch();
  const searchInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  // Get filter state from Redux
  const layerState = useSelector((state) =>
    get(state, `map.layers.${layerId}`)
  );
  const filterColumnOpen = get(layerState, "filterColumnOpen", null);
  const filterState = get(layerState, `filters.${column}`, null);
  const { value: selectedItems, label } = filterState;

  const isOpen = filterColumnOpen === column;

  // Focus the search input when the filter is opened
  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isOpen]);

  // Perform search when the input value changes or the filter is opened
  useEffect(() => {
    if (!isOpen) return;

    setLoading(true);
    const debouncedSearch = debounce((value) => {
      performSearch(value);
    }, 300);

    debouncedSearch(searchValue);

    return () => {
      debouncedSearch.cancel();
      setLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, isOpen]);

  const performSearch = (value) => {
    const otherFilters = Object.fromEntries(
      Object.entries(get(layerState, "filters", {}))
        .filter(([key]) => key !== column)
        .map(([key, filter]) => [key, filter.value.map((item) => item.value)])
        .filter(([_, values]) => values.length > 0)
    );

    apiBase
      .post(searchUri, {
        ...otherFilters,
        query_column: column,
        query_value: value,
        counts: counts,
      })
      .then((res) => {
        const results = get(res, `data.results`, []).filter(
          (result) => !selectedItems.some((item) => item.value === result.value)
        );
        setSearchResults(results);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSelect = (result) => {
    let newSelectedItems = [];

    // const isSelected = selectedItems.some((item) => item.id === result.id);
    const isSelected = selectedItems.some(
      (item) => item.value === result.value
    );

    if (isMultiSelect) {
      if (isSelected) {
        newSelectedItems = selectedItems.filter(
          // (item) => item.id !== result.id
          (item) => item.value !== result.value
        );
      } else {
        newSelectedItems = [...selectedItems, result];
      }
    } else {
      if (isSelected) {
        newSelectedItems = [];
      } else {
        newSelectedItems = [result];
      }
    }

    dispatch(
      mapActions.setLayerFilterColumnValue(
        null,
        layerId,
        column,
        newSelectedItems
      )
    );
  };

  return (
    <div className={styles.container}>
      <div
        className={clsx(styles.header, {
          [styles.active]: isOpen,
          [styles.headerDisabled]: disabled,
        })}
        onClick={() => {
          if (disabled) return;
          dispatch(
            mapActions.setLayerFilterColumnOpen(
              layerId,
              column,
              column === filterColumnOpen ? null : column
            )
          );
        }}
      >
        <span>{label}</span>
        {selectedItems.length > 0 && (
          <span
            className={styles.filterIcon}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(
                mapActions.setLayerFilterColumnValue(
                  mapRef,
                  layerId,
                  column,
                  []
                )
              );
            }}
          >
            <FilterFilled />
          </span>
        )}
      </div>
      {isOpen && (
        <>
          <div className={styles.search}>
            <Input
              ref={searchInputRef}
              type="text"
              size="small"
              placeholder="Search"
              suffix={
                loading ? (
                  <LoadingOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                ) : (
                  <SearchOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                )
              }
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
              className={styles.input}
            />
          </div>
        </>
      )}
      <div
        className={clsx(styles.resultsContainer, {
          [styles.resultsContainerLoading]: loading,
        })}
      >
        {selectedItems.length > 0 && (
          <div className={styles.selectedResult}>
            {selectedItems.map((result) => (
              <div
                key={result.value}
                className={styles.result}
                onClick={() => handleSelect(result)}
                onMouseOver={(e) => {
                  e.currentTarget.children[0].children[0].style.display =
                    "none";
                  e.currentTarget.children[0].children[1].style.display =
                    "inline";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.children[0].children[0].style.display =
                    "inline";
                  e.currentTarget.children[0].children[1].style.display =
                    "none";
                }}
              >
                <span>
                  <CheckOutlined
                    className={styles.actionIcon}
                    style={{ display: "inline" }}
                  />
                  <CloseOutlined
                    className={styles.actionIcon}
                    style={{ display: "none" }}
                  />
                  <span className={styles.name}>{get(result, "value")}</span>
                </span>
                {counts && (
                  <span className={styles.count}>
                    {get(result, "count", 0) > 0 &&
                      formatNumber(get(result, "count", 0), "0,0")}
                  </span>
                )}
              </div>
            ))}
          </div>
        )}
        {isOpen && searchResults.length > 0 && (
          <div className={styles.results}>
            {searchResults
              .filter(
                (result) =>
                  !selectedItems.some((item) => item.value === result.value)
              )
              .map((result) => (
                <div
                  key={result.value}
                  className={styles.result}
                  onClick={() => handleSelect(result)}
                >
                  <span className={styles.name}>{get(result, "value")}</span>
                  {counts && (
                    <span className={styles.count}>
                      {get(result, "count", 0) > 0 &&
                        formatNumber(get(result, "count", 0), "0,0")}
                    </span>
                  )}
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default MapFilterV2;
