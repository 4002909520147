import React, { Component } from "react";
import { withParams } from "../utils/withParams";
import { Link } from "react-router-dom";
import { apiBase } from "../utils/apiBase";
import { alphaSort } from "../utils/numbers";
import { formatDate } from "../utils/dates";
import { get, capitalize, cloneDeep } from "lodash";

import CreateGenericFileLeads from "./forms/CreateGenericFileLeads";
import TotalRecords from "./search/TotalRecords";

import ReactJson from "react-json-view";
import {
  Row,
  Col,
  Table,
  Select,
  Button,
  Input,
  Tooltip,
  Modal,
  Checkbox,
  message,
} from "antd";

import {
  DatabaseOutlined,
  QuestionCircleOutlined,
  ExclamationCircleFilled,
  DeleteOutlined,
} from "@ant-design/icons";

import styles from "./GenericFileDetail.module.scss";
import RawDataModal from "./common/RawDataModal";

const { confirm } = Modal;

class GenericFileDetail extends Component {
  state = {
    isLoading: false,
    genericFile: {},
    genericFileCopy: {},
    genericFileRecords: [],
    recordsPerPage: 25,
    isGenericFileEdit: false,
    isLoadingGenericFileEdit: false,
    columnExtras: false,
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    await Promise.all([this.getGenericFile(), this.getGenericFileRecords()]);
    this.setState({ isLoading: false });
  }

  async getGenericFile() {
    return apiBase
      .get(`/generic-files/${this.props.params.id}`)
      .then((res) => {
        this.setState({
          genericFile: res.data,
        });
      })
      .catch((err) => {
        let msg = `${get(
          err,
          "response.status"
        )}: Error loading generic file: ${this.props.params.id}`;
        message.error(msg);
      });
  }

  async getGenericFileRecords() {
    return apiBase
      .get(`/generic-files/${this.props.params.id}/records`)
      .then((res) => {
        this.setState({
          genericFileRecords: res.data
            .map((el, idx) => ({ ...el, ...{ key: idx } }))
            .sort((a, b) => a.id - b.id),
        });
      })
      .catch((err) => {
        let msg = `${get(
          err,
          "response.status"
        )}: Error loading generic file: ${this.props.params.id} records`;
        message.error(msg);
      });
  }

  downloadFile = () => {
    const { genericFile } = this.state;
    this.setState({ isLoading: true });
    return apiBase
      .post(`/files/download-url`, { key: genericFile.s3_key })
      .then((res) => {
        window.open(res?.data?.download_url, "_blank");
        this.setState({
          isLoading: false,
        });
      })
      .catch((err) => {
        let msg = `${get(
          err,
          "response.status"
        )}: Error downloading generic file: ${this.props.params.id}`;
        message.error(msg);
        this.setState({ isLoading: false });
      });
  };

  setGenericFileEdit = () => {
    const { isLoading, genericFile } = this.state;
    if (isLoading) return;
    this.setState({
      isGenericFileEdit: true,
      genericFileCopy: cloneDeep(genericFile),
    });
  };

  handleGenericFileEditChange = (e) => {
    const { genericFile } = this.state;
    genericFile[e.target.name] = e.target.value;
    this.setState({ genericFile });
  };

  handleGenericFileEditSubmit = () => {
    const { genericFile } = this.state;
    let genericFileEdit = {
      generic_file_id: parseInt(genericFile.id, 10),
      name: genericFile.name,
      description: genericFile.description,
    };
    this.setState({ isLoadingGenericFileEdit: true });
    // const DELAY = 2000
    // await new Promise((r) => setTimeout(r, DELAY));
    apiBase
      .patch(`/generic-files`, genericFileEdit)
      .then((res) => {
        this.setState({
          genericFile: res.data,
          isGenericFileEdit: false,
          isLoadingGenericFileEdit: false,
        });
        message.success("Successfully updated generic file");
      })
      .catch((err) => {
        this.setState({
          isLoadingGenericFileEdit: false,
        });
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
      });
  };

  cancelGenericFileEdit() {
    const { genericFileCopy } = this.state;
    this.setState({
      isGenericFileEdit: false,
      genericFile: genericFileCopy,
    });
  }

  handleRowClick(e, _to) {
    e.stopPropagation();
    if (e.ctrlKey || e.metaKey) {
      window.open(_to, "_blank");
      return;
    }
    this.props.history(_to);
  }

  handleRecordsChange(value) {
    this.setState({
      recordsPerPage: value,
    });
  }

  handleDelete = (e) => {
    const { isLoading, genericFile } = this.state;
    confirm({
      title: "Are you sure you want to delete this generic file?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      okButtonProps: { disabled: isLoading, loading: isLoading },
      cancelButtonProps: { disabled: isLoading, loading: isLoading },
      onOk: () => this.deleteGenericFile(genericFile.id),
    });
  };

  deleteGenericFile = (genericFileId) => {
    this.setState({ isLoading: true });
    apiBase
      .delete(`/generic-files/${genericFileId}`)
      .then((res) => {
        this.setState({ isLoading: false });
        window.open("/generic-files?limit=50&page=1", "_self");
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to delete generic file.`;
        message.error(msg);
        this.setState({ isLoading: false });
      });
  };

  deleteGenericFileRecord = (genericFileRecordId) => {
    this.setState({ isLoading: true });
    apiBase
      .delete(`/generic-file-records/${genericFileRecordId}`)
      .then((res) => {
        this.setState({ isLoading: false });
        Promise.all([this.getGenericFile(), this.getGenericFileRecords()]);
        let msg = `${get(res, "status")}: ${get(res, "data.message")}`;
        message.success(msg);
      })
      .catch((err) => {
        console.log(err);
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
        this.setState({ isLoading: false });
      });
  };

  showDeleteConfirm = (genericFileRecordId) => {
    const { isLoading } = this.state;
    confirm({
      title: "Are you sure you want to delete this generic file record?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      okButtonProps: { disabled: isLoading, loading: isLoading },
      cancelButtonProps: { disabled: isLoading, loading: isLoading },
      onOk: () => this.deleteGenericFileRecord(genericFileRecordId),
    });
  };

  onToggleExtras = (e) => {
    this.setState({
      columnExtras: e.target.checked,
    });
  };

  render() {
    const {
      genericFile,
      genericFileRecords,
      columnExtras,
      isGenericFileEdit,
      isLoadingGenericFileEdit,
      isLoading,
    } = this.state;

    const columns = [
      {
        title: "Record",
        dataIndex: "id",
        className: "App__rowLink",
        render: (value) => <div className="App__rowIcon">{value}</div>,
        onCell: (record) => ({
          onClick: (e) =>
            this.handleRowClick(e, `/generic-file-records/${record["id"]}`),
        }),
        width: "70px",
        sorter: (a, b) => a.id - b.id,
      },
      {
        title: "Owner",
        dataIndex: "owner_id",
        className: "App__rowLink",
        render: (value) => <div className="App__rowIcon">{value}</div>,
        onCell: (record) => ({
          onClick: (e) =>
            this.handleRowClick(e, `/owners/${record["owner_id"]}`),
        }),
        width: "70px",
        sorter: (a, b) => a.owner_id - b.owner_id,
      },
      ...[
        {
          title: "Owner name",
          dataIndex: "owner_name",
          sorter: (a, b) => alphaSort(a.owner_name, b.owner_name),
        },
        {
          title: "Owner name formatted",
          dataIndex: "owner_name_formatted",
          extras: true,
          sorter: (a, b) =>
            alphaSort(a.owner_name_formatted, b.owner_name_formatted),
        },
        {
          title: "Owner name ICO",
          dataIndex: "owner_name_ico",
          extras: true,
          sorter: (a, b) => alphaSort(a.owner_name_ico, b.owner_name_ico),
        },
        {
          title: "Owner address",
          dataIndex: "owner_address",
          sorter: (a, b) => alphaSort(a.owner_address, b.owner_address),
        },
        {
          title: "Owner address formatted",
          dataIndex: "owner_address_formatted",
          extras: true,
          sorter: (a, b) =>
            alphaSort(a.owner_address_formatted, b.owner_address_formatted),
        },
        {
          title: "Owner city",
          dataIndex: "owner_city",
          sorter: (a, b) => alphaSort(a.owner_city, b.owner_city),
        },
        {
          title: "Owner state",
          dataIndex: "owner_state",
          sorter: (a, b) => alphaSort(a.owner_state, b.owner_state),
        },
        {
          title: "Owner zip code",
          dataIndex: "owner_zip_code",
          sorter: (a, b) => alphaSort(a.owner_zip_code, b.owner_zip_code),
        },
        {
          title: "Lessee",
          dataIndex: ["data", "lessee"],
          sorter: (a, b) =>
            alphaSort(get(a, "data.lessee"), get(b, "data.lessee")),
        },
        {
          title: "Unit county",
          dataIndex: ["data", "unit_county"],
          sorter: (a, b) =>
            alphaSort(get(a, "data.unit_county"), get(b, "data.unit_county")),
        },
        {
          title: "Book",
          dataIndex: ["data", "book"],
          extras: true,
          sorter: (a, b) => alphaSort(get(a, "data.book"), get(b, "data.book")),
        },
        {
          title: "Page",
          dataIndex: ["data", "page"],
          extras: true,
          sorter: (a, b) => alphaSort(get(a, "data.page"), get(b, "data.page")),
        },
        {
          title: "File date",
          dataIndex: ["data", "file_date"],
          sorter: (a, b) =>
            alphaSort(get(a, "data.file_date"), get(b, "data.file_date")),
        },
        {
          title: "Lease type",
          dataIndex: ["data", "lease_type"],
          sorter: (a, b) =>
            alphaSort(get(a, "data.lease_type"), get(b, "data.lease_type")),
        },
        {
          title: "Section",
          dataIndex: ["data", "section"],
          sorter: (a, b) => {
            console.log(get(a, "data.section"), get(b, "data.section"));
            return alphaSort(get(a, "data.section"), get(b, "data.section"));
          },
        },
        {
          title: "Township",
          dataIndex: ["data", "township"],
          sorter: (a, b) =>
            alphaSort(get(a, "data.township"), get(b, "data.township")),
        },
        {
          title: "Range",
          dataIndex: ["data", "range"],
          sorter: (a, b) =>
            alphaSort(get(a, "data.range"), get(b, "data.range")),
        },
        {
          title: "Acres",
          dataIndex: ["data", "acres"],
          sorter: (a, b) =>
            alphaSort(get(a, "data.acres"), get(b, "data.acres")),
        },
        {
          title: "Royalty",
          dataIndex: ["data", "royalty"],
          sorter: (a, b) =>
            alphaSort(get(a, "data.royalty"), get(b, "data.royalty")),
        },
      ].filter((item) => (columnExtras ? true : !item.extras)),
      {
        title: "Data",
        dataIndex: "data",
        render: (_, record) => <RawDataModal data={get(record, "data")} />,
      },
      {
        title: "Actions",
        dataIndex: "id",
        key: "actions",
        render: (value, record) => {
          return (
            <div className="App__tableOperations">
              <Button
                shape="circle"
                icon={<DeleteOutlined />}
                size="small"
                onClick={() => this.showDeleteConfirm(record.id)}
                disabled={isLoading}
              />
            </div>
          );
        },
      },
    ];

    return (
      <div className="App__rowContentDetail">
        <Row>
          <Col span={24}>
            <div className={styles.card}>
              <div className={styles.cardTitle}>
                <div className={styles.leftTitle}>
                  <h2>Generic file</h2>
                </div>
                <div className={styles.rightTitle}>
                  <div>
                    <Button
                      onClick={this.handleDelete}
                      className={styles.cardHeaderAction}
                      disabled={
                        ["queued", "running"].includes(
                          genericFile.upload_status
                        ) || isLoading
                      }
                      loading={isLoading}
                    >
                      Delete
                    </Button>
                    <Button
                      onClick={this.downloadFile}
                      className={styles.cardHeaderAction}
                      disabled={
                        ["queued", "running"].includes(
                          genericFile.upload_status
                        ) || isLoading
                      }
                      loading={isLoading}
                    >
                      Download
                    </Button>
                  </div>
                  <CreateGenericFileLeads
                    genericFile={genericFile}
                    parentIsLoading={isLoading}
                  />
                  {!isGenericFileEdit ? (
                    <div>
                      <Button
                        onClick={() => this.setGenericFileEdit()}
                        className={styles.cardHeaderAction}
                        disabled={
                          ["queued", "running"].includes(
                            genericFile.upload_status
                          ) || isLoading
                        }
                        loading={isLoading}
                      >
                        Edit
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <Button
                        onClick={() => this.cancelGenericFileEdit()}
                        className={styles.cardHeaderAction}
                        disabled={isLoadingGenericFileEdit}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => this.handleGenericFileEditSubmit()}
                        className={styles.cardHeaderAction}
                        type="primary"
                        loading={isLoadingGenericFileEdit}
                      >
                        Save
                      </Button>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.cardBody}>
                <Row gutter={24}>
                  <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className={styles.objectData}>
                      <span>Id:</span>
                      {genericFile.id}
                    </div>
                    <div className={styles.objectData}>
                      <span>Name:</span>
                      <div onClick={this.setGenericFileEdit}>
                        <Input
                          value={genericFile.name}
                          onChange={(e) => this.handleGenericFileEditChange(e)}
                          onPressEnter={(e) => {
                            this.handleGenericFileEditSubmit();
                            e.target.blur();
                          }}
                          className={
                            isGenericFileEdit
                              ? styles.inputActive
                              : styles.inputDisabled
                          }
                          disabled={isLoadingGenericFileEdit}
                          name="name"
                          type="string"
                          size="small"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className={styles.objectData}>
                      <span>Description:</span>
                      <div onClick={() => this.setGenericFileEdit()}>
                        <Input
                          value={genericFile.description}
                          onChange={(e) => this.handleGenericFileEditChange(e)}
                          onPressEnter={(e) => {
                            this.handleGenericFileEditSubmit();
                            e.target.blur();
                          }}
                          className={
                            isGenericFileEdit
                              ? styles.inputActive
                              : styles.inputDisabled
                          }
                          disabled={isLoadingGenericFileEdit}
                          name="description"
                          type="string"
                          size="small"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className={styles.objectData}>
                      <span>File name:</span>
                      {genericFile.file_name}
                    </div>
                    <div className={styles.objectData}>
                      <span>File s3 key:</span>
                      {genericFile.s3_key}
                    </div>
                    <div className={styles.objectData}>
                      <span>File s3 bucket:</span>
                      {genericFile.s3_bucket}
                    </div>
                    <div
                      className={`${styles.objectData} ${styles.linkNoColor}`}
                    >
                      <span>Created By:</span>
                      <Link
                        to={`/settings/users/${get(genericFile, "user.id")}`}
                      >
                        {get(genericFile, "user.alias")}
                      </Link>
                    </div>
                  </Col>
                  <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className={styles.objectData}>
                      <span>Upload status:</span>
                      {capitalize(genericFile.upload_status)}
                    </div>
                    <div className={styles.objectData}>
                      <span>Upload error:</span>
                      <div className={styles.objectLink}>
                        <Tooltip
                          overlayClassName={styles.toolTipCard}
                          placement="bottom"
                          title={
                            <ReactJson
                              src={genericFile.error}
                              collapsed={true}
                              style={{
                                backgroundColor: "white",
                                width: "484px",
                                fontSize: "12px",
                              }}
                              displayDataTypes={false}
                            />
                          }
                        >
                          <DatabaseOutlined />
                        </Tooltip>
                      </div>
                    </div>
                    <div className={styles.objectData}>
                      <span>File rows:</span>
                      {genericFile.file_metadata?.data?.details?.shape?.rows}
                    </div>
                    <div className={styles.objectData}>
                      <span>File columns:</span>
                      {genericFile.file_metadata?.data?.details?.shape?.columns}
                    </div>
                    <div className={styles.objectData}>
                      <span>File metadata:</span>
                      <div className={styles.objectLink}>
                        <Tooltip
                          overlayClassName={styles.toolTipCard}
                          placement="bottom"
                          title={
                            <ReactJson
                              src={genericFile.file_metadata}
                              collapsed={true}
                              style={{
                                backgroundColor: "white",
                                width: "484px",
                                fontSize: "12px",
                              }}
                              displayDataTypes={false}
                            />
                          }
                        >
                          <DatabaseOutlined />
                        </Tooltip>
                      </div>
                    </div>
                    <div className={styles.objectData}>
                      <span>Column mapping:</span>
                      <div className={styles.objectLink}>
                        <Tooltip
                          overlayClassName={styles.toolTipCard}
                          placement="bottom"
                          title={
                            <ReactJson
                              src={genericFile.column_mapping}
                              collapsed={true}
                              style={{
                                backgroundColor: "white",
                                width: "484px",
                                fontSize: "12px",
                              }}
                              displayDataTypes={false}
                            />
                          }
                        >
                          <DatabaseOutlined />
                        </Tooltip>
                      </div>
                    </div>
                    <div className={styles.objectData}>
                      <span>Created At:</span>
                      {formatDate(
                        genericFile.created_at,
                        "YYYY-MM-DD, h:mm:ss A"
                      )}
                    </div>
                    <div className={styles.objectData}>
                      <span>Updated At:</span>
                      {formatDate(
                        genericFile.updated_at,
                        "YYYY-MM-DD, h:mm:ss A"
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className={styles.card}>
              <div className={styles.cardTitle}>
                <div className={styles.leftTitle}>
                  <h2>
                    Records{" "}
                    <Tooltip
                      title="First 1,000. We can increase this if needed."
                      placement="rightTop"
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </h2>
                </div>
                <div className={styles.rightTitle}>
                  <Checkbox
                    onChange={this.onToggleExtras}
                    className={styles.columnExtras}
                  >
                    Column extras
                  </Checkbox>
                  <Select
                    defaultValue={25}
                    onChange={this.handleRecordsChange.bind(this)}
                    className={"App__mr-5"}
                  >
                    <Select.Option value={10}>10</Select.Option>
                    <Select.Option value={25}>25</Select.Option>
                    <Select.Option value={50}>50</Select.Option>
                    <Select.Option value={100}>100</Select.Option>
                  </Select>
                  <TotalRecords
                    title={"Records"}
                    totalRecords={genericFileRecords.length}
                  />
                </div>
              </div>
              <div className={styles.cardBody}>
                <Table
                  columns={columns}
                  dataSource={genericFileRecords}
                  pagination={{
                    pageSize: this.state.recordsPerPage,
                    showSizeChanger: false,
                  }}
                  className="App__smallTables"
                  showSorterTooltip={false}
                  loading={this.state.isLoading}
                  scroll={{ x: "100%" }}
                  bordered
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withParams(GenericFileDetail);
