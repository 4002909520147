import { message } from "antd";
import { apiBase } from "../utils/apiBase";

export const types = {
  USERS_SET: "USERS_SET",
};

export const setUsers = (value) => ({
  type: types.USERS_SET,
  value,
});

export const getUsers = () => (dispatch) => {
  return apiBase
    .get(`users`)
    .then((res) => dispatch(setUsers(res.data)))
    .catch((err) => {
      dispatch(setUsers({}));
      message.error("Failed load users.");
    });
};

export const initialState = {
  data: [],
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case types.USERS_SET:
      return {
        ...state,
        data: action.value,
      };
    default:
      return state;
  }
}

const actions = {
  getUsers,
};

export default actions;
