import React, { useState } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import { apiBase } from "../../utils/apiBase";

import QBOSearchSelect from "../common/QBOSearchSelect";
import EntitySelect from "../common/EntitySelect";

import { Modal, Form, Button, message } from "antd";

import { PlusOutlined } from "@ant-design/icons";

// import styles from "./OperatorVendorCreateForm.module.scss";

const mapStateToProps = (state) => ({
  entity: get(state.entity, "data", {}),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const OperatorVendorCreateForm = (props) => {
  const { operator } = props;
  const { entity } = props;

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    apiBase
      .post(`operators/${operator.id}/quickbooks-vendors`, {
        ...values,
      })
      .then(async (res) => {
        message.success("Successfully created operator quickbooks vendor.");
      })
      .catch((err) => {
        console.log(err);
        let msg = `${err.response.status}: Failed to create operator quickbooks vendor.`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
        resetComponent();
        setIsModalOpen(false);
        props.getOperatorVendors();
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    resetComponent();
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    resetComponent();
  };

  const submitForm = () => {
    form
      .validateFields()
      .then(() => {
        form.submit();
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };

  const onFormChange = (changedValues, allValues) => {
    if (changedValues.entity_id) {
      form.setFieldsValue({
        "quickbooks_vendor.qbo_data": null,
      });
    }
  };

  const resetComponent = () => {
    form.resetFields();
  };

  const [form] = Form.useForm();

  return (
    <div>
      <Button type="secondary" icon={<PlusOutlined />} onClick={showModal} />
      <Modal
        title="Add quickbooks vendor"
        visible={isModalOpen}
        onOk={() => submitForm()}
        onCancel={handleCancel}
        cancelButtonProps={{ disabled: loading }}
        okButtonProps={{ disabled: loading, loading: loading }}
        maskClosable={false}
      >
        <Form
          name="wellAssetCreate"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onFormChange}
          autoComplete="off"
          layout="vertical"
          disabled={loading}
          initialValues={{
            entity_id: entity.id,
          }}
        >
          <Form.Item
            label="Entity"
            name="entity_id"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <EntitySelect />
          </Form.Item>
          <Form.Item
            label="Quickbooks vendor"
            name="quickbooks_vendor.qbo_data"
            rules={[
              { required: true, message: "Quickbooks vendor is required" },
            ]}
          >
            <QBOSearchSelect
              entityId={entity.id}
              searchObject="Vendor"
              searchField="DisplayName"
              placeholder="Search vendors"
              disabled={!entity.quickbooks_realm_id}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OperatorVendorCreateForm);
