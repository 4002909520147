import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { apiBase } from "../../utils/apiBase";
import { withParams } from "../../utils/withParams";
import { get, pick, isEmpty } from "lodash";
import { formatDate } from "../../utils/dates";
import moment from "moment";
import ReactJson from "react-json-view";

import { formatNumber } from "../../utils/numbers";

import {
  Row,
  Col,
  Form,
  Input,
  Button,
  DatePicker,
  Tooltip,
  Modal,
  Collapse,
  Table,
  message,
  Divider,
} from "antd";

import { ExclamationCircleFilled, DatabaseOutlined } from "@ant-design/icons";

import styles from "./Royalty.module.scss";

const { Panel } = Collapse;
const { confirm } = Modal;

const labelProps = {
  labelAlign: "left",
  labelCol: {
    xxl: { span: 7, offset: 0 },
    xl: { span: 9 },
    lg: { span: 10 },
  },
};

const formFields = [
  "entity_id",
  "user_id",
  "amount",
  "description",
  "operator_id",
];

const Royalty = (props) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [royalty, setRoyalty] = useState({});

  const getRoyalty = useCallback(async () => {
    try {
      setLoading(true);
      const res = await apiBase.get(`/royalties/${props.params.id}`);
      setRoyalty(get(res, "data", {}));
    } catch (err) {
      let msg = `${err.response.status}: Failed to load royalty ${props.params.id}`;
      message.error(msg);
    } finally {
      setLoading(false);
    }
  }, [props.params.id]);

  useEffect(() => {
    getRoyalty();
  }, [getRoyalty]);

  useEffect(() => {
    if (!isEmpty(royalty)) {
      form.setFieldsValue({
        ...pick(royalty, formFields),
      });
    }
  }, [form, royalty]);

  const updateSubmit = (values) => {
    setLoading(true);
    apiBase
      .patch(`royalties/${royalty.id}`, values)
      .then((res) => {
        setRoyalty(res.data);
        let msg = `${res.status}: Successfully updated royalty.`;
        message.success(msg);
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
        form.resetFields();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteRoyalty = async (royaltyId) => {
    setLoading(true);
    apiBase
      .delete(`/royalties/${royaltyId}`)
      .then((res) => {
        setLoading(false);
        props.history("/portfolio/royalties?limit=50&page=1");
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to delete royalty.`;
        message.error(msg);
        setLoading(false);
      });
  };

  const handleDelete = () => {
    confirm({
      title: "Are you sure you want to delete this royalty?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      okButtonProps: { disabled: loading, loading: loading },
      cancelButtonProps: { disabled: loading, loading: loading },
      onOk: () => deleteRoyalty(royalty.id),
    });
  };

  const quickbooksSync = async (royaltyId) => {
    setLoading(true);
    apiBase
      .get(`/royalties/${royaltyId}/quickbooks-sync`)
      .then((res) => {
        setLoading(false);
        getRoyalty();
        let msg = `${res.status}: Successfully synced to quickbooks.`;
        message.success(msg);
      })
      .catch((err) => {
        let msg = `${err.response.status}: ${get(
          err,
          "response.data.message",
          "Failed to create royalty."
        )}`;
        console.error(err);
        message.error(msg);
        setLoading(false);
      });
  };

  const handleQuickbooksSync = () => {
    confirm({
      title: "Are you sure you want to sync to quickbooks?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      okButtonProps: { disabled: loading, loading: loading },
      cancelButtonProps: { disabled: loading, loading: loading },
      onOk: () => quickbooksSync(royalty.id),
    });
  };

  const onFinishFailed = () => {
    message.error("Form fields reqired");
  };

  const handleRowClick = (e, _to) => {
    e.stopPropagation();
    if (e.ctrlKey || e.metaKey) {
      window.open(_to, "_blank");
      return;
    }
    props.history(_to);
  };

  const breakdown = get(royalty, "breakdown");

  return (
    <div className="App__rowContentDetail">
      <Row gutter={4}>
        <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className={styles.card}>
            <div className={styles.cardTitle}>
              <div className={styles.leftTitle}>
                <h2>Royalty</h2>
              </div>
              <div className={styles.rightTitle}>
                <div>
                  <Button
                    onClick={() => handleQuickbooksSync()}
                    className={styles.cardHeaderAction}
                    disabled={loading}
                    loading={loading}
                  >
                    Quickbooks Sync
                  </Button>

                  <Button
                    onClick={() => handleDelete()}
                    className={styles.cardHeaderAction}
                    disabled={loading}
                    loading={loading}
                  >
                    Delete
                  </Button>
                  <Button
                    onClick={() => form.resetFields()}
                    className={styles.cardHeaderAction}
                    disabled={loading}
                  >
                    Reset
                  </Button>
                  <Button
                    className={styles.cardHeaderAction}
                    type="primary"
                    loading={loading}
                    htmlType="submit"
                    onClick={() => {
                      form.submit();
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
            <div className={styles.cardBody}>
              <Row gutter={24}>
                <Col span={24}>
                  {!isEmpty(royalty) && (
                    <div className={styles.compactForm}>
                      <Form
                        name="basic"
                        layout="horizontal"
                        form={form}
                        initialValues={{
                          ...pick(royalty, formFields),
                          date: royalty.date ? moment(royalty.date) : null,
                        }}
                        onFinish={updateSubmit}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        disabled={loading}
                      >
                        <Row gutter={16}>
                          <Col
                            span={12}
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                          >
                            <Form.Item label="Entity" {...labelProps}>
                              <Link
                                to={`/portfolio/entities/${get(
                                  royalty,
                                  "entity_id"
                                )}`}
                              >
                                {get(royalty, "entity.name")}
                              </Link>
                            </Form.Item>
                            <Form.Item label="Operator" {...labelProps}>
                              <Link
                                to={`/portfolio/operators/${get(
                                  royalty,
                                  "operator_id"
                                )}`}
                              >
                                {!!get(royalty, "operator.name") ? (
                                  get(royalty, "operator.name")
                                ) : (
                                  <span>-</span>
                                )}
                              </Link>
                            </Form.Item>
                            <Form.Item
                              label="Total check amount"
                              {...labelProps}
                            >
                              {`$${formatNumber(royalty.amount, "0,0.00")}`}
                            </Form.Item>
                            <Form.Item
                              label="Statutory interest amount"
                              {...labelProps}
                            >
                              {`$${formatNumber(
                                royalty.statutory_interest_amount,
                                "0,0.00"
                              )}`}
                            </Form.Item>
                            <Form.Item label="User" {...labelProps}>
                              <Link
                                to={`/settings/users/${get(
                                  royalty,
                                  "user_id"
                                )}`}
                              >
                                {get(royalty, "user.alias")}
                              </Link>
                            </Form.Item>
                            {[
                              {
                                name: "date",
                                label: "Date",
                              },
                            ].map((item, index) => (
                              <Form.Item
                                key={index}
                                label={item.label}
                                name={item.name}
                                {...labelProps}
                              >
                                <DatePicker
                                  className="App__fullWidth"
                                  size="small"
                                  format={[
                                    "M/DD/YY",
                                    "M/D/YY",
                                    "MM/D/YY",
                                    "M/DD/YYYY",
                                    "M/D/YYYY",
                                    "MM/D/YYYY",
                                  ]}
                                />
                              </Form.Item>
                            ))}
                            <Form.Item label="Created at" {...labelProps}>
                              {formatDate(
                                royalty.created_at,
                                "YYYY-MM-DD, h:mm:ss A"
                              ) || "-"}
                            </Form.Item>
                            <Form.Item label="Quickbooks Id" {...labelProps}>
                              {royalty.qbo_id || "-"}
                            </Form.Item>
                            <Form.Item
                              label="Quickbooks deposit"
                              {...labelProps}
                            >
                              {royalty.qbo_data ? (
                                <Tooltip
                                  overlayClassName="App__toolTipCard"
                                  placement="bottom"
                                  title={
                                    <ReactJson
                                      src={royalty.qbo_data || {}}
                                      collapsed={true}
                                      style={{
                                        backgroundColor: "white",
                                        width: "484px",
                                        fontSize: "12px",
                                      }}
                                      displayDataTypes={false}
                                    />
                                  }
                                >
                                  <DatabaseOutlined />
                                </Tooltip>
                              ) : (
                                <span>-</span>
                              )}
                            </Form.Item>
                            <Form.Item
                              label="Description"
                              name="description"
                              {...labelProps}
                            >
                              <Input.TextArea rows={4} size="small" />
                            </Form.Item>
                            <Divider />
                            {breakdown && (
                              <Collapse
                                className={styles.collapseCompact}
                                defaultActiveKey={get(
                                  breakdown,
                                  "royalty_lines",
                                  []
                                ).map((_, idx) => idx)}
                              >
                                {get(breakdown, "royalty_lines", []).map(
                                  (royalty_line, index) => {
                                    return (
                                      <Panel
                                        key={index}
                                        header={
                                          <div className={styles.header}>
                                            <Link
                                              className={styles.link}
                                              to={`/portfolio/wells/${royalty_line.well_id}`}
                                            >
                                              {get(
                                                royalty_line,
                                                "well.name",
                                                royalty_line.well_id
                                              )}
                                            </Link>
                                            <div className={styles.amount}>
                                              {`$${formatNumber(
                                                royalty_line.amount,
                                                "0,0.00"
                                              )}`}
                                            </div>
                                          </div>
                                        }
                                      >
                                        <Table
                                          pagination={false}
                                          size="small"
                                          dataSource={get(
                                            royalty_line,
                                            "royalty_line_items",
                                            []
                                          ).map((item, idx) => ({
                                            ...item,
                                            key: idx,
                                          }))}
                                          columns={[
                                            {
                                              key: "asset_name",
                                              dataIndex: ["asset", "name"],
                                              title: "Asset name",
                                              className: `App__rowLink`,
                                              width: "40%",
                                              onCell: (record) => ({
                                                onClick: (e) =>
                                                  handleRowClick(
                                                    e,
                                                    `/portfolio/assets/${get(
                                                      record,
                                                      "asset.id"
                                                    )}`
                                                  ),
                                              }),
                                            },
                                            {
                                              key: "revenue_allocation",
                                              dataIndex: [
                                                "well_asset",
                                                "revenue_allocation",
                                              ],
                                              title: "Revenue allocation",
                                              render: (value) =>
                                                value !== null
                                                  ? `${formatNumber(
                                                      value * 100,
                                                      "0.00"
                                                    )}%`
                                                  : null,
                                            },
                                            {
                                              key: "asset_allocation",
                                              dataIndex: [
                                                "well_asset",
                                                "asset_allocation",
                                              ],
                                              title: "Asset allocation",
                                              render: (value) =>
                                                value !== null
                                                  ? `${formatNumber(
                                                      value * 100,
                                                      "0.00"
                                                    )}%`
                                                  : null,
                                            },
                                            {
                                              key: "amount",
                                              dataIndex: "amount",
                                              title: "",
                                              align: "right",
                                              width: 100,
                                              render: (value) =>
                                                value !== null
                                                  ? `$${formatNumber(
                                                      value,
                                                      "0,0.00"
                                                    )}`
                                                  : null,
                                            },
                                          ]}
                                        />
                                      </Panel>
                                    );
                                  }
                                )}
                              </Collapse>
                            )}
                          </Col>
                          <Col
                            span={12}
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                          >
                            {royalty.s3_url ? (
                              <div style={{ height: "80vh" }}>
                                <iframe
                                  title="royalty-document"
                                  width={"100%"}
                                  height={"100%"}
                                  src={`${royalty.s3_url}&embedded=true`}
                                ></iframe>
                              </div>
                            ) : (
                              <div>No documents</div>
                            )}
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withParams(Royalty);
