import React, { Component } from "react";
import moment from "moment";

import { Modal, Select, Tooltip, DatePicker, Checkbox } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";

import { syncType, syncTypeOptions } from "../../common/rrcSyncs";

const DEFAULT_FORM_VALUES = {
  [syncType.partial]: {
    submitted_date: [moment().subtract(3, "days"), moment()],
    upload_files: true,
  },
  [syncType.full]: {
    submitted_date: [moment().subtract(365, "days"), moment()],
    upload_files: false,
  },
};

const CreateRRCSync = Form.create({
  name: "create_rrc_sync",
})(
  // eslint-disable-next-line
  class extends Component {
    handleTypeChange = (value) => {
      this.props.form.resetFields(["submitted_date", "upload_files"]);
    };

    render() {
      const { visible, isLoading, onCancel, onCreate, form } = this.props;
      const { getFieldDecorator, getFieldValue } = form;

      return (
        <Modal
          open={visible}
          title="Create RRC sync"
          okText="Create"
          cancelText="Cancel"
          onCancel={onCancel}
          onOk={onCreate}
          confirmLoading={isLoading}
          maskClosable={false}
        >
          <Form layout="vertical">
            <Form.Item
              label={
                <span>
                  Sync type&nbsp;
                  <Tooltip
                    title="The type of sync to create."
                    placement="rightTop"
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator("type", {
                rules: [
                  {
                    required: true,
                  },
                ],
                initialValue: syncType.partial,
              })(
                <Select onChange={this.handleTypeChange}>
                  {syncTypeOptions.map((row, idx) => (
                    <Select.Option value={row.value} key={idx}>
                      {row.label}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item label={<span>Submitted date&nbsp;</span>}>
              {getFieldDecorator("submitted_date", {
                rules: [
                  {
                    required: true,
                    message: "Please select a date range.",
                  },
                ],
                initialValue:
                  DEFAULT_FORM_VALUES[getFieldValue("type")].submitted_date,
              })(
                <DatePicker.RangePicker
                  className="App__fullWidth"
                  disabledDate={(current) =>
                    current >
                    DEFAULT_FORM_VALUES[getFieldValue("type")].submitted_date[1]
                  }
                  format={[
                    "M/DD/YY",
                    "M/D/YY",
                    "MM/D/YY",
                    "M/DD/YYYY",
                    "M/D/YYYY",
                    "MM/D/YYYY",
                  ]}
                />
              )}
            </Form.Item>
            <Form.Item label={<span>Upload files&nbsp;</span>}>
              {getFieldDecorator("upload_files", {
                initialValue:
                  DEFAULT_FORM_VALUES[getFieldValue("type")].upload_files,
                valuePropName: "checked",
              })(<Checkbox />)}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

export default CreateRRCSync;
