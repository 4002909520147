import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "./utils/withRouter";
import { Routes, Route, Link } from "react-router-dom";
import { get, isEmpty } from "lodash";
import actions from "./actions";

import { message } from "antd";
import Loading from "./common/Loading";
import Login from "./main/Login";
import UserSetup from "./main/UserSetup";
import ForgotPassword from "./main/ForgotPassword";
import MainLayout from "./main/MainLayout";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

import "./App.css";

const mapStateToProps = (state) => ({
  user: get(state.user, "data", {}),
  users: get(state.users, "data", []),
  appConfig: get(state.appConfig, "data", {}),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInitializing: true,
    };

    this.loginUser = this.loginUser.bind(this);
    this.logoutUser = this.logoutUser.bind(this);
  }

  async componentDidMount() {
    const { dispatch } = this.props;

    try {
      const authToken = window.localStorage.getItem("authToken");
      if (authToken) {
        const user = await dispatch(actions.user.getUser());
        if (user) {
          await Promise.all([
            dispatch(actions.appConfig.getAppConfig()),
            dispatch(actions.entities.getEntities()),
            dispatch(actions.users.getUsers()),
            dispatch(actions.aggregates.getAggregates()),
            dispatch(actions.labels.getLabels()),
          ]);
        }
      }
    } catch (error) {
      console.log(error);
      window.localStorage.clear();
    }
    this.setState({ isInitializing: false });
  }

  logoutUser() {
    const { dispatch } = this.props;
    dispatch(actions.user.logoutUser());
  }

  async loginUser(values) {
    const { dispatch } = this.props;
    try {
      const auth = await dispatch(actions.user.authenticateUser(values));
      if (auth) {
        await Promise.all([
          dispatch(actions.appConfig.getAppConfig()),
          dispatch(actions.entities.getEntities()),
          dispatch(actions.user.getUser()),
          dispatch(actions.users.getUsers()),
          dispatch(actions.aggregates.getAggregates()),
          dispatch(actions.labels.getLabels()),
        ]);
        return true;
      }
      return false;
    } catch (err) {
      console.log(err);
      message.error("Failed to login.");
      return false;
    }
  }

  render() {
    const { user, users, appConfig } = this.props;
    const { isInitializing } = this.state;

    return (
      <>
        {isInitializing ? (
          <Loading message={""} />
        ) : (
          <>
            <Routes>
              <Route
                path="maintanence"
                element={
                  <Loading
                    message={"The application is in maintance mode. "}
                    subMessage={
                      <div>
                        Please reach out to glen@taborminerals.com.{" "}
                        <Link to={"/login"}>Try again</Link>
                      </div>
                    }
                  />
                }
              />
              <Route
                path="login"
                element={
                  <Login
                    isAuthenticated={!isEmpty(user)}
                    loginUser={this.loginUser}
                  />
                }
              />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route
                path="setup/:token"
                element={
                  <UserSetup
                    isAuthenticated={!isEmpty(user)}
                    loginUser={this.loginUser}
                  />
                }
              />
              <Route
                path="/*"
                element={
                  <MainLayout
                    logoutUser={this.logoutUser}
                    isAuthenticated={!isEmpty(user)}
                    currentUserId={user.id}
                    currentUserRole={user.role}
                    users={users}
                    interestFilters={get(appConfig, "interest_filters")}
                    leadStatus={get(appConfig, "lead_status")}
                  />
                }
              />
            </Routes>
          </>
        )}
      </>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
