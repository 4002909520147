import React, { Component } from "react";
import { withParams } from "../utils/withParams";
import { apiBase } from "../utils/apiBase";

import {
  LockOutlined,
  MailOutlined,
  PhoneOutlined,
  QuestionCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";

import {
  Row,
  Col,
  Button,
  Input,
  Checkbox,
  Tooltip,
  Radio,
  Modal,
  message,
} from "antd";
import styles from "./UserDetail.module.scss";

const ChangePasswordForm = Form.create({ name: "change_password" })(
  // eslint-disable-next-line
  class extends Component {
    state = {
      confirmDirty: false,
    };

    handleConfirmBlur = (e) => {
      const value = e.target.value;
      this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };

    compareToFirstPassword = (rule, value, callback) => {
      const form = this.props.form;
      if (value && value !== form.getFieldValue("password")) {
        callback("Two passwords that you enter is inconsistent!");
      } else {
        callback();
      }
    };

    validateToNextPassword = (rule, value, callback) => {
      const form = this.props.form;
      if (value && (this.state.confirmDirty || null)) {
        form.validateFields(["confirm"], { force: true });
      }
      callback();
    };

    render() {
      const { visible, onCancel, onSubmit, form } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal
          visible={visible}
          title="Change Password"
          okText="Submit"
          cancelText="Cancel"
          onCancel={onCancel}
          onOk={onSubmit}
          maskClosable={false}
        >
          <Form layout="vertical">
            <Form.Item label="Password">
              {getFieldDecorator("password", {
                rules: [
                  { required: true, message: "Please enter a password." },
                  { validator: this.validateToNextPassword },
                ],
              })(
                <Input prefix={<LockOutlined />} type="password" size="large" />
              )}
            </Form.Item>
            <Form.Item label="Confirm Password">
              {getFieldDecorator("confirm", {
                rules: [
                  { required: true, message: "Please confirm your password." },
                  { validator: this.compareToFirstPassword },
                ],
              })(
                <Input
                  prefix={<LockOutlined />}
                  type="password"
                  onBlur={this.handleConfirmBlur}
                  size="large"
                />
              )}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

const UserUpdateForm = Form.create({ name: "user_update" })(
  // eslint-disable-next-line
  class extends Component {
    render() {
      const { getFieldDecorator } = this.props.form;
      const user = this.props.user || {};
      const currentUserRole = this.props.currentUserRole;
      const disabled = !this.props.isEditUser;
      return (
        <Form layout="vertical">
          <Row>
            <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label="First Name">
                {getFieldDecorator("first_name", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your first name.",
                    },
                  ],
                  initialValue: user.first_name,
                })(
                  <Input
                    prefix={<UserOutlined />}
                    type="string"
                    size="large"
                    disabled={disabled}
                  />
                )}
              </Form.Item>
              <Form.Item label="Last Name">
                {getFieldDecorator("last_name", {
                  rules: [
                    { required: true, message: "Please input your last name." },
                  ],
                  initialValue: user.last_name,
                })(
                  <Input
                    prefix={<UserOutlined />}
                    type="string"
                    size="large"
                    disabled={disabled}
                  />
                )}
              </Form.Item>
              <Form.Item
                label={
                  <span>
                    Alias&nbsp;
                    <Tooltip
                      title="This will be the display name for user activities"
                      placement="rightTop"
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator("alias", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your first name.",
                    },
                  ],
                  initialValue: user.alias,
                })(
                  <Input
                    prefix={<UserOutlined />}
                    type="string"
                    size="large"
                    disabled={disabled}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label="Email">
                {getFieldDecorator("email", {
                  rules: [
                    {
                      type: "email",
                      required: true,
                      message: "Please enter a valid email.",
                    },
                  ],
                  initialValue: user.email,
                })(
                  <Input
                    prefix={<MailOutlined />}
                    type="email"
                    size="large"
                    disabled={disabled}
                  />
                )}
              </Form.Item>
              <Form.Item label="Phone">
                {getFieldDecorator("phone", {
                  rules: [
                    {
                      required: false,
                      message: "Please input your last name.",
                    },
                  ],
                  initialValue: user.phone,
                })(
                  <Input
                    prefix={<PhoneOutlined />}
                    type="string"
                    size="large"
                    disabled={disabled}
                  />
                )}
              </Form.Item>
              <Row>
                <Col span={12} className={styles.subInput}>
                  <Form.Item
                    label={
                      <span>
                        Role&nbsp;
                        <Tooltip
                          title="The 'USER' role has a limited set of permissions, e.g. the role cannot add new users or configure the app. Note this can be changed at any time."
                          placement="rightTop"
                        >
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </span>
                    }
                  >
                    {getFieldDecorator("role", {
                      rules: [
                        { required: true, message: "The user needs a role." },
                      ],
                      initialValue: user.role,
                    })(
                      <Radio.Group
                        disabled={disabled || currentUserRole === "USER"}
                      >
                        <Radio value={"USER"}>USER</Radio>
                        <Radio value={"ADMIN"}>ADMIN</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12} className={styles.subInput}>
                  <Form.Item label="Is Active">
                    {getFieldDecorator("active", {
                      rules: [
                        {
                          required: true,
                          message: "The user must have a state",
                        },
                      ],
                      initialValue: user.active,
                      valuePropName: "checked",
                    })(<Checkbox size="large" disabled={disabled} />)}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      );
    }
  }
);

class UserDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      user: {},
      isEditUser: false,
      isChangePassword: false,
    };
  }

  componentDidMount() {
    this.loadUser();
  }

  componentDidUpdate() {
    if (this.state.user.id !== parseInt(this.props.params.id)) {
      this.loadUser();
    }
  }

  loadUser() {
    const userId = this.props.params.id;
    apiBase
      .get(`/users/${userId}`)
      .then((res) => {
        this.setState({ user: res.data });
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to load User ${userId}`;
        message.error(msg);
      });
  }

  userUpdateSubmit = (e) => {
    const form = this.userUpdateFormRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      } else {
        this.setState({ isLoading: true });
        apiBase
          .patch(`users/${this.state.user.id}`, values)
          .then((res) => {
            this.setState({
              user: res.data,
              isEditUser: false,
              isLoading: false,
            });
            let msg = `${res.status}: Successfully updated user.`;
            message.success(msg);
          })
          .catch((err) => {
            let msg = `${err.response.status}: ${err.response.data.message}`;
            message.error(msg);
            this.setState({ isLoading: false });
            form.resetFields();
          });
      }
    });
  };

  changePasswordSubmit = (e) => {
    const form = this.changePasswordFormRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      } else {
        this.setState({ isLoading: true });
        apiBase
          .patch(`users/${this.state.user.id}/change-password`, values)
          .then((res) => {
            this.setState({
              isChangePassword: false,
              isLoading: false,
            });
            let msg = `${res.status}: Successfully changed password.`;
            message.success(msg);
            form.resetFields();
          })
          .catch((err) => {
            let msg = `${err.response.status}: ${err.response.data.message}`;
            message.error(msg);
            this.setState({ isLoading: false });
            form.resetFields();
          });
      }
    });
  };

  toggleUserEdit() {
    this.setState({ isEditUser: !this.state.isEditUser });
  }

  showChangePassword = () => {
    this.setState({ isChangePassword: true });
  };

  cancelChangePassword = () => {
    const form = this.changePasswordFormRef.props.form;
    this.setState(
      {
        isChangePassword: false,
      },
      () => form.resetFields()
    );
  };

  cancelEdit() {
    const form = this.userUpdateFormRef.props.form;
    this.setState(
      {
        isEditUser: false,
      },
      () => form.resetFields()
    );
  }

  saveUpdateFormRef = (userUpdateFormRef) => {
    this.userUpdateFormRef = userUpdateFormRef;
  };

  saveChangePasswordFormRef = (changePasswordFormRef) => {
    this.changePasswordFormRef = changePasswordFormRef;
  };

  render() {
    return (
      <div className="App__rowContentDetail">
        <ChangePasswordForm
          wrappedComponentRef={this.saveChangePasswordFormRef}
          visible={this.state.isChangePassword}
          onCancel={this.cancelChangePassword}
          onSubmit={this.changePasswordSubmit}
        />
        <Row>
          <Col span={24}>
            <div className={styles.card}>
              <div className={styles.cardTitle}>
                <div className={styles.leftTitle}>
                  <h2>User</h2>
                </div>
                <div className={styles.rightTitle}>
                  {!this.state.isEditUser ? (
                    <div>
                      <Button
                        onClick={this.showChangePassword}
                        className={styles.cardHeaderAction}
                      >
                        Change Password
                      </Button>
                      <Button
                        onClick={this.toggleUserEdit.bind(this)}
                        className={styles.cardHeaderAction}
                      >
                        Edit
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <Button
                        onClick={this.cancelEdit.bind(this)}
                        className={styles.cardHeaderAction}
                        disabled={this.state.isLoading}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={this.userUpdateSubmit.bind(this)}
                        className={styles.cardHeaderAction}
                        type="primary"
                        loading={this.state.isLoading}
                      >
                        Save
                      </Button>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.cardBody}>
                <Row gutter={24}>
                  <Col span={24}>
                    <UserUpdateForm
                      wrappedComponentRef={this.saveUpdateFormRef}
                      user={this.state.user}
                      currentUserRole={this.props.currentUserRole}
                      isEditUser={this.state.isEditUser}
                      userUpdateSubmit={this.userUpdateSubmit}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withParams(UserDetails);
