import React from "react";
import { useSelector } from "react-redux";
import { get, isEmpty, find } from "lodash";
import { guid } from "../../utils/numbers";
import { LabelIcon } from "./LabelIcon";

import styles from "./RecordLabels.module.scss";

function RecordLabels({ record_type, record_id, label_ids = [] }) {
  const labels = useSelector((state) => get(state.labels, "data", []));

  if (isEmpty(label_ids)) {
    return null;
  }

  // Find the labels that match the provided label_ids
  const matchedLabels = label_ids
    .map((id) => find(labels, (label) => label.id === id))
    .filter(Boolean)
    .sort((a, b) => a.order - b.order);

  if (isEmpty(matchedLabels)) {
    return null;
  }

  return (
    <div className={styles.container}>
      {matchedLabels.map((labelObj) => {
        const content = get(labelObj, "value", "Empty");
        const color = get(labelObj, "color", "white");
        const backgroundColor = get(labelObj, "background_color", "gray");

        return (
          <div className={styles.label} key={guid()}>
            <LabelIcon
              content={content}
              color={color}
              backgroundColor={backgroundColor}
            />
          </div>
        );
      })}
    </div>
  );
}

export default RecordLabels;
