import React, { useState, useEffect, useCallback } from "react";

import { apiBase } from "../../utils/apiBase";
import { withParams } from "../../utils/withParams";
import { Helmet } from "react-helmet";
import { get, omit } from "lodash";
import { clsx } from "clsx";

import { Row, Col, Table } from "antd";
import { Link } from "react-router-dom";
import ReactJson from "react-json-view";

import TotalRecords from "../search/TotalRecords";

import { message } from "antd";

import styles from "./USPSAddress.module.scss";

const USPSAddress = (props) => {
  const [loading, setLoading] = useState(false);
  const [uspsAddress, setUSPSAddress] = useState({});

  const getUSPSAddress = useCallback(async () => {
    try {
      setLoading(true);
      const res = await apiBase.get(`/usps-addresses/${props.params.id}`);
      setUSPSAddress(get(res, "data", {}));
    } catch (err) {
      let msg = `${err.response.status}: Failed to load USPS address ${props.params.id}`;
      message.error(msg);
    } finally {
      setLoading(false);
    }
  }, [props.params.id]);

  useEffect(() => {
    getUSPSAddress();
  }, [getUSPSAddress]);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (value) => <Link to={`/owners/${value}`}>{value}</Link>,
    },
  ];

  const owners = get(uspsAddress, "owners", []);

  return (
    <div className="App__rowContent">
      <Helmet>
        <title>{`USPSAddress: ${uspsAddress.street}`}</title>
      </Helmet>
      <Row>
        <Col span={24}>
          <div
            className={clsx({
              [styles.jsonView]: true,
              [styles.loading]: loading,
            })}
          >
            <ReactJson
              src={omit(uspsAddress, ["owners"])}
              collapsed={1}
              style={{
                fontSize: "12px",
              }}
            />
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: "1em" }}>
        <Col span={24}>
          <div className={`${styles.card} ${styles.owners}`}>
            <div className={styles.cardTitle}>
              <div className={styles.leftTitle}>
                <h2>Owners</h2>
              </div>
              <div className={styles.rightTitle}>
                <TotalRecords title={"Owners"} totalRecords={owners.length} />
              </div>
            </div>
            <div className={styles.cardBody}>
              <Table
                columns={columns}
                dataSource={owners}
                pagination={false}
                className="App__smallTables"
                showSorterTooltip={false}
                loading={loading}
                scroll={{ x: "100%" }}
                bordered
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withParams(USPSAddress);
