import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { apiBase } from "../../utils/apiBase";
import { formatNumber } from "../../utils/numbers";
import clsx from "clsx";

import BoxSearchSelect from "../common/BoxSearchSelect";

import {
  Modal,
  Form,
  Input,
  InputNumber,
  Button,
  DatePicker,
  message,
} from "antd";

import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

import styles from "./AssetSaleCreateForm.module.scss";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const AssetSaleCreateForm = (props) => {
  const { asset } = props;

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [validationError, setValidationError] = useState(null);
  const [totalFractionConveyed, setTotalFractionConveyed] = useState(
    (1 - asset.decimal_interest_current / asset.decimal_interest) * 100
  );
  const [remainingDecimalInterest, setRemainingDecimalInterest] = useState(
    asset.decimal_interest_current
  );
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    setRemainingDecimalInterest(asset.decimal_interest_current);
    setTotalFractionConveyed(
      (1 - asset.decimal_interest_current / asset.decimal_interest) * 100
    );
  }, [asset]);

  const onFinish = async (values) => {
    setLoading(true);
    apiBase
      .post(`assets/${asset.id}/sales`, {
        ...values,
        amount: totalAmount,
      })
      .then(async (res) => {
        message.success("Successfully created asset sale.");
        resetComponent();
        setIsModalOpen(false);
        props.getAsset();
        props.getAssetSales();
      })
      .catch((err) => {
        console.log(err);
        let msg = `${err.response.status}: Failed to create asset sale.`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    resetComponent();
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    resetComponent();
  };

  const validateAggregates = (values) => {
    let _validationError = null;
    if (parseFloat(totalFractionConveyed) - parseFloat(100) > 0.00000001) {
      _validationError =
        "The sum of fraction conveyed can't be greater than 100%.";
    }

    if (parseFloat(remainingDecimalInterest).toFixed(8) < -0.00000001) {
      _validationError =
        "The remaining decimal interest cannot be less than 0.";
    }

    if (_validationError) {
      message.warning("There are validation errors.");
    }

    setValidationError(_validationError);
    return _validationError;
  };

  const submitForm = () => {
    form
      .validateFields()
      .then((values) => {
        const _validationError = validateAggregates(values);
        if (_validationError) {
          return;
        }

        form.submit();
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };

  const resetComponent = () => {
    form.resetFields();
  };

  const onFormChange = async (changedValues, allValues) => {
    const { lines } = allValues;

    if (validationError) {
      setValidationError(null);
    }

    let _totalFractionConveyed =
      (1 - asset.decimal_interest_current / asset.decimal_interest) * 100;
    let _remaningDecimalInterest = asset.decimal_interest_current;
    let _totalAmount = 0;

    lines
      .filter((line) => !isEmpty(line))
      .forEach((line, index) => {
        const decimalIntrest = line.fraction_conveyed
          ? (line.fraction_conveyed / 100) * asset.decimal_interest
          : 0;

        _totalFractionConveyed += line.fraction_conveyed
          ? line.fraction_conveyed
          : 0;
        _remaningDecimalInterest -= decimalIntrest;
        _totalAmount += line.amount ? line.amount : 0;
        lines[index].decimal_interest = decimalIntrest;
      });

    form.setFieldsValue({
      lines: lines,
    });

    setTotalFractionConveyed(_totalFractionConveyed);
    setRemainingDecimalInterest(_remaningDecimalInterest);
    setTotalAmount(_totalAmount);
  };

  const [form] = Form.useForm();

  return (
    <div>
      <Button type="secondary" icon={<PlusOutlined />} onClick={showModal} />
      <Modal
        title="Create asset sale"
        width={1200}
        visible={isModalOpen}
        onOk={() => submitForm()}
        onCancel={handleCancel}
        cancelButtonProps={{ disabled: loading }}
        okButtonProps={{ disabled: loading, loading: loading }}
        maskClosable={false}
      >
        <Form
          name="createAssetSale"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onFormChange}
          autoComplete="off"
          layout="vertical"
          disabled={loading}
          initialValues={{
            lines: [],
          }}
        >
          <Form.Item
            label="Effective date"
            name="effective_date"
            rules={[
              {
                required: true,
                message: "Effective date is required.",
              },
            ]}
          >
            <DatePicker
              className="App__fullWidth"
              format={[
                "M/DD/YY",
                "M/D/YY",
                "MM/D/YY",
                "M/DD/YYYY",
                "M/D/YYYY",
                "MM/D/YYYY",
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Sale date"
            name="sale_date"
            rules={[
              {
                required: true,
                message: "Sale date is required.",
              },
            ]}
          >
            <DatePicker
              className="App__fullWidth"
              format={[
                "M/DD/YY",
                "M/D/YY",
                "MM/D/YY",
                "M/DD/YYYY",
                "M/D/YYYY",
                "MM/D/YYYY",
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Energy net fee percentage"
            name="energy_net_fee_percentage"
            rules={[
              {
                required: false,
                message: "",
                type: "number",
                max: 100,
                min: 0,
              },
            ]}
          >
            <InputNumber
              precision={6}
              addonAfter={"%"}
              placeholder="0.00"
              className="App__fullWidth"
            />
          </Form.Item>
          <Form.Item label="Box folder" name="box_folder_id">
            <BoxSearchSelect
              searchUri="box/folder/search"
              placeholder="Search box folder"
            />
          </Form.Item>
          <div className={`${styles.section} ${styles.compactList}`}>
            <Form.Item shouldUpdate={true} noStyle>
              {() => {
                return (
                  <Form.List
                    name="lines"
                    rules={[
                      {
                        validator: async (_, names) => {
                          if (!names || names.length < 1) {
                            return Promise.reject(
                              setValidationError(
                                "At least one line is required."
                              )
                            );
                          }
                        },
                      },
                    ]}
                  >
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          {fields.map(({ key, name, ...restField }) => {
                            return (
                              <div key={key}>
                                <div className={styles.line}>
                                  <Form.Item
                                    {...restField}
                                    label="Grantee"
                                    name={[name, "grantee"]}
                                    className={styles.grantee}
                                    rules={[
                                      {
                                        required: true,
                                        message: "",
                                      },
                                    ]}
                                  >
                                    <Input placeholder="Grantee" />
                                  </Form.Item>
                                  <Form.Item
                                    {...restField}
                                    label="Sale Code"
                                    name={[name, "sale_code"]}
                                    rules={[
                                      {
                                        required: false,
                                      },
                                    ]}
                                  >
                                    <Input placeholder="Sale code" />
                                  </Form.Item>
                                  <Form.Item
                                    {...restField}
                                    className={styles.childAmount}
                                    label="Fraction conveyed"
                                    name={[name, "fraction_conveyed"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "",
                                        type: "number",
                                        max: 100,
                                        min: 0,
                                      },
                                    ]}
                                  >
                                    <InputNumber
                                      precision={10}
                                      addonAfter={"%"}
                                      placeholder="0.00"
                                      className="App__fullWidth"
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    {...restField}
                                    className={styles.childAmount}
                                    label="Decimal Interest"
                                    name={[name, "decimal_interest"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "",
                                      },
                                    ]}
                                  >
                                    <InputNumber
                                      disabled={true}
                                      className={clsx("App__fullWidth", {
                                        [styles.disabledItem]: true,
                                      })}
                                      precision={8}
                                      placeholder="0.00"
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    {...restField}
                                    className={styles.childAmount}
                                    label="Amount "
                                    name={[name, "amount"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "",
                                      },
                                    ]}
                                  >
                                    <InputNumber
                                      precision={2}
                                      prefix={"$"}
                                      placeholder="0.00"
                                      className="App__fullWidth"
                                    />
                                  </Form.Item>
                                  <div className={styles.minusIcon}>
                                    <MinusCircleOutlined
                                      onClick={() => remove(name)}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                          <Form.Item className={styles.addLine}>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                            >
                              Add line
                            </Button>
                          </Form.Item>
                          <div
                            className={`${styles.section} ${styles.validationSection}`}
                          >
                            <div>
                              <div>Current DOI</div>
                              <div>{`${formatNumber(
                                asset.decimal_interest,
                                "0.00000000"
                              )}`}</div>
                            </div>
                            <div>
                              <div>Remaining DOI</div>
                              <div
                                className={
                                  parseFloat(remainingDecimalInterest).toFixed(
                                    8
                                  ) < 0
                                    ? styles.error
                                    : ""
                                }
                              >{`${formatNumber(
                                remainingDecimalInterest,
                                "0.00000000"
                              )}`}</div>
                            </div>
                            <div>
                              <div>Total fraction conveyed</div>
                              <div
                                className={
                                  parseFloat(remainingDecimalInterest).toFixed(
                                    8
                                  ) > 100
                                    ? styles.error
                                    : ""
                                }
                              >{`${formatNumber(
                                totalFractionConveyed,
                                "0.000000"
                              )}%`}</div>
                            </div>
                            <div>
                              <div>Total amount</div>
                              <div>{`$${formatNumber(
                                totalAmount,
                                "0,0.00"
                              )}`}</div>
                            </div>
                          </div>
                        </>
                      );
                    }}
                  </Form.List>
                );
              }}
            </Form.Item>
            {validationError && (
              <div className={styles.section}>
                <div className={styles.wellAllocationsSection}>
                  Validation errors:
                </div>
                <div className={styles.error}>{validationError}</div>
              </div>
            )}
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetSaleCreateForm);
