import { useState, useEffect } from "react";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { apiBase } from "../../../../utils/apiBase";
import mapActions from "../../../../actions/map";

import * as turf from "@turf/turf";
import clsx from "clsx";
import { SaveOutlined } from "@ant-design/icons";

import styles from "./BasePopup.module.scss";

const PermitPlatPopup = ({ currentFeature }) => {
  const { properties } = currentFeature;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [permitPlat, setPermitPlat] = useState(null);

  useEffect(() => {
    setLoading(true);
    apiBase
      .get(`/permit-plats/${properties.id}`)
      .then((res) => {
        setPermitPlat(res.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [properties.id]);

  const saveGeometry = () => {
    if (!permitPlat || !permitPlat.geom_polygon) return;

    const features = turf.feature(permitPlat.geom_polygon);
    dispatch(mapActions.setDraw(null, { savedGeometry: features }));
  };

  return (
    <>
      <div
        className={clsx(styles.properties, {
          [styles.loading]: loading,
        })}
      >
        {Object.entries(properties).map(([key, value]) => (
          <div className={styles.property} key={key}>
            <strong>{key}:</strong>
            {key === "id" ? (
              <a
                className="App__ml-5"
                href={`/permit-plats/${value}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {value}
              </a>
            ) : (
              <span className="App__ml-5">{value}</span>
            )}
          </div>
        ))}
      </div>
      <hr className={styles.separator} />
      <div className={styles.buttons}>
        <Button
          type="secondary"
          size="small"
          onClick={saveGeometry}
          disabled={loading}
        >
          <SaveOutlined />
        </Button>
      </div>
    </>
  );
};
export default PermitPlatPopup;
