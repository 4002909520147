import React, { Component } from "react";
import { withParams } from "../utils/withParams";
import { apiBase } from "../utils/apiBase";
import { formatNumber, alphaSort, formatCSVData } from "../utils/numbers";
import { formatDate } from "../utils/dates";
import { get } from "lodash";
import TotalRecords from "./search/TotalRecords";
import { CSVLink } from "react-csv";

import { DeleteOutlined } from "@ant-design/icons";

import { Row, Col, Table, Button, Select, message } from "antd";

import styles from "./OfferDetail.module.scss";

const csvHeaders = [
  { label: "id", key: "id" },
  { label: "unit_id", key: "unit_id" },
  { label: "owner_id", key: "owner_id" },
  { label: "owner.name_formatted", key: "owner.name_formatted" },
  { label: "unit.name_formatted", key: "unit.name_formatted" },
  { label: "unit.county", key: "unit.county" },
  { label: "unit.operator", key: "unit.operator" },
  { label: "owner.city", key: "owner.city" },
  { label: "owner.state", key: "owner.state" },
  { label: "owner.zip_code", key: "owner.zip_code" },
  { label: "interest_type", key: "interest_type" },
  { label: "decimal_interest", key: "decimal_interest" },
  { label: "nra", key: "nra" },
  { label: "offer_amount", key: "offer_amount" },
  { label: "status", key: "status" },
  { label: "user.alias", key: "user.alias" },
  { label: "tax_year", key: "tax_year" },
];

class OfferDetail extends Component {
  state = {
    isLoading: false,
    offer: {},
    leads: [],
    recordsPerPage: 25,
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    await Promise.all([this.getOffer(), this.getOfferLeads()]);
    this.setState({ isLoading: false });
  }

  async getOffer() {
    return apiBase
      .get(`/offers/${this.props.params.id}`)
      .then((res) => {
        this.setState({
          offer: res.data,
        });
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: Error loading Offer: ${
          this.props.params.id
        }`;
        message.error(msg);
      });
  }

  async getOfferLeads() {
    return apiBase
      .get(`/offers/${this.props.params.id}/leads`)
      .then((res) => {
        this.setState({
          leads: res.data.map((el, idx) => ({ ...el, ...{ key: idx } })),
        });
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: Error loading offer: ${
          this.props.params.id
        } leads`;
        message.error(msg);
      });
  }

  deleteLead = (leadId) => {
    this.setState({ isLoading: true });
    apiBase
      .delete(`/leads/${leadId}`)
      .then((res) => {
        let leads = this.state.leads;
        leads = leads.filter((lead) => lead.id !== leadId);
        this.setState({
          leads: leads.map((el, idx) => ({ ...el, ...{ key: idx } })),
          isLoading: false,
        });
        let msg = `${res.status}: ${res.data.message}`;
        message.success(msg);
        this.getOffer();
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: Failed to delete lead.`;
        message.error(msg);
        this.setState({ isLoading: false });
      });
  };

  handleRowClick(e, _to) {
    e.stopPropagation();
    if (e.ctrlKey || e.metaKey) {
      window.open(_to, "_blank");
      return;
    }
    this.props.history(_to);
  }

  handleRecordsChange(value) {
    this.setState({
      recordsPerPage: value,
    });
  }

  render() {
    const columns = [
      {
        title: "Lead",
        dataIndex: "id",
        className: `App__rowLink App__w6`,
        render: (value) => <div className="App__rowIcon">{value}</div>,
        onCell: (record) => ({
          onClick: (e) => this.handleRowClick(e, `/leads/${record["id"]}`),
        }),
        width: "45px",
      },
      {
        title: "Owner",
        dataIndex: "owner_id",
        className: `App__rowLink App__w8`,
        render: (value) => <div className="App__rowIcon">{value}</div>,
        onCell: (record) => ({
          onClick: (e) =>
            this.handleRowClick(e, `/owners/${record["owner_id"]}`),
        }),
        width: "45px",
      },
      {
        title: "Owner Name",
        dataIndex: ["owner", "name_formatted"],
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) =>
          alphaSort(a.owner.name_formatted, b.owner.name_formatted),
      },
      {
        title: "Unit Name",
        dataIndex: ["unit", "name_formatted"],
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) =>
          alphaSort(a.unit.name_formatted, b.unit.name_formatted),
      },
      {
        title: "Unit County",
        dataIndex: ["unit", "county"],
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => alphaSort(a.unit.county, b.unit.county),
      },
      {
        title: "Operator",
        dataIndex: ["unit", "operator"],
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => alphaSort(a.unit.operator, b.unit.operator),
      },
      {
        title: "Address",
        dataIndex: ["owner", "address_formatted"],
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) =>
          alphaSort(a.owner.address_formatted, b.owner.address_formatted),
      },
      {
        title: "City",
        dataIndex: ["owner", "city"],
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => alphaSort(a.owner.city, b.owner.city),
      },
      {
        title: "State",
        dataIndex: ["owner", "state"],
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => alphaSort(a.owner.state, b.owner.state),
      },
      {
        title: "Zip",
        dataIndex: ["owner", "zip_code"],
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => alphaSort(a.owner.zip_code, b.owner.zip_code),
      },
      {
        title: "Interest Type",
        dataIndex: "interest_type",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => alphaSort(a.interest_type, b.interest_type),
      },
      {
        title: "DOI",
        dataIndex: "decimal_interest",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => a.decimal_interest - b.decimal_interest,
      },
      {
        title: "NRA",
        dataIndex: "nra",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => a.nra - b.nra,
        render: (value) =>
          value !== null ? formatNumber(value, "0.00") : null,
      },
      {
        title: "Offer Amount",
        dataIndex: "offer_amount",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => a.offer_amount - b.offer_amount,
        render: (value) =>
          value !== null ? `$${formatNumber(value, "0,0.00")}` : null,
      },
      {
        title: "Lead Status",
        dataIndex: "status",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => alphaSort(a.status, b.status),
      },
      {
        title: "Tax year",
        dataIndex: "tax_year",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => a.tax_year - b.tax_year,
      },
      {
        title: "",
        dataIndex: "id",
        key: "actions",
        render: (value) => (
          <Button
            shape="circle"
            icon={<DeleteOutlined />}
            size="small"
            onClick={() => this.deleteLead(value)}
            loading={this.state.isLoading}
          />
        ),
      },
    ];

    return (
      <div className="App__rowContentDetail">
        <Row>
          <Col span={24}>
            <div className={styles.card}>
              <div className={styles.cardTitle}>
                <div className={styles.leftTitle}>
                  <h2>Offer</h2>
                </div>
                <div className={styles.rightTitle} />
              </div>
              <div className={styles.cardBody}>
                <Row gutter={24}>
                  <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className={styles.objectData}>
                      <span>Id:</span>
                      {this.state.offer.id}
                    </div>
                    <div className={styles.objectData}>
                      <span>Unit Id:</span>
                      <span
                        className={styles.objectLink}
                        onClick={(e) => {
                          this.handleRowClick(
                            e,
                            `/units/${this.state.offer.unit_id}`
                          );
                        }}
                      >
                        {this.state.offer.unit_id}
                      </span>
                    </div>
                    <div className={styles.objectData}>
                      <span>Created By:</span>
                      {get(this.state.offer, "user.alias")}
                    </div>
                    <div className={styles.objectData}>
                      <span>Created At:</span>
                      {formatDate(
                        this.state.offer.created_at,
                        "YYYY-MM-DD, h:mm:ss A"
                      )}
                    </div>
                  </Col>
                  <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className={styles.objectData}>
                      <span>Unit Size (Acres):</span>
                      {this.state.offer.acres}
                    </div>
                    <div className={styles.objectData}>
                      <span>Offer Per NRA ($)</span>
                      {`$${formatNumber(
                        this.state.offer.dollar_per_nra,
                        "0,0.00"
                      )}`}
                    </div>
                    <div className={styles.objectData}>
                      <span>Total Lead Offers ($):</span>
                      {`$${formatNumber(
                        this.state.offer.total_lead_offers,
                        "0,0.00"
                      )}`}
                    </div>
                    <div className={styles.objectData}>
                      <span>Number Lead Offers:</span>
                      {this.state.offer.number_lead_offers}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className={styles.card}>
              <div className={styles.cardTitle}>
                <div className={styles.leftTitle}>
                  <h2>Offer Leads</h2>
                </div>
                <div className={styles.rightTitle}>
                  <CSVLink
                    headers={csvHeaders}
                    data={formatCSVData(this.state.leads)}
                    filename={`offer-leads-${new Date().toJSON()}.csv`}
                    className={styles.csvDownload}
                  >
                    <i className="fas fa-download" />
                  </CSVLink>
                  <Select
                    defaultValue={25}
                    onChange={this.handleRecordsChange.bind(this)}
                    className={"App__mr-5"}
                  >
                    <Select.Option value={10}>10</Select.Option>
                    <Select.Option value={25}>25</Select.Option>
                    <Select.Option value={50}>50</Select.Option>
                    <Select.Option value={100}>100</Select.Option>
                  </Select>
                  <TotalRecords
                    title={"Leads"}
                    totalRecords={this.state.leads.length}
                  />
                </div>
              </div>
              <div className={styles.cardBody}>
                <Table
                  columns={columns}
                  dataSource={this.state.leads}
                  pagination={{
                    pageSize: this.state.recordsPerPage,
                    showSizeChanger: false,
                  }}
                  className="App__smallTables"
                  showSorterTooltip={false}
                  loading={this.state.isLoading}
                  scroll={{ x: "100%" }}
                  bordered
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withParams(OfferDetail);
