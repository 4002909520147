import React, { useState, useRef } from "react";
import axios from "axios";
import ReactJson from "react-json-view";
import { apiBase } from "../../utils/apiBase";
import { get } from "lodash";

import { humanFileSize, guid } from "../../utils/numbers";
import {
  getInitialColumnMappings,
  columnOptions,
} from "./UploadGenericFileForm.utils";

import {
  Button,
  Modal,
  Upload,
  Form,
  Progress,
  Select,
  Typography,
  Input,
  Row,
  Col,
  message,
} from "antd";

import {
  InboxOutlined,
  SyncOutlined,
  CheckCircleTwoTone,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

import styles from "./UploadGenericFileForm.module.scss";

const fileDetailsInitial = {
  data: null,
  loading: false,
  error: null,
};

const { Text } = Typography;

const UploadGenericFileForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [progress, setProgress] = useState(null);
  const [uploadingUID, setUploadingUID] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [fileSizeLoaded, setFileSizeLoaded] = useState(false);
  const [fileSizeTotal, setFileSizeTotal] = useState(false);
  const [fileDetails, setFileDetails] = useState(fileDetailsInitial);
  const [fileValidationErrors, setFileValidationErrors] = useState([]);

  const axiosSource = useRef();
  const [form] = Form.useForm();

  const validateFile = async (values) => {
    try {
      const res = await apiBase.post(`generic-files/validate`, {
        column_mapping: values?.columns,
        s3_key: values?.file?.s3Key,
      });
      const _fileValidationErrors = get(res, "data.errors", []);
      setFileValidationErrors(_fileValidationErrors);
      if (_fileValidationErrors.length > 0) {
        message.warning("There are file validation errors.");
      } else {
        message.success("Successfully validated file.");
      }
      return _fileValidationErrors;
    } catch (err) {
      let msg = `${err.response.status}: Failed to validate file`;
      message.error(msg);
      return false;
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    const _fileValidationErrors = await validateFile(values);
    if (_fileValidationErrors.length > 0 || !_fileValidationErrors) {
      setLoading(false);
      return;
    }

    apiBase
      .post(`generic-files`, {
        name: values?.name,
        description: values?.description,
        file_name: values?.file?.name,
        file_metadata: fileDetails,
        column_mapping: values?.columns,
        s3_key: values?.file?.s3Key,
      })
      .then(async (res) => {
        // console.log(res);
        // await new Promise((resolve) => setTimeout(resolve, 2000));
        message.success("Successfully created generics upload.");
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to create generics upload`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
        resetComponent();
        setIsModalOpen(false);
        props.executeSearch();
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    resetComponent();
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    resetComponent();
  };

  const submitForm = () => {
    form
      .validateFields()
      .then(() => {
        form.submit();
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };

  const createUploadUrl = (values) => {
    return apiBase
      .post(`files/upload-url`, values)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to create upload url`;
        message.error(msg);
        return false;
      });
  };

  const getFileDetails = (s3Key) => {
    return apiBase
      .post(`files/details`, { key: s3Key })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to get file details`;
        message.error(msg);
        return false;
      });
  };

  const beforeUpload = (file) => {
    // const isDelimited = file.type === "text/csv" || file.type === "text/tsv";
    // if (!isDelimited) {
    //   message.error("This isn't a delimited file");
    // }

    const isLt100Mb = file.size / 1024 / 1024 < 100;
    if (!isLt100Mb) {
      message.error("File must me smaller than 100Mb");
    }

    return isLt100Mb;
  };

  const uploadProgress = (e) => {
    setProgress(Math.floor((e.loaded / e.total) * 100));
    setFileSizeLoaded(humanFileSize(e.loaded, true, 2));
    setFileSizeTotal(humanFileSize(e.total, true, 2));
  };

  const uploadFile = async (options) => {
    const { onSuccess, onError, file } = options;

    if (file.uid !== uploadingUID && uploadingUID !== null) {
      axiosSource.current.cancel();
    }

    axiosSource.current = axios.CancelToken.source();

    const name = get(file, "name");
    form.setFieldValue("name", name);

    setFileName(name);
    setUploadingUID(file.id);
    setProgress(0);

    // Upload URL
    const s3Key = `generics/${guid()}_${name}`;
    const response = await createUploadUrl({ key: s3Key });
    if (response === false) {
      return onError();
    }
    const uploadUrl = response.data;
    file.s3Key = uploadUrl.s3_key_full;
    delete uploadUrl.s3_key_full;

    // Form Data
    const formData = new FormData();
    Object.keys(uploadUrl.fields).forEach((key) => {
      formData.append(key, uploadUrl.fields[key]);
    });
    formData.append("file", file);

    const postConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: uploadProgress,
      cancelToken: axiosSource.current.token,
    };

    try {
      await axios.post(uploadUrl.url, formData, postConfig);
      form.setFieldsValue({ file });
      onSuccess({ file });
    } catch (err) {
      if (!axios.isCancel(err)) {
        onError();
        return;
      }
    }

    await fetchFileDetails(s3Key);
  };

  const fetchFileDetails = async (s3Key) => {
    setFileDetails({ ...fileDetails, loading: true });
    try {
      const results = await getFileDetails(s3Key);

      const _fileDetails = get(results, "data", {});
      let sampleObject = get(results, "data.sample[0]", {});
      let columnNames = get(results, "data.columns", {});
      if (columnNames.length === 0) {
        throw new Error("Failed to parse file");
      }

      const columns = getInitialColumnMappings(sampleObject);

      form.setFieldValue("columns", columns);
      // await new Promise((resolve) => setTimeout(resolve, 1000));

      setFileDetails({
        data: _fileDetails,
        loading: false,
        error: null,
      });
    } catch (err) {
      setFileDetails({
        data: null,
        loading: false,
        error: "Failed to load and parse data",
      });
    }
  };

  const resetComponent = () => {
    setFileName(null);
    setUploadingUID(null);
    setProgress(null);
    setFileSizeLoaded(null);
    setFileSizeTotal(null);
    setFileDetails(fileDetailsInitial);
    setFileValidationErrors([]);
    form.resetFields();
  };

  const cancelUpload = (e) => {
    e.preventDefault();
    if (axiosSource.current) {
      axiosSource.current.cancel();
    }
    form.setFieldsValue({ file: null });
    resetComponent();
  };

  const handleColumnChange = (column, value) => {
    const columns = form.getFieldValue("columns");
    const index = columns.findIndex(
      (c) => c.file_column_name === column.file_column_name
    );
    columns[index]["database_column_name"] = value;
    form.setFieldValue("columns", columns);
  };

  return (
    <div>
      <Button type="secondary" onClick={showModal}>
        Upload new file
      </Button>
      <Modal
        title="Upload new generic file"
        visible={isModalOpen}
        onOk={() => submitForm()}
        onCancel={handleCancel}
        width={"100%"}
        cancelButtonProps={{ disabled: loading }}
        okButtonProps={{ disabled: loading, loading: loading }}
        maskClosable={false}
      >
        <Form
          name="basic"
          form={form}
          initialValues={{
            file: null,
            columns: [],
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
          disabled={loading}
        >
          <Row gutter={16}>
            <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Name of generics file",
                  },
                ]}
              >
                <Input autoComplete="off" placeholder="Custom name of import" />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label="Description" name="description">
                <Input autoComplete="off" placeholder="Custom description" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="File"
            name="file"
            rules={[
              {
                required: true,
                message: "Please upload a file",
              },
            ]}
          >
            <>
              <div className={progress !== null ? styles.hide : null}>
                <Upload.Dragger
                  name="config"
                  customRequest={uploadFile}
                  beforeUpload={beforeUpload}
                  multiple={false}
                  maxCount={1}
                  listType="picture"
                  showUploadList={false}
                >
                  <>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                    <p className="ant-upload-hint">
                      Formats ('.csv', '.tsv', '.xls', '.xlsx', '.xlsb')
                    </p>
                    <p className="ant-upload-hint">Max file size: 100Mb</p>
                  </>
                </Upload.Dragger>
              </div>
              {progress !== null && (
                <div className={styles.fileContainer}>
                  <div className={styles.iconContainer}>
                    {progress < 100 ? (
                      <SyncOutlined className={styles.icon} spin />
                    ) : (
                      <CheckCircleTwoTone
                        twoToneColor="#52c41a"
                        className={styles.icon}
                      />
                    )}
                  </div>
                  <div className={styles.file}>
                    <div className={styles.spaced}>
                      <div>{`${fileName}`}</div>
                      <div>
                        <Button
                          onClick={(e) => cancelUpload(e)}
                          icon={<DeleteOutlined />}
                          size={"small"}
                          disabled={loading}
                        />
                      </div>
                    </div>
                    <Progress percent={progress} showInfo={false} />
                    <div className={`${styles.spaced} ${styles.gray}`}>
                      {fileSizeLoaded && fileSizeTotal ? (
                        <div>{`${fileSizeLoaded} of ${fileSizeTotal}`}</div>
                      ) : (
                        <div>Loading...</div>
                      )}
                      <div>{`${progress}%`}</div>
                    </div>
                  </div>
                </div>
              )}
            </>
          </Form.Item>
          <div className={styles.columnSection}>
            <div className={styles.columnSectionHeading}>Column mapping</div>
            {!!fileDetails.data ? (
              <Form.Item shouldUpdate={true} noStyle>
                {({ getFieldValue }) => {
                  const columns = getFieldValue("columns");
                  return (
                    <div className={styles.columnMapper}>
                      <div className={styles.columnMapperTitle}>
                        <div className={styles.title}>File column name</div>
                        <div className={styles.title}>File example</div>
                        <div className={styles.title}>Database column name</div>
                      </div>
                      <Form.List name="columns">
                        {(fields) => {
                          return (
                            <>
                              {fields.map((field) => {
                                return (
                                  <div
                                    key={field.key}
                                    className={styles.columnMapperRow}
                                  >
                                    <div className={styles.columnMapperCell}>
                                      <Text
                                        ellipsis={{ tooltip: true }}
                                        className={styles.text}
                                      >
                                        {get(
                                          columns[field.name],
                                          "file_column_name",
                                          null
                                        )}
                                      </Text>
                                    </div>
                                    <div className={styles.columnMapperCell}>
                                      <Text
                                        ellipsis={{ tooltip: true }}
                                        className={styles.text}
                                      >
                                        {get(
                                          columns[field.name],
                                          "file_example",
                                          null
                                        )}
                                      </Text>
                                    </div>
                                    <div className={styles.columnMapperCell}>
                                      <Form.Item
                                        {...field}
                                        name={[
                                          field.name,
                                          "database_column_name",
                                        ]}
                                        fieldKey={[
                                          field.fieldKey,
                                          "database_column_name",
                                        ]}
                                        key={[
                                          field.key,
                                          "database_column_name",
                                        ]}
                                      >
                                        <Select
                                          size="default"
                                          disabled={loading}
                                          showSearch
                                          placeholder="Select column"
                                          optionFilterProp="children"
                                          onChange={(value) =>
                                            handleColumnChange(
                                              columns[field.name],
                                              value
                                            )
                                          }
                                          filterOption={(input, option) =>
                                            option.value
                                              .toLowerCase()
                                              .indexOf(input.toLowerCase()) >= 0
                                          }
                                          notFoundContent={null}
                                          optionLabelProp="label"
                                          options={columnOptions}
                                          allowClear
                                        />
                                      </Form.Item>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          );
                        }}
                      </Form.List>
                    </div>
                  );
                }}
              </Form.Item>
            ) : (
              <div className={styles.columnMapperEmpty}>
                {fileDetails.loading ? (
                  <LoadingOutlined />
                ) : (
                  <>
                    {fileDetails.error ? (
                      <div className={styles.parseError}>
                        Failed to load and parse data
                      </div>
                    ) : (
                      <div>No file details</div>
                    )}
                  </>
                )}
              </div>
            )}
            {fileValidationErrors.length > 0 && (
              <div className={styles.validationErrors}>
                <div className={styles.columnSectionHeading}>
                  File validation errors
                </div>
                <ReactJson
                  src={fileValidationErrors}
                  collapsed={true}
                  style={{
                    backgroundColor: "white",
                    width: "484px",
                    fontSize: "12px",
                  }}
                  displayDataTypes={false}
                />
              </div>
            )}
          </div>
        </Form>
      </Modal>
    </div>
  );
};
export default UploadGenericFileForm;
