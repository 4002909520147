import React, { useState, useEffect, useCallback, memo, useMemo } from "react";
import moment from "moment";
import { capitalize, get } from "lodash";

import { apiBase } from "../../utils/apiBase";
import { formatNumber, calculatePercentageChange } from "../../utils/numbers";

import { Select, Empty } from "antd";

// ECharts import
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import { BarChart } from "echarts/charts";
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
} from "echarts/components";
import { SVGRenderer } from "echarts/renderers";

import styles from "./AssetCharts.module.scss";

// Register the required components
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  SVGRenderer,
]);

const PERIOD = {
  DAY: "day",
  WEEK: "week",
  MONTH: "month",
  QUARTER: "quarter",
  YEAR: "year",
};

const formatDateByPeriod = (date, period) => {
  const startDate = moment(date);

  switch (period) {
    case PERIOD.DAY:
      return startDate.format("MMM D, YYYY");

    case PERIOD.WEEK: {
      const endDate = moment(date).add(6, "days");
      const sameYear = startDate.year() === endDate.year();
      const sameMonth = startDate.month() === endDate.month();

      if (sameMonth && sameYear) {
        return `${startDate.format("MMM")} ${startDate.format(
          "D"
        )}-${endDate.format("D")}, ${startDate.format("YYYY")}`;
      } else if (sameYear) {
        return `${startDate.format("MMM D")}-${endDate.format(
          "MMM D"
        )}, ${startDate.format("YYYY")}`;
      } else {
        return `${startDate.format("MMM D, YYYY")}-${endDate.format(
          "MMM D, YYYY"
        )}`;
      }
    }

    case PERIOD.MONTH:
      return startDate.format("MMM, YYYY");

    case PERIOD.QUARTER: {
      const endDate = moment(date).add(2, "months");
      const sameYear = startDate.year() === endDate.year();

      return sameYear
        ? `${startDate.format("MMM")}-${endDate.format("MMM, YYYY")}`
        : `${startDate.format("MMM, YYYY")}-${endDate.format("MMM, YYYY")}`;
    }

    case PERIOD.YEAR:
      return startDate.format("YYYY");

    default:
      return startDate.format("MMM YYYY");
  }
};
const getChartOptions = (title, xValues, yValues) => {
  return {
    grid: {
      left: "3%",
      right: "15%",
      bottom: "10%",
      containLabel: true,
    },
    tooltip: {
      trigger: "axis",
      backgroundColor: "rgba(50, 50, 50, 0.9)",
      formatter: function (params) {
        const dataIndex = params[0].dataIndex;
        const period = xValues[dataIndex];
        const value = yValues[dataIndex];
        const previousPeriodValue = dataIndex > 0 ? yValues[dataIndex - 1] : 0;
        const comparedToPreviousPeriodPercentage = calculatePercentageChange(
          previousPeriodValue,
          value
        );

        let message = `Period: ${period}<br />Amount: $${formatNumber(
          value,
          "0,0.00"
        )}`;

        if (comparedToPreviousPeriodPercentage !== null) {
          message += `<br />Compared to previous period: ${
            comparedToPreviousPeriodPercentage > 0 ? "+" : ""
          }${formatNumber(comparedToPreviousPeriodPercentage, "0.00")}%`;
        }

        return message;
      },
      textStyle: {
        fontSize: 12,
        color: "#fff",
        fontWeight: 400,
        fontFamily: "Nunito Sans",
      },
    },
    xAxis: {
      data: xValues,
      min: 0,
      axisTick: {
        alignWithLabel: true,
      },
      name: "Period",
      nameLocation: "center",
      nameGap: 30,
      nameTextStyle: {
        fontSize: 14,
        color: "#000",
        fontWeight: 700,
        fontFamily: "Nunito Sans",
      },
      axisLabel: {
        textStyle: {
          fontSize: 12,
          color: "#000",
          fontWeight: 400,
          fontFamily: "Nunito Sans",
        },
      },
    },
    yAxis: {
      type: "value",
      splitNumber: 5,
      name: title,
      nameLocation: "center",
      nameGap: 70,
      nameTextStyle: {
        fontSize: 14,
        color: "#000",
        fontWeight: 700,
        fontFamily: "Nunito Sans",
      },
      axisLabel: {
        formatter: function (value) {
          return `$${formatNumber(value, "0,0.00")}`;
        },
        textStyle: {
          fontSize: 12,
          color: "#000",
          fontWeight: 400,
          fontFamily: "Nunito Sans",
        },
      },
    },
    series: [
      {
        data: yValues,
        type: "bar",
        label: {
          show: true,
          position: "top",
          valueAnimation: true,
          fontSize: 10,
          color: "#000",
          fontWeight: 400,
          fontFamily: "Nunito Sans",
          formatter: function (data) {
            return `$${formatNumber(data.value, "0,0.00")}`;
          },
        },
        labelLayout: {
          hideOverlap: true,
        },
      },
    ],
  };
};

const useChartOptions = (chartData, period, title) => {
  return useMemo(() => {
    if (!chartData?.length) return {};

    const xValues = chartData.map((r) => formatDateByPeriod(r.date, period));
    const yValues = chartData.map((r) => r.amount);

    return getChartOptions(title, xValues, yValues);
  }, [chartData, period, title]);
};

const Chart = ({ chartData, chartOptions }) => {
  return chartData?.length ? (
    <ReactEChartsCore
      echarts={echarts}
      option={chartOptions}
      notMerge={true}
      lazyUpdate={true}
      opts={{ renderer: "svg" }}
      autoResize
    />
  ) : (
    <div className={styles.emptyChart}>
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
  );
};

const AssetCharts = ({ assetId }) => {
  const [loading, setLoading] = useState(false);
  const [chartsTabKey, setChartsTabKey] = useState(1);
  const [royaltiesPeriod, setRoyaltiesPeriod] = useState(PERIOD.MONTH);
  const [salesPeriod, setSalesPeriod] = useState(PERIOD.MONTH);
  const [royaltiesPerPeriodChartData, setRoyaltiesPerPeriodChartData] =
    useState([]);
  const [salesPerPeriodChartData, setSalesPerPeriodChartData] = useState([]);

  const royaltiesPerPeriodChartOptions = useChartOptions(
    royaltiesPerPeriodChartData,
    royaltiesPeriod,
    "Royalties"
  );
  const salesPerPeriodChartOptions = useChartOptions(
    salesPerPeriodChartData,
    salesPeriod,
    "Sales"
  );

  const getRoyaltiesPerPeriodChartData = useCallback(async () => {
    if (!assetId) return;

    try {
      setLoading(true);
      const res = await apiBase.get(
        `/charts/asset/${assetId}/royalties?period=${royaltiesPeriod}`
      );
      setRoyaltiesPerPeriodChartData(get(res, "data", []));
    } catch (err) {
      // message.error(
      //   `${err.response.status}: Failed to load royalties per period chart data ${assetId}`
      // );
    } finally {
      setLoading(false);
    }
  }, [assetId, royaltiesPeriod]);

  const getSalesPerPeriodChartData = useCallback(async () => {
    if (!assetId) return;

    try {
      setLoading(true);
      const res = await apiBase.get(
        `/charts/asset/${assetId}/sales?period=${salesPeriod}`
      );
      setSalesPerPeriodChartData(get(res, "data", []));
    } catch (err) {
      // message.error(
      //   `${err.response.status}: Failed to load sales per period chart data ${assetId}`
      // );
    } finally {
      setLoading(false);
    }
  }, [assetId, salesPeriod]);

  useEffect(() => {
    getRoyaltiesPerPeriodChartData();
    getSalesPerPeriodChartData();
  }, [getRoyaltiesPerPeriodChartData, getSalesPerPeriodChartData]);

  return (
    <div className={styles.cardTabs}>
      <div className={styles.cardTitle}>
        <div className={styles.leftTitle}>
          <div
            className={chartsTabKey === 1 ? styles.isActive : null}
            onClick={() => setChartsTabKey(1)}
          >
            <h2>Royalties per period</h2>
          </div>
          <div
            className={chartsTabKey === 2 ? styles.isActive : null}
            onClick={() => setChartsTabKey(2)}
          >
            <h2>Sales per period</h2>
          </div>
        </div>
        <div className={styles.rightTitle}>
          {chartsTabKey === 1 ? (
            <Select
              value={royaltiesPeriod}
              onChange={(value) => setRoyaltiesPeriod(value)}
              disabled={loading || !royaltiesPerPeriodChartData?.length}
            >
              {Object.values(PERIOD).map((period) => (
                <Select.Option value={period} key={period}>
                  {capitalize(period)}
                </Select.Option>
              ))}
            </Select>
          ) : null}
          {chartsTabKey === 2 ? (
            <Select
              value={salesPeriod}
              onChange={(value) => setSalesPeriod(value)}
              disabled={loading || !salesPerPeriodChartData?.length}
            >
              {Object.values(PERIOD).map((period) => (
                <Select.Option value={period} key={period}>
                  {capitalize(period)}
                </Select.Option>
              ))}
            </Select>
          ) : null}
        </div>
      </div>
      <div className={styles.cardBody}>
        {chartsTabKey === 1 ? (
          <div className={styles.chartContainer}>
            <Chart
              chartData={royaltiesPerPeriodChartData}
              chartOptions={royaltiesPerPeriodChartOptions}
            />
          </div>
        ) : null}
        {chartsTabKey === 2 ? (
          <div className={styles.chartContainer}>
            <Chart
              chartData={salesPerPeriodChartData}
              chartOptions={salesPerPeriodChartOptions}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default memo(AssetCharts);
