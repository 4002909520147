import React from "react";
import { NavLink } from "react-router-dom";
import {
  CodeOutlined,
  SettingOutlined,
  ProfileOutlined,
  SyncOutlined,
  TeamOutlined,
  FormOutlined,
} from "@ant-design/icons";

import { Menu } from "antd";

import styles from "./Nav.module.scss";

const Settings = (props) => {
  const { collapsed, navSelectedKeys, currentUserRole, currentUserId } = props;

  return (
    <Menu
      theme="light"
      mode="inline"
      className={styles.siderMenu}
      selectedKeys={navSelectedKeys}
    >
      <Menu.ItemGroup key="s1" title={collapsed ? "" : "User management"}>
        <Menu.Item key="profile" className={styles.siderMenuItem}>
          <NavLink to={`/settings/users/${currentUserId}`}>
            <ProfileOutlined />
            <span>Profile</span>
          </NavLink>
        </Menu.Item>
        {currentUserRole !== "USER" && (
          <Menu.Item key="users" className={styles.siderMenuItem}>
            <NavLink to="/settings/users">
              <TeamOutlined />
              <span>Users</span>
            </NavLink>
          </Menu.Item>
        )}
      </Menu.ItemGroup>
      {currentUserRole !== "USER" && (
        <Menu.ItemGroup key="s2" title={collapsed ? "" : "Customization"}>
          <Menu.Item key="labels" className={styles.siderMenuItem}>
            <NavLink to="/settings/labels">
              <SettingOutlined />
              <span>Labels</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="crm_settings" className={styles.siderMenuItem}>
            <NavLink to="/settings/crm">
              <SettingOutlined />
              <span>CRM</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="portfolio_settings" className={styles.siderMenuItem}>
            <NavLink to="/settings/portfolio">
              <SettingOutlined />
              <span>Portfolio</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="mail_templates" className={styles.siderMenuItem}>
            <NavLink to="/settings/mail-templates?limit=50&page=1">
              <FormOutlined />
              <span>Mail templates</span>
            </NavLink>
          </Menu.Item>
        </Menu.ItemGroup>
      )}
      {currentUserRole !== "USER" && (
        <Menu.ItemGroup key="s3" title={collapsed ? "" : "Processes"}>
          <Menu.Item key="pipedrive_syncs" className={styles.siderMenuItem}>
            <NavLink to="/settings/pipedrive-syncs?limit=50&page=1">
              <SyncOutlined />
              <span>Pipedrive syncs</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="enverus_syncs" className={styles.siderMenuItem}>
            <NavLink to="/settings/enverus-syncs?limit=50&page=1">
              <SyncOutlined />
              <span>Enverus syncs</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="rrc_syncs" className={styles.siderMenuItem}>
            <NavLink to="/settings/rrc-syncs?limit=50&page=1">
              <SyncOutlined />
              <span>RRC syncs</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="jobs" className={styles.siderMenuItem}>
            <NavLink to="/settings/jobs">
              <CodeOutlined />
              <span>Jobs</span>
            </NavLink>
          </Menu.Item>
        </Menu.ItemGroup>
      )}
    </Menu>
  );
};
export default Settings;
