import React, { useState, useEffect } from "react";
import { Form, Table, Button, Modal } from "antd";
import { alphaSort } from "../../utils/numbers";
import { get } from "lodash";

import { DeleteOutlined, ExclamationCircleFilled } from "@ant-design/icons";

// import styles from "./OwnerGenerics.module.scss";

const { confirm } = Modal;

const OwnerGenerics = (props) => {
  const {
    columnExtras,
    generics,
    recordsPerPage,
    isLoadingLeads,
    deleteGeneric,
  } = props;

  const [form] = Form.useForm();
  const [data, setData] = useState(generics);

  useEffect(() => setData(generics), [generics]);

  const showDeleteConfirm = (genericId) => {
    confirm({
      title: "Are you sure you want to delete this lead?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      okButtonProps: { disabled: isLoadingLeads, loading: isLoadingLeads },
      cancelButtonProps: { disabled: isLoadingLeads, loading: isLoadingLeads },
      onOk: () => deleteGeneric(genericId),
    });
  };

  const handleRowClick = (e, _to) => {
    e.stopPropagation();
    if (e.ctrlKey || e.metaKey) {
      window.open(_to, "_blank");
      return;
    }
    props.history(_to);
  };

  const columns = [
    {
      title: "Generic",
      dataIndex: "id",
      className: `App__rowLink App__w6`,
      render: (value) => <div className="App__rowIcon">{value}</div>,
      onCell: (record) => ({
        onClick: (e) =>
          handleRowClick(e, `/generic-file-records/${record["id"]}`),
      }),
    },
    {
      title: "Generic file",
      dataIndex: "generic_file_id",
      className: `App__rowLink App__w10`,
      render: (value) => <div className="App__rowIcon">{value}</div>,
      onCell: (record) => ({
        onClick: (e) =>
          handleRowClick(e, `/generic-files/${record["generic_file_id"]}`),
      }),
    },
    ...[
      {
        title: "Owner name",
        dataIndex: "owner_name",
        sorter: (a, b) => alphaSort(a.owner_name, b.owner_name),
      },
      {
        title: "Owner name formatted",
        dataIndex: "owner_name_formatted",
        extras: true,
        sorter: (a, b) =>
          alphaSort(a.owner_name_formatted, b.owner_name_formatted),
      },
      {
        title: "Owner name ICO",
        dataIndex: "owner_name_ico",
        extras: true,
        sorter: (a, b) => alphaSort(a.owner_name_ico, b.owner_name_ico),
      },
      {
        title: "Owner address",
        dataIndex: "owner_address",
        sorter: (a, b) => alphaSort(a.owner_address, b.owner_address),
      },
      {
        title: "Owner address formatted",
        dataIndex: "owner_address_formatted",
        extras: true,
        sorter: (a, b) =>
          alphaSort(a.owner_address_formatted, b.owner_address_formatted),
      },
      {
        title: "Owner city",
        dataIndex: "owner_city",
        sorter: (a, b) => alphaSort(a.owner_city, b.owner_city),
      },
      {
        title: "Owner state",
        dataIndex: "owner_state",
        sorter: (a, b) => alphaSort(a.owner_state, b.owner_state),
      },
      {
        title: "Owner zip code",
        dataIndex: "owner_zip_code",
        sorter: (a, b) => alphaSort(a.owner_zip_code, b.owner_zip_code),
      },
      {
        title: "Lessee",
        dataIndex: ["data", "lessee"],
        sorter: (a, b) =>
          alphaSort(get(a, "data.lessee"), get(b, "data.lessee")),
      },
      {
        title: "Unit county",
        dataIndex: ["data", "unit_county"],
        sorter: (a, b) =>
          alphaSort(get(a, "data.unit_county"), get(b, "data.unit_county")),
      },
      {
        title: "Book",
        dataIndex: ["data", "book"],
        extras: true,
        sorter: (a, b) => alphaSort(get(a, "data.book"), get(b, "data.book")),
      },
      {
        title: "Page",
        dataIndex: ["data", "page"],
        extras: true,
        sorter: (a, b) => alphaSort(get(a, "data.page"), get(b, "data.page")),
      },
      {
        title: "File date",
        dataIndex: ["data", "file_date"],
        sorter: (a, b) =>
          alphaSort(get(a, "data.file_date"), get(b, "data.file_date")),
      },
      {
        title: "Lease type",
        dataIndex: ["data", "lease_type"],
        sorter: (a, b) =>
          alphaSort(get(a, "data.lease_type"), get(b, "data.lease_type")),
      },
      {
        title: "Section",
        dataIndex: ["data", "section"],
        sorter: (a, b) =>
          alphaSort(get(a, "data.section"), get(b, "data.section")),
      },
      {
        title: "Township",
        dataIndex: ["data", "township"],
        sorter: (a, b) =>
          alphaSort(get(a, "data.township"), get(b, "data.township")),
      },
      {
        title: "Range",
        dataIndex: ["data", "range"],
        sorter: (a, b) => alphaSort(get(a, "data.range"), get(b, "data.range")),
      },
      {
        title: "Acres",
        dataIndex: ["data", "acres"],
        sorter: (a, b) => alphaSort(get(a, "data.acres"), get(b, "data.acres")),
      },
      {
        title: "Royalty",
        dataIndex: ["data", "royalty"],
        sorter: (a, b) =>
          alphaSort(get(a, "data.royalty"), get(b, "data.royalty")),
      },
    ]
      // .map((item) => ({
      //   ...item,
      //   sortDirections: ["descend", "ascend"],
      //   sorter: (a, b) => alphaSort(a[item.name], b[item.name]),
      // }))
      .filter((item) => (columnExtras ? true : !item.extras)),
    {
      title: "Actions",
      dataIndex: "id",
      key: "actions",
      render: (_, record) => {
        return (
          <div className="App__tableOperations">
            <Button
              shape="circle"
              icon={<DeleteOutlined />}
              size="small"
              onClick={() => showDeleteConfirm(record.id)}
              disabled={isLoadingLeads}
            />
          </div>
        );
      },
    },
  ];

  return (
    <Form form={form} component={false}>
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          pageSize: recordsPerPage,
          showSizeChanger: false,
        }}
        showSorterTooltip={false}
        className="App__smallTables"
        loading={isLoadingLeads}
        scroll={{ x: "100%" }}
        bordered
      />
    </Form>
  );
};

export default OwnerGenerics;
