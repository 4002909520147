import React from "react";
import { useDispatch, useSelector } from "react-redux";
import mapActions from "../../../actions/map";

import { Button } from "antd";

const DrawCardControls = (props) => {
  const { handleSave, loading } = props;

  const dispatch = useDispatch();

  const edit = useSelector((state) => state.map.controls.draw.edit);

  return (
    <div>
      {edit ? (
        <>
          <Button
            type="secondary"
            className="App__mr-5"
            onClick={() => dispatch(mapActions.setDraw(null, { edit: false }))}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={handleSave}
            loading={loading}
            disabled={loading}
          >
            Save
          </Button>
        </>
      ) : (
        <Button
          type="secondary"
          onClick={() => dispatch(mapActions.setDraw(null, { edit: true }))}
          disabled={loading}
        >
          Edit
        </Button>
      )}
    </div>
  );
};

export default DrawCardControls;
