import React, { Component } from "react";
import { alphaSort } from "../../utils/numbers";
import { Table, Tooltip } from "antd";
import ReactJson from "react-json-view";
import { DatabaseOutlined } from "@ant-design/icons";

import styles from "./EnverusObjectTables.module.scss";

class OwnerEnverusLandtracUnits extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleRowClick(e, _to) {
    e.stopPropagation();
    if (e.ctrlKey || e.metaKey) {
      window.open(_to, "_blank");
      return;
    }
    this.props.history(_to);
  }

  render() {
    const columns = [
      {
        title: "Id",
        dataIndex: "id",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => a.id - b.id,
      },
      {
        key: "data",
        dataIndex: "data",
        title: "Data",
        className: `App__w10`,
        render: (value) => (
          <Tooltip
            overlayClassName={styles.toolTipCard}
            placement="rightBottom"
            title={
              <ReactJson
                src={value}
                collapsed={false}
                style={{
                  backgroundColor: "white",
                  width: "484px",
                  height: "700px",
                  fontSize: "12px",
                  overflow: "auto",
                }}
                displayDataTypes={false}
              />
            }
          >
            <DatabaseOutlined />
          </Tooltip>
        ),
      },
      {
        title: "Unit Name",
        dataIndex: "unit_name",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => alphaSort(a.unit_name, b.unit_name),
      },
      {
        title: "API/UWI",
        dataIndex: "api_uwi_10",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => alphaSort(a.api_uwi_10, b.api_uwi_10),
      },
      {
        title: "RRC ID",
        dataIndex: "rrc_id",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => alphaSort(a.rrc_id, b.rrc_id),
      },
      {
        title: "County",
        dataIndex: "county",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => alphaSort(a.county, b.county),
      },
      {
        title: "State",
        dataIndex: "state",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => alphaSort(a.state, b.state),
      },
      {
        title: "Acres",
        dataIndex: "acres",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => a.acres - b.acres,
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={this.props.enverusLandtracUnits}
        className="App__smallTables"
        pagination={{
          pageSize: this.props.recordsPerPage,
          showSizeChanger: false,
        }}
        showSorterTooltip={false}
        loading={this.props.isLoadingEnverus}
        scroll={{ x: "100%" }}
        bordered
      />
    );
  }
}

export default OwnerEnverusLandtracUnits;
