const mailLetterType = {
  standard: "standard",
  ntf: "ntf",
  remail: "remail",
};

const mailLetterTypeOptions = [
  { value: mailLetterType.standard, label: "Standard" },
  { value: mailLetterType.ntf, label: "NTF" },
  { value: mailLetterType.remail, label: "Remail" },
];

const mailLetterTypeTitle = {
  [mailLetterType.standard]: "Standard",
  [mailLetterType.ntf]: "NTF",
  [mailLetterType.remail]: "Remail",
};

const mailLetterTypeColor = {
  [mailLetterType.standard]: "#e4e6e9",
  [mailLetterType.ntf]: "#fc6c85",
  [mailLetterType.remail]: "#317ae2",
};

export {
  mailLetterType,
  mailLetterTypeOptions,
  mailLetterTypeTitle,
  mailLetterTypeColor,
};
