import React from "react";

import BaseLayer from "./BaseLayer";
import {
  LAYER_ID,
  BORDER_HIGHLIGHT_LAYER_ID,
  API_BASE_ENDPOINT,
} from "../../layers/permitPlats";

const PermitLayer = ({ mapRef }) => {
  return (
    <BaseLayer
      mapRef={mapRef}
      title="Permit Plats"
      apiBaseEndpoint={API_BASE_ENDPOINT}
      layerId={LAYER_ID}
      selectedLayerIds={[BORDER_HIGHLIGHT_LAYER_ID]}
    />
  );
};

export default PermitLayer;
