import React from "react";

import BaseLayer from "./BaseLayer";
import {
  LAYER_ID,
  BORDER_HIGHLIGHT_LAYER_ID,
  API_BASE_ENDPOINT,
} from "../../layers/assets";

const AssetsLayer = ({ mapRef }) => {
  return (
    <BaseLayer
      mapRef={mapRef}
      title="Assets"
      apiBaseEndpoint={API_BASE_ENDPOINT}
      layerId={LAYER_ID}
      selectedLayerIds={[BORDER_HIGHLIGHT_LAYER_ID]}
    />
  );
};

export default AssetsLayer;
