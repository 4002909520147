import React, { useState } from "react";
import { apiBase } from "../../utils/apiBase";
import { withParams } from "../../utils/withParams";
import { withRouter } from "../../utils/withRouter";
import { get } from "lodash";

import ReactJson from "react-json-view";
import { Table, Button, Modal, Tooltip, message } from "antd";

import {
  DatabaseOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";

const { confirm } = Modal;

const OperatorVendors = (props) => {
  const { parentLoading, operatorVendors, getOperatorVendors } = props;

  const [loading, setLoading] = useState(false);

  const deleteQuickbooksVendor = async (id) => {
    setLoading(true);
    apiBase
      .delete(`/quickbooks-vendors/${id}`)
      .then((res) => {
        let msg = `${res.status}: Successfully deleted operator vendor.`;
        message.success(msg);
        getOperatorVendors();
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to delete operator vendor.`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: "Are you sure you want to delete this operator vendor?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      okButtonProps: { disabled: loading, loading: loading },
      cancelButtonProps: { disabled: loading, loading: loading },
      onOk: () => deleteQuickbooksVendor(id),
    });
  };

  function handleRowClick(e, _to) {
    e.stopPropagation();
    if (e.ctrlKey || e.metaKey) {
      window.open(_to, "_blank");
      return;
    }
    props.history(_to);
  }

  const columns = [
    {
      title: "Entity",
      dataIndex: ["entity", "name"],
      className: `App__rowLink App__w8`,
      render: (value) => <div>{value}</div>,
      onCell: (record) => ({
        onClick: (e) =>
          handleRowClick(e, `/portfolio/entities/${record["entity_id"]}`),
      }),
    },
    {
      title: "Quickbooks Id",
      dataIndex: "qbo_id",
      render: (value) => <div className="App__rowIcon">{value}</div>,
    },
    {
      title: "Quickbooks display name",
      dataIndex: "qbo_data.DisplayName",
      render: (_, record) => (
        <div className="App__rowIcon">
          {get(record, "qbo_data.DisplayName")}
        </div>
      ),
    },
    {
      title: "Quickbooks sync token",
      dataIndex: "qbo_data.SyncToken",
      render: (_, record) => (
        <div className="App__rowIcon">{get(record, "qbo_data.SyncToken")}</div>
      ),
    },
    {
      title: "Quickbooks Data",
      dataIndex: "qbo_data",
      render: (value) => (
        <div className="App__rowIcon">
          {" "}
          <Tooltip
            overlayClassName="App__toolTipCard"
            placement="bottom"
            title={
              <ReactJson
                src={value}
                collapsed={true}
                style={{
                  backgroundColor: "white",
                  width: "484px",
                  fontSize: "12px",
                }}
                displayDataTypes={false}
              />
            }
          >
            <DatabaseOutlined />
          </Tooltip>
        </div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "actions",
      render: (value, record) => {
        return (
          <div className="App__tableOperations">
            <Button
              shape="circle"
              icon={<DeleteOutlined />}
              size="small"
              onClick={() => showDeleteConfirm(record.id)}
              disabled={loading || parentLoading}
            />
          </div>
        );
      },
    },
  ];

  return (
    <Table
      pagination={false}
      columns={columns}
      dataSource={operatorVendors.map((operatorVendor, idx) => ({
        ...operatorVendor,
        key: idx,
      }))}
      showSorterTooltip={false}
      className="App__smallTables"
      loading={loading || parentLoading}
      scroll={{ x: "100%" }}
      bordered
    />
  );
};

export default withRouter(withParams(OperatorVendors));
