import React, { useState, useImperativeHandle, forwardRef } from "react";
import axios from "axios";
import clsx from "clsx";

import { apiBase } from "../../utils/apiBase";
import { humanFileSize, guid } from "../../utils/numbers";

import { Button, Upload, Progress, message } from "antd";
import {
  InboxOutlined,
  SyncOutlined,
  CheckCircleTwoTone,
  DeleteOutlined,
  FileOutlined,
  CloseCircleTwoTone,
} from "@ant-design/icons";

import styles from "./FileUpload.module.scss";

/**
 * FileUpload component allows users to upload files with progress tracking.
 *
 * @param {Object} props - The properties object. (https://ant.design/components/upload#api)
 * @param {React.Ref} ref - Ref to expose the upload method to parent components.
 *
 * @returns {JSX.Element} The rendered FileUpload component.
 */
const FileUpload = forwardRef((props = { maxSize: 100 }, ref) => {
  const [fileList, setFileList] = useState([]);

  // Expose handleUpload to parent via ref
  useImperativeHandle(ref, () => ({
    upload,
  }));

  /**
   * Validates the file before upload.
   *
   * @param {File} file - The file to be uploaded.
   * @returns {boolean} False to prevent automatic upload by Upload component.
   */
  const beforeUpload = (file) => {
    if (props.maxSize) {
      const isFileSizeValid = file.size / 1024 / 1024 < props.maxSize;

      if (!isFileSizeValid) {
        message.error(`File must me smaller than ${props.maxSize}Mb`);

        return;
      }
    }

    setFileList((prevFiles) => {
      return [...prevFiles, file];
    });

    return false;
  };

  /**
   * Removes a file from the upload list.
   *
   * @param {File} file - The file to be removed.
   */
  const onRemove = (file) => {
    if (file.uploading && file.cancel_token) {
      file.cancel_token.cancel();
    }

    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();

    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  /**
   * Updates the upload progress of a file.
   *
   * @param {ProgressEvent} e - The progress event.
   * @param {string} uploadingUID - The unique identifier of the uploading file.
   */
  const uploadProgress = (e, uploadingUID) => {
    setFileList((prevFiles) => {
      return prevFiles.map((file) => {
        if (file.uid === uploadingUID) {
          file.progress = Math.floor((e.loaded / e.total) * 100);
          file.size_loaded = humanFileSize(e.loaded, true, 2);
          file.size_total = humanFileSize(e.total, true, 2);
        }
        return file;
      });
    });
  };

  const getUploadUrl = async (s3Key) => {
    try {
      const uploadUrlResponse = await apiBase.post(`files/upload-url`, {
        key: s3Key,
      });

      if (!uploadUrlResponse?.data) {
        throw new Error("Failed to create upload url");
      }

      return uploadUrlResponse.data;
    } catch (err) {
      const errorMessage = err.response?.status
        ? `${err.response.status}: Failed to create upload url`
        : "Failed to create upload url";

      throw new Error(errorMessage);
    }
  };

  const saveFile = async (file) => {
    try {
      await apiBase.post(`files`, {
        record_id: props.recordId,
        record_type: props.recordType,
        s3_key: file?.s3_key,
        file_metadata: {
          name: file?.name,
          size: file?.size,
          type: file?.type,
          last_modified: file?.lastModifiedDate,
        },
      });
    } catch (err) {
      throw new Error(`${err.response.status}: Failed to save file`);
    }
  };

  /**
   * Initiates the upload process for files in the file list.
   */
  const upload = async () => {
    setFileList((prevFiles) => {
      return prevFiles.map((file) => {
        if (file.uploaded) {
          return file;
        }

        file.error = null;
        file.uploading = true;
        file.progress = 0;
        file.size_loaded = 0;
        file.size_total = 0;
        file.cancel_token = axios.CancelToken.source();

        return file;
      });
    });

    for (const file of fileList.filter((file) => !file.uploaded)) {
      try {
        const s3Key = `file/${props.recordType}/${props.recordId}/${guid()}_${
          file?.name
        }`;

        // sign upload url
        const uploadData = await getUploadUrl(s3Key);
        const formData = new FormData();

        Object.keys(uploadData.fields).forEach((key) => {
          formData.append(key, uploadData.fields[key]);
        });

        file.s3_key = uploadData.s3_key_full;
        formData.append("file", file);

        // check formdata

        const postConfig = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (e) => uploadProgress(e, file.uid),
          cancelToken: file.cancel_token.token,
        };

        // upload file to s3
        await axios.post(uploadData.url, formData, postConfig);

        // save file to db
        await saveFile(file);

        file.error = null;
        file.uploading = false;
        file.uploaded = true;
      } catch (err) {
        if (!axios.isCancel(err)) {
          message.error(err.message);

          file.error = err.message;
        }

        file.progress = null;
        file.uploading = false;
        file.uploaded = false;
      }

      // update file state
      setFileList((prevFiles) => {
        return prevFiles.map((f) => {
          if (f.uid === file.uid) {
            return file;
          }

          return f;
        });
      });
    }
  };

  /**
   * Renders the state of a file (uploaded, uploading, error, etc.).
   *
   * @param {File} file - The file whose state is to be rendered.
   * @returns {JSX.Element} The rendered file state.
   */
  const renderFileState = (file) => {
    if (file.uploaded) {
      return (
        <>
          <div className={styles.iconContainer}>
            <CheckCircleTwoTone
              twoToneColor="#52c41a"
              className={styles.icon}
            />
          </div>
          <div className={styles.file}>
            <div className={styles.spaced}>
              <div>{`${file?.name}`}</div>
              <div>
                <Button
                  onClick={() => onRemove(file)}
                  icon={<DeleteOutlined />}
                  size={"small"}
                  disabled={false}
                />
              </div>
            </div>
            <Progress percent={file.progress} showInfo={false} />
            <div className={`${styles.spaced} ${styles.gray}`}>
              <div>{`${file.size_loaded} of ${file.size_total}`}</div>
              <div>{`${file.progress}%`}</div>
            </div>
          </div>
        </>
      );
    } else if (file.error) {
      return (
        <>
          <div className={styles.iconContainer}>
            <CloseCircleTwoTone
              className={styles.icon}
              twoToneColor="#ff4d4f"
            />
          </div>
          <div className={styles.file}>
            <div className={styles.spaced}>
              <div>{`${file?.name}`}</div>
              <div>
                <Button
                  onClick={() => onRemove(file)}
                  icon={<DeleteOutlined />}
                  size={"small"}
                  disabled={false}
                />
              </div>
            </div>
          </div>
        </>
      );
    } else if (file.uploading) {
      return (
        <>
          <div className={styles.iconContainer}>
            <SyncOutlined className={styles.icon} spin />
          </div>
          <div className={styles.file}>
            <div className={styles.spaced}>
              <div>{`${file?.name}`}</div>
              <div>
                <Button
                  onClick={() => onRemove(file)}
                  icon={<DeleteOutlined />}
                  size={"small"}
                  disabled={false}
                />
              </div>
            </div>
            <Progress percent={file.progress} showInfo={false} />
            <div className={`${styles.spaced} ${styles.gray}`}>
              {file.size_loaded && file.size_total ? (
                <div>{`${file.size_loaded} of ${file.size_total}`}</div>
              ) : (
                <div>Loading...</div>
              )}
              <div>{`${file.progress}%`}</div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className={styles.iconContainer}>
            <FileOutlined className={styles.icon} />
          </div>
          <div className={styles.file}>
            <div className={styles.spaced}>
              <div>{`${file?.name}`}</div>
              <div>
                <Button
                  onClick={() => onRemove(file)}
                  icon={<DeleteOutlined />}
                  size={"small"}
                  disabled={false}
                />
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <Upload.Dragger
        name="file-uploader"
        multiple
        {...props}
        // props that cannot be overridden
        beforeUpload={beforeUpload}
        onRemove={onRemove}
        fileList={fileList}
        showUploadList={false}
      >
        <>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          {props.accept && (
            <p className="ant-upload-hint">Formats ({props.accept})</p>
          )}
          {props.maxSize && (
            <p className="ant-upload-hint">Max file size: {props.maxSize}Mb</p>
          )}
        </>
      </Upload.Dragger>

      {fileList.map((file) => (
        <div
          key={file.uid}
          className={clsx(styles.fileContainer, styles.fileCard)}
        >
          {renderFileState(file)}
        </div>
      ))}
    </>
  );
});

export default FileUpload;
