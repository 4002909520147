import React, { useState, useEffect, useCallback } from "react";

import { apiBase } from "../../utils/apiBase";
import { withParams } from "../../utils/withParams";
import { Helmet } from "react-helmet";
import { get, isEmpty } from "lodash";
import { clsx } from "clsx";
import ReactJson from "react-json-view";
import IDIPersonCard from "./IDIPersonCard";

import { message } from "antd";

import styles from "./IDISearch.module.scss";

const IDISearch = (props) => {
  const [loading, setLoading] = useState(false);
  const [idiSearch, setIDISearch] = useState({});

  const getIDISearch = useCallback(async () => {
    try {
      setLoading(true);
      const res = await apiBase.get(`/idi-searches/${props.params.id}`);
      setIDISearch(get(res, "data", {}));
    } catch (err) {
      let msg = `${err.response.status}: Failed to load IDI search ${props.params.id}`;
      message.error(msg);
    } finally {
      setLoading(false);
    }
  }, [props.params.id]);

  useEffect(() => {
    getIDISearch();
  }, [getIDISearch]);

  const results = get(idiSearch, "response_body.result", []);
  const error = get(idiSearch, "error");

  return (
    <div className="App__rowContent">
      <Helmet>
        <title>{`IDISearch: ${idiSearch.id}`}</title>
      </Helmet>
      <div
        className={clsx({
          [styles.loading]: loading,
        })}
      >
        <div className={styles.results}>{results.length} result(s)</div>
        {isEmpty(error) ? null : (
          <div className={styles.error}>
            <ReactJson
              src={error}
              style={{
                fontSize: "12px",
              }}
            />
          </div>
        )}
        {results.map((result, idx) => (
          <div className={styles.idiPersonCard} key={idx}>
            <IDIPersonCard idiPerson={{ data: result }} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default withParams(IDISearch);
