import React, { useState } from "react";
import { apiBase } from "../../utils/apiBase";
import { get } from "lodash";

import { Button, Form, Modal, message, InputNumber } from "antd";
import SearchSelect from "../common/SearchSelect";

const PermitUnitCreateForm = (props) => {
  const { permits, fetchData } = props;
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    form.resetFields();
    setVisible(false);
  };

  const onFinish = (values) => {
    setLoading(true);
    apiBase
      .post(`/permit-units/bulk`, {
        permit_ids: permits.map((permit) => permit.id),
        unit_id: values.unit_id,
        unit_allocation: values.unit_allocation / 100,
      })
      .then((res) => {
        let msg = `${res.status}: Successfully created permit unit.`;
        message.success(msg);
        fetchData();
        handleCancel();
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = () => {
    message.error("Please check the form for errors");
  };

  return (
    <>
      <Button
        type="secondary"
        onClick={() => setVisible(true)}
        disabled={loading}
        className="App__mr-5"
      >
        Add unit
      </Button>
      <Modal
        title="Add Unit"
        open={visible}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel} disabled={loading}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => form.submit()}
          >
            Create
          </Button>,
        ]}
      >
        <Form
          form={form}
          initialValues={{
            unit_allocation: 0,
          }}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Unit"
            name="unit_id"
            rules={[{ required: true, message: "Please select a unit" }]}
          >
            <SearchSelect
              searchUri="units"
              searchField="id"
              placeholder="Search units"
            />
          </Form.Item>
          <Form.Item
            label="Unit allocation"
            name="unit_allocation"
            rules={[
              {
                required: true,
                message: "Unit allocation between 0 and 100 required.",
                type: "number",
                max: 100,
                min: 0,
              },
            ]}
          >
            <InputNumber
              precision={8}
              addonAfter={"%"}
              placeholder="0.00"
              className="App__fullWidth"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default PermitUnitCreateForm;
