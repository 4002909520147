import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import mapActions from "../../../../actions/map";
import { apiBase } from "../../../../utils/apiBase";
import AssociatedUnits from "./associations/AssociatedUnits";

import clsx from "clsx";
import * as turf from "@turf/turf";
import { pick } from "lodash";

import { Button, notification } from "antd";
import { SaveOutlined } from "@ant-design/icons";

import styles from "./BasePopup.module.scss";

const LandtracUnitDetails = ({ currentFeature }) => {
  const { properties } = currentFeature;

  // TODO QUERY API FOR LANDTRAC UNIT DETAILS

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [landtracUnit, setLandtracUnit] = useState(null);

  useEffect(() => {
    setLoading(true);
    apiBase
      .get(`/enverus-landtrac-units/${properties.id}`)
      .then((res) => {
        setLandtracUnit(res.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [properties.id]);

  const openNotification = () => {
    notification.info({
      message: `Associated Units`,
      description: <AssociatedUnits apiUWI10={properties.api_uwi_10} />,
      placement: "topLeft",
      duration: 0,
      className: styles.notification,
      icon: <></>,
    });
  };

  const saveGeometry = () => {
    if (!landtracUnit || !landtracUnit.geom_polygon) return;

    const features = turf.feature(landtracUnit.geom_polygon);
    dispatch(mapActions.setDraw(null, { savedGeometry: features }));
  };

  return (
    <>
      <div
        className={clsx(styles.properties, {
          [styles.loading]: loading,
        })}
      >
        {Object.entries(
          pick(properties, [
            "id",
            "api_uwi_10",
            "unit_name",
            "rrc_id",
            "state",
            "county",
            "acres",
            "created_at",
            "updated_at",
          ])
        ).map(([key, value]) => (
          <div className={styles.property} key={key}>
            <strong>{key}:</strong>
            <span className="App__ml-5">{value}</span>
          </div>
        ))}
      </div>
      <hr className={styles.separator} />
      <div className={styles.buttons}>
        <Button
          className={styles.unitsButton}
          type="secondary"
          size="small"
          onClick={() => openNotification()}
        >
          Associated Units
        </Button>
        <Button
          disabled={loading}
          type="secondary"
          size="small"
          onClick={saveGeometry}
        >
          <SaveOutlined />
        </Button>
      </div>
    </>
  );
};
export default LandtracUnitDetails;
