import React, { Component } from "react";
import { formatNumber } from "../../utils/numbers";
import { columnTypes } from "../../utils/search";
import { formatDate } from "../../utils/dates";

import BaseSearch from "../search/BaseSearch";
import FilterTags from "../search/FilterTags";
import TotalRecords from "../search/TotalRecords";
import Took from "../search/Took";

import { DownloadOutlined, TableOutlined } from "@ant-design/icons";

import ExportForm from "../forms/ExportForm";
import ChangeColumnsForm from "../forms/ChangeColumnsForm";
import UnitReviewStatusModal from "./UnitReviewStatusModal";
import { EnvironmentTwoTone } from "@ant-design/icons";

// import { reviewStatusesOptions } from "../../common/unitReviewStatus";
import { yearsOptions } from "../../common/taxYear";

import { Row, Col, Table, Select, Button, Tooltip, Pagination } from "antd";
// import { UnitReviewStatusIcon } from "../common/LabelIcon";

import RecordLabels from "../common/RecordLabels";
import { arrayContainsOperators } from "../search/constants";

export const baseColumnState = [
  {
    dataIndex: "id",
    title: "Id",
    editable: false,
    type: columnTypes.LINK,
    baseUrl: "/units",
    sortable: true,
  },
  {
    title: "Offers",
    dataIndex: "offer_count",
    type: columnTypes.INTEGER,
  },
  {
    title: "Labels",
    dataIndex: ["label_ids"],
    className: `App__w16`,
    exportable: false,
    type: columnTypes.SELECT_MULTI,
    globalStateKey: "labels.data",
    globalStateOptions: (data) => {
      const recordLabels = data
        .filter((d) => d.record_type === "unit")
        .map((d) => ({ value: String(d.id), label: d.value }));
      recordLabels.push({ value: "-1", label: "No Label" });
      return recordLabels;
    },
    operatorOptions: arrayContainsOperators,
    format: ({ record }) => {
      return (
        <RecordLabels
          record_type="unit"
          record_id={record.id}
          label_ids={record.label_ids}
        />
      );
    },
  },
  // {
  //   title: "Review status",
  //   dataIndex: "review_status",
  //   type: columnTypes.SELECT,
  //   options: reviewStatusesOptions,
  //   className: `App__w10`,
  //   selectClassName: `App__w10`,
  //   format: ({ value }) => <UnitReviewStatusIcon value={value} />,
  // },
  {
    dataIndex: "review_at",
    title: "Reviewed at",
    type: columnTypes.DATE_TIME,
    className: `App__w10`,
    format: (value) => value && formatDate(value, "MM/DD/YYYY"),
  },
  {
    dataIndex: "geom_polygon",
    title: "Mapped",
    type: columnTypes.SELECT,
    options: [
      { value: "false", label: "False" },
      { value: "true", label: "True" },
    ],
    className: `App__w6`,
    selectClassName: `App__w6`,
    format: ({ record }) => {
      return record.geom_polygon ? (
        <div className="App__tableIcon">
          <EnvironmentTwoTone twoToneColor="#52c41a" />
        </div>
      ) : null;
    },
  },
  {
    dataIndex: "name_formatted",
    title: "Name formatted",
    type: columnTypes.STRING,
    first: true,
  },
  {
    dataIndex: "name",
    title: "Name",
    type: columnTypes.STRING,
    visible: false,
  },
  {
    dataIndex: "legal_description",
    title: "Legal description",
    type: columnTypes.STRING,
    visible: true,
  },
  {
    dataIndex: "well",
    title: "Well",
    type: columnTypes.STRING,
    className: `App__w8`,
    visible: false,
  },
  {
    dataIndex: "county",
    title: "County",
    type: columnTypes.STRING,
  },
  {
    dataIndex: "operator",
    title: "Operator",
    type: columnTypes.STRING,
  },
  {
    dataIndex: "rrc_id",
    title: "RRC(#)",
    type: columnTypes.STRING,
  },
  {
    title: "Owner count",
    dataIndex: "unique_owner_count",
    type: columnTypes.INTEGER,
    className: `App__w14`,
  },
  {
    title: "Median PC",
    dataIndex: "median_owner_unit_count",
    type: columnTypes.INTEGER,
    className: `App__w14`,
  },
  {
    title: "Cumulative DOI",
    dataIndex: "cumulative_decimal_interest",
    className: `App__w14`,
    type: columnTypes.INTEGER,
    format: (value) => formatNumber(value, "0.000000"),
  },
  {
    title: "Tax year first",
    dataIndex: "tax_year_first",
    className: `App__w10`,
    selectClassName: `App__w10`,
    type: columnTypes.SELECT,
    options: yearsOptions,
  },
  {
    title: "Tax year",
    dataIndex: "tax_year",
    className: `App__w10`,
    type: columnTypes.SELECT,
    options: yearsOptions,
  },
  {
    title: "Tax year count",
    dataIndex: "tax_year_count",
    className: `App__w12`,
    type: columnTypes.INTEGER,
  },
];

export class Units extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedUnitKeys: [],
      selectedUnits: [],
    };
  }

  onSelectUnitChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedUnitKeys: selectedRowKeys,
      selectedUnits: selectedRows,
    });
  };

  resetSelectedUnits = () => {
    this.setState({
      selectedUnitKeys: [],
      selectedUnits: [],
    });
  };

  render() {
    const {
      isLoadingRecords,
      isLoadingCount,
      records,
      columnState,
      limit,
      page,
      totalRecords,
      tookRecords,
      tookCount,
      searchFields,
      isExportModalVisible,
      isChangeColumnsVisible,
      isLoadingExport,
    } = this.props;

    const {
      handleSearch,
      handleRecordsChange,
      handlePaginationChange,
      handleFilterClose,
      handleTableChange,
      handleSubmitExport,
      showExportModal,
      handleCancelExport,
      handleSubmitColumnChange,
      showColumnChangeModal,
      handleCancelColumnsChange,
      exportFormRef,
      changeColumnFormRef,
      getColumnProps,
    } = this.props;

    const rowSelection = {
      selectedRowKeys: this.state.selectedUnitKeys,
      onChange: this.onSelectUnitChange,
    };

    let columns = columnState
      .map((column, idx) => ({
        key: idx,
        ...column,
        ...getColumnProps(column),
      }))
      .filter((col) => col.visible === true);

    return (
      <div>
        <Row className="App__rowContent">
          <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16}>
            <Button className="App__mr-5" onClick={() => handleSearch()}>
              Search
            </Button>
            <UnitReviewStatusModal
              selectedUnits={this.state.selectedUnits}
              resetSelectedUnits={this.resetSelectedUnits}
              handleSearch={handleSearch}
              loading={isLoadingRecords}
            />
            <FilterTags
              searchFields={searchFields}
              columnState={columnState}
              handleFilterClose={handleFilterClose}
            />
          </Col>
          <Col
            span={8}
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            className="App__rightActions"
          >
            <Tooltip placement="bottomRight" title={"Export search results"}>
              <Button className="App__iconButton" onClick={showExportModal}>
                <DownloadOutlined />
              </Button>
            </Tooltip>
            <ExportForm
              wrappedComponentRef={exportFormRef}
              visible={isExportModalVisible}
              isLoading={isLoadingExport}
              onCancel={handleCancelExport}
              onCreate={handleSubmitExport}
              totalRecords={totalRecords}
            />
            <Tooltip placement="bottomRight" title={"Change visible columns"}>
              <Button
                onClick={showColumnChangeModal}
                className="App__iconButton"
              >
                <TableOutlined />
              </Button>
            </Tooltip>
            <ChangeColumnsForm
              wrappedComponentRef={changeColumnFormRef}
              visible={isChangeColumnsVisible}
              isLoading={isLoadingRecords}
              onCancel={handleCancelColumnsChange}
              onOk={handleSubmitColumnChange}
              columnState={columnState}
            />
            <Select
              value={limit}
              onChange={handleRecordsChange}
              className={"App__mr-5"}
            >
              <Select.Option value={25}>25</Select.Option>
              <Select.Option value={50}>50</Select.Option>
              <Select.Option value={100}>100</Select.Option>
              <Select.Option value={1000}>1000</Select.Option>
            </Select>
            <TotalRecords
              title={"Units"}
              isLoadingCount={isLoadingCount}
              totalRecords={totalRecords}
            />
          </Col>
        </Row>
        <Row className="App__rowContent">
          <Col span={24}>
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={records}
              onChange={handleTableChange}
              showSorterTooltip={false}
              pagination={false}
              className="App__smallTables"
              loading={isLoadingRecords}
              scroll={{ x: "100%" }}
              size="small"
              bordered
            />
            <div className="App__searchFooterContainer">
              <Took
                isLoadingRecords={isLoadingRecords}
                isLoadingCount={isLoadingCount}
                tookRecords={tookRecords}
                tookCount={tookCount}
              />
              <Pagination
                current={page}
                pageSize={limit}
                total={totalRecords}
                onChange={handlePaginationChange}
                className="App__pagination"
                showSizeChanger={false}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const UnitsWithSearch = (props) => (
  <BaseSearch
    baseColumnState={baseColumnState}
    localSortColumns={[]}
    searchUri="units"
  >
    <Units {...props} />
  </BaseSearch>
);

export default UnitsWithSearch;
