import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import { withRouter } from "../utils/withRouter";
import { UserOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet";

import { Layout, Menu, Avatar, Dropdown } from "antd";

import Router from "./Router";

import CRM from "./navs/CRM";
import Settings from "./navs/Settings";
import Portfilio from "./navs/Portfolio";

import { getNavSelectedKeys } from "./navigation";

import {
  PieChartOutlined,
  SettingOutlined,
  SolutionOutlined,
} from "@ant-design/icons";

import styles from "./MainLayout.module.scss";

const { Header, Sider, Content } = Layout;

const iconSize = 34;

const menu = (props) => {
  return (
    <Menu className={styles.avatarDropDownMenu}>
      <Menu.Item key="profile">
        <Link to={`/settings/users/${props.currentUserId}`}>Profile</Link>
      </Menu.Item>
      <Menu.Item
        key="signOut"
        className={styles.signOut}
        onClick={() => props.logoutUser()}
      >
        Sign Out
      </Menu.Item>
    </Menu>
  );
};

class MainLayout extends Component {
  state = {
    collapsed: false,
    navSelectedKeys: getNavSelectedKeys(this.props.location.pathname),
  };

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  componentDidUpdate() {
    const navSelectedKeys = getNavSelectedKeys(this.props.location.pathname);
    if (
      JSON.stringify(this.state.navSelectedKeys) !==
      JSON.stringify(navSelectedKeys)
    ) {
      this.setState({
        navSelectedKeys: navSelectedKeys,
      });
    }
  }

  render() {
    if (this.props.isAuthenticated === false) {
      return (
        <Navigate
          to={{
            pathname: "/login",
            state: { referrer: this.props.currentUrl },
          }}
        />
      );
    } else if (this.props.location.pathname === "/") {
      return <Navigate to={`/units?limit=50&page=1`} />;
    } else {
      return (
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Taborminerals</title>
          </Helmet>
          <Layout className={styles.main}>
            <Header className={styles.header}>
              <div className={styles.leftHeader}>
                <Link to={`/units?limit=50&page=1`}>
                  <div className={styles.svgImage}>
                    <div className={styles.headerLogo} />
                  </div>
                </Link>
              </div>
              <div className={styles.rightHeader}>
                <div className={styles.iconDiv}>
                  <Link to={`/units?limit=50&page=1`}>
                    <Avatar
                      className={styles.icon}
                      shape="square"
                      size={iconSize}
                      icon={<SolutionOutlined />}
                    />
                  </Link>
                </div>
                {this.props.currentUserRole !== "USER" && (
                  <>
                    <div className={styles.iconDiv}>
                      <Link to="/portfolio/assets?limit=50&page=1">
                        <Avatar
                          className={styles.icon}
                          shape="square"
                          size={iconSize}
                          icon={<PieChartOutlined />}
                        />
                      </Link>
                    </div>
                    <div className={styles.iconDiv}>
                      <Link to={`/settings/jobs`}>
                        <Avatar
                          className={styles.icon}
                          shape="square"
                          size={iconSize}
                          icon={<SettingOutlined />}
                        />
                      </Link>
                    </div>
                  </>
                )}
                <div className={styles.iconDiv}>
                  <Dropdown
                    overlay={menu(this.props)}
                    placement="bottomLeft"
                    trigger={["click"]}
                  >
                    <Avatar
                      className={styles.icon}
                      shape="square"
                      size={iconSize}
                      icon={<UserOutlined />}
                    />
                  </Dropdown>
                </div>
              </div>
            </Header>
            <Layout>
              <Sider
                collapsible
                collapsed={this.state.collapsed}
                onCollapse={this.onCollapse}
                className={styles.siderNav}
                theme="light"
                width={165}
              >
                {this.props.location.pathname.includes("settings") ? (
                  <Settings
                    collapsed={this.state.collapsed}
                    navSelectedKeys={this.state.navSelectedKeys}
                    currentUserRole={this.props.currentUserRole}
                    currentUserId={this.props.currentUserId}
                  />
                ) : this.props.location.pathname.includes("portfolio") ? (
                  <Portfilio
                    collapsed={this.state.collapsed}
                    navSelectedKeys={this.state.navSelectedKeys}
                  />
                ) : (
                  <CRM
                    collapsed={this.state.collapsed}
                    navSelectedKeys={this.state.navSelectedKeys}
                  />
                )}
              </Sider>
              <Content className={styles.mainContent}>
                <Router {...this.props} />
              </Content>
            </Layout>
          </Layout>
        </div>
      );
    }
  }
}

export default withRouter(MainLayout);
