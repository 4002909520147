import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { get, find, isEmpty, capitalize } from "lodash";

import actions from "../../actions";
import { apiBase } from "../../utils/apiBase";
import { columnTypes } from "../../utils/search";
import { convertDataIndex } from "../../utils/search";
import {
  stringifyFilters,
  parseFilters,
  parseSortQuery,
  sortMapper,
} from "../../utils/queryParser";
import {
  downloadCSV,
  flattenObject,
  alphaSort,
  guid,
} from "../../utils/numbers";
import MinMaxDropdown from "./MinMaxDropdown";
import DateRangeDropdown from "./DateRangeDropdown";
import SelectMultiDropdown from "./SelectMultiDropdown";
import { parse, stringify } from "query-string";
import { withRouter } from "../../utils/withRouter";
import Highlighter from "react-highlight-words";

import { Input, Select, Checkbox, message } from "antd";

import styles from "./BaseSearch.module.scss";

const mapStateToProps = (state) => ({
  entities: get(state.entities, "data", []),
  globalState: state,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const initState = {
  isLoadingRecords: false,
  isLoadingCount: false,
  searchId: null,
  records: [],
  limit: 25,
  page: 1,
  totalRecordsTemp: 0,
  totalRecords: 0,
  tookRecords: 0,
  tookCount: 0,
  searchFields: {},
  sortBy: null,
  isExportModalVisible: false,
  isChangeColumnsVisible: false,
  isLoadingExport: false,
};
class BaseSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...initState,
      columnState: props.baseColumnState.map((col) => ({
        visible: true,
        editable: true,
        exportable: true,
        ...col,
      })),
    };
  }

  static propTypes = {
    localSortColumns: PropTypes.array,
    baseColumnState: PropTypes.array,
    searchUri: PropTypes.string,
  };

  componentDidMount() {
    if (this.firstInput) {
      this.firstInput.focus();
    }
    this.setStateFromLocation();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location.search &&
      this.props.location.search !== prevProps.location.search
    ) {
      this.setStateFromLocation();
    }
  }

  executeSearch = () => {
    const queryString = this.props.location.search;

    const searchId = guid();
    this.setState({
      isLoadingRecords: true,
      isLoadingCount: true,
      searchId,
    });

    // Search
    apiBase
      .get(`${this.props.searchUri}${queryString}`)
      .then(async (res) => {
        const resKeyd = res.data[this.props.searchUri.replaceAll("-", "_")].map(
          (el, idx) => ({
            ...el,
            ...{ key: idx },
          })
        );
        this.setState({
          records: resKeyd,
          totalRecordsTemp: res.data.total,
          tookRecords: res.data.took,
          isLoadingRecords: false,
        });
      })
      .catch((err) => {
        let msg = `${get(
          err,
          "response.status"
        )}: An error occured with the search`;
        message.error(msg);
        this.setState({ isLoadingRecords: false });
      });

    // Count
    apiBase
      .get(`${this.props.searchUri}${queryString}&count=true`)
      .then((res) => {
        if (this.state.searchId === searchId) {
          this.setState({
            totalRecords: res.data.total,
            tookCount: res.data.took,
            isLoadingCount: false,
          });
        }
      })
      .catch((err) => {
        let msg = `${get(
          err,
          "response.status"
        )}: An error occured with the search count`;
        message.error(msg);
        this.setState({ isLoadingCount: false });
      });
  };

  setStateFromLocation() {
    const query = parse(this.props.location.search);
    if (!query.limit || !query.page) {
      const queryString = stringify({
        limit: query.limit ? parseInt(query.limit, 10) : 25,
        page: query.page ? parseInt(query.page, 10) : 1,
        ...query,
      });

      this.props.history(
        `${this.props.baseUri}/${this.props.searchUri}?${queryString}`
      );
    }

    let searchFields = parseFilters(query.filters || {});
    const sortBy = parseSortQuery(query.sort);

    this.setState(
      {
        limit: query.limit ? parseInt(query.limit, 10) : 25,
        page: query.page ? parseInt(query.page, 10) : 1,
        searchFields: searchFields,
        sortBy: sortBy,
      },
      () => {
        this.executeSearch();
      }
    );
  }

  handleSearch = (confirm) => {
    if (confirm) confirm();
    const query = parse(this.props.location.search);
    const searchFields = this.state.searchFields;
    const filters = stringifyFilters(searchFields);
    const queryString = stringify({
      ...query,
      page: 1,
      filters: filters,
    });
    const newURL = `${this.props.baseUri}/${this.props.searchUri}?${queryString}`;

    if (`?${queryString}` !== this.props.location.search) {
      this.props.history(newURL);
    } else {
      this.executeSearch();
    }
  };

  handleRecordsChange = (value) => {
    const query = parse(this.props.location.search);
    const queryString = stringify({
      ...query,
      limit: value,
      page: 1,
    });
    this.props.history(
      `${this.props.baseUri}/${this.props.searchUri}?${queryString}`
    );
  };

  handlePaginationChange = (value) => {
    const query = parse(this.props.location.search);
    const queryString = stringify({
      ...query,
      page: value,
    });
    this.props.history(
      `${this.props.baseUri}/${this.props.searchUri}?${queryString}`,
      { replace: true }
    );
  };

  handleReset = (dataIndex, clearFilters) => {
    if (clearFilters) clearFilters();
    const searchFields = this.state.searchFields;
    searchFields[convertDataIndex(dataIndex)] = undefined;
    const query = parse(this.props.location.search);
    const queryString = stringify({
      ...query,
      filters: stringifyFilters(searchFields),
    });
    this.props.history(
      `${this.props.baseUri}/${this.props.searchUri}?${queryString}`
    );
  };

  handleFilterClose = (dataIndex) => {
    if (dataIndex === "entity_id") {
      const { dispatch } = this.props;
      dispatch(actions.entity.setEntity({}));
    }

    const searchFields = this.state.searchFields;
    delete searchFields[convertDataIndex(dataIndex)];
    const query = parse(this.props.location.search);
    const queryString = stringify({
      ...query,
      filters: stringifyFilters(searchFields),
    });
    this.props.history(
      `${this.props.baseUri}/${this.props.searchUri}?${queryString}`
    );
  };

  handleSearchStringChange = (event, dataIndex) => {
    const _value = event.target.value;

    const searchFields = this.state.searchFields;

    if (_value !== "") {
      searchFields[convertDataIndex(dataIndex)] = _value;
    } else {
      delete searchFields[convertDataIndex(dataIndex)];
    }

    this.setState({
      searchFields: searchFields,
    });
  };

  handleSearchNumberChange = (event, dataIndex, min_or_max) => {
    const searchFields = this.state.searchFields;

    let _filterInfo =
      searchFields[convertDataIndex(dataIndex)] !== undefined
        ? searchFields[convertDataIndex(dataIndex)]
        : {};
    if (min_or_max === "min") {
      _filterInfo["gte"] = event.target.value;
      if (_filterInfo.gte === "") delete _filterInfo.gte;
    }
    if (min_or_max === "max") {
      _filterInfo["lte"] = event.target.value;
      if (_filterInfo.lte === "") delete _filterInfo.lte;
    }

    if (Object.keys(_filterInfo).length === 0) {
      delete searchFields[convertDataIndex(dataIndex)];
    } else {
      searchFields[convertDataIndex(dataIndex)] = _filterInfo;
    }

    this.setState({
      searchFields: searchFields,
    });
  };

  handleSearchDateChange = (value, dataIndex, min_or_max) => {
    const searchFields = this.state.searchFields;

    let _filterInfo =
      searchFields[convertDataIndex(dataIndex)] !== undefined
        ? searchFields[convertDataIndex(dataIndex)]
        : {};

    if (min_or_max === "min") {
      if (_filterInfo.gte === "" || !value) {
        delete _filterInfo.gte;
      } else {
        _filterInfo["gte"] = value.startOf("day").format();
      }
    }
    if (min_or_max === "max") {
      if (_filterInfo.lte === "" || !value) {
        delete _filterInfo.lte;
      } else {
        _filterInfo["lte"] = value.endOf("day").format();
      }
    }

    if (Object.keys(_filterInfo).length === 0) {
      delete searchFields[convertDataIndex(dataIndex)];
    } else {
      searchFields[convertDataIndex(dataIndex)] = _filterInfo;
    }

    this.setState({
      searchFields: searchFields,
    });
  };

  handleSearchSelectMultiChange = (value, dataIndex, equality) => {
    const searchFields = this.state.searchFields;
    const convertedDataIndex = convertDataIndex(dataIndex);

    if (!value || isEmpty(value)) {
      delete searchFields[convertedDataIndex];
    } else {
      searchFields[convertedDataIndex] = { [equality]: value };
    }

    this.setState({
      searchFields: searchFields,
    });
  };

  handleTableChange = (...args) => {
    const { localSortColumns = [] } = this.props;

    // const pagination = args.length >= 2 ? args[1] : null;
    const sorter = args.length >= 3 ? args[2] : null;
    const query = parse(this.props.location.search);
    const columnName = convertDataIndex(get(sorter, "column.dataIndex"));
    const { sortBy } = this.state;
    if (
      localSortColumns.includes(columnName) ||
      (!columnName && localSortColumns.includes(get(sortBy, "column")))
    ) {
      if (columnName) {
        this.setState({
          sortBy: { column: columnName, order: get(sorter, "order", false) },
        });
      } else {
        this.setState({
          sortBy: {},
        });
      }
      return;
    }

    const order = get(sortMapper, get(sorter, "order"));
    if (columnName) {
      this.setState({
        sortBy: { column: columnName, order: get(sorter, "order", false) },
      });
      const queryString = stringify({
        ...query,
        sort: `${order}${columnName}`,
      });
      this.props.history(
        `${this.props.baseUri}/${this.props.searchUri}?${queryString}`
      );
    } else {
      delete query["sort"];
      const queryString = stringify(query);
      this.props.history(
        `${this.props.baseUri}/${this.props.searchUri}?${queryString}`
      );
    }
  };

  showExportModal = () => {
    this.setState({ isExportModalVisible: true });
  };

  handleCancelExport = () => {
    this.setState({ isExportModalVisible: false });
  };

  exportFormRef = (formRef) => {
    this.exportRef = formRef;
  };

  changeColumnFormRef = (formRef) => {
    this.changeColumnRef = formRef;
  };

  handleSubmitExport = () => {
    const form = this.exportRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      } else {
        this.setState({ isLoadingExport: true });
        const query = parse(this.props.location.search);
        const searchFields = this.state.searchFields;
        const filters = stringifyFilters(searchFields);
        const queryString = stringify({
          ...query,
          filters: filters,
          export: values.number_of_records,
        });

        apiBase
          .get(`/${this.props.searchUri}?${queryString}`)
          .then((res) => {
            this.setState({
              isExportModalVisible: false,
              isLoadingExport: false,
            });
            const columns = this.state.columnState
              .filter((col) => col.exportable)
              .map((col) => convertDataIndex(col.dataIndex));

            const dataKey = this.props.searchUri.replaceAll("-", "_");
            downloadCSV({
              filename: `${dataKey}-${new Date().toJSON()}.csv`,
              data: res.data[`${dataKey}`].map((row) => flattenObject(row)),
              columns,
            });
            let msg = `${res.status}: Successfully Exported Units.`;
            message.success(msg);
            form.resetFields();
          })
          .catch((err) => {
            let msg = `${err.response.status}: ${err.response.data.message}`;
            message.error(msg);
            this.setState({
              isExportModalVisible: false,
              isLoadingExport: false,
            });
            form.resetFields();
          });
      }
    });
  };

  handleSubmitColumnChange = () => {
    const form = this.changeColumnRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      const fattenValues = flattenObject(values);
      let columnState = [...this.state.columnState];
      columnState = columnState.map((col) => {
        col.visible = fattenValues[convertDataIndex(col.dataIndex)];
        return col;
      });
      this.setState({
        columnState,
        isChangeColumnsVisible: false,
      });
    });
  };

  showColumnChangeModal = () => {
    this.setState({ isChangeColumnsVisible: true });
  };

  handleCancelColumnsChange = () => {
    this.setState({ isChangeColumnsVisible: false });
  };

  highlightedRender = ({ dataIndex, value, format = (value) => value }) => {
    const { searchFields } = this.state;
    return value ? (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[searchFields[convertDataIndex(dataIndex)]]}
        autoEscape
        textToHighlight={value.toString()}
      />
    ) : (
      format(value)
    );
  };

  searchInput = (dataIndex, name, first) => {
    const { searchFields } = this.state;
    return (
      <Input
        ref={(node) => {
          first && (this.firstInput = node);
        }}
        placeholder={`Search ${name}`}
        value={searchFields[convertDataIndex(dataIndex)]}
        onChange={(e) => this.handleSearchStringChange(e, dataIndex)}
        onPressEnter={(e) => e.keyCode === 13 && this.handleSearch()}
        autoComplete="off"
        className={styles.searchTextInput}
      />
    );
  };

  getSortOrder = (dataIndex) => {
    const { sortBy } = this.state;
    return get(sortBy, "column") === convertDataIndex(dataIndex)
      ? get(sortBy, "order")
      : false;
  };

  handleRowClick = (e, _to) => {
    e.stopPropagation();
    if (e.ctrlKey || e.metaKey) {
      window.open(_to, "_blank");
      return;
    }
    this.props.history(_to);
  };

  getStringColumnProps = ({
    title,
    dataIndex,
    globalSort = true,
    first = false,
    className = "",
    format = (value) => value,
  }) => {
    const column = {
      title,
      dataIndex,
      className,
    };

    if (globalSort) {
      return {
        ...column,
        sorter: true,
        sortOrder: this.getSortOrder(dataIndex),
        children: [
          {
            dataIndex,
            title: () => this.searchInput(dataIndex, title, first),
            render: (value) =>
              this.highlightedRender({ dataIndex, value, format }),
          },
        ],
      };
    } else {
      return {
        ...column,
        sortOrder: this.getSortOrder(dataIndex),
        sorter: (a, b) =>
          alphaSort(
            get(a, convertDataIndex(dataIndex)) -
              get(b, convertDataIndex(dataIndex))
          ),
        children: [
          {
            dataIndex,
            title: () => <div />,
          },
        ],
      };
    }
  };

  getIntegerColumnProps = ({
    title,
    dataIndex,
    globalSort = true,
    format = (value) => value,
    className = "",
  }) => {
    const { searchFields } = this.state;

    const column = {
      title,
      dataIndex,
      className,
    };

    if (globalSort) {
      return {
        ...column,
        sorter: true,
        sortOrder: this.getSortOrder(dataIndex),
        children: [
          {
            dataIndex,
            title: () => (
              <MinMaxDropdown
                name={title}
                dataIndex={dataIndex}
                searchFields={searchFields || {}}
                handleSearchNumberChange={this.handleSearchNumberChange}
                handleSearch={this.handleSearch}
                handleReset={this.handleReset}
              />
            ),
            render: (value) => (value !== null ? format(value) : null),
          },
        ],
      };
    } else {
      return {
        ...column,
        sortOrder: this.getSortOrder(dataIndex),
        sorter: (a, b) =>
          get(a, convertDataIndex(dataIndex)) -
          get(b, convertDataIndex(dataIndex)),
        children: [
          {
            dataIndex,
            title: () => <div />,
            render: (value) => (value !== null ? format(value) : null),
          },
        ],
      };
    }
  };

  handleSelectChange = (value, dataIndex) => {
    // Can generalize this to handle other index types if need
    if (dataIndex === "entity_id" && !!value) {
      const { dispatch, entities } = this.props;
      dispatch(
        actions.entity.setEntity(find(entities, { id: parseInt(value) }))
      );
    }

    const searchFields = this.state.searchFields;
    if (!!value) {
      searchFields[convertDataIndex(dataIndex)] = value;
    } else {
      delete searchFields[convertDataIndex(dataIndex)];
    }

    this.setState(
      {
        searchFields: searchFields,
      },
      () => {
        this.handleSearch();
      }
    );
  };

  selectInput = ({
    dataIndex,
    title,
    options,
    selectClassName = "",
    isMulti = false,
  }) => {
    const { searchFields } = this.state;

    return (
      <Select
        placeholder={`Select ${title}`}
        value={searchFields[convertDataIndex(dataIndex)]}
        className={`${styles.selectOption} ${selectClassName}`}
        onChange={(e) => this.handleSelectChange(e, dataIndex)}
        mode={isMulti ? "multiple" : null}
        allowClear
      >
        {options.map((option, idx) => (
          <Select.Option value={String(option.value)} key={idx}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
    );
  };

  handleBooleanChange = (e, dataIndex) => {
    const searchFields = this.state.searchFields;
    if (!!e.target.checked) {
      searchFields[convertDataIndex(dataIndex)] = e.target.checked;
    } else {
      delete searchFields[convertDataIndex(dataIndex)];
    }

    this.setState(
      {
        searchFields: searchFields,
      },
      () => {
        this.handleSearch();
      }
    );
  };

  booleanInput = ({ dataIndex }) => {
    const { searchFields } = this.state;

    return (
      <Checkbox
        checked={searchFields[convertDataIndex(dataIndex)]}
        className={styles.booleanInput}
        onChange={(e) => this.handleBooleanChange(e, dataIndex)}
      />
    );
  };

  getSelectColumnProps = ({
    title,
    dataIndex,
    globalSort = true,
    format = ({ value }) => value,
    className = "",
    selectClassName = "",
    isMulti,
    options = [],
    globalStateKey = null,
    globalStateOptions = () => [],
  }) => {
    const column = {
      title,
      dataIndex,
      className,
    };

    if (dataIndex === "entity_id") {
      // Should probably remove this...
      const { entities } = this.props;
      format = ({ value }) => {
        return get(
          entities.find((e) => e.id === value),
          "name",
          value
        );
      };
    }

    if (globalStateKey) {
      const { globalState } = this.props;
      options = globalStateOptions(get(globalState, globalStateKey, []));
    }

    if (globalSort) {
      return {
        ...column,
        sorter: true,
        sortOrder: this.getSortOrder(dataIndex),
        children: [
          {
            dataIndex,
            title: () =>
              this.selectInput({
                dataIndex,
                title,
                options,
                selectClassName,
                isMulti,
              }),
            render: (value, record, idx) =>
              value !== null ? format({ value, record, idx }) : null,
          },
        ],
      };
    } else {
      return {
        ...column,
        sortOrder: this.getSortOrder(dataIndex),
        sorter: (a, b) =>
          get(a, convertDataIndex(dataIndex)) -
          get(b, convertDataIndex(dataIndex)),
        children: [
          {
            dataIndex,
            title: () => <div />,
            render: (value, record, idx) =>
              value !== null ? format({ value, record, idx }) : null,
          },
        ],
      };
    }
  };

  getSelectMultiColumnProps = ({
    title,
    dataIndex,
    globalSort = true,
    format = ({ value }) => value,
    className = "",
    options = [],
    globalStateKey = null,
    globalStateOptions = () => [],
    operatorOptions,
    // isMulti = false,
  }) => {
    const column = {
      title,
      dataIndex,
      className,
    };

    const { searchFields } = this.state;

    if (globalStateKey) {
      const { globalState } = this.props;
      options = globalStateOptions(get(globalState, globalStateKey, []));
    }

    if (globalSort) {
      return {
        ...column,
        sorter: true,
        sortOrder: this.getSortOrder(dataIndex),
        children: [
          {
            dataIndex,
            title: () => (
              <SelectMultiDropdown
                title={title}
                dataIndex={dataIndex}
                searchFields={searchFields || {}}
                handleSearchSelectMultiChange={
                  this.handleSearchSelectMultiChange
                }
                handleSearch={this.handleSearch}
                handleReset={this.handleReset}
                options={options}
                {...(!!operatorOptions && {
                  operatorOptions: operatorOptions,
                })}
                // isMulti={isMulti}
              />
            ),
            render: (value, record, idx) =>
              value !== null ? format({ value, record, idx }) : null,
          },
        ],
      };
    } else {
      return {
        ...column,
        sortOrder: this.getSortOrder(dataIndex),
        sorter: (a, b) =>
          get(a, convertDataIndex(dataIndex)) -
          get(b, convertDataIndex(dataIndex)),
        children: [
          {
            dataIndex,
            title: () => <div />,
            render: (value, record, idx) =>
              value !== null ? format({ value, record, idx }) : null,
          },
        ],
      };
    }
  };

  getLinkColumnProps = ({
    title,
    dataIndex,
    className = `App__rowLink App__w7`,
    classNameChild = "App__rowIcon",
    baseUrl,
    searchable = false,
    sortable = false,
  }) => {
    return {
      title,
      dataIndex,
      children: [
        {
          dataIndex,
          className,
          ...(sortable
            ? {
                sorter: true,
                sortOrder: this.getSortOrder(dataIndex),
              }
            : {}),
          ...(searchable
            ? { title: () => this.searchInput(dataIndex, title) }
            : {}),
          render: (value) => {
            return <div className={classNameChild}>{value}</div>;
          },
          onCell: (record) => ({
            onClick: (e) =>
              get(record, convertDataIndex(dataIndex))
                ? this.handleRowClick(
                    e,
                    `${baseUrl}/${get(record, convertDataIndex(dataIndex))}`
                  )
                : null,
          }),
        },
      ],
    };
  };

  getDateTimeColumnProps = ({
    title,
    dataIndex,
    globalSort = true,
    format = (value) => value,
    className = "",
  }) => {
    const { searchFields } = this.state;

    const column = {
      title,
      dataIndex,
      className,
    };

    if (globalSort) {
      return {
        ...column,
        sorter: true,
        sortOrder: this.getSortOrder(dataIndex),
        children: [
          {
            dataIndex,
            title: () => (
              <DateRangeDropdown
                name={title}
                dataIndex={dataIndex}
                searchFields={searchFields || {}}
                handleSearchDateChange={this.handleSearchDateChange}
                handleSearch={this.handleSearch}
                handleReset={this.handleReset}
              />
            ),
            render: (value) => (value !== null ? format(value) : null),
          },
        ],
      };
    } else {
      return {
        ...column,
        sortOrder: this.getSortOrder(dataIndex),
        sorter: (a, b) =>
          get(a, convertDataIndex(dataIndex)) -
          get(b, convertDataIndex(dataIndex)),
        children: [
          {
            dataIndex,
            title: () => <div />,
            render: (value) => (value !== null ? format(value) : null),
          },
        ],
      };
    }
  };

  getBooleanColumnProps = ({
    title,
    dataIndex,
    globalSort = true,
    format = ({ value }) => capitalize(value),
    className = "",
  }) => {
    const column = {
      title,
      dataIndex,
      className,
    };

    if (globalSort) {
      return {
        ...column,
        sorter: true,
        sortOrder: this.getSortOrder(dataIndex),
        children: [
          {
            dataIndex,
            title: () => this.booleanInput({ dataIndex }),
            render: (value, record, idx) =>
              value !== null ? format({ value, record, idx }) : null,
          },
        ],
      };
    } else {
      return {
        ...column,
        sortOrder: this.getSortOrder(dataIndex),
        sorter: (a, b) =>
          get(a, convertDataIndex(dataIndex)) -
          get(b, convertDataIndex(dataIndex)),
        children: [
          {
            dataIndex,
            title: () => <div />,
            render: (value, record, idx) =>
              value !== null ? format({ value, record, idx }) : null,
          },
        ],
      };
    }
  };

  getColumnProps = (column) => {
    if (column.type === columnTypes.LINK) {
      return this.getLinkColumnProps({ ...column });
    } else if (column.type === columnTypes.INTEGER) {
      return this.getIntegerColumnProps({ ...column });
    } else if (column.type === columnTypes.STRING) {
      return this.getStringColumnProps({ ...column });
    } else if (column.type === columnTypes.SELECT) {
      return this.getSelectColumnProps({ ...column });
    } else if (column.type === columnTypes.SELECT_MULTI) {
      return this.getSelectMultiColumnProps({ ...column });
    } else if (column.type === columnTypes.DATE_TIME) {
      return this.getDateTimeColumnProps({ ...column });
    } else if (column.type === columnTypes.BOOLEAN) {
      return this.getBooleanColumnProps({ ...column });
    } else {
      return column;
    }
  };

  render() {
    return React.cloneElement(this.props.children, {
      ...this.state,
      totalRecords: this.state.totalRecords || this.state.totalRecordsTemp,
      handleSearch: this.handleSearch,
      executeSearch: this.executeSearch,
      handleRecordsChange: this.handleRecordsChange,
      handlePaginationChange: this.handlePaginationChange,
      handleReset: this.handleReset,
      handleFilterClose: this.handleFilterClose,
      handleSearchStringChange: this.handleSearchStringChange,
      handleSearchNumberChange: this.handleSearchNumberChange,
      handleSearchDateChange: this.handleSearchDateChange,
      handleSelectChange: this.handleSelectChange,
      handleTableChange: this.handleTableChange,
      handleSubmitExport: this.handleSubmitExport, // Export
      showExportModal: this.showExportModal, // Export
      handleCancelExport: this.handleCancelExport, // Export
      handleSubmitColumnChange: this.handleSubmitColumnChange, // ColumnChange
      showColumnChangeModal: this.showColumnChangeModal, // ColumnChange
      handleCancelColumnsChange: this.handleCancelColumnsChange, // ColumnChange
      exportFormRef: this.exportFormRef,
      changeColumnFormRef: this.changeColumnFormRef,
      highlightedRender: this.highlightedRender,
      searchInput: this.searchInput,
      getStringColumnProps: this.getStringColumnProps,
      getIntegerColumnProps: this.getIntegerColumnProps,
      getLinkColumnProps: this.getLinkColumnProps,
      getColumnProps: this.getColumnProps,
    });
  }
}

BaseSearch.defaultProps = {
  baseUri: "",
};

// export default withRouter(BaseSearch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BaseSearch));
