import React, { useState } from "react";
import { apiBase } from "../../utils/apiBase";
import { get } from "lodash";

import { Button, Form, Modal, message } from "antd";

import SearchSelect from "../common/SearchSelect";

const PermitUnitsCopyForm = (props) => {
  const { permit, getPermit, getPermitUnits } = props;
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    form.resetFields();
    setVisible(false);
  };

  const onFinish = (values) => {
    setLoading(true);
    apiBase
      .post("/permit-units/copy", {
        permit_id: permit.id,
        copy_permit_id: values.copy_permit_id,
      })
      .then((res) => {
        let msg = `${res.status}: Successfully copied permit units.`;
        message.success(msg);
        getPermitUnits();
        getPermit();
        handleCancel();
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = () => {
    message.error("Please check the form for errors");
  };

  return (
    <>
      <Button
        type="secondary"
        onClick={() => setVisible(true)}
        disabled={loading}
        className="App__mr-5"
      >
        Copy units
      </Button>
      <Modal
        title="Copy Permit Units"
        open={visible}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel} disabled={loading}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => form.submit()}
          >
            Copy
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Source Permit ID"
            name="copy_permit_id"
            rules={[
              { required: true, message: "Please enter a source permit ID" },
            ]}
          >
            <SearchSelect
              searchUri="permits"
              searchField="id"
              placeholder="Search permits"
              renderLabel={(row) => row.lease}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default PermitUnitsCopyForm;
