import { message } from "antd";
import { apiBase } from "../utils/apiBase";

export const types = {
  APP_CONFIG_SET: "APP_CONFIG_SET",
};

export const setAppConfig = (value) => ({
  type: types.APP_CONFIG_SET,
  value,
});

export const getAppConfig = () => (dispatch) => {
  return apiBase
    .get(`app-config`)
    .then((res) => dispatch(setAppConfig(res.data)))
    .catch((err) => {
      dispatch(setAppConfig({}));
      message.error("Failed load app config.");
    });
};

export const updateAppConfig = (values) => (dispatch) => {
  return apiBase
    .patch(`app-config`, values)
    .then((res) => {
      dispatch(setAppConfig(res.data));
      message.success("Successfully updated settings.");
    })
    .catch((err) => {
      message.error("Failed to update app config.");
    });
};

export const initialState = {
  data: {},
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case types.APP_CONFIG_SET:
      return {
        ...state,
        data: action.value,
      };
    default:
      return state;
  }
}

const actions = {
  getAppConfig,
  updateAppConfig,
};

export default actions;
