import React, { useState } from "react";

import BaseSearch from "../search/BaseSearch";
import Took from "../search/Took";
import TotalRecords from "../search/TotalRecords";
import { columnTypes } from "../../utils/search";
import { arrayContainsOperators } from "../search/constants";
import { formatDate } from "../../utils/dates";
import {
  DownloadOutlined,
  TableOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { apiBase } from "../../utils/apiBase";

import ExportForm from "../forms/ExportForm";
import ChangeColumnsForm from "../forms/ChangeColumnsForm";
import PermitBulkEditForm from "./PermitBulkEditForm";
import PermitPlatCreateForm from "./PermitPlatCreateForm";
import RecordLabels from "../common/RecordLabels";
import FilterTags from "../search/FilterTags";
import {
  Row,
  Col,
  Table,
  Select,
  Button,
  Tooltip,
  Pagination,
  message,
} from "antd";

const baseColumnState = [
  {
    dataIndex: "id",
    title: "Id",
    editable: false,
    type: columnTypes.LINK,
    baseUrl: "/permits",
    sortable: true,
  },
  {
    dataIndex: "permit_plat_id",
    title: "Plat Id",
    type: columnTypes.LINK,
    baseUrl: "/permit-plats",
    className: `App__rowLink App__w8`,
    sortable: true,
  },
  {
    title: "Labels",
    dataIndex: ["label_ids"],
    className: `App__w20`,
    exportable: false,
    type: columnTypes.SELECT_MULTI,
    globalStateKey: "labels.data",
    globalStateOptions: (data) => {
      const recordLabels = data
        .filter((d) => d.record_type === "permit")
        .map((d) => ({ value: String(d.id), label: d.value }));
      recordLabels.push({ value: "-1", label: "No Label" });
      return recordLabels;
    },
    operatorOptions: arrayContainsOperators,
    format: ({ record }) => {
      return (
        <RecordLabels
          record_type="permit"
          record_id={record.id}
          label_ids={record.label_ids}
        />
      );
    },
  },
  {
    dataIndex: "unit_count",
    title: "Unit count",
    type: columnTypes.INTEGER,
    className: `App__w10`,
  },
  {
    dataIndex: "universal_doc_number",
    title: "Universal doc number",
    children: [
      {
        dataIndex: ["universal_doc_number"],
        title: () => <div />,
        className: `App__rowLink App__w14`,
        render: (value) => <div className="App__rowIcon">{value}</div>,
        onCell: (record) => ({
          onClick: (e) => {
            e.stopPropagation();
            window.open(record.lease_url, "_blank");
          },
        }),
      },
    ],
  },
  {
    dataIndex: "rrc_id",
    title: "RRC ID",
    type: columnTypes.STRING,
    className: `App__w12`,
  },
  {
    dataIndex: "api_number",
    title: "API number",
    type: columnTypes.STRING,
    className: `App__w12`,
  },
  {
    dataIndex: "county",
    title: "County",
    type: columnTypes.STRING,
    className: `App__w14`,
  },
  {
    dataIndex: "district",
    title: "District",
    type: columnTypes.STRING,
    visible: false,
  },
  {
    dataIndex: "submitted_date",
    title: "Submitted date",
    type: columnTypes.DATE_TIME,
    format: (value) => formatDate(value, "YYYY-MM-DD", true),
    className: `App__w12`,
  },
  {
    dataIndex: "approved_date",
    title: "Approved date",
    type: columnTypes.DATE_TIME,
    format: (value) => formatDate(value, "YYYY-MM-DD", true),
    className: `App__w12`,
    visible: false,
  },
  {
    dataIndex: "lease",
    title: "Lease",
    type: columnTypes.STRING,
  },
  {
    dataIndex: "well_number",
    title: "Well number",
    type: columnTypes.STRING,
  },
  {
    dataIndex: "operator_name",
    title: "Operator name",
    type: columnTypes.STRING,
  },
  {
    dataIndex: "operator_number",
    title: "Operator number",
    type: columnTypes.STRING,
    className: `App__w12`,
    visible: false,
  },
  {
    dataIndex: "amend",
    title: "Amend",
    type: columnTypes.SELECT,
    options: [
      { value: "false", label: "False" },
      { value: "true", label: "True" },
    ],
    className: `App__w6`,
    selectClassName: `App__w6`,
    format: ({ value }) => {
      return value === "true" ? "True" : "False";
    },
  },
  {
    dataIndex: "wellbore_profiles",
    title: "Wellbore profiles",
    type: columnTypes.STRING,
  },
  {
    dataIndex: "filing_purpose",
    title: "Filing purpose",
    type: columnTypes.STRING,
  },
  {
    dataIndex: "status",
    title: "Status",
    type: columnTypes.STRING,
    visible: false,
  },
  {
    title: "Created at",
    dataIndex: "created_at",
    type: columnTypes.DATE_TIME,
    format: (value) => formatDate(value, "YYYY-MM-DD"),
    className: `App__w10`,
  },
  {
    title: "Updated at",
    dataIndex: "updated_at",
    type: columnTypes.DATE_TIME,
    format: (value) => formatDate(value, "YYYY-MM-DD"),
    className: `App__w10`,
  },
];

const Permits = (props) => {
  const [selectedPermits, setSelectedPermits] = useState([]);
  const [selectedPermitKeys, setSelectedPermitKeys] = useState([]);
  const [isBulkEditModalVisible, setIsBulkEditModalVisible] = useState(false);
  const [isLoadingBulkEdit, setIsLoadingBulkEdit] = useState(false);

  const {
    isLoadingRecords,
    isLoadingCount,
    records,
    columnState,
    limit,
    page,
    totalRecords,
    tookRecords,
    tookCount,
    searchFields,
    isExportModalVisible,
    isChangeColumnsVisible,
    isLoadingExport,
    executeSearch,
    location,
  } = props;

  const {
    handleSearch,
    handleRecordsChange,
    handlePaginationChange,
    handleFilterClose,
    handleTableChange,
    handleSubmitExport,
    showExportModal,
    handleCancelExport,
    handleSubmitColumnChange,
    showColumnChangeModal,
    handleCancelColumnsChange,
    exportFormRef,
    changeColumnFormRef,
    getColumnProps,
  } = props;

  const onSelectPermitChange = (selectedRowKeys, selectedRows) => {
    setSelectedPermitKeys(selectedRowKeys);
    setSelectedPermits(selectedRows);
  };

  const showBulkEditModal = () => {
    setIsBulkEditModalVisible(true);
  };

  const handleCancelBulkEdit = () => {
    setIsBulkEditModalVisible(false);
  };

  const handleSubmitBulkEdit = (values) => {
    setIsLoadingBulkEdit(true);

    let data = {
      update_values: values,
      permit_ids: selectedPermits.map((row) => row.id),
    };

    const queryString = location.search;

    apiBase
      .patch(`/permits${queryString}`, data)
      .then((res) => {
        executeSearch();
        setIsBulkEditModalVisible(false);
        setIsLoadingBulkEdit(false);
        setSelectedPermits([]);
        setSelectedPermitKeys([]);
        let msg = `${res.status}: ${res.data.message}`;
        message.success(msg);
      })
      .catch((err) => {
        let msg = `${err.response.status}: ${err.response.data.message}`;
        message.error(msg);
        setIsBulkEditModalVisible(false);
        setIsLoadingBulkEdit(false);
        setSelectedPermits([]);
        setSelectedPermitKeys([]);
      });
  };

  const rowSelection = {
    selectedRowKeys: selectedPermitKeys,
    onChange: onSelectPermitChange,
  };

  let columns = columnState
    .map((column, idx) => ({
      key: idx,
      ...column,
      ...getColumnProps(column),
    }))
    .filter((col) => col.visible === true);

  return (
    <div>
      <Row className="App__rowContent">
        <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16}>
          <Button className="App__mr-5" onClick={() => handleSearch()}>
            Search
          </Button>
          <FilterTags
            searchFields={searchFields}
            columnState={columnState}
            handleFilterClose={handleFilterClose}
          />
        </Col>
        <Col
          span={8}
          xs={24}
          sm={24}
          md={8}
          lg={8}
          xl={8}
          className="App__rightActions"
        >
          {selectedPermitKeys.length > 0 && (
            <PermitPlatCreateForm
              selectedPermits={selectedPermits}
              onSuccess={() => {
                executeSearch();
              }}
            />
          )}
          <Tooltip placement="bottomRight" title={"Bulk edit"}>
            <Button onClick={showBulkEditModal} className="App__iconButton">
              <EditOutlined />
            </Button>
          </Tooltip>
          <PermitBulkEditForm
            visible={isBulkEditModalVisible}
            isLoading={isLoadingBulkEdit}
            onCancel={handleCancelBulkEdit}
            onCreate={handleSubmitBulkEdit}
            selectedPermits={selectedPermits}
          />
          <Tooltip placement="bottomRight" title={"Export search results"}>
            <Button className="App__iconButton" onClick={showExportModal}>
              <DownloadOutlined />
            </Button>
          </Tooltip>
          <ExportForm
            wrappedComponentRef={exportFormRef}
            visible={isExportModalVisible}
            isLoading={isLoadingExport}
            onCancel={handleCancelExport}
            onCreate={handleSubmitExport}
            totalRecords={totalRecords}
          />
          <Tooltip placement="bottomRight" title={"Change visible columns"}>
            <Button onClick={showColumnChangeModal} className="App__iconButton">
              <TableOutlined />
            </Button>
          </Tooltip>
          <ChangeColumnsForm
            wrappedComponentRef={changeColumnFormRef}
            visible={isChangeColumnsVisible}
            isLoading={isLoadingRecords}
            onCancel={handleCancelColumnsChange}
            onOk={handleSubmitColumnChange}
            columnState={columnState}
          />
          <Select
            value={limit}
            onChange={handleRecordsChange}
            className={"App__mr-5"}
          >
            <Select.Option value={25}>25</Select.Option>
            <Select.Option value={50}>50</Select.Option>
            <Select.Option value={100}>100</Select.Option>
          </Select>
          <TotalRecords
            title={"Permits"}
            isLoadingCount={isLoadingCount}
            totalRecords={totalRecords}
          />
        </Col>
      </Row>
      <Row className="App__rowContent">
        <Col span={24}>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={records}
            onChange={handleTableChange}
            showSorterTooltip={false}
            pagination={false}
            className="App__smallTables"
            loading={isLoadingRecords || isLoadingBulkEdit}
            scroll={{ x: "100%" }}
            size="small"
            bordered
          />
          <div className="App__searchFooterContainer">
            <Took
              isLoadingRecords={isLoadingRecords}
              isLoadingCount={isLoadingCount}
              tookRecords={tookRecords}
              tookCount={tookCount}
            />
            <Pagination
              current={page}
              pageSize={limit}
              total={totalRecords}
              onChange={handlePaginationChange}
              className="App__pagination"
              showSizeChanger={false}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

const PermitsWithSearch = (props) => (
  <BaseSearch
    baseColumnState={baseColumnState}
    localSortColumns={[]}
    searchUri="permits"
  >
    <Permits {...props} />
  </BaseSearch>
);

export default PermitsWithSearch;
