import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withParams } from "../utils/withParams";
import { get } from "lodash";
import { clsx } from "clsx";
import actions from "../actions";

import {
  Row,
  Col,
  Divider,
  Form,
  Button,
  Input,
  Select,
  Tooltip,
  Alert,
  message,
} from "antd";

import { QuestionCircleOutlined } from "@ant-design/icons";

import styles from "./CRMSettings.module.scss";

const mapStateToProps = (state) => ({
  appConfig: get(state.appConfig, "data", {}),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const explinationInterestFilters = `This configuration allows you to edit filters on interests. 
These will be the criterea for filtering out interests when making offers. You can
tell if an interest is being filtered out on a unit page if it is highlighted in red.`;

const explinationLeadStatus = `This configuration allows you to edit lead status options. 
These will be the lead status options that show up when creating new leads. Note that
any leads with old lead status values will remain the same as they were.`;

const CRMSettings = (props) => {
  const { dispatch, appConfig } = props;

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(actions.appConfig.getAppConfig());
  }, [dispatch]);

  const onFinish = async (values) => {
    setLoading(true);
    await dispatch(
      actions.appConfig.updateAppConfig({
        lead_status: values.lead_status,
        interest_filters: {
          owner_name: values.interest_filters_owner_name,
          interest_type: values.interest_filters_interest_type,
        },
      })
    );
    setLoading(false);
  };

  const onFinishFailed = () => {
    message.error("Form fields reqired");
  };

  return (
    <div className="App__rowContent">
      <Row gutter={16}>
        <Col span={24}>
          <div className={styles.card}>
            <div className={styles.cardTitle}>
              <div className={styles.leftTitle}>
                <h2>CRM settings</h2>
              </div>
              <div className={styles.rightTitle}>
                <div>
                  <Button
                    onClick={() => form.resetFields()}
                    className={styles.cardHeaderAction}
                    disabled={loading}
                  >
                    Reset
                  </Button>
                  <Button
                    className={styles.cardHeaderAction}
                    type="primary"
                    loading={loading}
                    htmlType="submit"
                    onClick={() => {
                      form.submit();
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
            <div className={styles.cardBody}>
              <Row gutter={24}>
                <Col span={24}>
                  <Form
                    name="basic"
                    layout="vertical"
                    form={form}
                    initialValues={{
                      lead_status: get(appConfig, "lead_status", []),
                      interest_filters_owner_name: get(
                        appConfig,
                        "interest_filters.owner_name",
                        []
                      ),
                      interest_filters_interest_type: get(
                        appConfig,
                        "interest_filters.interest_type",
                        []
                      ),
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    disabled={loading}
                  >
                    <Divider orientation="left" orientationMargin="0">
                      Lead settings
                    </Divider>
                    <Row gutter={24}>
                      <Col span={24}>
                        <Alert
                          message={explinationLeadStatus}
                          type="info"
                          showIcon
                        />
                      </Col>
                    </Row>
                    <Row className="App__nthRow">
                      <Col span={24}>
                        <Form.Item
                          label={
                            <span>
                              Lead Status&nbsp;
                              <Tooltip
                                title="These will be the lead status options that show up when creating new leads."
                                placement="rightTop"
                              >
                                <QuestionCircleOutlined />
                              </Tooltip>
                            </span>
                          }
                          name={"lead_status"}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Select
                            mode="tags"
                            disabled={loading}
                            style={{ width: "100%" }}
                            onPressEnter={() => form.submit()}
                            className={clsx({
                              [styles.disabledItem]: loading,
                            })}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Divider orientation="left" orientationMargin="0">
                      Interest filters
                    </Divider>
                    <Row gutter={24}>
                      <Col span={24}>
                        <Alert
                          message={explinationInterestFilters}
                          type="info"
                          showIcon
                        />
                      </Col>
                    </Row>
                    <Row className="App__nthRow">
                      <Col span={24}>
                        <Form.Item
                          label={
                            <span>
                              Owner Name&nbsp;
                              <Tooltip
                                title="These filters will be applied to the Owner name."
                                placement="rightTop"
                              >
                                <QuestionCircleOutlined />
                              </Tooltip>
                            </span>
                          }
                          name={"interest_filters_owner_name"}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Input.TextArea
                            type="string"
                            disabled={loading}
                            autoSize={true}
                          />
                        </Form.Item>
                        <Form.Item
                          label={
                            <span>
                              Interest Type&nbsp;
                              <Tooltip
                                title="These filters will be applied to the Interest type."
                                placement="rightTop"
                              >
                                <QuestionCircleOutlined />
                              </Tooltip>
                            </span>
                          }
                          name={"interest_filters_interest_type"}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Input.TextArea
                            type="string"
                            disabled={loading}
                            autoSize={true}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withParams(CRMSettings));
