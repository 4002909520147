import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { ThunderboltOutlined } from "@ant-design/icons";
import ReactDOM from "react-dom/client";
import mapboxgl from "mapbox-gl";
import Mousetrap from "mousetrap";
import { Provider } from "react-redux";

import Popup from "../Popup/Popup";
import { WELL_LAYERS } from "../constants";
import { CLICKABLE_LAYERS as UNITS_CLICKABLE_LAYERS } from "../layers/units";
import { CLICKABLE_LAYERS as ASSETS_CLICKABLE_LAYERS } from "../layers/assets";
import { CLICKABLE_LAYERS as LANDTRAC_UNITS_CLICKABLE_LAYERS } from "../layers/landtracUnits";
import { CLICKABLE_LAYERS as PERMIT_PLATS_CLICKABLE_LAYERS } from "../layers/permitPlats";
import { CLICKABLE_LAYERS as TRACTS_CLICKABLE_LAYERS } from "../layers/tracts";
import { setHighlightFilters } from "../layers";
import mapActions from "../../../actions/map";

import styles from "./ClickLayerControl.module.scss";
import popupStyles from "../Popup/Popup.module.scss";

const CLICKABLE_LAYERS = [
  ...WELL_LAYERS,
  ...UNITS_CLICKABLE_LAYERS,
  ...ASSETS_CLICKABLE_LAYERS,
  ...LANDTRAC_UNITS_CLICKABLE_LAYERS,
  ...PERMIT_PLATS_CLICKABLE_LAYERS,
  ...TRACTS_CLICKABLE_LAYERS,
];

const CLICK_RADIUS = 2;

const ClickLayerControl = (props) => {
  const { mapRef } = props;

  const popupRef = useRef();
  const rootRef = useRef(null);
  const dispatch = useDispatch();
  const store = useStore();

  const isActive = useSelector((state) => state.map.controls.clickLayer);

  useEffect(() => {
    if (!mapRef.current) return;

    const map = mapRef.current;

    const onClose = () => {
      setHighlightFilters(mapRef, null, null);
      if (rootRef.current) {
        rootRef.current.unmount();
        rootRef.current = null;
      }
      if (popupRef.current) {
        popupRef.current.remove();
        popupRef.current = null;
      }
    };

    const clickHandler = (e) => {
      onClose();

      const features = map.queryRenderedFeatures([
        [e.point.x - CLICK_RADIUS, e.point.y - CLICK_RADIUS],
        [e.point.x + CLICK_RADIUS, e.point.y + CLICK_RADIUS],
      ]);

      const formattedFeatures = features.filter((feat) =>
        CLICKABLE_LAYERS.includes(feat.layer.id)
      );

      if (formattedFeatures.length > 0) {
        const popupInstance = new mapboxgl.Popup({
          maxWidth: "400px",
          maxHeight: "600px",
          closeButton: false,
          className: popupStyles.popup,
        });

        popupRef.current = popupInstance;

        const popupNode = document.createElement("div");
        rootRef.current = ReactDOM.createRoot(popupNode);
        rootRef.current.render(
          <Provider store={store}>
            <Popup
              mapRef={mapRef}
              features={formattedFeatures}
              onClose={onClose}
            />
          </Provider>
        );

        popupInstance.setLngLat(e.lngLat);
        popupInstance.setDOMContent(popupNode);
        popupInstance.addTo(map);
      } else {
        setHighlightFilters(mapRef, null, null);
      }
    };

    if (isActive) {
      map.on("click", clickHandler);
    } else {
      map.off("click", clickHandler);
    }

    for (const layer of CLICKABLE_LAYERS) {
      if (isActive) {
        mapRef.current.on("mouseenter", layer, () => {
          mapRef.current.getCanvas().style.cursor = "pointer";
        });

        mapRef.current.on("mousemove", layer, () => {
          mapRef.current.getCanvas().style.cursor = "pointer";
        });

        mapRef.current.on("mouseleave", layer, () => {
          mapRef.current.getCanvas().style.cursor = "";
        });
      } else {
        mapRef.current.on("mouseenter", layer, () => {
          mapRef.current.getCanvas().style.cursor = "";
        });

        mapRef.current.on("mousemove", layer, () => {
          mapRef.current.getCanvas().style.cursor = "";
        });

        mapRef.current.on("mouseleave", layer, () => {
          mapRef.current.getCanvas().style.cursor = "";
        });
      }
    }

    const escHandler = (e) => {
      e.preventDefault();
      onClose();
    };

    Mousetrap.bind("esc", escHandler);

    return () => {
      map.off("click", clickHandler);
      if (rootRef.current) {
        rootRef.current.unmount();
        rootRef.current = null;
      }
      if (popupRef.current) {
        popupRef.current.remove();
        popupRef.current = null;
      }
      Mousetrap.unbind("esc");
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef, isActive]);

  const toggleActive = () => {
    dispatch(mapActions.setClickLayer(!isActive));
  };

  return (
    <div onClick={toggleActive} className={styles.main}>
      <ThunderboltOutlined style={{ color: isActive ? "#FF5F23" : "black" }} />
    </div>
  );
};

export default ClickLayerControl;
