import React, { useState } from "react";
import { apiBase } from "../../utils/apiBase";
import { get } from "lodash";

import { Button, Form, Modal, message } from "antd";

import SearchSelect from "./SearchSelect";

const TractAssetLinkForm = (props) => {
  const { asset, tract, getAssetTracts, getTractAssets } = props;
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    form.resetFields();
    setVisible(false);
  };

  const onFinish = (values) => {
    setLoading(true);
    apiBase
      .post("/tract-assets", {
        tract_id: values.tract_id,
        asset_id: values.asset_id,
      })
      .then((res) => {
        let msg = `${res.status}: Successfully linked tract and asset.`;
        message.success(msg);
        if (getAssetTracts) getAssetTracts();
        if (getTractAssets) getTractAssets();
        handleCancel();
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = () => {
    message.error("Please check the form for errors");
  };

  // Set initial values if asset or tract is provided
  React.useEffect(() => {
    if (visible) {
      const initialValues = {};
      if (asset) initialValues.asset_id = asset.id;
      if (tract) initialValues.tract_id = tract.id;
      form.setFieldsValue(initialValues);
    }
  }, [visible, asset, tract, form]);

  return (
    <>
      <Button
        type="secondary"
        onClick={() => setVisible(true)}
        disabled={loading}
        className="App__mr-5"
      >
        Link
      </Button>
      <Modal
        title="Link tract and asset"
        open={visible}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel} disabled={loading}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => form.submit()}
          >
            Link
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Tract"
            name="tract_id"
            rules={[{ required: true, message: "Please select a tract" }]}
          >
            <SearchSelect
              searchUri="tracts"
              searchField="name"
              placeholder="Search tracts"
              renderLabel={(row) => row.name}
              defaultOptions={[{ value: tract?.id, label: tract?.name }]}
              disabled={!!tract}
            />
          </Form.Item>
          <Form.Item
            label="Asset"
            name="asset_id"
            rules={[{ required: true, message: "Please select an asset" }]}
          >
            <SearchSelect
              searchUri="assets"
              searchField="name"
              placeholder="Search assets"
              renderLabel={(row) => row.name}
              defaultOptions={[{ value: asset?.id, label: asset?.name }]}
              disabled={!!asset}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default TractAssetLinkForm;
