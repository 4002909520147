import React, { useState, useEffect, useCallback } from "react";
import { apiBase } from "../../utils/apiBase";
import { withParams } from "../../utils/withParams";
import { Helmet } from "react-helmet";
import { get, pick, isEmpty } from "lodash";

import WellAssets from "./WellAssets";
import WellAssetCreateForm from "./WellAssetCreateForm";
import AppConfigSelect from "../common/AppConfigSelect";
import SearchSelect from "../common/SearchSelect";
import RawDataModal from "../common/RawDataModal";
import Files from "../common/Files";

import { Row, Col, Button, Input, Select, Form, Modal, message } from "antd";

import { ExclamationCircleFilled } from "@ant-design/icons";

import styles from "./Well.module.scss";

const { confirm } = Modal;

const labelProps = {
  labelAlign: "left",
  labelCol: {
    xxl: { span: 7, offset: 0 },
    xl: { span: 9 },
    lg: { span: 10 },
  },
};

const formFields = [
  "name",
  "api_number",
  "operator_id",
  "type",
  "county",
  "state",
  "status",
];

const Well = (props) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [well, setWell] = useState({});
  const [wellAssets, setWellAssets] = useState([]);

  const getWell = useCallback(async () => {
    try {
      setLoading(true);
      const res = await apiBase.get(`/wells/${props.params.id}`);
      setWell(get(res, "data", {}));
    } catch (err) {
      let msg = `${err.response.status}: Failed to load well ${props.params.id}`;
      message.error(msg);
    } finally {
      setLoading(false);
    }
  }, [props.params.id]);

  const getWellAssets = useCallback(async () => {
    try {
      setLoading(true);
      const res = await apiBase.get(`/wells/${props.params.id}/assets`);
      setWellAssets(get(res, "data", {}));
    } catch (err) {
      let msg = `${err.response.status}: Failed to load well assets ${props.params.id}`;
      message.error(msg);
    } finally {
      setLoading(false);
    }
  }, [props.params.id]);

  useEffect(() => {
    getWell();
    getWellAssets();
  }, [getWell, getWellAssets]);

  useEffect(() => {
    if (!isEmpty(well)) {
      form.setFieldsValue({
        ...pick(well, formFields),
      });
    }
  }, [form, well]);

  const onFinishFailed = () => {
    message.error("Form fields reqired");
  };

  const updateSubmit = (values) => {
    setLoading(true);
    apiBase
      .patch(`wells/${well.id}`, values)
      .then((res) => {
        setWell(res.data);
        let msg = `${res.status}: Successfully updated well.`;
        message.success(msg);
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
        form.resetFields();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteWell = async (wellId) => {
    setLoading(true);
    apiBase
      .delete(`/wells/${wellId}`)
      .then((res) => {
        setLoading(false);
        props.history("/portfolio/wells?limit=50&page=1");
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to delete well.`;
        message.error(msg);
        setLoading(false);
      });
  };

  const handleDelete = () => {
    confirm({
      title: "Are you sure you want to delete this well?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      okButtonProps: { disabled: loading, loading: loading },
      cancelButtonProps: { disabled: loading, loading: loading },
      onOk: () => deleteWell(well.id),
    });
  };

  const handleEnverusSync = (values) => {
    setLoading(true);
    apiBase
      .get(`wells/${well.id}/enverus-sync`, values)
      .then((res) => {
        setWell(res.data);
        let msg = `${res.status}: Successfully synced with enverus.`;
        message.success(msg);
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
        form.resetFields();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRRCCompletions = () => {
    function openLinkWithFormData(url, formData) {
      const form = document.createElement("form");
      form.method = "POST";
      form.action = url;
      form.target = "_blank"; // Opens in a new tab

      for (const [key, value] of formData.entries()) {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = value;
        form.appendChild(input);
      }

      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form); // Clean up the form element
    }

    const formData = new FormData();
    formData.append("formData.methodHndlr.inputValue", "search");
    formData.append("formData.actionFlagHndlr.inputValue", "0");
    formData.append(
      "searchArgs.apiNoHndlr.inputValue",
      well.api_number.substring(2)
    );

    openLinkWithFormData(
      "https://webapps.rrc.texas.gov/CMPL/publicSearchAction.do",
      formData
    );
  };

  return (
    <div className="App__rowContentDetail">
      <Helmet>
        <title>{`Well: ${well.name}`}</title>
      </Helmet>
      <Row gutter={4}>
        <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24} xxl={14}>
          <div className={styles.card}>
            <div className={styles.cardTitle}>
              <div className={styles.leftTitle}>
                <h2>Well</h2>
              </div>
              <div className={styles.rightTitle}>
                <div>
                  <Button onClick={() => handleRRCCompletions()}>
                    RRC Completions
                  </Button>
                  <Button
                    onClick={() => handleEnverusSync()}
                    className={styles.cardHeaderAction}
                    disabled={loading}
                    loading={loading}
                  >
                    Enverus sync
                  </Button>
                  <Button
                    onClick={() => handleDelete()}
                    className={styles.cardHeaderAction}
                    disabled={loading}
                    loading={loading}
                  >
                    Delete
                  </Button>
                  <Button
                    onClick={() => form.resetFields()}
                    className={styles.cardHeaderAction}
                    disabled={loading}
                  >
                    Reset
                  </Button>
                  <Button
                    className={styles.cardHeaderAction}
                    type="primary"
                    loading={loading}
                    htmlType="submit"
                    onClick={() => {
                      form.submit();
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
            <div className={styles.cardBody}>
              {!isEmpty(well) && (
                <div className={styles.compactForm}>
                  <Form
                    name="basic"
                    layout="horizontal"
                    form={form}
                    initialValues={{
                      ...pick(well, formFields),
                    }}
                    onFinish={updateSubmit}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    disabled={loading}
                  >
                    <Row gutter={16}>
                      <Col
                        span={12}
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={12}
                        xxl={12}
                      >
                        <Form.Item
                          label={"Name"}
                          name={"name"}
                          rules={[{ required: true, message: "" }]}
                          {...labelProps}
                        >
                          <Input
                            onPressEnter={() => form.submit()}
                            size="small"
                          />
                        </Form.Item>
                        <Form.Item label="Enverus name" {...labelProps}>
                          {!!get(well, "enverus_name") ? (
                            get(well, "enverus_name")
                          ) : (
                            <span>-</span>
                          )}
                        </Form.Item>
                        <Form.Item
                          label={"API number"}
                          name={"api_number"}
                          rules={[{ required: false, message: "" }]}
                          {...labelProps}
                        >
                          <Input
                            onPressEnter={() => form.submit()}
                            size="small"
                          />
                        </Form.Item>
                        <Form.Item
                          label="Operator"
                          name="operator_id"
                          rules={[{ required: true, message: "" }]}
                          {...labelProps}
                        >
                          <SearchSelect
                            searchUri="operators"
                            searchField="name"
                            placeholder="Search operators"
                            defaultOptions={[
                              {
                                value: get(well, "operator_id"),
                                label: get(well, "operator.name"),
                              },
                            ]}
                            size="small"
                          />
                        </Form.Item>
                        <Form.Item
                          label="Type"
                          name="type"
                          rules={[{ required: true, message: "" }]}
                          {...labelProps}
                        >
                          <Select size="small">
                            <Select.Option value={"oil"}>Oil</Select.Option>
                            <Select.Option value={"gas"}>Gas</Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item label="Total DOI" {...labelProps}>
                          {wellAssets.length > 0 ? (
                            wellAssets
                              .reduce(
                                (sum, asset) =>
                                  sum + (asset.decimal_interest || 0),
                                0
                              )
                              .toFixed(8)
                          ) : (
                            <span>-</span>
                          )}
                        </Form.Item>
                      </Col>
                      <Col
                        span={12}
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={12}
                        xxl={12}
                      >
                        <Form.Item label="Enverus data" {...labelProps}>
                          {well.enverus_data ? (
                            <RawDataModal
                              modalProps={{ title: "Enverus data" }}
                              data={well.enverus_data}
                            />
                          ) : (
                            <span>-</span>
                          )}
                        </Form.Item>
                        <Form.Item label="Enverus status" {...labelProps}>
                          {!!get(well, "enverus_status") ? (
                            get(well, "enverus_status")
                          ) : (
                            <span>-</span>
                          )}
                        </Form.Item>
                        <Form.Item
                          label="Status"
                          name="status"
                          rules={[{ required: true, message: "" }]}
                          {...labelProps}
                        >
                          <AppConfigSelect
                            appConfigKey="well_status"
                            size="small"
                          />
                        </Form.Item>
                        <Form.Item
                          label="State"
                          name="state"
                          rules={[{ required: true, message: "" }]}
                          {...labelProps}
                        >
                          <AppConfigSelect
                            appConfigKey="states"
                            showSearch
                            size="small"
                          />
                        </Form.Item>
                        <Form.Item
                          label="County"
                          name="county"
                          rules={[{ required: true, message: "" }]}
                          {...labelProps}
                        >
                          <AppConfigSelect
                            appConfigKey="counties"
                            showSearch
                            size="small"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </div>
              )}
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.cardTitle}>
              <div className={styles.leftTitle}>
                <h2>Assets</h2>
              </div>
              <div className={styles.rightTitle}>
                <WellAssetCreateForm
                  well={well}
                  getWell={getWell}
                  getWellAssets={getWellAssets}
                />
              </div>
            </div>
            <div className={styles.cardBody}>
              <Row gutter={24}>
                <Col span={24}>
                  <WellAssets
                    well={well}
                    getWell={getWell}
                    wellAssets={wellAssets}
                    getWellAssets={getWellAssets}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24} xxl={10}>
          <Files recordId={well.id} recordType="well" />
        </Col>
      </Row>
    </div>
  );
};

export default withParams(Well);
