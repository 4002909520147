import React, { useState, useEffect, useCallback } from "react";
import { apiBase } from "../../utils/apiBase";
import { withParams } from "../../utils/withParams";
import { get, pick, isEmpty } from "lodash";
import { clsx } from "clsx";

import OperatorVendors from "./OperatorVendors";
import OperatorVendorCreateForm from "./OperatorVendorCreateForm";

import { Row, Col, Button, Input, Form, Modal, message } from "antd";

import { ExclamationCircleFilled } from "@ant-design/icons";

import styles from "./Operator.module.scss";

const { confirm } = Modal;

const labelProps = {
  labelAlign: "left",
  labelCol: {
    xxl: { span: 7, offset: 0 },
    xl: { span: 9 },
    lg: { span: 10 },
  },
};

const formFields = ["name"];

const Operator = (props) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [operator, setOperator] = useState({});
  const [operatorVendors, setOperatorVendors] = useState([]);

  const getOperator = useCallback(async () => {
    try {
      setLoading(true);
      const res = await apiBase.get(`/operators/${props.params.id}`);
      setOperator(get(res, "data", {}));
    } catch (err) {
      let msg = `${err.response.status}: Failed to load operator ${props.params.id}`;
      message.error(msg);
    } finally {
      setLoading(false);
    }
  }, [props.params.id]);

  const getOperatorVendors = useCallback(async () => {
    try {
      setLoading(true);
      const res = await apiBase.get(
        `/operators/${props.params.id}/quickbooks-vendors`
      );
      setOperatorVendors(get(res, "data", {}));
    } catch (err) {
      let msg = `${err.response.status}: Failed to load operator vendors ${props.params.id}`;
      message.error(msg);
    } finally {
      setLoading(false);
    }
  }, [props.params.id]);

  useEffect(() => {
    getOperator();
    getOperatorVendors();
  }, [getOperator, getOperatorVendors]);

  useEffect(() => {
    if (!isEmpty(operator)) {
      form.setFieldsValue({
        ...pick(operator, formFields),
      });
    }
  }, [form, operator]);

  const updateSubmit = (values) => {
    setLoading(true);
    apiBase
      .patch(`operators/${operator.id}`, values)
      .then((res) => {
        setOperator(res.data);
        getOperatorVendors();
        let msg = `${res.status}: Successfully updated operator.`;
        message.success(msg);
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
        form.resetFields();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteOperator = async (operatorId) => {
    setLoading(true);
    apiBase
      .delete(`/operators/${operatorId}`)
      .then((res) => {
        setLoading(false);
        props.history("/portfolio/operators?limit=50&page=1");
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to delete operator.`;
        message.error(msg);
        setLoading(false);
      });
  };

  const handleDelete = () => {
    confirm({
      title: "Are you sure you want to delete this operator?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      okButtonProps: { disabled: loading, loading: loading },
      cancelButtonProps: { disabled: loading, loading: loading },
      onOk: () => deleteOperator(operator.id),
    });
  };

  const onFinishFailed = () => {
    message.error("Form fields reqired");
  };

  return (
    <div className="App__rowContentDetail">
      <Row gutter={4}>
        <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className={styles.card}>
            <div className={styles.cardTitle}>
              <div className={styles.leftTitle}>
                <h2>Operator</h2>
              </div>
              <div className={styles.rightTitle}>
                <div>
                  <Button
                    onClick={() => handleDelete()}
                    className={styles.cardHeaderAction}
                    disabled={loading}
                    loading={loading}
                  >
                    Delete
                  </Button>
                  <Button
                    onClick={() => form.resetFields()}
                    className={styles.cardHeaderAction}
                    disabled={loading}
                  >
                    Reset
                  </Button>
                  <Button
                    className={styles.cardHeaderAction}
                    type="primary"
                    loading={loading}
                    htmlType="submit"
                    onClick={() => {
                      form.submit();
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
            <div className={styles.cardBody}>
              <Row gutter={24}>
                <Col span={24}>
                  {!isEmpty(operator) && (
                    <div className={styles.compactForm}>
                      <Form
                        name="basic"
                        layout="horizontal"
                        form={form}
                        initialValues={{
                          ...pick(operator, formFields),
                        }}
                        onFinish={updateSubmit}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        disabled={loading}
                      >
                        <Row gutter={16}>
                          <Col
                            span={12}
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                          >
                            {[
                              {
                                name: "name",
                                label: "Name",
                                required: true,
                              },
                            ].map((item, index) => (
                              <Form.Item
                                key={index}
                                label={item.label}
                                name={item.name}
                                rules={[
                                  { required: item.required, message: "" },
                                ]}
                                {...labelProps}
                              >
                                <Input
                                  disabled={item.disabled}
                                  onPressEnter={() => form.submit()}
                                  className={clsx({
                                    [styles.disabledItem]: item.disabled,
                                  })}
                                  size="small"
                                />
                              </Form.Item>
                            ))}
                          </Col>
                          <Col
                            span={12}
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                            xxl={12}
                          ></Col>
                        </Row>
                      </Form>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.cardTitle}>
              <div className={styles.leftTitle}>
                <h2>Quickbooks vendors</h2>
              </div>
              <div className={styles.rightTitle}>
                <OperatorVendorCreateForm
                  operator={operator}
                  getOperatorVendors={getOperatorVendors}
                />
              </div>
            </div>
            <div className={styles.cardBody}>
              <Row gutter={24}>
                <Col span={24}>
                  <OperatorVendors
                    parentLoading={loading}
                    operatorVendors={operatorVendors}
                    getOperatorVendors={getOperatorVendors}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withParams(Operator);
