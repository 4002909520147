import React, { Component } from "react";
import { formatDate } from "../utils/dates";
import { columnTypes } from "../utils/search";
import { apiBase } from "../utils/apiBase";
import { get } from "lodash";
import BaseSearch from "../views/search/BaseSearch";
import Took from "../views/search/Took";
import TotalRecords from "../views/search/TotalRecords";
import FilterTags from "../views/search/FilterTags";

import CreateMailTemplate from "./forms/CreateMailTemplate";

import { DeleteOutlined, ExclamationCircleFilled } from "@ant-design/icons";

import {
  Row,
  Col,
  Table,
  Select,
  Button,
  Modal,
  Pagination,
  message,
} from "antd";

import styles from "./MailTemplates.module.scss";

const { confirm } = Modal;

const baseColumnState = [
  {
    dataIndex: "id",
    title: "Id",
    editable: false,
    type: columnTypes.LINK,
    baseUrl: "/settings/mail-templates",
  },
  {
    dataIndex: "name",
    title: "Name",
    type: columnTypes.STRING,
    first: true,
  },
  {
    dataIndex: "description",
    title: "Description",
    type: columnTypes.STRING,
  },
  {
    title: "Created Date",
    dataIndex: "created_at",
    type: columnTypes.DATE_TIME,
    format: (value) => formatDate(value, "YYYY-MM-DD, h:mm:ss A"),
  },
  {
    title: "Updated Date",
    dataIndex: "updated_at",
    type: columnTypes.DATE_TIME,
    format: (value) => formatDate(value, "YYYY-MM-DD, h:mm:ss A"),
  },
];

class MailTemplates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      isLoading: false,
    };
  }

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleCreate = (values) => {
    this.setState({ isLoading: true });
    apiBase
      .post("/mail-templates", values)
      .then((res) => {
        let msg = `${get(res, "status")}: Successfully created new template.`;
        message.success(msg);
        this.props.history(`/settings/mail-templates/${get(res, "data.id")}`);
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
      });
  };

  showDeleteConfirm = (mailTemplateId) => {
    const { isLoading } = this.state;
    confirm({
      title: "Are you sure you want to delete this mail template?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      okButtonProps: { disabled: isLoading, loading: isLoading },
      cancelButtonProps: { disabled: isLoading, loading: isLoading },
      onOk: () => this.deleteMailTemplate(mailTemplateId),
    });
  };

  deleteMailTemplate = (mailTemplateId) => {
    const { executeSearch } = this.props;
    this.setState({ isLoading: true });
    apiBase
      .delete(`/mail-templates/${mailTemplateId}`)
      .then((res) => {
        executeSearch();
        this.setState({ isLoading: false });
        let msg = `${res.status}: ${res.data.message}`;
        message.success(msg);
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to delete mail template.`;
        message.error(msg);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const {
      isLoadingRecords,
      isLoadingCount,
      records,
      columnState,
      limit,
      page,
      totalRecords,
      tookRecords,
      tookCount,
      searchFields,
    } = this.props;

    const {
      handleSearch,
      handleRecordsChange,
      handlePaginationChange,
      handleFilterClose,
      handleTableChange,
      getColumnProps,
    } = this.props;

    let columns = columnState
      .map((column, idx) => ({
        key: idx,
        ...column,
        ...getColumnProps(column),
      }))
      .filter((col) => col.visible === true);

    columns.push({
      title: "",
      dataIndex: "actions",
      visible: true,
      editable: false,
      children: [
        {
          dataIndex: "actions",
          render: (_, record) => {
            return (
              <div className="App__tableOperations">
                <Button
                  shape="circle"
                  icon={<DeleteOutlined />}
                  size="small"
                  onClick={() => this.showDeleteConfirm(record.id)}
                  loading={this.state.isLoading}
                />
              </div>
            );
          },
        },
      ],
    });

    return (
      <div>
        <Row className="App__rowContent">
          <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16}>
            <Button onClick={this.showModal} className={styles.marginRight}>
              Create template
            </Button>
            <Button className="App__mr-5" onClick={() => handleSearch()}>
              Search
            </Button>
            <FilterTags
              searchFields={searchFields}
              columnState={columnState}
              handleFilterClose={handleFilterClose}
            />
            <CreateMailTemplate
              visible={this.state.visible}
              isLoading={this.state.isLoading}
              onCancel={this.handleCancel}
              onCreate={this.handleCreate}
            />
          </Col>
          <Col
            span={8}
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            className="App__rightActions"
          >
            <Select
              value={limit}
              onChange={handleRecordsChange}
              className={"App__mr-5"}
            >
              <Select.Option value={25}>25</Select.Option>
              <Select.Option value={50}>50</Select.Option>
              <Select.Option value={100}>100</Select.Option>
            </Select>
            <TotalRecords
              title={"Mail templates"}
              isLoadingCount={isLoadingCount}
              totalRecords={totalRecords}
            />
          </Col>
        </Row>
        <Row className="App__rowContent">
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={records}
              onChange={handleTableChange}
              pagination={false}
              showSorterTooltip={false}
              className="App__smallTables"
              loading={isLoadingRecords}
              scroll={{ x: "100%" }}
              size="small"
              bordered
            />
            <div className="App__searchFooterContainer">
              <Took
                isLoadingRecords={isLoadingRecords}
                isLoadingCount={isLoadingCount}
                tookRecords={tookRecords}
                tookCount={tookCount}
              />
              <Pagination
                current={page}
                pageSize={limit}
                total={totalRecords}
                onChange={handlePaginationChange}
                className="App__pagination"
                showSizeChanger={false}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const MailTemplatesWithSearch = (props) => (
  <BaseSearch
    baseColumnState={baseColumnState}
    localSortColumns={[]}
    searchUri="mail-templates"
    baseUri="/settings"
  >
    <MailTemplates {...props} />
  </BaseSearch>
);

export default MailTemplatesWithSearch;
