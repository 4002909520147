import React, { Component } from "react";
import { capitalize } from "lodash";
import { formatNumber } from "../utils/numbers";
import { formatDate } from "../utils/dates";
import { columnTypes } from "../utils/search";

import BaseSearch from "./search/BaseSearch";
import Took from "./search/Took";
import TotalRecords from "./search/TotalRecords";
import { statusOptions } from "../common/idiBulkSearches";
import FilterTags from "./search/FilterTags";
import ChangeColumnsForm from "./forms/ChangeColumnsForm";

import { TableOutlined } from "@ant-design/icons";

import { Row, Col, Table, Select, Button, Tooltip, Pagination } from "antd";

const baseColumnState = [
  {
    dataIndex: "id",
    title: "Id",
    editable: false,
    type: columnTypes.LINK,
    baseUrl: "/idi-bulk-searches",
  },
  {
    dataIndex: "name",
    title: "Name",
    type: columnTypes.STRING,
  },
  {
    dataIndex: "status",
    title: "Status",
    type: columnTypes.SELECT,
    options: statusOptions,
    format: ({ value }) => capitalize(value),
  },
  {
    title: "Progress",
    dataIndex: "progress",
    type: columnTypes.INTEGER,
    format: (value) => `${formatNumber(value, "0.00")}%`,
    className: "App__w12",
  },
  {
    title: "Percentage Success",
    dataIndex: "percentage_success",
    className: "App__w12",
    children: [
      {
        key: "percentage_success",
        dataIndex: "percentage_success",
        title: () => <div />,
        render: (_, record) => {
          const percentage =
            record.count_owners > 0
              ? (record.count_with_results / record.count_owners) * 100
              : 0;
          return `${formatNumber(percentage, "0.00")}%`;
        },
      },
    ],
  },
  {
    key: "runtime_seconds",
    dataIndex: "runtime_seconds",
    title: "Runtime (s)",
    className: `App__w10`,
    children: [
      {
        key: "runtime_seconds",
        dataIndex: "runtime_seconds",
        title: () => <div />,
        render: (value) => value,
      },
    ],
  },
  {
    title: "Count owners",
    dataIndex: "count_owners",
    type: columnTypes.INTEGER,
    className: "App__w12",
  },
  {
    title: "Count leads",
    dataIndex: "count_leads",
    type: columnTypes.INTEGER,
    className: "App__w12",
  },
  {
    title: "Count success",
    dataIndex: "count_with_results",
    type: columnTypes.INTEGER,
    className: "App__w12",
  },
  {
    title: "Count failed",
    dataIndex: "count_without_results",
    type: columnTypes.INTEGER,
    className: "App__w12",
  },
  {
    title: "Count success (API)",
    dataIndex: "count_with_results",
    type: columnTypes.INTEGER,
    className: "App__w16",
    visible: false,
  },
  {
    title: "Count failed (API)",
    dataIndex: "count_without_results",
    type: columnTypes.INTEGER,
    className: "App__w16",
    visible: false,
  },
  {
    title: "Created By",
    dataIndex: ["user", "alias"],
    type: columnTypes.STRING,
  },
  {
    title: "Started at",
    dataIndex: "started_at",
    type: columnTypes.DATE_TIME,
    format: (value) => formatDate(value, "YYYY-MM-DD, h:mm:ss A"),
  },
  {
    title: "Stopped at",
    dataIndex: "stopped_at",
    type: columnTypes.DATE_TIME,
    format: (value) => formatDate(value, "YYYY-MM-DD, h:mm:ss A"),
  },
];

class IDIBulkSearches extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  render() {
    const {
      isLoadingRecords,
      isLoadingCount,
      records,
      columnState,
      limit,
      page,
      totalRecords,
      tookRecords,
      tookCount,
      searchFields,
      isChangeColumnsVisible,
    } = this.props;

    const {
      handleSearch,
      handleRecordsChange,
      handlePaginationChange,
      handleFilterClose,
      handleTableChange,
      handleSubmitColumnChange,
      showColumnChangeModal,
      handleCancelColumnsChange,
      changeColumnFormRef,
      getColumnProps,
    } = this.props;

    let columns = columnState
      .map((column, idx) => ({
        key: idx,
        ...column,
        ...getColumnProps(column),
      }))
      .filter((col) => col.visible === true);

    return (
      <div>
        <Row className="App__rowContent">
          <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16}>
            <Button className="App__mr-5" onClick={() => handleSearch()}>
              Search
            </Button>
            <FilterTags
              searchFields={searchFields}
              columnState={columnState}
              handleFilterClose={handleFilterClose}
            />
          </Col>
          <Col
            span={8}
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            className="App__rightActions"
          >
            <Tooltip placement="bottomRight" title={"Change visible columns"}>
              <Button
                onClick={showColumnChangeModal}
                className="App__iconButton"
              >
                <TableOutlined />
              </Button>
            </Tooltip>
            <ChangeColumnsForm
              wrappedComponentRef={changeColumnFormRef}
              visible={isChangeColumnsVisible}
              isLoading={isLoadingRecords}
              onCancel={handleCancelColumnsChange}
              onOk={handleSubmitColumnChange}
              columnState={columnState}
            />
            <Select
              value={limit}
              onChange={handleRecordsChange}
              className={"App__mr-5"}
            >
              <Select.Option value={25}>25</Select.Option>
              <Select.Option value={50}>50</Select.Option>
              <Select.Option value={100}>100</Select.Option>
            </Select>
            <TotalRecords
              title={"IDI bulk searches"}
              isLoadingCount={isLoadingCount}
              totalRecords={totalRecords}
            />
          </Col>
        </Row>
        <Row className="App__rowContent">
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={records}
              onChange={handleTableChange}
              pagination={false}
              showSorterTooltip={false}
              className="App__smallTables"
              loading={isLoadingRecords}
              scroll={{ x: "100%" }}
              size="small"
              bordered
            />
            <div className="App__searchFooterContainer">
              <Took
                isLoadingRecords={isLoadingRecords}
                isLoadingCount={isLoadingCount}
                tookRecords={tookRecords}
                tookCount={tookCount}
              />
              <Pagination
                current={page}
                pageSize={limit}
                total={totalRecords}
                onChange={handlePaginationChange}
                className="App__pagination"
                showSizeChanger={false}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const IDIBulkSearchesWithSearch = (props) => (
  <BaseSearch
    baseColumnState={baseColumnState}
    localSortColumns={[]}
    searchUri="idi-bulk-searches"
  >
    <IDIBulkSearches {...props} />
  </BaseSearch>
);

export default IDIBulkSearchesWithSearch;
