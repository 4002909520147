import React, { useState, useEffect } from "react";
import { Form, Table, Button, Modal } from "antd";
import { alphaSort, formatNumber } from "../../utils/numbers";
import { formatDate } from "../../utils/dates";
import { get } from "lodash";

import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";

import { EditableCell } from "../utils/TableUtils";

const { confirm } = Modal;

const UnitLeads = (props) => {
  const {
    users,
    leads,
    recordsPerPage,
    isLoadingLeads,
    handleLeadEditSubmit,
    deleteLead,
  } = props;

  const [form] = Form.useForm();
  const [data, setData] = useState(leads);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;

  useEffect(() => setData(leads), [leads]);

  const showDeleteConfirm = (leadId) => {
    confirm({
      title: "Are you sure you want to delete this lead?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      okButtonProps: { disabled: isLoadingLeads, loading: isLoadingLeads },
      cancelButtonProps: { disabled: isLoadingLeads, loading: isLoadingLeads },
      onOk: () => deleteLead(leadId),
    });
  };

  const handleRowClick = (e, _to) => {
    e.stopPropagation();
    if (e.ctrlKey || e.metaKey) {
      window.open(_to, "_blank");
      return;
    }
    props.history(_to);
  };

  const edit = (record) => {
    form.setFieldsValue({
      status: "",
      offer_amount: "",
      "user.alias": get(record, "user.alias"),
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (record) => {
    try {
      const row = await form.validateFields();
      const userIdx = users.findIndex(
        (user) => user.alias === get(row, "user.alias")
      );
      let newLead = {
        offer_amount: row.offer_amount,
        status: row.status,
        user_id: users[userIdx].id,
      };
      const newData = [...data];
      const index = newData.findIndex((item) => record.id === item.id);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row, user: users[userIdx] });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
      await handleLeadEditSubmit(record.id, newLead);
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "Lead",
      dataIndex: "id",
      className: `App__rowLink App__w6`,
      render: (value) => <div className="App__rowIcon">{value}</div>,
      onCell: (record) => ({
        onClick: (e) => handleRowClick(e, `/leads/${record["id"]}`),
      }),
    },
    {
      title: "Owner",
      dataIndex: "owner_id",
      className: `App__rowLink App__w8`,
      render: (value) => <div className="App__rowIcon">{value}</div>,
      onCell: (record) => ({
        onClick: (e) => handleRowClick(e, `/owners/${record["owner_id"]}`),
      }),
    },
    {
      title: "Owner Name",
      dataIndex: ["owner", "name_formatted"],
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        alphaSort(a.owner.name_formatted, b.owner.name_formatted),
    },
    {
      title: "Address",
      dataIndex: ["owner", "address_formatted"],
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        alphaSort(a.owner.address_formatted, b.owner.address_formatted),
    },
    {
      title: "City",
      dataIndex: ["owner", "city"],
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => alphaSort(a.owner.city, b.owner.city),
    },
    {
      title: "State",
      dataIndex: ["owner", "state"],
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => alphaSort(a.owner.state, b.owner.state),
    },
    {
      title: "Zip",
      dataIndex: ["owner", "zip_code"],
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => alphaSort(a.owner.zip_code, b.owner.zip_code),
    },
    {
      title: "Interest Type",
      dataIndex: "interest_type",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => alphaSort(a.interest_type, b.interest_type),
    },
    {
      title: "DOI",
      dataIndex: "decimal_interest",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.decimal_interest - b.decimal_interest,
      render: (value) =>
        value !== null ? formatNumber(value, "0.000000") : null,
    },
    {
      title: "NRA",
      dataIndex: "nra",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.nra - b.nra,
      render: (value) => (value !== null ? formatNumber(value, "0.00") : null),
    },
    {
      title: "Offer Amount",
      dataIndex: "offer_amount",
      sortDirections: ["descend", "ascend"],
      width: 125,
      sorter: (a, b) => a.offer_amount - b.offer_amount,
      render: (value) =>
        value !== null ? `$${formatNumber(value, "0,0.00")}` : null,
      editable: true,
    },
    {
      title: "Lead Status",
      dataIndex: "status",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => alphaSort(a.status, b.status),
      editable: true,
    },
    {
      title: "Tax year",
      dataIndex: "tax_year",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.tax_year - b.tax_year,
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => alphaSort(a.created_at, b.created_at),
      render: (value) => formatDate(value, "YYYY-MM-DD"),
    },
    {
      title: "Updated Date",
      dataIndex: "updated_at",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => alphaSort(a.updated_at, b.updated_at),
      render: (value) => formatDate(value, "YYYY-MM-DD"),
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "actions",
      render: (value, record) => {
        const editable = isEditing(record);
        return (
          <div className="App__tableOperations">
            {editable ? (
              <>
                <Button
                  shape="circle"
                  icon={<CheckOutlined />}
                  size="small"
                  className="App__tableEditButton"
                  onClick={() => save(record)}
                  disabled={isLoadingLeads}
                />
                <Button
                  shape="circle"
                  icon={<CloseOutlined />}
                  size="small"
                  className="App__tableEditButton"
                  onClick={() => cancel()}
                  disabled={isLoadingLeads}
                />
              </>
            ) : (
              <Button
                shape="circle"
                icon={<EditOutlined />}
                size="small"
                className="App__tableEditButton"
                onClick={() => edit(record)}
                disabled={isLoadingLeads}
              />
            )}
            <Button
              shape="circle"
              icon={<DeleteOutlined />}
              size="small"
              onClick={() => showDeleteConfirm(record.id)}
              disabled={isLoadingLeads}
            />
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        columns={mergedColumns}
        dataSource={data}
        pagination={{
          pageSize: recordsPerPage,
          onChange: cancel,
          showSizeChanger: false,
        }}
        showSorterTooltip={false}
        className="App__smallTables"
        loading={isLoadingLeads}
        scroll={{ x: "100%" }}
        bordered
      />
    </Form>
  );
};

export default UnitLeads;
