import React, { useState } from "react";
import { apiBase } from "../../utils/apiBase";
import { withParams } from "../../utils/withParams";
import { withRouter } from "../../utils/withRouter";
import { formatNumber, alphaSort } from "../../utils/numbers";

import { Table, Button, Modal, message } from "antd";

import { DeleteOutlined, ExclamationCircleFilled } from "@ant-design/icons";

const { confirm } = Modal;

const TractAssets = (props) => {
  const { tractAssets, parentLoading } = props;

  const [loading, setLoading] = useState(false);

  const deleteTractAsset = async (id) => {
    setLoading(true);
    apiBase
      .delete(`/tract-assets/${id}`)
      .then((res) => {
        let msg = `${res.status}: Successfully deleted tract asset.`;
        message.success(msg);
        props.getTractAssets();
      })
      .catch((err) => {
        let msg = `${err.response.status}: Failed to delete tract asset.`;
        message.error(msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: "Are you sure you want to delete this tract asset?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      okButtonProps: {
        disabled: loading || parentLoading,
        loading: loading || parentLoading,
      },
      cancelButtonProps: {
        disabled: loading || parentLoading,
        loading: loading || parentLoading,
      },
      onOk: () => deleteTractAsset(id),
    });
  };

  function handleRowClick(e, _to) {
    e.stopPropagation();
    if (e.ctrlKey || e.metaKey) {
      window.open(_to, "_blank");
      return;
    }
    props.history(_to);
  }

  const columns = [
    {
      title: "Name",
      dataIndex: ["asset", "name"],
      className: `App__rowLink`,
      render: (value) => <div>{value}</div>,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => alphaSort(a.name, b.name),
      onCell: (record) => ({
        onClick: (e) =>
          handleRowClick(e, `/portfolio/assets/${record["asset"]["id"]}`),
      }),
    },
    {
      title: "County",
      dataIndex: ["asset", "county"],
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => alphaSort(a.county, b.county),
    },
    {
      title: "State",
      dataIndex: ["asset", "state"],
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => alphaSort(a.state, b.state),
    },
    {
      title: "Acres",
      dataIndex: ["asset", "acres"],
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.acres - b.acres,
      render: (value) =>
        value !== null ? formatNumber(value, "0.0000") : null,
    },
    {
      title: "DOI",
      dataIndex: ["asset", "decimal_interest"],
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.decimal_interest - b.decimal_interest,
      render: (value) =>
        value !== null ? formatNumber(value, "0.00000000") : null,
    },
    {
      title: "NRA",
      dataIndex: ["asset", "nra"],
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.nra - b.nra,
      render: (value) =>
        value !== null ? formatNumber(value, "0.0000") : null,
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "actions",
      render: (value, record) => {
        return (
          <div className="App__tableOperations">
            <Button
              shape="circle"
              icon={<DeleteOutlined />}
              size="small"
              onClick={() => showDeleteConfirm(record.id)}
              disabled={loading || parentLoading}
            />
          </div>
        );
      },
    },
  ];

  return (
    <Table
      pagination={false}
      columns={columns}
      dataSource={tractAssets.map((tractAsset, idx) => ({
        ...tractAsset,
        key: idx,
      }))}
      showSorterTooltip={false}
      className="App__smallTables"
      loading={loading || parentLoading}
      scroll={{ x: "100%" }}
      bordered
    />
  );
};

export default withRouter(withParams(TractAssets));
