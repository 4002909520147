import React, { useState, useEffect, useCallback } from "react";
import { apiBase } from "../../utils/apiBase";
import { withParams } from "../../utils/withParams";
import { Helmet } from "react-helmet";
import { get, pick, isEmpty } from "lodash";

import { Link } from "react-router-dom";
import { formatDate } from "../../utils/dates";
import Files from "../common/Files";
import PermitUnits from "./PermitUnits";
import PermitUnitsCopyForm from "./PermitUnitsCopyForm";
import PermitUnitCreateForm from "./PermitUnitCreateForm";
import RecordLabelsEdit from "../common/RecordLabelsEdit";

import { formatNumber } from "../../utils/numbers";
import { Button, Row, Col, Form, message, Input } from "antd";
import { LinkOutlined } from "@ant-design/icons";

import styles from "./Permit.module.scss";

const formFields = ["permit_plat_id"];

const Permit = (props) => {
  const [loading, setLoading] = useState(false);
  const [permit, setPermit] = useState({});
  const [permitUnits, setPermitUnits] = useState([]);

  const [permitEdit, setPermitEdit] = useState(false);
  const [permitCopy, setPermitCopy] = useState({});

  const [form] = Form.useForm();

  const getPermit = useCallback(async () => {
    try {
      setLoading(true);
      const res = await apiBase.get(`/permits/${props.params.id}`);
      setPermit(get(res, "data", {}));
    } catch (err) {
      let msg = `${err.response.status}: Failed to load permit ${props.params.id}`;
      message.error(msg);
    } finally {
      setLoading(false);
    }
  }, [props.params.id]);

  const getPermitUnits = useCallback(async () => {
    try {
      setLoading(true);
      const res = await apiBase.get(`/permits/${props.params.id}/units`);
      setPermitUnits(get(res, "data", {}));
    } catch (err) {
      let msg = `${err.response.status}: Failed to load permit units ${props.params.id}`;
      message.error(msg);
    } finally {
      setLoading(false);
    }
  }, [props.params.id]);

  const onFinishFailed = () => {
    message.error("Form fields required");
  };

  const setPermitEditMode = () => {
    if (!permitEdit) {
      let permitCopy = { ...permit };
      setPermitCopy(permitCopy);
      setPermitEdit(true);
    }
  };

  const cancelPermitEdit = () => {
    setPermit(permitCopy);
    setPermitEdit(false);
    form.setFieldsValue({
      ...pick(permitCopy, formFields),
    });
  };

  const updateSubmit = (values) => {
    setLoading(true);
    apiBase
      .patch(`permits/${permit.id}`, values)
      .then((res) => {
        setPermit(res.data);
        setPermitEdit(false);
        let msg = `${res.status}: Successfully updated permit.`;
        message.success(msg);
      })
      .catch((err) => {
        let msg = `${get(err, "response.status")}: ${get(
          err,
          "response.data.message"
        )}`;
        message.error(msg);
        form.resetFields();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createPermitPlat = async () => {
    try {
      cancelPermitEdit();
      setLoading(true);
      if (permit.permit_plat_id) {
        message.error("Permit already has a plat");
        return;
      }
      const res = await apiBase.post("/permit-plats", {
        permit_id: permit.id,
      });
      debugger;
      props.history(`/permit-plats/${get(res, "data.id")}`);
      message.success("Successfully created permit plat");
    } catch (err) {
      debugger;
      message.error("Failed to create permit plat");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPermit();
    getPermitUnits();
  }, [getPermit, getPermitUnits]);

  useEffect(() => {
    if (!isEmpty(permit)) {
      form.setFieldsValue({
        ...pick(permit, formFields),
      });
    }
  }, [form, permit]);

  return (
    <div className="App__rowContentDetail">
      <Helmet>
        <title>{`Permit: ${permit.id}`}</title>
      </Helmet>
      <Row>
        <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
          <div className={styles.card}>
            <div className={styles.cardTitle}>
              <div className={styles.leftTitle}>
                <h2>Permit</h2>
                <RecordLabelsEdit
                  record_type="permit"
                  record_id={permit.id}
                  label_ids={permit.label_ids || []}
                />
              </div>
              <div className={styles.rightTitle}>
                {!permitEdit ? (
                  <Button
                    onClick={setPermitEditMode}
                    className="App__mr-8"
                    disabled={loading}
                    loading={loading}
                  >
                    Edit
                  </Button>
                ) : (
                  <>
                    <Button
                      onClick={cancelPermitEdit}
                      className="App__mr-8"
                      disabled={loading}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => form.submit()}
                      type="primary"
                      className="App__mr-8"
                      disabled={loading}
                      loading={loading}
                    >
                      Save
                    </Button>
                  </>
                )}
                <Button
                  onClick={createPermitPlat}
                  disabled={permit.permit_plat_id || loading}
                  loading={loading}
                >
                  Create permit plat
                </Button>
              </div>
            </div>
            <div className={styles.cardBody}>
              <div className={styles.compactForm}>
                <Form
                  name="basic"
                  form={form}
                  initialValues={{
                    ...pick(permit, formFields),
                  }}
                  onFinish={updateSubmit}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  disabled={loading}
                >
                  <Row>
                    <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                      <div className={styles.objectData}>
                        <span>Id:</span>
                        {permit.id}
                      </div>
                      <div className={styles.objectData}>
                        <span>Permit Plat ID:</span>
                        <div onClick={() => setPermitEditMode()}>
                          <Form.Item
                            name="permit_plat_id"
                            rules={[{ required: false, message: "" }]}
                            noStyle
                          >
                            <Input.Group compact>
                              <Form.Item name="permit_plat_id" noStyle>
                                <Input
                                  style={{
                                    width: "calc(100% - 24px)",
                                  }}
                                  size="small"
                                  className={
                                    permitEdit
                                      ? styles.inputActive
                                      : styles.inputDisabled
                                  }
                                  onPressEnter={() => form.submit()}
                                />
                              </Form.Item>
                              <Button
                                size="small"
                                icon={<LinkOutlined />}
                                className={styles.clickInput}
                                onClick={() => {
                                  if (permit.permit_plat_id) {
                                    props.history(
                                      `/permit-plats/${permit.permit_plat_id}`
                                    );
                                  }
                                }}
                              />
                            </Input.Group>
                          </Form.Item>
                        </div>
                      </div>
                      <div className={styles.objectData}>
                        <span>Universal doc number:</span>
                        <Link
                          to={permit.lease_url}
                          className={styles.linkButton}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {permit.universal_doc_number}
                        </Link>
                      </div>
                      <div className={styles.objectData}>
                        <span>RRC ID:</span>
                        {permit.rrc_id}
                      </div>
                      <div className={styles.objectData}>
                        <span>API number:</span>
                        {permit.api_number}
                      </div>
                      <div className={styles.objectData}>
                        <span>Lease:</span>
                        {permit.lease}
                      </div>
                      <div className={styles.objectData}>
                        <span>Well number:</span>
                        {permit.well_number}
                      </div>
                      <div className={styles.objectData}>
                        <span>Operator name:</span>
                        {permit.operator_name}
                      </div>
                      <div className={styles.objectData}>
                        <span>District:</span>
                        {permit.district}
                      </div>
                      <div className={styles.objectData}>
                        <span>County:</span>
                        {permit.county}
                      </div>
                      <div className={styles.objectData}>
                        <span>Status:</span>
                        {permit.status}
                      </div>
                    </Col>
                    <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                      <div className={styles.objectData}>
                        <span>Submitted date:</span>
                        {formatDate(permit.submitted_date, "YYYY-MM-DD")}
                      </div>
                      <div className={styles.objectData}>
                        <span>Approved date:</span>
                        {formatDate(permit.approved_date, "YYYY-MM-DD")}
                      </div>
                      <div className={styles.objectData}>
                        <span>Created at:</span>
                        {formatDate(permit.created_at, "YYYY-MM-DD, h:mm:ss A")}
                      </div>
                      <div className={styles.objectData}>
                        <span>Updated at:</span>
                        {formatDate(permit.updated_at, "YYYY-MM-DD, h:mm:ss A")}
                      </div>
                      <div className={styles.objectData}>
                        <span>Wellbore profiles:</span>
                        {permit.wellbore_profiles}
                      </div>
                      <div className={styles.objectData}>
                        <span>Filing purpose:</span>
                        {permit.filing_purpose}
                      </div>
                      <div className={styles.objectData}>
                        <span>Amend:</span>
                        {String(permit.amend)}
                      </div>
                      <div className={styles.objectData}>
                        <span>Total depth:</span>
                        {permit.total_depth}
                      </div>
                      <div className={styles.objectData}>
                        <span>Stacked lateral parent well DP:</span>
                        {permit.stacked_lateral_parent_well_dp}
                      </div>
                      <div className={styles.objectData}>
                        <span>Unit count:</span>
                        {permit.unit_count}
                      </div>
                      <div className={styles.objectData}>
                        <span>Total unit allocation:</span>
                        {formatNumber(
                          permit.total_unit_allocation * 100,
                          "0.00"
                        )}
                        %
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.cardTitle}>
              <div className={styles.leftTitle}>
                <h2>Units</h2>
              </div>
              <div className={styles.rightTitle}>
                <PermitUnitsCopyForm
                  permit={permit}
                  getPermit={getPermit}
                  getPermitUnits={getPermitUnits}
                />
                <PermitUnitCreateForm
                  permits={[permit]}
                  fetchData={() => {
                    getPermit();
                    getPermitUnits();
                  }}
                />
              </div>
            </div>
            <div className={styles.cardBody}>
              <PermitUnits
                permitUnits={permitUnits}
                getPermitUnits={getPermitUnits}
                getPermit={getPermit}
              />
            </div>
          </div>
        </Col>
        <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
          <Files recordId={permit.id} recordType="permit" />
        </Col>
      </Row>
    </div>
  );
};

export default withParams(Permit);
